import { useState, useMemo } from "react"
import {
    Elements,
    useElements,
    useStripe,
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import auth0 from "auth0-js";
import decrypt from "./crpyto";
import auth from "../../../Services/auth0services";
import Services from "../../../Services/auth.service"
import { addregidetail } from "../../../Action";
import CreditCards from "../../../Images/credit-cards.png";
import Loader2 from "../../../Images/loader2.gif";

// const stripePromise = loadStripe("pk_test_51LDYk4B9a9of99tJTtAMRmwNDZdjnRy43FKhnSMenpcXk9Ecgh6S3xYwmlNOtDv7fMP94VtAVfjh7MssrBxVcYza00JVsFSIb8")
const stripePromise = loadStripe("pk_test_eEr5kmCNHkAb7tn5oQGGO75s")
const PaymentForm = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const regidetail = useSelector((state) => {
      return state.ReduRegistrationDetail;
    })
    const [isProcessing, setProcessingTo] = useState(false);
    const [checkoutError, setCheckoutError] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [cardDetail, setcardDetail] = useState({
        cardno: "",
        expiry: "",
        cvc: "",
        cardname: "",
        country: "",
        zip: "",
      });
    const [err, setErr] = useState({
      cardno: "",
      expiry: "",
      cvc: "",
    });
    const options = useMemo(() => countryList().getData(), []);
  // called on change on input fields
    const handleChange = (e) => {
      setcardDetail({ ...cardDetail, [e.target.name]: e.target.value });  
    }
    const handleCardDetailsChange = event => {
        event.error ? setCheckoutError(event.error.message) : setCheckoutError();
    };

    const handleSubmit = (stripe, elements) => async () => {
      var cardnum = elements.getElement(CardNumberElement)
      var data = {}
      if(cardDetail.cardname.length > 0)
          data = {...data,"name": cardDetail.cardname}
      if(cardDetail.country.length > 0)
          data = {...data,"address": {"country": cardDetail.country}}
      const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardnum,
          billing_details: data,
      })

      if (error) {
        console.log('[error]', error)
        setCheckoutError(error.message) 
      } else {
          // console.log(selectedplan)
          console.log('[PaymentMethod]', paymentMethod)
          setProcessingTo(true) 
          stripePaymentMethodHandler(paymentMethod)
          // ... SEND to your API server to process payment intent
      }
    }

    const stripePaymentMethodHandler = (paymentMethod) => {
      var data = {
        userId: regidetail.userid,
        name_on_card: cardDetail.cardname,
        postal_code: paymentMethod.billing_details.address.postal_code,
        country: cardDetail.country,
        purchased_plan: regidetail.selectedplan._id,
        payment_id: paymentMethod.id,
        card_last4: paymentMethod.card.last4,
        card_expiry_date: paymentMethod.card.exp_month + "/" + paymentMethod.card.exp_year,
        card_brand: paymentMethod.card.brand,
      }
      Services.saveuseronstep2(data).then((response) => {
        /* Can only call response.json() once, otherwise you will get an error */
        console.log(response)
        return response
    }).then((parsedResponse) => {
        /* Proceed to next step of checking Payment Intent status */
        console.log(parsedResponse, " subscription")
        manageSubscriptionStatus(parsedResponse.data.data[0].subscription)
    }).catch((err) => {
        console.error('Error creating subscription:', err)
    })
    }
    function manageSubscriptionStatus(subscription) {
        const { latest_invoice } = subscription
        const { payment_intent } = latest_invoice
        if (payment_intent) {
            /* Do NOT share or embed your client_secret anywhere */
            const { client_secret, status } = payment_intent
            if (status === "requires_action" || status === "requires_payment_method") {
                console.log("reached........")
                stripe.confirmCardPayment(client_secret)
                    .then((result) => {
                        console.log(result)
                        if (result.error) {
                            setProcessingTo(false)
                            console.log('payment-form')
                            console.log(result.error) // Display error message to customer
                            setCheckoutError(result.error)
                        } else {
                            // call api to signup user in auth0 
                            dispatch(addregidetail({payment_status: true}))
                            setProcessingTo(false)
                            history("/firststepregister/6");
                            console.log('success1') // Show success state
                        }
                    }).catch((err) => {
                      console.error('Error confirming card payment:', err)
                      setProcessingTo(false)
                      setCheckoutError(err)
                      console.log('error') // Show error state
                    })
            } else {
              dispatch(addregidetail({payment_status: true}))
              setProcessingTo(false)
                console.log('success2') // Show success state
                history("/firststepregister/6");
            }
        } else {
            dispatch(addregidetail({payment_status: true}))
            /* If no payment intent exists, show the success state
             * Usually in this case if you set up a trial with the subscription
             */
            setProcessingTo(false)
            console.log('success3')
            history("/firststepregister/6");
        }
    }

    return (
        <>
      
            <div className="form-group">
                <span className="error">{checkoutError}</span>
            </div>
                <div className="form-group">
                  <div className="um-right-in-label">Card number*</div>
                  <div className="um-right-in-box um-credit-cards">
                   <CardNumberElement onChange={handleCardDetailsChange}/>
                    <a>
                      <img src={CreditCards} />
                    </a>
                  </div>
				  <span className="error">{err.cardno}</span>
                </div>

                <div className="row">
                  <div className="col-sm-6 form-group">
                    <div className="um-right-in-label">Expiry*</div>
                    <div className="um-right-in-box  um-credit-cards">                    
                        <CardExpiryElement onChange={handleCardDetailsChange}/>
                    </div>
					<span className="error">{err.expiry}</span>
                  </div>
                  <div className="col-sm-6 form-group">
                    <div className="um-right-in-label">CVC*</div>
                    <div className="um-right-in-box">
                    <CardCvcElement onChange={handleCardDetailsChange}/>
                    </div>
					<span className="error">{err.cvc}</span>
                  </div>
                </div>
               
                <div className="form-group">
                  <div className="um-right-in-label">Name on card</div>
                  <div className="um-right-in-box">
                    <input
                      type="text"
                      name="cardname"
                      placeholder="Enter cardholder name"
                      value={cardDetail.cardname}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 form-group">
                    <div className="um-right-in-label">Country or region</div>
                    <div className="um-right-in-box">
                      <select
                        name="country"
                        value={cardDetail.country}
                        onChange={handleChange}
                      >
                        <option>-- Select your country --</option>
                        {options.length > 0 &&
                          options.map((opt) => {
                            return <option value={opt.value}>{opt.label}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 form-group">
                    <div className="um-right-in-label">Zip</div>
                    <div className="um-right-in-box">
                      <input
                        type="text"
                        name="zip"
                        placeholder="Enter zip code"
                        value={cardDetail.zip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {/* <CardElement /> */}
                <div className="um-rgt-btn">
                  {isProcessing ? <>
                  <div className="um-rgt-btn-loading">
                      <span className="loading-btn-in2"><img src={Loader2} /></span>
                  <button className="btn2" disabled={isProcessing} >
                  Please wait...
                  </button></div></> :   <button className="btn2" onClick={handleSubmit(stripe, elements)} disabled={isProcessing} >
                 Pay Now
                  </button>}
                  </div>
                
        </>
    )
}

const StripePaymentForm = () => {
 
    return (
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements> );
}

export default StripePaymentForm


