import React, { useEffect, useState } from "react";
import authServicePartner from "../../../../Services/auth.service.partner";
import { useLocation } from "react-router-dom";
import ReviewTable from "./ReviewTable";
import SearchAndSorting from "../../../AdminCommonComponent/TableComponent/SearchAndSorting";
import { useDispatch } from "react-redux";
import moment from "moment";
import authService from "../../../../Services/auth.service";

const PartnerReview = ({ tabInformation, partnername }) => {
  const columns = [
    { id: "name", label: "Member name" },
    { id: "star", label: "Rating" },
    { id: "reviewNote", label: "Review" },
    { id: "date", label: "Date" },
    { id: "status", label: "Status" },
    { id: "reportCount", label: "Reports" },
    { id: "_id", label: "Actions" },
  ];

  const location = useLocation().search;
  const id = new URLSearchParams(location).get("id");
  const [partnerReview, setPartnerReview] = useState([]);
  const [rows, setRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("pending");
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setDataLoader(true);
    reviewListByPartnerApi(filter);
    getallusersSuggestionListApi();
    setSearchText("");
  }, [tabInformation]);

  function createReviewData(
    _id,
    star,
    companyName,
    name,
    reviewNote,
    status,
    date,
    reportCount
  ) {
    return {
      _id,
      star,
      companyName,
      name,
      reviewNote,
      status,
      date,
      reportCount,
    };
  }
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes =
        await authService.getPartnerReviewSuggestionList();

      console.log(getSuggestionRes, "getSuggestionRes");
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.companyName }));
        console.log(arr);
        setSuggestionList(arr);
      }
    } catch (err) { }
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  async function reviewListByPartnerApi(filter, col, sort) {
    let query = `?filtertype=${filter}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    // setDataLoader(true)
    const reviewListRes = await authServicePartner.reviewListByPartnerId(
      id,
      query
    );
    try {
      if (reviewListRes.data.status) {
        const arr = reviewListRes.data.data[0].reviews;
        const pendingData = arr.filter((item) => item.status === "none");
        dispatch({ type: "pendingReview", payload: pendingData.length });
        if (arr.length > 0) {
          var newArr = [];
          for (let i = 0; i < arr.length; i++) {
            newArr[i] = createReviewData(
              arr[i]._id,
              arr[i].star !== undefined && arr[i].star !== null
                ? arr[i].star
                : 0,
              arr[i].companyName,
              arr[i].userId !== undefined &&
                arr[i].userId !== null &&
                (arr[i].userId.display_name !== "")
                ? `${arr[i].userId.display_name} ${arr[i].isAnonymous ? `*` : ""
                }`
                : "-",
              arr[i].reviewNote !== undefined &&
                arr[i].reviewNote !== null &&
                arr[i].reviewNote !== ""
                ? arr[i].reviewNote
                : "-",
              arr[i].status,
              moment(arr[i].createdAt).format("MMM DD, YYYY"),
              arr[i].reportCount !== undefined && arr[i].reportCount !== null
                ? arr[i].reportCount
                : 0
            );
          }
          // let finalData = newArr.sort((a, b) => a.status === 'none' && b.status !== 'none' ? -1 : 1,)
          setRows(newArr);
          setPartnerReview(newArr);
        } else {
          setRows([]);
          setPartnerReview([]);
        }
        setDataLoader(false);
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
      return false;
    }
  }

  //sorting function
  const handleSort = (col, direc) => {
    setDataLoader(true);
    reviewListByPartnerApi(filter, col, direc);
  };

  //search using text
  const handleSearch = (text) => {
    setSuggestionBox(false);
    setSearchText(text);
    if (partnerReview !== undefined && partnerReview.length > 0) {
      var searchResult = partnerReview.filter((review) => {
        return review.companyName
          .toLowerCase()
          .includes(text.trim().toLowerCase());
      });
      setRows(searchResult);
    }
  };

  // handle status change filter function
  const handleStatusChange = (e) => {
    setFilter(e.target.value);
    setDataLoader(true);
    reviewListByPartnerApi(e.target.value);
  };
  return (
    <>
      <div className="uic-fl-search">
        <input
          type="text"
          placeholder="Search"
          className="search-user-input"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            var currentValue;
            if (e.target.value) {
              currentValue = e.target.value;
            } else {
              currentValue = e.target.value;
              setSearchText(currentValue);
            }
            setActualsearch(currentValue);
            if (suggestionList !== undefined && suggestionList.length > 0) {
              setListIndex(-1);
              setSuggestionBox(true);
              var searchResult = suggestionList.filter((user) => {
                return user.name
                  .toLowerCase()
                  .includes(e.target.value.trim().toLowerCase());
              });
              setSuggestions(searchResult);
            }
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSearch(e.target.value);
            } else if (e.key === "ArrowDown") {
              const listLength =
                suggestions.length > 15 ? 15 : suggestions.length;
              if (listIndex < listLength - 1) {
                setListIndex(listIndex + 1);
              } else if (listIndex === listLength - 1) {
                setListIndex(-1);
                setSearchText(actualsearch);
              }
            } else if (e.key === "ArrowUp") {
              e.preventDefault();
              const listLength =
                suggestions.length > 15 ? 15 : suggestions.length;
              if (listIndex === 0) {
                setListIndex(-1);
                setSearchText(actualsearch);
              } else if (listIndex === -1) {
                setListIndex(listLength - 1);
              } else {
                setListIndex(listIndex - 1);
              }
            }
          }}
          onBlur={() => setSuggestionBox(false)}
        />

        {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
          <div className="suggestion-box">
            <ul id="suggestion-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onMouseDown={() => handleSearch(suggestion.name)}
                  className={index === listIndex ? "selected-active" : ""}
                >
                  {searchText !== null &&
                    searchText.length > 0 &&
                    suggestion.name
                      .toLowerCase()
                      .startsWith(searchText.toLowerCase()) ? (
                    <>
                      <b>{suggestion.name.slice(0, searchText.length)}</b>
                      {suggestion.name &&
                        suggestion.name.slice(
                          searchText.length,
                          suggestion.name.length - 1
                        )}{" "}
                    </>
                  ) : (
                    suggestion.name
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        <i
          className="fa fa-search"
          onClick={() => handleSearch(searchText)}
        ></i>
      </div>
      <div className="uic-fl-btn sort-by-mn">
        <div className="sort-by-mn-title">Filter by : </div>
        <div className="cstm-select-ap">
          <select value={filter} onChange={handleStatusChange}>
            <option value="all">All Review</option>
            <option value="verified">Approved</option>
            <option value="pending">Pending</option>
          </select>
        </div>
      </div>
      <ReviewTable
        partnername={partnername}
        dataLoader={dataLoader}
        rows={rows}
        recallApi={reviewListByPartnerApi}
        handleSort={handleSort}
        filter={filter}
        columns={columns}
      />
    </>
  );
};

export default PartnerReview;
