import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import auth0 from "auth0-js";
import WelcomeUser from "../Dashboard";
// Import Images
import logo from "../../../Images/logo_mds_small.svg";
import nonaccess from "../../../Images/nonaccess.svg";
import Somethingwentwrong from "../../../Images/Somethingwentwrong.png";
// Import Api integration file;
import Services from "../../../Services/auth.service";
import FrontendNewsManagement from "../NewsManagement";
import { ReactComponent as FbIcon } from '../../../Images/fb-icon.svg';
import { ReactComponent as AppleIcon } from '../../../Images/apple-icon.svg';


const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [err, setErr] = useState("");
  const [errPopUp, setErrPopUp] = useState(false);
  const [isUser, setIsUser] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  useEffect(() => {
    if (
      localStorage.getItem("idtoken") !== null &&
      localStorage.getItem("idtoken") !== undefined &&
      localStorage.getItem("idtoken").length > 0 &&
      localStorage.getItem("userid") !== null &&
      localStorage.getItem("userid") !== undefined &&
      localStorage.getItem("userid").length > 0 &&
      localStorage.getItem("username") !== null &&
      localStorage.getItem("username") !== undefined &&
      localStorage.getItem("username").length > 0 &&
      localStorage.getItem("role") !== null &&
      localStorage.getItem("role") === "user" &&
      localStorage.getItem("logintime") !== undefined &&
      localStorage.getItem("logintime") !== null
    ) {
      const pathname = window.location.href;
      const queryParams = pathname.slice(pathname.indexOf("?") + 1);
      console.log(queryParams, "path slice");
      console.log(queryParams.split("&"), "split");
      const paramsData = queryParams.split("&");

      if (paramsData[0].includes("redirectto")) {
        console.log("Redirect To Calling");
        switch (true) {
          case paramsData[0] === "redirectto=video":
            const passData = {
              videoId: paramsData[1].slice(paramsData[1].indexOf("=") + 1),
            };
            console.log(passData, "passdata");
            Services.notAccessScreen(passData)
              .then((response) => {
                console.log(response.data.access, "response");
                if (!response.data.access) {
                  console.log("response False");
                  navigate("/notAccessScreen?type=videopage");
                } else {
                  console.log("response True");
                  navigate(
                    `/videos/${paramsData[1].slice(
                      paramsData[1].indexOf("=") + 1
                    )}`
                  );
                }
              })
              .catch((error) => {
                console.error(error);
              });

            break;


          case paramsData[0] === "redirectto=news":
            navigate(
              `/frontnews/${paramsData[1].slice(
                paramsData[1].indexOf("=") + 1
              )}`
            );
            break;
          case paramsData[0] === "redirectto=partner":
            navigate(
              `/partners/all-partners/${paramsData[1].slice(
                paramsData[1].indexOf("=") + 1
              )}`
            );
            break;
          // for redirect to document url
          case paramsData[0] === "redirectto=document":
            console.log("document url ", paramsData[1])
            navigate(
              `/documents?type=documents&&doc=${paramsData[1].slice(
                paramsData[1].indexOf("=") + 1
              )}`
            );
            break;
          case paramsData[0] === "redirectto=chatMessage":
            const passParam = {
              chatId: paramsData[1].slice(paramsData[1].indexOf("=") + 1),
              type: paramsData[4].slice(paramsData[4].indexOf("=") + 1),
            };

            Services.notChatAccessScreen(passParam)
              .then((response) => {
                console.log(response.data, "response");
                if (!response.data.access) {
                  navigate(
                    `/notAccessScreen?type=${paramsData[4].slice(
                      paramsData[4].indexOf("=") + 1
                    )}`
                  );
                } else {
                  navigate(
                    `/frontendchat/${paramsData[1].slice(
                      paramsData[1].indexOf("=") + 1
                    )}/${paramsData[4].slice(paramsData[4].indexOf("=") + 1)}` +
                    "?user_name=" +
                    paramsData[2].slice(paramsData[2].indexOf("=") + 1) +
                    "&" +
                    "user_image=" +
                    paramsData[3].slice(paramsData[3].indexOf("=") + 1)
                  );
                }
              })
              .catch((error) => {
                console.error(error);
              });

            break;
          default:
          // code block
        }
      } else {
        window.location.href = "/homepage";
      }
    } else {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state.err.length > 0
      ) {
        setErr(location.state.err);
      }
      if (location?.state?.socialEmailDBExists) {
        setSubmitted(true)
      }
      if (location?.state?.socialEmailDBExists === false) {
        setIsUser(true)
      }

    }
    console.log("login page calling");
  }, []);


  return localStorage.getItem("idtoken") !== null &&
    localStorage.getItem("idtoken") !== undefined &&
    localStorage.getItem("idtoken").length > 0 &&
    localStorage.getItem("userid") !== null &&
    localStorage.getItem("userid") !== undefined &&
    localStorage.getItem("userid").length > 0 &&
    localStorage.getItem("username") !== null &&
    localStorage.getItem("username") !== undefined &&
    localStorage.getItem("username").length > 0 &&
    localStorage.getItem("role") !== null &&
    localStorage.getItem("role") === "user" &&
    localStorage.getItem("logintime") !== undefined &&
    localStorage.getItem("logintime") !== null ? (
    window.location.hostname === process.env.REACT_APP_TESTSITE_HOSTNAME ? (
      <FrontendNewsManagement />
    ) : (
      <WelcomeUser />
    )
  ) : (
    <>
      <div className="um-main cstm-um-main new-login-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 cstm-um-right-2-1">
              <div className="new-login-box-wrapper">
                <div className="header-collaborator header-spacing">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>
                <div className="cstm-simplity">
                  <div className="cstm-sim-title text-center">Welcome back!</div>
                  <div className="cstm-apple-btn">
                    <button
                      className="btn-facebook new-login-page-btn login-btn-facebook rounded-pill"
                      onClick={() => {
                        var options = {
                          connection: "facebook",
                        };
                        var auth_soc = new auth0.WebAuth({
                          domain: process.env.REACT_APP_AUTH0_DOMAIN,
                          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                          responseType: "token id_token",
                          redirectUri:
                            window.location.origin + "/postauthenticate",
                          scope: "openid",
                        });

                        auth_soc.authorize(options, function (err, authResult) {
                          if (err) {
                            setMainErr(err);
                            console.log(err, " error");
                          } else {
                          }
                          console.log(authResult, "*********");
                        });
                      }}
                    >
                      <FbIcon /> Log in with Facebook
                    </button>
                  </div>
                  <div className="cstm-apple-btn">
                    <button
                      className="apple-btn new-login-page-btn rounded-pill"
                      onClick={() => {
                        var options = {
                          connection: "apple",
                        };
                        var auth_soc = new auth0.WebAuth({
                          domain: process.env.REACT_APP_AUTH0_DOMAIN,
                          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                          responseType: "token id_token",
                          redirectUri:
                            window.location.origin + "/postauthenticate",
                          scope: "openid",
                        });

                        auth_soc.authorize(options, function (err, authResult) {
                          if (err) {
                            setMainErr(err);
                            console.log(err, " error");
                          } else {
                          }
                          console.log(authResult, "*********");
                        });
                      }}
                    >
                      <AppleIcon /> Log in with Apple
                    </button>
                  </div>

                  <div className="cstm-register-now text-center">
                    Not a member? <a
                      href="https://www.milliondollarsellers.com/apply-step-1"
                    >
                      Apply now
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {errPopUp && (
        <div className="desk-globle-model-box globle-model-box cstm-login-screen-popup">
          <div className="desk-globle-model-box-inner">
            <span className="cstm-sww-close" onClick={() => setErrPopUp(false)}>
              ✖
            </span>
            <div className="modal-content">
              <span className="cstm-somr-icom">
                <img src={nonaccess} />
              </span>
              <h2>
                Something went wrong
                <img className="something-icon" src={Somethingwentwrong} />
              </h2>
              <p>
                <b>Did you follow these steps?</b>
              </p>
              <p>
                1. Clicked the verification link in your email
                <br />
                2. Entered the 6-digit confirmation code
                <br />
                3. Connected your Facebook account
              </p>
              <p>
                If you've completed all these steps and are still facing issues,
                please contact us.
              </p>
              <div className="cstm-try-contact-btn">
                <button class="btn2" onClick={() => setErrPopUp(false)}>
                  Try again
                </button>
                <button class="btn4">Contact support</button>
              </div>
            </div>
          </div>
        </div>
      )}



    </>
  );
};
export default Login;
