import React, { useEffect, useState } from "react";
import { useDeleteMainTicketMutation } from "../../../Services/mainTicket";
import ModalPopup from "../../ModalPopup/ModalPopup";

const DeleteMainTicket = ({
  rows,
  deleteId,
  showDeleteModal,
  setShowDeleteModal,
  setDeleteId,
  setShowAlert,
  setAlertMessage,
}) => {
  const [deleteLoader, setDeleteLoader] = useState(false);
  //delete main ticket
  const [deleteMainTicket] = useDeleteMainTicketMutation();
  //delete main ticket api call
  const handleDelete = async () => {
    setDeleteLoader(true);
    try {
      const deleteMainTicketResponse = await deleteMainTicket({ id: deleteId });
      if (deleteMainTicketResponse.data.status) {
        setDeleteId("");
        setShowDeleteModal(false);
        setShowAlert(true);
        setAlertMessage(deleteMainTicketResponse?.data?.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 4000);
        setDeleteLoader(false);
      }
    } catch (err) {
      console.log("Error in handle delete:", err);
    }
  };

  //closing the modal while deleting
  const handleDeleteModalCloseEvent = () => {
    setShowDeleteModal(false);
    setDeleteId("");
  };

  //get the name of the main ticket while deleting
  const deleteMainTicketName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]._id === deleteId) {
        return rows[i].name;
      }
    }
  };

  //closing guest ticket and delete modal while pressing esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && showDeleteModal) {
        handleDeleteModalCloseEvent();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleDeleteModalCloseEvent, showDeleteModal]);

  return (
    <>
      <ModalPopup
        onClose={handleDeleteModalCloseEvent}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant="danger"
        buttonText1="Delete"
        isOpen={true}
        className=""
        handleClick2ndButton={handleDelete}
        hasLoading={deleteLoader}
        headingLable={"Delete main ticket"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete "{deleteMainTicketName(deleteId)}"
            ?This action cannot be undone.
          </p>
        </div>
      </ModalPopup>
    </>
  );
};

export default DeleteMainTicket;
