import CryptoJS from 'crypto-js';

const secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3';
const decrypt = (encrypted) => {

    const cipher = CryptoJS.AES.decrypt(encrypted, secretKey);
    const decrypted = cipher.toString(CryptoJS.enc.Utf8);

    return decrypted
};


export default decrypt;
