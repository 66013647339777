import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchsearchpost } from "../../../Action/index";
import authService from "../../../Services/auth.service";
import Star from "../../../Images/star.svg";
import Gruopicon from "../../../Images/gruop-icon.svg";
import Image1 from "../../../Images/image1.png";
import LoaderComponent from "../LoaderComponent";

import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import CloseFillIcon from "remixicon-react/CloseFillIcon";

const GlobleSearchLeftsidebar = ({ setdis_hum }) => {
  const history = useNavigate();
  const search = useLocation().search;
  const text_search = new URLSearchParams(search).get("text");
  const dispatch = useDispatch();
  const [friendlist, setfriendlist] = useState([]);
  const [accessible_topics, setaccessible_topics] = useState([]);
  const [sidebarloader, setsidebarloader] = useState({
    topic: false,
    friends: false,
  });
  useEffect(() => {
    setsidebarloader({ topic: true, friends: true });
    authService
      .getallaccessibletopics()
      .then((res) => {
        if (res.data.status) {
          setaccessible_topics(res.data.data);
        }
        setsidebarloader({ ...sidebarloader, topic: false });
      })
      .catch((e) => {
        console.log(e);
        setsidebarloader({ ...sidebarloader, topic: false });
      });
    authService
      .getfriendlistglobalsearch()
      .then((res) => {
        if (res.data.status) {
          setfriendlist(res.data.data);
        }
        setsidebarloader({ ...sidebarloader, friends: false });
      })
      .catch((e) => {
        console.log(e);
        setsidebarloader({ ...sidebarloader, friends: false });
      });
  }, []);
  return (
    <>
      <div className="all-content-main-left-sidebar global-left-side">
        {window.location.hostname !==
        process.env.REACT_APP_TESTSITE_HOSTNAME ? (
          <>
            <div className="card">
              <div className="card-header" id="headingOne">
                <button
                  type="button"
                  className="btn-link global-das"
                  onClick={() => {
                    history("/dashboard");
                  }}
                >
                  <ArrowLeftLineIcon size="16px" />
                  Back to dashboard
                </button>

                <div className="global-result">
                  <div className="global-result-inner">
                    <span>Search Results By</span>
                    <p>{text_search}</p>
                  </div>
                  <div className="global-result-buttom">
                    <button>
                      <CloseFillIcon
                        onClick={() => {
                          setdis_hum(false);
                          document.body.classList.remove(
                            "global-search-active"
                          );
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          ""
        )}
        <div className="card">
          <div className="card-header" id="headingtwo2">
            <button type="button" className="btn-link">
              <img src={Star} />
              Topics
            </button>
          </div>
          <div>
            <div className="cstm-starred-group">
              {sidebarloader.topic ? (
                <div className="loadind-post">
                  <LoaderComponent />
                </div>
              ) : (
                <ul>
                  {accessible_topics !== undefined &&
                  accessible_topics !== null &&
                  accessible_topics.length > 0 ? (
                    accessible_topics.map((topics) => {
                      return (
                        <>
                          <li className="cstm-topic-star-listing">
                            <div className="cstm-sg-ttl-name">
                              <a
                                className="cstm-sg-ttl-main"
                                onClick={() => {
                                  dispatch(
                                    fetchsearchpost({
                                      text: text_search,
                                      topic: topics.topic_id,
                                    })
                                  );
                                  history(
                                    "/globlesearch?text=" +
                                      text_search +
                                      "&topic=" +
                                      topics.topic_id
                                  );
                                }}
                              >
                                {topics.topic_name}
                              </a>
                            </div>
                          </li>
                        </>
                      );
                    })
                  ) : accessible_topics !== undefined &&
                    accessible_topics !== null &&
                    accessible_topics.length === 0 ? (
                    <span className="emptymsg">No Data Found</span>
                  ) : (
                    ""
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingthree">
            <button type="button" className="btn-link">
              <img src={Gruopicon} />
              Friends{" "}
            </button>
          </div>
          <div>
            <div className="cstm-starred-group">
              {sidebarloader.friends ? (
                <div className="loadind-post">
                  <LoaderComponent />
                </div>
              ) : friendlist !== undefined &&
                friendlist !== null &&
                friendlist.length > 0 ? (
                <ul>
                  {friendlist.map((frnd) => {
                    return (
                      <li className="cstm-statte-grup-main">
                        <div className="cstm-sg-list">
                          <img
                            className="sctm-pt-mn"
                            src={
                              frnd.profileImg !== undefined &&
                              frnd.profileImg !== null &&
                              frnd.profileImg.length > 0
                                ? frnd.profileImg
                                : Image1
                            }
                          />
                        </div>
                        <div className="cstm-sg-ttl-name">
                          <a
                            className="cstm-sg-ttl-main"
                            onClick={() => {
                              dispatch(
                                fetchsearchpost({
                                  text: text_search,
                                  user: frnd.user_id,
                                })
                              );
                              history(
                                "/globlesearch?text=" +
                                  text_search +
                                  "&user=" +
                                  frnd.user_id
                              );
                            }}
                          >
                            {frnd.otherdetail[
                              process.env.REACT_APP_FIRSTNAME_DBID
                            ] +
                              " " +
                              frnd.otherdetail[
                                process.env.REACT_APP_LASTNAME_DBID
                              ]}
                          </a>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : friendlist !== undefined &&
                friendlist !== null &&
                !sidebarloader.friends &&
                friendlist.length === 0 ? (
                <span className="emptymsg">No Data Found</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GlobleSearchLeftsidebar;
