const AllPostImgView = ({post, postimgvid, setpost_allimg, setallimg}) => {
    return (
        postimgvid.length > 0 ? (
            postimgvid.length === 1 ? (
              postimgvid[0].indexOf("image") !== -1 ? (
                <div
                  className="create-pp-post-img1 cstm-create-post-img1"
                  onClick={() => {
                    setpost_allimg(post._id);
                    setallimg(postimgvid);
                  }}
                  data-toggle="modal"
                  data-target="#allimgs"
                >
                  <img
                    src={
                      process.env.REACT_APP_AWS_IMG_VID_PATH +
                      postimgvid[0]
                    }
                  />
                </div>
              ) : (
                <div
                  className="create-pp-post-img1 cstm-create-post-video"
                  onClick={() => {
                    setpost_allimg(post._id);
                    setallimg(postimgvid);
                  }}
                  data-toggle="modal"
                  data-target="#allimgs"
                >
                  <video
                    src={
                      process.env.REACT_APP_AWS_IMG_VID_PATH +
                      postimgvid[0]
                    }
                    controls={true}
                  />
                </div>
              )
            ) : postimgvid.length === 2 ? (
              <div className="create-pp-post-img2">
                <div
                  className="create-pp-post-img2-in"
                  onClick={() => {
                    setpost_allimg(post._id);
                    setallimg(postimgvid);
                  }}
                  data-toggle="modal"
                  data-target="#allimgs"
                >
                  {postimgvid[0].indexOf("image") !== -1 ? (
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH +
                        postimgvid[0]
                      }
                    />
                  ) : (
                    <video
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH +
                        postimgvid[0]
                      }
                      controls={true}
                    ></video>
                  )}
                </div>
                <div
                  className="create-pp-post-img2-in"
                  onClick={() => {
                    setpost_allimg(post._id);
                    setallimg(postimgvid);
                  }}
                  data-toggle="modal"
                  data-target="#allimgs"
                >
                  {postimgvid[1].indexOf("image") !== -1 ? (
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH +
                        postimgvid[1]
                      }
                    />
                  ) : (
                    <video
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH +
                        postimgvid[1]
                      }
                      controls={true}
                    ></video>
                  )}
                </div>
              </div>
            ) : (
              <div className="create-pp-post-img3">
                <div
                  className="create-pp-post-img3-left"
                  onClick={() => {
                    setpost_allimg(post._id);
                    setallimg(postimgvid);
                  }}
                  data-toggle="modal"
                  data-target="#allimgs"
                >
                  {postimgvid[0].indexOf("image") !== -1 ? (
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH +
                        postimgvid[0]
                      }
                    />
                  ) : (
                    <video
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH +
                        postimgvid[0]
                      }
                      controls={true}
                    />
                  )}
                </div>
                <div className="create-pp-post-img3-right">
                  <div
                    className="create-pp-post-img3-right-in"
                    onClick={() => {
                      setpost_allimg(post._id);
                      setallimg(postimgvid);
                    }}
                    data-toggle="modal"
                    data-target="#allimgs"
                  >
                    {postimgvid[1].indexOf("image") !== -1 ? (
                      <img
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          postimgvid[1]
                        }
                      />
                    ) : (
                      <video
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          postimgvid[1]
                        }
                        controls={true}
                      />
                    )}
                  </div>
                  <div
                    className="create-pp-post-img3-right-in"
                    onClick={() => {
                      setpost_allimg(post._id);
                      setallimg(postimgvid);
                    }}
                    data-toggle="modal"
                    data-target="#allimgs"
                  >
                    {postimgvid[2].indexOf("image") !== -1 ? (
                      <img
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          postimgvid[2]
                        }
                      />
                    ) : (
                      <video
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          postimgvid[2]
                        }
                        controls={true}
                      />
                    )}
                    {postimgvid.length > 3 ? (
                      <div
                        className="create-pp-cunt"
                        onClick={() => {
                          setpost_allimg(post._id);
                          setallimg(postimgvid);
                        }}
                        data-toggle="modal"
                        data-target="#allimgs"
                      >
                        {postimgvid.length - 3} +
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )
          ) : (
            <></>
          )
    )
}
export default AllPostImgView;