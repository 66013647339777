import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
// Import Api integration file
import Services from "../../../Services/auth.service";

// Import components
import DisplayCustomRegistrationForm from "./DisplayCustomRegistrationForm";
import ReviewConfirm from "./ReviewConfirm";
import PreferredEmail from "./PreferredEmail";

// Import Images
import logo from "../../../Images/logo.png";
import arrowleft from "../../../Images/arrow-left.png";

const Register = ({ setuserDetail }) => {
  const history = useNavigate()
  const redu_register = useSelector((state) => {
    return state.ReduRegistrationDetail;
  })
  const [formstep, setformStep] = useState(1);
  const [formFields, setFormFields] = useState([]);
  const [otherUserDetail, setotherUserDetail] = useState({
    preferredEmail: "",
  });
  const [otherDetailErr, setotherDetailErr] = useState({});
  const [err, seterr] = useState("");
  // call api to get registration form fields from database
  useEffect(() => {
    if(redu_register.register_status === undefined || !redu_register.register_status || redu_register.personalDetail_status === undefined || redu_register.personalDetail_status)
    {
      history("/")
    } else {
      Services.retrivefieldsregistrationform()
      .then(function (response) {
        if (response.data.success === true) setFormFields(response.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
    }
    
  }, []);
  // useEffect called on formFields change
  useEffect(() => {
    <DisplayCustomRegistrationForm formFields={formFields} />;

    var err = {};
    var fld = {};
    formFields.length > 0
      ? formFields.map((f) => {
          f.fields.map((fields) => {
            err = { ...err, [fields._id]: "" };
            fld =
              fields.type === "checkbox"
                ? { ...fld, [fields._id]: [] }
                : fields.type === "file"
                ? { ...fld, [fields._id]: {} }
                : { ...fld, [fields._id]: "" };
          });
        })
      : setotherDetailErr({});
    setotherDetailErr(err);
    setotherUserDetail({ ...otherUserDetail, ...fld ,  preferredEmail: redu_register.emailid, [process.env.REACT_APP_EMAIL_FIELD_DBID]: redu_register.emailid});
  }, [formFields]);
console.log(otherUserDetail) 
  // called on any input field change event
  const handleChangeDBfields = (e, required, min, max, label) => {
    if (e.target.type === "checkbox") {
      var arr = otherUserDetail[e.target.name];
      var err_obj = { ...otherDetailErr };
      if (e.target.checked) {
        if (arr.indexOf(e.target.value) === -1) arr.push(e.target.value);
      } else {
        arr.splice(arr.indexOf(e.target.value), 1);
      }
      setotherUserDetail({ ...otherUserDetail, [e.target.name]: arr });
      err_obj = {
        ...err_obj,
        [e.target.name]: required
          ? arr.length > 0
            ? min > 0 && max > min
              ? arr.length >= min && arr.length <= max
                ? ""
                : "Must contain minimum " + min + "and maximum " + max 
              : min > 0
              ? arr.length >= min
                ? ""
                : "Must contain minimum " + min 
              : max > 0
              ? arr.length <= max
                ? ""
                : "Must be less then " + max 
              : ""
            : "Select " + label.toLowerCase() 
          : min > 0 && max > min
          ? arr.length >= min && arr.length <= max
            ? ""
            : "Must contain minimum " + min + "and maximum " + max 
          : min > 0
          ? arr.length >= min
            ? ""
            : "Must contain minimum " + min 
          : max > 0
          ? arr.length <= max
            ? ""
            : "Must be less then " + max 
          : "",
      };
      setotherDetailErr({ ...otherDetailErr, ...err_obj });
    } else if (e.target.type === "file") {
      setotherUserDetail({
        ...otherUserDetail,
        [e.target.name]: e.target.files[0],
      });
      err_obj = {
        ...err_obj,
        [e.target.name]: required
          ? typeof e.target.files[0] !== "object"
            ? "Select " + label.toLowerCase() 
            : ""
          : "",
      };
      setotherDetailErr({ ...otherDetailErr, ...err_obj });
    } else {
      var err_obj = { ...otherDetailErr };
      if(e.target.type === "number") {
        setotherUserDetail({
          ...otherUserDetail,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, ''),
        });
      } else {
        setotherUserDetail({
          ...otherUserDetail,
          [e.target.name]: e.target.value,
        });
      }
       if (e.target.type === "email") {
        err_obj = {
          ...err_obj,
          [e.target.name]: required
            ? e.target.value.length > 0
              ? e.target.value
                  .toLowerCase()
                  .match(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) === null
                ? "Invalid email format"
                : ""
              : "Enter " + label.toLowerCase() 
            : e.target.value.length > 0
            && e.target.value
                .toLowerCase()
                .match(
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) === null
              ? "Invalid email format": "",
        };
      } else {
        err_obj = {
          ...err_obj,
          [e.target.name]: required
            ? e.target.value.length > 0
              ? min > 0 && max > min
                ? e.target.value.length >= min && e.target.value.length <= max
                  ? ""
                  : "Must contain minimum " + min + "and maximum " + max 
                : min > 0
                ? e.target.value.length >= min
                  ? ""
                  : "Must contain minimum " + min 
                : max > 0
                ? e.target.value.length <= max
                  ? ""
                  : "Must be less then " + max 
                : ""
              : "Enter " + label.toLowerCase() 
            : min > 0 && max > min
            ? e.target.value.length >= min && e.target.value.length <= max
              ? ""
              : "Must contain minimum " + min + "and maximum " + max 
            : min > 0
            ? e.target.value.length >= min
              ? ""
              : "Must contain minimum " + min
            : max > 0
            ? e.target.value.length <= max
              ? ""
              : "Must be less then " + max
            : "",
        };
      }
      setotherDetailErr({ ...otherDetailErr, ...err_obj });
    }
  };
  // called on click on next button
  const handleSubmit = (e) => {
    e.preventDefault();
    var err_exist = false;
    var err_obj = { ...otherDetailErr };
    Object.keys(otherDetailErr).map((field) => {
      if (otherDetailErr[field].length > 0) err_exist = true;
    });

    if (!err_exist) {
      var sample =
        formFields.length > 0
          ? formFields.map((field_arr) => {
              field_arr.fields.map((f) => {

                if (f.type !== "file") {
                
                    if (
                      ( f.required.length > 0 && otherUserDetail[f._id] !== undefined && otherUserDetail[f._id] !== null &&
                      otherUserDetail[f._id].length < 1) || (f.required.length > 0 && (otherUserDetail[f._id] === undefined || otherUserDetail[f._id] === null))
                    ) {
                      err_obj = {
                        ...err_obj,
                        [f._id]: "Enter " + f.label.toLowerCase() + "!",
                      };
                      setotherDetailErr({ ...otherDetailErr, ...err_obj });
                      err_exist = true;
                    } else {
                      err_obj = { ...err_obj, [f._id]: "" };
                      setotherDetailErr({ ...otherDetailErr, ...err_obj });
                    }
                } else {
                  if (
                    f.required.length > 0 &&
                    typeof otherUserDetail[f._id] !== "object"
                  ) {
                    err_obj = {
                      ...err_obj,
                      [f._id]: "Select " + f.label.toLowerCase() + "!",
                    };
                    setotherDetailErr({ ...otherDetailErr, ...err_obj });
                    err_exist = true;
                  } else {
                    err_obj = { ...err_obj, [f._id]: "" };
                    setotherDetailErr({ ...otherDetailErr, ...err_obj });
                  }
                }
              });
            })
          : "";
    }

    if (!err_exist) {
      setuserDetail({...otherUserDetail,preferredEmail: otherUserDetail[process.env.REACT_APP_EMAIL_FIELD_DBID]});
      setotherUserDetail({...otherUserDetail,preferredEmail: otherUserDetail[process.env.REACT_APP_EMAIL_FIELD_DBID]})
      setformStep(2);
    }
  };
  //  step wise displaying registration form
  switch (formstep) {
    case 1:
      return (
        <>
          <div
            style={formstep === 1 ? { display: "block" } : { display: "none" }}
          >
            <div className="um-main">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6 um-left">
                    <div className="um-left-in">
                      <div className="um-logo">
                        <Link to="/">
                          <img src={logo} />
                        </Link>
                      </div>

                      <div className="um-left-in-mn">
                        <div className="um-title2">Registration</div>
                        <div className="um-text">
                          Please provide the best details to personally reach
                          you. This is used for event invites and other
                          important updates.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 um-right">
                    <div className="um-right-in2">
                      <div className="um-back">
                        <button
                          className="back-btn"
                          onClick={() => {
                            if(redu_register.backpage !== undefined && redu_register.backpage === "preemail")
                            {
                              history("/firststepregister/2");
                            } else {
                              history("/");
                            }
                          }}
                        >
                          <img src={arrowleft} /> Back
                        </button>
                      </div>
                      <div className="um-right-in-title">Personal Details</div>
                      <form
                        method="post"
                        enctype="multipart/form-data"
                        onSubmit={handleSubmit}
                      >
                        <span id="err" classNameName="error">
                          {err}
                        </span>
                        {formFields.length > 0
                          ? formFields.map((field) => {
                            if(field._id !== process.env.REACT_APP_FIRST_LAST_NAME_GRID_DBID && field._id !== process.env.REACT_APP_EMAIL_GRID_DBID)
                              return (
                                <DisplayCustomRegistrationForm
                                  field={field}
                                  handleChangeDBfields={handleChangeDBfields}
                                  otherDetailErr={otherDetailErr}
                                  otherUserDetail={otherUserDetail}
                                />
                              );
                            })
                          : ""}
                        <div className="um-rgt-btn">
                          <button
                            className="btn3"
                            onClick={() => {
                              if(redu_register.backpage !== undefined && redu_register.backpage === "preemail")
                              {
                                history("/firststepregister/2");
                              } else {
                                history("/");
                              }
                            }}
                          >
                            Cancel
                          </button>
                          <button className="btn2" type="submit">
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );

    case 2:
      return (
        <ReviewConfirm
          otherUserDetail={otherUserDetail}
          formFields={formFields}
          setformStep={setformStep}
          // setStep={setStep}
          // selectedplan={selectedplan}
        />
      );
    default:
      break;
  }
};

export default Register;