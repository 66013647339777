import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import authServicePartner from '../../../../Services/auth.service.partner';
import Loader from "../../../../Images/loader.gif";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../../../Images/exportimg.svg"
import ExportSelect from './ExportSelect';
import moment from 'moment';

const ViewsModal = ({ setViewModalOpen, claimId, filterType, fromDate, toDate }) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    function viewUsersCreateData(id, name, viewCount, email, lastDate) {
        return { id, name, viewCount, email, lastDate };
    }

    const userColumns = [
        { id: "name", label: "User Name" },
        { id: "viewCount", label: "Views" },
        { id: "email", label: "Email" },
        { id: "lastDate", label: "Last View Date" },
    ];

    const [userRows, setUserRow] = useState([]);
    const [viewUsers, setViewUsers] = useState([]);
    const [userViewLoader, setUserViewLoader] = useState(false);
    const [userPage, setUserPage] = useState(0);
    const [userRowsPerPage, setUserRowsPerPage] = useState(20);
    const [selected, setSelected] = useState([]); // Selected rows
    const [selectRow, setSelectRow] = useState([]);
    const [selectError, setSelectError] = useState('');
    const [error, setError] = useState(false);

    // first render when component is mount
    useEffect(() => {
        setUserViewLoader(true);
        handleViewUsers();
    }, []);

    // Get AllPartnerView Users List Api calling function
    const handleViewUsers = () => {
        console.log("filterType", filterType);
        authServicePartner.getAllPartnerViewUsersList(`${claimId}?field=pageView&dateFilterType=${filterType !== undefined ? filterType : ""}${filterType !== undefined && filterType === "custom" ? `&fromdate=${fromDate}&todate=${toDate}` : ""}`).then((res) => {
            if (res.data.status) {
                const viewUserList = res.data.data ? res.data.data.userViews.length > 0 ? res.data.data.userViews : [] : [];
                if (viewUserList.length > 0) {
                    let arr = [];
                    viewUserList.forEach((viewUser) => {
                        arr.push(viewUsersCreateData(viewUser.userId, `${viewUser.userData[0].display_name ? viewUser.userData[0].display_name  : "" }`, viewUser.viewCount, viewUser.userData[0].email, viewUser.lastViewClickDate));
                    });
                    setViewUsers(arr);
                    setUserRow(arr);
                }
            }
            setUserViewLoader(false);
        }).catch((e) => {
            setUserViewLoader(false);
            return false;
        });
    };

    // Pagination onChange function
    const handleChangeUserPage = (event, newPage) => {
        setUserPage(newPage);
    };

    // Table data shorting function
    const handleSort = (col, direc) => {
        const sortedResult = [...viewUsers];
        if (direc === "asc") {
            sortedResult.sort((firstItem, secondItem) => {
                const itemA = firstItem[col];
                const itemB = secondItem[col];
                return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
            });
        } else if (direc === "desc") {
            sortedResult.sort((firstItem, secondItem) => {
                const itemA = firstItem[col];
                const itemB = secondItem[col];
                return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
            });
        }

        setViewUsers(sortedResult);
        const table = sortedResult.map((item) => viewUsersCreateData(item.id, item.name, item.viewCount, item.email));
        setUserRow(table);
    };

    // Export for download xlsx file
    const handleExport = () => {
        const workbook_ = XLSX.utils.book_new(); // create new workbook
        if (selectRow.length > 0) {
            const modifiedClaimData = selectRow.map((item) => ({
                name: item.name,
                viewCount: item.viewCount,
                email: item.email,
                lastviewdate: moment(item.lastDate).format('MMM DD, YYYY')
            }));
            const convertToexcel = XLSX.utils.json_to_sheet(modifiedClaimData); // convert modified JSON data to sheet

            XLSX.utils.book_append_sheet(workbook_, convertToexcel, "Sheet2"); // add the sheet to the workbook
            const downloadExcel = XLSX.write(workbook_, {
                bookType: "xlsx",
                type: "array",
            });
            const dataExcel = new Blob([downloadExcel], { type: fileType });

            FileSaver.saveAs(dataExcel, "partnerview.xlsx");
        } else {
            setError(true);
            setSelectError("Please select checkbox from column")
        }
    };
    return (
        <>
            <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
                <div className="desk-globle-model-box-inner">
                    <div className="modal-content">
                        <div class="cstm-replace-pp">
                            <span class="cstm-rpp-close-btn" onClick={() => { setViewModalOpen(false); document.body.classList.remove("modal-open"); }}>×</span>
                            <div class="cstm-rpp-title">Total number of Partner Views based on Users</div>
                            <div className="cstm-export-link">
                                <a onClick={handleExport}> <img src={exportImg} />Export</a>
                            </div>
                            {
                                error ? <div className="export-error">{selectError}</div> : ""
                            }
                            <div className="cstm-cstm-viewlist">
                                <Paper className="uic-tb-mn ">
                                    <TableContainer className=" uic-table-main ">
                                        <Table className="" >
                                            <TableHead className="uic-table-th">
                                                <TableRow>
                                                    <TableCell className="uic-th-td" padding="checkbox">
                                                        <Checkbox className='cstm-ls-checkbox'
                                                            color="primary"
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    const newSelecteds = userRows.map((row) => row.id);
                                                                    const newSelecteds1 = userRows.map((row) => row);
                                                                    setSelected(newSelecteds)
                                                                    setSelectRow(newSelecteds1)
                                                                    setSelectError("")
                                                                } else {
                                                                    setSelected([]);
                                                                    setSelectRow([]);
                                                                    setError(true);
                                                                    setSelectError("Please select checkbox from column")
                                                                }
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'select all desserts',
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {userColumns.map((column) => (
                                                        <TableCell
                                                            className="uic-th-td"
                                                            key={column.id}
                                                        >
                                                            <span className="custom-sorting">
                                                                <i
                                                                    className="fa fa-angle-down"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "asc");
                                                                    }}
                                                                ></i>
                                                                <i
                                                                    className="fa fa-angle-up"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "desc");
                                                                    }}
                                                                ></i>
                                                            </span>
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="uic-table-td">
                                                {userViewLoader ? (
                                                    <div className="cstm-data-load">
                                                        <img src={Loader} />
                                                    </div>
                                                ) : userRows.length > 0 ? (
                                                    userRows
                                                        .slice(userPage * userRowsPerPage, userPage * userRowsPerPage + userRowsPerPage)
                                                        .map((row, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                >
                                                                    <TableCell className='uic-th-td' padding="checkbox">
                                                                        <ExportSelect
                                                                            id={row.id}
                                                                            row={row}
                                                                            selected={selected}
                                                                            setSelected={setSelected}
                                                                            selectRow={selectRow}
                                                                            setSelectRow={setSelectRow}
                                                                            setSelectError={setSelectError}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className='uic-th-td'>{row.name}</TableCell>
                                                                    <TableCell className='uic-th-td'>{row.viewCount}</TableCell>
                                                                    <TableCell className='uic-th-td'>{row.email}</TableCell>
                                                                    <TableCell className='uic-th-td'>{moment(row.lastDate).format('MMM DD, YYYY')}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                ) : (
                                                    <span className="no-data-found-pl">No data found</span>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className="uic-pagination"
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={userRows.length}
                                        rowsPerPage={userRowsPerPage}
                                        page={userPage}
                                        onPageChange={handleChangeUserPage}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewsModal;
