import { useState } from "react";
import AnnouncementList from "./AnnouncementList";
import SidebarFilesList from "./SidebarFilesList";
import SavePostList from "./SavePostList";
import EventsList from "./EventsList";
import ForumList from "./ForumList";
import SaveVideosList from "./SaveVideosList";

const RightSidebar = ({selectedDiv}) => {
  const [angle, setangle] = useState({
    announcement: "down", events: "up", forums: "up", files: "up", savedpost: "up", savedvideos: "up"
  })

  return (
    <>
      <div className="all-content-main-right-sidebar">
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="rightheadingOne">
              <button
                type="button"
                className="btn-link "
                data-toggle="collapse"
                data-target="#ancmnt"
                onClick={() => {
                  angle.announcement === "down"
                    ? setangle({ ...angle, announcement: "up" })
                    : setangle({ ...angle, announcement: "down" });
                }}
              >
                Announcements
                {/* { allannou.length > 0 ? <span className="nbr-rd">{allannou.length}</span> : <></>} */}
                {angle.announcement === "down" ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </button>
            </div>
            <div
              id="ancmnt"
              className="collapse"
              aria-labelledby="rightheadingOne"
              // data-parent="#accordionExample"
            >
              <div className="cstm-announcements-list cstm-announcements-list-main">
                
                  <AnnouncementList />
               
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="rightheadingTwo">
              <button
                type="button"
                className="btn-link "
                data-toggle="collapse"
                data-target="#events"
                onClick={() => {
                  angle.events === "down"
                    ? setangle({ ...angle, events: "up" })
                    : setangle({ ...angle, events: "down" });
                }}
              >
                Events
                {/* <span className="nbr-rd">2</span> */}
                {angle.events === "down" ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </button>
            </div>
            <div
              id="events"
              className="collapse show"
              aria-labelledby="rightheadingTwo"
              // data-parent="#accordionExample"
            >
              <div className="cstm-events-list">
                  <EventsList/>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="rightheadingthree">
              <button
                type="button"
                className="btn-link "
                data-toggle="collapse"
                data-target="#forum"
                onClick={() => {
                  angle.forums === "down"
                    ? setangle({ ...angle, forums: "up" })
                    : setangle({ ...angle, forums: "down" });
                }}
              >
                Forums
                {/* <span className="nbr-rd">2</span>{" "} */}
                {angle.forums === "down" ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </button>
            </div>
            <div
              id="forum"
              className="collapse show"
              aria-labelledby="rightheadingthree"
              // data-parent="#accordionExample"
            >
              <div className="cstm-starred-group">
                  <ForumList/>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="rightheadingfour">
              <button
                type="button"
                className="btn-link "
                data-toggle="collapse"
                data-target="#files"
                onClick={() => {
                  angle.files === "down"
                    ? setangle({ ...angle, files: "up" })
                    : setangle({ ...angle, files: "down" });
                }}
              >
                Files
                {/* <span className="nbr-rd">2</span>{" "} */}
                {angle.files === "down" ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </button>
            </div>
            <div
              id="files"
              className="collapse show"
              aria-labelledby="rightheadingfour"
              // data-parent="#accordionExample"
            >
              <div className="cstm-starred-group cstm-files-main">
               <SidebarFilesList/>
              
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="rightheadingfive">
              <button
                type="button"
                className="btn-link "
                data-toggle="collapse"
                data-target="#svpost"
                onClick={() => {
                  angle.savedpost === "down"
                    ? setangle({ ...angle, savedpost: "up" })
                    : setangle({ ...angle, savedpost: "down" });
                }}
              >
                Saved Posts
                {/* {savedpost.length > 0 ? <span className="nbr-rd">{savedpost.length}</span> : ""} */}
                {angle.savedpost === "down" ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </button>
            </div>
            <div
              id="svpost"
              className="collapse show"
              aria-labelledby="rightheadingfive"
              // data-parent="#accordionExample"
            >
              <div className="cstm-starred-group">
               <SavePostList/>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="rightheadingfive">
              <button
                type="button"
                className="btn-link "
                data-toggle="collapse"
                data-target="#savid"
                onClick={() => {
                  angle.savedvideos === "down"
                    ? setangle({ ...angle, savedvideos: "up" })
                    : setangle({ ...angle, savedvideos: "down" });
                }}
              >
                Saved Videos
                {/* {savedpost.length > 0 ? <span className="nbr-rd">{savedpost.length}</span> : ""} */}
                {angle.savedvideos === "down" ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </button>
            </div>
            <div
              id="savid"
              className="collapse show"
              aria-labelledby="rightheadingfive"
              // data-parent="#accordionExample"
            >
              <div className="cstm-starred-group">
               <SaveVideosList/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
