import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import Services from "../../../Services/auth.service.event";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Editor } from "@tinymce/tinymce-react";
import { stateToHTML } from "draft-js-export-html";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import imgDelete from "../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import MessageModal from "../../AdminCommonComponent/MessageModal";
import DeleteModalWithLoader from "../../AdminCommonComponent/DeleteModalWithLoader";
import AlertComponent from "../../../Hooks/AlertComponent";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import ModalPopup from "../../ModalPopup/ModalPopup";
import copyimg from "../../../Images/file-copy-line 4.svg";
import CloneSessionModal from "./CloneSessionModal";
import { useCloneSessionMutation } from "../../../Services/session";
import { Tooltip } from "@mui/material";

const columns = [
  { id: "title", label: "Title" },
  { id: "room", label: "Room" },
  { id: "date", label: "Date" },
  { id: "startTime", label: "Start time" },
  { id: "endTime", label: "End time" },
  { id: "action", label: "Actions" },
];

//create a object of using given key
function createData(title, room, date, startTime, endTime, action) {
  return {
    title,
    room,
    date,
    startTime,
    endTime,
    action,
  };
}
//Event Session module
const Sessions = ({ tabInformation }) => {
  const emptyObjectErr = {
    room: "",
    speakers: "",
    title: "",
    shortDescription: "",
    longDescription: "",
    date: "",
    startTime: "",
    endTime: "",
    access: "",
    reservedUrl: "",
    reservedLabelForListing: "",
    reservedLabelForDetail: "",
    notifyChanges: false,
    notificationText: "",
    endDate: "",
    isEndOnNextDate: "",
  };
  const emptyObjectModal = {
    room: "",
    speakers: [],
    title: "",
    shortDescription: "",
    longDescription: "",
    date: "",
    startTime: "",
    endTime: "",
    access: [],
    reserve: false,
    reservedUrl: "",
    reservedLabelForListing: "",
    reservedLabelForDetail: "",
    notifyChanges: "",
    notificationText: "",
    endDate: "",
    isEndOnNextDate: false,
    scheduleNotify: false,
    scheduleNotifyTime: 0,
    accessRoles: [],
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const multiSelectAddRef = useRef(null);
  const multiSelectEditRef = useRef(null);
  const descriptionRef = useRef(null);
  const editDescriptionRef = useRef(null);
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [addEvent, setAddEvent] = useState(emptyObjectModal);
  const [editEvent, setEditEvent] = useState(emptyObjectModal);
  const [addErr, setAddErr] = useState(emptyObjectErr);
  const [editErr, setEditErr] = useState(emptyObjectErr);
  const [selectedId, setSelectedId] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const [speakersList, setSpeakersList] = useState([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [editorStateCreateModalLongDesc, setEditorStateCreateModalLongDesc] =
    useState("");
  const [editorStateEditModalLongDesc, setEditorStateEditModalLongDesc] =
    useState("");
  const [loader, setLoader] = useState(false);

  const allAccessList = ["member", "speaker", "partner", "guest"];
  // const [addChangeDate, setAddChangeDate] = useState(false);
  // const [addChangeStartTime, setAddChangeStartTime] = useState(false);
  // const [editChangeDate, setEditChangeDate] = useState(false);
  // const [editChangeStartTime, setEditChangeStartTime] = useState(false);
  const [eventDetail, setEventDetail] = useState({});
  const [searchDate, setSearchDate] = useState("");
  const [deletionMessage, setDeletionMessage] = useState("");
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [messageModalDivsArray, setMessageModalDivsArray] = useState([]);
  const [cloning, setCloning] = useState(false);
  const [cloneSuccess, setCloneSuccess] = useState(false);
  const [cloneMsg, setCloneMsg] = useState("");
  const [cloneId, setCloneId] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const [sortingOption, setSortingOption] = useState("");
  const scheduleNotifyTimeOptionList = [
    { time: "0", label: "At time of Event" },
    { time: "5", label: "Before 5 minutes" },
    { time: "10", label: "Before 10 minutes" },
    { time: "15", label: "Before 15 minutes" },
    { time: "30", label: "Before 30 minutes" },
    { time: "60", label: "Before 1 hour" },
    { time: "120", label: "Before 2 hour" },
  ];
  const [allAccessRoles, setAllAccessRoles] = useState([]);

  //clone hook
  const [cloneSessionHook] = useCloneSessionMutation();

  //fetch all existing Session list
  useEffect(() => {
    if (tabInformation === "Session") {
      getEventDetailApi();
      setLoader(true);
      getAllRoomApi();
      getAllSessionsApi();
      getAllSpeakerApi();
      getAllParticipantTypes();
      setSearchText("");
      setSearchDate("");
    }
  }, [tabInformation]);

  //get participant type list
  const getAllParticipantTypes = () => {
    Services.searchEventParticipantTypesApi(queryVariable, "")
      .then((res) => {
        const rolesTypesData = res.data.list;
        setAllAccessRoles(rolesTypesData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  ///get event detail
  const getEventDetailApi = () => {
    Services.getEventById(queryVariable)
      .then((response) => {
        if (response.data.status) {
          setEventDetail(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //get all sessions list
  const getAllSessionsApi = (isClearSearch, col, sort) => {
    let query = "";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    Services.getAllSessionsByEventId(queryVariable, query)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          var sortingData = res.data.data;
          var data = sortingData;
          var table = [];
          for (var i = 0; i < data.length; i++) {
            table[i] = createData(
              data[i].title,
              data[i].RoomName,
              moment(data[i].date, "MM-DD-YYYY").format("MMMM DD, YYYY"),
              data[i].startTime,
              data[i].endTime,
              data[i]._id
            );
          }
          setAllEvents(table);
          if (isClearSearch) {
            setSearchText("");
            setSortingOption("-1");
          }

          if (!isClearSearch && searchText && searchText.length > 0) {
            table = table.filter((session) => {
              if (
                searchText.length > 0 &&
                session.title.toLowerCase().includes(searchText.toLowerCase())
              )
                return session;
            });
          }

          setRow(table);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  //get all room list
  const getAllRoomApi = () => {
    Services.getAllRoomsByEventId(queryVariable)
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          setRoomList(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //get all speaker list
  const getAllSpeakerApi = () => {
    Services.getAllAttendeesByEventId(queryVariable)
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data
            .filter((speaker) => {
              if (speaker.type === "Speaker") return speaker;
            })
            .map((item) => ({
              id: item._id,
              name: `${item.display_name ? item.display_name : ""}`,
            }));
          setSpeakersList(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //close create session module
  const handleCloseAddModel = (e) => {
    setAddEvent(emptyObjectModal);
    setEditEvent(emptyObjectModal);
    setAddErr(emptyObjectErr);
    setEditErr(emptyObjectErr);
    setSelectedSpeakers([]);
    descriptionRef?.current.setContent("");
    multiSelectAddRef.current.resetSelectedValues();
    multiSelectEditRef.current.resetSelectedValues();
    handleClearAllStates();
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setSelectedId(value);
    setEditModalLoader(true);
    Services.getEventSessionById(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          var accessArray = [];
          if (data.member) accessArray.push("member");
          if (data.speaker) accessArray.push("speaker");
          if (data.partner) accessArray.push("partner");
          if (data.guest) accessArray.push("guest");

          let dataAccessRoles = [];
          data.accessRoles &&
            data.accessRoles.map((item) => {
              dataAccessRoles.push(item._id.toString());
            });

          setEditEvent({
            title: data.title,
            shortDescription: data.shortDescription,
            longDescription: data.longDescription,
            room: data.room._id,
            event: data.event,
            date: moment(data.date, "MM-DD-YYYY").format("YYYY-MM-DD"),
            startTime: moment(data.startTime, "hh:mm A").format("HH:mm"),
            endTime: moment(data.endTime, "hh:mm A").format("HH:mm"),
            access: accessArray,
            reserve: data.reserved,
            reservedUrl: data.reserved_URL,
            reservedLabelForListing: data.reservedLabelForListing ?? "",
            reservedLabelForDetail: data.reservedLabelForDetail ?? "",
            notifyChanges: false,
            notificationText: "",
            // notifyChanges: data.notifyChanges,
            // notificationText: data.notifyChangeText,
            isEndOnNextDate: data.isEndOrNextDate,
            endDate: moment(data.endDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
            scheduleNotify: data?.scheduleNotify ?? false,
            scheduleNotifyTime: data?.scheduleNotify
              ? data?.scheduleNotifyTime ?? ""
              : "0",
            accessRoles: dataAccessRoles,
          });
          let selSpeakers = [];
          data.speakerId.map((item) => {
            selSpeakers.push({
              id: item._id,
              name: `${item.display_name ? item.display_name : ""}`,
            });
          });
          setSelectedSpeakers(selSpeakers);

          // setSelectedSpeakers(data.speakerId);
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //search using text
  const handleSearch = (text, date) => {
    setSearchText(text);
    setSearchDate(date);
    if (allEvents !== undefined && allEvents.length > 0) {
      var data = allEvents.filter((event) => {
        if (
          text.length > 0 &&
          date.toString().length > 0 &&
          event.title.toLowerCase().includes(text.toLowerCase()) &&
          event.date === moment(date, "YYYY-MM-DD").format("MMMM DD, YYYY")
        )
          return event;
        else if (
          text.length > 0 &&
          date.toString().length === 0 &&
          event.title.toLowerCase().includes(text.toLowerCase())
        )
          return event;
        else if (
          text.length === 0 &&
          date.toString().length > 0 &&
          event.date === moment(date, "YYYY-MM-DD").format("MMMM DD, YYYY")
        )
          return event;
        else if (text.length === 0 && date.toString().length === 0)
          return event;
      });
      var table = [];
      for (var i = 0; i < data.length; i++) {
        table[i] = createData(
          data[i].title,
          data[i].room,
          data[i].date,
          data[i].startTime,
          data[i].endTime,
          data[i].action
        );
      }
      setPage(0);
      setRow(table);
    }
  };

  //deletion session api call
  const handleDelete = () => {
    setDeleteModalLoader(true);
    Services.deleteEventSession(deleteId, queryVariable)
      .then((res) => {
        let actualData = res.data;
        setDeleteModalLoader(false);
        setShowDeleteModal(false);
        if (res.data.status) {
          var table = [];
          var n = 0;
          for (var i = 0; i < allEvents.length; i++) {
            if (allEvents[i].action !== deleteId) {
              table[n] = createData(
                allEvents[i].title,
                allEvents[i].room,
                allEvents[i].date,
                allEvents[i].startTime,
                allEvents[i].endTime,
                allEvents[i].action
              );
              n++;
            }
          }
          setAllEvents(table);
          setRow(table);
          setDeletionMessage(res.data.message);
          setIsFailure(false);
          setShowAlert(true);
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setShowAlert(false);
            setAlertMsg("");
          }, 2000);
        } else {
          setIsFailure(true);
          let messageStringArray = [];
          if (actualData.data?.activityList.length) {
            let actList =
              "Activity List : " +
              actualData.data.activityList.toString().replace(",", ", ");
            messageStringArray.push(actList);
          }
          setMessageModalDivsArray(messageStringArray);
          setDeletionMessage(actualData.message);
        }
      })
      .catch((e) => {
        setShowDeleteModal(false);
        setDeleteModalLoader(false);
        console.log(e);
      });
  };

  // function for session clone api
  const handleCloneSession = async () => {
    setCloning(true);
    try {
      const cloneRes = await cloneSessionHook({ body: { id: cloneId } });
      if (cloneRes?.data?.status) {
        setCloning(false);
        setCloneSuccess(true);
        getAllSessionsApi();
        setCloneMsg(cloneRes.data.message);
        setTimeout(() => {
          setCloneSuccess(false);
          setCloneMsg("");
        }, 3000);
      } else {
        setCloning(false);
        setCloneSuccess(true);
        setCloneMsg(cloneRes.data.message);
        setTimeout(() => {
          setCloneSuccess(false);
          setCloneMsg("");
        }, 3000);
      }
    } catch (error) {
      console.log("Error clone session:", error);
    }
  };

  //sorting function
  const handleSorting = (e) => {
    setSortingOption(e.target.value);
    var normal = allEvents;
    var arr = [...allEvents];

    if (e.target.value === "a-z") {
      arr.sort(function (a, b) {
        var itemA = a.title.toUpperCase();
        var itemB = b.title.toUpperCase();
        return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      });
    } else if (e.target.value === "z-a") {
      arr.sort(function (a, b) {
        var itemA = a.title.toUpperCase();
        var itemB = b.title.toUpperCase();
        return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
      });
    } else {
      arr = normal;
    }
    var table = [];
    for (var i = 0; i < arr.length; i++) {
      table[i] = createData(
        arr[i].title,
        arr[i].room,
        arr[i].date,
        arr[i].startTime,
        arr[i].endTime,
        arr[i].action
      );
    }
    setRow(table);
  };

  //sorting function
  const handleSort = (col, direc) => {
    setLoader(true);
    getAllSessionsApi(false, col, direc);
  };

  //handle onchange for create session
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    if (e.target.name !== "reserve" && e.target.name !== "accessRoles") {
      setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
      if (e.target.name === "date") {
        if (addEvent.isEndOnNextDate)
          setAddEvent({
            ...addEvent,
            [e.target.name]: e.target.value,
            endDate: moment(e.target.value, "YYYY-MM-DD")
              .add(1, "days")
              .format("YYYY-MM-DD"),
          });
      }
    } else if (e.target.name === "reserve") {
      if (!e.target.checked)
        setAddEvent({
          ...addEvent,
          [e.target.name]: e.target.checked,
          reservedUrl: "",
          reservedLabelForListing: "",
          reservedLabelForDetail: "",
        });
      else setAddEvent({ ...addEvent, [e.target.name]: e.target.checked });
    } else if (e.target.checked) {
      setAddEvent({
        ...addEvent,
        [e.target.name]: [
          ...addEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
          e.target.value,
        ],
      });
      setAddErr({ ...addErr, access: "" });
    } else if (!e.target.checked) {
      setAddEvent({
        ...addEvent,
        [e.target.name]: [
          ...addEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
        ],
      });
      setAddErr({ ...addErr, access: "" });
    }

    if (e.target.name === "title") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "description") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "room") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "date") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "startTime") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "endTime") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "reservedUrl") {
      if (e.target.value.trim().length > 0) {
        let url;
        try {
          url = new URL(e.target.value);
        } catch (error) {
          setAddErr({
            ...addErr,
            [e.target.name]: "Invalid url",
          });
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          setAddErr({ ...addErr, [e.target.name]: "" });
        }
      }
    }
    if (
      (e.target.value === "reservedLabelForDetail" ||
        e.target.value === "reservedLabelForListing") &&
      e.target.value.length > 0
    ) {
      setAddErr({ ...addErr, [e.target.name]: "" });
    }
    // if (e.target.name === "date" && e.target.value.length > 0) {

    //   if (moment(e.target.value).isSameOrAfter(moment(eventDetail.startDate, 'MM-DD-YYYY')) && moment(e.target.value).isSameOrBefore(moment(eventDetail.endDate, 'MM-DD-YYYY'))) {
    //     setAddErr({ ...addErr, [e.target.name]: "", startTime: "", endTime: "" });
    //   } else {

    //     if ((moment(e.target.value).isBefore(moment(eventDetail.startDate, 'MM-DD-YYYY')))) {
    //       setAddErr({ ...addErr, [e.target.name]: "Date should be greater than event start date!" });
    //     }
    //     if ((moment(e.target.value).isAfter(moment(eventDetail.endDate, 'MM-DD-YYYY')))) {
    //       setAddErr({ ...addErr, [e.target.name]: "Date should be less than event end date!" });
    //     }

    //   }
    // }

    // if (e.target.name === "startTime" && e.target.value.length > 0) {

    //   if (moment(addEvent.date).isSameOrAfter(moment(eventDetail.startDate, 'MM-DD-YYYY')) && moment(addEvent.date).isSameOrBefore(moment(eventDetail.endDate, 'MM-DD-YYYY'))) {
    //     if ((moment(addEvent.date).isSame(moment(eventDetail.startDate, 'MM-DD-YYYY'))) && moment(e.target.value, "HH:mm").isBefore(moment(eventDetail.startTime, 'hh:mm a'))) {
    //       setAddErr({ ...addErr, [e.target.name]: "Start Time should be greater than event start time!" });
    //     }

    //     if ((moment(addEvent.date).isSame(moment(eventDetail.endDate, 'MM-DD-YYYY'))) && moment(e.target.value, "HH:mm").isAfter(moment(eventDetail.endTime, 'hh:mm a'))) {
    //       setAddErr({ ...addErr, [e.target.name]: "Start Time should be greater than event end time!" });
    //     }

    //   }
    // }

    // if (e.target.name === "endTime" && e.target.value.length > 0 && !addEvent.isEndOnNextDate) {
    //   if (moment(e.target.value, "HH:mm").isBefore(moment(addEvent.startTime, 'hh:mm a'))) {
    //     setAddErr({ ...addErr, [e.target.name]: "End time should be greater than start time !" });
    //   }

    //   if ((moment(addEvent.date).isSame(moment(eventDetail.endDate, 'MM-DD-YYYY'))) && moment(e.target.value, "HH:mm").isAfter(moment(eventDetail.endTime, 'hh:mm a'))) {
    //     setAddErr({ ...addErr, [e.target.name]: "End time should be less than event end time !" });
    //   }

    //   if ((moment(addEvent.date).isSame(moment(eventDetail.startDate, 'MM-DD-YYYY'))) && moment(e.target.value, "HH:mm").isBefore(moment(eventDetail.startTime, 'hh:mm a'))) {
    //     setAddErr({ ...addErr, [e.target.name]: "End time should be greater than event start time !" });
    //   }

    // }
    if (e.target.name === "shortDescription") {
      if (e.target.value.trim().length > 0)
        setAddErr({ ...addErr, [e.target.name]: "" });
    }

    // if (e.target.name === "startTime" && e.target.value.length > 0 && !addEvent.isEndOnNextDate) {
    //   if (
    //     moment(e.target.value, "HH:mm").isAfter(
    //       moment(addEvent.endTime, "hh:mm a")
    //     )
    //   ) {
    //     setAddErr({
    //       ...addErr,
    //       endTime: "End time should be greater than start time !",
    //     });
    //   }

    // }
  };

  //Create session validation and api call
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text?.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //Create session validation and api call
  const checkEditDesciptionValue = (state) => {
    var text = editDescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //validation and api call on create session
  const handleCreate = async () => {
    var status = true;
    var temp = {};
    if (addEvent.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    }
    if (addEvent.shortDescription.trim().length === 0) {
      temp = { ...temp, shortDescription: "Enter a short description!" };
      status = false;
    }
    if (!checkDesciptionValue(editorStateCreateModalLongDesc)) {
      temp = { ...temp, longDescription: "Enter a long description!" };
      status = false;
    }
    if (addEvent.room.trim().length === 0) {
      temp = { ...temp, room: "Select room!" };
      status = false;
    }

    if (!selectedSpeakers.length > 0) {
      temp = { ...temp, speakers: "Select speakers!" };
      status = false;
    }

    if (addEvent.date.length === 0) {
      temp = { ...temp, date: "Select date!" };
      status = false;
    }
    if (addEvent.startTime.length === 0) {
      temp = { ...temp, startTime: "Select start time!" };
      status = false;
    }
    if (addEvent.endTime.length === 0) {
      temp = { ...temp, endTime: "Select end time!" };
      status = false;
    }

    // if (addEvent.accessRoles.length === 0) {
    //   temp = { ...temp, access: "Select access!" };
    //   status = false;
    // }

    if (addEvent.reserve && addEvent.reservedUrl.length > 0) {
      let url;

      try {
        url = new URL(addEvent.reservedUrl);
      } catch (error) {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }

      if (url && (url.protocol === "http:" || url.protocol === "https:")) {
      } else {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }
    } else if (addEvent.reserve && addEvent.reservedUrl.length === 0) {
      temp = { ...temp, reservedUrl: "Enter url!" };
      status = false;
    }
    if (addEvent.reserve && addEvent.reservedLabelForDetail.length === 0) {
      temp = {
        ...temp,
        reservedLabelForDetail: "Enter button label for detail!",
      };
      status = false;
    }
    if (addEvent.reserve && addEvent.reservedLabelForListing.length === 0) {
      temp = {
        ...temp,
        reservedLabelForListing: "Enter button label for listing!",
      };
      status = false;
    }
    setEditorStateCreateModalLongDesc(descriptionRef.current.getContent());

    if (status) {
      setCreateInprocess(true);
      // const longDescriptionHtml = await handleDesciption(editorStateCreateModalLongDesc);
      createApiCall();
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setAddErr(temp);
    }
  };
  //Api calll on create session
  const createApiCall = (longDescriptionHtml) => {
    Services.createEventSession({
      title: addEvent.title,
      shortDescription: addEvent.shortDescription,
      longDescription: descriptionRef.current.getContent(),
      date: moment(addEvent.date).format("MM-DD-YYYY"),
      startTime: moment(addEvent.startTime, "hh:mm A").format("hh:mm A"),
      endTime: moment(addEvent.endTime, "hh:mm A").format("hh:mm A"),
      room: addEvent.room,
      speakerId: selectedSpeakers.map((s) => s.id),
      event: queryVariable,
      // member: addEvent.access.includes("member"),
      // speaker: addEvent.access.includes("speaker"),
      // partner: addEvent.access.includes("partner"),
      // guest: addEvent.access.includes("guest"),
      reserved: addEvent.reserve,
      reserved_URL: addEvent.reservedUrl,
      reservedLabelForDetail: addEvent.reservedLabelForDetail,
      reservedLabelForListing: addEvent.reservedLabelForListing,
      isEndOrNextDate: addEvent.isEndOnNextDate,
      endDate: addEvent.isEndOnNextDate
        ? moment(addEvent.endDate).format("MM-DD-YYYY")
        : "",
      scheduleNotify: addEvent.scheduleNotify,
      scheduleNotifyTime: addEvent.scheduleNotify
        ? addEvent.scheduleNotifyTime
        : "",
      accessRoles: addEvent.accessRoles,
    })
      .then((res) => {
        setCreateInprocess(false);
        if (res.data.status) {
          setShowAlert(true);
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setShowAlert(false);
            setAlertMsg("");
          }, 3000);
          $("#AddSession").modal("hide");
          getAllSessionsApi(false);
        } else {
          setShowAlertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
        }
        handleClearAllStates();
        setEditorStateCreateModalLongDesc("");
        setAddEvent(emptyObjectModal);
        setSelectedSpeakers([]);
        multiSelectAddRef.current.resetSelectedValues();
        multiSelectEditRef.current.resetSelectedValues();
      })
      .catch((e) => {
        setCreateInprocess(false);
        console.log(e);
      });
    setAddErr(emptyObjectErr);
  };
  //on change for Edit
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    if (e.target.name !== "accessRoles" && e.target.name !== "reserve") {
      setEditEvent({ ...editEvent, [e.target.name]: e.target.value });

      if (e.target.name === "date") {
        if (editEvent.isEndOnNextDate)
          setEditEvent({
            ...editEvent,
            [e.target.name]: e.target.value,
            endDate: moment(e.target.value, "YYYY-MM-DD")
              .add(1, "days")
              .format("YYYY-MM-DD"),
          });
      }
    } else if (e.target.name === "reserve") {
      if (!e.target.checked)
        setEditEvent({
          ...editEvent,
          [e.target.name]: e.target.checked,
          reservedUrl: "",
          reservedLabelForListing: "",
          reservedLabelForDetail: "",
        });
      else setEditEvent({ ...editEvent, [e.target.name]: e.target.checked });
    } else if (e.target.name === "notificationText") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "accessRoles" && e.target.checked) {
      setEditEvent({
        ...editEvent,
        [e.target.name]: [
          ...editEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
          e.target.value,
        ],
      });
      setEditErr({ ...editErr, access: "" });
    } else if (e.target.name === "accessRoles" && !e.target.checked) {
      setEditEvent({
        ...editEvent,
        [e.target.name]: [
          ...editEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
        ],
      });
      setEditErr({ ...editErr, access: "" });
    }
    if (e.target.name === "title") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "description") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "room") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "date") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "startTime") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "endTime") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "reservedUrl") {
      if (e.target.value.trim().length > 0) {
        let url;
        try {
          url = new URL(e.target.value);
        } catch (error) {
          setEditErr({
            ...editErr,
            [e.target.name]: "Invalid url",
          });
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          setEditErr({ ...editErr, [e.target.name]: "" });
        }
      }
    } else if (
      (e.target.value === "reservedLabelForDetail" ||
        e.target.value === "reservedLabelForListing") &&
      e.target.value.length > 0
    ) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }

    // }

    // if (e.target.name === "endTime" && e.target.value.length > 0 && !editEvent.isEndOnNextDate) {
    //   if (
    //     moment(e.target.value, "HH:mm").isBefore(
    //       moment(editEvent.startTime, "hh:mm a")
    //     )
    //   ) {
    //     setEditErr({
    //       ...editErr,
    //       endTime: "End time should be greater than start time !",
    //     });
    //   }

    // }
  };
  //Called when user click on update changes
  const handleEditSubmit = async () => {
    var status = true;
    var temp = {};
    if (editEvent.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    }
    if (editEvent.shortDescription.trim().length === 0) {
      temp = { ...temp, shortDescription: "Enter a short description!" };
      status = false;
    }
    if (!checkEditDesciptionValue(editorStateEditModalLongDesc)) {
      temp = { ...temp, longDescription: "Enter a long description!" };
      status = false;
    }
    if (editEvent.room.trim().length === 0) {
      temp = { ...temp, room: "Select room!" };
      status = false;
    }
    if (!selectedSpeakers.length > 0) {
      temp = { ...temp, speakers: "Select speakers!" };
      status = false;
    }
    if (editEvent.date.length === 0) {
      temp = { ...temp, date: "Select date!" };
      status = false;
    }
    if (editEvent.startTime.length === 0) {
      temp = { ...temp, startTime: "Select start time!" };
      status = false;
    }
    if (editEvent.endTime.length === 0) {
      temp = { ...temp, endTime: "Select end time!" };
      status = false;
    }
    // if (editEvent.accessRoles.length === 0) {
    //   temp = { ...temp, access: "Select access!" };
    //   status = false;
    // }
    if (
      editEvent.notifyChanges &&
      editEvent.notificationText.trim().length === 0
    ) {
      temp = { ...temp, notificationText: "Enter notification text!" };
      status = false;
    }

    if (editEvent.reserve && editEvent.reservedUrl.length > 0) {
      let url;

      try {
        url = new URL(editEvent.reservedUrl);
      } catch (error) {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }

      if (url && (url.protocol === "http:" || url.protocol === "https:")) {
      } else {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }
    } else if (editEvent.reserve && editEvent.reservedUrl.length === 0) {
      temp = { ...temp, reservedUrl: "Enter url!" };
      status = false;
    }
    if (editEvent.reserve && editEvent.reservedLabelForDetail.length === 0) {
      temp = {
        ...temp,
        reservedLabelForDetail: "Enter button label for detail!",
      };
      status = false;
    }
    if (editEvent.reserve && editEvent.reservedLabelForListing.length === 0) {
      temp = {
        ...temp,
        reservedLabelForListing: "Enter button label for listing!",
      };
      status = false;
    }
    if (status) {
      setEditInprocess(true);
      // const longDescriptionHtml = await handleDesciption(editorStateEditModalLongDesc);
      editApiCall();
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.querySelectorAll(
          `[name="${emptyKeys[0]}"]`
        );
        if (inputElement) {
          inputElement[1]?.focus(); // Focus the input element
        }
      }
      setEditErr(temp);
    }
  };
  //Edit session api call
  const editApiCall = () => {
    Services.editEventSession({
      id: selectedId,
      data: {
        title: editEvent.title,
        shortDescription: editEvent.shortDescription,
        longDescription: editDescriptionRef.current.getContent(),
        date: moment(editEvent.date).format("MM-DD-YYYY"),
        startTime: moment(editEvent.startTime, "hh:mm A").format("hh:mm A"),
        endTime: moment(editEvent.endTime, "hh:mm A").format("hh:mm A"),
        room: editEvent.room,
        speakerId: selectedSpeakers.map((s) => s.id),
        event: queryVariable,
        // member: editEvent.access.includes("member"),
        // speaker: editEvent.access.includes("speaker"),
        // partner: editEvent.access.includes("partner"),
        // guest: editEvent.access.includes("guest"),
        reserved: editEvent.reserve,
        reserved_URL: editEvent.reservedUrl,
        reservedLabelForDetail: editEvent.reservedLabelForDetail,
        reservedLabelForListing: editEvent.reservedLabelForListing,
        notifyChanges: editEvent.notifyChanges,
        notifyChangeText: editEvent.notificationText,
        isEndOrNextDate: editEvent.isEndOnNextDate,
        endDate: editEvent.isEndOnNextDate
          ? moment(editEvent.endDate).format("MM-DD-YYYY")
          : "",
        scheduleNotify: editEvent.scheduleNotify,
        scheduleNotifyTime: editEvent.scheduleNotify
          ? editEvent.scheduleNotifyTime
          : "",
        accessRoles: editEvent.accessRoles,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setSearchDate("");
          setShowAlert(true);
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setShowAlert(false);
            setAlertMsg("");
          }, 3000);
          getAllSessionsApi(false);
          $("#editSession").modal("hide");
          setEditInprocess(false);
          //setEditEvent(emptyObjectModal)
        } else {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
        }
        handleClearAllStates();
        setEditInprocess(false);
      })
      .catch((e) => {
        console.log(e);
        setEditInprocess(false);
      });
    setEditErr(emptyObjectErr);
  };
  //editor state onchange
  const validationCheckForEditor = (editorState) => {
    var text = stateToHTML(editorState.getCurrentContent(), {
      defaultBlockTag: null,
    }).replace(/&nbsp;$/, "");
    if (text.length > 0 && text !== "<br>") {
      return true;
    } else {
      return false;
    }
  };

  //base64 to image file covertion
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], Math.random().toString() + ".png", { type: mime });
  };
  //handling editor state to html state and uploading images to server
  const handleDesciption = async (state) => {
    return new Promise((resolve, reject) => {
      const contentState = state.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      if (
        contentRaw?.entityMap &&
        Object.keys(contentRaw.entityMap).length > 0
      ) {
        var entityMap = contentRaw.entityMap;
        var formdata = new FormData();
        var uploadStatus = false;
        var keyList = [];

        for (var key in entityMap) {
          var data = entityMap[key];
          if (
            data.type === "IMAGE" &&
            data.data.src.startsWith("data:image/")
          ) {
            uploadStatus = true;
            var fileData = dataURLtoFile(data.data.src);
            formdata.append(`image`, fileData);
            keyList = [...keyList, key];
          }
        }

        if (uploadStatus) {
          Services.eventUploadFiles(formdata)
            .then((res) => {
              if (res.data.status) {
                var mediaData = res.data.media;
                mediaData.map((m, index) => {
                  contentRaw.entityMap[keyList[index]].data.src = m.key;
                });
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              } else {
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              }
            })
            .catch((e) => {
              console.log(e);
              const htmlContent = draftToHtml(contentRaw);
              reject(htmlContent);
            });
        } else {
          const htmlContent = draftToHtml(contentRaw);
          resolve(htmlContent);
        }
      } else {
        const htmlContent = draftToHtml(contentRaw);
        resolve(htmlContent);
      }
    });
  };
  //handle upload image in editor
  const uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  //html to editor state
  function htmlToEditor(html, setEditor) {
    const contentBlock = htmlToDraft(html.replace("<p>&nbsp;</p>", ""));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditor(editorState);
  }

  //past event for editor
  const handlePastedText = (text, editor, stateChangeFunctionCall) => {
    const contentState = editor.getCurrentContent();
    const selectionState = editor.getSelection();

    // Create a new modified content state with the pasted text
    const modifiedContentState = Modifier.replaceText(
      contentState,
      selectionState,
      text,
      editor
        .getCurrentInlineStyle()
        .filter((style) => style !== "backgroundColor")
    );

    // Create a new editor state with the modified content state
    const newEditorState = EditorState.push(
      editor,
      modifiedContentState,
      "insert-characters"
    );
    stateChangeFunctionCall(newEditorState);
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#AddSession").hasClass("show")) {
        $("#AddSession").modal("show");
      }

      if ($("#editSession").hasClass("show")) {
        $("#editSession").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#AddSession").hasClass("show")) {
        $("#AddSession").modal("hide");
      }

      if ($("#editSession").hasClass("show")) {
        $("#editSession").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  // Define message JSX element for show highlighted formatted
  const message = (
    <span>
      Do you want to delete this{" "}
      <span style={{ fontWeight: "bold", color: "red" }}>"{deleteName}"</span>{" "}
      session?
    </span>
  );

  return (
    <>

      <MessageModal
        deletionMessage={deletionMessage}
        setDeletionMessage={setDeletionMessage}
        failure={isFailure}
        additionalDivArray={messageModalDivsArray}
      />
      <div className="uic-fl mt-0 d-flex flex-wrap gap-xl-4 gap-3 align-items-center mb-lg-4 mb-3">
        <div className="search-input position-relative mb-0 me-0 flex-grow-1 flex-shrink-0">
          <input
            type="search"
            placeholder="Search sessions"
            className="w-100"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSearch(e.target.value, searchDate);
              }
            }}
          />
          <i className="fa fa-search" onClick={() => handleSearch(searchText, searchDate)}></i>
        </div>
        <div className="uic-fl-btn d-flex align-items-center flex-xl-grow-0 flex-grow-1">
          <input
            type="date"
            placeholder="Search activities"
            className="w-100"
            value={searchDate}
            onChange={(e) => {
              handleSearch(searchText, e.target.value);
            }}
            min={moment(eventDetail.startDate, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            )}
            max={moment(eventDetail.endDate, "MM-DD-YYYY").format("YYYY-MM-DD")}
          />
        </div>
        <div className="uic-fl-btn d-flex align-items-center flex-grow-1 gap-3">
          <div className="flex-shrink-0">Sort by : </div>
          <div className="w-auto flex-xxl-grow-0 flex-grow-1">
            <select value={sortingOption} onChange={handleSorting}>
              <option value={"-1"}>All</option>
              <option value="a-z">A-Z</option>
              <option value="z-a">Z-A</option>
            </select>
          </div>
        </div>
        <div className="uic-title-rt-only mt-0 position-relative end-0 ms-auto r-0">
          <div className="uic-fl-btn">
            <button
              className="btn"
              data-toggle="modal"
              data-target="#AddSession"
              onClick={(e) => {
                handleCloseAddModel(e);
                setSortingOption("-1");
              }}
            >
              Create Session
            </button>
          </div>
        </div>
      </div>

      {showAlert && <div className="alert alert-info">{alertMsg}</div>}
      <Paper className="uic-tb-mn">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHead className="uic-table-th">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="uic-th-td w-10"
                  >
                    {"action" !== column.id ? (
                      <>
                        <span className="custom-sorting">
                          <i
                            className="fa fa-angle-down"
                            onClick={(e) => {
                              handleSort(column.id, "Dec");
                            }}
                          ></i>
                          <i
                            className="fa fa-angle-up"
                            onClick={(e) => {
                              handleSort(column.id, "Asc");
                            }}
                          ></i>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {loader ? (
              <div className="cstm-data-load">
                <img src={Loader} />{" "}
              </div>
            ) : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .length > 0 ? (
              <TableBody className="uic-table-td">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        className="uic-th-td w-20"
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id === "title" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              <a
                                data-toggle="modal"
                                data-target="#editSession"
                                onClick={() => {
                                  handleEdit(row["action"]);
                                }}
                                className="cstm-view-users-count"
                              >
                                {value}
                              </a>
                            </TableCell>
                          ) : column.id === "room" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value}
                            </TableCell>
                          ) : column.id === "date" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value}
                            </TableCell>
                          ) : column.id === "startTime" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value}
                            </TableCell>
                          ) : column.id === "endTime" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={column.id}
                              className="uic-th-td uic-table-icon w-17"
                            >
                              <Tooltip title="Clone" arrow>
                                <a
                                  data-toggle="modal"
                                  data-target="#cloneSession"
                                  onClick={() => {
                                    setCloneId(value);
                                  }}
                                >
                                  <img src={copyimg} />
                                </a>
                              </Tooltip>
                              <a
                                data-toggle="modal"
                                data-target="#editSession"
                                onClick={() => {
                                  handleEdit(value);
                                }}
                              >
                                <img src={editIcon} />
                              </a>
                              {row["name"] !== "others" && (
                                <a
                                  onClick={() => {
                                    setDeleteId(value);
                                    setDeleteName(row.title);
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  <img src={imgDelete} />
                                </a>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <span className="no-data-found">No data found</span>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* create modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="AddSession"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Session</h2>
              <button
                type="button"
                className="close"
                // data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#AddSession").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Title*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    onChange={handleAddChange}
                    value={addEvent.title}
                  />
                  <span className="error">{addErr.title}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Short description*</div>
                <div className="form-group-ct">
                  <textarea
                    placeholder="Enter short description"
                    name="shortDescription"
                    onChange={handleAddChange}
                    value={addEvent.shortDescription}
                    maxLength={80}
                  />
                  <span className="text-limitaion">
                    The maximum character limit is 80.
                  </span>
                  <span className="error">{addErr.shortDescription}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Long description*</div>
                <div className="form-group-ct">
                  <div className="cstm-vd cstm-code-addt">
                    <TinyEditor
                      valueRef={descriptionRef}
                      value={editorStateCreateModalLongDesc}
                      handleEditorChange={(value, editor) => {
                        setAddErr({ ...addErr, longDescription: "" });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{addErr.longDescription}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Room*</div>
                <div className="form-group-ct">
                  <select
                    onChange={handleAddChange}
                    value={addEvent.room}
                    name="room"
                  >
                    <option value="">Select a room</option>
                    {roomList.map((room) => (
                      <option
                        key={room._id}
                        value={room._id}
                        data-address={room.address}
                      >
                        {room.name}
                      </option>
                    ))}
                  </select>
                  <span className="error">{addErr.room}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Speakers*</div>
                <div className="form-group-ct speakers-only">
                  <Multiselect
                    ref={multiSelectAddRef}
                    placeholder="Select speakers"
                    displayValue="name"
                    options={speakersList}
                    selectedValues={selectedSpeakers}
                    onSelect={(selectedlist) => {
                      setAddErr({ ...addErr, speakers: "" });
                      setSelectedSpeakers(selectedlist);
                    }}
                    onRemove={(selectedlist) => {
                      setSelectedSpeakers(selectedlist);
                    }}
                  />
                  <span className="error">{addErr.speakers}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Date*</div>
                <div className="form-group-ct">
                  <input
                    type="date"
                    placeholder="Select date"
                    name="date"
                    onChange={handleAddChange}
                    min={moment(eventDetail.startDate, "MM-DD-YYYY").format(
                      "YYYY-MM-DD"
                    )}
                    max={moment(eventDetail.endDate, "MM-DD-YYYY").format(
                      "YYYY-MM-DD"
                    )}
                    // max={moment(eventDetail.endDate, 'MM-DD-YYYY').format("YYYY-MM-DD")}
                    value={addEvent.date}
                  />
                  <span className="error">{addErr.date}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Start time*</div>
                <div className="form-group-ct">
                  <input
                    type="time"
                    placeholder="Select start time"
                    name="startTime"
                    onChange={handleAddChange}
                    value={addEvent.startTime}
                  />
                  <span className="error">{addErr.startTime}</span>
                </div>
              </div>
              <div className="form-group clt-checkbox">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      setAddEvent({
                        ...addEvent,
                        endDate: "",
                        isEndOnNextDate: e.target.checked,
                      });
                      if (e.target.checked) {
                        if (addEvent.date)
                          setAddEvent({
                            ...addEvent,
                            endDate: moment(addEvent.date, "YYYY-MM-DD")
                              .add(1, "days")
                              .format("YYYY-MM-DD"),
                            isEndOnNextDate: e.target.checked,
                          });
                        setAddErr({
                          ...addErr,
                          endTime: "",
                          isEndOnNextDate: e.target.checked,
                        });
                      }
                    }}
                    name="isEndOnNextDate"
                    checked={addEvent.isEndOnNextDate}
                  />
                  Session ends on next day?
                </label>
              </div>
              {addEvent.isEndOnNextDate && (
                <div className="form-group">
                  <div className="form-group-label">End date</div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      placeholder="dd-mm-yyyy"
                      name="endDate"
                      disabled
                      value={addEvent.endDate}
                    />
                    <span className="error">{addErr.endDate}</span>
                  </div>
                </div>
              )}
              <div className="form-group">
                <div className="form-group-label">End time*</div>
                <div className="form-group-ct">
                  <input
                    type="time"
                    placeholder="Select end time"
                    name="endTime"
                    onChange={handleAddChange}
                    value={addEvent.endTime}
                  />
                  <span className="error">{addErr.endTime}</span>
                </div>
              </div>

              {/* TODO - temporary remove MDS - 5883 */}

              {/* <div className="form-group">
                <div className="cstm-add-video-title-sub">Access*</div>
                <div className=" form-group mb-0">
                  <div className="form-group-ctst">
                    {allAccessRoles.map((a) => {
                      return (
                        <>
                          <label className="subcat-addvideo">
                            <input
                              type="checkbox"
                              name="accessRoles"
                              value={a._id.toString()}
                              onChange={handleAddChange}
                              checked={addEvent.accessRoles.includes(
                                a._id.toString()
                              )}
                            />
                            {a.role}
                          </label>
                        </>
                      );
                    })}
                  </div>
                  <span className="error cstm-smg">{addErr.access}</span>
                </div>
              </div> */}
              <div className="cstm-add-video-title-sub">Reserved</div>
              <div className="form-group">
                <label className="subcat-addvideo">
                  <input
                    type="checkbox"
                    name="reserve"
                    onChange={handleAddChange}
                    checked={addEvent.reserve}
                  />
                  Reserved
                </label>
              </div>
              {addEvent.reserve && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Reserved Url*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter reserved url"
                        name="reservedUrl"
                        onChange={handleAddChange}
                        value={addEvent.reservedUrl}
                      />
                      <span className="error">{addErr.reservedUrl}</span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">
                      Button label for listing*
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter button label for listing"
                        name="reservedLabelForListing"
                        onChange={handleAddChange}
                        value={addEvent.reservedLabelForListing}
                        maxLength={18}
                      />
                      <span className="text-limitaion">
                        The maximum character limit is 18.
                      </span>
                      <span className="error">
                        {addErr.reservedLabelForListing}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Button label for detail*
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter button label for detail"
                        name="reservedLabelForDetail"
                        onChange={handleAddChange}
                        value={addEvent.reservedLabelForDetail}
                      />
                      <span className="error">
                        {addErr.reservedLabelForDetail}
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="cstm-add-video-title-sub">
                Notify reminder to user
              </div>
              <div className="form-group">
                <label className="subcat-addvideo">
                  <input
                    type="checkbox"
                    name="scheduleNotify"
                    onChange={(e) => {
                      setAddEvent({
                        ...addEvent,
                        scheduleNotify: e.target.checked,
                      });
                    }}
                    checked={addEvent.scheduleNotify}
                  />
                  Notify reminder to user
                </label>
              </div>
              {addEvent.scheduleNotify && (
                <div className="form-group">
                  <div className="form-group-label">Choose Time Reminder*</div>
                  <div className="form-group-ct">
                    <select
                      onChange={(e) => {
                        setAddEvent({
                          ...addEvent,
                          scheduleNotifyTime: e.target.value,
                        });
                      }}
                      value={addEvent.scheduleNotifyTime}
                      name="scheduleNotifyTime"
                    >
                      {scheduleNotifyTimeOptionList.map((opt) => (
                        <option key={opt.time} value={opt.time}>
                          {opt.label}
                        </option>
                      ))}
                    </select>
                    <span className="error">{addErr.scheduleNotifyTime}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editSession"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Session</h2>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editSession").modal("hide");
                      handleCloseAddModel(e);
                    }
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Title*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      onChange={handleEditChange}
                      value={editEvent.title}
                    />
                    <span className="error">{editErr.title}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Short description*</div>
                  <div className="form-group-ct">
                    <textarea
                      placeholder="Enter short description"
                      name="shortDescription"
                      onChange={handleEditChange}
                      value={editEvent.shortDescription}
                      maxLength={80}
                    />
                    <span className="text-limitaion">
                      The maximum character limit is 80.
                    </span>
                    <span className="error">{editErr.shortDescription}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Long description*</div>
                  <div className="form-group-ct">
                    <div className="cstm-vd cstm-code-addt">
                      <TinyEditor
                        valueRef={editDescriptionRef}
                        value={editEvent.longDescription}
                        handleEditorChange={(value, editor) => {
                          setEditErr({ ...editErr.longDescription });
                        }}
                        handleKeyPress={() => {
                          dispatch({ type: "SETMODALINPROCESS" });
                        }}
                      />
                    </div>
                    <span className="error">{editErr.longDescription}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Room*</div>
                  <div className="form-group-ct">
                    <select
                      onChange={handleEditChange}
                      value={editEvent.room}
                      name="room"
                    >
                      <option value="">Select a room</option>
                      {roomList.map((room) => (
                        <option
                          key={room._id}
                          value={room._id}
                          data-address={room.address}
                        >
                          {room.name}
                        </option>
                      ))}
                    </select>
                    <span className="error">{editErr.room}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Speakers*</div>
                  <div className="form-group-ct speakers-only">
                    <Multiselect
                      ref={multiSelectEditRef}
                      placeholder="Select speakers"
                      displayValue="name"
                      options={speakersList}
                      selectedValues={selectedSpeakers}
                      onSelect={(selectedlist) => {
                        setEditErr({ ...editErr, speakers: "" });
                        setSelectedSpeakers(selectedlist);
                      }}
                      onRemove={(selectedlist) => {
                        setSelectedSpeakers(selectedlist);
                      }}
                    />
                    <span className="error">{editErr.speakers}</span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-label">Date*</div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      placeholder="Select date"
                      name="date"
                      onChange={handleEditChange}
                      value={editEvent.date}
                      min={moment(eventDetail.startDate, "MM-DD-YYYY").format(
                        "YYYY-MM-DD"
                      )}
                      max={moment(eventDetail.endDate, "MM-DD-YYYY").format(
                        "YYYY-MM-DD"
                      )}
                    />
                    <span className="error">{editErr.date}</span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-label">Start time*</div>
                  <div className="form-group-ct">
                    <input
                      type="time"
                      placeholder="Select start time"
                      name="startTime"
                      onChange={handleEditChange}
                      value={editEvent.startTime}
                    />
                    <span className="error">{editErr.startTime}</span>
                  </div>
                </div>
                <div className="form-group clt-checkbox">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) => {
                        setEditEvent({
                          ...editEvent,
                          endDate: "",
                          isEndOnNextDate: e.target.checked,
                        });
                        if (e.target.checked) {
                          if (editEvent.date)
                            setEditEvent({
                              ...editEvent,
                              endDate: moment(editEvent.date, "YYYY-MM-DD")
                                .add(1, "days")
                                .format("YYYY-MM-DD"),
                              isEndOnNextDate: e.target.checked,
                            });
                          setEditErr({ ...editErr, endTime: "" });
                        }
                      }}
                      name="isEndOnNextDate"
                      checked={editEvent.isEndOnNextDate}
                    />
                    Session ends on next day?
                  </label>
                </div>
                {editEvent.isEndOnNextDate && (
                  <div className="form-group">
                    <div className="form-group-label">End date</div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        placeholder="dd-mm-yyyy"
                        name="endDate"
                        disabled
                        value={editEvent.endDate}
                      />
                      <span className="error">{editErr.endDate}</span>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <div className="form-group-label">End time*</div>
                  <div className="form-group-ct">
                    <input
                      type="time"
                      placeholder="Select end time"
                      name="endTime"
                      onChange={handleEditChange}
                      value={editEvent.endTime}
                    />
                    <span className="error">{editErr.endTime}</span>
                  </div>
                </div>

                {/* TODO - temporary remove MDS - 5883 */}

                {/* <div className="form-group">
                  <div className="cstm-add-video-title-sub">Access*</div>
                  <div className="form-group mb-0">
                    <div className="form-group-ctst">
                      {allAccessRoles.map((a) => {
                        return (
                          <>
                            <label className="subcat-addvideo">
                              <input
                                type="checkbox"
                                name="accessRoles"
                                value={a._id.toString()}
                                onChange={handleEditChange}
                                checked={editEvent.accessRoles.includes(
                                  a._id.toString()
                                )}
                              />
                              {a.role}
                            </label>
                          </>
                        );
                      })}
                    </div>
                    <span className="error cstm-smg">{editErr.access}</span>
                  </div>
                </div> */}
                <div className="cstm-add-video-title-sub">Reserved</div>

                <div className="form-group">
                  <label className="subcat-addvideo">
                    <input
                      type="checkbox"
                      name="reserve"
                      onChange={handleEditChange}
                      checked={editEvent.reserve}
                    />
                    Reserved
                  </label>
                </div>
                {editEvent.reserve && (
                  <>
                    <div className="form-group">
                      <div className="form-group-label">Reserved Url*</div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Enter reserved url"
                          name="reservedUrl"
                          onChange={handleEditChange}
                          value={editEvent.reservedUrl}
                        />
                        <span className="error">{editErr.reservedUrl}</span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">
                        Button label for listing*
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Enter button label for listing"
                          name="reservedLabelForListing"
                          onChange={handleEditChange}
                          value={editEvent.reservedLabelForListing}
                          maxLength={18}
                        />
                        <span className="text-limitaion">
                          The maximum character limit is 18.
                        </span>
                        <span className="error">
                          {editErr.reservedLabelForListing}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-group-label">
                        Button label for detail*
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Enter button label for detail"
                          name="reservedLabelForDetail"
                          onChange={handleEditChange}
                          value={editEvent.reservedLabelForDetail}
                        />
                        <span className="error">
                          {editErr.reservedLabelForDetail}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="cstm-add-video-title-sub">
                  Notify reminder to user
                </div>
                <div className="form-group">
                  <label className="subcat-addvideo">
                    <input
                      type="checkbox"
                      name="scheduleNotify"
                      onChange={(e) => {
                        setEditEvent({
                          ...editEvent,
                          scheduleNotify: e.target.checked,
                        });
                      }}
                      checked={editEvent.scheduleNotify}
                    />
                    Notify reminder to user
                  </label>
                </div>
                {editEvent.scheduleNotify && (
                  <div className="form-group">
                    <div className="form-group-label">
                      Choose Time Reminder*
                    </div>
                    <div className="form-group-ct">
                      <select
                        onChange={(e) => {
                          setEditEvent({
                            ...editEvent,
                            scheduleNotifyTime: e.target.value,
                          });
                        }}
                        value={editEvent.scheduleNotifyTime}
                        name="scheduleNotifyTime"
                      >
                        {scheduleNotifyTimeOptionList.map((opt) => (
                          <option key={opt.time} value={opt.time}>
                            {opt.label}
                          </option>
                        ))}
                      </select>
                      <span className="error">
                        {editErr.scheduleNotifyTime}
                      </span>
                    </div>
                  </div>
                )}
                <div className="cstm-add-video-title-sub">
                  Notify users about the session schedule changes
                </div>
                <div className="form-group-ct">
                  <label className="subcat-addvideo">
                    <input
                      type="checkbox"
                      name="notifyChanges"
                      value={editEvent.notifyChanges}
                      onChange={(e) => {
                        setEditEvent({
                          ...editEvent,
                          notifyChanges: e.target.checked,
                          notificationText: "",
                        });
                      }}
                      checked={editEvent.notifyChanges}
                      defaultChecked={editEvent.notifyChanges}
                    />
                    Do you want this changes to notify users?
                  </label>
                  <span className="error">{editErr.notifyChanges}</span>
                </div>

                {editEvent.notifyChanges && (
                  <div className="form-group">
                    <div className="form-group-label">Notification text*</div>
                    <div className="form-group-ct">
                      <textarea
                        placeholder="Enter notification text"
                        name="notificationText"
                        onChange={handleEditChange}
                        value={editEvent.notificationText}
                      />
                      {console.log('editErr', editErr)}
                      <span className="error">{editErr.notificationText}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteModalLoader ? "danger" : "secondary"}
        buttonText1={
          !deleteModalLoader ? (
            "Delete"
          ) : (
            <img
              src={Loader2}
              width={50}
              height={15}
              className="btn-loader-img-cover"
            />
          )
        }
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete session"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteName}"? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {/* {showDeleteModal && (
        <DeleteModalWithLoader
          message={message}
          handleDelete={handleDelete}
          onClose={() => setShowDeleteModal(false)}
          loader={deleteModalLoader}
        />
      )} */}
      {cloning && (
        <div className="cstm-clone-msg-container">
          <h3>Cloning Record...</h3>
          <p>Please wait while the record is being cloned.</p>
        </div>
      )}
      {cloneSuccess && (
        <div className="cstm-success-msg-container">
          <div className="cstm-success-msg-container-title">{cloneMsg}</div>
        </div>
      )}
      {/* clone modal */}
      <CloneSessionModal handleCloneSession={handleCloneSession} />
      <AlertComponent />
    </>
  );
};
export default Sessions;
