const chatmoreloader = false;

const ReduChatMoreLoader = (state = chatmoreloader, action) => {
  switch (action.type) {
    case "SETCHATMORELOADER":
      return true;
    case "REMOVECHATMORELOADER":
        return false;
    default:
      return state;
  }
};

export default ReduChatMoreLoader;