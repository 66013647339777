//import component
import FrontendPost from "../FrontendPost";
import Group from "../Group";
import Middlebar_profile from "../UserProfile/Middlebar_profile";
import CreatePostPopupClick from "../CreatePostPopupClick";
//import images
import userImage from "../../../Images/user-icon.png";
import yesicon from "../../../Images/yes.svg";
import CommnetImage from "../../../Images/message.svg";
import likebtn from "../../../Images/heart.svg";
import VideoshareImage from "../../../Images/share.png"

const Middlebar = ({ SelectedGroup, selectedDiv, topicSearch, setselectedDiv }) => {

  const dashboardComponent = () => {
    return (
      <>
     <CreatePostPopupClick/>
        <div
          className="modal fade msg-popup"
          id="postdetail"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content ">
              <div className="header-chat-cont-head-top post-detail post-detail-cstm">
                <div className="header-chat-cont-head-top-left"> </div>
                <div className="header-chat-cont-head-top-right">
                  <button className="view-in-chat-box-icon">×</button>
                </div>
              </div>
              <div className="border-bottom1"></div>
              <div className="postdetail cstm-postdetail">
                <div className="row ">
                  <div className="col-3">
                    <img src={userImage} />
                  </div>
                  <div className="col-auto postdetailcontet">
                    <h5>
                      Cameron Williamson <img src={yesicon} />{" "}
                    </h5>
                    <p>February 27 at 4:58 PM</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="postdetailtext1">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, orem Ipsum is
                      simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard
                      dummy text ever since the 1500s,
                    </p>
                    <p className="postdetailtext2">#Amazon PPC </p>
                  </div>
                </div>
                <div className="video-detail-main">
                  <div className="cstm-linkebtn">
                    <span className="videodetailicon">
                      <img className="icon-align" src={likebtn} />
                      12.5k Likes
                    </span>
                  </div>
                  <div className="cstm-comment-share-btn">
                    <span className="videodetailicon">
                      <img className="icon-align" src={CommnetImage} />
                      12.5k Comments
                    </span>
                    <span className="videodetailicon">
                      <img className="icon-align" src={VideoshareImage} />
                      150 Shares
                    </span>
                  </div>
                </div>
              </div>
              <div className="border-bottom1"></div>
            </div>
          </div>
        </div>
        <FrontendPost topicSearch={topicSearch} />
      </>
    );
  }
  switch (selectedDiv) {
    case "dashboard":
      return (
        dashboardComponent()
      )
    case "group":
      return (<Group SelectedGroup={SelectedGroup} setselectedDiv={setselectedDiv} />)
    case "profile":
      return (<Middlebar_profile />)
    default:
      return (
        dashboardComponent()
      )
  }
};

export default Middlebar;
