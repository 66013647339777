import { Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import authServicePartner from '../../../../Services/auth.service.partner';
import Loader from "../../../../Images/loader.gif";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../../../Images/exportimg.svg"
import moment from 'moment';

const ReviewModal = ({ setRatingmodal, claimId, reviewName, filterType, fromDate, toDate }) => {

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const columns = [
        { id: "name", label: "Name" },
        { id: "star", label: "Stars" },
        { id: "date", label: "Date" },
        { id: "reviewNote", label: "Review" },
    ];

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [loader, setLoader] = useState(false)
    const [reviewData, setReviewData] = useState([])
    const navigate = useNavigate()
    //create function for review list
    function createReviewData(_id, star, name, reviewNote, status, createdAt, userid) {
        return {
            _id, star, name, reviewNote, status, createdAt, userid
        }
    }

    // first render when component is mount
    useEffect(() => {
        reviewListByPartnerApi()
    }, [])

    // Review List PartnerApi calling 
    async function reviewListByPartnerApi() {
        setLoader(true)
        const reviewListRes = await authServicePartner.reviewListByPartnerId(claimId, `?filtertype=all&field=rating&dateFilterType=${filterType !== undefined ? filterType : ""}${filterType !== undefined && filterType === "custom" ? `&fromdate=${fromDate}&todate=${toDate}` : ""}`)
        try {
            if (reviewListRes.data.status) {
                const arr = reviewListRes.data.data[0].reviews
                if (arr.length > 0) {
                    var newArr = []
                    for (let i = 0; i < arr.length; i++) {
                        newArr[i] = createReviewData(
                            arr[i]._id,
                            arr[i].star !== undefined && arr[i].star !== null ? arr[i].star : 0,
                            `${arr[i].userId.display_name ? arr[i].userId.display_name : ""}` ,
                            arr[i].reviewNote !== undefined && arr[i].reviewNote !== null && arr[i].reviewNote !== "" ? arr[i].reviewNote : "-",
                            arr[i].status, arr[i].createdAt, arr[i].userId._id
                        )
                    }
                    setReviewData(newArr)
                } else {
                    setReviewData([])
                }
                setLoader(false)
            } else {
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            return false
        }
    }

    //pagination for page change
    const handleChangeUserPage = (event, newPage) => {
        setPage(newPage);
    };

    //pagination for rows per page change
    const handleChangeUserRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //Export for download xlsx file
    const handleExport = () => {
        // Extract only the desired fields from reviewData and trim whitespace
        const modifiedReviewData = reviewData.map(({ star, name, reviewNote, status, createdAt }) => ({
            Name: (name || '').trim(), // Trim whitespace from name
            Star: star || 0, // Handle null or undefined star values
            Date: (moment(createdAt).format('DD MMM, YYYY')), // Trim whitespace from status and provide a default value if it's empty
            Review: (reviewNote || '').trim(), // Trim whitespace from reviewNote
        }));

        // Create a new sheet with modifiedReviewData
        const convertToexcel = XLSX.utils.json_to_sheet(modifiedReviewData, {
            header: ['Name', 'Star', 'Date', 'Review'], // Specify the header with desired fields
        });

        // Create a new workbook
        const workbook_ = XLSX.utils.book_new();

        // Add the sheet to the workbook and specify the starting position
        XLSX.utils.book_append_sheet(workbook_, convertToexcel, 'Sheet2', { origin: 'A1' });

        // Generate the Excel file
        const downloadExcel = XLSX.write(workbook_, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Create a Blob and save the file
        const dataExcel = new Blob([downloadExcel], { type: fileType });
        FileSaver.saveAs(dataExcel, `${reviewName}Review.xlsx`);
    }

    return (
        <>
            <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
                <div className="desk-globle-model-box-inner">
                    <div className="modal-content">
                        <div className="cstm-replace-pp">

                            <span className="cstm-rpp-close-btn" onClick={() => { setRatingmodal(false); document.body.classList.remove("modal-open"); }}>×</span>
                            <div className="cstm-rpp-title">Partner Review User List</div>
                            <div className="cstm-export-link">
                                <a onClick={handleExport}> <img src={exportImg} />Export</a>
                            </div>
                            <div className="cstm-cstm-viewlist">

                                <Paper className="uic-tb-mn ">
                                    <TableContainer className=" uic-table-main ">
                                        <Table>
                                            <TableHead className="uic-table-th">
                                                <TableRow>
                                                    {columns.map((colmn) => (
                                                        <TableCell
                                                            key={colmn.id}
                                                            className="uic-th-td"
                                                        >{colmn.label}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="uic-table-td">
                                                {loader ?
                                                    <div className="cstm-data-load"><img src={Loader} /> </div>
                                                    :
                                                    reviewData !== undefined && reviewData.length > 0 ?
                                                        reviewData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((reviews, i) => {
                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                    >
                                                                        <TableCell className='uic-th-td w-15'> <a className="cstm-view-users-count" onClick={() => {

                                                                            navigate(`/user/edituser?id=${reviews.userid}`);
                                                                        }} >{reviews.name}</a></TableCell>
                                                                        <TableCell className='uic-th-td w-15'>{reviews.star + "." + "0"}</TableCell>
                                                                        <TableCell className='uic-th-td w-15'>{moment(reviews.createdAt).format('MMM DD, YYYY')}</TableCell>
                                                                        <TableCell className='uic-th-td w-30'>{reviews.reviewNote !== undefined && reviews.reviewNote !== null && reviews.reviewNote !== "" ? reviews.reviewNote : "-"}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }) :
                                                        <span className="no-data-found">No data found</span>
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className="uic-pagination"
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={reviewData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangeUserPage}
                                        onRowsPerPageChange={handleChangeUserRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewModal