import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
// Components
import Avtar from "../../../components/Avtar/Avtar";
import OutgoingMessageTime from "./HelperComponets/OutgoingMessageTime";
import Reaction from "./HelperComponets/Reaction";
import IncomingMessageActions from "./HelperComponets/IncomingMessageActions";
import MessageTail from "./HelperComponets/MessageTail";
import OutgoingMessageActions from "./HelperComponets/OutgoingMessageActions";
import IncomingMessageTime from "./HelperComponets/IncomingMessageTime";

// Icon
import FileIcon from "../../../components/Icons/GeneralIcons/FileIcon";
import DropDownItem from "../../../components/DropDownItem/DropDownItem";
// Style
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatMessagesBubble.css";
import "./Styles/ChatMessagesFile.css";

const ChatMessagesFile = ({
  menuSelectedDivRef,
  uniqueId = uuidv4(),
  // Main Components Prompts
  type = "Channel/Group",
  isOutgoing = false,
  isHighlighted = false,
  isStacked = false,
  files = {
    fileName: "filename.svg",
    size: "55KB",
  },

  // Avatar
  AvatarType = "photo",
  Avtarsrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  AvtarLetter = "M",
  AvtarIcon = "",

  // OutgoingMessageTime
  isRead = true,
  hasBackdrop = false,
  isEdited = false,
  time = "09:12 AM",
  handleEmojiClick,
  handleReplyClick,
  handleDotsClick,
  handleReactionClick,
  actionModel,
  setActionModel,
  // reaction
  hasAction = false,
  showAllEmogis,
  toggleShowEmojis,
  initialEmojis,
  reactions,
  setReactions,
  showEmojis,
  showDropdown,
  handleSelectEmoji,
  setShowDropdown,
  setShowEmojis,
  reactionMsg,
  chatId,
  dropDownOptions,
  onSelectOptions,
  chatData,
  hasCounter = true,
  hasReactions = false,
  hasReaction2 = false,
  hasReaction3 = false,
  hasReaction4 = false,
  emoji1 = "👍",
  emoji2 = "😂",
  emoji3 = "👌",
  emoji4 = "🙌",
  counter = 5,
  handleReactionUsersList,
  isInProgress,
  handlePreviewClick,
}) => {
  const [hoverEffect, setHoverEffect] = useState(hasAction);
  const [dropDownClass, setDropDownClass] = useState("bottom-drop-down");

  const manageMouseEnter = () => {
    setHoverEffect(true);
  };

  const manageMouseLeave = () => {
    if (
      menuSelectedDivRef === null ||
      menuSelectedDivRef.current !== uniqueId
    ) {
      setHoverEffect(false);
    } else if (showEmojis || actionModel) {
      setHoverEffect(true);
    } else {
      setHoverEffect(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // event.preventDefault();
      let emojiDiv = document.getElementById("REACTION_EMOJIS");
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        emojiDiv &&
        !emojiDiv.contains(event.target) &&
        setActionModel
      ) {
        setHoverEffect(false);
        setActionModel(false);
        setShowEmojis(false);
        setShowDropdown(false);
        menuSelectedDivRef = null;
      }
    };
    function handleScroll(event) {
      // setHoverEffect(false);
    }
    const scrollableDiv = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
    scrollableDiv.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (menuSelectedDivRef?.current === null) {
      setHoverEffect(false);
    }
  }, [menuSelectedDivRef?.current]);
  const dropdownRef = useRef(null);

  const handleOptionSelect = (selectedOption) => {
    if (onSelectOptions) {
      onSelectOptions(selectedOption, chatData); // Pass chat data as an argument
    }
  };

  return (
    <>
      <div
        id={uniqueId}
        className="chat-message-bubble-sec"
        onMouseEnter={manageMouseEnter}
        onMouseLeave={manageMouseLeave}
        ref={dropdownRef}
      >
        <div
          className={`chat-message-bubble-inner flex chatmessage-files-inner ${
            type !== "Personal" ? "chat-channel-group" : ""
          } ${!isOutgoing ? "incoming" : "outgoing"} ${
            isStacked ? "stacked-item" : ""
          }`}
        >
          {/* <div>Outgoing False </div> */}
          {isHighlighted && <div className="highlight"></div>}
          <div
            className={`chat-message-container ${
              hasReactions ? "reaction-on" : ""
            }`}
          >
            <div className="chat-avtar-message flex items-end">
              <div className="chat-avtar flex">
                {type !== "Personal" && !isOutgoing && (
                  <Avtar
                    type={AvatarType}
                    src={Avtarsrc}
                    letter={AvtarLetter}
                    icon={AvtarIcon}
                    size="40"
                  />
                )}
              </div>
              <div className="chat-body flex items-end relative">
                <div className="chat-tail">
                  <MessageTail isOutgoing={isOutgoing} />
                </div>
                <div
                  className={`chat-message ${isStacked ? "stacked" : ""}`}
                  onClick={() => handlePreviewClick(files, "", "", "file")}
                >
                  {/* Files sections */}
                  <div className="chatmessage-advanced relative flex items-center">
                    <FileIcon
                      width={24}
                      height={24}
                      iconColor="var(--color-icon-neutral-strong)"
                    />
                    <div className="document-content">
                      <p className="file-title">{files.fileName}</p>
                      <p className="file-size">{files.size}</p>
                    </div>
                  </div>

                  <div className="chat-message-time">
                    {isOutgoing ? (
                      <OutgoingMessageTime
                        isRead={isRead}
                        hasBackdrop={hasBackdrop}
                        isEdited={isEdited}
                        time={time}
                        isInProgress={isInProgress}
                      />
                    ) : (
                      <IncomingMessageTime
                        hasBackdrop={hasBackdrop}
                        isEdited={isEdited}
                        time={time}
                        isInProgress={isInProgress}
                      />
                    )}
                  </div>
                </div>
                <div className="chat-actions">
                  {!isInProgress && (hasAction || hoverEffect) && isOutgoing ? (
                    <OutgoingMessageActions
                      handleDotsClick={(e) => {
                        menuSelectedDivRef.current = uniqueId;

                        handleDotsClick(e);
                        let heights = window.innerHeight - e.pageY;
                        if (heights < 500) {
                          setDropDownClass("top-drop-down");
                        }
                      }}
                      handleReplyClick={handleReplyClick}
                      handleEmojiClick={(e) => {
                        handleEmojiClick(chatId, e);
                        menuSelectedDivRef.current = uniqueId;
                      }}
                      chatId={chatId}
                    />
                  ) : !isInProgress && (hasAction || hoverEffect) ? (
                    <IncomingMessageActions
                      handleDotsClick={(e) => {
                        menuSelectedDivRef.current = uniqueId;
                        handleDotsClick(e);
                        let heights = window.innerHeight - e.pageY;

                        if (heights < 500) {
                          setDropDownClass("top-drop-down");
                        }
                      }}
                      handleReplyClick={handleReplyClick}
                      handleEmojiClick={(e) => {
                        handleEmojiClick(chatId, e);
                        menuSelectedDivRef.current = uniqueId;
                      }}
                      chatId={chatId}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {hasReactions && (
              <div className="chat-reaction">
                <Reaction
                  isOutgoing={isOutgoing}
                  hasCounter={hasCounter}
                  counter={counter}
                  handleReactionClick={handleReactionClick}
                  handleReactionUsersList={handleReactionUsersList}
                  hasReactions={hasReactions}
                  hasReaction2={hasReaction2}
                  hasReaction3={hasReaction3}
                  hasReaction4={hasReaction4}
                  emoji1={emoji1}
                  emoji2={emoji2}
                  emoji3={emoji3}
                  emoji4={emoji4}
                  chatId={chatId}
                />
              </div>
            )}
            <div className={`chat-action-menu ${dropDownClass}`}>
              {actionModel && (
                <DropDownItem
                  size="small"
                  options={dropDownOptions}
                  isIcon={true}
                  onSelect={handleOptionSelect}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ChatMessagesFile.propTypes = {
  type: PropTypes.oneOf(["Channel/Group", "Personal"]),
  // Main Component prompts
  isOutgoing: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isStacked: PropTypes.bool,
  hasTime: PropTypes.bool,
  hasReactions: PropTypes.bool,
  hasAction: PropTypes.bool,
  files: PropTypes.object,

  // Avatar
  AvatarType: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  Avtarsrc: PropTypes.string,
  AvtarLetter: PropTypes.string,
  AvtarIcon: PropTypes.element,

  // OutgoingMessageTime
  isRead: PropTypes.bool,
  hasBackdrop: PropTypes.bool,
  isEdited: PropTypes.bool,
  time: PropTypes.string,

  // Reaction
  hasCounter: PropTypes.bool,
  hasReaction2: PropTypes.bool,
  counter: PropTypes.number,

  // Actions
  handleEmojiClick: PropTypes.func,
  handleReplyClick: PropTypes.func,
  handleDotsClick: PropTypes.func,
  handleReactionClick: PropTypes.func,
  actionModel: PropTypes.any,
  setActionModel: PropTypes.func,
  showAllEmogis: PropTypes.any,
  toggleShowEmojis: PropTypes.func,
  initialEmojis: PropTypes.any,
  reactions: PropTypes.any,
  setReactions: PropTypes.func,
  showEmojis: PropTypes.bool,
  showDropdown: PropTypes.any,
  handleSelectEmoji: PropTypes.func,
  setShowDropdown: PropTypes.func,
  setShowEmojis: PropTypes.func,
  reactionMsg: PropTypes.any,
  chatId: PropTypes.string,

  //DropDown items
  dropDownOptions: PropTypes.array,
  onSelectOptions: PropTypes.func,
  chatData: PropTypes.object,
  handleReactionUsersList: PropTypes.func,
  isInProgress: PropTypes.bool,
};

export default ChatMessagesFile;
