import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import Services from "../../../Services/auth.service"
import { fetchsavedpost, fetchlatestfiles } from "../../../Action/index.js";

//import components
import UpgradePlanPopup from "../UpgradePlanPopup";
import Header from "../Dashboard/Header";
import LeftSidebar from "../Dashboard/LeftSidebar";
import RightSidebar from "../Dashboard/RightSidebar";
import Middlebar from "../Dashboard/Middlebar.js";
import AllGroupMobile from "../Dashboard/AllGroupMobile";
import AnnoucementPopup from "../Dashboard/AnnoucementPopup";
import EventListPopup from "../Dashboard/EventListPopup";
import FilesPopup from "../Dashboard/FilesPopup";
import ForumListPopup from "../Dashboard/ForumListPopup";
import NotificationListPopup from "../Dashboard/NotificationListPopup";
import SavedPostPopup from "../Dashboard/SavedFilesPopup";
import Footer from "../Dashboard/Footer";
import GloblePopup from "../Dashboard/GloblePopup";
import UserListingPopup from "../Dashboard/UserListingPopup.js";
import LoaderComponent from "../LoaderComponent";
import Mastercard from "../../../Images/mastercard.png";
import bigdelimg from "../../../Images/BackendImages/delete-icon.svg";
import Yesmsg from "../../../Images/yes-msg.svg";
import Alert from "../../../Images/alert.svg";


const BillingInfo = () => {
  const dispatch = useDispatch();
  let history = useNavigate();
  const [upgradeselectedPlan , setupgradeselectedPlan] = useState('');
  const [loading, setloading] = useState(false)
  const [upgradepopup , setupgradepopup] = useState(false);
  const [leftsidebar, setleftsidebar] = useState(true);
  const [rightsidebar, setrightsidebar] = useState(true);
  const [SelectedGroup, setSelectedGroup] = useState("");
  const [selectedDiv, setselectedDiv] = useState("dashboard");
  const [topicSearch, settopicSearch] = useState("");
  const [billinginfo , setbillinginfo] = useState({});
  const [planerr, setplanerr] = useState({ status: false, msg: '' })
    const [openingpop, setopenpop] = useState({
      annou: false,
      files: false,
      save: false,
      event: false,
      chat: false,
      forum: false,
      notifi: false,
      allgroup: false,
    });

  useEffect(() => {
    if (
      localStorage.getItem("idtoken") === null ||
      localStorage.getItem("idtoken").length === 0 ||
      localStorage.getItem("userid") === null ||
      localStorage.getItem("userid").length === 0 ||
      localStorage.getItem("username") === null ||
      localStorage.getItem("username").length === 0 ||
      localStorage.getItem("role") === null ||
      localStorage.getItem("role") !== "user" ||
      localStorage.getItem("logintime") === undefined ||
      localStorage.getItem("logintime") === null
    ) {
      window.location.href = "/";
    }
    else{
      setloading(true)
      setSelectedGroup("");
      setselectedDiv("dashboard");
      settopicSearch("");
      dispatch(fetchsavedpost())
      dispatch(fetchlatestfiles())
      Services.getbillinginfo().then((res)=>{
        setloading(false)
        if(res.data.status)
          {
            setbillinginfo(res.data.data)
            setupgradeselectedPlan(res.data.data)
          }
      }).catch((e)=>{console.log(e);setloading(false);})
    }
    
  }, []);
  const cancelPlan = () => {
    Services.cancelPlan().then((res)=>{
      if(res.data.status)
      {
        Services.getbillinginfo().then((res)=>{
          if(res.data.status)
          {
            setbillinginfo(res.data.data)
          }
         }).catch((e)=>{console.log(e)})
      }
      setplanerr({status: res.data.status, msg: res.data.message})
      setTimeout(() => {
        setplanerr({ status: false, msg: "" });
      }, 3000);
    }).catch((e) => {
      console.log(e);
      setplanerr({status: false , msg: "Something went wrong!"}); 
      setTimeout(() => {
        setplanerr({ status: false, msg: "" });
      }, 3000);
    })
  }
  const updatePlan = (selectedplan) => {
    Services.updatePlan({plan_id: selectedplan._id}).then((res)=>{
      if(res.data.status)
      {
        Services.getbillinginfo().then((response)=>{
          if(response.data.status)
          {
            setbillinginfo(response.data.data)
          }
         }).catch((e)=>{
          console.log(e);
        })
      }
      setplanerr({status: res.data.status, msg: res.data.message})
      setTimeout(() => {
        setplanerr({ status: false, msg: "" });
      }, 3000);
    }).catch((e)=>{
      console.log(e);
      setplanerr({status: false , msg: "Something went wrong!"});
      setTimeout(() => {
        setplanerr({ status: false, msg: "" });
      }, 3000);
    })
  }
  return (
    <>
      <Header
        setSelectedGroup={setSelectedGroup}
        setselectedDiv={setselectedDiv}
        settopicSearch={settopicSearch}
      />
      <div
        className={
          leftsidebar && rightsidebar
            ? "all-content-main cstm-all-content-main"
            : leftsidebar === false && rightsidebar
            ? "all-content-main mn-cnt-wd"
            : rightsidebar === false && leftsidebar
            ? "all-content-main mn-cnt3-wd"
            : "all-content-main mn-cnt-both"
        }
      >
        {/* <div
          className={
            leftsidebar ? "sidebar-icon " : "sidebar-icon sidebar-icon2"
          }
          onClick={() => {
            leftsidebar ? setleftsidebar(false) : setleftsidebar(true);
          }}
        >
          <i className="fa fa-angle-left"></i>
        </div> */}
        <div
          className={
            leftsidebar
              ? "all-content-main-left cstm-all-content-main-left"
              : "all-content-main-left sidebar-hd"
          }
        >
          <LeftSidebar
            setSelectedGroup={setSelectedGroup}
            setselectedDiv={setselectedDiv}
            selectedDiv={selectedDiv}
            SelectedGroup={SelectedGroup}
          />
        </div>
        {billinginfo.payment_info !== undefined ? (
          <div className="all-content-main-center cstm-all-content-center">
            {planerr.msg.length > 0 && planerr.status === false ? (
              <div className="post-msg-all post-sucessfully">
                <img src={Yesmsg} />
                {planerr.msg}
                <button className="post-close-msg">✕</button>
              </div>
            ) : (
              ""
            )}

            {planerr.msg.length > 0 && planerr.status ? (
              <div className="post-msg-all post-alert">
                <img src={Alert} />
                {planerr.msg}
                <button className="post-close-msg">✕</button>
              </div>
            ) : (
              ""
            )}

            <div className="um-billing-info cstm-um-billing-info">
              <div className="um-billing-info-lable">
                {billinginfo !== undefined
                  ? billinginfo.recurring_timeframe
                  : ""}{" "}
                Payment
              </div>
              <div className="um-billing-info-details">
                <div className="um-billing-info-left">
                  <div className="um-billing-info-price">
                    <sup>$</sup>
                    <span className="um-billing-info-price-in">
                      {billinginfo !== undefined ? billinginfo.plan_price : ""}
                    </span>
                  </div>
                  <div>
                    <div className="um-billing-info-next">Next payment on</div>
                    <div className="um-billing-info-date">
                      {moment(billinginfo.payment_info.expire_date).format(
                        "DD MMM, YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <div className="um-billing-info-right">
                  {/* <p>Subscription period: 1 year</p> */}
                  <div>
                    {billinginfo !== undefined &&
                    billinginfo.automatic_renewal ? (
                      <p>Automatic renewal (recurring payments)</p>
                    ) : (
                      <></>
                    )}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: billinginfo.plan_description,
                      }}
                    ></div>
                  </div>
                  {/* <p>Includes $1,000 Event Credit</p> */}
                  <div className="um-billing-info-right-upgrade">
                    <button
                      className="btn2"
                      onClick={() => {
                        setupgradepopup(true);
                      }}
                    >
                      Upgrade Plan
                    </button>{" "}
                    <span className="cancle-subscription-link">
                      <button
                        data-toggle="modal"
                        data-target="#cancelsubscription"
                      >
                        Cancel Subscription
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {billinginfo.payment_info.card_number ? (
              <div className="um-payment-info">
                <p>Payment Info</p>
                <div className="um-billing-info-main">
                  <div className="um-billing-info-main-left">
                    <img src={Mastercard} />
                    {billinginfo.payment_info.card_number.replaceAll("*", "X")}
                  </div>
                  {/* <div className="um-billing-info-main-right">
              <button className="cstm-remove-link">Remove</button>
            </div> */}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="um-billing-information">
              <span className="um-billing-informatio-title">
                Billing Information
              </span>
              <div className="um-billing-information-table">
                <table className="bitable">
                  <tr>
                    <th>Date</th>
                    <th>Subscription type</th>
                    <th>Price</th>
                  </tr>
                  {billinginfo.billing_info.map((invoice,i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {moment(invoice.createdAt).format("DD MMM, YYYY")}
                        </td>
                        <td>
                          {invoice.membership_plan_id.recurring_timeframe}
                        </td>
                        <td>{"$" + invoice.membership_plan_id.plan_price}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
            <div
              className="modal fade msg-popup"
              id="cancelsubscription"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    &times;
                  </button>
                  <div className="msg-icon">
                    <img src={bigdelimg} />
                  </div>
                  <h1>Are you sure want to cancel this subscription?</h1>
                  <div className="row">
                    <div className="col-6">
                      <span className="msg-btn">
                        <button
                          type="button"
                          className="btn btn-red"
                          onClick={() => {
                            cancelPlan();
                          }}
                          data-dismiss="modal"
                        >
                          Yes
                        </button>
                      </span>
                    </div>
                    <div className="col-6">
                      <span className="msg-btn">
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                        >
                          No
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {upgradepopup ? (
              <UpgradePlanPopup
                setopen={setupgradepopup}
                upgradeselectedPlan={upgradeselectedPlan}
                updatePlan={updatePlan}
              />
            ) : (
              <></>
            )}

            {/* <GloblePopup trigger={openingpop}> */}
            {openingpop.annou ? (
              <AnnoucementPopup setclose={setopenpop} openingpop={openingpop} />
            ) : (
              <></>
            )}
            {/* </GloblePopup> */}
            {openingpop.files ? (
              <FilesPopup setclose={setopenpop} openingpop={openingpop} />
            ) : (
              <></>
            )}
            {openingpop.save ? (
              <SavedPostPopup setclose={setopenpop} openingpop={openingpop} />
            ) : (
              <></>
            )}
            {openingpop.event ? (
              <EventListPopup setclose={setopenpop} openingpop={openingpop} />
            ) : (
              <></>
            )}
            {openingpop.forum ? (
              <ForumListPopup setclose={setopenpop} openingpop={openingpop} />
            ) : (
              <></>
            )}
            {openingpop.notifi ? (
              <NotificationListPopup
                setclose={setopenpop}
                openingpop={openingpop}
              />
            ) : (
              <></>
            )}
            {openingpop.allgroup ? (
              <AllGroupMobile setclose={setopenpop} openingpop={openingpop} />
            ) : (
              <></>
            )}
            {openingpop.chat ? (
              <UserListingPopup setclose={setopenpop} openingpop={openingpop} />
            ) : (
              <></>
            )}
          </div>
        ) : loading ? (
          <div className="all-content-main-center cstm-all-content-center">
            {" "}
            <div className="loadind-post">
              <LoaderComponent />{" "}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={
            rightsidebar
              ? "all-content-main-right cstm-all-content-main-right"
              : "all-content-main-right  sidebar-hd"
          }
        >
          <RightSidebar selectedDiv={selectedDiv} />
        </div>
        {/* <Footer openingpop={openingpop} setopenpop={setopenpop} /> */}

        {/* <div
          className={
            rightsidebar ? "sidebar2-icon " : "sidebar2-icon sidebar2-icon2"
          }
          onClick={() => {
            rightsidebar ? setrightsidebar(false) : setrightsidebar(true);
          }}
        >
          <i className="fa fa-angle-right"></i>
        </div> */}
      </div>
    </>
  );
};
export default BillingInfo;
