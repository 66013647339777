import React, { useEffect } from "react";
import "./Styles/ChatCell.css";
import PropTypes from "prop-types";
import "../../../Styles/Theme/Theme.css";
import Avtar from "../../../components/Avtar/Avtar";
import ChatTitle from "./HelperComponets/ChatTitle";
import ChatMessageType from "./HelperComponets/ChatMessageType";
import ChatBadgeMention from "./HelperComponets/ChatBadgeMention";
import Badges from "../../../components/Badge/Badges";

const ChatCell = ({
  type = "Personal",
  groupLable = "W",
  hasText = true,
  time = "Yesterday",
  name = "Name",
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  hasCounter = true,
  messageType,
  muteStatus = "NotMuted",
  senderName = "nickname",
  messageCount = "2",
  hasMentions = false,
  hasSender = false,
  handleClick,
  contextMenuId,
  setContextMenuId,
  quote_btn_start,
  setquote_btn_start,
  quote_btn,
  setquotebtn,
  setContextMenuChatType,
  setContextmenu,
  chat,
  handleContextMenu,
  staredLi,
  chatLastMessage,
  selectedClassName,
  setContextMenuName,
  hasStatus = true,
  badgeStatus,
  isOnline,
}) => {
  return (
    <div
      className={`${selectedClassName ? "chat-active" : ""} chat-cell`}
      onClick={handleClick}
      onContextMenu={(e) => {
        e.preventDefault();
        let x =
          e.clientX + 100 > window.innerWidth ? e.clientX - 100 : e.clientX;
        let y =
          e.clientY + 100 > window.innerHeight ? e.clientY - 100 : e.clientY;
        setContextmenu({
          y: y,
          x: x,
        });
        setContextMenuId(chat.id);
        setContextMenuChatType(chat.type);
        setContextMenuName(chat.firstname);
        if (staredLi) {
          setquotebtn(false);
          setquote_btn_start(true);
        } else {
          setquotebtn(true);
          setquote_btn_start(false);
        }
      }}
    >
      <div className="chat-cell-avatar">
        <Avtar
          badgeStatus={type === "Personal" && isOnline ? "online" : "offline"}
          size="48"
          src={src}
          type={src && src.length ? "photo" : "letter"}
          letter={groupLable}
          hasStatus={hasStatus}
          badgeSize="medium"
        />
      </div>

      <div className="chat-cell-container">
        <div className="chat-cell-container-text">
          <ChatTitle name={name} type={muteStatus} />
          {hasText && (
            <ChatMessageType
              type={messageType}
              hasSender={hasSender}
              label={chatLastMessage}
              name={senderName}
            />
          )}
        </div>
        <div className="chat-cell-container-time">
          <p className="chat-cell-container-time-in">{time}</p>
          <div className="chat-cell-container-cont">
            {hasMentions && hasCounter && <ChatBadgeMention />}
            {hasCounter > 0 && (
              <Badges
                className="badges-danger medium"
                label={messageCount}
                size="medium"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ChatCell.propTypes = {
  type: PropTypes.oneOf(["Personal", "Group"]),
  isSelected: PropTypes.bool,
  hasText: PropTypes.bool,
  hasCounter: PropTypes.bool,
  hasMentions: PropTypes.bool,
  time: PropTypes.string,
  groupLable: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  isOnline: PropTypes.bool,
  hasStatus: PropTypes.bool,
  muteStatus: PropTypes.oneOf(["NotMuted", "SoundOff", "Muted"]),
  messageType: PropTypes.oneOf(["text", "image", "video", "file", "voice"]),
  senderName: PropTypes.string,
  messageCount: PropTypes.string,
  hasSender: PropTypes.bool,
  handleClick: PropTypes.func,
  setContextmenu: PropTypes.func,
  setContextMenuId: PropTypes.func,
  setContextMenuChatType: PropTypes.func,
  setContextMenuName: PropTypes.string,
  setquotebtn: PropTypes.func,
  setquote_btn_start: PropTypes.func,
  chatId: PropTypes.string,
  handleStarUser: PropTypes.func,
  handleClearChat: PropTypes.func,
  setdeleteConversationPopup: PropTypes.func,
  handleContextMenu: PropTypes.func,
  chat: PropTypes.string,
  staredLi: PropTypes.bool,
  selectedClassName: PropTypes.bool,
};

export default ChatCell;
