const chatpageloader = false;

const ReduChatPageLoader = (state = chatpageloader, action) => {
  switch (action.type) {
    case "SETCHATPAGELOADER":
      return true;
    case "REMOVECHATPAGELOADER":
        return false;
    default:
      return state;
  }
};

export default ReduChatPageLoader;