import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// Import Images
import logo from "../../../Images/logo.png";
import StripePaymentForm from "./stripePayment"

const Payment = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const { stepno } = useParams();
  const regidetail = useSelector((state) => {
    return state.ReduRegistrationDetail;
  })
  useEffect(() => {
    if((regidetail === undefined || !regidetail.register_status || regidetail.selectedplan === undefined ) && stepno === "5")
    {
      window.location.href = "/"
    }  
  },[])

  useEffect(() => {
    if((regidetail === undefined || !regidetail.register_status || regidetail.selectedplan === undefined ) && stepno === "5")
    {
      window.location.href = "/"
    }  
  },[stepno])
  return (
    regidetail !== undefined && regidetail.register_status && regidetail.selectedplan !== undefined ? 
      <>
      <div className="um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left um-payment-left">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn">
                  <div className="um-title2">Payment</div>
                  <div className="um-text">
                    Please provide the best details to personally reach you.
                    This is used for event invites and other important updates.
                  </div>
                  <div className="um-ysp">
                    <div className="um-ysp-left">
                      <div className="um-ysp-lable">Membership</div>
                      <div className="um-ysp-price">
                        <sup>$</sup>
                        <span className="um-ysp-price-in">{regidetail.selectedplan.plan_price.toLocaleString()  + ".00 USD"}</span>
                      </div>
                    </div>
                    <div className="um-ysp-right">
                    {
                      regidetail.selectedplan.automatic_renewal ? <p>{regidetail.selectedplan.recurring_timeframe.charAt(0).toUpperCase() + regidetail.selectedplan.recurring_timeframe.slice(1).toLowerCase()} Recurring</p> : <></>
                    }
                      <div dangerouslySetInnerHTML={{__html: regidetail.selectedplan.plan_description}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="col-sm-6 um-right">
              <div className="um-right-in2 cstm-payment-chng">
                <div className="um-right-in-title">Pay With Card</div>
                <div className="um-right-in-sub">
                  Please enter your credit card information for payment.
                </div>
                <hr className="um-line" />

                  <StripePaymentForm selectedplan={regidetail.selectedplan}/>
              </div>
            </div>
           
          </div>
        </div>
      </div> 
    </>
      : <></>
   
  );
};

export default Payment;
