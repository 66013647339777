import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Services from "../../../Services/auth.service";
import { fetchsavedvideos } from "../../../Action";
import Dotted from "../../../Images/dotted.svg";
import videoimg from "../../../Images/videos.svg";

const SaveVideosList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedvideos = useSelector((state) => {
    return state.ReduSavedVideos;
  });
  const handleSave = (videoid) => {
    Services.savevideo(videoid)
      .then((res) => {
        dispatch(fetchsavedvideos());
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return savedvideos.length > 0 ? (
    <ul>
      {savedvideos.map((video) => {
        return (
          <li
            onClick={() => {
              navigate(`/videos/${video._id}`);
              localStorage.setItem(
                "videosec",
                video.user_video_pause !== undefined &&
                  video.user_video_pause[localStorage.getItem("userid")] !==
                    undefined
                  ? video.user_video_pause[localStorage.getItem("userid")]
                  : 0
              );
            }}
          >
            <div className="cstm-sg-list">
              <img
                src={
                  video.thumbnail
                    ? process.env.REACT_APP_AWS_IMG_VID_PATH + video.thumbnail
                    : videoimg
                }
              />
            </div>
            <div className="cstm-sg-ttl-name">
              <a className="cstm-sg-ttl-main">{video.title}</a>
              <span className="cstm-sg-sb">
                {video.likes !== undefined ? video.likes.length : 0} likes
              </span>
            </div>
            <div className="cstm-sg-dotted">
              <span className="remove-srarred-img" data-toggle="dropdown">
                <img src={Dotted} />
              </span>
              <div
                className="dropdown-menu remove-srarred-in"
                onClick={() => {
                  handleSave(video._id);
                }}
              >
                Remove
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  ) : (
    <span className="emptymsg">No Data Found</span>
  );
};

export default SaveVideosList;
