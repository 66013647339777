import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import components
import BecomeMember from "./BecomeMember";
import MembershipDetail from "./MembershipDetail";
import Payment from "./Payment";
import Questions from "./Questions";
import Register from "./Register";
import Thankyou from "./Thankyou";
import "../../../Styles/registration.css";
import PreferredEmail from "./PreferredEmail.js";

const FirstStepRegister = () => {
  const { stepno } = useParams();
  const [step, setStep] = useState(1);
  const [userDetail, setuserDetail] = useState([]);
  const [userQuestion, setuserQuestion] = useState([]);
  const [selectedplan, setselectedplan] = useState({})
  const [paymentId, setpaymentId] = useState('');
  useEffect(() => {
    setStep(parseInt(stepno));
  },[stepno])
  switch (step) {
    case 1:
      return <BecomeMember />;
    case 2:
      return <PreferredEmail />;
    case 3:
      return <Register setuserDetail={setuserDetail} />;
    case 4:
      return <MembershipDetail setselectedplan={setselectedplan} selectedplan={selectedplan}/>;
    case 5:
      return <Payment />;
    case 6:
      return <Questions userDetail={userDetail} setuserDetail={setuserDetail} userQuestion={userQuestion} setuserQuestion={setuserQuestion} paymentId = {paymentId}/>;
    case 7:
      return (
        <Thankyou/>
      );
    default:
      return (<BecomeMember/>);
  }
};

export default FirstStepRegister;
