import { useEffect, useState } from "react";
import Services from "../../../Services/auth.service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchmemberprofile, fetchmyaccount } from "../../../Action";
import Dummy from "../../../Images/dummy.jpg";
import loading from "../../../Images/loader.gif";

const FollowersFollowingList = ({ member_uid, type }) => {
  const dispatch = useDispatch();
  const [list, setlist] = useState([]);
  const [dislist, setdislist] = useState([]);
  const [text, settext] = useState("");
  const [loader, setloader] = useState(false);
  const [requestlist, setrequestlist] = useState([]);
  const [current_userfollower, setcurrent_userfollower] = useState([])
  const [current_userfollowing, setcurrent_userfollowing] = useState([])
  const history = useNavigate();
  useEffect(() => {
    Services.getsentrequestlist()
      .then((res) => {
        if (res.data.success) {
          setrequestlist(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getuserfollower().then((res)=>{
      if(res.data.success)
      {
        console.log(res.data.data.followers)
        if(member_uid === localStorage.getItem("userid") && type === "follower")
        {
          setlist(res.data.data.followers)
          setdislist(res.data.data.followers)
        } 
        setcurrent_userfollower(res.data.data.followers)
      } else if(member_uid === localStorage.getItem("userid") && type === "follower")
      {
        setlist([])
        setdislist([])
      } 
    }).catch((e) => {
      console.log(e)
    })
    Services.getuserfollowing().then((res)=>{
      if(res.data.success)
      {
        if(member_uid === localStorage.getItem("userid") && type === "following")
        {
          setlist(res.data.data.following)
          setdislist(res.data.data.following)
        } 
        setcurrent_userfollowing(res.data.data.following)
      } else if(member_uid === localStorage.getItem("userid") && type === "following")
      {
        setlist([])
        setdislist([])
      } 
    }).catch((e) => {
      console.log(e)
    })
    if (member_uid !== undefined && member_uid.length > 0 && member_uid !== localStorage.getItem("userid")) {
      if (type === "follower") {
        setloader(true);
        Services.othermemberfollowers(member_uid)
          .then((res) => {
            if (res.data.success) {
              setdislist(res.data.data);
              setlist(res.data.data);
            }
            setloader(false);
          })
          .catch((e) => {
            console.log(e);
            setloader(false);
          });
      } else if (type === "following") {
        setloader(true);
        Services.othermemberfollowing(member_uid)
          .then((res) => {
            if (res.data.success) {
              setdislist(res.data.data);
              setlist(res.data.data);
            }
            setloader(false);
          })
          .catch((e) => {
            setloader(false);
            console.log(e);
          });
      }
    }
  }, [member_uid, type]);
  const followrequest = (mem_id) => {
    Services.sendfollowrequest(mem_id)
      .then((res) => {
        if (res.data.status) {
          if(member_uid === localStorage.getItem("userid"))
          {
            setrequestlist([...requestlist, {recipient: {_id: mem_id} , requester: {_id: localStorage.getItem('userid')}}])
          }
          else{
            var arr = [];
            var arr2 = [];
            for (var i = 0; i < list.length; i++) {
              if (list[i].id === mem_id) {
                arr[i] = { ...list[i], request_send_byAuthUser: true };
              } else {
                arr[i] = list[i];
              }
            }
            for (var i = 0; i < dislist.length; i++) {
              if (dislist[i].id === mem_id) {
                arr2[i] = { ...dislist[i], request_send_byAuthUser: true };
              } else {
                arr2[i] = dislist[i];
              }
            }
            setdislist(arr2);
            setlist(arr);
          }
          
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const unfollowrequest = (mem_id) => {
    Services.sendunfollowrequest(mem_id)
      .then((res) => {
        if (res.data.status) {
          if (member_uid === localStorage.getItem("userid")) {
            var arr = [];
            var arr2 = [];
            for (var i = 0; i < list.length; i++) {
              if (list[i].id !== mem_id) {
                arr[i] = list[i];
              }
            }
            for (var i = 0; i < dislist.length; i++) {
              if (dislist[i].id !== mem_id) {
                arr2[i] = dislist[i];
              }
            }
            dispatch(fetchmyaccount());
            setdislist(arr2);
            setlist(arr);
          } else {
            var arr = [];
            var arr2 = [];
            for (var i = 0; i < list.length; i++) {
              if (list[i].id === mem_id) {
                arr[i] = { ...list[i], follow_byAuthUser: false };
              } else {
                arr[i] = list[i];
              }
            }
            for (var i = 0; i < dislist.length; i++) {
              if (dislist[i].id === mem_id) {
                arr2[i] = { ...dislist[i], follow_byAuthUser: false };
              } else {
                arr2[i] = dislist[i];
              }
            }
            setdislist(arr2);
            setlist(arr);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const cancelrequest = (mem_id) => {
    Services.cancelfollowrequest(mem_id).then((res) => {
      if (res.data.status) {
        
        if (member_uid === localStorage.getItem("userid")) {
          var arr = [];
          var n = 0
          for(var i = 0 ; i < requestlist.length ; i++)
          {
            if(requestlist[i].recipient._id !== mem_id && requestlist[i].requester._id !== localStorage.getItem('userid'))
            {
              arr[n] = requestlist[i];
              n++;
            }
          }
          setrequestlist(arr)
        }else{
          var arr = [];
        var arr2 = [];
          for (var i = 0; i < list.length; i++) {
            if (list[i].id === mem_id) {
              arr[i] = { ...list[i], request_send_byAuthUser: false };
            } else {
              arr[i] = list[i];
            }
          }
          for (var i = 0; i < dislist.length; i++) {
            if (dislist[i].id === mem_id) {
              arr2[i] = { ...dislist[i], request_send_byAuthUser: false };
            } else {
              arr2[i] = dislist[i];
            }
          }
          setlist(arr);
          setdislist(arr2);
        }
        
       
      }
    });
  };
  const handleSearch = (value) => {
    var arr = [];
    var n = 0;
    if (member_uid === localStorage.getItem("userid")) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].otherdetail[process.env.REACT_APP_FIRSTNAME_DBID].toLowerCase().includes(value.toLowerCase()) || list[i].otherdetail[process.env.REACT_APP_LASTNAME_DBID].toLowerCase().includes(value.toLowerCase())) {
          arr[n] = list[i];
          n++;
        }
      }
    }
    else{
      for (var i = 0; i < list.length; i++) {
        if (list[i].name.toLowerCase().includes(value.toLowerCase())) {
          arr[n] = list[i];
          n++;
        }
      }
    }
    setdislist(arr);
  };
  
  return (
    <>
      <div className="pro-followers-following-cont">
        <div className="pro-group-members">
          <div className="pro-group-members-title">
            {type === "follower" ? "Followers" : "Following"}
          </div>
          <div className="pro-group-members-search">
            <input
              className="search-members-input"
              value={text}
              onChange={(e) => {
                settext(e.target.value);
                handleSearch(e.target.value);
              }}
              type="text"
              placeholder={
                type === "follower" ? "Search followers" : "Search following"
              }
            />
          </div>
        </div>

        <div className="pro-group-members-list-main">
          {loader ? (
            <div className="loadind-post">
              <img src={loading} />
            </div>
          ) : (
            ""
          )}
          { member_uid === localStorage.getItem('userid')  ? 
            list !== undefined && list.length < 1 ? (
              <div className="pro-group-members-list">
                <span className="emptymsg">No Data Found</span>
              </div>
            ) : (
              dislist !== undefined && dislist.map((member) => {
                var curruserfollowmem = current_userfollowing.filter((mem)=>{if(mem._id === member._id) return mem}).length > 0 ? true : false
                return (
                  <>
                    <div className="pro-group-members-list">
                      <div className="pro-group-members-list-left">
                        <div className="pro-group-members-list-left-img">
                          <img src={Dummy} />
                        </div>
                        <div className="pro-group-members-list-left-title">
                          <a
                            onClick={() => {
                                 dispatch(fetchmemberprofile(member._id));
                                history(`/settings/edit`);
                            }}
                          >
                            {member.otherdetail[process.env.REACT_APP_FIRSTNAME_DBID] + " " + member.otherdetail[process.env.REACT_APP_FIRSTNAME_DBID]}{" "}
                          </a>
                        </div>
                        {/* <div className="pro-group-members-list-left-file">Virtual assistant at Lorem Ipusm</div> */}
                      </div>
                      { curruserfollowmem ? (
                          <div
                            className="pro-group-members-list-right"
                            onClick={() => {
                              unfollowrequest(member._id);
                            }}
                          >
                            <span className="follow-af"> Unfollow</span>
                          </div>
                        ) : requestlist.length > 0 &&
                          requestlist.filter((mem) => {
                            if (mem.recipient._id === member._id) return mem;
                          }).length > 0 ? (
                          <div
                            className="pro-group-members-list-right"
                            onClick={() => {
                              cancelrequest(member._id);
                            }}
                          >
                            <span className="follow-bf"> Requested</span>
                          </div>
                        ) : (
                          <div
                            className="pro-group-members-list-right"
                            onClick={() => {
                              followrequest(member._id);
                            }}
                          >
                            <span className="follow-bf"> Follow</span>
                          </div>
                        )
                       }
                    </div>
                  </>
                );
              })
            )
           : list !== undefined && list.length < 1 ? (
            <div className="pro-group-members-list">
              <span className="emptymsg">No Data Found</span>
            </div>
          ) : (
            dislist.map((member) => {
              return (
                <>
                  <div className="pro-group-members-list">
                    <div className="pro-group-members-list-left">
                      <div className="pro-group-members-list-left-img">
                        <img src={Dummy} />
                      </div>
                      <div className="pro-group-members-list-left-title">
                        <a
                          onClick={() => {
                            member.id === localStorage.getItem("userid")
                              ? dispatch(fetchmyaccount())
                              : dispatch(fetchmemberprofile(member.id));
                              history(`/settings/edit`);
                          }}
                        >
                          {member.name}{" "}
                        </a>
                      </div>
                      {/* <div className="pro-group-members-list-left-file">Virtual assistant at Lorem Ipusm</div> */}
                    </div>
                    {member.id !== localStorage.getItem("userid") ? (
                      member.follow_byAuthUser ? (
                        <div
                          className="pro-group-members-list-right"
                          onClick={() => {
                            unfollowrequest(member.id);
                          }}
                        >
                          <span className="follow-af"> Unfollow</span>
                        </div>
                      ) : requestlist.length > 0 &&
                        requestlist.filter((mem) => {
                          if (mem.recipient._id === member.id) return mem;
                        }).length > 0 ? (
                        <div
                          className="pro-group-members-list-right"
                          onClick={() => {
                            cancelrequest(member.id);
                          }}
                        >
                          <span className="follow-bf"> Requested</span>
                        </div>
                      ) : (
                        <div
                          className="pro-group-members-list-right"
                          onClick={() => {
                            followrequest(member.id);
                          }}
                        >
                          <span className="follow-bf"> Follow</span>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              );
            })
          )}

         
          
        </div>
      </div>
    </>
  );
};
export default FollowersFollowingList;
