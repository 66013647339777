import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import millify from "millify";
import Masonry from "react-responsive-masonry";
import { useDropzone } from "react-dropzone";
import { fetchmemberpost, fetchmyallpost, fetchmyaccount, fetchmemberprofile} from "../../../Action";
import Services from "../../../Services/auth.service";
import AllPost from "../FrontendPost/AllPost";
import FollowersFollowingList from "./FollowersFollowingList";
import RequestList from "./RequestList";
import Dummy from "../../../Images/app_logo.png";
import Dottedy from "../../../Images/dottedy.svg";
import Yes from "../../../Images/yes.svg";
import Comment2 from "../../../Images/comment2.svg";
import EditImg from "../../../Images/edit-line.svg";
import Image1 from "../../../Images/app_logo.png";
import Mastercard from "../../../Images/mastercard.png";
import Loader2 from "../../../Images/loader2.gif";

import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import RightSidebar_profile from "./RightSidebar_profile";

const Middlebar_profile = ({ profiletype, profileDetail, setshoweditdiv }) => {
  const dispatch = useDispatch();
  let { memberid } = useParams();
  const [joinedgroup, setjoinedgroup] = useState([]);
  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });
  const assignFiles = (acceptedFile) => {
    var formdata = new FormData();
    formdata.append("profileCover", acceptedFile[0]);
    Services.editprofile(formdata)
      .then((res) => {
        if (res.data.status) {
          dispatch(fetchmyaccount());
        }
      })
      .catch((e) => {
        console.log(e);
      });
    setFile(acceptedFile);
  };
  const [err, setErr] = useState({ msg: "", status: "" });
  const [listtype, setlisttype] = useState("");
  const [allimages, setimages] = useState([]);
  const [disimage, setdisimage] = useState([]);
  const [filtermedia, setfiltermedia] = useState("all");
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [requestlist, setrequestlist] = useState([]);
  const [inprocess_join, setinprocess_join] = useState({});
  const [inprocess_leave, setinprocess_leave] = useState({});
  useEffect(() => {
    var arr = allimages.filter((media, index) => {
      if (
        media.type === "image" &&
        (filtermedia === "images" || filtermedia === "all")
      ) {
        return media;
      } else if (
        media.type === "video" &&
        (filtermedia === "videos" || filtermedia === "all")
      ) {
        return media;
      }
    });
    setdisimage(arr);
  }, [filtermedia]);
  useEffect(() => {
    if (
      profileDetail !== undefined &&
      profileDetail._id !== undefined &&
      profiletype !== "myprofile"
    ) {
      dispatch(fetchmemberpost(profileDetail._id));
      Services.membermedia(profileDetail._id)
        .then((res) => {
          if (res.data.status) {
            setimages(res.data.data);
            setdisimage(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
        Services.getsentrequestlist()
        .then((res) => {
          if (res.data.success) {
            setrequestlist(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
       Services.getjoinedgroupothermember(profileDetail._id)
         .then((res) => {
           if (res.data.status) {
             setjoinedgroup(res.data.data);
           }
         })
         .catch((e) => {
           console.log(e);
         });
    } else if (
      profileDetail !== undefined &&
      profileDetail._id !== undefined &&
      profiletype !== undefined &&
      profiletype === "myprofile"
    ) {
      dispatch(fetchmyallpost());
      Services.membermedia(profileDetail._id)
        .then((res) => {
          if (res.data.status) {
            setimages(res.data.data);
            setdisimage(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      Services.myjoinedgroup()
        .then((res) => {
          if (res.data.status) {
            setjoinedgroup(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [profileDetail]);
  const followrequest = () => {
    Services.sendfollowrequest(profileDetail._id)
      .then((res) => {
        if (res.data.status) {
            setrequestlist([...requestlist, {recipient: {_id: profileDetail._id} , requester: {_id: localStorage.getItem('userid')}}])
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const unfollowrequest = () => {
    Services.sendunfollowrequest(profileDetail._id)
      .then((res) => {
        if (res.data.status) {
          dispatch(fetchmemberprofile(profileDetail._id));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const cancelrequest = () => {
    Services.cancelfollowrequest(profileDetail._id).then((res) => {
      if (res.data.status) {
        var arr = requestlist.filter((frnd)=>{ if(frnd.recipient._id !== profileDetail._id ) return frnd})
        setrequestlist([...arr])
      }
    });
  };
    const joingroup = (gid) => {
      setinprocess_join({ [gid]: true });
      Services.joingroup({ groupId: gid })
        .then((res) => {
          if (res.data.status) {
            var arr = [];
            var newgroup = {};
            var n = 0;
            for (var i = 0; i < joinedgroup.length; i++) {
              if (joinedgroup[i]._id !== gid) {
                arr[n] = joinedgroup[i];
                n++;
              } else {
                newgroup = joinedgroup[i];
              }
            }
            setjoinedgroup(arr);
            setcommonGroup([...commonGroup, newgroup]);
          } else {
            setgrouperr({ status: true, msg: res.data.message });
            setTimeout(() => {
              setgrouperr({ status: false, msg: "" });
            }, 3000);
          }
          setinprocess_join({ [gid]: false });
        })
        .catch((e) => {
          setinprocess_join({ [gid]: false });
          console.log(e);
        });
    };
    const leavegroup = (groupid) => {
      setinprocess_leave({ [groupid]: true });
      Services.leavegroup(groupid)
        .then((res) => {
          if (res.data.status) {
            var arr = [];
            var n = 0;
            for (var i = 0; i < joinedgroup.length; i++) {
              // console.log(joinedgroup[i]._id, groupid);
              if (
                joinedgroup[i] !== null &&
                joinedgroup[i]._id !== null &&
                joinedgroup[i]._id !== groupid
              ) {
                arr[n] = joinedgroup[i];
                n++;
              }
            }
            setjoinedgroup(arr);
          }
          setinprocess_leave({ [groupid]: false });
        })
        .catch((e) => {
          setinprocess_leave({ [groupid]: false });
          console.log(e);
        });
    };
  return (
    <>
      {profileDetail !== undefined && profileDetail._id === memberid ? (
        <>
          {profileDetail._id === localStorage.getItem("userid") ? (
            <div className="profile-bg-img">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <span className="profile_edit_image">
                  <img src={EditImg} />
                </span>
              </div>
              <img
                className="profile-bg-img-mn"
                src={
                  profileDetail !== undefined &&
                  profileDetail.profileCover !== undefined &&
                  profileDetail.profileCover.length > 0
                    ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                      profileDetail.profileCover
                    : Dummy
                }
              />
            </div>
          ) : (
            <div className="profile-bg-img">
              <img
                className="profile-bg-img-mn"
                src={
                  profileDetail !== undefined &&
                  profileDetail.profileCover !== undefined &&
                  profileDetail.profileCover.length > 0
                    ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                      profileDetail.profileCover
                    : Dummy
                }
              />
            </div>
          )}

          <div className="profile-detilas-all res-profile-detilas-all">
            <div className="profile-detilas-all-username">
              <div className="profile-detilas-all-username-left2">
                <div className="profile-detilas-user-photo">
                  <img
                    src={
                      profileDetail !== undefined &&
                      profileDetail.profileImg !== undefined &&
                      profileDetail.profileImg.length > 0
                        ? profileDetail.profileImg
                        : Dummy
                    }
                  />
                </div>
                <div className="profile-detilas-user-title">
                  {profileDetail.otherdetail !== undefined
                    ? profileDetail.otherdetail.filter((n) => {
                        if (
                          (profiletype !== undefined &&
                            profiletype === "myprofile" &&
                            n._id === process.env.REACT_APP_FIRSTNAME_DBID) ||
                          n.id === process.env.REACT_APP_FIRSTNAME_DBID
                        )
                          return n;
                      })[0]["value"] +
                      " " +
                      profileDetail.otherdetail.filter((n) => {
                        if (
                          (profiletype !== undefined &&
                            profiletype === "myprofile" &&
                            n._id === process.env.REACT_APP_LASTNAME_DBID) ||
                          n.id === process.env.REACT_APP_LASTNAME_DBID
                        )
                          return n;
                      })[0]["value"]
                    : ""}{" "}
                  {profileDetail.verified ? <img src={Yes} /> : <></>}
                  {/* <button
                    className="res-edit-icon"
                    onClick={() => {
                      setshoweditdiv(true);
                    }}
                  >
                    <Edit2FillIcon size="20px" />
                  </button> */}
                </div>

                <div className="profile-detilas-flw">
                  <span className="profile-detilas-flw-in pd-rs">
                    {profileDetail.followers !== undefined
                      ? millify(profileDetail.followers.length) + " "
                      : ""}
                    Followers
                  </span>
                  <span className="profile-detilas-flw-in pd-ng">
                    {profileDetail.following !== undefined
                      ? millify(profileDetail.following.length) + " "
                      : ""}
                    Following
                  </span>
                </div>
              </div>
              {profiletype !== undefined && profiletype === "myprofile" ? (
                <>
                  <div className="profile-detilas-all-username-right">
                    <div className="profile-detilas-comm">
                      {/* <button
                        className="btn2"
                        onClick={() => {
                          setshoweditdiv(true);
                        }}
                      >
                        Edit Profile
                      </button> */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="profile-detilas-all-username-right">
                  <div className="profile-detilas-comm">
                    <img src={Comment2} />
                  </div>

                  <div className="profile-detilas-cmn pd-joind">
                    {" "}
                    {profileDetail.following !== undefined &&
                    profileDetail.followers.indexOf(
                      localStorage.getItem("userid")
                    ) !== -1 ? (
                      <button
                        className="pd-joind-btn"
                        onClick={() => {
                          unfollowrequest();
                        }}
                      >
                        Unfollow
                      </button>
                    ) : requestlist.filter((frnd) => {
                        if (frnd.recipient._id === profileDetail._id)
                          return frnd;
                      }).length > 0 ? (
                      <button
                        className="pd-joind-btn"
                        onClick={() => {
                          cancelrequest();
                        }}
                      >
                        Cancel Request
                      </button>
                    ) : (
                      <button
                        className="pd-joind-btn"
                        onClick={() => {
                          followrequest();
                        }}
                      >
                        Follow
                      </button>
                    )}
                  </div>

                  <div className="profile-detilas-cmn pd-dottod">
                    <span
                      className="pd-dottod-btn-mn"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {" "}
                      <button className="pd-dottod-btn">
                        <img src={Dottedy} />
                      </button>
                    </span>

                    <div
                      className="dropdown-menu  pd-dottod-in"
                      x-placement="bottom-start"
                    >
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-info-circle"></i> Report group
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="profile-detilas-all-nav">
              <ul className="nav profiledt-menu">
              { window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ? <>
                <li>
                  <a
                    className="active"
                    id="Groupposts-tab"
                    data-toggle="tab"
                    href="#posts"
                    role="tab"
                    aria-controls="Group"
                    aria-selected="true"
                  >
                    Posts
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    id="members-tab"
                    data-toggle="tab"
                    href="#follow"
                    role="tab"
                    aria-controls="members"
                    aria-selected="false"
                    onClick={() => {
                      setlisttype("follower");
                    }}
                  >
                    Followers
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    id="events-tab"
                    data-toggle="tab"
                    href="#follow"
                    role="tab"
                    aria-controls="events"
                    aria-selected="false"
                    onClick={() => {
                      setlisttype("following");
                    }}
                  >
                    Following
                  </a>
                </li>
                {profiletype === "myprofile" ? (
                  <li>
                    <a
                      className=""
                      id="events-tab"
                      data-toggle="tab"
                      href="#request"
                      role="tab"
                      aria-controls="events"
                      aria-selected="false"
                    >
                      Requests
                    </a>
                  </li>
                ) : (
                  <></>
                )}
                <li>
                  <a
                    className=""
                    id="media-tab"
                    data-toggle="tab"
                    href="#media"
                    role="tab"
                    aria-controls="media"
                    aria-selected="false"
                  >
                    Media
                  </a>
                </li>
                <li className="rs-joined-group">
                  <a
                    id="joined-group-tab-1"
                    data-toggle="tab"
                    href="#joinedgrpMain"
                    role="tab"
                    aria-controls="media1"
                    aria-selected="false"
                  >
                    Joined group
                  </a>
                </li>
                </> : <></>}
                <li >
                  <a
                    className= { window.location.hostname !==
                      process.env.REACT_APP_TESTSITE_HOSTNAME ? "" : "active"}
                    id="about-tab-1"
                    data-toggle="tab"
                    href="#aboutMain"
                    role="tab"
                    aria-controls="media1"
                    aria-selected="false"
                  >
                    About info 
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content profile-details-tabs" id="myTabContent">
          { window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ?
          <>
            <div
              className="tab-pane fade show active"
              id="posts"
              role="tabpanel"
              aria-labelledby="posts-tab"
            >
              <AllPost
                posterr={err}
                setposterr={setErr}
                post_scope="member"
                userIsMember={true}
                memberid={profileDetail._id}
              />
            </div>

            <div
              className="tab-pane fade"
              id="follow"
              role="tabpanel"
              aria-labelledby="follower-tab"
            >
              <FollowersFollowingList
                member_uid={profileDetail._id}
                type={listtype}
              />
            </div>
            {profiletype === "myprofile" ? (
              <div
                className="tab-pane fade"
                id="request"
                role="tabpanel"
                aria-labelledby="follower-tab"
              >
                <RequestList />
              </div>
            ) : (
              <></>
            )}
            <div
              className="tab-pane fade"
              id="media"
              role="tabpanel"
              aria-labelledby="media-tab"
            >
              <div className="cstm-media-all-main">
                <div
                  className="modal fade create-popup-post"
                  id="groupmediaslider"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content cstm-modalcontent">
                      {allimages !== undefined &&
                      allimages[selectedSlide] !== undefined &&
                      allimages[selectedSlide].type !== undefined ? (
                        allimages[selectedSlide].type === "image" ? (
                          <img
                            src={
                              process.env.REACT_APP_AWS_IMG_VID_PATH +
                              allimages[selectedSlide].image
                            }
                          />
                        ) : (
                          <video
                            src={
                              process.env.REACT_APP_AWS_IMG_VID_PATH +
                              allimages[selectedSlide].video
                            }
                            controls={true}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <span className="group-media-title">Media</span>
                <div className="group-media-main">
                  <ul>
                    <li>
                      <button
                        className={filtermedia === "all" ? "acvite-gm" : ""}
                        onClick={() => {
                          setfiltermedia("all");
                        }}
                      >
                        ALL
                      </button>
                    </li>
                    <li>
                      <button
                        className={filtermedia === "images" ? "acvite-gm" : ""}
                        onClick={() => {
                          setfiltermedia("images");
                        }}
                      >
                        Images
                      </button>
                    </li>
                    <li>
                      <button
                        className={filtermedia === "videos" ? "acvite-gm" : ""}
                        onClick={() => {
                          setfiltermedia("videos");
                        }}
                      >
                        Videos
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="group-media-slider">
                  <Masonry columnsCount={3} gutter="16px">
                    {disimage.map((media, index) => {
                      if (
                        media.type === "image" &&
                        (filtermedia === "images" || filtermedia === "all")
                      ) {
                        return (
                          <img
                            src={
                              process.env.REACT_APP_AWS_IMG_VID_PATH +
                              media.image
                            }
                            onClick={() => {
                              setSelectedSlide(index);
                            }}
                            data-toggle="modal"
                            data-target="#groupmediaslider"
                          />
                        );
                      } else if (
                        media.type === "video" &&
                        (filtermedia === "videos" || filtermedia === "all")
                      ) {
                        return (
                          <video
                            src={
                              process.env.REACT_APP_AWS_IMG_VID_PATH +
                              media.video
                            }
                            controls={true}
                            onClick={() => {
                              setSelectedSlide(index);
                            }}
                            data-toggle="modal"
                            data-target="#groupmediaslider"
                          />
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </Masonry>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="joinedgrpMain"
              role="tabpanel"
              aria-labelledby="joined-group-tab-1"
            >
              <div className="cstm-media-all-main rs-joined-group">
                <span className="group-media-title">Joined Group</span>
                <ul className="rs-group-media-inner-ul">
                  {joinedgroup !== undefined && joingroup.length > 0 ? (
                    joinedgroup.map((group) => {
                      if (group !== undefined && group !== null) {
                        return (
                          <>
                            <li>
                              <div className="rs-group-media-inner-li-div">
                                <img
                                  className="rs-group-media-inner-li-img"
                                  src={
                                    group.groupImage !== undefined &&
                                    group.groupImage.length > 0
                                      ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        group.groupImage
                                      : Image1
                                  }
                                />
                              </div>
                              <div className="rs-group-media-inner-details">
                                <div className="rs-group-media-inner-li-img-heading">
                                  <a className="">{group.groupTitle}</a>
                                </div>
                                <div className="rs-group-media-inner-li-leave">
                                  {inprocess_leave[group._id] !== undefined &&
                                  inprocess_leave[group._id] ? (
                                    <img
                                      src={Loader2}
                                      className="dis-loader3"
                                    />
                                  ) : (
                                    <a
                                      className="leave-group"
                                      onClick={() => {
                                        leavegroup(group._id);
                                      }}
                                    >
                                      Leave
                                    </a>
                                  )}
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      } else {
                        return <></>;
                      }
                    })
                  ) : (
                    <span className="emptymsg">No Data Found</span>
                  )}
                </ul>
              </div>
            </div>
            </> : <></> }
            <div
              className={`tab-pane fade ${ window.location.hostname !==
                process.env.REACT_APP_TESTSITE_HOSTNAME ? "" : "show active"}`}
              id="aboutMain"
              role="tabpanel"
              aria-labelledby="about-tab-1"
            >
              <div className="cstm-media-all-main">
              {memberid === localStorage.getItem("userid") ?
                    <RightSidebar_profile  profiletype="myprofile"
                  profileDetail={profileDetail} setshoweditdiv={setshoweditdiv}/> : <RightSidebar_profile 
                  profileDetail={profileDetail} />}
              </div>
            </div>
            {/* <div
              className="tab-pane fade"
              id="joinedgrp111"
              role="tabpanel"
              aria-labelledby="joined-group-tab"
            >
              <div className="cstm-media-all-main">
                <ul>
                  {joinedgroup !== undefined && joingroup.length > 0 ? (
                    joinedgroup.map((group) => {
                      if (group !== undefined && group !== null) {
                        return (
                          <>
                            <li>
                              <div className="cstm-sg-list">
                                <img
                                  className="sctm-pt-mn"
                                  src={
                                    group.groupImage !== undefined &&
                                    group.groupImage.length > 0
                                      ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        group.groupImage
                                      : Image1
                                  }
                                />
                              </div>
                              <div className="">
                                <a className="cstm-sg-ttl-main">
                                  {group.groupTitle}
                                </a>
                              </div>
                              <div className="cstm-sg-join">
                                {inprocess_leave[group._id] !== undefined &&
                                inprocess_leave[group._id] ? (
                                  <img src={Loader2} className="dis-loader3" />
                                ) : (
                                  <a
                                    className="leave-group"
                                    onClick={() => {
                                      leavegroup(group._id);
                                    }}
                                  >
                                    Leave
                                  </a>
                                )}
                              </div>
                            </li>
                          </>
                        );
                      } else {
                        return <></>;
                      }
                    })
                  ) : (
                    <span className="emptymsg">No Data Found</span>
                  )}
                </ul>
              </div>
            </div> */}
            {/* {profileDetail._id === localStorage.getItem("userid") ? (
              <div
                className="tab-pane fade"
                id="billing"
                role="tabpanel"
                aria-labelledby="billing-tab"
              >
                <div className="um-billing-info">
                  <div className="um-billing-info-left">
                    <div className="um-billing-info-lable">Annual Payment</div>
                    <div className="um-billing-info-price">
                      <sup>$</sup>
                      <span className="um-billing-info-price-in">7,497.00</span>
                    </div>
                    <div className="um-billing-info-next">Next payment on</div>
                    <div className="um-billing-info-date">20 July,2023</div>
                  </div>
                  <div className="um-billing-info-right">
                    <p>Subscription period: 1 year</p>
                    <p>Automatic renewal (recurring payments)</p>
                    <p>Includes $1,000 Event Credit</p>
                    <div className="um-billing-info-right-upgrade">
                      <button className="btn2">Upgrade Plan</button>{" "}
                      <span className="cancle-subscription-link">
                        <button>Cancle Subscription</button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="um-payment-info">
                  <p>Payment Info</p>
                  <div className="um-billing-info-main">
                    <div className="um-billing-info-main-left">
                      <img src={Mastercard} />
                      XXXX-XXXX-1234
                    </div>
                    <div className="um-billing-info-main-right">
                      <button className="cstm-remove-link">Remove</button>
                    </div>
                  </div>
                </div>

                <div className="um-billing-information">
                  <span className="um-billing-informatio-title">
                    Billing Information
                  </span>
                  <div className="um-billing-information-table">
                    <table className="bitable">
                      <tr>
                        <th>Date</th>
                        <th>Subscription type</th>
                        <th>Price</th>
                      </tr>
                      <tr>
                        <td>7,Jan 2016</td>
                        <td>Annual</td>
                        <td>$7497.00</td>
                      </tr>
                      <tr>
                        <td>7,Jan 2016</td>
                        <td>Annual</td>
                        <td>$7497.00</td>
                      </tr>
                      <tr>
                        <td>7,Jan 2016</td>
                        <td>Annual</td>
                        <td>$7497.00</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default Middlebar_profile;


