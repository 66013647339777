import React, { useEffect , useState} from "react";
import { Link, useNavigate , useParams} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addregidetail } from "../../../Action";
import Services from "../../../Services/auth.service"
// Import images
import logo from "../../../Images/logo.png";
import loading from "../../../Images/lodemorecmt.gif";

const MembershipDetail = ({ setselectedplan, selectedplan }) => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const { stepno } = useParams();
  const regidetail = useSelector((state) => {
    return state.ReduRegistrationDetail;
  })
  const [loader, setloader] = useState(true);
  const [allplan, setallplan] = useState([])
  const [err, setErr] = useState('')
 
  useEffect(()=>{
    if(regidetail !== undefined && regidetail.register_status !== undefined &&  regidetail.register_status)
    {
      Services.getallplanatregis().then((res)=>{
        if(res.data.status)
        {
          setallplan(res.data.data);
          if(res.data.data.length === 1)
          {
            setselectedplan(res.data.data[0])
          }
          setloader(false);
        } else{
          setloader(false);
        }
      }).catch((e)=>{
        console.log(e)
        setloader(false);
      })
    }    
    else if(stepno === "4"){
      window.location.href= "/firststepregister/2";
    }
  },[])
  const handleNext = () => {
    if(selectedplan !== undefined && selectedplan._id.length > 0)
    {
      setErr("");
      dispatch(addregidetail({selectedplan: selectedplan}))
      history("/firststepregister/5")
    }
    else
    {
      setErr("Select plan!");
    }
  }
  return (
    <>
      {/* Membership level choice */}
      <div className="um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn">
                  <div className="um-title2">
                  Pay Service Fee
                  </div>
                  <div className="um-text">
                    Your annual membership does not begin until you are approved
                    and gain access to our member site. It is prorated daily and
                    you can cancel anytime.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 um-right">
              <div className="um-right-in2 cstm-payment-chng">
                <div className="um-right-in-title">Service Fee</div>
                <hr className="um-line" />
                <div className="um-member-step um-meme-det-only" >
                  <span className="error">{err}</span>
                  <div className="um-member-step-in um-membership-details">
                    {
                      allplan.length > 0 ? allplan.map((plan)=>{
                        return (
                          <div className={selectedplan !== undefined && selectedplan._id === plan._id ? "um-membership-details-in selectedplan" : "um-membership-details-in"} onClick={()=>{
                            if(selectedplan !== undefined)
                            {
                              setErr("");
                              setselectedplan(plan);
                            }
                           }}>
                          <div className="um-membership-details-in-lable um-annual-payment">
                          Membership
                          </div>
                          <div className="um-membership-details-in-price">
                            <sup>$</sup>
                            <span className="um-md-price">{plan.plan_price.toLocaleString() + ".00 USD"}</span>
                          </div>
                          {
                            plan.automatic_renewal ? <p>{plan.recurring_timeframe.charAt(0).toUpperCase() + plan.recurring_timeframe.slice(1).toLowerCase()} Recurring</p> : <></>

                          }
                          <div dangerouslySetInnerHTML={{__html: plan.plan_description}}></div>
                        </div>
                        )
                      }) : loader ?  <div className="loadind-post cmt-loading">
                      <img src={loading} />{" "}
                    </div> : <></>
                    }
                    </div>
                  </div>

                <div className="um-rgt-btn">
                  <button
                    type="button"
                    className="btn2"
                    onClick={() => {
                      handleNext()
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipDetail;
