import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../../Services/auth.service";
const AnnouncementList = () => {
  const history = useNavigate();
  const [allannou, setallannou] = useState([]);
  useEffect(() => {
    Services.getallannoucements()
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var n = 0;
          for (var i = 0; i < res.data.data[0].final_posts.length; i++) {
            for (var l = 0; l < res.data.data[0].final_posts[i].length; l++) {
              arr[n] = res.data.data[0].final_posts[i][l];
              n++;
            }
          }
          setallannou(arr);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      {allannou.length > 0 ? (
        <ul>
          {allannou.map((annou) => {
            return (
              <li>
                {annou.images.length > 0 ? (
                  <div className="announcements-list-image">
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH + annou.images[0]
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                <span className="new-lbl">New</span>
                <div className="announcements-list-title">
                  {annou.postedBy !== undefined && annou.postedBy !== null ? (
                    <>
                      {annou.user_type !== "adminuser" ? (
                        <span
                          onClick={() => {
                            history(`/settings`);
                          }}
                        >
                          {annou.postedBy.otherdetail[
                            process.env.REACT_APP_FIRSTNAME_DBID
                          ] +
                            " " +
                            annou.postedBy.otherdetail[
                              process.env.REACT_APP_LASTNAME_DBID
                            ]}{" "}
                        </span>
                      ) : (
                        annou.postedBy.first_name +
                        " " +
                        annou.postedBy.last_name
                      )}{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  {/* {annou.postedBy.otherdetail[process.env.REACT_APP_FIRSTNAME_DBID] + " " + annou.postedBy.otherdetail[process.env.REACT_APP_LASTNAME_DBID] } */}
                </div>
                <div className="announcements-list-cont">
                  {annou.description}
                </div>
                <div className="announcements-list-read">
                  <a href="#">Read Announcements</a>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <span className="emptymsg ">No Data Found</span>
      )}
    </>
  );
};
export default AnnouncementList;
