import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import InputEmoji from "react-input-emoji";
import moment from "moment";
import fillheart from "../../Images/fillheart.svg";
import replyimg from "../../Images/reply-line.svg";
import timeimg from "../../Images/time-line.svg";
import svdpostimg from "../../Images/savedpost.svg";
import File1 from "../../Images/image1.png";
import Yes from "../../Images/yes.svg";
import Dotted from "../../Images/dotted.svg";
import Link from "../../Images/link.svg";
import Save from "../../Images/save.svg";
import Heart from "../../Images/heart.svg";
import Message from "../../Images/message.svg";
import Share from "../../Images/share.svg";
import Delete2 from "../../Images/delete2.svg";
import Edit from "../../Images/edit.svg";
import loading from "../../Images/loader.gif";
//import api file
import Services from "../../Services/auth.service";
//import function from action file
import {
  handlegrouplike,
  handlegroupvote,
  addgroupcommt,
  deletegroupcommt,
  handlehidepost,
  handlegroupannoucement,
  fetchpostbygroupid_admin,
} from "../../Action/index.js";
import PostComments from "../UserComponents/FrontendPost/PostComments";
const DisplayPost = ({
  allpost,
  topics,
  setdelpostid,
  seteditId,
  handleSelectEdit,
  setallimg,
  posterr,
  setposterr,
  progbarVal,
  selectedGid,
  cntcmt,
  postfilter,
  setpostfilter,
  postpage,setpostpage
}) => {
  const dispatch = useDispatch();
  const feelings = useSelector((state) => {
    return state.ReduActivityFeeling;
  });
  const USER_ID = localStorage.getItem("userid");
  const USER_NAME = localStorage.getItem("username");
  const [allcomments, setallcomments] = useState([]);
  const [showcinput, setshowcinput] = useState({});
  const [comment, setComment] = useState("");
  const [reply, setreply] = useState({});
  const [rereply, setrereply] = useState({});
  const [loader, setloader] = useState(false);
  const [usersvdpost, setsvdpost] = useState([]);
  const [morecmt, setmorecmt] = useState(1)
  const [totalcmt, settotalcmt] = useState(0)
  const [cmtinprocess, setcmtinprocess] = useState(false);

  var mainpost = useSelector((state) => {
    return state.ReduGroupPost.allpost;
  });
  var totalpost = useSelector((state)=>{
    return state.ReduGroupPost.totalpost;
  })
  const savedpost = useSelector((state) => {
    return state.ReduSavedPost;
  });
  useEffect(() => {
    setsvdpost(savedpost);
    window.addEventListener("scroll", handleScroll);
  }, []);
  // useEffect(() => {
  //   setpostpage(1);
  // }, [selectedGid]);

  useEffect(()=>{
    dispatch(fetchpostbygroupid_admin({groupid: selectedGid, page: postpage}))
    // console.log("postpage" , postpage)
  },[postpage])
  const handleScroll = () => {
    if(postpage === Math.round(totalpost/5)){
      setloader(false)
    }
    else{
      if(window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight && allpost.length < totalpost){
        setloader(true)
      }
      else{
        setloader(false)
      }
    }
  }
  useEffect(()=>{
    if(loader && postpage <= Math.round(totalpost/5))
      setpostpage(postpage + 1)
  },[loader])
  const handleSave = (id) => {
    Services.savepost(id)
      .then((res) => {
        dispatch(fetchsavedpost());
        if (usersvdpost.includes(id))
          setsvdpost(
            usersvdpost.filter((p) => {
              if (p !== id) return;
            })
          );
        else setsvdpost([...usersvdpost, id]);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const handlehidefromfeed = (postid) => {
    var data = {
      postid: postid,
      groupid: selectedGid,
    };
    Services.hidefromfeedpost(data)
      .then((res) => {
        dispatch(handlehidepost(postid));
        if (res.data.status) {
          dispatch();
          setposterr({
            status: false,
            msg: res.data.message,
          });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        } else {
          setposterr({
            status: true,
            msg: res.data.message,
          });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handlemakeannoucement = (postid) => {
    var data = {
      postid: postid,
      groupid: selectedGid,
    };
    Services.makeannoucement(data)
      .then((res) => {
        if (res.data.status) {
          dispatch(handlegroupannoucement(postid));
          setposterr({
            status: false,
            msg: res.data.message,
          });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        } else {
          setposterr({
            status: true,
            msg: res.data.message,
          });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        }
      })
      .catch((e) => {
        setposterr({
          status: false,
          msg: "Something went wrong!",
        });
        setTimeout(() => {
          setposterr({ status: false, msg: "" });
        }, 3000);
      });
  };
  
  const handleLike = (postid) => {
    dispatch(handlegrouplike(postid));
    Services.likepost_admin(postid)
      .then((res) => {
        if (res.data.status) {
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchcomments = (postid) => {
    Services.getallcomments_admin({postid: postid, page: morecmt})
      .then((res) => {
        if (res.data.status) {
          if(res.data.data[0].comment !== undefined && res.data.data[0].comment.length > 0 )
          {
          var data = res.data.data[0].comment.map((comm) => {
            var reply = [];
            var rereply = [];
            if (comm.comments.length > 0) {
              reply = comm.comments.map((reply) => {
                if (reply.comments.length > 0) {
                  rereply = reply.comments.map((r) => {
                    return {
                      ...r,
                      likes: { count: r.likes.length, list: r.likes },
                    };
                  });
                }
                return {
                  ...reply,
                  likes: { count: reply.likes.length, list: reply.likes },
                  comments: rereply,
                };
              });
            }
            return {
              ...comm,
              likes: { count: comm.likes.length, list: comm.likes },
              comments: reply,
            };
          });
          setallcomments(data);
          settotalcmt(res.data.data[0].totalPosts)
          }
          else {
            settotalcmt(0)
            setallcomments([]);
          }
        } else {
          settotalcmt(0)
          setallcomments([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleComment = (id) => {
    showcinput[id] !== undefined && showcinput[id]
      ? setshowcinput({ [id]: false })
      : setshowcinput({ [id]: true });
    fetchcomments(id);
  };

  const addcomment = (postid, commid) => {
    setcmtinprocess(true)
    if (commid.length === 0 && comment.length > 0) {
      console.log("fjdgkf")
      let data = {
        postId: postid,
        data: {
          content: comment,
          userId: USER_ID,
          user_type: "adminuser"
        },
      };
      Services.addcomment_admin(data)
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            setComment("");
            dispatch(
              addgroupcommt({ postid: postid, commid: res.data.data._id })
            );
            fetchcomments(postid);
          } else {
            setposterr({ status: true, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
          }
          setcmtinprocess(false)
        })
        .catch((e) => {
          setcmtinprocess(false)
          console.log(e);
        });
    } else {
      if (
        (reply[commid] !== undefined && reply[commid].length > 0) ||
        (rereply[commid] !== undefined && rereply[commid].length > 0)
      ) {
        var data = {
          postId: postid,
          commId: commid,
          user_type: "adminuser",
          content:
            reply[commid] !== undefined && reply[commid].length > 0
              ? reply[commid]
              : rereply[commid],
        };
        Services.createreply_admin(data)
          .then((res) => {
            setreply({ ...reply, [commid]: "" });
            setrereply({ ...rereply, [commid]: "" });
            // dispatch(fetchallpost());
            fetchcomments(postid);
            setcmtinprocess(false)
          })
          .catch((e) => {
            setcmtinprocess(false)
            console.log(e);
          });
      }

      setcmtinprocess(false)
    }
  };

  const likecomment = (postid, commid) => {
    var data = {
      postId: postid,
      commId: commid,
    };
    Services.likecomment_admin(data)
      .then((res) => {
        if (res.data.status) {
          fetchcomments(postid);
        } else {
          setposterr({ status: true, msg: res.data.message });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deletecmt = (postid, comtid) => {
    var data = {
      postId: postid,
      commId: comtid,
    };
    Services.deletecomment_admin(data)
      .then((res) => {
        if(res.data.status){
          dispatch(deletegroupcommt({ postid: postid, commid: comtid }));
          fetchcomments(postid);          
        }
        
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleVote = (pollid, postid) => {
    var data = {
      postid: postid,
      pollChoicesID: pollid,
    };
    Services.addvote_admin(data)
      .then((res) => {
        // dispatch(fetchallpost());
        if (res.data.status) {
          dispatch(
            handlegroupvote({
              postid: postid,
              pollid: pollid,
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlemorecmt = (postid) => {
    if(morecmt < totalcmt)
    {      
      Services.getallcomments_admin({postid: postid, page: morecmt + 1})
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data[0].comment.map((comm) => {
            var reply = [];
            var rereply = [];
            if (comm.comments.length > 0) {
              reply = comm.comments.map((reply) => {
                if (reply.comments.length > 0) {
                  rereply = reply.comments.map((r) => {
                    return {
                      ...r,
                      likes: { count: r.likes.length, list: r.likes },
                    };
                  });
                }
                return {
                  ...reply,
                  likes: { count: reply.likes.length, list: reply.likes },
                  comments: rereply,
                };
              });
            }
            return {
              ...comm,
              likes: { count: comm.likes.length, list: comm.likes },
              comments: reply,
            };
          });
          setallcomments([...data,...allcomments]);
          settotalcmt(res.data.data[0].totalPosts)
        } else {
          settotalcmt(0)
          setallcomments([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
      setmorecmt(morecmt + 1)
    }
  }
 

  return (
    <>
         {postfilter === "post" && mainpost.length > 0 ? (
            mainpost.map((post, index) => {
              return displayPost(post);
            })
          ) : (
            <></>
          )}
     
      {postfilter === "hidden"
        ? mainpost.map((post, index) => {
            if (post.hideFromFeed) {
              return displayPost(post);
            }
          })
        : ""}
      {postfilter === "annoucement"
        ? mainpost.map((post, index) => {
            if (post.makeAnnouncement) {
              return displayPost(post);
            }
          })
        : ""}

        {loader ? <div  className="loadind-post">
            <img src={loading} />
          </div>
           : ""}
    </>
  );
  function displayPost(post) {
    var postimgvid = [...post.images, ...post.videos];
    
    var feeling_post = post.feelingsActivity !== undefined && post.feelingsActivity !== null ? feelings.filter(
      (feelings) => feelings._id === post.feelingsActivity._id
    )[0] !== undefined ? feelings.filter(
      (feelings) => feelings._id === post.feelingsActivity._id
    )[0].emoji +
    " " +
    feelings.filter(
      (feelings) => feelings._id === post.feelingsActivity._id
    )[0].feeling : "" : ""
    return (
      <div className="newsfeed-post">
        <div className="newsfeed-post-left">
          <img src={File1} />
        </div>
        <div className="newsfeed-post-right">
          <div className="newsfeed-post-right-header">
            <div className="newsfeed-post-right-header-name">
              <span className="user-name-title">
                {post.postedBy !== null && post.postedBy !== undefined && post.user_type !== "adminuser" && post.postedBy.otherdetail[ process.env.REACT_APP_FIRSTNAME_DBID] !== undefined && post.postedBy.otherdetail[ process.env.REACT_APP_FIRSTNAME_DBID] !== null && 
                post.postedBy.otherdetail[ process.env.REACT_APP_LASTNAME_DBID] !== undefined && post.postedBy.otherdetail[ process.env.REACT_APP_LASTNAME_DBID] !== null
                ? post.postedBy.otherdetail[
                  process.env.REACT_APP_FIRSTNAME_DBID
                ] +
                  " " +
                  post.postedBy.otherdetail[
                    process.env.REACT_APP_LASTNAME_DBID
                  ] : post.postedBy !== null && post.postedBy !== undefined && post.user_type === "adminuser" ? post.postedBy.first_name + " " + post.postedBy.last_name : "" }{" "}
                {post.postedBy !== null && post.postedBy !== undefined && post.postedBy.verified !== undefined && post.postedBy.verified !== null && post.postedBy.verified ? (
                  <img className="yesicon" src={Yes} />
                ) : (
                  ""
                )}
                <span>
                  {post.feelingsActivity !== undefined &&
                  post.feelingsActivity !== null &&
                  post.feelingsActivity._id.length > 0 ? (
                    <span>
                      {" "}
                      is feeling{" "}
                      {feeling_post}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </span>
              <span className="user-name-date">
                {new Date(post.createdAt).toLocaleDateString("en-us", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }) +
                  " at " +
                  new Date(post.createdAt).toLocaleTimeString(
                    navigator.language,
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
              </span>
            </div>
            <div className="newsfeed-post-right-header-icon">
              {/* <div className="newfeed-dp">
                <button>
                  <img src={Link} />
                </button>
              </div>
              <div className="newfeed-dp">
                <button
                  onClick={() => {
                    handleSave(post._id);
                  }}
                >
                  {usersvdpost.includes(post._id) ? (
                    <img src={svdpostimg} />
                  ) : (
                    <img src={Save} />
                  )}
                </button>
              </div> */}
              <div className="newfeed-dp cstm-dtl-poll">
                <span className="cstm-poll-ad" data-toggle="dropdown">
                  <img src={Dotted} />
                </span>
                <div className="dropdown-menu cstm-edit-delete-poll">
                  <ul>
                    <li>
                      <a
                        onClick={() => {
                          seteditId(post._id);
                          handleSelectEdit(post._id);
                        }}
                        data-toggle="modal"
                        data-target="#editpost"
                      >
                        <img src={Delete2} />
                        {post.postType === "Post" ? "Edit Post" : "Edit Poll"}
                      </a>
                    </li>
                   {!post.isDelete ? 
                     <li>
                     <a
                       onClick={() => {
                         setdelpostid(post._id);
                       }}
                       data-toggle="modal"
                       data-target="#deletepost"
                     >
                       <img src={Edit} />
                       {post.postType === "Post"
                         ? "Delete Post"
                         : "Delete Poll"}
                     </a>
                   </li>
                   : <></>}
                  
                    <li>
                      <a
                        onClick={() => {
                          handlehidefromfeed(post._id);
                        }}
                      >
                        <img src={Edit} />
                        {post.hideFromFeed
                          ? "Display in feed"
                          : "Hide From Feed"}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handlemakeannoucement(post._id);
                        }}
                      >
                        <img src={Edit} />
                        {post.makeAnnouncement
                          ? "Remove from Announcement"
                          : "Make Announcement"}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="newsfeed-post-right-text"> {post.description}</div>

          {post.topics.length > 0 ? (
            <div className="newsfeed-post-right-topik">
              {" "}
              {post.topics.map((topic) => (
                <span className="topik-in">
                 {topic.topic}
                </span>
              ))}
            </div>
          ) : (
            ""
          )}
          {postimgvid.length > 0 ? (
            postimgvid.length === 1 ? (
              postimgvid[0].indexOf("image") !== -1 ? (
                <div className="create-pp-post-img1 cstm-create-post-img1">
                  <img
                    src={process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[0]}
                  />
                </div>
              ) : (
                <div className="create-pp-post-img1 cstm-create-post-video">
                  <video
                    src={process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[0]}
                    controls={true}
                  />
                </div>
              )
            ) : postimgvid.length === 2 ? (
              <div className="create-pp-post-img2">
                <div className="create-pp-post-img2-in">
                  {postimgvid[0].indexOf("image") !== -1 ? (
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[0]
                      }
                    />
                  ) : (
                    <video
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[0]
                      }
                      controls={true}
                    ></video>
                  )}
                </div>
                <div className="create-pp-post-img2-in">
                  {postimgvid[1].indexOf("image") !== -1 ? (
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[1]
                      }
                    />
                  ) : (
                    <video
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[1]
                      }
                      controls={true}
                    ></video>
                  )}
                </div>
              </div>
            ) : (
              <div className="create-pp-post-img3">
                <div className="create-pp-post-img3-left">
                  {postimgvid[0].indexOf("image") !== -1 ? (
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[0]
                      }
                    />
                  ) : (
                    <video
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[0]
                      }
                      controls={true}
                    />
                  )}
                </div>
                <div className="create-pp-post-img3-right">
                  <div className="create-pp-post-img3-right-in">
                    {postimgvid[1].indexOf("image") !== -1 ? (
                      <img
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[1]
                        }
                      />
                    ) : (
                      <video
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[1]
                        }
                        controls={true}
                      />
                    )}
                  </div>
                  <div className="create-pp-post-img3-right-in">
                    {postimgvid[2].indexOf("image") !== -1 ? (
                      <img
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[2]
                        }
                      />
                    ) : (
                      <video
                        src={
                          process.env.REACT_APP_AWS_IMG_VID_PATH + postimgvid[2]
                        }
                        controls={true}
                      />
                    )}
                    {postimgvid.length > 3 ? (
                      <div
                        className="create-pp-cunt"
                        onClick={() => {
                          setallimg(postimgvid);
                        }}
                        data-toggle="modal"
                        data-target="#allimgs"
                      >
                        {postimgvid.length - 3} +
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )
          ) : (
            ""
          )}
          <div
            style={
              post.pollChoices !== undefined && post.pollChoices.length > 0
                ? { display: "block" }
                : { display: "none" }
            }
          >
            {post.pollChoices !== undefined && post.pollChoices.length > 0
              ? post.pollChoices.map((opt) => {
                  var givevote =
                    post.pollDuration !== "No time limit"
                      ? moment
                          .duration(
                            moment()
                              .startOf("day")
                              .diff(moment(post.createdAt, "YYYY-MM-DD"))
                          )
                          .asDays() > post.pollDuration
                        ? false
                        : true
                      : true;
                  return (
                    <>
                    <div className="newsfeed-agree-all">
                    <div
                      className="newsfeed-agree-main"
                      onClick={() => {
                        if (givevote) handleVote(opt._id, post._id);
                      }}
                    >
                      <span
                        className="newsfeed-agree-main-color"
                        style={{
                          width: `${
                            post.pollTotalVotes !== undefined &&
                            post.pollTotalVotes.length > 0
                              ? Math.round(
                                  (opt.votes.length * 100) /
                                    post.pollTotalVotes.length
                                )
                              : 0
                          }%`,
                          background: "#d3efe7",
                        }}
                      ></span>
                      <span className="newsfeed-agree-main-title">
                        {opt.value}
                      </span>
                      <span className="newsfeed-agree-main-ps">
                        {post.pollTotalVotes !== undefined &&
                        post.pollTotalVotes.length > 0
                          ? Math.round(
                              (opt.votes.length * 100) /
                                post.pollTotalVotes.length
                            )
                          : 0}
                        %
                      </span>
                    </div>
                    <span className="news-votes-cunt">{opt.votes.length === 0? "0 Vote" : opt.votes.length === 1 ? "1 Vote" : `${opt.votes.length} Votes`} </span>
                    </div>
                    </>
                  );
                })
              : ""}
            <div className="newsfeed-votes-main">
              <span className="cstm-votes-ct">
                {post.pollTotalVotes !== undefined
                  ? post.pollTotalVotes.length
                  : 0}{" "}
                Votes
              </span>
              <span className="cstm-poll-will-close">
                &bull;{" "}
                {post.pollDuration !== "No time limit" ? moment
                  .duration(
                    moment()
                      .startOf("day")
                      .diff(moment(post.createdAt, "YYYY-MM-DD"))
                  )
                  .asDays() <= post.pollDuration
                  ? `Poll will close in ${post.pollDuration} ${
                      post.pollDuration !== "No time limit"
                        ? post.pollDuration === "1"
                          ? "Day"
                          : "Days"
                        : ""
                    }`
                  : "Voting closed" : "No time limit for voting"}
              </span>
            </div>
          </div>
          <div className="newsfeed-post-right-cmt">
            <div className="newsfeed-post-right-cmt-left">
              <span
                className="newfeed-img-name"
                onClick={() => {
                  handleLike(post._id);
                }}
              >
                {post.likes.list.includes(USER_ID) ? (
                  <img src={fillheart} />
                ) : (
                  <img src={Heart} />
                )}
                {post.likes.list.includes(USER_ID)
                  ? post.likes.count === 1
                    ? "You liked this post"
                    : `You and ${
                        post.likes.count - 1
                      } other people like this post`
                  : `${post.likes.count} Likes`}
              </span>
            </div>
            <div className="newsfeed-post-right-cmt-right">
              <span
                className="newfeed-img-name"
                onClick={() => {
                  handleComment(post._id);
                }}
              >
                <img src={Message} />
                {post.comments.length} Comments
              </span>
              <span className="newfeed-img-name">
                <img src={Share} /> Shares
              </span>
            </div>
          </div>
          <PostComments allcomments={allcomments} cntcmt={cntcmt} post={post}
  deletecmt={deletecmt}
  userIsMember={true}
  likecomment={likecomment}
  USER_ID={USER_ID}
  totalcmt={totalcmt} addcomment={addcomment} setrereply={setrereply} rereply={rereply} reply={reply} handlemorecmt={handlemorecmt} setreply={setreply} showcinput={showcinput} setComment={setComment} comment={comment} cmtinprocess={cmtinprocess} called_from={"admin"}/>
        </div>
      </div>
    );
  }
};

export default DisplayPost;
