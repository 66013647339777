import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import webimage1 from "../../../Images/f1.png";
import webimage2 from "../../../Images/f2.png";
import webimage3 from "../../../Images/f3.png";
import webimage4 from "../../../Images/f4.png";
import webimage5 from "../../../Images/f5.png";
import webimage6 from "../../../Images/f6.png";
import GloblePopup from "./GloblePopup";
import AllGroupMobile from "./AllGroupMobile";
import AnnoucementPopup from "./AnnoucementPopup";
import EventListPopup from "./EventListPopup";
import FilesPopup from "./FilesPopup";
import ForumListPopup from "./ForumListPopup";
import NotificationListPopup from "./NotificationListPopup";
import SavedPostPopup from "./SavedFilesPopup";
// import View from "../../../Images/view.svg";
// import Close from "../../../Images/close.svg";

// Icons
import DashboardLineIcon from "remixicon-react/DashboardLineIcon";
import DashboardFillIcon from "remixicon-react/DashboardFillIcon";
import GroupLineIcon from "remixicon-react/GroupLineIcon";
import GroupFillIcon from "remixicon-react/GroupFillIcon";
import VideoLineIcon from "remixicon-react/VideoLineIcon";
import VideoFillIcon from "remixicon-react/VideoFillIcon";
import Chat3LineIcon from "remixicon-react/Chat3LineIcon";
import Chat3FillIcon from "remixicon-react/Chat3FillIcon";
import Notification2LineIcon from "remixicon-react/Notification2LineIcon";
import Notification2FillIcon from "remixicon-react/Notification2FillIcon";

const Footer = ({ openingpop, setopenpop }) => {
  const history = useNavigate();
  const pathname = useLocation();
  // const [openingpop, setopenpop] = useState(false);
  return (
    <div
      className={`webfooter ${
        pathname.pathname.split("/")[1] === "frontendchat" ? "active" : ""
      }`}
    >
      <span className="footer-icon">
        <a
          onClick={() => {
            setopenpop({
              annou: false,
              files: false,
              save: false,
              event: false,
              forum: false,
              notifi: false,
              allgroup: false,
            });
            history("/dashboard");
          }}
        >
          {pathname.pathname.split("/")[1] === "dashboard" &&
          !openingpop.annou &&
          !openingpop.files &&
          !openingpop.save &&
          !openingpop.event &&
          !openingpop.forum &&
          !openingpop.notifi &&
          !openingpop.allgroup ? (
            <DashboardFillIcon size="24px" color="#1372D1" />
          ) : (
            <DashboardLineIcon size="24px" />
          )}
          {/* <img src={webimage1} /> */}
        </a>
      </span>
      {window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ?
      <span className="footer-icon">
        <a
          onClick={() => {
            setopenpop({
              annou: false,
              files: false,
              save: false,
              event: false,
              forum: false,
              notifi: false,
              allgroup: true,
            });
          }}
        >
          {openingpop && openingpop.allgroup ? (
            <GroupFillIcon size="24px" color="#1372D1" />
          ) : (
            <GroupLineIcon size="24px" />
          )}
          {/* <img src={webimage2} /> */}
        </a>
      </span> : <></>}
      <span className="footer-icon">
        <span
          onClick={() => {
            setopenpop({
              annou: false,
              files: false,
              save: false,
              event: false,
              forum: false,
              notifi: false,
              allgroup: false,
            });
            history(`/videos`);
          }}
        >
          {pathname.pathname.split("/")[1] === "frontcontentlibrary" &&
          !openingpop.annou &&
          !openingpop.files &&
          !openingpop.save &&
          !openingpop.event &&
          !openingpop.forum &&
          !openingpop.notifi &&
          !openingpop.allgroup ? (
            <VideoFillIcon size="24px" color="#1372D1" />
          ) : (
            <VideoLineIcon size="24px" />
          )}
          {/* <img src={webimage3} /> */}
        </span>
      </span>
      {window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ?
      <span
        className="footer-icon"
        onClick={() => {
          setopenpop({
            annou: false,
            files: false,
            save: false,
            event: false,
            forum: false,
            notifi: false,
            allgroup: false,
          });
          history(`/frontendchat/me/me`);
        }}
      >
        <a>
          {pathname.pathname.split("/")[1] === "frontendchat" &&
          !openingpop.annou &&
          !openingpop.files &&
          !openingpop.save &&
          !openingpop.event &&
          !openingpop.forum &&
          !openingpop.notifi &&
          !openingpop.allgroup ? (
            <Chat3FillIcon size="24px" color="#1372D1" />
          ) : (
            <Chat3LineIcon size="24px" />
          )}
          {/* <img src={webimage4} /> */}
        </a>
      </span>:<></>}
      {window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ?
      <span className="footer-icon">
        <a
          onClick={() => {
            setopenpop({
              annou: false,
              files: false,
              save: false,
              event: false,
              forum: false,
              allgroup: false,
              notifi: true,
            });
          }}
        >
          {openingpop && openingpop.notifi ? (
            <Notification2FillIcon size="24px" color="#1372D1" />
          ) : (
            <Notification2LineIcon size="24px" />
          )}
          {/* <img src={webimage5} /> */}
        </a>{" "}
      </span>: <></>}
      {/* <span className="footer-icon"> <a href="#"><img src={webimage6} /></a> </span> */}
     {window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ?
      <div className="header-ft-menu-view">
        <span className="footer-icon" data-toggle="dropdown">
          <img src={webimage6} />
        </span>
        <div className="dropdown-menu header-menu-dp footer-menu">
          <ul>
            <li>
              <a
                onClick={() => {
                  setopenpop({
                    files: false,
                    save: false,
                    event: false,
                    forum: false,
                    notifi: false,
                    allgroup: false,
                    annou: true,
                  });
                }}
              >
                Announcements
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setopenpop({
                    annou: false,
                    files: false,
                    save: false,
                    forum: false,
                    notifi: false,
                    allgroup: false,
                    event: true,
                  });
                }}
              >
                Events
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setopenpop({
                    annou: false,
                    files: false,
                    save: false,
                    event: false,
                    notifi: false,
                    allgroup: false,
                    forum: true,
                  });
                }}
              >
                Forums
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setopenpop({
                    annou: false,
                    save: false,
                    event: false,
                    forum: false,
                    notifi: false,
                    allgroup: false,
                    files: true,
                  });
                }}
              >
                Files
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setopenpop({
                    annou: false,
                    files: false,
                    event: false,
                    forum: false,
                    notifi: false,
                    allgroup: false,
                    save: true,
                  });
                }}
              >
                Saved Posts
              </a>
            </li>
          </ul>
        </div>
      </div> : <></>}
      {/* <AnnoucementPopup />
      <FilesPopup />
      <SavedPostPopup />
      <EventListPopup />
      <ForumListPopup />
      <NotificationListPopup />
      <AllGroupMobile /> */}
    </div>
  );
};
export default Footer;
