import Header from '../../components/UserComponents/Dashboard/Header'
import ChatMainPage from './ChatMainPage.js'
// import "./Chat.css"
// import ChatSideBar from './Components/ChatSideBar.js'

const index = () => {
  return (
    <>
      <Header />
      <div className="">
            {/* <ChatSideBar/> */}
            <div className={``}>
            <ChatMainPage />
          </div>
        </div>
    </>
  )
}

export default index