import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { SetMealRounded } from "@mui/icons-material";

const UserInfoSideBar = () => {
    let history = useNavigate();
    const dispatch = useDispatch();
    const [isMenu, setMenu] = useState(false)
    const { action } = useParams();
    useEffect(() => {
        if (action === "collaborators" || action === "listing" || action === 'registeruser' || action === 'attendees' || action === 'import' || action === 'cancelled'  || action === 'partnerlist' || action === 'blockuserslist' || action === "leaduserslist") {
            setMenu(true)
        } else {
            setMenu(false)
        }
    }, [action])

    const allPendingRequest = useSelector((state) => {
        return state.allPendingRequest;
    });

    return (
        <>

            <div className="users-info-sidebar">
                <div className="accordion" id="accordionExample">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <button
                                type="button"
                                className={`btn-link sd-active2 ${isMenu && 'show-icon'}`}
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                onClick={() => {
                                    setMenu(!isMenu)
                                }}
                            >
                                Users info <i className="fa fa-angle-down"></i>
                            </button>
                        </div>
                        <div
                            id="collapseOne"
                            className={`collapse ${isMenu && 'show'}`}
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                        >

                            <div className="cstm-ac-in-menu">
                                <ul>
                                    <li>
                                        <Link to={"/user/listing"} className={`${(action === 'registeruser' || action === 'listing' || action === 'edituser') ? 'sd-active' : ''}`}>
                                            Registered users
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`${action === 'attendees' ? 'sd-active' : ''}`} to={"/user/attendees"} >All users</Link>
                                    </li>
                                    <li>
                                        <Link className={`${action === 'import' ? 'sd-active' : ''}`} to={"/user/import"} >Import to mongo</Link>
                                    </li>
                                    
                                    <li>
                                        <Link className={`${action === 'collaborators' ? 'sd-active' : ''}`} to={"/user/collaborators"} >Team-users</Link>
                                    </li>
                                    <li>
                                        <Link className={`${action === 'cancelled' ? 'sd-active' : ''}`} to={"/user/cancelled"} >Cancelled Users</Link>
                                    </li>
                                    <li>
                                        <Link to={"/user/partnerlist"}
                                            className={`${action === 'partnerlist' ? 'sd-active' : ''}`}>
                                            Partners
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/user/blockuserslist"}
                                            className={`${action === 'blockuserslist' ? 'sd-active' : ''}`}>
                                            Blocked users
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to={"/user/leaduserslist"}
                                            className={`${action === 'leaduserslist' ? 'sd-active' : ''}`}>
                                            Lead users {allPendingRequest > 0 && <span className='request-count'>{allPendingRequest}</span>}
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingTwo">
                            <Link
                                className={`${action === 'customregisterform' ? 'sd-active' : ''}`}
                                to={"/user/customregisterform"}
                            >
                                Registration form
                            </Link>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingThree">
                            <Link
                                className={`${action === 'questions' ? 'sd-active' : ''}`}
                                to={"/user/questions"}
                            >
                                Questions & answers
                            </Link>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingFour">
                            <Link
                                className={`${action === 'deleterequestusers' ? 'sd-active' : ''}`}
                                to={"/user/deleterequestusers"}
                            >
                                Delete Request Users
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserInfoSideBar;
