import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Services from "../../../Services/auth.service.event";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import Dropzone from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import { useDropzone } from "react-dropzone";
import HtmlEditor from "../../AdminCommonComponent/HtmlEditor";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";
import ProfileIcon from "../../AdminCommonComponent/ProfileIcon";
import authService from "../../../Services/auth.service";
import MessageModal from "../../AdminCommonComponent/MessageModal";
import DeleteModalWithLoader from "../../AdminCommonComponent/DeleteModalWithLoader";
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
import TableBodyComponent from "../../AdminCommonComponent/TableComponent/TableBodyComponent";
import authServiceEvent from "../../../Services/auth.service.event";
import { searchAndSortArray } from "../../AdminCommonComponent/TableComponent/CommonFunction";
import AlertComponent from "../../../Hooks/AlertComponent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import loading from "../../../Images/loader.gif";
import ZoomImageModal from "../../AdminCommonComponent/ZoomImageModal";
import ModalPopup from "../../ModalPopup/ModalPopup";
import PurchaseTicketCountModal from "./PurchaseTicketCountModal";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import ImportQuestions from "./ImportQuestions";

//create a object using given key
function createData(
  img,
  name,
  firstName,
  lastName,
  email,
  company,
  type,
  count,
  roleId,
  action,
  participantId,
  isManuallyAdded
) {
  return {
    img,
    name,
    firstName,
    lastName,
    email,
    company,
    type,
    count,
    roleId,
    action,
    participantId,
    isManuallyAdded,
  };
}
//Event Session module
const Attendees = ({ tabInformation }) => {
  const descriptionRef = useRef(null);
  const editDescriptionRef = useRef(null);
  const offerRef = useRef(null);
  const editOfferRef = useRef(null);

  const emptyObject = {
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    profession: "",
    phone: "",
    type: "",
    auth0: "",
    facebook: "",
    linkedin: "",
    oldType: "",
    newType: "",
    description: "",
    offer: "",
    contactPartnerName: "",
    passcode: "",
    profileImage: "",
    speakerIcon: "",
    guestIcon: "",
    partnerIcon: "",
    type_icon: "",
    displayName: "",
  };
  const emptyExistingModalObject = { type: "", selectedUser: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const searchVariable = searchParams.get("search");
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [resendId, setResendId] = useState("");
  const [showAlert, setshowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [addEvent, setAddEvent] = useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [selectedId, setSelectedId] = useState("");
  const [addInprocess, setAddInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [eventAttendeesList, setEventAttendeesList] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);
  const [importLoader, setImportLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const [selectedRole, setSelectedRole] = useState("");
  const [searchParticipents, setSearchParticipents] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allAttendeesList, setAllAttendeesList] = useState([]);
  const [existingAttendeeType, setExistingAttendeeType] = useState("");
  const [existingAttendeeErr, setExistingAttendeeErr] = useState(
    emptyExistingModalObject
  );
  const [existingModalLoader, setExistingModalLoader] = useState(false);
  const [existingModalAlert, setExistingModalAlert] = useState("");

  const [messageModalText, setMessageModalText] = useState("");
  const [messageModalDiv, setMessageModalDiv] = useState([]);
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const [selected, setSelected] = useState([]);
  const [selectedAttende, setSelectedAttende] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);
  const [validUserType, setValidUserType] = useState([]);
  const [eventDetail, setEventDetail] = useState([]);
  const [againLoad, setAgainLoad] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [resendAll, setResendAll] = useState(false);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const [editorStateDescCreateModal, setEditorStateDescCreateModal] = useState(
    EditorState.createEmpty()
  );
  const [editorStateOfferCreateModal, setEditorStateOfferCreateModal] =
    useState(EditorState.createEmpty());
  const [editorStateDescEditModal, setEditorStateDescEditModal] = useState(
    EditorState.createEmpty()
  );
  const [editorStateOfferEditModal, setEditorStateOfferEditModal] = useState(
    EditorState.createEmpty()
  );
  const [clearSearch, setClearSearch] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [isZoomImg, setIsZoomImg] = useState(false);
  const [zoomImg, setZoomImg] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [showPurchaseTicketModal, setShowPurchaseTicketModal] = useState(false);
  const [purchasedTicketList, setPurchasedTicketList] = useState([]);
  const [isManuallyAddedVal, setIsManuallyAddedVal] = useState("");
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    1 / 1
  );

  //event id
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");
  const attendeeIdFromUrl = new URLSearchParams(location.search).get(
    "attendee-id"
  );
  const roleIdFromUrl = new URLSearchParams(location.search).get("role-id");
  //call when tab name change
  useEffect(() => {
    if (tabInformation === "Attendees") {
      setLoader(true);
      setClearSearch(true);
      getAttendeesByEventIdApi();
      getAllAttendeesApi();
      getAllParticipantTypes();
      setFilterType("all");
      getEventDetail();
      if (attendeeIdFromUrl && roleIdFromUrl) {
        handleEdit(attendeeIdFromUrl, roleIdFromUrl);
        $("#editAttendee").modal("show");
      }
    }
  }, [tabInformation]);

  //getting event detail api
  const getEventDetail = () => {
    authServiceEvent
      .getEventById(queryVariable)
      .then((response) => {
        if (response.data.status) {
          setEventDetail(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  //get all participant types
  const getAllParticipantTypes = () => {
    authServiceEvent
      .searchEventParticipantTypesApi(queryVariable, "")
      .then((res) => {
        const participantTypesData = res.data.list;
        setValidUserType(participantTypesData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //get all attendees list
  const getAttendeesByEventIdApi = (
    search,
    col,
    sort,
    role,
    menuallyAdded
    // isDenverCheckInForm
  ) => {
    const menualState = menuallyAdded ?? isManuallyAddedVal;
    setLoader(true);
    if (search) {
      setSearchedText(search);
    }
    let query = "";
    query +=
      search !== undefined && search !== null ? `search=${search}` : "search=";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    query +=
      role !== undefined && role !== null && role !== "" && role !== "all"
        ? `&role=${role}`
        : "";
    query +=
      menualState !== undefined && menualState !== null && menualState !== ""
        ? `&isManuallyAdded=${menualState}`
        : "";
    Services.getAllAttendeesByEventId(queryVariable, query)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          setPage(0);
          var data = res.data.data;
          if (role !== undefined && role !== null && role !== "") {
            if (
              validUserType.filter(
                (t) =>
                  t._id.toString() === role.toString() &&
                  t.role.toLowerCase() === "partner"
              ).length > 0
            ) {
              const arrayOfObjects = data;
              const sortedArray = arrayOfObjects.sort(
                (a, b) => a.partner_order - b.partner_order
              );
              data = sortedArray;
            }
          }
          if (data.length > 0) {
            var table = [];
            for (var i = 0; i < data.length; i++) {
              table[i] = createData(
                data[i].type_icon,
                `${
                  data[i].first_name !== undefined &&
                  data[i].first_name !== null
                    ? data[i].first_name
                    : ""
                } ${
                  data[i].last_name !== undefined && data[i].last_name !== null
                    ? data[i].last_name
                    : ""
                }`,
                data[i].first_name !== undefined && data[i].first_name !== null
                  ? data[i].first_name
                  : "",
                data[i].last_name !== undefined && data[i].last_name !== null
                  ? data[i].last_name
                  : "",
                data[i].email,
                data[i].company,
                data[i].type,
                data[i].purchasedTicket,
                data[i].roleId,
                data[i]._id,
                data[i].attendee_id,
                data[i].isManuallyAdded
              );
            }
            setEventAttendeesList(table);
            setAllEvents(table);
            setRow(table);
          } else {
            setRow([]);
            setAllEvents([]);
          }
        } else {
          setRow([]);
          setAllEvents([]);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  const getAllAttendeesApi = () => {
    authService
      .getAllUsersMembersAndAttendees()
      .then((res) => {
        const attendeesArray = res.data;
        const formatedArray = attendeesArray.map((a) => {
          const attendeeData = a.attendeeDetail;
          return {
            id: a._id,
            email: a["Preferred Email"]
              ? a["Preferred Email"]
              : a["email"]
              ? a["email"]
              : "",
            name: `${
              a.display_name !== undefined && a.display_name !== null
                ? a.display_name
                : ""
            }`,
          };
        });
        setAllAttendeesList(formatedArray);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //close create attendees module
  const handleCloseAddModel = (e) => {
    setEditEvent(emptyObject);
    setEditErr(emptyObject);
    setAddErr(emptyObject);
    setAddEvent(emptyObject);
    setExistingAttendeeErr(emptyExistingModalObject);
    setExistingAttendeeType("");
    setSelectedUsers([]);
    handleClearAllStates();

    // old HTML editor
    setEditorStateDescCreateModal(EditorState.createEmpty());
    setEditorStateOfferCreateModal(EditorState.createEmpty());
    setEditorStateOfferEditModal(EditorState.createEmpty());
    setEditorStateOfferEditModal(EditorState.createEmpty());
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //called when edit button is clicked
  const handleEdit = (value, role) => {
    navigate(
      `/events/detail?id=${eventId}&tab=Attendees&attendee-id=${value}&role-id=${role}`
    );
    setSelectedId(value);
    setSelectedRole(role);
    setEditModalLoader(true);
    const data = {
      id: value,
      role: role,
      eventId: queryVariable,
    };
    Services.getEventAttendeeById(data)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setEditEvent({
            // title: data.title??"",
            name: data.name ?? "",
            email: data.email ?? "",
            firstName: data.firstName,
            lastName: data.lastName,
            company:
              data.company !== undefined && data.company !== null
                ? data.company
                : "",
            profession:
              data.profession !== undefined && data.profession !== null
                ? data.profession
                : "",
            phone:
              data.phone !== undefined && data.phone !== null ? data.phone : "",
            type: data.role_type,
            auth0:
              data.auth0Id !== undefined && data.auth0Id !== null
                ? data.auth0Id
                : "",
            facebook:
              data.facebook !== undefined && data.facebook !== null
                ? data.facebook
                : "",
            linkedin:
              data.linkedin !== undefined && data.linkedin !== null
                ? data.linkedin
                : "",
            oldType: data.role_type,
            isManuallyAdded: data?.isManuallyAdded,
            newType: data.role_type,
            description: data?.description,
            offer: data?.offer,
            contactPartnerName: data?.contactPartnerName ?? "",
            passcode: data?.passcode ?? "",
            // profileImage: data?.profileImg ?? "",
            type_icon: data?.type_icon ?? "",
            displayName: data?.display_name ?? "",
            // speakerIcon: data?.speakerIcon ?? "",
            // partnerIcon: data?.partnerIcon ?? "",
            // guestIcon: data?.guestIcon ?? "",
            questionsList: data?.event_questions,
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //called when existingModalAlert state change
  useEffect(() => {
    if (existingModalAlert) {
      setTimeout(() => {
        setExistingModalAlert("");
      }, 3000);
    }
  }, [existingModalAlert]);

  //sorting function
  const handleSort = (col, direc) => {
    setLoader(true);
    getAttendeesByEventIdApi("", col, direc);
  };

  //covert function dataURLtoFile
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], Math.random().toString() + ".png", { type: mime });
  };

  //html to editor state
  function htmlToEditor(html, setEditor) {
    const contentBlock = htmlToDraft(html.replace("<p>&nbsp;</p>", ""));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditor(editorState);
  }

  //handling editor state to html state and uploading images to server
  const handleDesciption = async (state) => {
    return new Promise((resolve, reject) => {
      const contentState = state.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      if (
        contentRaw?.entityMap &&
        Object.keys(contentRaw.entityMap).length > 0
      ) {
        var entityMap = contentRaw.entityMap;
        var formdata = new FormData();
        var uploadStatus = false;
        var keyList = [];

        for (var key in entityMap) {
          var data = entityMap[key];
          if (
            data.type === "IMAGE" &&
            data.data.src.startsWith("data:image/")
          ) {
            uploadStatus = true;
            var fileData = dataURLtoFile(data.data.src);
            formdata.append(`image`, fileData);
            keyList = [...keyList, key];
          }
        }

        if (uploadStatus) {
          Services.eventUploadFiles(formdata)
            .then((res) => {
              if (res.data.status) {
                var mediaData = res.data.media;
                mediaData.map((m, index) => {
                  contentRaw.entityMap[keyList[index]].data.src = m.key;
                });
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              } else {
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              }
            })
            .catch((e) => {
              console.log(e);
              const htmlContent = draftToHtml(contentRaw);
              reject(htmlContent);
            });
        } else {
          const htmlContent = draftToHtml(contentRaw);
          resolve(htmlContent);
        }
      } else {
        const htmlContent = draftToHtml(contentRaw);
        resolve(htmlContent);
      }
    });
  };

  //on change for Edit
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      setAddErr({ ...addErr, [e.target.name]: "" });
    }

    if (e.target.name === "firstName" && e.target.value.length == 0) {
      setAddErr({ ...addErr, [e.target.name]: "Enter first name!" });
    }

    if (e.target.name === "lastName" && e.target.value.length == 0) {
      setAddErr({ ...addErr, [e.target.name]: "Enter last name!" });
    }

    if (e.target.name === "phone" && e.target.value.length == 0) {
      setAddErr({ ...addErr, [e.target.name]: "Enter phone!" });
    }
  };

  //on change for Edit
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    if (e.target.name === "type") {
      let arr = editEvent;
      arr.newType = e.target.value;
      arr.type = e.target.value;
      setEditEvent(arr);
    } else {
      setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    }

    if (e.target.value.length > 0) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }

    if (e.target.name === "phone" && e.target.value.length == 0) {
      setEditErr({ ...editErr, [e.target.name]: "Enter phone!" });
    }
  };

  //Create session validation and api call
  const checkDesciptionValue = (state) => {
    var text = stateToHTML(state.getCurrentContent(), {
      defaultBlockTag: null,
    }).replace(/&nbsp;$/, "");
    if (text.length > 0 && text !== "<br>") {
      return true;
    } else {
      return false;
    }
  };

  //Called when user click on update changes
  const handleAddSubmit = async () => {
    var status = true;
    var temp = {};
    // if (addEvent.title.trim().length === 0) {
    //   temp = { ...temp, title: "Enter title!" };
    //   status = false;
    // }
    // if (addEvent.name.trim().length === 0) {
    //   temp = { ...temp, name: "Enter name!" };
    //   status = false;
    // }
    if (addEvent.firstName.trim().length === 0) {
      temp = { ...temp, firstName: "Enter first name!" };
      status = false;
    }
    if (addEvent.lastName.trim().length === 0) {
      temp = { ...temp, lastName: "Enter last name!" };
      status = false;
    }
    if (addEvent.email.trim().length === 0) {
      temp = { ...temp, email: "Enter email!" };
      status = false;
    } else if (!isValidEmail(addEvent.email)) {
      temp = { ...temp, email: "Invalid email format!" };
      status = false;
    }
    if (addEvent.type.length === 0) {
      temp = { ...temp, type: "Select attendee type!" };
      status = false;
    }
    // if (addEvent.phone.length === 0) {
    //   temp = { ...temp, phone: "Enter phone!" };
    //   status = false;
    // }
    // if (addEvent.passcode.trim().length === 0) {
    //   temp = { ...temp, passcode: "Enter passcode!" };
    //   status = false;
    // }
    // if (!addEvent.profileImage) {
    //   temp = { ...temp, profileImage: "Upload profile photo!" };
    //   status = false;
    // }

    if (status) {
      setAddInprocess(true);
      // const descriptionHtml = await handleDesciption(
      //   editorStateDescCreateModal
      // );
      // const offerHtml = await handleDesciption(editorStateOfferCreateModal);
      let descriptionContent = "";
      if (descriptionRef.current) {
        descriptionContent = descriptionRef.current.getContent();
      }
      let offerContent = "";
      if (offerRef.current) {
        offerContent = offerRef.current.getContent();
      }
      addApiCall(descriptionContent, offerContent);
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setAddErr(temp);
    }
  };

  //On adding attendee, while changing the participant type
  useEffect(() => {
    setEditorStateDescCreateModal(EditorState.createEmpty());
    setEditorStateOfferCreateModal(EditorState.createEmpty());
    setAddEvent({ ...addEvent, auth0: "" });
  }, [addEvent.type]);

  //On editing attendee, while changing the participant type
  useEffect(() => {
    if (
      validUserType.filter(
        (t) =>
          t._id.toString() === editEvent.type.toString() && t.role !== "Partner"
      ).length > 0
    ) {
      setEditorStateDescEditModal(EditorState.createEmpty());
      setEditorStateOfferEditModal(EditorState.createEmpty());
    }
  }, [editEvent.type]);

  //add attendees api call
  const addApiCall = (descriptionHtml, offerHtml) => {
    const formData = new FormData();
    formData.append(
      "name",
      `${addEvent.firstName.trim()} ${addEvent.lastName.trim()}`
    );
    formData.append("firstName", addEvent.firstName);
    formData.append("lastName", addEvent.lastName);
    formData.append("display_name", addEvent.displayName);
    formData.append("email", addEvent.email);
    formData.append("company", addEvent.company);
    formData.append("profession", addEvent.profession);
    formData.append("phone", addEvent.phone);
    formData.append("type", addEvent.type);
    formData.append("auth0Id", addEvent.auth0);
    formData.append("facebook", addEvent.facebook);
    formData.append("linkedin", addEvent.linkedin);
    formData.append("eventId", queryVariable);
    formData.append(
      "description",
      descriptionHtml === "<p></p>\n" ? "" : descriptionHtml
    );

    formData.append("offer", offerHtml === "<p></p>\n" ? "" : offerHtml);
    formData.append("contactPartnerName", addEvent.contactPartnerName);
    formData.append("passcode", addEvent.passcode);
    formData.append("type_icon", addEvent.type_icon);

    Services.createEventAttendees(formData)
      .then((res) => {
        if (res.data.status) {
          handleClearAllStates();
          setshowAlert(true);
          setAlertMsg(res.data.message);
          setAgainLoad(true);
          document.querySelector("#AddAttendee .close").click();
          getAttendeesByEventIdApi(searchedText, "", "", selectedFilter);
          setAddInprocess(false);
          setAddEvent(emptyObject);
          setEditorStateDescCreateModal(EditorState.createEmpty());
          setEditorStateOfferCreateModal(EditorState.createEmpty());
          Services.sendSocketEventAtAttendeeUpdation({
            listOfSocketEvents: res.data.chatData,
          });
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 5000);
        } else {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          handleClearAllStates();
        }
        setAddInprocess(false);
      })
      .catch((e) => {
        console.log(e);
        setAddInprocess(false);
      });
    setAddErr(emptyObject);
  };

  //Called when user click on update changes
  const handleEditSubmit = async () => {
    var status = true;
    var temp = {};

    // if (editEvent.name.trim().length === 0) {
    //   temp = { ...temp, name: "Enter name!" };
    //   status = false;
    // }
    if (editEvent.firstName.trim().length === 0) {
      temp = { ...temp, firstName: "Enter first name!" };
      status = false;
    }
    if (editEvent.lastName.trim().length === 0) {
      temp = { ...temp, lastName: "Enter last name!" };
      status = false;
    }
    if (editEvent.email.trim().length === 0) {
      temp = { ...temp, email: "Enter email!" };
      status = false;
    } else if (!isValidEmail(editEvent.email)) {
      temp = { ...temp, email: "Invalid email format!" };
      status = false;
    }
    if (editEvent.type.length === 0) {
      temp = { ...temp, type: "Select attendee type!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.querySelectorAll(
        `[name="${emptyKeys[0]}"]`
      );
      if (inputElement) {
        inputElement[1].focus(); // Focus the input element
      }
    }
    if (status) {
      setEditInprocess(true);
      // const descriptionHtml = await handleDesciption(editorStateDescEditModal);
      // const offerHtml = await handleDesciption(editorStateOfferEditModal);
      let editDescriptionContent = "";
      if (editDescriptionRef.current) {
        editDescriptionContent = editDescriptionRef.current.getContent();
      }
      let editOfferContent = "";
      if (editOfferRef.current) {
        editOfferContent = editOfferRef.current.getContent();
      }
      editApiCall(editDescriptionContent, editOfferContent);
    } else {
      setEditErr(temp);
    }
  };

  //Edit attendees api call
  const editApiCall = (descriptionHtml, offerHtml) => {
    const formData = new FormData();
    formData.append(
      "name",
      `${editEvent.firstName.trim()} ${editEvent.lastName.trim()}`
    );
    formData.append("firstName", editEvent.firstName);
    formData.append("lastName", editEvent.lastName);
    formData.append("display_name", editEvent.displayName);
    formData.append("email", editEvent.email);
    formData.append("company", editEvent.company);
    formData.append("profession", editEvent.profession);
    formData.append("phone", editEvent.phone);
    formData.append("type", editEvent.type);
    formData.append("auth0Id", editEvent.auth0);
    formData.append("facebook", editEvent.facebook);
    formData.append("linkedin", editEvent.linkedin);
    formData.append("eventId", queryVariable);
    formData.append(
      "description",
      descriptionHtml === "<p></p>\n" ? "" : descriptionHtml
    );
    formData.append("offer", offerHtml === "<p></p>\n" ? "" : offerHtml);
    formData.append("contactPartnerName", editEvent.contactPartnerName);
    formData.append("passcode", editEvent.passcode);
    formData.append("type_icon", editEvent.type_icon);
    formData.append("oldType", editEvent.oldType);
    formData.append("newType", editEvent.type);

    Services.editEventAttendees(selectedId, formData)
      .then((res) => {
        if (res.data.status) {
          handleClearAllStates();
          setshowAlert(true);
          document.querySelector("#editAttendee .close").click();
          getAttendeesByEventIdApi(searchedText, "", "", selectedFilter);
          setAgainLoad(true);
          setEditInprocess(false);
          setEditEvent({ ...editEvent, oldType: editEvent.newType });

          Services.sendSocketEventAtAttendeeUpdation({
            listOfSocketEvents: res.data.listOfSocketEvents,
          });
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 5000);
        } else {
          let actualData = res.data;
          setMessageModalText(res.data.message);
          let messageStringArray = [];
          if (actualData.data?.sessionList.length) {
            let actList =
              "Session List : " +
              actualData.data.sessionList.toString().replace(",", ", ");
            messageStringArray.push(actList);
          }
          setMessageModalDiv(messageStringArray);
          handleClearAllStates();
        }
        setEditInprocess(false);
        navigate(`/events/detail?id=${eventId}&tab=Attendees`);
      })
      .catch((e) => {
        console.log(e);
        setEditInprocess(false);
        handleClearAllStates();
      });
    setEditErr(emptyObject);
  };

  //callback function when file upload
  const onDrop = (acceptedFiles) => {
    importFile(acceptedFiles);
  };

  //called when file upload
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    maxSizeBytes: 10485760,
    onDrop,
  });

  //export attendees
  const handleExport = () => {
    setExportLoader(true);
    if (eventAttendeesList.length > 0) {
      var final_list = [];

      eventAttendeesList.map((item) => {
        var obj = {};
        //obj.Name = item?.name ? item.name : "";
        obj[`First Name`] = item?.firstName ? item.firstName : "";
        obj[`Last Name`] = item?.lastName ? item.lastName : "";
        obj.Email = item?.email ? item.email : "";
        obj.Passcode = item?.passcode ? item.passcode : "";
        obj.Organisation = item?.company ? item.company : "";
        obj.Profession = item?.profession ? item.profession : "";
        obj["Contact Number"] = item?.phone ? item.phone : "";
        obj["Type"] = item?.type ? item.type : "";
        obj["Facebook Link"] = item?.facebook ? item.facebook : "";
        obj["Linkedin Link"] = item?.linkedin ? item.linkedin : "";
        obj["AUTH0"] = item?.auth0Id ? item.auth0Id : "";
        final_list.push(obj);
      });
      const workbook = XLSX.utils.book_new(); // create new workbook
      const sheet = XLSX.utils.json_to_sheet(final_list); // convert JSON data to sheet

      XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1"); // add the sheet to the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `${eventDetail.title} ${moment()
          .format("DD-MM-YYYY hh:mm A")
          .replace(":", "_")}.xlsx`
      );
      setshowAlert(true);
      setAlertMsg("User data exported!");
      setTimeout(() => {
        setshowAlert(false);
      }, 2000);
      setExportLoader(false);
    } else {
      setshowAlert(true);
      setAlertMsg("No data to export!");
      setTimeout(() => {
        setshowAlert(false);
      }, 2000);
      setExportLoader(false);
    }
  };

  //validatation function for file format of excel file
  const validationFileFormat = (data) => {
    var columnList = data[0];
    const expectedColumnSequence = [
      // "Title",
      //"Name",
      "First Name",
      "Last Name",
      "Email",
      "Passcode",
      "Organisation",
      "Profession",
      "Contact Number",
      "Type",
      "Facebook Link",
      "Linkedin Link",
      "AUTH0",
    ];
    if (data.length > 0 && columnList.length === 11) {
      if (expectedColumnSequence.length === columnList.length) {
        for (let i = 0; i < columnList.length; i++) {
          if (columnList[i] !== expectedColumnSequence[i]) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  };

  //email validation function
  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  //call when import excel file
  const importFile = (acceptedFile) => {
    if (
      acceptedFile[0] !== undefined &&
      acceptedFile[0] !== null &&
      acceptedFile[0] !== ""
    ) {
      setImportLoader(true);
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data_ = XLSX.utils.sheet_to_json(ws, { header: 1 });
        if (!validationFileFormat(data_)) {
          setshowAlert(true);
          setAlertMsg("Invalid Excel file format");
          setTimeout(() => {
            setshowAlert(false);
          }, 2000);
          setImportLoader(false);
          return;
        }
        const data = XLSX.utils.sheet_to_json(ws);
        var final_list = [];
        if (data && data.length > 0) {
          var validationFailure = false;
          data.map((item) => {
            if (!isValidEmail(item.Email.trim())) {
              setshowAlert(true);
              setAlertMsg("Invalid email format!");
              setTimeout(() => {
                setshowAlert(false);
              }, 2000);
              setImportLoader(false);
              validationFailure = true;
            }

            if (!validationFailure) {
              let filterValidTypes = validUserType.filter((userType) =>
                userType.role.includes(item.Type.trim())
              );
              if (filterValidTypes.length === 0) {
                setshowAlert(true);
                setAlertMsg("Invalid attendees type!");
                setTimeout(() => {
                  setshowAlert(false);
                }, 2000);
                setImportLoader(false);
                validationFailure = true;
              }
              var obj = {};
              obj.name =
                item["First Name"] && item["Last Name"]
                  ? `${item["First Name"].trim()} ${item["Last Name"].trim()}`
                  : `${item["First Name"]} ${item["Last Name"]}`;
              obj.firstName = item["First Name"]
                ? item["First Name"].trim()
                : item["First Name"];
              obj.lastName = item["Last Name"]
                ? item["Last Name"].trim()
                : item["Last Name"];
              obj.email = item.Email ? item.Email.trim() : item.Email;
              obj.passcode = item.Passcode
                ? item.Passcode.toString().trim()
                : item.Passcode;
              obj.company = item.Organisation
                ? item.Organisation.toString().trim()
                : item.Organisation;
              obj.type =
                filterValidTypes.length > 0 ? filterValidTypes[0].role : null;
              obj.profession = item.Profession
                ? item.Profession.trim()
                : item.Profession;
              obj.phone = item["Contact Number"]
                ? item["Contact Number"].toString().trim()
                : item["Contact Number"];
              obj.facebook = item["Facebook Link"]
                ? item["Facebook Link"].toString().trim()
                : item["Facebook Link"];
              obj.linkedin = item["Linkedin Link"]
                ? item["Linkedin Link"].toString().trim()
                : item["Linkedin Link"];
              obj.auth0Id = item["AUTH0"]
                ? item["AUTH0"].toString().trim()
                : item["AUTH0"];
              obj.eventId = queryVariable
                ? queryVariable.trim()
                : queryVariable;
              final_list.push(obj);
            }
          });
          if (validationFailure) {
            return;
          }
        }
        const req_data = {
          allAttendees: final_list,
        };
        Services.importAllAttendees(req_data)
          .then((res) => {
            if (res.data.status) {
              getAttendeesByEventIdApi();
            }
            setshowAlert(true);
            setAlertMsg(res.data.message);
            setAgainLoad(true);
            setTimeout(() => {
              setshowAlert(false);
            }, 2000);
            setImportLoader(false);
            Services.sendSocketEventAtAttendeeUpdation({
              listOfSocketEvents: res.data.listOfSocketEvents,
            });
          })
          .catch((e) => {
            setImportLoader(false);
            console.log(e, "error");
          });
      };
      reader.readAsBinaryString(acceptedFile[0]);
    }
  };

  //handle user selection without event
  const handleUserSelect = (user) => {
    const updatedSelection = selectedUsers.includes(user)
      ? selectedUsers.filter((selectedUser) => selectedUser !== user)
      : [...selectedUsers, user];
    if (updatedSelection.length > 0)
      setExistingAttendeeErr({ ...existingAttendeeErr, selectedUsers: "" });
    setSelectedUsers(updatedSelection);
  };

  //handles add existing user as attendee
  const handleAddExisting = () => {
    var status = true;
    var temp = {};
    if (!existingAttendeeType) {
      temp = { ...temp, type: "Select type of attendee!" };
      status = false;
    }
    if (selectedUsers.length === 0) {
      temp = { ...temp, selectedUsers: "Select attendee!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setExistingModalLoader(true);
      Services.addExistingAttendees({
        role: existingAttendeeType,
        eventId: queryVariable,
        attendeesList: selectedUsers.map((u) => u.id),
      })
        .then((res) => {
          if (res.data.status) {
            handleClearAllStates();
            setshowAlert(true);
            setAlertMsg(res.data.message);
            setExistingModalLoader(false);
            getAttendeesByEventIdApi(searchedText, "", "", selectedFilter);
            setAgainLoad(true);
            document.querySelector("#existingAttendee .close").click();
            Services.sendSocketEventAtAttendeeUpdation({
              listOfSocketEvents: res.data.listOfSocketEvents,
            });
            setTimeout(() => {
              setExistingAttendeeType("");
              setSelectedUsers([]);
              setshowAlert(false);
              setAlertMsg("");
            }, 3000);
          } else {
            setExistingModalAlert(res.data.message);
            setExistingModalLoader(false);
          }
        })
        .catch((e) => {
          console.log(e, "error");
          handleClearAllStates();
        });
    } else {
      setExistingAttendeeErr(temp);
    }
  };

  //close button event function for delete attendee modal
  const handleDeleteModalCloseEvent = () => {
    setShowDeleteModal(false);
    setDeleteAll(false);
    setDeleteId("");
    setSelectedAttende([]);
  };

  const handleResendModalCloseEvent = () => {
    setShowResendModal(false);
    setResendAll(false);
    setResendId("");
    setSelectedAttende([]);
  };

  //search and sorting component property function
  const createObjectFromArray = (arr, updateAllEventArray = true) => {};

  // set all filtered data
  const setAllFilteredData = (arrayWithoutFilter) => {
    if (arrayWithoutFilter.length) {
      if (
        document.getElementById("searchInputAttendees") !== null &&
        document.getElementById("searchInputAttendees").value !== null &&
        document.getElementById("sortingPatternAttendees") !== null &&
        document.getElementById("sortingPatternAttendees").value !== null
      ) {
        const inputText = document.getElementById("searchInputAttendees").value;
        const sortingPatternText = document.getElementById(
          "sortingPatternAttendees"
        ).value;
        const filteredArray = searchAndSortArray(
          arrayWithoutFilter,
          inputText,
          ["name", "email"],
          sortingPatternText,
          "name",
          filterType,
          true
        );
        setRow(filteredArray);
      } else {
        //  let filterArr = arrayWithoutFilter
        // if (filterType !== "all") {
        //   filterArr = arrayWithoutFilter.filter(row => row.roleId.toString() == filterType)
        // }
        //setRow(filterArr)
      }
    }
  };

  //call when allEvents state change
  useEffect(() => {
    setAllFilteredData(allEvents);
  }, [allEvents]);

  // filter attendee type
  const handleTypeFilter = (filter) => {
    setRow([]);
    setSelectedFilter(filter);
    if (filter === "all") {
      getAttendeesByEventIdApi();
      setPage(0);
    } else if (
      filter !== "all" &&
      validUserType.filter(
        (t) =>
          t._id.toString() === filter.toString() &&
          t.role.toLowerCase() !== "partner"
      ).length > 0
    ) {
      getAttendeesByEventIdApi(searchedText, "", "", filter);
      setPage(0);
    } else {
      getAttendeesByEventIdApi(searchedText, "partner_order", "Asc", filter);
      setPage(0);
    }
  };

  // filter import type
  const handleImportType = (impType) => {
    setRow([]);
    setIsManuallyAddedVal(impType);
    getAttendeesByEventIdApi(searchedText, "", "", selectedFilter, impType);
  };

  //dropping row call
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(rows);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    setRow(updatedRows);

    const ids = updatedRows.map((r) => r.action);
    authServiceEvent.rearrangeAttendee({ eventId: queryVariable, Ids: ids });
  };

  //call when stayOnPage state change
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#AddAttendee").hasClass("show")) {
        $("#AddAttendee").modal("show");
      }

      if ($("#editAttendee").hasClass("show")) {
        $("#editAttendee").modal("show");
      }

      if ($("#existingAttendee").hasClass("show")) {
        $("#existingAttendee").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#AddAttendee").hasClass("show")) {
        $("#AddAttendee").modal("hide");
      }

      if ($("#editAttendee").hasClass("show")) {
        $("#editAttendee").modal("hide");
      }

      if ($("#existingAttendee").hasClass("show")) {
        $("#existingAttendee").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //called when delete attendee
  const handleDeletebutton = () => {
    setDeleteLoader(true);
    const bodyData = {
      deleteData: selectedAttende,
    };
    Services.deleteBulk(queryVariable, bodyData)
      .then((res) => {
        if (res.data.status) {
          setSelected([]);
          setSelectedAttende([]);
          setshowAlert(true);
          setAlertMsg(res.data.message);
          setIsChecked(false);
          setDeleteAll(false);
          setDeleteLoader(false);
          setShowDeleteModal(false);
          getAttendeesByEventIdApi(searchedText, "", "", selectedFilter);
          setAgainLoad(true);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 3000);
        } else {
          setshowAlert(true);
          setShowDeleteModal(false);
          setAlertMsg(res.data.message + res.data.data.sessionList.toString());
          setDeleteLoader(false);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 3000);
          getAttendeesByEventIdApi();
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const handleResendButton = () => {
    setResendLoader(true);
    const bodyData = {
      attendeeData: selectedAttende.map((item) => ({
        attendeeId: item.participantId,
      })),
    };
    Services.ResendTicket(queryVariable, bodyData)
      .then((res) => {
        if (res.data.status) {
          setSelected([]);
          setSelectedAttende([]);
          setshowAlert(true);
          setAlertMsg(res.data.message);
          setIsChecked(false);
          setResendAll(false);
          setResendLoader(false);
          setShowResendModal(false);
          getAttendeesByEventIdApi(searchedText, "", "", selectedFilter);
          setAgainLoad(true);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 3000);
        } else {
          setshowAlert(true);
          setShowResendModal(false);
          setAlertMsg(res.data.message + res.data.data.sessionList.toString());
          setResendLoader(false);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 3000);
          getAttendeesByEventIdApi();
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  //get the ticket list purchased by the attendee
  const purchaseTicketAttendeesApi = (userId, participantId) => {
    Services.purchaseTicketListApi({
      userId: userId,
      participantId: participantId,
      eventId: eventId,
    })
      .then((res) => {
        setPurchasedTicketList(res?.data?.data);
        setLoader(false);
      })
      .catch((err) => console.log("Error:", err));
  };

  //close the purchase ticket modal
  const handlePurchaseTicketCloseModal = () => {
    setShowPurchaseTicketModal(false);
  };

  //called when attendee selected or notselected by marking checkbox in table
  const handleCheckboxChange = (event, id) => {
    const selectedIndex = selected.findIndex(
      (item) =>
        item.action === id.action &&
        item.roleId.toString() == id.roleId.toString()
    );
    const newSelected = [...selected];
    const newSelectedAttende = [...selectedAttende];

    if (selectedIndex === -1) {
      newSelected.push({ action: id.action, roleId: id.roleId });
      newSelectedAttende.push({
        attendeeId: id.action,
        role: id.roleId,
        participantId: id.participantId,
      });
    } else {
      newSelected.splice(selectedIndex, 1);
      const attendeIndex = newSelectedAttende.findIndex(
        (item) => item.attendeeId === id.action && item.role === id.roleId
      );
      if (attendeIndex !== -1) {
        setIsChecked(false);
        newSelectedAttende.splice(attendeIndex, 1);
      }
    }

    setSelected(newSelected);
    setSelectedAttende(newSelectedAttende);
  };

  //columns array
  const columns = [
    {
      id: "bulkDelete",
      label: "",
      callBackFunc: handleCheckboxChange,
      checked: selected,
      width: 5,
    },
    { id: "name", label: "Name", width: 20 },
    { id: "email", label: "Email", width: 30 },
    { id: "company", label: "Company", width: 15 },
    { id: "type", label: "Type", width: 15 },
    { id: "count", label: "Purchased ticket count", width: 20 },
    { id: "isManuallyAdded", label: "Manually import", width: 15 },
    { id: "action", label: "Actions", width: 10 },
  ];

  //called when select all attendees
  const handleSelectAll = (isSelected) => {
    const selectedRows = rows.filter((row) => row.type !== "Speaker");
    console.log(selectedRows, "selectedRows");

    setIsChecked(isSelected);
    if (isSelected) {
      const allRowIds = selectedRows.map((row) => {
        return {
          action: row.action,
          roleId: row.roleId,
          participantId: row.participantId,
        };
      });
      const allRow = selectedRows.map((row) => {
        return {
          attendeeId: row.action,
          role: row.roleId,
          participantId: row.participantId,
        };
      });
      setSelected(allRowIds);
      setSelectedAttende(allRow);
    } else {
      setSelected([]);
      setSelectedAttende([]);
    }
  };

  //zoom image
  const handleZoomImage = (e) => {
    setIsZoomImg(true);
    setZoomImg(e.target.src);
  };

  const deleteAttendeeName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].name;
      }
    }
  };

  //closing purchased ticket popup while pressing esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && showPurchaseTicketModal) {
        handlePurchaseTicketCloseModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showPurchaseTicketModal, handlePurchaseTicketCloseModal]);
  return (
    <>
      <div className="cstm-srd flex-wrap">
        <SearchAndSorting
          currentArray={allEvents}
          createObjectFromArray={createObjectFromArray}
          sortingOn={"name"}
          searchOn={["name", "email"]}
          placeholderText={"Search Attendee"}
          displayFilter={true}
          setFilterType={setFilterType}
          filterType={filterType}
          handleTypeFilter={handleTypeFilter}
          searchInputId="searchInputAttendees"
          sortingPatternId="sortingPatternAttendees"
          tabInformation={tabInformation}
          recallApi={getAttendeesByEventIdApi}
          clearSearch={clearSearch}
          setClearSearch={setClearSearch}
          searchedText={searchedText}
          setSearchedText={setSearchedText}
          setAgainLoad={setAgainLoad}
          againLoad={againLoad}
        >
          <div className="flex-grow-1">
            <select onChange={(e) => handleImportType(e.target.value)}>
              <option value={""}>Select import type</option>
              <option value={true}>Manual</option>
              <option value={false}>AirTable Synced</option>
            </select>
          </div>
        </SearchAndSorting>
      </div>

      <div className="cstm-btm-at mt-4">
        <div className="d-flex gap-xl-4 gap-2 flex-wrap">
          <ImportQuestions
            setAlertMsg={setAlertMsg}
            setshowAlert={setshowAlert}
          />
          <button
            data-toggle={selected.length > 0 ? "modal" : ""}
            // data-target="#DeletemodalId"
            className="btn"
            disabled={rows.length === 0 || selected.length === 0}
            onClick={() => {
              if (selected.length > 0) {
                setDeleteAll(true);
              }
            }}
          >
            Delete
          </button>
          <button
            data-toggle={selected.length > 0 ? "modal" : ""}
            className="btn"
            disabled={rows.length === 0 || selected.length === 0}
            onClick={() => {
              if (selected.length > 0) {
                setResendAll(true);
              }
            }}
          >
            Resend Ticket
          </button>
          <button className="btn" onClick={handleExport}>
            Export
          </button>
          <div className="">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <span className="import text d-flex align-items-center">
                Import
              </span>
            </div>
            {importLoader && (
              <div className="import-loading-image">
                <span className="cstm-pli-bg-importfile">
                  <img src={Loader} />
                </span>
              </div>
            )}
          </div>
          <div className="">
            <button
              className="btn"
              data-toggle="modal"
              data-target="#AddAttendee"
              onClick={(e) => {
                handleCloseAddModel(e);
                // setClearSearch(true)
                // getAttendeesByEventIdApi();
              }}
            >
              Create Attendee
            </button>
          </div>
          <div className="">
            <button
              className="btn"
              data-toggle="modal"
              data-target="#existingAttendee"
            >
              Add Existing Attendees
            </button>
          </div>
        </div>
      </div>

      {showAlert && <div className="alert alert-info">{alertMsg}</div>}
      <Paper className="uic-tb-mn">
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer className="uic-table-main">
            <Table className="uic-table">
              <TableHead className="uic-table-th">
                <TableRow>
                  {rows.length > 0 &&
                  (filterType === "all" ||
                    (filterType !== "all" &&
                      validUserType.filter(
                        (t) =>
                          t._id.toString() === filterType.toString() &&
                          t.role.toLowerCase() !== "partner"
                      ).length > 0)) ? (
                    <TableCell className="uic-th-td w-3">
                      <Checkbox
                        className="cstm-ls-checkbox"
                        color="primary"
                        checked={isChecked}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                      />
                    </TableCell>
                  ) : (
                    <TableCell className="uic-th-td w-3"></TableCell>
                  )}

                  {columns.slice(1).map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={`uic-th-td w-${column.width}`}
                    >
                      {!["action"].includes(column.id) &&
                      (filterType === "all" ||
                        (filterType !== "all" &&
                          validUserType.filter(
                            (t) =>
                              t._id.toString() === filterType.toString() &&
                              t.role.toLowerCase() !== "partner"
                          ).length > 0)) ? (
                        <>
                          <span className="custom-sorting">
                            <i
                              className="fa fa-angle-down"
                              onClick={(e) => {
                                handleSort(column.id, "Asc");
                              }}
                            ></i>
                            <i
                              className="fa fa-angle-up"
                              onClick={(e) => {
                                handleSort(column.id, "Dec");
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loader ? (
                <div className="cstm-data-load">
                  <img src={Loader} />{" "}
                </div>
              ) : rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).length > 0 ? (
                filterType === "all" ||
                (filterType !== "all" &&
                  validUserType.filter(
                    (t) =>
                      t._id.toString() === filterType.toString() &&
                      t.role.toLowerCase() !== "partner"
                  ).length > 0) ? (
                  <TableBodyComponent
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    columns={columns}
                    editCallBack={(value, row) => handleEdit(value, row.roleId)}
                    setDeleteId={(value, row) => {
                      setSelectedAttende([
                        { attendeeId: row.action, role: row.roleId },
                      ]);
                      setShowDeleteModal(true);
                      setDeleteId(row.action);
                      setSelectedRole(row.roleId);
                    }}
                    purchaseTicketCallBack={(userId, participantId) => {
                      setLoader(true);
                      purchaseTicketAttendeesApi(userId, participantId);
                      setShowPurchaseTicketModal(true);
                    }}
                    deleteModalId=""
                    editModalId="editAttendee"
                    setSelectAllChecked={setSelectAllChecked}
                    selectAllChecked={selectAllChecked}
                    selected={selected}
                    selectedAttende={selectedAttende}
                    tabInformation={tabInformation}
                  />
                ) : (
                  validUserType.filter(
                    (t) =>
                      t._id.toString() === filterType.toString() &&
                      t.role.toLowerCase() === "partner"
                  ).length > 0 && (
                    <Droppable droppableId="table">
                      {(provided) => (
                        <TableBody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="uic-table-td"
                        >
                          {rows &&
                            rows.length > 0 &&
                            rows.map((row, index) => (
                              <Draggable
                                key={row.action}
                                draggableId={row.action}
                                index={index}
                              >
                                {(provided) => (
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="uic-th-td w-20"
                                  >
                                    {columns.map((column) => {
                                      const value = row[column.id];
                                      return column.id === "action" ? (
                                        <TableCell
                                          key={column.id}
                                          className={`uic-th-td w-${column.width} uic-table-icon`}
                                        >
                                          <a
                                            data-toggle="modal"
                                            data-target="#editAttendee"
                                            onClick={() => {
                                              handleEdit(value, row.roleId);
                                            }}
                                          >
                                            <img src={editIcon} />
                                          </a>
                                          <a
                                            data-toggle="modal"
                                            data-target="#deleteModal"
                                            onClick={() => {
                                              setSelectedAttende([
                                                {
                                                  attendeeId: row.action,
                                                  role: row.roleId,
                                                },
                                              ]);
                                              setShowDeleteModal(true);
                                              setDeleteId(row.action);
                                              setSelectedRole(row["type"]);
                                            }}
                                          >
                                            <img src={imgDelete} />
                                          </a>
                                        </TableCell>
                                      ) : column.id === "bulkDelete" ? (
                                        <TableCell
                                          key={column.id}
                                          className={`uic-th-td w-${column.width}`}
                                        >
                                          <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                        </TableCell>
                                      ) : column.id === "name" ? (
                                        <TableCell
                                          className={`uic-th-td w-${column.width}`}
                                        >
                                          <div className="cstm-lg-ttl">
                                            {row.img !== undefined &&
                                            row.img !== null &&
                                            row.img !== "" ? (
                                              <img
                                                className="cstm-url-link"
                                                src={row.img}
                                                alt="image"
                                                onClick={handleZoomImage}
                                              />
                                            ) : (
                                              <div className="cstm-partner-avar">
                                                {row[column.id]
                                                  ? row[column.id].charAt(0)
                                                  : ""}
                                              </div>
                                            )}
                                            <a
                                              data-toggle="modal"
                                              data-target="#editAttendee"
                                              onClick={() => {
                                                handleEdit(
                                                  row["action"],
                                                  row.roleId
                                                );
                                              }}
                                              className="cstm-view-users-count"
                                            >
                                              {value}
                                            </a>{" "}
                                          </div>
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          key={column.id}
                                          className={`uic-th-td w-${column.width}`}
                                        >
                                          {value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  )
                )
              ) : (
                <span className="no-data-found">No data found</span>
              )}
            </Table>
          </TableContainer>
        </DragDropContext>
        {(filterType === "all" ||
          (filterType !== "all" &&
            validUserType.filter(
              (t) =>
                t._id.toString() === filterType.toString() &&
                t.role.toLowerCase() !== "partner"
            ).length > 0)) && (
          <TablePagination
            className="uic-pagination"
            rowsPerPageOptions={[20, 50, 100, 200]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {/* create modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="AddAttendee"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Create Attendee</h2>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#AddAttendee").modal("hide");
                      handleCloseAddModel(e);
                    }
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}

                <div className="form-group">
                  <div className="form-group-ct">
                    <ProfileIcon
                      image={addEvent.type_icon}
                      setImage={(img) =>
                        setAddEvent({ ...addEvent, type_icon: img })
                      }
                      title={`${
                        addEvent.type !== ""
                          ? validUserType.filter(
                              (t) =>
                                t._id.toString() === addEvent.type.toString()
                            )[0].role
                          : "Profile"
                      } Picture`}
                      requiredImageAspectRatio={requiredImageAspectRatio}
                    />
                    <span className="error">{addErr.type_icon}</span>
                  </div>
                </div>
                {/* <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleAddChange}
                      value={addEvent.name}
                    />
                    <span className="error">{addErr.name}</span>
                  </div>
                </div> */}
                <div className="form-group">
                  <div className="form-group-label">First Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter first name"
                      name="firstName"
                      onChange={handleAddChange}
                      value={addEvent.firstName}
                    />
                    <span className="error">{addErr.firstName}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Last Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter last name"
                      name="lastName"
                      onChange={handleAddChange}
                      value={addEvent.lastName}
                    />
                    <span className="error">{addErr.lastName}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Display Name</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter display name"
                      name="displayName"
                      onChange={handleAddChange}
                      value={addEvent.displayName}
                    />
                    <span className="error m-0 position-relative">
                      {addErr.displayName}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Email*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter email"
                      name="email"
                      onChange={handleAddChange}
                      value={addEvent.email}
                    />
                    <span className="error">{addErr.email}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Passcode</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter passcode"
                      name="passcode"
                      onChange={handleAddChange}
                      value={addEvent.passcode}
                    />
                  </div>
                  <span className="error">{addErr.passcode}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Company</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter company"
                      name="company"
                      onChange={handleAddChange}
                      value={addEvent.company}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Profession</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter profession"
                      name="profession"
                      onChange={handleAddChange}
                      value={addEvent.profession}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Phone no.</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter phone no."
                      name="phone"
                      onChange={handleAddChange}
                      value={addEvent.phone}
                    />
                    <span className="error">{addErr.phone}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Type*</div>
                  <div className="form-group-ct">
                    <select
                      value={addEvent.type}
                      onChange={handleAddChange}
                      name="type"
                    >
                      <option value={""}>Select type</option>
                      {validUserType.map((t) => {
                        return <option value={t._id}>{t.role}</option>;
                      })}
                    </select>
                    <span className="error">{addErr.type}</span>
                  </div>
                </div>

                {validUserType &&
                  validUserType.filter(
                    (t) =>
                      t._id === addEvent.type &&
                      t.role.toLowerCase() === "partner"
                  ).length > 0 && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Contact name</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter contact name"
                            name="contactPartnerName"
                            onChange={handleAddChange}
                            value={addEvent.contactPartnerName}
                          />
                        </div>
                      </div>
                      {/* Description field */}
                      <div className="form-group">
                        <div className="form-group-label">Description</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <TinyEditor
                              valueRef={descriptionRef}
                              value={editEvent.description}
                              handleEditorChange={(value, editor) => {
                                setAddErr({ ...addErr, description: "" });
                              }}
                              handleKeyPress={() => {
                                dispatch({ type: "SETMODALINPROCESS" });
                              }}
                            />
                          </div>
                          <span className="error">{addErr.description}</span>
                        </div>
                      </div>
                      {/* Offer field */}
                      <div className="form-group">
                        <div className="form-group-label">Offer</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <TinyEditor
                              valueRef={offerRef}
                              value={editEvent.offer}
                              handleEditorChange={(value, editor) => {
                                setAddErr({ ...addErr, offer: "" });
                              }}
                              handleKeyPress={() => {
                                dispatch({ type: "SETMODALINPROCESS" });
                              }}
                            />
                          </div>
                          <span className="error">{addErr.offer}</span>
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <div className="form-group-label">Description</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <HtmlEditor
                              editorState={editorStateDescCreateModal}
                              setEditorState={setEditorStateDescCreateModal}
                              addErr={addErr}
                              setAddErr={setAddErr}
                              fieldName={"description"}
                            />
                          </div>
                          <span className="error">{addErr.description}</span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Offer</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <HtmlEditor
                              editorState={editorStateOfferCreateModal}
                              setEditorState={setEditorStateOfferCreateModal}
                              addErr={addErr}
                              setAddErr={setAddErr}
                              fieldName={"offer"}
                            />
                          </div>
                          <span className="error">{addErr.offer}</span>
                        </div>
                      </div> */}
                    </>
                  )}
                {validUserType &&
                  validUserType.filter(
                    (t) =>
                      t._id === addEvent.type &&
                      t.role.toLowerCase() === "member"
                  ).length > 0 && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Auth0</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter auth0 id"
                            name="auth0"
                            onChange={handleAddChange}
                            value={addEvent.auth0}
                          />
                        </div>
                      </div>
                    </>
                  )}
                <div className="form-group">
                  <div className="form-group-label">Facebook</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter facebook link"
                      name="facebook"
                      onChange={handleAddChange}
                      value={addEvent.facebook}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Linkedin</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter linkedin link"
                      name="linkedin"
                      onChange={handleAddChange}
                      value={addEvent.linkedin}
                    />
                  </div>
                </div>
              </div>

              <div className="edit-user-details-footer cstm-create-button">
                {addInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleAddSubmit}
                  >
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* existing attendees */}
      <div
        className="modal fade edit-user-details-popup"
        id="existingAttendee"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Add Existing Attendees</h2>
              <button
                type="button"
                className="close"
                // data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#existingAttendee").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {existingModalAlert && (
                <div className="alert alert-info">{existingModalAlert}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Type*</div>
                <div className="form-group-ct">
                  <select
                    value={existingAttendeeType}
                    onChange={(e) => {
                      dispatch({ type: "SETMODALINPROCESS" });
                      dispatch({ type: "SETSTAYONPAGE", payload: null });

                      setExistingAttendeeType(e.target.value);
                      if (e.target.value)
                        setExistingAttendeeErr({
                          ...existingAttendeeErr,
                          type: "",
                        });
                    }}
                    name="type"
                  >
                    <option value={""}>Select type</option>
                    {validUserType.map((t) => {
                      return <option value={t._id}>{t.role}</option>;
                    })}
                  </select>
                  <span className="error">{existingAttendeeErr.type}</span>
                </div>
              </div>
              <div className="form-group margin-bottom-0">
                <div className="form-group-ct">
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="cstm-channel-type-title">
                        Select attendees*
                      </div>
                      <div className="existing-attendee-search">
                        <input
                          type="text"
                          value={searchParticipents}
                          placeholder="Search Attendee"
                          onChange={(e) => {
                            dispatch({ type: "SETMODALINPROCESS" });
                            dispatch({ type: "SETSTAYONPAGE", payload: null });
                            setSearchParticipents(e.target.value);
                          }}
                        />
                      </div>
                      <div className="select-attendees-box">
                        {allAttendeesList
                          .filter((att) => {
                            if (att.name && att.email)
                              return (
                                att.name
                                  .toLowerCase()
                                  .includes(searchParticipents.toLowerCase()) ||
                                att.email
                                  .toLowerCase()
                                  .includes(searchParticipents.toLowerCase())
                              );
                          })
                          .map((attendee) => (
                            <label
                              className="select-participants-box-in"
                              key={attendee._id}
                              style={{
                                backgroundColor: selectedUsers.includes(
                                  attendee
                                )
                                  ? "#EBE8FA"
                                  : "",
                              }}
                            >
                              <div className="select-participants-box-in-name">
                                <p className="select-pt-title">
                                  {attendee.name ? attendee.name : "-"}
                                </p>
                                <p className="select-pt-email">
                                  {attendee?.email ? attendee.email : "-"}
                                </p>
                              </div>
                              <div className="select-participants-box-in-checkbox">
                                <input
                                  type="checkbox"
                                  checked={selectedUsers.includes(attendee)}
                                  onChange={() => {
                                    dispatch({ type: "SETMODALINPROCESS" });
                                    dispatch({
                                      type: "SETSTAYONPAGE",
                                      payload: null,
                                    });
                                    handleUserSelect(attendee);
                                  }}
                                />
                              </div>
                            </label>
                          ))}
                      </div>
                      <span className="error">
                        {existingAttendeeErr.selectedUsers}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-user-details-footer cstm-create-button">
              {existingModalLoader ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleAddExisting}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        deletionMessage={messageModalText}
        setDeletionMessage={setMessageModalText}
        failure={true}
        additionalDivArray={messageModalDiv}
      />
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editAttendee"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Attendee</h2>
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      navigate(`/events/detail?id=${eventId}&tab=Attendees`);
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editAttendee").modal("hide");
                      handleCloseAddModel(e);
                      navigate(`/events/detail?id=${eventId}&tab=Attendees`);
                    }
                  }}
                  id="editAttendeeModelId"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <ProfileIcon
                  image={editEvent.type_icon}
                  setImage={(img) =>
                    setEditEvent({ ...editEvent, type_icon: img })
                  }
                  title={`${
                    editEvent.type !== ""
                      ? validUserType.filter(
                          (t) => t._id.toString() === editEvent.type.toString()
                        )[0].role
                      : "Profile"
                  } Picture`}
                  requiredImageAspectRatio={requiredImageAspectRatio}
                />
                {/* <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editEvent.name}
                    />
                    <span className="error">{editErr.name}</span>
                  </div>
                </div> */}
                <div className="form-group">
                  <div className="form-group-label">First Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="firstName"
                      onChange={handleEditChange}
                      value={editEvent.firstName}
                    />
                    <span className="error">{editErr.firstName}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Last Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="lastName"
                      onChange={handleEditChange}
                      value={editEvent.lastName}
                    />
                    <span className="error">{editErr.lastName}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Display Name</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter display name"
                      name="displayName"
                      onChange={handleEditChange}
                      value={editEvent.displayName}
                    />
                    <span className="error m-0 position-relative">
                      {editErr.displayName}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Email*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter email"
                      name="email"
                      onChange={handleEditChange}
                      value={editEvent.email}
                      disabled
                    />
                    <span className="error">{editErr.email}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Passcode</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter passcode"
                      name="passcode"
                      onChange={handleEditChange}
                      value={editEvent.passcode}
                    />
                  </div>
                  <span className="error">{editErr.passcode}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Company</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter company"
                      name="company"
                      onChange={handleEditChange}
                      value={editEvent.company}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Profession</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter profession"
                      name="profession"
                      onChange={handleEditChange}
                      value={editEvent.profession}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Phone no.</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter phone no."
                      name="phone"
                      onChange={handleEditChange}
                      value={editEvent.phone}
                    />
                    <span className="error">{editErr.phone}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Type*</div>
                  <div className="form-group-ct">
                    <select
                      value={editEvent.type}
                      onChange={handleEditChange}
                      name="type"
                      disabled={!editEvent?.isManuallyAdded}
                    >
                      <option value={""}>Select type</option>
                      {validUserType.map((t) => {
                        return <option value={t._id}>{t.role}</option>;
                      })}
                    </select>
                    <span className="error">{editErr.type}</span>
                  </div>
                </div>
                {validUserType &&
                  validUserType.filter(
                    (t) =>
                      t._id === editEvent.type &&
                      t.role.toLowerCase() === "partner"
                  ).length > 0 && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Contact name</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter contact name"
                            name="contactPartnerName"
                            onChange={handleEditChange}
                            value={editEvent.contactPartnerName}
                          />
                        </div>
                      </div>
                      {/* Edit Description field */}
                      <div className="form-group">
                        <div className="form-group-label">Description</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <TinyEditor
                              valueRef={editDescriptionRef}
                              value={editEvent.description}
                              handleEditorChange={(value, editor) => {
                                setAddErr({ ...addErr, description: "" });
                              }}
                              handleKeyPress={() => {
                                dispatch({ type: "SETMODALINPROCESS" });
                              }}
                            />
                          </div>
                          <span className="error">{editErr.description}</span>
                        </div>
                      </div>
                      {/* Edit Offer field */}
                      <div className="form-group">
                        <div className="form-group-label">Offer</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <TinyEditor
                              valueRef={editOfferRef}
                              value={editEvent.offer}
                              handleEditorChange={(value, editor) => {
                                setAddErr({ ...addErr, offer: "" });
                              }}
                              handleKeyPress={() => {
                                dispatch({ type: "SETMODALINPROCESS" });
                              }}
                            />
                          </div>
                          <span className="error">{editErr.offer}</span>
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <div className="form-group-label">Description</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <HtmlEditor
                              editorState={editorStateDescEditModal}
                              setEditorState={setEditorStateDescEditModal}
                              addErr={editErr}
                              setAddErr={setEditErr}
                              fieldName={"description"}
                            />
                          </div>
                          <span className="error">{editErr.description}</span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Offer</div>
                        <div className="form-group-ct">
                          <div className="cstm-vd cstm-code-addt">
                            <HtmlEditor
                              editorState={editorStateOfferEditModal}
                              setEditorState={setEditorStateOfferEditModal}
                              addErr={editErr}
                              setAddErr={setEditErr}
                              fieldName={"offer"}
                            />
                          </div>
                          <span className="error">{editErr.offer}</span>
                        </div>
                      </div> */}
                    </>
                  )}
                {validUserType &&
                  validUserType.filter(
                    (t) =>
                      t._id === editEvent.type &&
                      t.role.toLowerCase() === "member"
                  ).length > 0 && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Auth0</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter auth0 id"
                            name="auth0"
                            disabled
                            readOnly={true}
                            onChange={handleEditChange}
                            value={editEvent.auth0}
                          />
                        </div>
                      </div>
                    </>
                  )}

                <div className="form-group">
                  <div className="form-group-label">Facebook</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter facebook link"
                      name="facebook"
                      onChange={handleEditChange}
                      value={editEvent.facebook}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Linkedin</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter linkedin link"
                      name="linkedin"
                      onChange={handleEditChange}
                      value={editEvent.linkedin}
                    />
                  </div>
                </div>
                {editEvent?.questionsList?.questions?.length > 0 && (
                  <List sx={{ marginLeft: "10px" }}>
                    <Typography variant="h4">Questions</Typography>
                    {editEvent?.questionsList?.questions?.map((item, index) => {
                      return (
                        <>
                          <ListItem
                            disableGutters={true}
                            dense={true}
                            key={index}
                          >
                            <ListItemText
                              primary={item?.question}
                              secondary={item?.answer}
                            />
                          </ListItem>
                        </>
                      );
                    })}
                  </List>
                )}
              </div>

              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      {/* {showDeleteModal && (
        <DeleteModalWithLoader
          message={"Are you sure you want to delete this attendees?"}
          handleDelete={handleDeletebutton}
          onClose={handleDeleteModalCloseEvent}
          loader={deleteLoader}
        />
      )} */}

      <ModalPopup
        onClose={handleDeleteModalCloseEvent}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteLoader ? "danger" : "secondary"}
        buttonText1={
          !deleteLoader ? (
            "Delete"
          ) : (
            <img
              src={Loader2}
              width={50}
              height={15}
              className="btn-loader-img-cover"
            />
          )
        }
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDeletebutton}
        headingLable={"Delete attendee"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteAttendeeName(deleteId)}
            "? This action cannot be undone.
          </p>
        </div>
      </ModalPopup>
      <ModalPopup
        onClose={handleDeleteModalCloseEvent}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteLoader ? "danger" : "secondary"}
        buttonText1={
          !deleteLoader ? (
            "Delete"
          ) : (
            <img
              src={Loader2}
              width={50}
              height={15}
              className="btn-loader-img-cover"
            />
          )
        }
        isOpen={deleteAll}
        className=""
        handleClick2ndButton={handleDeletebutton}
        headingLable={"Delete attendee"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this attendees? This action cannot
            be undone.
          </p>
        </div>
      </ModalPopup>

      <ModalPopup
        onClose={handleResendModalCloseEvent}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!resendLoader ? "primary" : "secondary"}
        buttonText1={
          !resendLoader ? (
            "Resend"
          ) : (
            <img
              src={Loader2}
              width={50}
              height={15}
              className="btn-loader-img-cover"
            />
          )
        }
        isOpen={resendAll}
        className=""
        handleClick2ndButton={handleResendButton}
        headingLable={"Resend ticket attendee"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>Are you sure you want to Resend Ticket?</p>
        </div>
      </ModalPopup>

      {isZoomImg && zoomImg !== null && (
        <ZoomImageModal
          zoomImg={zoomImg}
          setIsZoomImg={setIsZoomImg}
          title="Profile Image"
        />
      )}
      {/* show purchase ticket modal */}
      {showPurchaseTicketModal && (
        <PurchaseTicketCountModal
          setShowPurchaseTicketModal={setShowPurchaseTicketModal}
          purchasedTicketList={purchasedTicketList}
          loader={loader}
        />
      )}
      <AlertComponent />
    </>
  );
};
export default Attendees;
