import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import authServicePartner from '../../../../Services/auth.service.partner';
import Loader from "../../../../Images/loader.gif";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../../../Images/exportimg.svg"
import ExportSelect from './ExportSelect';
import moment from 'moment';

const ClaimModal = ({ claimModal, setClaimModal, claimId, filterType, fromDate, toDate }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const claimColumns = [
        { id: "name", label: "User Name" },
        { id: "claims", label: "Claims" },
        { id: "email", label: "Email" },
        { id: "lastDate", label: "Last View Date" },
    ];

    //function for claim users list  in state 
    function claimUsersCreateData(id, name, claims, email, lastDate) {
        return { id, name, claims, email, lastDate }
    }
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [loader, setLoader] = useState(false)
    const [claimData, setClaimData] = useState([])
    const [selected, setSelected] = useState([]);
    const [selectRow, setSelectRow] = useState([]);
    const [selectError, setSelectError] = useState('');
    const [error, setError] = useState(false);

    // first render when component is mount
    useEffect(() => {
        partnerListClaimCountApi()
    }, [])

    //Partner List ClaimCountApi function
    async function partnerListClaimCountApi() {
        setLoader(true)
        try {
            const partnerListClaimCountRes = await authServicePartner.whoClickGetDetails(`${claimId}?field=claim&dateFilterType=${filterType !== undefined ? filterType : ""}${filterType !== undefined && filterType === "custom" ? `&fromdate=${fromDate}&todate=${toDate}` : ""}`)
            if (partnerListClaimCountRes.data.status) {
                let data = partnerListClaimCountRes.data.data.userOfferViews
                if (data.length > 0) {
                    let arr = []
                    for (let i = 0; i < data.length; i++) {
                        arr[i] = claimUsersCreateData(
                            data[i]._id,
                            `${data[i].userData[0].display_name ? data[i].userData[0].display_name  : ""}`,
                            data[i].offerCount,
                            data[i].userData[0]["Preferred Email"],
                            data[i].lastOfferClickDate !== undefined ? moment(data[i].lastOfferClickDate).format("MMM DD, YYYY") : "-"
                        )
                    }
                    setClaimData(arr)
                }
                setLoader(false)
            } else {
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            return false
        }
    }

    //pagination for page change
    const handleChangeUserPage = (event, newPage) => {
        setPage(newPage);
    };

    //pagination for rows per page change
    const handleChangeUserRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Export for download xlsx file
    const handleExport = () => {
        const workbook_ = XLSX.utils.book_new(); // create new workbook
        if (selectRow.length > 0) {
            const modifiedClaimData = selectRow.map((item) => ({
                name: item.name,
                claims: item.claims,
                email: item.email,
                lastviewDate: item.lastDate
            }));
            const convertToexcel = XLSX.utils.json_to_sheet(modifiedClaimData); // convert modified JSON data to sheet

            XLSX.utils.book_append_sheet(workbook_, convertToexcel, "Sheet2"); // add the sheet to the workbook
            const downloadExcel = XLSX.write(workbook_, {
                bookType: "xlsx",
                type: "array",
            });
            const dataExcel = new Blob([downloadExcel], { type: fileType });

            FileSaver.saveAs(dataExcel, "partnerclaim.xlsx");
        } else {
            setError(true);
            setSelectError("Please select checkbox from column")
        }
    }

    return (
        <>
            <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
                <div className="desk-globle-model-box-inner">
                    <div className="modal-content">
                        <div class="cstm-replace-pp">
                            <div className="cstm-hdr">
                                <span class="cstm-rpp-close-btn" onClick={() => { setClaimModal(false); document.body.classList.remove("modal-open"); }}>×</span>
                                <div class="cstm-rpp-title">Partner Claim User List</div></div>
                            <div className="cstm-export-link">
                                <a onClick={handleExport}> <img src={exportImg} />Export</a>
                            </div>
                            {
                                error ? <div className="export-error">{selectError}</div> : ""
                            }
                            <div className="cstm-cstm-viewlist">

                                <Paper className="uic-tb-mn ">
                                    <TableContainer className=" uic-table-main ">
                                        <Table>
                                            <TableHead className="uic-table-th">
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            className="cstm-ls-checkbox"
                                                            color="primary"
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    const newSelecteds = claimData.map((row) => row.id);
                                                                    const newSelecteds1 = claimData.map((row) => row);
                                                                    setSelected(newSelecteds)
                                                                    setSelectRow(newSelecteds1)
                                                                    setSelectError("")
                                                                } else {
                                                                    setSelected([]);
                                                                    setSelectRow([]);
                                                                    setError(true);
                                                                    setSelectError("Please select checkbox from column")
                                                                }
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'select all desserts',
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {claimColumns.map((colmn) => (
                                                        <TableCell
                                                            key={colmn.id}
                                                            className="uic-th-td"
                                                        >{colmn.label}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="uic-table-td">
                                                {loader ?
                                                    <div className="cstm-data-load"><img src={Loader} /> </div>
                                                    :
                                                    claimData !== undefined && claimData.length > 0 ?
                                                        claimData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((claims, i) => {
                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={claims.id}
                                                                    >
                                                                        <TableCell padding="checkbox">
                                                                            <ExportSelect
                                                                                id={claims.id}
                                                                                row={claims}
                                                                                selected={selected}
                                                                                setSelected={setSelected}
                                                                                selectRow={selectRow}
                                                                                setSelectRow={setSelectRow}
                                                                                setSelectError={setSelectError}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className='uic-th-td'>{claims.name}</TableCell>
                                                                        <TableCell className='uic-th-td'>{claims.claims}</TableCell>
                                                                        <TableCell className='uic-th-td'>{claims.email}</TableCell>
                                                                        <TableCell className='uic-th-td'>{claims.lastDate}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }) :
                                                        <span className="no-data-found">No data found</span>
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className="uic-pagination"
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={claimData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangeUserPage}
                                        onRowsPerPageChange={handleChangeUserRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClaimModal