// MessageBarTextField.js
import React, { useState, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageBarTextField.css";
import "quill-mention";
import "react-quill/dist/quill.snow.css";

const MessageBarTextField = ({
  placeholder = "Type a message...",
  MentionClickHandle,
  msg,
  setMsg,
  hasMentionDropdown = false,
  onChange,
  handleEnter,
  suggestionList,
  mentionDropDownTopRight,
  defaultValue,
  className,
  editMessage,
  isemojiOpenSelect,
  setisemojiSelect,
  selectedMentionName,
  sendBtnClick,
  setSendBtnClick,
  setHasMentionDropdown,
  editMessageState,
  setEditMessageState,
  editorClear,
  setEditorClear,
  allUsersList,
  uploadErrFlag,
  handleCopyPasteImage,
}) => {
  const editor = useRef(null);
  const mentionSelectRef = useRef(null);
  const [UserEnterEvent, setUserEnterEvent] = useState(false);

  useEffect(() => {
    const atValues = allUsersList.map((user) => ({
      id: user._id,
      value:
        user?.first_name || user?.last_name
          ? `${user?.first_name} ${user?.last_name}`
          : "",
      profileImg:
        user?.auth0Id && user.auth0Id !== ""
          ? user.profileImg
          : user.attendeeDetail?.photo,
      onlineStatus: user.onlineStatus,
    }));
    if (editor.current) {
      const quill = new Quill(editor.current, {
        modules: {
          toolbar: false,
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: (event) => { },
              },
            },
          },
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            isolateCharacter: true,
            mentionDenotationChars: ["@"],
            positioningStrategy: "fixed",
            dataAttributes: [
              "id",
              "value",
              "denotationChar",
              "link",
              "target",
              "disabled",
              "color",
            ],
            source: async function (searchTerm, renderList, mentionChar) {
              let values;
              if (atValues.length > 0) {
                if (mentionChar === "@") {
                  values = atValues;
                }

                // if (searchTerm.length === 0) {
                //   renderList(values, searchTerm);
                // } else {
                //   const matches = [];
                //   for (let i = 0; i < values.length; i++)
                //     if (
                //       ~values[i].value
                //         .toLowerCase()
                //         .indexOf(searchTerm.toLowerCase())
                //     )
                //       matches.push(values[i]);
                //   renderList(matches, searchTerm);
                // }
                if (searchTerm.length === 0) {
                  renderList(values, searchTerm);
                } else {
                  const matches = [];
                  const exactMatches = [];
                  const mentionMatches = [];

                  // Loop through values to find matches
                  for (let i = 0; i < values.length; i++) {
                    const valueLowerCase = values[i].value.toLowerCase();
                    const searchTermLowerCase = searchTerm.toLowerCase();

                    // Check for exact match
                    if (valueLowerCase.startsWith(searchTermLowerCase)) {
                      exactMatches.push(values[i]);
                    }

                    // Check for mention match
                    else if (valueLowerCase.includes(searchTermLowerCase)) {
                      mentionMatches.push(values[i]);
                    }
                  }

                  // Push mention matches first
                  matches.push(
                    ...mentionMatches.filter(
                      (match) => !exactMatches.includes(match)
                    )
                  );

                  // Push exact matches to the top
                  matches.unshift(...exactMatches);

                  // Render the list
                  renderList(matches, searchTerm);
                }
              }
            },
            renderItem: (item) => {
              var mentionItem = document.createElement("div");
              mentionItem.className = "mention-user";
              mentionItem.innerHTML = item?.profileImg
                ? '<img src="' +
                item?.profileImg +
                '" class="profile-image" alt="" width="20px" height="20px">' +
                item?.value
                : "<span class='rounded-user-text'>" +
                item?.value?.charAt(0) +
                "</span>" +
                item.value;
              // mentionList.appendChild(mentionItem);
              return mentionItem;
            },
            mentionContainer: document.querySelector(".mention-container"),
            defaultMenuOrientation: top,
            onSelect: function (item, insertItem) {
              insertItem(item);
              mentionSelectRef.current = true;
              handleSelectionChange();
            },
            onOpen: function (data) {
              // mentionSelectRef.current = true;
            },
            onClose: function (data) {
              setTimeout(() => {
                mentionSelectRef.current = false;
              }, 200);
            },
          },
        },
        placeholder: "Type a message...",
        formats: ["mention"],
        sanitize: {
          // Specify the allowed HTML tags
          tags: ["a", "img", "br"],
          // Specify the allowed attributes for each tag
          attributes: {
            a: ["href", "target"],
            img: ["src", "alt"],
          },
        },
        theme: "snow",
        value: msg,
      });

      quill.on("text-change", (delta, oldDelta, source) => {
        const contents = quill.getContents();
        const textWithMentions = getTextWithMentions(contents);
        setMsg(textWithMentions);
        onChange(textWithMentions);
      });

      editor.current.quill = quill;
    }
  }, [allUsersList]);

  // // Function to handle paste event
  // const handlePaste = (event) => {
  //   const items = (event.clipboardData || event.originalEvent.clipboardData)
  //     .items;
  //   let containsImage = false; // Flag to track if pasted content contains an image
  //   for (const item of items) {
  //     if (item.type.indexOf("image") !== -1) {
  //       containsImage = true;
  //       // break; // Exit the loop if an image is found
  //     }
  //   }
  //   if (containsImage) {
  //     event.preventDefault(); // Prevent default paste behavior only for images
  //   }
  //   // Process pasted content (including images)
  //   for (const item of items) {
  //     if (item.type.indexOf("image") !== -1) {
  //       const blob = item.getAsFile();
  //       const imageUrl = URL.createObjectURL(blob);
  //       handleCopyPasteImage(imageUrl, blob); // Call function to handle the image data
  //     }
  //   }
  // };
  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    const pastedImages = []; // Array to store pasted image data
    let containsImage = false; // Flag to track if pasted content contains an image
    let pastedImageCount = 0;
    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        containsImage = true;
        pastedImageCount++;
        const blob = item.getAsFile();
        const imageUrl = URL.createObjectURL(blob);
        pastedImages.push({ imageUrl, blob }); // Push image data into the array
      }
    }
    if (containsImage) {
      event.preventDefault(); // Prevent default paste behavior only for images
    }
    // Process all pasted images together
    handleCopyPasteImage(pastedImages, pastedImageCount);
  };
  // Attach handlePaste function to paste event
  useEffect(() => {
    if (editor.current) {
      editor.current.addEventListener("paste", handlePaste);
    }
    return () => {
      if (editor.current) {
        editor.current.removeEventListener("paste", handlePaste);
      }
    };
  }, []);

  /// Add Focus in quill editor
  useEffect(() => {
    if (editor.current && editor.current.quill) {
      editor.current.quill.focus();
    }
  }, [allUsersList, editor.current]);

  // Function to get text with mentions
  const getTextWithMentions = (contents) => {
    let textWithMentions = "";
    contents.ops.forEach((op, index) => {
      if (op.insert) {
        if (typeof op.insert === "string") {
          // Trim leading and trailing newline characters
          const trimmedText = op.insert.replace(/^\n+|\n+$/g, "");

          textWithMentions += trimmedText;
        } else if (op.insert.mention) {
          // Handle mentions differently (preserve newlines)
          if (index > 0 && typeof contents.ops[index - 1].insert === "string" && contents.ops[index - 1].insert.endsWith('\n')) {
            textWithMentions += `\n @${op.insert.mention.id}`; // Add a space before the mention
          } else {
            textWithMentions += `@${op.insert.mention.id}`; // Add mention without space
          }
        }
      }
    });
    return textWithMentions;
  };

  //clear content of editor when send button click
  useEffect(() => {
    setMsg("");
    setUserEnterEvent(true);
    // Clear the editor content
    if (editor.current) {
      editor.current.quill.setText("");
      if (sendBtnClick) {
        setSendBtnClick(false);
      }
    }
  }, [sendBtnClick]);

  useEffect(() => {
    if (editor.current && editor.current.quill) {
      const quill = editor.current.quill;
      if (editMessageState && msg) {
        quill.deleteText(0, quill.getLength());
        // Replace mention IDs with names in the Delta ops
        const updatedDeltaOps = getDeltaWithNamesFromIdsEditMessage(
          msg,
          allUsersList
        );
        // Insert the updated Delta ops into the editor
        quill.updateContents(updatedDeltaOps);

        // Callback to ensure the content is updated before setting focus
        setTimeout(() => {
          const contentLength = quill.getLength();
          // Set the cursor position to the end of the editor
          quill.setSelection(contentLength);
          quill.focus();

          // Delay setting editMessageState to avoid re-rendering immediately
          setTimeout(() => {
            setEditMessageState(false);
          }, 0);
        }, 0);
      }
    }
  }, [editMessageState]);

  useEffect(() => {
    if (editor.current && isemojiOpenSelect && editor.current.quill) {
      const quill = editor.current.quill;
      quill.deleteText(0, quill.getLength());
      const UpdateDelta = getDeltaWithNamesFromIdsEmojiSelect(
        msg,
        allUsersList
      );
      quill.updateContents(UpdateDelta);
      handleSelectionChange();

      // quill.clipboard.dangerouslyPasteHTML(`<p>${msg}</p>`);
      // Optionally, set the cursor position
      quill.setSelection(quill.getLength());
      setisemojiSelect(false);
    }
  }, [isemojiOpenSelect]);

  const getDeltaWithNamesFromIdsEmojiSelect = (msg, allUsersList) => {
    const deltaOps = [];
    const segments = msg.split(" ");
    if (allUsersList) {
      segments.forEach((segment, index) => {
        if (segment.startsWith("@")) {
          const mentionId = segment.substring(1);
          const user = allUsersList.find((user) => user._id === mentionId);
          if (user) {
            // Push mention object with a space if not the first segment
            if (index !== 0) {
              deltaOps.push({ insert: " " });
            }
            deltaOps.push({
              insert: {
                mention: {
                  index: index * 2 - 1,
                  denotationChar: "@",
                  id: user._id,
                  value:
                    user?.first_name || user?.last_name
                      ? `${user?.first_name} ${user?.last_name}`
                      : "",
                },
              },
            });
          } else {
            deltaOps.push({ insert: `@${mentionId}` });
          }
        } else {
          if (index !== 0) {
            deltaOps.push({ insert: " " }); // Push space if not the first segment
          }
          deltaOps.push({ insert: segment });
        }
      });
    }
    return deltaOps;
  };

  const getDeltaWithNamesFromIdsEditMessage = (msg, allUsersList) => {
    const deltaOps = [];
    const segments = msg.split(" ");
    if (allUsersList) {
      segments.forEach((segment, index) => {
        if (segment.startsWith("@")) {
          const mentionId = segment.substring(1);
          const user = allUsersList.find((user) => user._id === mentionId);
          if (user) {
            deltaOps.push({
              insert: {
                mention: {
                  index: index * 2 - 1,
                  denotationChar: "@",
                  id: user._id,
                  value:
                    user?.first_name || user?.last_name
                      ? `${user?.first_name} ${user?.last_name}`
                      : "",
                },
              },
            });
            deltaOps.push({ insert: " " });
          } else {
            deltaOps.push({ insert: `@${mentionId}` });
          }
        } else {
          deltaOps.push({ insert: segment });
          if (segments) {
            deltaOps.push({ insert: " " });
          }
        }
      });
    }
    return deltaOps;
  };

  useEffect(() => {
    if (hasMentionDropdown) {
      addAtSymbol();
      handleSelectionChange();
      setHasMentionDropdown(false);
    }
  }, [hasMentionDropdown]);

  const addAtSymbol = async () => {
    const quill = editor.current.quill;
    if (!quill.hasFocus()) {
      quill.focus();
    }
    // Get the current selection range
    const range = quill.getSelection();
    if (range) {
      // Check if "@" is already present in the text
      const currentText = quill.getText();
      const atIndex = currentText.indexOf("@", range.index);

      if (atIndex !== -1) {
        // If "@" is already present, just insert "@" without any changes
        quill.getModule("mention").openMenu("@");
        quill.setSelection(range.index + 1);
      } else {
        if (range.index === 0 || currentText[range.index - 1] === " ") {
          quill.getModule("mention").openMenu("@");
          quill.setSelection(range.index + 1);
        } else {
          if (
            currentText[range.index - 1] !== " " &&
            currentText[range.index - 1] !== undefined
          ) {
            quill.insertText(range.index, " ");
          }
          quill.getModule("mention").openMenu("@");
          quill.setSelection(range.index + 2);
        }
      }
    }
  };

  const handleSelectionChange = (e) => {
    const mentionNodes = editor.current.querySelectorAll(".mention");
    if (mentionNodes.length > 0) {
      mentionNodes.forEach((mentionNode) => {
        mentionNode.classList.add("mention-highlight");
        mentionNode.style.color = "#007be5"; // Change the color to your desired color
      });
    }
  };

  const handleKeyUp = (e) => {
    if (editor.current) {
      handleSelectionChange(e);
    }
  };

  useEffect(() => {
    setMsg("");
    setUserEnterEvent(false);
  }, [UserEnterEvent, editorClear]);

  useEffect(() => {
    if (editor.current && editor.current.quill) {
      editor.current.quill.setText("");
      setEditorClear(false);
      editor.current.quill.focus();
    }
  }, [editorClear]);

  useEffect(() => {
    if (editor.current && editor.current.quill) {
      editor.current.quill.focus();
    }
  }, [uploadErrFlag]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (mentionSelectRef.current) {
        event.preventDefault();
        mentionSelectRef.current = false;
      } else {
        if (event.shiftKey) {
          event.preventDefault();
        } else {
          event.preventDefault(); // Prevents newline character in the text area
          setUserEnterEvent(true);
          handleEnter(event, true); // Call the handleEnter function
          // Clear the editor state after sending the message

          setMsg("");
          // Clear the editor content
          if (editor.current && editor.current.quill) {
            editor.current.quill.setText("");
          }
        }
      }
    }
  };

  return (
    <div className="messagebarText-sec">
      <div
        className={`messagebarText-container ${className}`}
        id="editor"
        ref={editor}
        onKeyUp={handleKeyUp}
        onFocus={handleSelectionChange} // Handle the selection change when the editor gets focus
        onBlur={handleSelectionChange}
        onKeyDown={handleKeyDown} // Ensure this line is present
        tabIndex={0}
      ></div>

      {/* {hasMentionDropdown && (
        <div
          className="hasMantionDropdown"
          style={
            mentionDropDownTopRight && mentionDropDownTopRight.top
              ? {
                  position: "absolute",
                  top: mentionDropDownTopRight.top,
                  left: mentionDropDownTopRight.right,
                }
              : {}
          }
        >
          <DropDownItem
            onSelect={MentionClickHandle}
            options={suggestionList}
            size="large"
            isIcon={true}
          />
        </div>
      )} */}
    </div>
  );
};

MessageBarTextField.propTypes = {
  placeholder: PropTypes.string,
  MentionClickHandle: PropTypes.func,
  filteredSuggestionList: PropTypes.array,
  msg: PropTypes.string,
  setMsg: PropTypes.func,
  className: PropTypes.string,
  hasMentionDropdown: PropTypes.bool,
  onChange: PropTypes.func,
  handleEnter: PropTypes.func,
  suggestionList: PropTypes.array,
  selectedNames: PropTypes.array,
  setSelectedNames: PropTypes.func,
};

export default MessageBarTextField;
