import { useEffect, useState } from "react";
import authService from "../../../Services/auth.service";
import { fetchmyaccount } from  "../../../Action"
import Dummy from "../../../Images/dummy.jpg";
import noMediaFound from "../../../Images/nomediafound.svg";
const RequestList = () => {
  const [requestlist, setrequestlist] = useState([]);
  const [dislist, setdislist] = useState([]);
  const [text, settext] = useState("");
  useEffect(() => {
    authService
      .getrequestslist()
      .then((res) => {
        if (res.data.success) {
          setrequestlist(res.data.data);
          setdislist(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const acceptrequest = (member_id) => {
    authService
      .acceptfollowreq(member_id)
      .then((res) => {
        if (res.data.status) {
          var arr = requestlist.filter((mem) => {
            if (mem.requester && mem.requester._id !== member_id) return mem;
          });
          var arr2 = dislist.filter((mem) => {
            if (mem.requester && mem.requester._id !== member_id) return mem;
          });
          setdislist(arr2);
          setrequestlist(arr);
          dispatch(fetchmyaccount());
        }
       
      })
      .catch((e) => {
        console.log(e);
      });
  };
  console.log(dislist,"displaylist")
  const rejectrequest = (member_id) => {
    authService
      .rejectrequest(member_id)
      .then((res) => {
        if (res.data.status) {
          var arr = requestlist.filter((mem) => {
            if (mem.requester && mem.requester._id !== member_id) return mem;
          });
          var arr1 = dislist.filter((mem) => {
            if (mem.requester && mem.requester._id !== member_id) return mem;
          });
          setrequestlist(arr);
          setdislist(arr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleSearch = (value) => {
    var arr = [];
    var n = 0;
    for (var i = 0; i < requestlist.length; i++) {
      if (
        requestlist[i].requester.otherdetail[
          process.env.REACT_APP_FIRSTNAME_DBID
        ] +
        " " +
        requestlist[i].requester.otherdetail[
          process.env.REACT_APP_LASTNAME_DBID
        ]
          .toLowerCase()
          .includes(value.toLowerCase())
      ) {
        arr[n] = requestlist[i];
        n++;
      }
    }
    setdislist(arr);
  };
  return (
    <>
      <div className="pro-followers-following-cont">
        <div className="pro-group-members">
          <div className="pro-group-members-title">Requests</div>
          <div className="pro-group-members-search">
            <input
              className="search-members-input"
              value={text}
              onChange={(e) => {
                settext(e.target.value);
                handleSearch(e.target.value);
              }}
              type="text"
              placeholder={"Search request"}
            />
          </div>
        </div>
        {requestlist.length < 1 ? (
          <div className="newsfeed-post cstm-news-no-post no-req-found">
            <img src={noMediaFound} alt="noMediaFound" />
            <span className="emptymsg">No Data Found</span>
          </div>

          
        ) : (
          <div className="pro-group-members-list-main">
            {dislist.length > 0 && dislist.map((member) => {
              return (
                <>
                  <div className="pro-group-members-list">
                    <div className="pro-group-members-list-left">
                      <div className="pro-group-members-list-left-img">
                        <img src={Dummy} />
                      </div>
                      <div className="pro-group-members-list-left-title">
                        <a>
                          {member.requester && member.requester.otherdetail[
                            process.env.REACT_APP_FIRSTNAME_DBID
                          ] +
                            " " +
                            member.requester.otherdetail[
                              process.env.REACT_APP_LASTNAME_DBID
                            ]}{" "}
                        </a>
                      </div>
                      {/* <div className="pro-group-members-list-left-file">Virtual assistant at Lorem Ipusm</div> */}
                    </div>
                    <div
                      className="pro-group-members-list-right"
                      onClick={() => {
                        acceptrequest(member.requester._id);
                      }}
                    >
                      <span className="follow-bf"> Accept </span>
                    </div>
                    <div
                      className="pro-group-members-list-right"
                      onClick={() => {
                        rejectrequest(member.requester._id);
                      }}
                    >
                      <span className="follow-bf"> Reject </span>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
export default RequestList;
