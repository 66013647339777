import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import InputEmoji from "react-input-emoji";
//import function from action file
import {
  fetchactivityfeelings,
  fetchpostbygroupid_admin,
  fetchsavedpost
} from "../../Action/index.js";
//import api file
import Services from "../../Services/auth.service";
//import header file
import authAdminHeader from "../../Services/auth.admin.header.js";
//import components
import UploadImages from "./UploadImages";
import UploadVideos from "./UploadVideos";
//import images
import File1 from "../../Images/image1.png";
import Backarrow from "../../Images/back-arrow.svg";
import Search from "../../Images/search.svg";
import Feelings from "../../Images/feelings.svg";
import Topics from "../../Images/topics.svg";
import Dotted from "../../Images/dotted.svg";
import Tagfrnd from "../../Images/user-add-line.svg";
import Barchart from "../../Images/bar-chart-fill.svg";
import Yesmsg from "../../Images/yes-msg.svg";
import Alert from "../../Images/alert.svg";
import Plusadd from "../../Images/plus-add.svg";
import Delete from "../../Images/delete.svg";
import fillheart from "../../Images/fillheart.svg";
import loading from "../../Images/loader.gif";
import replyimg from "../../Images/reply-line.svg";
import timeimg from "../../Images/time-line.svg";
import bigdelimg from "../../Images/BackendImages/delete-icon.svg";
import svdpostimg from "../../Images/savedpost.svg"
import DisplayPost from "./DisplayPost.js";
import EditPostImgView from "../UserComponents/FrontendPost/EditPostImgView.js";
import EditAllPix from "../UserComponents/FrontendPost/EditAllPix.js";
const AllPost = ({ posterr, setposterr, progbarVal, setprogbarVal, selectedGid }) => {
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API_URL;
  const formData = new FormData();
  const [usersvdpost, setsvdpost] = useState([])
  const USER_ID = localStorage.getItem("userid");
  const USER_NAME = localStorage.getItem("username");
  const loader = useSelector((state) => {
    return state.ReduLoader;
  });
  const [allpost,setallpost] = useState([])
  const feelings = useSelector((state) => {
    return state.ReduActivityFeeling;
  });
  const topics = useSelector((state) => {
    var arr = []
    for(var i = 0; i < state.ReduTopics.length ; i++)
    {
      if(state.ReduTopics[i].numberOfGroup.includes(selectedGid))
        arr[i] =  state.ReduTopics[i]
    }
    return arr;
  });
  const savedpost = useSelector((state) => {
    return state.ReduSavedPost
  })
  const [delpostid, setdelpostid] = useState("");
  const [editId, seteditId] = useState("");
  const [editPost, seteditPost] = useState({});
  const [savedImg, setsavedImg] = useState([]);
  const [savedVid, setsavedVid] = useState([]);
  const [removeImg, setremoveImg] = useState([]);
  const [removeVid, setremoveVid] = useState([]);
  const [addImg, setaddImg] = useState([]);
  const [addVid, setaddVid] = useState([]);
  const [alleditimgvids, setalleditimgvids] = useState({ imgs: [], vids: [] });
  const [showpoll, setshowpoll] = useState(false);
  const [option, setoption] = useState("");
  const [searchTopic, setsearchTopic] = useState("");
  const [err, setErr] = useState("");
  const [postpage, setpostpage] = useState(1);
  const [allimg, setallimg] = useState([]);
  const [postfilter, setpostfilter] = useState("post")
  const days = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    "No time limit",
  ];
  var cntcmt = 0;
  useEffect(()=>{
      dispatch(fetchsavedpost())
      dispatch(fetchactivityfeelings())
  },[])

  const handleSelectEdit = (postid) => {
    Services.getsinglepost_admin(postid)
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          var pollarr = [];
          if (data.pollChoices.length > 0) {
            pollarr = data.pollChoices.map((choice) => {
              return choice.value;
            });
          }
          seteditPost({ ...data, pollChoices: pollarr, topics: data.topics.map((t) => {return t._id; } ) , feelingsActivity: data.feelingsActivity !== undefined && data.feelingsActivity !== null ? data.feelingsActivity._id : ""  });
          var arr_imgs,
          arr_vids = [];
          if (data.images.length > 0) {
             arr_imgs = data.images.map((img) => {
              return process.env.REACT_APP_AWS_IMG_VID_PATH + img;
            });
            setsavedImg(arr_imgs);
          }
          if (data.videos.length > 0) {
             arr_vids = data.videos.map((video) => {
              return process.env.REACT_APP_AWS_IMG_VID_PATH + video;
            });
            setsavedVid(arr_vids);
          }
          setalleditimgvids({ imgs: [...arr_imgs], vids: [...arr_vids] });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const editremove = (id, type) => {
    if (type === "db") {
      var newarr = [...savedImg];
      newarr.splice(id, 1);
      setremoveImg([...removeImg, savedImg[id]]);
      setsavedImg(newarr);
    } else {
      var newarr = [...addImg];
      newarr.splice(id, 1);
      setaddImg(newarr);
    }
  };
  const editremovevideo = (id, type) => {
    if (type === "db") {
      var newarr = [...savedVid];
      newarr.splice(id, 1);
      setremoveVid([...removeVid, savedVid[id]]);
      setsavedVid(newarr);
    } else {
      var newarr = [...addVid];
      newarr.splice(id, 1);
      setaddVid(newarr);
    }
  };

 

  const handleChange = (e) => {
    setErr("");
    if(e.target.type === "checkbox")
      seteditPost({ ...editPost, [e.target.name]: e.target.checked });
    else  
    seteditPost({ ...editPost, [e.target.name]: e.target.value });
    
  };

  const changeFeeling = (id) => {
    setErr("");
    seteditPost({ ...editPost, feelingsActivity: id });
  };

  const handlePublish = () => {
    var empty = true;
    Object.keys(editPost).map(function (key) {
      if (editPost[key].length > 0) empty = false;
    });

    if (!empty) {
      formData.append("groupId" , selectedGid)
      if (
        editPost.pollChoices.length > 0 &&
        (editPost.description.length === 0 ||
          editPost.pollDuration.length === 0)
      ) {
        setposterr({
          status: false,
          msg: "Poll must have description and poll duration!",
        });
        setTimeout(() => {
          setposterr({ status: false, msg: "" });
        }, 3000);
        return;
      }
      if (editPost.topics !== undefined && editPost.topics.length > 0) {
        for (var i = 0; i < editPost.topics.length; i++) {
          formData.append(`topics[]`, editPost.topics[i]);
        }
      }
      else{
        setposterr({
          status: false,
          msg: "Select topics!",
        });
        setTimeout(() => {
          setposterr({ status: false, msg: "" });
        }, 3000);
        return;
      }
      formData.append("postedBy", USER_ID);
      if (removeImg.length > 0) {
        for (var i = 0; i < removeImg.length; i++) {
          formData.append(`removeImg[]`, removeImg[i]);
        }
      }

      if (removeVid.length > 0) {
        for (var i = 0; i < removeVid.length; i++) {
          formData.append(`removeVideo[]`, removeVid[i]);
        }
      }
      if (addImg.length > 0) {
        for (var i = 0; i < addImg.length; i++) {
          formData.append(`images`, addImg[i]);
        }
      }

      if (addVid.length > 0) {
        for (var i = 0; i < addVid.length; i++) {
          formData.append(`videos`, addVid[i]);
        }
      }
      formData.append("description", editPost.description);
      if (
        editPost.feelingsActivity !== undefined &&
        editPost.feelingsActivity.length > 0
      )
        formData.append("feelingsActivity", editPost.feelingsActivity);
      if (editPost.postStatus !== undefined && editPost.postStatus.length > 0)
        formData.append("postStatus", editPost.postStatus);
     
      if (
        editPost.pollChoices !== undefined &&
        editPost.pollChoices.length > 0
      ) {
        for (var i = 0; i < editPost.pollChoices.length; i++) {
          formData.append(`pollChoices[${i}][value]`, editPost.pollChoices[i]);
        }
      }
      if (
        editPost.pollDuration !== undefined &&
        editPost.pollDuration.length > 0
      )
        formData.append("pollDuration", editPost.pollDuration);
      editPost.pollChoices !== undefined && editPost.pollChoices.length > 0
        ? formData.append("postType", "Poll")
        : formData.append("postType", "Post");
      if(editPost.makeAnnouncement !== undefined)
        formData.append("makeAnnouncement", editPost.makeAnnouncement)
      if(editPost.hideFromFeed !== undefined)
        formData.append("hideFromFeed", editPost.hideFromFeed)
      formData.append("user_type", "adminuser");
      formData.append("postId", data.editid)
      var data = {
        editid: editId,
        data: formData,
      };

      axios
        .put(API_URL + "AS/post/edit", data.data, {
          headers: authAdminHeader(),
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setprogbarVal(percentCompleted + "%");
          },
        })
        .then((res) => {
          if (res.data.status) {
            setposterr({ status: false, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
            dispatch(fetchpostbygroupid_admin({groupid: selectedGid, page:1}))
          } else {
            setposterr({ status: true, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
          }
          setErr("");
          seteditPost({});
          setshowpoll(false);
          setaddImg([]);
          setaddVid([]);
          setremoveImg([]);
          setremoveVid([]);
          setsavedImg([]);
          setsavedVid([]);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setposterr({ status: false, msg: "Empty post can't be published!" });
      setTimeout(() => {
        setposterr({ status: false, msg: "" });
      }, 3000);
    }
  };

  
  
  const handleDeletePost = () => {
    Services.deletepost_admin({postId: delpostid, user_type: "admin"})
      .then((res) => {
        if (res.data.status) {
          setposterr({ status: false, msg: res.data.message });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
          dispatch(fetchpostbygroupid_admin({groupid: selectedGid, page:1}))
        } else {
          setposterr({ status: true, msg: res.data.message });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  
  const handleSave = (id) => {
    Services.savepost(id).then((res) => {
      dispatch(fetchsavedpost())
      if(usersvdpost.includes(id))
        setsvdpost(usersvdpost.filter((p)=>{if(p !== id) return }))
      else
        setsvdpost([...usersvdpost,id])
    }).catch((e) =>{
      console.log(e)
    })
  }

  return (
    <>
      <div className="newsfeed-main">
        <div className="newsfeed-title">Newsfeed</div>

        <div className="newsfeed-sort">
          <div className="newsfeed-sort-title">Filter :</div>
          <div className="newsfeed-sort-all">
            <span className="newsfeed-select" data-toggle="dropdown">
              {postfilter}
            </span>
            <div className="dropdown-menu posts-filter-dp">
              <li
                onClick={() => {
                  setpostfilter("post");
                }}
              >
                Posts
              </li>
              <li
                onClick={() => {
                  setpostfilter("annoucement");
                }}
              >
                Annoucements
              </li>
              <li
                onClick={() => {
                  setpostfilter("hidden");
                }}
              >
                Hide From Feed Posts
              </li>
            </div>
          </div>
        </div>
      </div>
      <div className="gi-group-posts-post">
        {posterr.msg.length > 0 && posterr.status === false ? (
          <div className="post-msg-all post-sucessfully">
            <img src={Yesmsg} />
            {posterr.msg}
            <button className="post-close-msg">✕</button>
          </div>
        ) : (
          ""
        )}

        {posterr.msg.length > 0 && posterr.status ? (
          <div className="post-msg-all post-alert">
            <img src={Alert} />
            {posterr.msg} <a> Try again</a>
            <button className="post-close-msg">✕</button>
          </div>
        ) : (
          ""
        )}
        {loader ? (
          <div className="loadind-post">
            <img src={loading} />
          </div>
        ) : (
          ""
        )}
        <DisplayPost
          allpost={allpost}
          topics={topics}
          setdelpostid={setdelpostid}
          seteditId={seteditId}
          handleSelectEdit={handleSelectEdit}
          setallimg={setallimg}
          posterr={posterr}
          setposterr={setposterr}
          progbarVal={progbarVal}
          selectedGid={selectedGid}
          cntcmt={cntcmt}
          postfilter={postfilter}
          setpostfilter={setpostfilter}
          postpage={postpage}
          setpostpage={setpostpage}
        />
        {/* Edit popup */}
        <div
          className="modal  admin-create-post fade create-popup-post"
          id="editpost"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="create-ps-title">
                <div className="create-ps-title-title">Edit Post</div>
                <div className="create-ps-title-close">
                  <button
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setErr("");
                      seteditPost({});
                      setshowpoll(false);
                      setaddImg([]);
                      setaddVid([]);
                      setremoveImg([]);
                      setremoveVid([]);
                      setsavedImg([]);
                      setsavedVid([]);
                    }}
                  >
                    ✕
                  </button>
                </div>
              </div>
              <div className="create-pp-post">
                <div className="create-pp-post-left">
                  <img className="cr-pp-img" src={File1} />{" "}
                  <span className="cr-pp-name">{USER_NAME}</span>
                </div>
                <div className="create-pp-post-right">
                  {editId.length > 0 ? (
                    <>
                      <div className="create-pp-post-cont">
                        <div className="create-pp-post-cont-text">
                          {editPost.feelingsActivity !== undefined &&
                          editPost.feelingsActivity.length > 0 ? (
                            <div className="feelings-emoji">
                              <div className="feelings-emoji-icon">
                                {editPost.feelingsActivity !== undefined &&
                                editPost.feelingsActivity.length > 0 > 0
                                  ? feelings.filter(
                                      (feelings) =>
                                        feelings._id ===
                                        editPost.feelingsActivity
                                    )[0].emoji +
                                    " " +
                                    feelings.filter(
                                      (feelings) =>
                                        feelings._id ===
                                        editPost.feelingsActivity
                                    )[0].feeling
                                  : ""}
                              </div>
                              <div
                                className="feelings-emoji-close"
                                onClick={() => {
                                  seteditPost({
                                    ...editPost,
                                    feelingsActivity: "",
                                  });
                                }}
                              >
                                ×
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <textarea
                            name="description"
                            onChange={handleChange}
                            value={editPost.description}
                            placeholder="What is happening?"
                          ></textarea>
                          <div className="create-topics-box">
                            {editPost.topics !== undefined &&
                            editPost.topics.length > 0
                              ? editPost.topics.map((topic) => {
                                  return (
                                    <span className="create-topics-box-id">
                                      {
                                        topics.filter(
                                          (tpc) => tpc._id === topic
                                        )[0].topic
                                      }
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div
                            style={
                              showpoll ||
                              (editPost.pollChoices !== undefined &&
                                editPost.pollChoices.length > 0)
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            <div className="add-poll-main">
                              <div className="add-poll-title">Add Poll</div>
                              <div className="add-poll-main-box">
                                <input
                                  type="text"
                                  name="option"
                                  onChange={(e) => {
                                    setoption(e.target.value);
                                  }}
                                  value={option}
                                />
                                <button
                                  className="plus-add-btn"
                                  onClick={(e) => {
                                    seteditPost({
                                      ...editPost,
                                      pollChoices:
                                        option.length > 0
                                          ? [...editPost.pollChoices, option]
                                          : [...editPost.pollChoices],
                                    });
                                    setoption("");
                                  }}
                                >
                                  <img src={Plusadd} /> Add
                                </button>
                              </div>

                              {editPost.pollChoices !== undefined &&
                              editPost.pollChoices.length > 0
                                ? editPost.pollChoices.map((opt) => {
                                    return (
                                      <>
                                        <div className="add-poll-main-box2">
                                          <input
                                            type="text"
                                            value={opt}
                                            disabled
                                          />
                                          <button
                                            className="addpoll-delete"
                                            onClick={() => {
                                              seteditPost({
                                                ...editPost,
                                                pollChoices:
                                                  editPost.pollChoices.filter(
                                                    (option) => option !== opt
                                                  ),
                                              });
                                            }}
                                          >
                                            <img src={Delete} />
                                          </button>
                                        </div>
                                      </>
                                    );
                                  })
                                : ""}
                            </div>
                            <div
                              style={
                                editPost.pollChoices !== undefined &&
                                editPost.pollChoices.length > 0
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <label>Poll Duration</label>
                              <select
                                name="pollDuration"
                                onChange={handleChange}
                                value={editPost.pollDuration}
                              >
                                <option value=""> Select Days </option>
                                {days.map((d) => {
                                  return (
                                    <option value={d}>
                                      {d}{" "}
                                      {d === 1
                                        ? "Day"
                                        : d !== "No time limit"
                                        ? "Days"
                                        : ""}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <EditPostImgView alleditimgvids={alleditimgvids} />
                      </div>
                      <div className="create-pp-post-btn">
                        <UploadImages
                          addImg={addImg}
                          setaddImg={setaddImg}
                          edit={true}
                          setErr={setErr}
                          showpoll={showpoll}
                          alleditimgvids={alleditimgvids}
                          setalleditimgvids={setalleditimgvids}
                        />
                        <UploadVideos
                          addVid={addVid}
                          setaddVid={setaddVid}
                          edit={true}
                          setErr={setErr}
                          showpoll={showpoll}
                          alleditimgvids={alleditimgvids}
                          setalleditimgvids={setalleditimgvids}
                        />
                        <span className="cp-btn">
                          <button
                            className="cp-btn-feelingn"
                            data-toggle="modal"
                            data-target="#editfeeling"
                          >
                            <img src={Feelings} />
                            Feelings / Activity
                          </button>
                        </span>
                        <span className="cp-btn">
                          <button
                            className="cp-btn-topics"
                            data-toggle="modal"
                            data-target="#adminedittopics"
                          >
                            <img src={Topics} />
                            Topics
                          </button>
                        </span>
                        <span className="cp-btn cstm-cp-dp">
                          <button className="cp-btn-dotted">
                            <img src={Dotted} />
                          </button>
                          <div className="cstm-cp">
                            <ul>
                              <li
                                onClick={() => {
                                  setshowpoll(true);
                                  setremoveImg(savedImg);
                                  setremoveVid(savedVid);
                                  setsavedImg([]);
                                  setsavedVid([]);
                                  addImg([]);
                                  addVid([]);
                                }}
                              >
                                <img src={Barchart} />
                                Create a Poll
                              </li>
                              <li>
                                <img src={Tagfrnd} />
                                Tag a Friend
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>

                      <div className="create-pp-post-public">
                        <div className="make-hide-ck">
                          <div className="make-hide-ck-in">
                            <input
                              type="checkbox"
                              name="makeAnnouncement"
                              onChange={handleChange}
                              checked={editPost.makeAnnouncement}
                            />
                            Make Annoucements
                          </div>
                          <div className="make-hide-ck-in">
                            <input
                              type="checkbox"
                              name="hideFromFeed"
                              onChange={handleChange}
                              checked={editPost.hideFromFeed}
                            />
                            Hide From Feed
                          </div>
                        </div>

                        <div className="create-pp-post-public-btn">
                          <button
                            className="btn"
                            onClick={handlePublish}
                            data-dismiss="modal"
                          >
                            Publish
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade create-popup-post"
          id="allimgs"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content">
              {allimg.length > 0 &&
                allimg.map((file, i) => (
                  <div className="list-group-item list-group-item-success">
                    {file.indexOf("image") !== -1 ? (
                      <img
                        src={process.env.REACT_APP_AWS_IMG_VID_PATH + file}
                      />
                    ) : (
                      <video
                        src={process.env.REACT_APP_AWS_IMG_VID_PATH + file}
                        style={{ height: "100px", width: "100px" }}
                        controls
                      ></video>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div
          className="modal fade msg-popup create-popup-post edit-img-popup-post"
          id="editallpix"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered edit-img-modal-dialog  "
            role="document"
          >
            <div className="modal-content">
              <EditAllPix
                savedImg={savedImg}
                savedVid={savedVid}
                addImg={addImg}
                addVid={addVid}
                editremove={editremove}
                editremovevideo={editremovevideo}
                setalleditimgvids={setalleditimgvids}
                alleditimgvids={alleditimgvids}
                setaddImg={setaddImg}
                setErr={setErr}
                showpoll={showpoll}
                setaddVid={setaddVid}
              />
            </div>
          </div>
        </div>
        <div
          className="modal fade msg-popup"
          id="deletepost"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
              <div className="msg-icon">
                <img src={bigdelimg} />
              </div>
              <h1>Are you sure want to delete post?</h1>
              <div className="row">
                <div className="col-6">
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={handleDeletePost}
                      data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </span>
                </div>
                <div className="col-6">
                  <span className="msg-btn">
                    <button type="button" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade create-popup-post"
          id="editfeeling"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="create-ps-title">
                <div className="create-ps-title-title">
                  <button className="back-btn-mn" data-dismiss="modal">
                    <img src={Backarrow} />
                  </button>{" "}
                  What are you feeling today?
                </div>
                <div className="create-ps-title-close">
                  <button className="close" data-dismiss="modal">
                    ✕
                  </button>
                </div>
              </div>
              <div className="what-are-you-feeling">
                {feelings.map((feeling) => {
                  return (
                    <span
                      className="wayf-main"
                      onClick={() => {
                        changeFeeling(feeling._id);
                      }}
                    >
                      {feeling.emoji}
                      {feeling.feeling}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade create-popup-post"
          id="adminedittopics"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="create-ps-title">
                <div className="create-ps-title-title">
                  <button className="back-btn-mn" data-dismiss="modal">
                    <img src={Backarrow} />
                  </button>
                  Topics
                </div>
                <div className="create-ps-title-close">
                  <button className="close" data-dismiss="modal">
                    ✕
                  </button>
                </div>
              </div>
              <div className="topics-search-bar">
                <input
                  type="text"
                  name="searchtopic"
                  onChange={(e) => {
                    setsearchTopic(e.target.value);
                  }}
                  value={searchTopic}
                />
                <img src={Search} />
              </div>
              <div className="topics-search-label">
                {editPost.topics !== undefined &&
                  topics !== undefined &&
                  editPost.topics.map((topic, index) => {
                    return (
                      <>
                        <span className="topics-search-label-in">
                          {topics.filter((tpc) => tpc._id === topic)[0] !==
                          undefined
                            ? topics.filter((tpc) => tpc._id === topic)[0].topic
                            : ""}
                          <button
                            onClick={() => {
                              seteditPost({
                                ...editPost,
                                topics: editPost.topics.filter(
                                  (tpc, inx) => inx !== index
                                ),
                              });
                            }}
                          >
                            ✕
                          </button>
                        </span>
                      </>
                    );
                  })}
              </div>
              {
                <div className="topics-search-ovr">
                  {topics
                    .filter((topics) => topics.topic.includes(searchTopic))
                    .map((filterdtopic) => {
                      return (
                        <div
                          className="topics-search-ovr-in"
                          onClick={() => {
                            seteditPost({
                              ...editPost,
                              topics: [...editPost.topics, filterdtopic._id],
                            });
                          }}
                        >
                          <span className="tso-id"> {filterdtopic.topic}</span>
                          <span className="tso-post">
                            {filterdtopic.totalPost} post
                          </span>
                        </div>
                      );
                    })}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllPost;
