import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchmemberprofile, fetchmyaccount } from "../../../Action";
import Services from "../../../Services/auth.service";
import Dummy from "../../../Images/dummy.jpg";
import Mapimg from "../../../Images/map.png";
import loading from "../../../Images/loader.gif";
import LoaderComponent from "../LoaderComponent";
const Members = ({ groupmembers, setselectedDiv, userIsMember }) => {
  const history = useNavigate();
  const [admin, setadmin] = useState({});
  const [othermembers, setothermembers] = useState([]);
  const [dismember, setdismember] = useState([]);
  const USERID = localStorage.getItem("userid");
  const [text, settext] = useState("");
  const [requestlist, setrequestlist] = useState([]);
  const [loader, setloader] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setloader(true);
    Services.getsentrequestlist()
      .then((res) => {
        if (res.data.success) {
          setrequestlist(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    setadmin(
      groupmembers.filter((gm) => {
        if (gm.userId !== null && gm.userId !== undefined && gm.user_type === "adminuser") return gm;
      })[0]
    );
    setothermembers(
      groupmembers.filter((gm) => {
        if (gm.userId !== null && gm.userId !== undefined && gm.user_type !== "adminuser") return gm;
      })
    );
    setdismember(
      groupmembers.filter((gm) => {
        if (gm.userId !== null && gm.userId !== undefined && gm.user_type !== "adminuser") return gm;
      })
    );
    setloader(false);
  }, [groupmembers]);

  const handleSearch = (value) => {
    var arr = [];
    var name = "";
    for (var i = 0; i < othermembers.length; i++) {
      name =
        othermembers[i].userId.otherdetail[
          process.env.REACT_APP_FIRSTNAME_DBID
        ] +
        " " +
        othermembers[i].userId.otherdetail[process.env.REACT_APP_LASTNAME_DBID];
      if (name.toLowerCase().includes(value.toLowerCase())) {
        arr.push(othermembers[i]);
      }
    }
    setdismember(arr);
  };
  const followrequest = (mem_id) => {
    Services.sendfollowrequest(mem_id)
      .then((res) => {
        if (res.data.status) {
          setrequestlist([
            ...requestlist,
            {
              _id: res.data.data._id,
              recipient: { _id: mem_id },
              requester: { _id: localStorage.getItem("userid") },
            },
          ]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const unfollowrequest = (mem_id) => {
    Services.sendunfollowrequest(mem_id)
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var arr2 = [];
          var n = 0;
          for (var i = 0; i < othermembers.length; i++) {
            if (othermembers[i].userId._id === mem_id) {
              arr[i] = {
                ...othermembers[i],
                userId: {
                  ...othermembers[i].userId,
                  followers: [othermembers[i].userId.followers.splice(USERID)],
                },
              };
            } else {
              arr[i] = { ...othermembers[i] };
            }
          }
          for (var i = 0; i < dismember.length; i++) {
            if (dismember[i].userId._id === mem_id) {
              arr2[i] = {
                ...dismember[i],
                userId: {
                  ...dismember[i].userId,
                  followers: [dismember[i].userId.followers.splice(USERID)],
                },
              };
            } else {
              arr2[i] = { ...dismember[i] };
            }
          }

          setdismember(arr2);
          setothermembers(arr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const cancelrequest = (mem_id) => {
    Services.cancelfollowrequest(mem_id).then((res) => {
      if (res.data.status) {
        var arr = [];
        var n = 0;
        for (var i = 0; i < requestlist.length; i++) {
          if (requestlist[i].recipient._id !== mem_id) {
            arr[n] = requestlist[i];
            n++;
          }
        }
        setrequestlist(arr);
      }
    });
  };
  return (
    <>
      <div className="pro-about">
        <div className="pro-group-members">
          <div className="pro-group-members-title">Group Members</div>
          <div className="pro-group-members-search">
            <input
              className="search-members-input"
              value={text}
              onChange={(e) => {
                settext(e.target.value);
                handleSearch(e.target.value);
              }}
              type="text"
              placeholder="Search members"
            />
          </div>
        </div>

        <div className="pro-map">
          <img src={Mapimg} />
        </div>

        <div className="cstm-grup-admin">
          <div className="pro-title">Admins & Moderators</div>
          {admin !== undefined &&
            admin !== null &&
            Object.keys(admin).length !== 0 && (
              <div className="pro-post-list-main">
                <div className="pro-post-list-main-in">
                  <div className="pro-post-list-main-in-admin">Admin</div>
                  <div className="pro-post-list-main-in-user">
                    <div className="pro-post-list-main-in-user-left">
                      <img src={Dummy} />
                    </div>
                    <div className="pro-post-list-main-in-user-right">
                      <span className="pro-post-user-name">
                        {admin.userId.first_name + " " + admin.userId.last_name}
                      </span>
                      <span className="pro-post-time-date">
                        Joined {moment(admin.updatedAt).fromNow()}
                      </span>
                    </div>
                  </div>
                  {/* <div className="pro-post-list-main-in-cont">Lorem Ipsum is simply dummy text of the printing and type setting industry.</div>
                        <div className="pro-post-list-main-in-hobbies">Hobbies</div>
                        <div className="pro-post-list-main-in-tag">
                           <span className="pro-post-blue">Health/ Fiteness</span>
                           <span className="pro-post-green">Music</span>
                        </div> */}
                </div>
              </div>
            )}
        </div>
        {dismember.length > 0 ? (
          <>
            <hr />
            <div className="pro-title">Group members</div>

            <div className="pro-post-list-main">
              {dismember.map((member) => {
                return (
                  <div className="pro-post-list-main-in">
                    {loader ? (
                      <div className="loadind-post">
                       <LoaderComponent/>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="pro-post-list-main-in-user">
                      <div className="pro-post-list-main-in-user-left">
                        <img src={Dummy} />
                      </div>
                      <div className="pro-post-list-main-in-user-right">
                        <span className="pro-post-user-name">
                          {member !== undefined &&
                          member.userId !== undefined &&
                          member.userId !== undefined
                            ? member.userId.otherdetail[
                                process.env.REACT_APP_FIRSTNAME_DBID
                              ] +
                              " " +
                              member.userId.otherdetail[
                                process.env.REACT_APP_LASTNAME_DBID
                              ]
                            : ""}
                        </span>
                        <span className="pro-post-time-date">
                          Joined {moment(member.updatedAt).fromNow()}
                        </span>
                      </div>
                    </div>
                    {userIsMember ?(
                      <div className="pro-post-list-main-in-link">
                        <div className="pro-post-list-main-in-link-view">
                            <button
                              onClick={() => {
                                if (
                                  member.userId._id ===
                                  localStorage.getItem("userid")
                                )
                                  dispatch(fetchmyaccount());
                                else {
                                  dispatch(
                                    fetchmemberprofile(member.userId._id)
                                  );
                                  history(`/settings/edit`);
                                }
                              }}
                            >
                              View Details
                            </button>
                        </div>

                        {member.userId._id !== USERID ? (
                          member.userId.followers !== undefined &&
                          member.userId.followers.length > 0 &&
                          member.userId.followers.includes(USERID) ? (
                            <div
                              className="pro-post-list-main-in-link-unfollow"
                              onClick={() => {
                                unfollowrequest(member.userId._id);
                              }}
                            >
                              Unfollow
                            </div>
                          ) : requestlist.length > 0 &&
                            requestlist.filter((mem) => {
                              if (mem.recipient._id === member.userId._id)
                                return mem;
                            }).length > 0 ? (
                            <div
                              className="pro-post-list-main-in-link-unfollow"
                              onClick={() => {
                                cancelrequest(member.userId._id);
                              }}
                            >
                              Requested
                            </div>
                          ) : (
                            <div
                              className="pro-post-list-main-in-link-unfollow"
                              onClick={() => {
                                followrequest(member.userId._id);
                              }}
                            >
                              Follow
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default Members;
