import File1 from "../../Images/app_logo.png";
import Photos from "../../Images/photos.svg";
import Videos from "../../Images/videos.svg";
import Feelings from "../../Images/feelings.svg";
import Dotted from "../../Images/dotted.svg";
import Tagfrnd from "../../Images/user-add-line.svg";
import { useEffect , useState} from "react";
const CreatePostPopupClick = () => {
    const [userpic, setuserpic] = useState('');
    useEffect(()=>{
      setuserpic(localStorage.getItem('userprofile'))
    },[localStorage.getItem('userprofile')])
    return (
        <div
        className="what-is-happening-main"
        data-toggle="modal"
        data-target="#addpost"
      >
        <div className="what-post">
          <div className="what-post-left">
            <img src = { userpic ? userpic : File1 } />
          </div>
          <div className="what-post-right">
            <div className="what-post-right-input">
              <input
                type="text"
                placeholder="what is happening?"
                data-toggle="modal"
                data-target="#addpost"
                value=""
              />
            </div>
            <div className="what-post-btn-all what-post-btn-all-desktop">
              <span className="cp-btn ">
                <button
                  className="cp-btn-photos"
                  data-toggle="modal"
                  data-target="#"
                >
                  <img src={Photos} />
                  <span>Photos</span>
                </button>
              </span>
              <span className="cp-btn">
                <button
                  className="cp-btn-videos"
                  data-toggle="modal"
                  data-target="#"
                >
                  <img src={Videos} />
                  <span>Videos</span>
                </button>
              </span>
              <span className="cp-btn">
                <button
                  className="cp-btn-feelingn"
                  data-toggle="modal"
                  data-target="#"
                >
                  <img src={Feelings} />
                  <span>Feelings</span>
                </button>
              </span>
              <span className="cp-btn">
                <button
                  className="cp-btn-topics"
                  data-toggle="modal"
                  data-target="#"
                >
                  <img src={Tagfrnd} />
                  <span>Tag a Friend</span>
                </button>
              </span>

              <span className="cp-btn cstm-cp-dp">
                <button className="cp-btn-dotted">
                  <img src={Dotted} />
                </button>
              </span>
            </div>
          </div>
        </div>

        <div className="what-post-btn-all what-post-btn-all-mobile">
          <span className="cp-btn photos">
            <button
              className="cp-btn-photos"
              data-toggle="modal"
              data-target="#"
            >
              <img src={Photos} />
              <span>Photos</span>
            </button>
          </span>
          <span className="cp-btn videos">
            <button
              className="cp-btn-videos"
              data-toggle="modal"
              data-target="#"
            >
              <img src={Videos} />
              <span>Videos</span>
            </button>
          </span>
          <span className="cp-btn feeling">
            <button
              className="cp-btn-feelingn"
              data-toggle="modal"
              data-target="#"
            >
              <img src={Feelings} />
              <span>Feelings</span>
            </button>
          </span>
          <span className="cp-btn topics">
            <button
              className="cp-btn-topics"
              data-toggle="modal"
              data-target="#"
            >
              <img src={Tagfrnd} />
              Tag a Friend
            </button>
          </span>
          <span className="cp-btn cstm-cp-dp">
            <button className="cp-btn-dotted">
              <img src={Dotted} />
            </button>
          </span>
        </div>
      </div>
    )
}

export default CreatePostPopupClick;