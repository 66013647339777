import React from "react";
import "./Styles/ChatDetailsMembers.css";
import PropTypes from "prop-types";
import "../../../Styles/Theme/Theme.css";
import "../../../Styles/frontendGlobal.css";
import SearchField from "../../../components/InputField/SearchField";
import Button from "../../../components/Button/Button";
import ArrowleftIcon from "../../../components/Icons/ArrowsIcon/ArrowleftIcon";
import Typography from "../../../components/Typography/Typography";
import ChatMemberCell from "./HelperComponets/ChatMemberCell";
const ChatDetailsMembers = ({
  isAdmin = false,
  placeholder = "Search for members",
  hasText = true,
  name = "Guy Hawkins",
  title = "Owner",
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  isOnline = "online",
  type = "userChatGroup",
  handleClick,
  groupMemberList,
  onClickBackMemberDetails,
  handleSearchClick,
  handleSearchClear,
  searchValue,
  onClickRemoveMember = () => { },
  chatAllUsers,
  disabledRemoveButton,
  setDisabledButton,
}) => {
  return (
    <>
      <div className="chat-details-members">
        <div className="chat-details-members-heading">
          <div className="chat-details-members-heading-title">
            <Button
              elementIcon={<ArrowleftIcon />}
              variant="ghost"
              hasText={false}
              size="small"
              handleClick={onClickBackMemberDetails}
              toolTipText="Back"
            />
            <Typography variant="h4">Members</Typography>
          </div>
          <div className="chat-details-members-heading-search ">
            <SearchField
              className="text-field-search"
              handleChange={handleSearchClick}
              helperText=""
              isRounded
              label="Label"
              name=""
              value={searchValue}
              placeholder={placeholder}
              handleClear={handleSearchClear}
            />
          </div>
        </div>
        <hr />
        <div className="chat-details-members-list scrollbar">
          {groupMemberList &&
            groupMemberList
              .sort((a, b) => {
                if (type === "userChatGroup") {
                  if (
                    a.user_type === "adminuser" &&
                    b.user_type !== "adminuser"
                  ) {
                    return -1;
                  } else if (
                    a.user_type !== "adminuser" &&
                    b.user_type === "adminuser"
                  ) {
                    return 1;
                  }
                }
                return 0;
              })
              .filter((data) => {
                if (type === "chatChannel") {
                  return data.user_type !== "adminuser";
                }
                return true;
              })
              .filter((data) => {
                const fullName =
                  type === "userChatGroup"
                    ? data?.user
                      ? data?.user?.first_name || data?.user?.last_name
                        ? `${data?.user?.first_name} ${data?.user?.last_name}`
                        : ""
                      : ""
                    : type === "chatChannel"
                      ? data?.first_name || data?.last_name
                        ? `${data?.first_name} ${data?.last_name}`
                        : ""
                      : "";
                return fullName
                  ?.toLowerCase()
                  .includes(searchValue.toLowerCase());
              })
              .map((data) => {
                let onlineStatus =
                  type === "chatChannel"
                    ? chatAllUsers &&
                    chatAllUsers?.filter(
                      (user) =>
                        (user?.id === data._id && user?.onlineStatus) ||
                        data._id === localStorage.getItem("userid")
                    ).length
                    : type === "userChatGroup"
                      ? chatAllUsers &&
                      chatAllUsers?.filter(
                        (user) =>
                          (user?.id === data.userId && user?.onlineStatus) ||
                          data.userId === localStorage.getItem("userid")
                      ).length
                      : "";
                return (
                  <>
                    <ChatMemberCell
                      handleClick={handleClick}
                      key={data._id}
                      hasText={data.user_type === "adminuser"}
                      badgeStatus={onlineStatus ? "online" : "offline"}
                      src={
                        type === "chatChannel"
                          ? data.profileImg
                          : type === "userChatGroup"
                            ? data.user.profileImg
                            : ""
                      }
                      name={
                        type === "userChatGroup"
                          ? data?.user
                            ? data?.user?.first_name || data?.user?.last_name
                              ? `${data?.user?.first_name} ${data?.user?.last_name}`
                              : ""
                            : ""
                          : type === "chatChannel"
                            ? data?.first_name || data?.last_name
                              ? `${data?.first_name} ${data?.last_name}`
                              : ""
                            : ""
                      }
                      title="Owner"
                      hasStatus={true}
                      hasRemoveMemberButton={
                        isAdmin && data.user_type !== "adminuser"
                      }
                      handleRemoveMemberButtonClick={() => {
                        onClickRemoveMember(data.userId);
                        setDisabledButton(data.userId);
                      }}
                      handleChatList={() => { }}
                      disabledRemoveButton={disabledRemoveButton}
                      data={data}
                    />
                  </>
                );
              })}
        </div>
      </div>
    </>
  );
};

ChatDetailsMembers.propTypes = {
  placeholder: PropTypes.string,
  hasText: PropTypes.bool,
  src: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  isOnline: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func,
  handleKeyUp: PropTypes.func,
  handleClick: PropTypes.func,
  handleSearchClick: PropTypes.func,
  onClickBackMemberDetails: PropTypes.func,
  groupMemberList: PropTypes.array,
};

export default ChatDetailsMembers;
