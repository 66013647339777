import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import Services from "../../../Services/auth.service";
import authServiceEvent from "../../../Services/auth.service.event";
import authHeaderChannel from "../../../Services/auth.header.channel";
import defaultTimezones from "../timeZone.json";
import Frame from "../../../Images/Frame.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import AlertComponent from "../../../Hooks/AlertComponent";
import Select from "react-select";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import undo from "../../../Images/undo.svg";
import CreateCategory from "../Modals/CreateCategory";
// import CroppingImageEventModule from "./CroppingImageEventModule";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import AddRules from "../../CommonRules/AddRules";
import { useUserCountMutation } from "../../../Services/ruleUserCount";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";

const EditEventInfo = ({ setApiCall }) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const longDescriptionRef = useRef(null);

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  //empty object states
  const [eventType, setEventType] = useState({
    value: "",
    label: "",
  });
  const [eventDetail, setEventDetail] = useState({
    title: "",
    status: "draft",
    shortDescription: "",
    longDescription: EditorState.createEmpty(),
    eventUrl: "",
    type: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    restrictedAccessGroups: [],
    restrictedAccessMemberships: [],
    thumbnail: "",
    preRegisterBtnLink: "",
    preRegisterBtnTitle: "",
    preRegisterDescription: "",
    preRegisterEndDate: "",
    preRegisterStartDate: "",
    preRegisterTitle: "",
    preRegisterStartTime: "",
    preRegisterEndTime: "",
    isPreRegister: false,
    address: "",
    city: "",
    country: "",
    latitude: "",
    longitude: "",
    postalcode: "",
    location: "",
    isLocation: false,
    airTableEventName: "",
    partnerType: {
      value: "",
      label: "",
    },
    tag: [],
    category: [],
    subCategory: [],
    locationType: "inPerson",
    onlineUrl: "",
    toBeAnnounceedDescription: "",
    autoTimezone: "",
  });
  const [eventDetailErr, setEventDetailErr] = useState({
    title: "",
    eventUrl: "",
    status: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    thumbnail: "",
    shortDescription: "",
    longDescription: "",
    type: "",
    preRegisterBtnLink: "",
    preRegisterBtnTitle: "",
    preRegisterDescription: "",
    preRegisterEndDate: "",
    preRegisterStartDate: "",
    preRegisterTitle: "",
    preRegisterStartTime: "",
    preRegisterEndTime: "",
    isPreRegister: "",
    address: "",
    city: "",
    country: "",
    latitude: "",
    longitude: "",
    postalcode: "",
    location: "",
    isLocation: "",
    airTableEventName: "",
    locationType: "",
    onlineUrl: "",
    toBeAnnounceedDescription: "",
  });

  const regex = /^[^0-9]+$/;
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [newThumbnail, setNewThumbnail] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [resetRulesFields, setResetRulesFields] = useState(false);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});
  const [ticketCount, setTicketCount] = useState(0);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [totalUserList, setTotalUserList] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [allGroupList, setAllGroupList] = useState([]);
  const [allMemberShipList, setAllMemberShipList] = useState([]);
  const [symbolCompulsory, setSymbolCompulsory] = useState("");
  const [preRegisterCompulsory, setPreRegisterCompulsory] = useState(false);
  const [address, setAddress] = useState("");
  const [showSuggestion, setshowSuggestion] = useState(false);
  const [placeId, setPlaceId] = useState("");
  const [loader, setLoader] = useState(false);
  const [symbolCompulsoryLocation, setSymbolCompulsoryLocation] = useState("");
  const [isLocationCompulsory, setLocationCompulsory] = useState(false);
  const [isTimezoneManually, setIsTimezoneManually] = useState(false);
  const [typesList, setTypeList] = useState([]);
  const [selectedEditEventTypeOption, setSelectedEditEventTypeOption] =
    useState(null);
  const [locationImageErr, setLocationImageErr] = useState("");
  const [imgFileList, setImgFileList] = useState([]);
  const [locationImageLoader, setLocationImageLoader] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [createInprocess, setcreateInprocess] = useState(false);
  const [showalertAdd, setshowalertAdd] = useState(false);
  const [alertmsgAdd, setalertmsgAdd] = useState("");
  const [badgeList, setBadgeList] = useState([]);
  const [addtag, setaddtag] = useState("");
  const [adderr, setadderr] = useState("");

  //  image cropping state
  const [modalOpen, setModalOpen] = useState(false);
  const [fileDimension, setFileDimension] = useState("");
  const [cropedFile, setcropedFile] = useState();
  const [cropedFileData, setcropedFileData] = useState();
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requiredImageSize, setRequiredImageSize] = useState({
    width: 1500,
    height: 846,
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    16 / 9
  );
  const [isCheckInAllowed, setIsCheckInAllowed] = useState(false);
  const latestRequest = useRef(0);

  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [nonUsers, setNonUsers] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState([]);
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  useEffect(() => {
    handleClearAllStates();

    Services.getallgroup()
      .then((res) => {
        if (res.data.status) {
          setAllGroupList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getPlanService()
      .then((res) => {
        if (res.data.status) {
          setAllMemberShipList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    getTagList();
    getCategoriesListApi();
    getChannelList();
  }, []);

  async function getTagList() {
    try {
      const tagListRes = await Services.getalltags_byadmin("");
      if (tagListRes.data.status) {
        setTagList(tagListRes.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  //Channel list api call
  async function getChannelList() {
    try {
      const channelListRes = await authHeaderChannel.getAllChannelList("");

      if (channelListRes.data.status) {
        const channelList = channelListRes.data.data;
        let arr = [
          {
            value: "",
            label: "Select channel",
          },
        ];
        if (channelList !== undefined && channelList.length > 0) {
          channelList.map((channel) => {
            arr.push({
              value: channel._id,
              label: channel.channelName,
            });
          });
          setBadgeList(arr);
        } else {
          setBadgeList([]);
        }
      }
    } catch (error) { }
  }

  const onhandleBadgeChange = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    // setPartnerDetailErr({ ...partnerDetailErr, partnerType: "" })
    setEventDetail({ ...eventDetail, partnerType: selectedOption });
  };

  const getCategoriesListApi = async () => {
    let query = "?";
    query += `page=${1}`;
    query += `&limit=${1000}`;

    try {
      const getCategoriesRes = await authServiceEvent.getCategoriesList(query);

      if (getCategoriesRes.data.status) {
        var data = getCategoriesRes.data.data.event;
        setCategoryData(data);
      } else {
      }
    } catch (error) { }
  };

  useEffect(() => {
    setLoader(true);
    authServiceEvent
      .getEventById(queryVariable)
      .then((response) => {
         if (response.data.status) {
          setSaveInProcess(false);
          response.data.data.eventUrl !== undefined &&
            response.data.data.eventUrl !== null &&
            response.data.data.eventUrl !== "" &&
            dispatch({
              type: "EVENTURL",
              payload: response.data.data.eventUrl,
            });
          if (response.data.data.isPreRegister) {
            setSymbolCompulsory("*");
            setPreRegisterCompulsory(true);
          }

          if (response.data.data.isLocation) {
            setLocationCompulsory(true);
            setSymbolCompulsoryLocation("*");
          }

          if (response.data.data.isTimeZoneAutomatic) {
            setIsTimezoneManually(false);
          } else {
            setIsTimezoneManually(true);
          }
          setTicketCount(
            response.data.data.mainTicketCount !== undefined ||
              response.data.data.mainTicketCount > 0
              ? response.data.data.mainTicketCount
              : 0
          );
          setcropedFile(response.data.data.thumbnail)
          setIsCheckInAllowed(response?.data?.data?.isCheckInAllow);
          setEventDetail({
            ...response.data.data,
            status: response.data.data.status,
            startDate:
              response.data.data.startDate !== "Invalid date"
                ? moment(response.data.data.startDate, "MM-DD-YYYY").format(
                  "YYYY-MM-DD"
                )
                : "",
            endDate:
              response.data.data.endDate !== "Invalid date"
                ? moment(response.data.data.endDate, "MM-DD-YYYY").format(
                  "YYYY-MM-DD"
                )
                : "",
            shortDescription:
              response.data.data.shortDescription !== undefined
                ? response.data.data.shortDescription
                : "",
            longDescription: response.data.data.longDescription,
            startTime:
              response.data.data.startTime !== "Invalid date"
                ? moment(response.data.data.startTime, "hh:mm A").format(
                  "HH:mm"
                )
                : "",
            endTime:
              response.data.data.endTime !== "Invalid date"
                ? moment(response.data.data.endTime, "hh:mm A").format("HH:mm")
                : "",
            timeZone: !response.data.data.isTimeZoneAutomatic
              ? response.data.data.timeZone
              : "",
            autoTimezone: response.data.data.isTimeZoneAutomatic
              ? response.data.data.timeZone
              : "",
            type:
              response.data.data.type &&
                response.data.data.type.typeId !== undefined &&
                response.data.data.type.typeId !== null
                ? response.data.data.type.typeId
                : "",
            partnerType: {
              value:
                response.data.data.chatChannel !== undefined &&
                  response.data.data.chatChannel !== null &&
                  response.data.data.chatChannel !== "" &&
                  response.data.data.chatChannel._id
                  ? response.data.data.chatChannel._id
                  : "",
              label:
                response.data.data.chatChannel !== undefined &&
                  response.data.data.chatChannel !== null &&
                  response.data.data.chatChannel !== ""
                  ? response.data.data.chatChannel.channelName
                  : "Select channel",
            },
            preRegisterTitle: response.data.data.isPreRegister
              ? response.data.data.preRegisterTitle
              : "",
            preRegisterDescription: response.data.data.isPreRegister
              ? response.data.data.preRegisterDescription
                .replace(`<div \"font-family: 'Muller';\">`, "")
                .replace(`</div>`, "")
              : "",
            preRegisterBtnLink: response.data.data.isPreRegister
              ? response.data.data.preRegisterBtnLink
              : "",
            preRegisterBtnTitle: response.data.data.isPreRegister
              ? response.data.data.preRegisterBtnTitle
              : "",
            preRegisterStartDate: response.data.data.isPreRegister
              ? moment(
                moment(response.data.data.preRegisterStartDate).utc(),
                "MM-DD-YYYY"
              ).format("YYYY-MM-DD")
              : "",
            preRegisterEndDate: response.data.data.isPreRegister
              ? moment(
                moment(response.data.data.preRegisterEndDate).utc(),
                "MM-DD-YYYY"
              ).format("YYYY-MM-DD")
              : "",
            preRegisterStartTime: response.data.data.isPreRegister
              ? moment(
                moment(response.data.data.preRegisterStartDate).utc(),
                "HH:mm"
              ).format("HH:mm")
              : "",
            preRegisterEndTime: response.data.data.isPreRegister
              ? moment(
                moment(response.data.data.preRegisterEndDate).utc(),
                "HH:mm"
              ).format("HH:mm")
              : "",
            isPreRegister: response.data.data.isPreRegister,
            isLocation: response.data.data.isLocation,
            location: response.data.data.location ?? "",
            address: response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.address
                ? response.data.data.location.address
                : ""
              : "",
            city: response.data.data.isLocation
              ? response.data.data.location && response.data.data.location.city
                ? response.data.data.location.city
                : ""
              : "",
            country: response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.country
                ? response.data.data.location.country
                : ""
              : "",
            latitude: response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.latitude
                ? response.data.data.location.latitude
                : ""
              : "",
            longitude: response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.longitude
                ? response.data.data.location.longitude
                : ""
              : "",
            postalcode: response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.postalcode
                ? response.data.data.location.postalcode
                : ""
              : "",
            airTableEventName: response.data.data.airTableEventName,
            category: response.data.data.category
              ? response.data.data.category
              : [],
            onlineUrl: response.data.data.onlineLocationDetail.onlineEventUrl,
            toBeAnnounceedDescription: response.data.data.toBeAnnouceDetail,
            subCategory: [],
            locationType:
              response.data.data.locationType === "virtual"
                ? "virtual"
                : response.data.data.locationType === "inPerson"
                  ? "inPerson"
                  : response.data.data.locationType,
          });
          setRulesDataForEdit({
            selectedUser: response?.data?.data?.restrictedAccessUserId,
            selectedGroups: response?.data?.data?.restrictedAccessGroupId,
            selectedTags: response?.data?.data?.restrictedAccessTagId,
            selectedMembership:
              response?.data?.data?.restrictedAccessMembershipPlanId,
            selectedUserAccess:
              response?.data?.data?.restrictionAccess === "public"
                ? "all"
                : response?.data?.data?.restrictionAccess,
          });
          setEmails(response?.data?.data?.restrictedAccessUserId?.map((user)=>user["Preferred Email"]));
          setUsers(response?.data?.data?.restrictedAccessUserId?.map((user)=>user["Preferred Email"]));
          setNonUsers([]);
          setInvalidEmail([]);
         if (
            response.data.data.subcategory &&
            response.data.data.subcategory.length > 0
          ) {
            setSubcategories(
              response.data.data.subcategory.map((subcat) => subcat._id)
            );
          }
          setImgFileList(
            response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.locationImages
                ? response.data.data.location.locationImages
                : []
              : []
          );

          setLoader(false);
          setAddress(
            response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.address
                ? response.data.data.location.address.trim()
                : ""
              : ""
          );
          setPlaceId(
            response.data.data.isLocation
              ? response.data.data.location &&
                response.data.data.location.placeId
                ? response.data.data.location.placeId
                : ""
              : ""
          );
          if (response.data.data.type) {
            setEventType({
              ...eventType,
              value: response.data.data.type.typeId,
              label: response.data.data.type.name,
            });
          }
        }
      })
      .catch((e) => {
        setSaveInProcess(false);
        console.log(e);
      });
  }, [queryVariable]);

  // handle event detail change
  const handleEventDetailChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
    if (
      e.target.name === "title" ||
      e.target.name === "status" ||
      e.target.name === "shortDescription" ||
      e.target.name === "timeZone" ||
      e.target.name === "startDate" ||
      e.target.name === "startTime" ||
      e.target.name === "endDate" ||
      e.target.name === "endTime" ||
      e.target.name === "airTableEventName"
    ) {
      if (e.target.value.length === 0)
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]:
            e.target.name === "title"
              ? "Enter title"
              : e.target.name === "status"
                ? "Enter status"
                : e.target.name === "shortDescription"
                  ? "Enter short description"
                  : e.target.name === "timeZone"
                    ? "Select time zone"
                    : e.target.name === "startDate"
                      ? "Select start date"
                      : e.target.name === "startTime"
                        ? "Select start time"
                        : e.target.name === "endDate"
                          ? "Select end date"
                          : e.target.name === "endTime"
                            ? "Select end time"
                            : "",
        });
      else {
        if (e.target.name === "title") {
          if (e.target.value.indexOf(",") > -1) {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: 'Special character comma "," is not allowed!',
            });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "",
            });
          }
          setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
        }
        // else if (e.target.name === "airTableEventName") {
        //   if (e.target.value.indexOf(",") > -1) {
        //     setEventDetailErr({
        //       ...eventDetailErr,
        //       [e.target.name]: 'Special character comma "," is not allowed!',
        //     });

        //   } else {
        //     setEventDetailErr({
        //       ...eventDetailErr,
        //       [e.target.name]: "",
        //     });
        //   }
        //   setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });

        // }
        else if (e.target.name === "endDate" && eventDetail.startDate) {
          if (Date.parse(e.target.value) < Date.parse(eventDetail.startDate))
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Must be greater then start date",
            });
          else setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        } else {
          setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        }
      }
    }

    if (e.target.name === "toBeAnnounceedDescription") {
      if (e.target.value.trim().length === 0) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter description",
        });
      } else {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "",
        });
      }
    }

    if (eventDetail.isLocation) {
      if (eventDetail.locationType === "inPerson") {
        if (e.target.name === "city") {
          if (e.target.value.trim().length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter city",
            });
          }
        } else if (e.target.name === "country") {
          if (e.target.value.trim().length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter country",
            });
          }
        } else if (e.target.name === "latitude") {
          if (e.target.value.length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter latitude",
            });
          }
        } else if (e.target.name === "longitude") {
          if (e.target.value.length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter longitude",
            });
          }
        }
        // else if (e.target.name === "eventUrl") {
        //   if (e.target.value.length > 0) {
        //     setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        //   } else {
        //     setEventDetailErr({
        //       ...eventDetailErr,
        //       [e.target.name]: "Enter URL",
        //     });
        //   }
        // }
        if (e.target.name === "eventUrl") {
          let url;
          try {
            url = new URL(e.target.value);
          } catch (error) {
            if (e.target.value.length > 0) {
              setEventDetailErr({
                ...eventDetailErr,
                [e.target.name]: "Invalid url",
              });
            } else {
              setEventDetailErr({
                ...eventDetailErr,
                [e.target.name]: "",
              });
            }
          }
          if (url && (url.protocol === "http:" || url.protocol === "https:")) {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "",
              status: "",
            });
          }
        }
      }

      if (eventDetail.locationType === "online") {
        if (e.target.name === "onlineUrl") {
          if (e.target.value.trim().length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
            if (e.target.value.trim().length > 0) {
              setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
              let url;
              try {
                url = new URL(e.target.value);
              } catch (error) {
                setEventDetailErr({
                  ...eventDetailErr,
                  [e.target.name]: "Invalid url",
                });
                // setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
              }
              if (
                url &&
                (url.protocol === "http:" || url.protocol === "https:")
              ) {
                setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
              }
            }
          }
        }
      }
    }
    if (eventDetail.isPreRegister) {
      //   if (e.target.name === "preRegisterStartDate")
      //   setEditChangePreStartDate(true)
      // else
      // setEditChangePreStartDate(false)

      // if (e.target.name === "preRegisterStartTime")
      //   setEditChangePreStartTime(true)
      // else
      // setEditChangePreStartTime(false)

      // if (e.target.name === "preRegisterEndDate")
      //   setEditChangePreEndDate(true)
      // else
      // setEditChangePreEndDate(false)

      if (e.target.name === "preRegisterTitle" && e.target.value.length === 0) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter title",
        });
      }

      if (
        e.target.name === "preRegisterDescription" &&
        e.target.value.length === 0
      ) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter description",
        });
      }

      if (
        e.target.name === "preRegisterBtnTitle" &&
        e.target.value.length === 0
      ) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter button title",
        });
      }

      if (
        e.target.name === "preRegisterBtnLink" &&
        e.target.value.length === 0
      ) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter button link",
        });
      } else if (
        e.target.name === "preRegisterBtnLink" &&
        e.target.value.length === 0
      ) {
        let url;
        try {
          url = new URL(e.target.value);
        } catch (error) {
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Invalid url",
          });
          setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        }
      }

      if (
        e.target.name === "preRegisterStartDate" &&
        eventDetail.preRegisterEndDate.length > 0
      ) {
        if (
          moment(e.target.value).isAfter(moment(eventDetail.preRegisterEndDate))
        )
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Must be less then end date!",
          });
        else setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
      } else {
        setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
      }

      if (
        e.target.name === "preRegisterEndDate" &&
        eventDetail.preRegisterStartDate.length > 0
      ) {
        if (
          moment(e.target.value).isBefore(
            moment(eventDetail.preRegisterStartDate)
          )
        )
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Must be greater then start date!",
          });
        else setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
      } else {
        setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
      }

      if (
        e.target.name === "preRegisterEndTime" &&
        eventDetail.preRegisterStartDate.length > 0 &&
        eventDetail.preRegisterEndDate.length > 0 &&
        eventDetail.preRegisterStartTime.length > 0
      ) {
        if (
          Date.parse(eventDetail.preRegisterEndDate) ===
          Date.parse(eventDetail.preRegisterStartDate) &&
          (moment(eventDetail.preRegisterStartTime, "hh:mm").isAfter(
            moment(e.target.value, "hh:mm")
          ) ||
            moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
              moment(e.target.value, "hh:mm")
            ))
        ) {
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Must be greater then start time",
          });
        } else if (
          e.target.name === "preRegisterEndTime" &&
          moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
            moment(e.target.value, "hh:mm")
          )
        ) {
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Must be less then end time",
          });
        } else {
          setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        }
      }
    }
  };
  // base64 to image file conversation
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], Math.random().toString() + ".png", { type: mime });
  };
  // handling editor state to html state and uploading images to server
  const handleDesciption = async (state) => {
    return new Promise((resolve, reject) => {
      const contentState = state.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      if (
        contentRaw?.entityMap &&
        Object.keys(contentRaw.entityMap).length > 0
      ) {
        var entityMap = contentRaw.entityMap;
        var formdata = new FormData();
        var uploadStatus = false;
        var keyList = [];

        for (var key in entityMap) {
          var data = entityMap[key];
          if (
            data.type === "IMAGE" &&
            data.data.src.startsWith("data:image/")
          ) {
            uploadStatus = true;
            var fileData = dataURLtoFile(data.data.src);
            formdata.append(`image`, fileData);
            keyList = [...keyList, key];
          }
        }
        if (uploadStatus) {
          authServiceEvent
            .eventUploadFiles(formdata)
            .then((res) => {
              if (res.data.status) {
                var mediaData = res.data.media;
                mediaData.map((m, index) => {
                  contentRaw.entityMap[keyList[index]].data.src = m.key;
                });
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              } else {
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              }
            })
            .catch((e) => {
              console.log(e);
              const htmlContent = draftToHtml(contentRaw);
              resolve(htmlContent);
            });
        } else {
          const htmlContent = draftToHtml(contentRaw);
          resolve(htmlContent);
        }
      } else {
        const htmlContent = draftToHtml(contentRaw);
        resolve(htmlContent);
      }
    });
  };
  // handle upload image in editor
  const uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  //Create session validation and api call
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //Create session validation and api call
  const checkLongDesciptionValue = (state) => {
    var text = longDescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //close create session module
  const handleCloseAddModel = () => {
    setResetRulesFields(true);
    setTimeout(() => {
      setResetRulesFields(false);
    }, 2000);
  };
  // editevent api function
  const editEventApi = async () => {
    let imageCompress;
    const longDescriptionFinalData = longDescriptionRef.current.getContent();
    const formDataCreateEvent = new FormData();
    if (eventDetail.tag.length > 0) {
      eventDetail.tag.map((tag) => formDataCreateEvent.append("tag", tag._id));
    }
    if (eventDetail.title)
      formDataCreateEvent.append("title", eventDetail.title);
    formDataCreateEvent.append("status", eventDetail.status);
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formDataCreateEvent.append("thumbnail", imageCompress);
    if (eventDetail.shortDescription)
      formDataCreateEvent.append(
        "shortDescription",
        eventDetail.shortDescription
      );
    if (longDescriptionRef.current !== null)
      formDataCreateEvent.append("longDescription", longDescriptionFinalData);
    // if (eventDetail.eventUrl)
    eventDetail.eventUrl !== undefined &&
      eventDetail.eventUrl !== null &&
      formDataCreateEvent.append("eventUrl", eventDetail.eventUrl);

    if (isTimezoneManually === true) {
      eventDetail.timeZone &&
        formDataCreateEvent.append("timeZone", eventDetail.timeZone);
    } else {
      eventDetail.autoTimezone &&
        formDataCreateEvent.append("timeZone", eventDetail.autoTimezone);
    }
    if (eventDetail.startDate)
      formDataCreateEvent.append(
        "startDate",
        moment(eventDetail.startDate).format("MM-DD-YYYY")
      );
    if (eventDetail.startTime)
      formDataCreateEvent.append(
        "startTime",
        moment(eventDetail.startTime, ["HH:mm"]).format("hh:mm a")
      );
    if (eventDetail.endDate)
      formDataCreateEvent.append(
        "endDate",
        moment(eventDetail.endDate).format("MM-DD-YYYY")
      );
    if (eventDetail.endTime)
      formDataCreateEvent.append(
        "endTime",
        moment(eventDetail.endTime, ["HH:mm"]).format("hh:mm a")
      );
    if (eventDetail.category && eventDetail.category.length > 0) {
      eventDetail.category.map((cat) =>
        formDataCreateEvent.append("category", cat._id)
      );
    }
    if (subcategories && subcategories.length > 0) {
      subcategories.map((item) =>
        formDataCreateEvent.append("subcategory", item)
      );
    }

    eventDetail.isLocation !== undefined &&
      eventDetail.isLocation !== null &&
      formDataCreateEvent.append("isLocation", eventDetail.isLocation);
    formDataCreateEvent.append(
      "isTimeZoneAutomatic",
      isTimezoneManually === true ? false : true
    );
    if (eventDetail.isLocation) {
      if (eventDetail.locationType === "inPerson") {
        const locationData = {
          address: eventDetail.address,
          latitude: eventDetail.latitude,
          longitude: eventDetail.longitude,
          postalCode: eventDetail.postalcode,
          city: eventDetail.city,
          country: eventDetail.country,
          placeId: placeId,
          locationImages: imgFileList,
        };
        formDataCreateEvent.append("location", JSON.stringify(locationData));
      }
      if (eventDetail.locationType) {
        formDataCreateEvent.append(
          `locationType`,
          eventDetail.locationType === "virtual"
            ? "virtual"
            : eventDetail.locationType === "inPerson"
              ? "inPerson"
              : eventDetail.locationType
        );
      }
      if (eventDetail.locationType === "virtual") {
        if (eventDetail.onlineUrl) {
          formDataCreateEvent.append("onlineEventUrl", eventDetail.onlineUrl);
        }
      }
      if (eventDetail.locationType === "to_be_announce") {
        if (eventDetail.toBeAnnounceedDescription) {
          formDataCreateEvent.append(
            "toBeAnnouceDetail",
            eventDetail.toBeAnnounceedDescription
          );
        }
      }
    }

    formDataCreateEvent.append("isPreRegister", eventDetail.isPreRegister);
    //formDataCreateEvent.append("location", eventDetail.location);

    if (eventDetail.isPreRegister === true) {
      if (eventDetail.preRegisterTitle)
        formDataCreateEvent.append(
          "preRegisterTitle",
          eventDetail.preRegisterTitle
        );

      if (eventDetail.preRegisterDescription)
        formDataCreateEvent.append(
          "preRegisterDescription",
          eventDetail.preRegisterDescription
        );

      if (eventDetail.preRegisterBtnTitle)
        formDataCreateEvent.append(
          "preRegisterBtnTitle",
          eventDetail.preRegisterBtnTitle
        );

      if (eventDetail.preRegisterBtnLink)
        formDataCreateEvent.append(
          "preRegisterBtnLink",
          eventDetail.preRegisterBtnLink
        );

      if (eventDetail.preRegisterStartDate && eventDetail.preRegisterStartTime)
        formDataCreateEvent.append(
          "preRegisterStartDate",
          moment(
            eventDetail.preRegisterStartDate +
            " " +
            eventDetail.preRegisterStartTime
          ).format()
        );

      if (eventDetail.preRegisterEndDate && eventDetail.preRegisterEndTime)
        formDataCreateEvent.append(
          "preRegisterEndDate",
          moment(
            eventDetail.preRegisterEndDate +
            " " +
            eventDetail.preRegisterEndTime
          ).format()
        );
    }

    formDataCreateEvent.append(
      "airTableEventName",
      eventDetail.airTableEventName
    );

    //Data for rules
    dataFromRules?.userAccess.length !== 0 &&
      formDataCreateEvent.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );

    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formDataCreateEvent.append(`restrictedAccessGroupId[]`, id);
        });
    }
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formDataCreateEvent.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formDataCreateEvent.append(`restrictedAccessMembershipPlanId[]`, id);
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formDataCreateEvent.append(`restrictedAccessUserId[]`, id);
      });
    if (isCheckInAllowed !== undefined) {
      formDataCreateEvent.append("isCheckInAllow", isCheckInAllowed);
    }
    if (
      eventDetail.partnerType.value !== undefined &&
      eventDetail.partnerType.value !== "" &&
      eventDetail.partnerType.value
    ) {
      formDataCreateEvent.append(
        "chatChannel",
        eventDetail.partnerType.value
      );
    }
    authServiceEvent
      .editEventDetail({
        eventId: queryVariable,
        formData: formDataCreateEvent,
      })
      .then((res) => {
        setInProcess(false);
        setSaveInProcess(false);
        setResponseMessage(res.data.message);
        setApiCall(true);
        setTimeout(() => {
          setResponseMessage("");
          setApiCall(false);
        }, 3000);
        if (res.data.status) {
          setEventDetailErr({
            title: "",
            url: "",
            timeZone: "",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            thumbnail: "",
            type: "",
            preRegisterBtnLink: "",
            preRegisterBtnTitle: "",
            preRegisterDescription: "",
            preRegisterEndDate: "",
            preRegisterStartDate: "",
            preRegisterTitle: "",
            preRegisterStartTime: "",
            preRegisterEndTime: "",
            isPreRegister: "",
            address: "",
            city: "",
            country: "",
            latitude: "",
            longitude: "",
            postalcode: "",
            location: "",
            isLocation: "",
            airTableEventName: "",
          });
        }
        handleCloseAddModel();
        handleClearAllStates();
      })
      .catch((e) => {
        setSaveInProcess(false);
        setInProcess(false);
        setResponseMessage(e);
        setTimeout(() => {
          setResponseMessage("");
        }, 3000);
        console.log(e);
      });
  };


  // called when click on update event button
  const handleEditEvent = async () => {
    var validatedData = true;
    var addressErr = true;
    var eventDetailErrObj = { ...eventDetailErr };
    for (let eventDetailKey in eventDetailErr) {
      if (validatedData && eventDetailErr[eventDetailKey].length > 0)
        validatedData = false;
    }

    for (let eventDetailKey in eventDetail) {
      if (
        (eventDetailKey === "title" ||
          eventDetailKey === "status" ||
          eventDetailKey === "shortDescription" ||
          eventDetailKey === "url" ||
          eventDetailKey === "startDate" ||
          eventDetailKey === "startTime" ||
          eventDetailKey === "endDate" ||
          eventDetailKey === "category" ||
          eventDetailKey === "endTime") &&
        eventDetail[eventDetailKey].length === 0
      ) {
        if (validatedData) validatedData = false;
        eventDetailErrObj = {
          ...eventDetailErrObj,
          [eventDetailKey]:
            eventDetailKey === "title"
              ? "Enter title"
              : eventDetailKey === "status"
                ? "select status"
                : eventDetailKey === "shortDescription"
                  ? "Enter short description"
                  : eventDetailKey === "startDate"
                    ? "Select start date"
                    : eventDetailKey === "startTime"
                      ? "Select start time"
                      : eventDetailKey === "endDate"
                        ? "Select end date"
                        : eventDetailKey === "category"
                          ? "Select category"
                          : "Select end time",
        };
      }
    }
    if (eventDetail.category.length === 0) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        category: "Select category",
      };
    }

    if (isTimezoneManually) {
      if (eventDetail.timeZone === "") {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          timeZone: "Select time zone",
        };
        validatedData = false;
      }
    }

    if (eventDetail.title && eventDetail.title.indexOf(",") > -1) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        title: 'Special character comma "," is not allowed!',
      };
      validatedData = false;
    }
    // if (eventDetail.airTableEventName && eventDetail.airTableEventName.indexOf(",") > -1) {
    //   eventDetailErrObj = { ...eventDetailErrObj, airTableEventName: 'Special character comma "," is not allowed!' };
    //   validatedData = false;

    // }

    if (
      eventDetail.thumbnail &&
      eventDetail.thumbnail.length === 0 &&
      (!cropedFileData || cropedFileData.length === 0)
    ) {
      eventDetailErrObj = { ...eventDetailErrObj, thumbnail: "Add thumbnail" };
      if (validatedData) validatedData = false;
    }
    if (eventDetail.shortDescription.trim().length === 0) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        shortDescription: "Enter short description",
      };
      if (validatedData) validatedData = false;
    } else {
      eventDetailErrObj = { ...eventDetailErrObj, shortDescription: "" };
    }
    if (eventDetail.status === "draft") {
      eventDetailErrObj = { ...eventDetailErrObj, status: "select status" };
      validatedData = false;
    }
    if (!checkLongDesciptionValue(eventDetail.longDescription)) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        longDescription: "Enter long description",
      };
      if (validatedData) validatedData = false;
    } else {
      eventDetailErrObj = { ...eventDetailErrObj, longDescription: "" };
    }

    if (eventDetail.startDate.length > 0 && eventDetail.endDate.length > 0) {
      if (Date.parse(eventDetail.endDate) < Date.parse(eventDetail.startDate)) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          endDate: "Must be greater then start date",
        };
        if (validatedData) validatedData = false;
      } else eventDetailErrObj = { ...eventDetailErrObj, endDate: "" };
    }
    if (
      Date.parse(eventDetail.endDate) === Date.parse(eventDetail.startDate) &&
      (moment(eventDetail.startTime, "hh:mm").isAfter(
        moment(eventDetail.endTime, "hh:mm")
      ) ||
        moment(eventDetail.startTime, "hh:mm").isSame(
          moment(eventDetail.endTime, "hh:mm")
        ))
    ) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        endTime: "Must be greater then start time",
      };
      if (validatedData) validatedData = false;
    } else {
      eventDetailErrObj = { ...eventDetailErrObj, endTime: "" };
    }

    if (eventDetail.isLocation) {
      if (eventDetail.locationType === "inPerson") {
        // if (eventDetail.eventUrl.trim().length === 0) {
        //   eventDetailErrObj = {
        //     ...eventDetailErrObj,
        //     eventUrl: "Enter URL",
        //   };
        //   addressErr = false;
        //   validatedData = false;
        // }

        if (eventDetail.address.trim().length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            address: "Enter full address!",
          };
          addressErr = false;
          validatedData = false;
        }
        if (!placeId && addressErr) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            address: "Please select full address from dropdown list!",
          };
          validatedData = false;
        }
        if (eventDetail.latitude.length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            latitude: "Enter latitude!",
          };
          validatedData = false;
        }
        if (eventDetail.longitude.length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            longitude: "Enter longitude!",
          };
          validatedData = false;
        }
        if (eventDetail.city.trim().length === 0) {
          eventDetailErrObj = { ...eventDetailErrObj, city: "Enter city!" };
          validatedData = false;
        } else if (!regex.test(eventDetail.city)) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            city: "Numbers are not allowed!",
          };
          validatedData = false;
        }
        if (eventDetail.country.trim().length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            country: "Enter country!",
          };
          validatedData = false;
        } else if (!regex.test(eventDetail.country)) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            country: "Numbers are not allowed!",
          };
          validatedData = false;
        }
      }

      if (eventDetail.locationType === "virtual") {
        if (eventDetail.onlineUrl.trim().length === 0) {
          eventDetailErrObj = { ...eventDetailErrObj, onlineUrl: "Enter url!" };
          addressErr = false;
          validatedData = false;
        } else {
          let url;
          try {
            url = new URL(eventDetail.onlineUrl);
          } catch (error) {
            eventDetailErrObj = {
              ...eventDetailErrObj,
              onlineUrl: "Invalid url",
            };
            if (validatedData) validatedData = false;
          }
          if (url && (url.protocol === "http:" || url.protocol === "https:")) {
            eventDetailErrObj = { ...eventDetailErrObj, onlineUrl: "" };
          }
        }
      }
      if (eventDetail.locationType === "to_be_announce") {
        if (eventDetail.toBeAnnounceedDescription.trim().length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            toBeAnnounceedDescription: "Enter description!",
          };
          addressErr = false;
          validatedData = false;
        }
      }
    }
    if (eventDetail.isPreRegister) {
      if (eventDetail.preRegisterTitle.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterTitle: "Enter title",
        };
        if (validatedData) validatedData = false;
      } else {
        eventDetailErrObj = { ...eventDetailErrObj, preRegisterTitle: "" };
      }

      if (eventDetail.preRegisterDescription.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterDescription: "Enter description",
        };
        if (validatedData) validatedData = false;
      } else {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterDescription: "",
        };
      }

      if (eventDetail.preRegisterBtnTitle.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterBtnTitle: "Enter button title",
        };
        if (validatedData) validatedData = false;
      } else {
        eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnTitle: "" };
      }

      if (eventDetail.preRegisterBtnLink.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterBtnLink: "Enter button title",
        };
        if (validatedData) validatedData = false;
      } else {
        eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnLink: "" };
      }

      if (eventDetail.preRegisterBtnLink.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterBtnLink: "Enter button link",
        };
        if (validatedData) validatedData = false;
      } else {
        let url;
        try {
          url = new URL(eventDetail.preRegisterBtnLink);
        } catch (error) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterBtnLink: "Invalid url",
          };
          if (validatedData) validatedData = false;
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnLink: "" };
        }
      }

      if (eventDetail.preRegisterStartDate.length > 0) {
      } else {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterStartDate: "Enter start date",
        };
        if (validatedData) validatedData = false;
      }

      if (eventDetail.preRegisterStartTime.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterStartTime: "Enter start time",
        };
        if (validatedData) validatedData = false;
      } else {
      }

      if (eventDetail.preRegisterEndDate.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterEndDate: "Enter end date!",
        };
        if (validatedData) validatedData = false;
      } else if (
        eventDetail.preRegisterStartDate.length > 0 &&
        eventDetail.startDate.length > 0
      ) {
      } else {
        eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      }

      if (
        eventDetail.preRegisterStartDate.length > 0 &&
        eventDetail.preRegisterEndDate.length > 0
      ) {
        if (
          Date.parse(eventDetail.preRegisterEndDate) <
          Date.parse(eventDetail.preRegisterStartDate)
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterEndDate: "Must be greater then start date",
          };
          if (validatedData) validatedData = false;
        } else
          eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      }

      if (eventDetail.preRegisterEndTime.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterEndTime: "Enter end time!",
        };
        if (validatedData) validatedData = false;
      } else if (
        eventDetail.preRegisterEndDate.length > 0 &&
        eventDetail.preRegisterStartDate.length > 0 &&
        eventDetail.preRegisterEndTime.length > 0 &&
        eventDetail.preRegisterStartTime.length > 0
      ) {
        if (
          moment(eventDetail.preRegisterEndDate).isSame(
            moment(eventDetail.preRegisterStartDate)
          ) &&
          (moment(eventDetail.preRegisterStartTime, "hh:mm").isAfter(
            moment(eventDetail.preRegisterEndTime, "hh:mm")
          ) ||
            moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
              moment(eventDetail.preRegisterEndTime, "hh:mm")
            ))
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterEndTime: "Must be greater then start time!",
          };
          if (validatedData) validatedData = false;
        } else if (
          eventDetail.preRegisterEndTime.length > 0 &&
          moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
            moment(eventDetail.preRegisterEndTime, "hh:mm")
          )
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterEndTime: "Must be less then end time!",
          };
          if (validatedData) validatedData = false;
        } else {
          eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndTime: "" };
        }
      }
    } else {
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterTitle: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterDescription: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnLink: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnTitle: "" };
    }

    setEventDetailErr({ ...eventDetailErr, ...eventDetailErrObj });
    if (validatedData && !ruleError && !noRuleFieldSelected) {
      setInProcess(true);
      editEventApi();
    } else {
      const emptyKeys = Object.keys(eventDetailErrObj).filter(
        (key) => eventDetailErrObj[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
      setInProcess(false);
      noRuleFieldSelected && setRuleError(true);
    }
  };

  // handel save draft event
  const handleSaveDraft = () => {
    setSaveInProcess(true);
    editEventApi();
  };
  //html to editor state
  function htmlToEditor(html) {
    const contentBlock = htmlToDraft(html.replace("<p>&nbsp;</p>", ""));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  const handleCheckPreRegisterationChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSymbolCompulsory(e.target.checked ? "*" : "");
    setPreRegisterCompulsory(e.target.checked ? true : false);
    setEventDetail({
      ...eventDetail,
      isPreRegister: e.target.checked ? true : false,
    });

    var eventDetailErrObj = { ...eventDetailErr };
    if (!e.target.checked) {
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterTitle: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterDescription: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnLink: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnTitle: "" };

      setEventDetailErr({ ...eventDetailErr, ...eventDetailErrObj });
    }
  };

  //past event for editor
  const handlePastedText = (text, editor, stateChangeFunctionCall) => {
    const contentState = editor.getCurrentContent();
    const selectionState = editor.getSelection();

    // Create a new modified content state with the pasted text
    const modifiedContentState = Modifier.replaceText(
      contentState,
      selectionState,
      text,
      editor
        .getCurrentInlineStyle()
        .filter((style) => style !== "backgroundColor")
    );

    // Create a new editor state with the modified content state
    const newEditorState = EditorState.push(
      editor,
      modifiedContentState,
      "insert-characters"
    );
    stateChangeFunctionCall(newEditorState);
  };

  //onchange event for edit location field
  const handleChangeEditLocation = (newAddress) => {
    setAddress(newAddress);
    setshowSuggestion(true);
    setEventDetail({ ...eventDetail, address: newAddress });
    setPlaceId("");
    if (newAddress) {
      setEventDetailErr({ ...eventDetailErr, address: "" });
    }
  };

  //called when user selects suggestion from dropdown  for edit modal
  const handleSelectEditLocation = async (
    address,
    selectedPlaceId,
    selectedPlace
  ) => {
    setshowSuggestion(false);
    setPlaceId(selectedPlaceId);
    if (!selectedPlace?.description || !selectedPlace.description) return;
    setAddress(selectedPlace.description);

    //latitude, longitude api call
    var { latitude, longitude } = await getPlaceDetails(selectedPlaceId);
    let city = "";
    let country = "";
    //city, country api call
    const results = await geocodeByPlaceId(selectedPlaceId);
    for (const result of results) {
      for (const component of result.address_components) {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        }
      }
    }
    if (selectedPlace) {
      setEventDetail({
        ...eventDetail,
        address: selectedPlace.description,
        city: city,
        country: country,
        latitude,
        longitude,
      });
      let tempErrObj = eventDetailErr;
      if (city) {
        tempErrObj = { ...tempErrObj, city: "" };
      }
      if (country) {
        tempErrObj = { ...tempErrObj, country: "" };
      }
      if (latitude && longitude) {
        tempErrObj = { ...tempErrObj, latitude: "", longitude: "" };
      }
      setEventDetailErr(tempErrObj);
    }
  };

  //google map id and setup
  const googlePlacesAutocompleteProps = {
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY, // Replace with your actual API key
    debounce: 300,
  };

  const getPlaceDetails = async (placeId) => {
    const geocodeResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_PLACE_KEY}`
    );
    const geocodeData = await geocodeResponse.json();

    if (geocodeData.status === "OK") {
      const { lat, lng } = geocodeData.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    } else {
    }
  };

  const handleCheckLocationChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSymbolCompulsoryLocation(e.target.checked ? "*" : "");
    setLocationCompulsory(true);
    setEventDetail({
      ...eventDetail,
      isLocation: e.target.checked ? true : false,
    });
    var eventDetailErrObj = { ...eventDetailErr };
    if (!e.target.checked) {
      eventDetailErrObj = { ...eventDetailErrObj, address: "" };
      eventDetailErrObj = { ...eventDetailErrObj, city: "" };
      eventDetailErrObj = { ...eventDetailErrObj, latitude: "" };
      eventDetailErrObj = { ...eventDetailErrObj, country: "" };
      eventDetailErrObj = { ...eventDetailErrObj, longitude: "" };
      eventDetailErrObj = { ...eventDetailErrObj, postalcode: "" };
      eventDetailErrObj = { ...eventDetailErrObj, onlineUrl: "" };
      eventDetailErrObj = {
        ...eventDetailErrObj,
        toBeAnnounceedDescription: "",
      };
      setEventDetailErr({ ...eventDetailErr, ...eventDetailErrObj });
    }
  };

  //call when event type change for edit edit
  const onhandleEditSelectEventType = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setEventType(selectedOption);
    setEventDetailErr({ ...eventDetailErr, type: "" });
  };

  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //Will call on dropping or setting file to dropzone
  const assignFiles = (acceptedFile) => {
    let tempArray = [...imgFileList, ...acceptedFile];
    if (tempArray.length > 5) tempArray.length = 5;

    const formDataAddLocationImages = new FormData();
    tempArray.map((img) => {
      if (typeof img === "object") {
        formDataAddLocationImages.append("location_images", img);
      } else {
        formDataAddLocationImages.append("locationOldData", img);
      }
    });

    setLocationImageLoader(true);
    authServiceEvent
      .editLocationImageForEvent(queryVariable, formDataAddLocationImages)
      .then((resImage) => {
        if (resImage.status) {
          if (resImage.data.data.length > 0) {
            setImgFileList(resImage.data.data);
          }
        }
        setLocationImageLoader(false);
      })
      .catch((e) => {
        setLocationImageLoader(false);
      });
  };

  //Called when someone drop a file
  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });

  const removeSelectedImage = (index) => {
    setImgFileList(imgFileList.filter((_, i) => i !== index));
  };

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    // setModalOpen(true);
  };

  // handle tag onchange
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setaddtag(e.target.value.trim());
    if (e.target.value.length > 0) {
      setadderr("");
    } else {
      setadderr("Enter tags! ");
    }
  };

  // handle create tag
  const handleCreate = () => {
    if (addtag.length > 0) {
      setcreateInprocess(true);

      Services.createtag({
        name: addtag.trim(),
      })
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setshowalertAdd(true);
            setaddtag("");
            setalertmsgAdd(res.data.message);
            getTagList();
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          } else {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          }
          handleClearAllStates();
        })
        .catch((e) => {
          handleClearAllStates();
          setcreateInprocess(false);
          console.log(e);
        });
      setadderr("");
    } else {
      setadderr("Enter tags!");
    }
  };

  async function fetchTimeZone(lat, lng) {
    try {
      const response = await authServiceEvent.getTimeZoneFromLocation(
        `?lat=${lat}&long=${lng}`
      );
      if (response.data.status) {
        const data = response.data.data;
        setEventDetail({ ...eventDetail, autoTimezone: data });
        return data;
      }
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    fetchTimeZone(eventDetail.latitude, eventDetail.longitude);
  }, [eventDetail.latitude, eventDetail.longitude]);

  return (
    <>
      {responseMessage.length > 0 && (
        <div className="alert alert-info">{responseMessage}</div>
      )}

      {loader ? (
        <div className="cstm-data-edit-load">
          <img src={Loader} />{" "}
        </div>
      ) : (
        <>
          <div className="group-info-user">
            <div className="cstm-video-upload">
              {modalOpen && (
                <ImageCropperReact
                  file={newThumbnail}
                  setModalOpen={setModalOpen}
                  setcropedFile={setcropedFile}
                  setFile={setNewThumbnail}
                  fileName={fileName}
                  setcropedFileData={setcropedFileData}
                  fileDimension={fileDimension}
                  requiredImageSize={requiredImageSize}
                  requiredImageAspectRatio={requiredImageAspectRatio}
                  setRequiredImageSizeError={() =>
                    setEventDetailErr({
                      ...eventDetailErr,
                      thumbnail: `Recommended size ${requiredImageSize.width} x ${requiredImageSize.height}`,
                    })
                  }
                  isLoading={isLoading}
                />
              )}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    setModalOpen(true)
                    setIsLoading(true)
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        setNewThumbnail(event.target.result);
                        thumbnailImages(file);
                        setEventDetailErr({ ...eventDetailErr, thumbnail: "" });
                        setIsLoading(false)
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                name="thumbnail"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-video-upload-img">
                      {cropedFile !== undefined &&
                        cropedFile !== "" &&
                        cropedFile.length > 0 ? (
                        <img
                          className="channel-img square w-100 h-100"
                          src={cropedFile}
                        />
                      ) : (
                        <img className="empty-img" src={Frame} />
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">Thumbnail*</div>
              <div className="cover-image-upload-size">
                {`Recommended size ${requiredImageSize.width} x ${requiredImageSize.height}`}
              </div>
            </div>
          </div>
          <span className="error cstm-error44">{eventDetailErr.thumbnail}</span>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Event title*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="title"
                  value={eventDetail.title}
                  onChange={handleEventDetailChange}
                />
              </div>
              <span className="error cstm-error4">{eventDetailErr.title}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Airtable Event Name</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="airTableEventName"
                  value={eventDetail.airTableEventName}
                  onChange={handleEventDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {eventDetailErr.airTableEventName}
              </span>
            </div>
          </div>
          {eventDetail.isLocation && (
            <>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Location type*</div>
                  <div className="form-group-ct">
                    <select
                      value={eventDetail.locationType}
                      onChange={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setEventDetail({
                          ...eventDetail,
                          locationType: e.target.value,
                        });
                        if (e.target.value !== "inPerson") {
                          setIsTimezoneManually(true);
                        } else {
                          setIsTimezoneManually(false);
                        }
                      }}
                    >
                      <option value="inPerson">InPerson</option>
                      <option value="virtual">Virtual</option>
                      <option value="to_be_announce">To be announceed</option>
                    </select>
                  </div>
                  <span className="error">{eventDetailErr.locationType}</span>
                </div>
              </div>
              {eventDetail.locationType === "inPerson" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">
                      Full address{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <PlacesAutocomplete
                        name="address"
                        value={address}
                        onChange={handleChangeEditLocation}
                        onSelect={handleSelectEditLocation}
                        {...googlePlacesAutocompleteProps}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Enter a location",
                              })}
                              value={address} // Pass the value prop directly to the input element
                            />
                            <div className="location-suggestions">
                              {loading ? (
                                <div class="location-suggestions-loading">
                                  <img src={Loader} />
                                </div>
                              ) : null}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#e8e8e8"
                                    : "#ffffff",
                                };
                                if (showSuggestion) {
                                  return (
                                    <div
                                      className="location-suggestions-in"
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                }
                                return "";
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <span className="error">{eventDetailErr.address}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Latitude{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="number"
                        placeholder="Enter latitude"
                        name="latitude"
                        onChange={handleEventDetailChange}
                        value={eventDetail.latitude}
                      />
                    </div>
                    <span className="error">{eventDetailErr.latitude}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Longitude{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="number"
                        placeholder="Enter longitude"
                        name="longitude"
                        onChange={handleEventDetailChange}
                        value={eventDetail.longitude}
                      />
                    </div>
                    <span className="error">{eventDetailErr.longitude}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Postal code</div>
                    <div className="form-group-ct">
                      <input
                        type="number"
                        placeholder="Enter postal code"
                        name="postalcode"
                        onChange={handleEventDetailChange}
                        value={eventDetail.postalcode}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      City{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter city name"
                        name="city"
                        onChange={handleEventDetailChange}
                        value={eventDetail.city}
                      />
                    </div>
                    <span className="error">{eventDetailErr.city}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Country{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter country name"
                        name="country"
                        onChange={handleEventDetailChange}
                        value={eventDetail.country}
                      />
                    </div>
                    <span className="error">{eventDetailErr.country}</span>
                  </div>
                  {!isTimezoneManually && (
                    <div className="form-group">
                      <div className="form-group-label">
                        Time Zone(Fetched automatically)
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Timezone"
                          name="autoTimezone"
                          disabled
                          value={eventDetail.autoTimezone}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">
                        Event URL (External URL to WA registration page)
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="eventUrl"
                          value={eventDetail.eventUrl}
                          onChange={handleEventDetailChange}
                        />
                      </div>
                      <span className="error cstm-error4">
                        {eventDetailErr.eventUrl}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div
                        {...getRootProps()}
                        className="edit-group-info-mn-change-img-label"
                      >
                        <input {...getInputProps()} />

                        <div className="cstm-apl-main">
                          {locationImageLoader ? (
                            <>
                              {" "}
                              <div className="event-locimage-loader">
                                <span className="loader-btn">
                                  <img src={Loader2} />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>Click here to add photos</>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="cstm-apl-upload-img">
                          {imgFileList !== undefined &&
                            imgFileList !== null &&
                            imgFileList.map((image, index) => {
                              return (
                                <>
                                  <div className="cstm-apl-upload-img-box">
                                    <span
                                      className="cstm-apl-upload-close"
                                      onClick={() => {
                                        removeSelectedImage(index);
                                      }}
                                    >
                                      ×
                                    </span>
                                    <img
                                      alt="location image"
                                      src={
                                        typeof image === "object"
                                          ? URL.createObjectURL(image)
                                          : image
                                      }
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                      <span className="error">{locationImageErr}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="cstm-chk-preregistration-container">
                        <input
                          type="checkbox"
                          name="chk-islocation"
                          id="chk-islocation"
                          checked={isTimezoneManually}
                          onChange={(e) =>
                            setIsTimezoneManually(e.target.checked)
                          }
                        />
                        <label for="chk-islocation">
                          Do you want to add timezone manually?
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {eventDetail.locationType === "virtual" && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">URL*</div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="onlineUrl"
                          value={eventDetail.onlineUrl}
                          onChange={handleEventDetailChange}
                          maxLength={75}
                        />
                      </div>
                      <span className="error cstm-error4">
                        {eventDetailErr.onlineUrl}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {eventDetail.locationType === "to_be_announce" && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">Description*</div>
                      <div className="form-group-ct">
                        <textarea
                          name="toBeAnnounceedDescription"
                          value={eventDetail.toBeAnnounceedDescription}
                          onChange={handleEventDetailChange}
                        />
                      </div>
                      <span className="error cstm-error4">
                        {eventDetailErr.toBeAnnounceedDescription}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {isTimezoneManually && (
            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="form-group-label">Time Zone*</div>
                <div className="form-group-ct">
                  <select
                    name="timeZone"
                    value={eventDetail.timeZone}
                    onChange={handleEventDetailChange}
                  >
                    <option value="">Select time zone</option>
                    {Object.keys(defaultTimezones).map((timeZoneKey) => {
                      return <option value={timeZoneKey}>{timeZoneKey}</option>;
                    })}
                  </select>
                </div>
                <span className="error">{eventDetailErr.timeZone}</span>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm-3 form-group">
              <div className="form-group-label">Start Date*</div>
              <div className="form-group-ct">
                <input
                  type="date"
                  placeholder="Select start date"
                  name="startDate"
                  onChange={handleEventDetailChange}
                  value={eventDetail.startDate}
                // min={moment().add(1, 'days').format("YYYY-MM-DD")}
                />
              </div>
              <span className="error">{eventDetailErr.startDate}</span>
            </div>
            <div className="col-sm-3 form-group">
              <div className="form-group-label">Start Time*</div>
              <div className="form-group-ct">
                <input
                  type="time"
                  placeholder="Select start time"
                  name="startTime"
                  onChange={handleEventDetailChange}
                  value={eventDetail.startTime}
                  disabled={eventDetail.startDate.length === 0 ? true : false}
                />
              </div>
              <span className="error">{eventDetailErr.startTime}</span>
            </div>
            <div className="col-sm-3 form-group">
              <div className="form-group-label">End Date*</div>
              <div className="form-group-ct">
                <input
                  type="date"
                  placeholder="Select end date"
                  name="endDate"
                  onChange={handleEventDetailChange}
                  value={eventDetail.endDate}
                  disabled={eventDetail.startDate.length === 0 ? true : false}
                  min={eventDetail.startDate}
                />
              </div>
              <span className="error">{eventDetailErr.endDate}</span>
            </div>
            <div className="col-sm-3 form-group">
              <div className="form-group-label">End Time*</div>
              <div className="form-group-ct">
                <input
                  type="time"
                  placeholder="Select end time"
                  name="endTime"
                  onChange={handleEventDetailChange}
                  value={eventDetail.endTime}
                  disabled={eventDetail.startDate.length === 0 ? true : false}
                />
              </div>
              <span className="error">{eventDetailErr.endTime}</span>
            </div>
          </div>
          {/* <div className="row">
        <div className="col-sm-12 form-group">
          <div className="form-group-label">Location</div>
          <div className="form-group-ct">
            <select
              onChange={(e)=>{setEventDetail({...eventDetail, location : e.target.value})}}
              value={eventDetail.location}
              className="location"
            >
              <option value="">Select a location</option>
              {locationList.map((location) => (
                <option
                  key={location._id}
                  value={location._id}
                  data-address={location.name}
                >
                  {location.name}
                </option>
              ))}
            </select>
          </div>
          <span className="error">{eventDetailErr.location}</span>
        </div>
      </div> */}
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="chk-preregistration"
                  id="chk-preregistration"
                  checked={isCheckInAllowed}
                  onChange={(e) => {
                    setIsCheckInAllowed(e.target.checked);
                  }}
                />
                <label for="chk-preregistration">
                  Do you want to enable the check-in tab for this event?
                </label>
              </div>
            </div>
          </div>
          <AddRules
            title="Rules*"
            ruleError={ruleError}
            setRuleError={setRuleError}
            totalUserList={totalUserList}
            noRuleFieldSelected={noRuleFieldSelected}
            setNoRuleFieldSelected={setNoRuleFieldSelected}
            dataFromRules={dataFromRules}
            setDataFromRules={setDataFromRules}
            addedRules={rulesDataForEdit}
            showEventField={true}
            showTagsField={true}
            users={users}
            setUsers={setUsers}
            emails={emails}
            setEmails={setEmails}
            invalidEmail={invalidEmail}
            setInvalidEmail={setInvalidEmail}
            emailRegex={emailRegex}
            nonUsers={nonUsers}
            setNonUsers={setNonUsers}
          />
          <div className="row">
            <div class="col-sm-4 form-group">
              <div class="cstm-channel-type-title">Channel</div>
              <div className="form-group-ctst event-type-drop-only">
                <Select
                  isSearchable={true}
                  options={badgeList}
                  onChange={onhandleBadgeChange}
                  value={eventDetail.partnerType}
                  styles={{ zIndex: 9999 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">
                Add Tag or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addtag"
                >
                  Create Tag
                </a>
              </div>
              <div className="form-group-ct">
                <input
                  type="text"
                  autoComplete="off"
                  list="tag"
                  name="browser"
                  onKeyUp={(e) => {
                    var selType = tagList.filter((type) => {
                      if (type.name === e.target.value) return type.name;
                    });
                    var eventType = eventDetail.tag.find(
                      (type) => type.name === e.target.value
                    );

                    if (selType.length > 0 && !eventType) {
                      setEventDetail({
                        ...eventDetail,
                        tag: [...eventDetail.tag, ...selType],
                      });
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            {eventDetail.tag.map((type) => {
              return (
                <span className="topic-label-in">
                  <b>{type.name}</b>
                  <button
                    onClick={() => {
                      setEventDetail({
                        ...eventDetail,
                        tag: eventDetail.tag.filter((typeFilter) => {
                          if (typeFilter.name !== type.name) return typeFilter;
                        }),
                      });
                    }}
                  >
                    ×
                  </button>
                </span>
              );
            })}
            <datalist id="tag">
              {tagList.map((tag) => {
                if (
                  eventDetail.tag.filter((category) => {
                    if (category.name === tag.name) return category;
                  }).length === 0
                )
                  return <option>{tag.name}</option>;
              })}
            </datalist>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">
                Category* or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addCategory"
                >
                  Create Category
                </a>
              </div>
              <div className="form-group-ct">
                <input
                  list="category"
                  type="text"
                  name="browser"
                  autoComplete="off"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    var selType = categoryData.filter((type) => {
                      if (type.name === e.target.value) return type.name;
                    });
                    var eventType = eventDetail.category.find(
                      (type) => type.name === e.target.value
                    );

                    if (selType.length > 0 && !eventType) {
                      setEventDetail({
                        ...eventDetail,
                        category: [...eventDetail.category, ...selType],
                        subCategory: [
                          ...eventDetail.subCategory,
                          ...selType[0].subcategory.map((cat) => {
                            return cat._id;
                          }),
                        ],
                      });
                      e.target.value = "";
                      setEventDetailErr({ ...eventDetailErr, category: "" });
                    }
                  }}
                />
              </div>
              <span className="error cstm-error4">
                {eventDetailErr.category}
              </span>
            </div>
          </div>
          <div>
            {eventDetail.category.map((type) => {
              return (
                <span className="topic-label-in">
                  <b>{type.name}</b>
                  <button
                    onClick={() => {
                      setEventDetail({
                        ...eventDetail,
                        category: eventDetail.category.filter((typeFilter) => {
                          if (typeFilter.name !== type.name) return typeFilter;
                        }),
                      });
                    }}
                  >
                    ×
                  </button>
                </span>
              );
            })}
            <datalist id="category">
              {categoryData.map((item) => {
                if (
                  eventDetail.category.filter((category) => {
                    if (category.name === item.name) return category;
                  }).length === 0
                )
                  return <option>{item.name}</option>;
              })}
            </datalist>
            {eventDetail.category.length > 0 &&
              eventDetail.category.filter((cat) => {
                if (cat.subcategory) return cat;
              }).length > 0 ? (
              <div className="row">
                <div className="col-sm-12 form-group mb-0">
                  <div className="cstm-add-video-title-sub">
                    Select Subcategory
                  </div>
                  <div className="form-group-ctst">
                    {eventDetail.category.map((cat) => {
                      if (cat.subcategory)
                        return cat.subcategory.map((sCat) => {
                          return (
                            <label className="subcat-addvideo">
                              <input
                                type="checkbox"
                                name="subcategory"
                                value={sCat.name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSubcategories([
                                      ...subcategories,
                                      sCat._id,
                                    ]);
                                  } else {
                                    setSubcategories([
                                      ...subcategories.filter((cat) => {
                                        if (cat !== sCat._id) return cat;
                                      }),
                                    ]);
                                  }
                                }}
                                defaultChecked={
                                  subcategories.filter((cat) => {
                                    if (cat === sCat._id) return cat;
                                  }).length > 0
                                }
                              />
                              {sCat.name}
                            </label>
                          );
                        });
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div class="form-group">
            <div class="cstm-channel-type-title partner-icon-rf-only">
              Event Status*
              <a
                className="status-undo"
                onClick={() =>
                  setEventDetail({ ...eventDetail, status: "draft" })
                }
              >
                <img src={undo} />
              </a>
            </div>
            <div class="cstm-pm-list">
              <label class="subcat-addvideo">
                <input
                  type="radio"
                  value="paused"
                  name="status"
                  checked={eventDetail.status === "paused"}
                  onChange={handleEventDetailChange}
                />
                Pause
              </label>
              <label class="subcat-addvideo">
                {" "}
                <input
                  type="radio"
                  value="published"
                  name="status"
                  checked={eventDetail.status === "published"}
                  onChange={handleEventDetailChange}
                />
                Live
              </label>
            </div>
            <span className="error cstm-pm-list-msg">
              {eventDetailErr.status}
            </span>
          </div>
          {/* <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input type="checkbox" name="chk-islocation" id="chk-islocation" checked={isLocationCompulsory} onChange={handleCheckLocationChange} />
                <label for="chk-islocation">Do you want to add location?</label>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Short Description*</div>
              <div className="cstm-editor-tool-ds">
                <textarea
                  value={eventDetail.shortDescription}
                  name="shortDescription"
                  onChange={handleEventDetailChange}
                  maxLength={500}
                />
              </div>
              <span className="error">{eventDetailErr.shortDescription}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Long Description*</div>
              <div className="cstm-editor-tool-ds">
                <TinyEditor
                  valueRef={longDescriptionRef}
                  value={eventDetail.longDescription}
                  handleEditorChange={(value, editor) => {
                    setEventDetailErr({
                      ...eventDetailErr,
                      longDescription: "",
                    });
                  }}
                  handleKeyPress={() => {
                    dispatch({ type: "SETMODALINPROCESS" });
                  }}
                />
              </div>
              <span className="error">{eventDetailErr.longDescription}</span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="chk-preregistration"
                  id="chk-preregistration"
                  checked={eventDetail.isPreRegister}
                  onChange={handleCheckPreRegisterationChange}
                />
                <label for="chk-preregistration">
                  Do you want to proceed with Pre Registration form?
                </label>
              </div>
            </div>
          </div>
          {eventDetail.isPreRegister && (
            <>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Title{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="preRegisterTitle"
                      value={eventDetail.preRegisterTitle}
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterTitle}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Description{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <textarea
                      name="preRegisterDescription"
                      placeholder="Enter description"
                      value={eventDetail.preRegisterDescription}
                      row="5"
                      col="5"
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterDescription}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Button Title{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter title"
                      name="preRegisterBtnTitle"
                      value={eventDetail.preRegisterBtnTitle}
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterBtnTitle}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Button Link{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="preRegisterBtnLink"
                      placeholder="Enter button link"
                      value={eventDetail.preRegisterBtnLink}
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterBtnLink}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    Start Date{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      placeholder="Select start date"
                      name="preRegisterStartDate"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterStartDate}
                    // max={moment(eventDetail.startDate).subtract(2,'days').format("YYYY-MM-DD")}
                    // min={moment(new Date()).format("YYYY-MM-DD")}
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterStartDate}
                  </span>
                </div>
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    Start Time{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="time"
                      placeholder="Select start time"
                      name="preRegisterStartTime"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterStartTime}
                      disabled={
                        eventDetail.preRegisterStartDate.length === 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterStartTime}
                  </span>
                </div>
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    End Date{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      placeholder="Select end date"
                      name="preRegisterEndDate"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterEndDate}
                      disabled={
                        eventDetail.preRegisterStartDate.length === 0
                          ? true
                          : false
                      }
                      min={moment(eventDetail.preRegisterStartDate).format(
                        "YYYY-MM-DD"
                      )}
                    // max={moment(eventDetail.startDate).subtract(2).format("YYYY-MM-DD")}
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterEndDate}
                  </span>
                </div>
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    End Time{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="time"
                      placeholder="Select end time"
                      name="preRegisterEndTime"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterEndTime}
                      disabled={
                        eventDetail.preRegisterEndDate.length === 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterEndTime}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="cyp-btm-btn">
            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button
                className="btn"
                onClick={() => {
                  handleEditEvent();
                }}
              >
                Update Event
              </button>
            )}
            {eventDetail.status === "draft" && (
              <>
                {saveInProcess ? (
                  <div className="loader-button1 save-draft-loader">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button className="btn ms-4" onClick={handleSaveDraft}>
                    Save as draft
                  </button>
                )}
              </>
            )}
            <button
              className="btn2"
              onClick={() => {
                dispatch({ type: "REMOVEMODALINPROCESS" });
                navigate("/events/list?search=");
              }}
            >
              Discard
            </button>
          </div>
        </>
      )}
      <>
        <AlertComponent />
      </>
      <div
        className="modal fade edit-user-details-popup"
        id="addtag"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create tag</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addtag").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showalertAdd && (
                <div className="alert alert-info">{alertmsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Tag name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter tags name"
                    name="tag"
                    onChange={handleAddChange}
                    value={addtag}
                  />
                </div>
                <span className="error">{adderr}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreateCategory
        handleClearAllStates={handleClearAllStates}
        listingApi={getCategoriesListApi}
      />
    </>
  );
};
export default EditEventInfo;
