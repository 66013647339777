import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setregidetail } from "../../../Action";
import Services from "../../../Services/auth.service"
// Import images
import logo from "../../../Images/logo.png";
import arrowleft from "../../../Images/arrow-left.png";
import Loader2 from "../../../Images/loader2.gif";
const ReviewConfirm = ({
  otherUserDetail,
  formFields,
  setformStep
}) => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const [curr_location, setcurr_location] = useState({longitude: 0, latitude: 0 })
  const redu_register = useSelector((state) => {
    return state.ReduRegistrationDetail;
  })
  const [isProcessing, setProcessingTo] = useState(false);
  useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position,"position")
      setcurr_location({longitude: position.coords.longitude, latitude: position.coords.latitude })
    })
  },[])
  const handlesaveandnext = async () => {
    setProcessingTo(true);
      var data = {
        otherdetail: {},
        userId: redu_register.userid,
      }
      for (let userDetailkey in otherUserDetail) {
        if (typeof otherUserDetail[userDetailkey] === "object") {
          if (typeof otherUserDetail[userDetailkey][0] === "string") {
            data.otherdetail[userDetailkey] =
            otherUserDetail[userDetailkey];
          } else {
            if(otherUserDetail[userDetailkey] !== null && otherUserDetail[userDetailkey] !== undefined )
            {
              var formData = new FormData();
              formData.append(
                "multi_question_files",
                otherUserDetail[userDetailkey]
              );
              var response = await Services.uploadfiles(formData);
              data.otherdetail[userDetailkey] =
                process.env.REACT_APP_AWS_IMG_VID_PATH +
                response.data.data[0];
            }
            else{
              data.otherdetail[userDetailkey] = "";
            }
          }
        } else {
          if (
            userDetailkey !== "preferredEmail" 
          )
            data.otherdetail[userDetailkey] =
            otherUserDetail[userDetailkey];
        }
      }
      data.latitude = curr_location.latitude
      data.longitude = curr_location.longitude
    Services.saveuserpersonaldetail(data).then((res) => {
      if(res.data.status)
      {
        var userdata = {
          userid: res.data.data._id,
          provider: res.data.data.provider,
          personalDetail_status: res.data.data.personalDetail_status,
          register_status: res.data.data.register_status,
          payment_status: res.data.data.payment_status,
          QA_status: res.data.data.QA_status,
          emailid: res.data.data.email,
        }
        dispatch(setregidetail(userdata));   
        history("/firststepregister/4");
      }
      setProcessingTo(false);
    }).catch((e) => {
      setProcessingTo(false);
      console.log(e)
    })
  }
  return (
    <>
      {/* Display user input in registration form */}
      <div className="um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>
                <div className="um-left-in-mn">
                  <div className="um-title2">Registration</div>
                  <div className="um-text">
                    Please provide the best details to personally reach you.
                    This is used for event invites and other important updates.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 um-right">
              <div className="um-right-in2">
                <div className="um-back">
                  <button
                    className="back-btn"
                    onClick={() => {
                      setformStep(1);
                    }}
                  >
                    <img src={arrowleft} /> Back
                  </button>
                </div>
                <div className="um-right-in-title">Review And Confirm</div>
                <div className="um-right-in-sub">
                  Please check your selected plan and personal information
                </div>
                <hr className="um-line" />
                {redu_register.provider !== undefined &&  redu_register.provider !== "auth0" ? <h1>You are signin with {redu_register.provider} credential</h1> : <></>}
                <div className="um-cstm-rac"> 
                  <div className="um-cstm-rac-in">
                    <div className="um-cstm-rac-in-label">Preferred Email*</div>
                    <div className="um-cstm-rac-in-content">
                      {otherUserDetail.preferredEmail}
                    </div>
                  </div>
                  {formFields.map((field) => {
                    return field.fields.map((f) => {
                        return (
                          <>
                            <div className="um-cstm-rac-in">
                              <div className="um-cstm-rac-in-label">
                                {f.label}
                                {f.required.length > 0 ? "*" : ""}
                              </div>
                              <div className="um-cstm-rac-in-content">
                                {f.type === "password"
                                  ? "********"
                                  : f.type === "file"
                                  ? otherUserDetail[f._id].name
                                  : typeof otherUserDetail[f._id] === "object" ? otherUserDetail[f._id].join(",") : otherUserDetail[f._id]}
                              </div>
                            </div>
                          </>
                        );
                      }
                    );
                  })}
                </div>

                <br />

                <div className="um-rgt-btn">
                  <button
                    className="btn3"
                    onClick={() => {
                      setformStep(2);
                    }}
                  >
                    Cancel
                  </button>
                  {isProcessing ? <>
                  <div className="um-rgt-btn-loading">
                      <span className="loading-btn-in2"><img src={Loader2} /></span>
                  <button className="btn2"  disabled={isProcessing} >
                  Please wait...
                  </button></div></> :   
                  <button
                  className="btn2"
                  onClick={() => {
                    handlesaveandnext();
                  }}
                >
                  Save and Next
                </button>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewConfirm;