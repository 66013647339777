const starchatuser = null ;

const ReduStarChat = (state = starchatuser, action) => {
  switch (action.type) {
    case "UPDATESTARCHAT":
        if(state.includes(action.payload))
        {
            return state.filter((star)=>{
                if(action.payload !== star)
                    return star
            });
        }
        else {
            return [...state, action.payload]
        }
    case "FETCHSTARCHAT":
      return action.payload;
    default:
      return state;
  }
};

export default ReduStarChat;
