import { useEffect, useState } from "react";
import Services from "../../Services/auth.service";
import shortLefticon from "../../Images/short_left-icon.svg";


// icons
import CloseFillIcon from "remixicon-react/CloseFillIcon";




const UpgradePlanPopup = ({setopen,upgradeselectedPlan,updatePlan}) => {
    const [allplan, setallplan] = useState([])
    const [selectedplan, setselectedplan] = useState('')
    const [err , setErr] = useState('')
    useEffect(()=>{
        Services.getallplanatregis().then((res)=>{
        if(res.data.status)
        {
            setallplan(res.data.data)
        }
        }).catch((e)=>{
        console.log(e)
        })
      },[])
    useEffect(()=>{
      console.log(upgradeselectedPlan)
      setselectedplan(upgradeselectedPlan)
    },[upgradeselectedPlan])
  return (
    <div className="desk-globle-model-box globle-model-box">
      <div className="desk-globle-model-box-inner">
        <button
          className="globle-back-btn"
          onClick={() => {
            setopen(false);
          }}
        >
          <CloseFillIcon size="24px" />
        </button>
        <div className="um-member-step um-meme-det-only">
          {/* <span className="error">{err}</span> */}

          <h2 className="globle-hsk"> Upgrade Your Plan</h2>
          <div className="um-member-step-in um-membership-details">
            {allplan.length > 0 &&
              allplan.map((plan) => {
                return (
                  <div
                    className={
                      selectedplan !== undefined &&
                      selectedplan._id === plan._id
                        ? "um-membership-details-in selectedplan"
                        : "um-membership-details-in"
                    }
                    onClick={() => {
                      if (selectedplan !== undefined) {
                        setErr("");
                        setselectedplan(plan);
                      }
                    }}
                  >
                    {/* <div className="um-save">
                            Save
                            <br />
                            14%
                          </div> */}
                    <div className="um-membership-details-in-lable um-annual-payment">
                      {plan.recurring_timeframe} Payment
                    </div>
                    <div className="um-membership-details-in-price">
                      <sup>$</sup>
                      <span className="um-md-price">
                        {plan.plan_price.toLocaleString()}
                      </span>
                    </div>
                    {plan.automatic_renewal ? (
                      <p>Automatic renewal (recurring payments)</p>
                    ) : (
                      <></>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: plan.plan_description,
                      }}
                    ></div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="up-sbmit-btn">
          <button
            className="btn2"
            onClick={() => {
              updatePlan(selectedplan);
              setopen(false);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default UpgradePlanPopup;
