const totalmessages = {};

const ReduTotalMessages = (state = totalmessages, action) => {
  switch (action.type) {
    case "SETTOTALMESSAGES":
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export default ReduTotalMessages;
