import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
//import header file
import authAdminHeader from "../../Services/auth.admin.header";
import {
  fetchpostbygroupid_admin
} from "../../Action/index.js";
//import components
import UploadImages from "./UploadImages";
import UploadVideos from "./UploadVideos";
//import images
import File1 from "../../Images/image1.png";
import Feelings from "../../Images/feelings.svg";
import Topics from "../../Images/topics.svg";
import Dotted from "../../Images/dotted.svg";
import Tagfrnd from "../../Images/user-add-line.svg";
import Barchart from "../../Images/bar-chart-fill.svg";
import Backarrow from "../../Images/back-arrow.svg";
import Search from "../../Images/search.svg";
import Plusadd from "../../Images/plus-add.svg";
import Delete from "../../Images/delete.svg";
import noMediaFound from "../../Images/nomediafound.svg";
const AddPost = ({ setposterr, setprogbarVal, selectedGid }) => {
  const dispatch = useDispatch()
  const API_URL = process.env.REACT_APP_API_URL;
  const formData = new FormData();
  const descref = useRef();
  const [post, setpost] = useState({
    desc: "",
    imgs: [],
    videos: [],
    likes: 0,
    feelings: "",
    topics: [],
    polloption: [],
    polldur: "",
    pollview: 0,
    status: "",
    tagfrnd: [],
    makeAnnouncement: false,
    hideFromFeed: false
  });
  const [showpoll, setshowpoll] = useState(false);
  const [option, setoption] = useState("");
  const [searchTopic, setsearchTopic] = useState("");
  const feelings = useSelector((state) => {
    return state.ReduActivityFeeling;
  });
  const topics = useSelector((state) => {
    var arr = []
    for(var i = 0; i < state.ReduTopics.length ; i++)
    {
      if(state.ReduTopics[i].numberOfGroup.includes(selectedGid))
        arr[i] =  state.ReduTopics[i]
    }
    return arr;
  });
  const [friends, setfriends] = useState([]);
  const [err, setErr] = useState("");
  const days = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    "No time limit",
  ];
  useEffect(() => {
    descref.current.style.height = "10px";
    descref.current.style.height = descref.current.scrollHeight + "px";
  }, [descref.current, post.desc]);
  const remove = (file) => {
    var arr = post.imgs;
    arr.splice(file, 1);
    setpost({
      ...post,
      imgs: arr,
    });
  };
  const removevideo = (file) => {
    console.log(file);
    var arr = post.videos;
    arr.splice(file, 1);
    setpost({
      ...post,
      videos: arr,
    });
  };

  const handleChange = (e) => {
    setErr("");
    if(e.target.type === "checkbox")
      setpost({ ...post, [e.target.name]: e.target.checked });
    else  
      setpost({ ...post, [e.target.name]: e.target.value });
  };

  const changeFeeling = (id) => {
    setErr("");
    setpost({ ...post, feelings: id });
  };

  const handlePublish = () => {
    var empty = true;
    Object.keys(post).map(function (key) {
      if (post[key].length > 0) empty = false;
    });
    if (!empty) {
      formData.append("groupId" , selectedGid)
      if (
        post.polloption.length > 0 &&
        (post.desc.length === 0 || post.polldur.length === 0)
      ) {
        setErr("Poll must have description and poll duration!");
        setposterr({
          status: false,
          msg: "Poll must have description and poll duration!",
        });
        setTimeout(() => {
          setposterr({ status: false, msg: "" });
        }, 3000);
        return;
      }
      formData.append("postedBy", localStorage.getItem("userid"));
      if (post.topics.length > 0) {
        for (var i = 0; i < post.topics.length; i++) {
          formData.append(`topics[]`, post.topics[i]);
        }
      }
      else{
        setErr("Select topics!");
        setposterr({
          status: false,
          msg: "Select topics!!",
        });
        setTimeout(() => {
          setposterr({ status: false, msg: "" });
        }, 3000);
        return;
      }
      if (post.imgs.length > 0) {
        for (var i = 0; i < post.imgs.length; i++) {
          formData.append(`images`, post.imgs[i]);
        }
      } else if (post.polloption.length === 0) {
        formData.append(`images`, []);
      }

      if (post.videos.length > 0) {
        for (var i = 0; i < post.videos.length; i++) {
          formData.append(`videos`, post.videos[i]);
        }
      } else if (post.polloption.length === 0) {
        formData.append(`videos`, []);
      }
      formData.append("description", post.desc);
      if (post.feelings.length > 0)
        formData.append("feelingsActivity", post.feelings);
      if (post.status.length > 0) formData.append("postStatus", post.status);
      
      if (post.polloption.length > 0) {
        for (var i = 0; i < post.polloption.length; i++) {
          formData.append(`pollChoices[${i}][value]`, post.polloption[i]);
        }
      }
      if (post.polldur.length > 0)
        formData.append("pollDuration", post.polldur);
      post.polloption.length > 0
        ? formData.append("postType", "Poll")
        : formData.append("postType", "Post");
     if(post.makeAnnouncement !== undefined)
        formData.append("makeAnnouncement", post.makeAnnouncement)
      if(post.hideFromFeed !== undefined)
        formData.append("hideFromFeed", post.hideFromFeed)
      formData.append("user_type", "adminuser")
      axios
        .post(API_URL + "AS/post/create", formData, {
          headers: authAdminHeader(),
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setprogbarVal(percentCompleted + "%");
          },
        })
        .then((res) => {
          if (res.data.status) {
            setposterr({ status: false, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
            var data = {groupid: selectedGid , page: 1}
            dispatch(fetchpostbygroupid_admin(data))
          } else {
            setposterr({ status: true, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
      setErr("");
      setpost({
        desc: "",
        imgs: [],
        videos: [],
        likes: 0,
        feelings: "",
        topics: [],
        polloption: [],
        polldur: "",
        pollview: 0,
        status: "",
        tagfrnd: [],
      });
      setshowpoll(false);
    } else {
      setErr("Empty post can't be published!");
      setposterr({ status: false, msg: "Empty post can't be published!" });
      setTimeout(() => {
        setposterr({ status: false, msg: "" });
      }, 3000);
    }
  };

  return (
    <>
      <div
        className="modal admin-create-post create-popup-post"
        id="adminaddpost"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="create-post-content-inner-main">
              <div className="create-post-content-inner-sub">
                <div className="responsive-cr-heading-sec">
                  <div className="responsive-cr-heading-in">
                    <img className="cr-pp-img" src={File1} />
                    <span className="cr-pp-name">
                      {localStorage.getItem("username")}
                    </span>
                  </div>
                  <div>
                    <button
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        // setInprocess(false);
                        setErr("");
                        setpost({
                          desc: "",
                          imgs: [],
                          videos: [],
                          likes: 0,
                          feelings: "",
                          topics: [],
                          polloption: [],
                          polldur: "",
                          pollview: 0,
                          status: "",
                        });
                        setshowpoll(false);
                      }}
                    >
                      ✕
                    </button>
                  </div>
                </div>
                <div className="create-ps-title res-create-ps-title">
                  <div className="create-ps-title-title ccstm-creatr-post-main">
                    <h4>Create Post</h4>
                  </div>
                  <div className="create-ps-title-close">
                    <button
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setErr("");
                        setpost({
                          desc: "",
                          imgs: [],
                          videos: [],
                          likes: 0,
                          feelings: "",
                          topics: [],
                          polloption: [],
                          polldur: "",
                          pollview: 0,
                          status: "",
                          tagfrnd: [],
                        });
                        setshowpoll(false);
                      }}
                    >
                      ✕
                    </button>
                  </div>
                </div>

                <div className="create-pp-post csm-main-pp-post res-csm-main-pp-post">
                  <div className="create-pp-post-left">
                    <img className="cr-pp-img" src={File1} />{" "}
                    <span className="cr-pp-name">
                      {localStorage.getItem("username")}
                    </span>
                  </div>
                  <div className="create-pp-post-right">
                    <div className="create-pp-post-cont">
                      <div className="create-pp-post-cont-text cstm-create-pp-post-cont-text">
                        {post.feelings.length > 0 ? (
                          <div className="feelings-emoji">
                            <div className="feelings-emoji-icon">
                              {post.feelings.length > 0
                                ? feelings.filter(
                                    (feelings) => feelings._id === post.feelings
                                  )[0].emoji +
                                  " " +
                                  feelings.filter(
                                    (feelings) => feelings._id === post.feelings
                                  )[0].feeling
                                : ""}
                            </div>
                            <div
                              className="feelings-emoji-close"
                              onClick={() => {
                                setpost({ ...post, feelings: "" });
                              }}
                            >
                              ×
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <textarea
                          ref={descref}
                          name="desc"
                          onChange={handleChange}
                          value={post.desc}
                          placeholder="What is happening?"
                        >
                          {" "}
                          {post.desc}{" "}
                        </textarea>

                        {post.topics.length > 0 ? (
                          <div className="topics-search-label">
                            {post.topics.map((topic) => {
                              return (
                                <span className="topics-search-label-in">
                                  {
                                    topics.filter((tpc) => tpc._id === topic)[0]
                                      .topic
                                  }
                                  <button
                                    onClick={() => {
                                      setpost({
                                        ...post,
                                        topics: post.topics.filter((topc) => {
                                          if (topc !== topic) return topc;
                                        }),
                                      });
                                    }}
                                  >
                                    ×
                                  </button>
                                </span>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          style={
                            showpoll
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div className="add-poll-main">
                            <div className="add-poll-title">Add Poll</div>
                            <div className="add-poll-main-box">
                              <input
                                type="text"
                                name="option"
                                onChange={(e) => {
                                  setoption(e.target.value);
                                }}
                                value={option}
                              />
                              <button
                                className="plus-add-btn"
                                onClick={(e) => {
                                  setpost({
                                    ...post,
                                    polloption:
                                      option.length > 0
                                        ? [...post.polloption, option]
                                        : [...post.polloption],
                                  });
                                  setoption("");
                                }}
                              >
                                <img src={Plusadd} /> Add
                              </button>
                            </div>

                            {post.polloption.map((opt) => {
                              return (
                                <>
                                  <div className="add-poll-main-box2">
                                    <input type="text" value={opt} disabled />
                                    <button
                                      className="addpoll-delete"
                                      onClick={() => {
                                        setpost({
                                          ...post,
                                          polloption: post.polloption.filter(
                                            (option) => option !== opt
                                          ),
                                        });
                                      }}
                                    >
                                      <img src={Delete} />
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div
                        style={
                          post.polloption.length > 0
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label>Poll Duration</label>
                        <select
                          name="polldur"
                          onChange={handleChange}
                          value={post.polldur}
                        >
                          <option value=""> Select Days </option>
                          {days.map((d) => {
                            return (
                              <option value={d}>
                                {d}{" "}
                                {d === 1
                                  ? "Day"
                                  : d !== "No time limit"
                                  ? "Days"
                                  : ""}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {[...post.imgs, ...post.videos].length > 0 ? (
                        [...post.imgs, ...post.videos].length === 1 ? (
                          [...post.imgs, ...post.videos][0].type.indexOf(
                            "image"
                          ) !== -1 ? (
                            <div className="create-pp-post-img1">
                              <button
                                className="bx-close"
                                onClick={() => remove(0)}
                              >
                                ✕
                              </button>
                              <img
                                src={URL.createObjectURL(
                                  [...post.imgs, ...post.videos][0]
                                )}
                              />
                            </div>
                          ) : (
                            <div className="create-pp-post-img1">
                              <button
                                className="bx-close"
                                onClick={() => removevideo(0)}
                              >
                                ✕
                              </button>
                              <video
                                src={URL.createObjectURL(
                                  [...post.imgs, ...post.videos][0]
                                )}
                                controls={true}
                              />
                            </div>
                          )
                        ) : [...post.imgs, ...post.videos].length === 2 ? (
                          <div className="create-pp-post-img2">
                            {[...post.imgs, ...post.videos][0].type.indexOf(
                              "image"
                            ) !== -1 ? (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() => remove(0)}
                                >
                                  ✕
                                </button>
                                <img
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                />
                              </div>
                            ) : (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() => removevideo(0)}
                                >
                                  ✕
                                </button>
                                <video
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                  controls={true}
                                />
                              </div>
                            )}
                            {[...post.imgs, ...post.videos][1].type.indexOf(
                              "image"
                            ) !== -1 ? (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() => remove(1)}
                                >
                                  ✕
                                </button>
                                <img
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][1]
                                  )}
                                />
                              </div>
                            ) : (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() =>
                                    removevideo(
                                      [...post.imgs, ...post.videos].length -
                                        post.imgs.length -
                                        1
                                    )
                                  }
                                >
                                  ✕
                                </button>
                                <video
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][1]
                                  )}
                                  controls={true}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="create-pp-post-img3">
                            {[...post.imgs, ...post.videos][0].type.indexOf(
                              "image"
                            ) !== -1 ? (
                              <div className="create-pp-post-img3-left">
                                <button
                                  className="bx-close"
                                  onClick={() => remove(0)}
                                >
                                  ✕
                                </button>
                                <img
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                />
                              </div>
                            ) : (
                              <div className="create-pp-post-img3-left">
                                <button
                                  className="bx-close"
                                  onClick={() => removevideo(0)}
                                >
                                  ✕
                                </button>
                                <video
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                  controls={true}
                                />
                              </div>
                            )}
                            <div className="create-pp-post-img3-right">
                              {[...post.imgs, ...post.videos][1].type.indexOf(
                                "image"
                              ) !== -1 ? (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() => remove(1)}
                                  >
                                    ✕
                                  </button>
                                  <img
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][1]
                                    )}
                                  />
                                </div>
                              ) : (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() =>
                                      removevideo(
                                        [...post.imgs, ...post.videos].length -
                                          post.imgs.length -
                                          1
                                      )
                                    }
                                  >
                                    ✕
                                  </button>
                                  <video
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][1]
                                    )}
                                    controls={true}
                                  />
                                </div>
                              )}
                              {[...post.imgs, ...post.videos][2].type.indexOf(
                                "image"
                              ) !== -1 ? (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() => remove(2)}
                                  >
                                    ✕
                                  </button>
                                  <img
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][2]
                                    )}
                                  />
                                  {[...post.imgs, ...post.videos].length > 3 ? (
                                    <div
                                      className="create-pp-cunt"
                                      data-toggle="modal"
                                      data-target="#imgs"
                                    >
                                      {[...post.imgs, ...post.videos].length -
                                        3}
                                      +
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() =>
                                      removevideo(
                                        [...post.imgs, ...post.videos].length -
                                          post.imgs.length -
                                          1
                                      )
                                    }
                                  >
                                    ✕
                                  </button>
                                  <video
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][2]
                                    )}
                                    controls={true}
                                  />
                                  {[...post.imgs, ...post.videos].length > 3 ? (
                                    <div
                                      className="create-pp-cunt"
                                      data-toggle="modal"
                                      data-target="#imgs"
                                    >
                                      {[...post.imgs, ...post.videos].length -
                                        3}
                                      +
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="create-pp-post-btn">
                      <UploadImages
                        post={post}
                        setpost={setpost}
                        edit={false}
                        setErr={setErr}
                        showpoll={showpoll}
                      />
                      <UploadVideos
                        post={post}
                        setpost={setpost}
                        edit={false}
                        setErr={setErr}
                        showpoll={showpoll}
                      />

                      <span className="cp-btn">
                        <button
                          className="cp-btn-feelingn"
                          data-toggle="modal"
                          data-target="#adminfeeling"
                        >
                          <img src={Feelings} />
                          Feelings / Activity
                        </button>
                      </span>
                      <span className="cp-btn">
                        <button
                          className="cp-btn-topics"
                          data-toggle="modal"
                          data-target="#admintopics"
                        >
                          <img src={Topics} />
                          Topics
                        </button>
                      </span>
                      <span className="cp-btn cstm-cp-dp">
                        <button className="cp-btn-dotted">
                          <img src={Dotted} />
                        </button>
                        <div className="cstm-cp">
                          <ul>
                            <li
                              onClick={() => {
                                setshowpoll(true);
                                setpost({
                                  ...post,
                                  imgs: [],
                                  videos: [],
                                });
                              }}
                            >
                              <img src={Barchart} />
                              Create a Poll
                            </li>
                          </ul>
                        </div>
                      </span>
                    </div>

                    <div className="create-pp-post-public">
                      {/* <div className="create-pp-post-public-gl">
                    <select
                      name="status"
                      onChange={handleChange}
                      className="cp-public-select"
                    >
                      <option disabled selected value="">
                        Public/Private
                      </option>
                      <option>Public</option>
                      <option>Private</option>
                    </select>
                  </div> */}
                      <div className="make-hide-ck">
                        <div className="make-hide-ck-in">
                          <input
                            type="checkbox"
                            name="makeAnnouncement"
                            onChange={handleChange}
                          />
                          Make Annoucements
                        </div>
                        <div className="make-hide-ck-in">
                          <input
                            type="checkbox"
                            name="hideFromFeed"
                            onChange={handleChange}
                          />
                          Hide From Feed
                        </div>
                      </div>
                      <div className="create-pp-post-public-btn">
                        <button
                          className="btn"
                          onClick={handlePublish}
                          data-dismiss="modal"
                        >
                          Publish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>

      <div
        className="modal fade create-popup-post"
        id="adminfeeling"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="create-ps-title">
              <div className="create-ps-title-title">
                <button className="back-btn-mn" data-dismiss="modal">
                  <img src={Backarrow} />
                </button>
                What are you feeling today?
              </div>
              <div className="create-ps-title-close">
                <button className="close" data-dismiss="modal">
                  ✕
                </button>
              </div>
            </div>

            <div className="what-are-you-feeling">
              {feelings.map((feeling) => {
                return (
                  <span
                    className="wayf-main"
                    data-dismiss="modal"
                    onClick={() => {
                      changeFeeling(feeling._id);
                    }}
                  >
                    {feeling.emoji}
                    {feeling.feeling}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade create-popup-post"
        id="admintopics"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="create-ps-title">
              <div className="create-ps-title-title">
                <button className="back-btn-mn" data-dismiss="modal">
                  <img src={Backarrow} />
                </button>
                Topics
              </div>
              <div className="create-ps-title-close">
                <button className="close" data-dismiss="modal">
                  ✕
                </button>
              </div>
            </div>
            <div className="topics-search-bar">
              <input
                type="text"
                name="searchtopic"
                onChange={(e) => {
                  setsearchTopic(e.target.value);
                }}
                value={searchTopic}
              />
              <img src={Search} />
            </div>
            <div className="topics-search-label">
              {post.topics.map((topic, index) => {
                return (
                  <>
                    <span className="topics-search-label-in">
                      {topics.filter((tpc) => tpc._id === topic)[0].topic}
                      <button
                        onClick={() => {
                          setpost({
                            ...post,
                            topics: post.topics.filter(
                              (tpc, inx) => inx !== index
                            ),
                          });
                        }}
                      >
                        ✕
                      </button>
                    </span>
                  </>
                );
              })}
            </div>

            {
              <div className="topics-search-ovr">
                {topics
                  .filter((topics) => topics.topic.includes(searchTopic))
                  .map((filterdtopic) => {
                    return (
                      <div
                        className="topics-search-ovr-in"
                        onClick={() => {
                          setpost({
                            ...post,
                            topics:
                              post.topics.indexOf(filterdtopic._id) === -1
                                ? [...post.topics, filterdtopic._id]
                                : [...post.topics],
                          });
                        }}
                      >
                        <span className="tso-id">{filterdtopic.topic}</span>
                        <span className="tso-post">
                          {filterdtopic.totalPost} post
                        </span>
                      </div>
                    );
                  })}
              </div>
            }
          </div>
        </div>
      </div>

      <div
        className="modal fade msg-popup  create-popup-post edit-img-popup-post show"
        id="imgs"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered edit-img-modal-dialog"
          role="document"
        >
          <div className="modal-content">
            <div
              className={`edit-post-content-inner-main ${
                post.imgs.length + post.videos.length === 1
                  ? "editimgcount1"
                  : post.imgs.length + post.videos.length === 2
                  ? "editimgcount2"
                  : post.imgs.length + post.videos.length === 3
                  ? "editimgcount3"
                  : post.imgs.length + post.videos.length === 4
                  ? "editimgcount4"
                  : post.imgs.length + post.videos.length >= 5
                  ? "editimgcount5"
                  : "editimgcount0"
              }`}
            >
              <div className="edit-post-content-inner-sub">
                <div className="edit-img-inner-main">
                  {post.imgs.length === 0 && post.videos.length === 0 ? (
                    <div className="no-img-video">
                      <div>
                        <img src={noMediaFound} alt="noMediaFound" />
                        <p>Please add Image/Video</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {post.imgs.length > 0 &&
                    post.imgs.map((file, i) => (
                      <div className="list-group-item list-group-item-success">
                        <div
                          className="edit-cstm-img-inner-bg"
                          style={{
                            background: `url(${URL.createObjectURL(file)})`,
                          }}
                        ></div>
                        <img
                          src={URL.createObjectURL(file)}
                          // style={{ height: "50px", width: "50px" }}
                        />
                        <span
                          className="um-ulpoad-close"
                          onClick={() => remove(i, "add")}
                        >
                          ×
                        </span>
                      </div>
                    ))}

                  {post.videos.length > 0 &&
                    post.videos.map((file, i) => (
                      <div className="list-group-item list-group-item-success">
                        <div
                          className="edit-cstm-img-inner-bg"
                          style={{
                            background: `url(${URL.createObjectURL(file)})`,
                          }}
                        ></div>
                        <video
                          src={URL.createObjectURL(file)}
                          // style={{ height: "50px", width: "50px" }}
                        />
                        <span
                          className="um-ulpoad-close"
                          onClick={() => removevideo(i, "add")}
                        >
                          ×
                        </span>
                      </div>
                    ))}
                </div>
                <div className="edit-img-cstm-btn admin-edit-img-cstm-btn">
                  <div className="edit-img-cstm-btn-left">
                    <UploadImages
                      post={post}
                      setpost={setpost}
                      edit={false}
                      setErr={setErr}
                      showpoll={showpoll}
                    />
                    <UploadVideos
                      post={post}
                      setpost={setpost}
                      edit={false}
                      setErr={setErr}
                      showpoll={showpoll}
                    />
                  </div>
                  <div className="edit-img-cstm-btn-right">
                    <button className="btn" data-dismiss="modal">
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPost;
