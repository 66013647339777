import { useEffect, useState } from "react";
import moment from "moment";
import Global from "../../../Images/global.svg"; 
import Eyered from "../../../Images/eye-red.svg";
import Dummy from "../../../Images/dummy.jpg";

const About = ({groupmembers , groupDetail}) => {
   const [admin, setadmin] = useState({})
   useEffect(()=>{
      setadmin(groupmembers.filter((gm)=>{ if(gm.userId !== null && gm.userId.role === "admin") return gm})[0])
   },[groupmembers])
    return (
                <>
        <div className="pro-about"> 
          <div className="pro-title">About Group</div>
          <p>{groupDetail.groupInfo}</p>
         <div className="pro-about-list">
           <div className="pro-about-list-name"><img src={Global} />{groupDetail.groupType} group</div>
           {/* <div className="pro-about-list-cont">Anyone can see who's in the group and what they post.</div> */}
          </div>
         <div className="pro-about-list">
           <div className="pro-about-list-name"><img src={Eyered} />{groupDetail.groupVisibility === "Anyone" ? "Visible group" : "Invisible group" }</div>
           {/* <div className="pro-about-list-cont">{groupDetail.groupVisibility === "Anyone" ? "Anyone can find this group." : "Only admin can find this group." }</div> */}
          </div>
        </div>

        <div className="pro-about"> 
          <div className="pro-title">Admins & Moderators</div> 
          {admin !== undefined && admin !== null && Object.keys(admin).length !== 0 &&  
          <div className="pro-post-list-main">
            
          <div className="pro-post-list-main-in">  
              <div className="pro-post-list-main-in-admin">Admin</div>
              <div className="pro-post-list-main-in-user">
                 <div className="pro-post-list-main-in-user-left"><img src={Dummy} /></div>
                 <div className="pro-post-list-main-in-user-right">
                  <span className="pro-post-user-name">{admin.userId.otherdetail[process.env.REACT_APP_FIRSTNAME_DBID] + " " + admin.userId.otherdetail[process.env.REACT_APP_LASTNAME_DBID]}</span>
                  <span className="pro-post-time-date">Joined {moment(admin.updatedAt).fromNow()}</span>
                 </div>
              </div>
              {/* <div className="pro-post-list-main-in-cont">Lorem Ipsum is simply dummy text of the printing and type setting industry.</div>
              <div className="pro-post-list-main-in-hobbies">Hobbies</div>
              <div className="pro-post-list-main-in-tag">
                 <span className="pro-post-blue">Health/ Fiteness</span>
                 <span className="pro-post-green">Music</span>
              </div> */}
              <div className="pro-post-list-main-in-link"> 
                  <div className="pro-post-list-main-in-link-view"><button>View Details</button></div>
                  {/* <div className="pro-post-list-main-in-link-unfollow">Unfollow</div>  */}
              </div>
           </div>

      


          </div>}
        
        </div>
        </>
    )

}
export default About;

