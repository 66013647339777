import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import millify from "millify";
import moment from "moment";
import axios from "axios";
//import function from action file
import {
  fetchallpost,
  handleredulike,
  handlereduvote,
  addredcommt,
  deleteredcommt,
  fetchsavedpost,
  handlegrouplike,
  handlegroupvote,
  addgroupcommt,
  deletegroupcommt,
  fetchpostbygroupid,
  handlememberlike,
  addmembercommt,
  deletemembercommt,
  fetchmemberpost,
  fetchgrouptopics,
  fetchalltopics,
  handlesearchlike,
  fetchsearchpost,
  addsearchcommt,
  deletesearchcommt,
  handlemembervote,
  handlesearchvote,
} from "../../../Action/index.js";
//import api file
import Services from "../../../Services/auth.service";
//import header file
import authHeader from "../../../Services/auth.header";
//import components
import UploadImages from "./UploadImages";
import UploadVideos from "./UploadVideos";
import TagFriendPopup from "./TagFriendPopup.js";
import ImageGalleryPost from "./ImageGalleryPost.js";
import PostComments from "./PostComments.js";
import LoaderComponent from "../LoaderComponent.js";
import EditPostImgView from "./EditPostImgView.js";
import EditAllPix from "./EditAllPix.js";
import AllPostImgView from "./AllPostImgView.js"
import PostMainContent from "./PostMainContent.js";
//import images
import File1 from "../../../Images/image1.png";
import Backarrow from "../../../Images/back-arrow.svg";
import Search from "../../../Images/search.svg";
import Feelings from "../../../Images/feelings.svg";
import Topics from "../../../Images/topics.svg";
import Dotted from "../../../Images/dotted.svg";
import Tagfrnd from "../../../Images/user-add-line.svg";
import Barchart from "../../../Images/bar-chart-fill.svg";
import Yes from "../../../Images/yes.svg";
import Link from "../../../Images/link.svg";
import Save from "../../../Images/save.svg";
import Heart from "../../../Images/heart.svg";
import Message from "../../../Images/message.svg";
import Share from "../../../Images/share.svg";
import Delete2 from "../../../Images/delete2.svg";
import Edit from "../../../Images/edit.svg";
import Yesmsg from "../../../Images/yes-msg.svg";
import Alert from "../../../Images/alert.svg";
import Plusadd from "../../../Images/plus-add.svg";
import Delete from "../../../Images/delete.svg";
import fillheart from "../../../Images/fillheart.svg";
import bigdelimg from "../../../Images/BackendImages/delete-icon.svg";
import svdpostimg from "../../../Images/savedpost.svg";
import loading3 from "../../../Images/loading3.gif";
import noMediaFound from "../../../Images/nomediafound.svg";

const AllPost = ({
  posterr,
  setposterr,
  progbarVal,
  setprogbarVal,
  post_scope,
  userIsMember,
  topicSearch,
  SelectedGroup,
  memberid,
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const editref = useRef();
  const allmedia = useRef()
  const [post_allimg, setpost_allimg] = useState('')
  const search = useLocation().search;
  const topic_search = new URLSearchParams(search).get("topic_search");
  const text_search = new URLSearchParams(search).get("text");
  const user_search = new URLSearchParams(search).get("user");
  const topic_def = new URLSearchParams(search).get("topic");
  const API_URL = process.env.REACT_APP_API_URL;
  const formData = new FormData();
  const [usersvdpost, setsvdpost] = useState([]);
  const [sortby, setsortby] = useState("All");
  const USER_ID = localStorage.getItem("userid");
  const USER_NAME = localStorage.getItem("username");
  const [alleditimgvids, setalleditimgvids] = useState({ imgs: [], vids: [] });
  const [inprocess_save, setinprocess_save] = useState({});
  const [dispnodatafound, setdispnodatafound] = useState(false);
  const [cmtinprocess, setcmtinprocess] = useState(false);
  const [replyinprocess, setreplyinprocess] = useState({});
  const [rereplyinprocess, setrereplyinprocess] = useState({});


  const loader = useSelector((state) => {
    return state.ReduLoader;
  });
  var allpost = useSelector((state) => {
    if (post_scope === "all") {
      return state.ReduPost.allpost;
    } else if (post_scope === "group") return state.ReduGroupPost.allpost;
    else if (post_scope === "member") return state.ReduMemberPost;
    else if (post_scope === "search") return state.ReduSearchPost;
    else return state.ReduPost.allpost;
  });
  const feelings = useSelector((state) => {
    return state.ReduActivityFeeling;
  });
  var totalpost = useSelector((state) => {
    if (post_scope === "group") return state.ReduGroupPost.totalpost;
    else if (post_scope === "all") return state.ReduPost.totalpost;
  });
  const topics = useSelector((state) => {
    return state.ReduTopics;
  });
  const alltopics = useSelector((state) => {
    return state.ReduAllTopics;
  });
  const savedpost = useSelector((state) => {
    return state.ReduSavedPost;
  });
  const [postpage, setpostpage] = useState(1);
  const [delpostid, setdelpostid] = useState("");
  const [editId, seteditId] = useState("");
  const [editPost, seteditPost] = useState({});
  const [savedImg, setsavedImg] = useState([]);
  const [savedVid, setsavedVid] = useState([]);
  const [removeImg, setremoveImg] = useState([]);
  const [removeVid, setremoveVid] = useState([]);
  const [addImg, setaddImg] = useState([]);
  const [addVid, setaddVid] = useState([]);
  const [showpoll, setshowpoll] = useState(false);
  const [option, setoption] = useState("");
  const [searchTopic, setsearchTopic] = useState("");
  const [err, setErr] = useState("");
  const [showcinput, setshowcinput] = useState({});
  const [comment, setComment] = useState("");
  const [reply, setreply] = useState({});
  const [rereply, setrereply] = useState({});
  const [allimg, setallimg] = useState([]);
  const [allcomments, setallcomments] = useState([]);
  const [morecmt, setmorecmt] = useState(1);
  const [cmtloader, setcmtloader] = useState(false);
  const [totalcmt, settotalcmt] = useState(0);
  const [pageloader, setpageloader] = useState(false);
  const [friends, setfriends] = useState([]);
  const [allusers, setallusers] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [deletecmtInprocess, setdeletecmtInprocess] = useState({});

  const days = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    "No time limit",
  ];
  var cntcmt = 0;

  useEffect(() => {
    if (localStorage.getItem("idtoken") === undefined || localStorage.getItem("idtoken") === null || localStorage.getItem("idtoken").length === 0 || localStorage.getItem("userid") === null || localStorage.getItem("userid") === undefined ||
      localStorage.getItem("userid").length === 0 || localStorage.getItem("username") === null || localStorage.getItem("userid") === undefined || localStorage.getItem("username").length === 0 || localStorage.getItem("username") === undefined ||
      localStorage.getItem("role") === null || localStorage.getItem("role") !== 'user' || localStorage.getItem("logintime") === undefined ||
      localStorage.getItem("logintime") === null) {
      window.location.href = "/"
    }
    setpostpage(1);
    dispatch(fetchsavedpost());
    Services.getalluseremailname()
      .then((res) => {
        if (res.data.status) {
          setallusers(res.data.data);
          var arr = [];
          for (var i = 0; i < res.data.data.length; i++) {
            arr[i] =
              res.data.data[i].otherdetail[
              process.env.REACT_APP_FIRSTNAME_DBID
              ] +
              " " +
              res.data.data[i].otherdetail[process.env.REACT_APP_LASTNAME_DBID];
          }
          setSuggestionList(arr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // if(post_scope === undefined || post_scope === "all")
    //   dispatch(fetchalltopics())
    // else
    //   dispatch(fetchgrouptopics(SelectedGroup))
    window.addEventListener("scroll", handleScroll);
    if (editref.current !== null && editref.current !== undefined)
      editref.current.style.height = "10px";
  }, []);
  useEffect(() => {
    setpostpage(1);
  }, [SelectedGroup, topic_def]);
  useEffect(() => {
    if (pageloader && post_scope === "all" && allpost.length < totalpost) {
      dispatch(fetchallpost(postpage));
      setpageloader(false);
    } else if (
      pageloader &&
      post_scope === "group" &&
      allpost.length < totalpost
    ) {
      dispatch(fetchpostbygroupid({ groupid: SelectedGroup, page: postpage }));
      setpageloader(false);
    }

    // console.log("postpage" , postpage)
  }, [postpage]);
  useEffect(() => {
    if (savedpost.length > 0) {
      var arr = [];
      for (var i = 0; i < savedpost.length; i++) {
        arr[i] = savedpost[i]._id;
      }
      setsvdpost(arr);
    } else {
      setsvdpost([]);
    }
  }, [savedpost]);

  useEffect(() => {
    if (editref.current !== null && editref.current !== undefined) {
      editref.current.style.height = "10px";
      editref.current.style.height = editref.current.scrollHeight + "px";
    }
  }, [editref.current, editPost]);

  const handleScroll = () => {
    // if(allpost.length >= totalpost){
    //   setpageloader(false)
    // }
    // else{

    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      setpageloader(true);
    } else {
      setpageloader(false);
    }
    // }
  };
  useEffect(() => {
    if (pageloader && allpost.length < totalpost) {
      setpostpage(postpage + 1);
    }
  }, [pageloader]);

  const handleLike = (postid) => {
    if (post_scope === "all") dispatch(handleredulike(postid));
    else if (post_scope === "group") dispatch(handlegrouplike(postid));
    else if (post_scope === "member") dispatch(handlememberlike(postid));
    else if (post_scope === "search") dispatch(handlesearchlike(postid));
    Services.likepost(postid)
      .then((res) => {
        if (res.data.status) {

        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleSelectEdit = (postid) => {
    Services.getsinglepost(postid)
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          var pollarr = [];
          if (data.pollChoices.length > 0) {
            pollarr = data.pollChoices.map((choice) => {
              return choice.value;
            });
          }
          dispatch(fetchgrouptopics(data.groupId._id));
          if (data.tagAFriend.length > 0) {
            var arr_frnd = []
            for (var i = 0; i < data.tagAFriend.length; i++) {
              arr_frnd[i] = { id: data.tagAFriend[i]._id, name: data.tagAFriend[i].otherdetail[process.env.REACT_APP_FIRSTNAME_DBID] + " " + data.tagAFriend[i].otherdetail[process.env.REACT_APP_LASTNAME_DBID] }
            }
            setfriends(arr_frnd)
          }

          seteditPost({
            ...data,
            pollChoices: pollarr,
            topics: data.topics.map((t) => {
              return t._id;
            }),
            feelingsActivity:
              data.feelingsActivity !== undefined &&
                data.feelingsActivity !== null
                ? data.feelingsActivity._id
                : "",
          });
          var arr_imgs,
            arr_vids = [];
          if (data.images.length > 0) {
            arr_imgs = data.images.map((img) => {
              return process.env.REACT_APP_AWS_IMG_VID_PATH + img;
            });
            setsavedImg(arr_imgs);
          }
          if (data.videos.length > 0) {
            arr_vids = data.videos.map((video) => {
              return process.env.REACT_APP_AWS_IMG_VID_PATH + video;
            });
            setsavedVid(arr_vids);
          }
          setalleditimgvids({ imgs: [...arr_imgs], vids: [...arr_vids] });
          console.log(editPost);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const editremove = (id, type) => {
    if (type === "db") {
      var newarr = [...savedImg];
      newarr.splice(id, 1);
      setremoveImg([...removeImg, savedImg[id]]);
      setsavedImg(newarr);
    } else {
      var newarr = [...addImg];
      newarr.splice(id, 1);
      setaddImg(newarr);
    }
  };
  const editremovevideo = (id, type) => {
    if (type === "db") {
      var newarr = [...savedVid];
      newarr.splice(id, 1);
      setremoveVid([...removeVid, savedVid[id]]);
      setsavedVid(newarr);
    } else {
      var newarr = [...addVid];
      newarr.splice(id, 1);
      setaddVid(newarr);
    }
  };

  const handleChange = (e) => {
    setErr("");
    setposterr({ status: false, msg: "" });
    seteditPost({ ...editPost, [e.target.name]: e.target.value });
  };

  const changeFeeling = (id) => {
    setErr("");
    seteditPost({ ...editPost, feelingsActivity: id });
  };

  function closeOneModal(editpost) {

    // get modal
    const modal = document.getElementById(editpost);

    // change state like in hidden modal
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');

    // get modal backdrop
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove opened modal backdrop
    document.body.removeChild(modalBackdrops[0]);
  }

  const handlePublish = () => {
    var empty = true;
    Object.keys(editPost).map(function (key) {
      if (editPost[key].length > 0) empty = false;
    });
    if (!empty) {
      if (
        editPost.pollChoices.length > 0 &&
        (editPost.description.length === 0 ||
          editPost.pollDuration.length === 0)
      ) {
        setposterr({
          status: true,
          msg: "Poll must have description and poll duration!",
        });
        // setTimeout(() => {
        //   setposterr({ status: false, msg: "" });
        // }, 3000);
        return;
      }
      if (editPost.topics !== undefined && editPost.topics.length > 0) {
        for (var i = 0; i < editPost.topics.length; i++) {
          formData.append(`topics[]`, editPost.topics[i]);
        }
      } else {
        setposterr({
          status: true,
          msg: "Select topics!!",
        });
        // setTimeout(() => {
        //   setposterr({ status: false, msg: "" });
        // }, 3000);
        return;
      }
      formData.append("postedBy", USER_ID);
      if (removeImg.length > 0) {
        for (var i = 0; i < removeImg.length; i++) {
          formData.append(`removeImg[]`, removeImg[i]);
        }
      }

      if (removeVid.length > 0) {
        for (var i = 0; i < removeVid.length; i++) {
          formData.append(`removeVideo[]`, removeVid[i]);
        }
      }
      if (addImg.length > 0) {
        for (var i = 0; i < addImg.length; i++) {
          formData.append(`images`, addImg[i]);
        }
      }

      if (addVid.length > 0) {
        for (var i = 0; i < addVid.length; i++) {
          formData.append(`videos`, addVid[i]);
        }
      }
      formData.append("description", editPost.description);
      if (
        editPost.feelingsActivity !== undefined &&
        editPost.feelingsActivity.length > 0
      )
        formData.append("feelingsActivity", editPost.feelingsActivity);
      if (editPost.postStatus !== undefined && editPost.postStatus.length > 0)
        formData.append("postStatus", editPost.postStatus);
      else formData.append("postStatus", "Public");
      if (
        editPost.pollChoices !== undefined &&
        editPost.pollChoices.length > 0
      ) {
        for (var i = 0; i < editPost.pollChoices.length; i++) {
          formData.append(`pollChoices[${i}][value]`, editPost.pollChoices[i]);
        }
      }
      if (
        editPost.pollDuration !== undefined &&
        editPost.pollDuration.length > 0
      )
        formData.append("pollDuration", editPost.pollDuration);
      editPost.pollChoices !== undefined && editPost.pollChoices.length > 0
        ? formData.append("postType", "Poll")
        : formData.append("postType", "Post");
      var data = {
        editid: editId,
        data: formData,
      };
      formData.append("groupId", editPost.groupId._id);
      if (friends.length > 0) {

        for (var i = 0; i < friends.length; i++) {
          formData.append(`tagAFriend[${i}]`, friends[i].id)
        }
      } else {
        formData.append("removealltagAFriend", true)
      }
      formData.append("user_type", "user");
      formData.append("postId", data.editid);
      if (posterr.status === false) {
        closeOneModal('editpost');
      }
      axios
        .put(API_URL + "post/edit", data.data, {
          headers: authHeader(),
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setprogbarVal(percentCompleted + "%");
          },
        })
        .then((res) => {
          if (res.data.status) {
            if (post_scope === "group") {
              dispatch(fetchpostbygroupid({ groupid: SelectedGroup, page: 1 }));
            } else if (post_scope === "all") {
              dispatch(fetchallpost(1));
            } else if (post_scope === "search") {
              dispatch(fetchsearchpost({ text: text_search, user: user_search, topic: topic_search }))
            }
            setposterr({ status: false, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
          } else {
            setposterr({ status: true, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
          }
          setErr("");
          seteditPost({});
          setshowpoll(false);
          setaddImg([]);
          setaddVid([]);
          setremoveImg([]);
          setremoveVid([]);
          setsavedImg([]);
          setsavedVid([]);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setErr("Empty post can't be published!");
      setposterr({ status: true, msg: "Empty post can't be published!" });
      // setTimeout(() => {
      //   setposterr({ status: false, msg: "" });
      // }, 3000);
    }
  };

  const fetchcomments = (postid) => {
    Services.getallcomments({ postid: postid, page: morecmt })
      .then((res) => {
        if (res.data.status) {
          var data =
            res.data.data[0].comment !== undefined
              ? res.data.data[0].comment.map((comm) => {
                var reply = [];
                var rereply = [];
                if (comm.comments.length > 0) {
                  reply = comm.comments.map((reply) => {
                    if (reply.comments.length > 0) {
                      rereply = reply.comments.map((r) => {
                        return {
                          ...r,
                          likes: { count: r.likes.length, list: r.likes },
                        };
                      });
                    }
                    return {
                      ...reply,
                      likes: { count: reply.likes.length, list: reply.likes },
                      comments: rereply,
                    };
                  });
                }
                return {
                  ...comm,
                  likes: { count: comm.likes.length, list: comm.likes },
                  comments: reply,
                };
              })
              : [];
          setallcomments(data);
          settotalcmt(res.data.data[0].totalPosts);
        } else {
          setallcomments([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleComment = (id) => {
    setmorecmt(1);
    showcinput[id] !== undefined && showcinput[id]
      ? setshowcinput({ [id]: false })
      : setshowcinput({ [id]: true });
    fetchcomments(id);
  };

  const addcomment = (postid, commid) => {

    setcmtinprocess(true)
    if (commid.length === 0 && comment.length > 0) {
      let data = {
        postId: postid,
        data: {
          content: comment,
          userId: USER_ID,
          user_type: "user",
          createdAt: new Date()
        },
      };
      Services.addcomment(data)
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            setComment("");
            if (post_scope === "all")
              dispatch(
                addredcommt({ postid: postid, commid: res.data.data._id })
              );
            else if (post_scope === "group") {
              dispatch(
                addgroupcommt({ postid: postid, commid: res.data.data._id })
              );
            } else if (post_scope === "member") {
              dispatch(
                addmembercommt({ postid: postid, commid: res.data.data._id })
              );
            } else if (post_scope === "search") {
              dispatch(addsearchcommt({ postid: postid, commid: res.data.data._id }))
            }
            fetchcomments(postid);
          } else {
            setposterr({ status: true, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
          }
          setcmtinprocess(false)
        })
        .catch((e) => {
          setcmtinprocess(false)
          console.log(e);
        });
    } else {
      if (
        (reply[commid] !== undefined && reply[commid].length > 0) ||
        (rereply[commid] !== undefined && rereply[commid].length > 0)
      ) {
        setcmtinprocess(false)
        setreplyinprocess({ ...replyinprocess, [commid]: true })
        setrereplyinprocess({ ...rereplyinprocess, [commid]: true })

        var data = {
          postId: postid,
          commId: commid,
          user_type: "user",
          createdAt: new Date(),
          content:
            reply[commid] !== undefined && reply[commid].length > 0
              ? reply[commid]
              : rereply[commid],
        };
        Services.createreply(data)
          .then((res) => {
            setreply({ ...reply, [commid]: "" });
            setrereply({ ...rereply, [commid]: "" });
            // dispatch(fetchallpost());
            fetchcomments(postid);
            // setcmtinprocess(false)
            setreplyinprocess({ ...replyinprocess, [commid]: false })
            setrereplyinprocess({ ...rereplyinprocess, [commid]: false })

          })
          .catch((e) => {
            // setcmtinprocess(false)
            setreplyinprocess({ ...replyinprocess, [commid]: false })
            setrereplyinprocess({ ...rereplyinprocess, [commid]: false })

            console.log(e);
          });
      } else {
        setreplyinprocess({ ...replyinprocess, [commid]: false })
        setrereplyinprocess({ ...rereplyinprocess, [commid]: false })

        setcmtinprocess(false)
      }
    }
  };


  // ----------------------------------

  const likecomment = (postid, commid, type) => {
    var data = {
      postid: postid,
      commId: commid,
    };

    var data = allcomments.map((comm) => {
      const USERID = localStorage.getItem("userid");
      var reply = [];
      var rereply = [];
      if (comm.comments.length > 0) {

        if (comm.comments[0].comments.length > 0) {
          if (type === "rereply") {
            rereply = comm.comments[0].comments.map((rereply) => {
              if (rereply._id === commid) {
                if (rereply.likes.list.includes(USERID)) {
                  
                  return {
                    ...rereply,
                    likes: {
                      count: rereply.likes.count - 1,
                      list: rereply.likes.list.filter((lik) => {
                        if (lik !== USERID) return lik;
                      }),
                    },
                    // comments: rereply,
                  };
                } else {
                  return {
                    ...rereply,
                    likes: {
                      count: rereply.likes.count + 1,
                      list:
                      rereply.likes.count > 0
                      ? [...rereply.likes.list, USERID]
                      : [USERID],
                    },
                    // comments: rereply,
                    
                  };
                }
              } else {
                return {
                  ...rereply,
                  likes: {
                    count: rereply.likes.count,
                    list: rereply.likes.list,
                  },
                  // comments: rereply,
                };
              }
            });
          } else {
            rereply = [...comm.comments[0].comments];
          }
          // console.log(rereply,"rereply 123");
          reply = [{...comm.comments[0],comments: rereply }];
        }

        if (type === "reply") {
          reply = comm.comments.map((reply) => {
            if (reply._id === commid) {
              if (reply.likes.list.includes(USERID)) {
                return {
                  ...reply,
                  likes: {
                    count: reply.likes.count - 1,
                    list: reply.likes.list.filter((lik) => {
                      if (lik !== USERID) return lik;
                    }),
                  },
                  // comments: rereply,
                };
              } else {
                return {
                  ...reply,
                  likes: {
                    count: reply.likes.count + 1,
                    list:
                      reply.likes.count > 0
                        ? [...reply.likes.list, USERID]
                        : [USERID],
                  },
                  // comments: rereply,
                };
              }
            } else {
              return {
                ...reply,
                likes: {
                  count: reply.likes.count,
                  list: reply.likes.list,
                },
                // comments: rereply,
              };
            }
          });
        }
        else if(type !== "rereply") {
          reply = [...comm.comments];

        }
        // console.log(reply, "reply 2");


      }

      if (type === "cmt" && comm._id === commid) {
        if (comm.likes.count > 0 && comm.likes.list.includes(USERID)) {
          return {
            ...comm,
            likes: {
              count: comm.likes.count - 1,
              list: comm.likes.list.filter((lik) => {
                if (lik !== USERID) return lik;
              }),
            },
            comments: reply,
          };
        } else {
          return {
            ...comm,
            likes: {
              count: comm.likes.count > 0 ? comm.likes.count + 1 : 1,
              list:
                comm.likes.count > 0
                  ? [...comm.likes.list, USERID]
                  : [USERID],
            },
            comments: reply,
          };
        }
      } else {
        return {
          ...comm,
          likes: { count: comm.likes.count, list: comm.likes.list },
          comments: reply,
        };
      }
    });
    console.log(data, "data");
    // return false;
    setallcomments(data);
    Services.likecomment(data)
  };
  // ----------------------------------
  // const likecomment = (postid, commid) => {
  //   var data = {
  //     postId: postid,
  //     commId: commid,
  //   };
  //   console.log("test123");
  //   Services.likecomment(data)
  //     .then((res) => {
  //       if (res.data.status) {
  //         fetchcomments(postid);
  //       } else {
  //         setposterr({ status: true, msg: res.data.message });
  //         setTimeout(() => {
  //           setposterr({ status: false, msg: "" });
  //         }, 3000);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const deletecmt = (postid, comtid) => {
    var data = {
      postId: postid,
      commId: comtid,
    };
    setdeletecmtInprocess({ ...deletecmtInprocess, [comtid]: true })
    Services.deletecomment(data)
      .then((res) => {
        for (var i = 0; i < allpost.length; i++) {
          if (allpost[i]._id === postid) {
            if (allpost[i].comments.includes(comtid)) {
              if (post_scope === "all")
                dispatch(deleteredcommt({ postid: postid, commid: comtid }));
              else if (post_scope === "group")
                dispatch(deletegroupcommt({ postid: postid, commid: comtid }));
              else if (post_scope === "member")
                dispatch(deletemembercommt({ postid: postid, commid: comtid }));
              else if (post_scope === "search")
                dispatch(deletesearchcommt({ postid: postid, commid: comtid }));
            }
          }
        }
        fetchcomments(postid);
        setdeletecmtInprocess({ ...deletecmtInprocess, [comtid]: true })
      })
      .catch((e) => {
        console.log(e);
        setdeletecmtInprocess({ ...deletecmtInprocess, [comtid]: false })

      });
  };

  const handleDeletePost = () => {
    Services.deletepost({ postId: delpostid, user_type: "user" })
      .then((res) => {
        if (res.data.status) {
          if (post_scope === "all") {
            dispatch(fetchallpost(1));
            dispatch(fetchsavedpost());
          } else if (post_scope === "group") {
            dispatch(fetchpostbygroupid({ groupid: SelectedGroup, page: 1 }));
            dispatch(fetchsavedpost());
          } else if (post_scope === "member") {
            dispatch(fetchmemberpost(memberid));
            dispatch(fetchsavedpost());
          } else if (post_scope === "search") {
            dispatch(fetchsearchpost({ text: text_search, user: user_search, topic: topic_search }))
            dispatch(fetchsavedpost());
          }
          setposterr({ status: false, msg: res.data.message });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        } else {
          setposterr({ status: true, msg: res.data.message });
          setTimeout(() => {
            setposterr({ status: false, msg: "" });
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleVote = (pollid, postid) => {
    var data = {
      postid: postid,
      pollChoicesID: pollid,
    };
    Services.addvote(data)
      .then((res) => {
        // dispatch(fetchallpost());
        if (res.data.status) {
          if (post_scope === "all")
            dispatch(
              handlereduvote({
                postid: postid,
                pollid: pollid,
              })
            );
          else if (post_scope === "group")
            dispatch(handlegroupvote({ postid: postid, pollid: pollid }));
          else if (post_scope === "member")
            dispatch(handlemembervote({ postid: postid, pollid: pollid }));
          else if (post_scope === "search")
            dispatch(handlesearchvote({ postid: postid, pollid: pollid }));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSave = (id) => {
    setinprocess_save({ [id]: true });
    Services.savepost(id)
      .then((res) => {
        if (res.data.status) {
          dispatch(fetchsavedpost());
          if (usersvdpost.includes(id))
            setsvdpost(
              usersvdpost.filter((p) => {
                if (p !== id) return;
              })
            );
          else setsvdpost([...usersvdpost, id]);
        }
        setinprocess_save({ [id]: false });
      })
      .catch((e) => {
        setinprocess_save({ [id]: false });
        console.log(e);
      });
  };
  const handlemorecmt = (postid) => {
    if (morecmt < totalcmt) {
      setcmtloader(true);
      Services.getallcomments({ postid: postid, page: morecmt + 1 })
        .then((res) => {
          if (res.data.status) {
            var data = res.data.data[0].comment.map((comm) => {
              var reply = [];
              var rereply = [];
              if (comm.comments.length > 0) {
                reply = comm.comments.map((reply) => {
                  if (reply.comments.length > 0) {
                    rereply = reply.comments.map((r) => {
                      return {
                        ...r,
                        likes: { count: r.likes.length, list: r.likes },
                      };
                    });
                  }
                  return {
                    ...reply,
                    likes: { count: reply.likes.length, list: reply.likes },
                    comments: rereply,
                  };
                });
              }
              return {
                ...comm,
                likes: { count: comm.likes.length, list: comm.likes },
                comments: reply,
              };
            });
            setallcomments([...allcomments,...data]);
            settotalcmt(res.data.data[0].totalPosts);
            setcmtloader(false);

          } else {
            setallcomments([]);
            setcmtloader(false);

          }
        })
        .catch((e) => {
          console.log(e);
        });
      setmorecmt(morecmt + 1);
    }
  };
  const handleSharePost = (postid) => {
    Services.sharepost(postid).then((res) => {
      if (res.data.status) {
        if (post_scope === "all") {
          dispatch(fetchallpost(1));
        } else {
          dispatch(fetchpostbygroupid({ groupid: SelectedGroup, page: 1 }));
        }
        setposterr({ status: false, msg: res.data.message });
        setTimeout(() => {
          setposterr({ status: false, msg: "" });
        }, 3000);
      }
    }).catch((e) => { console.log(e) })

  }
  return (
    <>
      <div className="allpost-main">

        {posterr.msg.length > 0 && posterr.status === false ? (
          <div className="post-msg-all post-sucessfully">
            <img src={Yesmsg} />
            {posterr.msg}
            <button className="post-close-msg">✕</button>
          </div>
        ) : (
          ""
        )}

        {/* {posterr.msg.length > 0 && posterr.status ? (
          <div className="post-msg-all post-alert">
            <img src={Alert} />
            {posterr.msg}
            <button className="post-close-msg">✕</button>
          </div>
        ) : (
          ""
        )} */}
        {post_scope !== "search" ?
          <div className="newsfeed-main">
            <div className="newsfeed-title">Newsfeed</div>
            <div className="newsfeed-sort">
              <div className="newsfeed-sort-title">Sort by :</div>
              <div className="newsfeed-sort-all">
                <span className="newsfeed-select" data-toggle="dropdown">
                  {sortby}
                </span>
                <div className="dropdown-menu newsfeed-select-in">
                  <ul>
                    <li
                      onClick={() => {
                        setsortby("All");
                      }}
                    >
                      All
                    </li>
                    <li
                      onClick={() => {
                        setsortby("User");
                      }}
                    >
                      User
                    </li>
                    <li
                      onClick={() => {
                        setsortby("Topics");
                      }}
                    >
                      Topics
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          : <></>}
        {loader ? (
          <div className="loadind-post">
            <LoaderComponent />
          </div>
        ) : (
          ""
        )}
        {!loader && allpost !== undefined && allpost.length < 1 ? (
          <div className="newsfeed-post cstm-news-no-post">
            <img src={noMediaFound} alt="noMediaFound" />
            <span className="emptymsg">No Post Found</span>
          </div>
        ) : (
          <></>
        )}
        {allpost !== undefined &&
          allpost.map((post, index) => {
            if (post !== undefined) {
              var postimgvid = post.shared_post ? [...post.shared_post.images, ...post.shared_post.videos] : [...post.images, ...post.videos];
              return post.postType === "share_post" ? (
                <>
                  <div className="shared-post-all-main">
                    <div className="shared-post-user-details">
                      <div className="shared-post-user-img-details" onClick={() => {
                        if (post.user_type !== "adminuser" && post.postedBy !== undefined &&
                          post.postedBy !== null) history(
                            `/settings/edit`
                          );
                      }}>
                        <img
                          className="shared-post-user-name"
                          src={
                            post.user_type === "user" &&
                            post.postedBy && post.postedBy.profileImg !== undefined &&
                              post.postedBy.profileImg.length > 0
                              ? post.postedBy.profileImg
                              : File1
                          }
                        />
                        <div className="post-shared-date-info">
                          <div className="user-name-title">
                            {post.postedBy !== undefined &&
                              post.postedBy !== null ? (
                              <>
                                {post.user_type !== "adminuser" ? (
                                  <span
                                    className="user-title-click"
                                    onClick={() => {
                                      history(
                                        `/settings/edit`
                                      );
                                    }}
                                  >
                                    {post.postedBy.otherdetail[
                                      process.env.REACT_APP_FIRSTNAME_DBID
                                    ] +
                                      " " +
                                      post.postedBy.otherdetail[
                                      process.env.REACT_APP_LASTNAME_DBID
                                      ]}{" "}
                                  </span>
                                ) : (
                                  post.postedBy.first_name +
                                  " " +
                                  post.postedBy.last_name
                                )}{" "}
                                {post.postedBy.verified ? (
                                  <img className="yesicon" src={Yes} />
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {post.shared_post ?
                              <>
                                <span className="cstm-posted-in"> Shared </span>
                                {post.shared_post.user_type !== "adminuser" ? (
                                  <span
                                    className="user-title-click"
                                    onClick={() => {
                                      history(
                                        `/settings/edit`
                                      );
                                    }}
                                  >
                                    {post.shared_post.postedBy ? post.shared_post.postedBy.otherdetail[
                                      process.env.REACT_APP_FIRSTNAME_DBID
                                    ] +
                                      " " +
                                      post.shared_post.postedBy.otherdetail[
                                      process.env.REACT_APP_LASTNAME_DBID
                                      ] : ""}{" "}
                                  </span>
                                ) : (
                                  post.shared_post.postedBy.first_name +
                                  " " +
                                  post.shared_post.postedBy.last_name
                                )}{" "}
                                {post.shared_post.postedBy && post.shared_post.postedBy.verified ? (
                                  <img className="yesicon" src={Yes} />
                                ) : (
                                  ""
                                )}
                                <span className="cstm-posted-in">Post</span>
                              </> : <></>}
                          </div>
                          <div className="user-name-date">
                            {new Date(post.createdAt).toLocaleDateString(
                              "en-us",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            ) +
                              " at " +
                              new Date(post.createdAt).toLocaleTimeString(
                                navigator.language,
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="shard-post-icons">
                        {userIsMember ? (
                          <div className="newsfeed-post-right-header-icon">
                            <div className="newfeed-dp newfeed-dp-link">
                              <button>
                                <img src={Link} />
                              </button>
                            </div>
                            {post.postStatus !== "Private" ? (
                              <div className="newfeed-dp newfeed-dp-save">
                                <button
                                  onClick={() => {
                                    handleSave(post._id);
                                  }}
                                >
                                  {inprocess_save[post._id] !== undefined &&
                                    inprocess_save[post._id] ? (
                                    <span>
                                      <img
                                        src={loading3}
                                        className="dis-loader3"
                                      />
                                    </span>
                                  ) : usersvdpost.includes(post._id) ? (
                                    <img src={svdpostimg} />
                                  ) : (
                                    <img src={Save} />
                                  )}
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div
                              className={`newfeed-dp cstm-dtl-poll ${post.postedBy !== null &&
                                post.postedBy !== undefined &&
                                USER_ID === post.postedBy._id
                                ? "userpoteclass"
                                : "bijausernoclass"
                                }`}
                            // style={
                            //   post.postedBy !== null &&
                            //   post.postedBy !== undefined &&
                            //   USER_ID === post.postedBy._id
                            //     ? "userpoteclass"
                            //     : "bijausernoclass"
                            // }
                            >
                              <span
                                className="cstm-poll-ad"
                                data-toggle="dropdown"
                              >
                                <img src={Dotted} />
                              </span>
                              <div className="dropdown-menu cstm-edit-delete-poll">
                                <div className="cstm-edit-delete-poll-inner">
                                  <ul>
                                    <li className="cstm-link-dash-btn">
                                      <button>
                                        <img src={Link} />
                                        <span>Copy Link</span>
                                      </button>
                                    </li>
                                    <li className="cstm-save-dash-btn">
                                      {post.postStatus !== "Private" ? (
                                        <div className="">
                                          <button
                                            onClick={() => {
                                              handleSave(post._id);
                                            }}
                                          >
                                            {inprocess_save[post._id] !==
                                              undefined &&
                                              inprocess_save[post._id] ? (
                                              <span>
                                                <img
                                                  src={loading3}
                                                  className="dis-loader3"
                                                />
                                              </span>
                                            ) : usersvdpost.includes(
                                              post._id
                                            ) ? (
                                              <img src={svdpostimg} />
                                            ) : (
                                              <img src={Save} />
                                            )}

                                            <span>Save Post</span>
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </li>

                                    <li className="cstm-delete-dash-btn">
                                      <a
                                        onClick={() => {
                                          setdelpostid(post._id);
                                        }}
                                        data-toggle="modal"
                                        data-target="#deletepost"
                                      >
                                        <img src={Delete2} />
                                        {post.postType === "Post" || post.postType === "share_post"
                                          ? "Delete Post"
                                          : "Delete Poll"}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="shared-post-comment-sec">
                      {post.shared_post ? <PostMainContent post={post.shared_post} userIsMember={userIsMember} handleSave={handleSave} inprocess_save={inprocess_save} usersvdpost={usersvdpost} USER_ID={USER_ID} postimgvid={postimgvid} setpost_allimg={setpost_allimg} setallimg={setallimg} post_scope={post_scope} feelings={feelings} />
                        : <> This content isn't available right now.
                          When this happens, it's usually because the owner only shared it with a small group of people, changed who can see it or it's been deleted.</>}
                      <div className="newsfeed-post-right-cmt">
                        <div className="newsfeed-post-right-cmt-left">
                          <span
                            className="newfeed-img-name"
                            onClick={() => {
                              if (userIsMember) handleLike(post._id);
                            }}
                          >
                            {post.likes.list.includes(USER_ID) ? (
                              <img src={fillheart} />
                            ) : (
                              <img src={Heart} />
                            )}
                            {post.likes.list.includes(USER_ID)
                              ? post.likes.count === 1
                                ? "You liked this post"
                                : `You and ${post.likes.count - 1
                                } other people like this post`
                              : `${millify(post.likes.count)} Likes`}
                          </span>
                        </div>
                        <div className="newsfeed-post-right-cmt-right">
                          <span
                            className="newfeed-img-name"
                            onClick={() => {
                              handleComment(post._id);
                            }}
                          >
                            <img src={Message} />
                            {millify(post.comments.length)} Comments
                          </span>
                          {/* {userIsMember ? (
                            <span className="newfeed-img-name" onClick={()=>{
                              Services.sharepost(post._id).then((res)=>{console.log(res)})
                            }}>
                              <img src={Share} /> {millify(post.share_count)} Shares
                            </span>
                          ) : (
                            <></>
                          )} */}
                        </div>
                      </div>
                      <PostComments
                        allcomments={allcomments}
                        cntcmt={cntcmt}
                        post={post}
                        deletecmt={deletecmt}
                        userIsMember={userIsMember}
                        likecomment={likecomment}
                        USER_ID={USER_ID}
                        totalcmt={totalcmt}
                        addcomment={addcomment}
                        setrereply={setrereply}
                        rereply={rereply}
                        reply={reply}
                        handlemorecmt={handlemorecmt}
                        setreply={setreply}
                        showcinput={showcinput}
                        setComment={setComment}
                        comment={comment}
                        allusers={allusers}
                        suggestionList={suggestionList}
                        cmtinprocess={cmtinprocess}
                        replyinprocess={replyinprocess}
                        rereplyinprocess={rereplyinprocess}
                        cmtloader={cmtloader}
                        called_from="user"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="newsfeed-post cstm-newsfeed-post">
                  <div className="newsfeed-post-left" onClick={() => {
                    if (post.user_type !== "adminuser" && post.postedBy !== undefined &&
                      post.postedBy !== null) history(
                        `/settings/edit`
                      );
                  }}>
                    <img
                      src={
                        post.user_type === "user" &&
                        post.postedBy && post.postedBy.profileImg !== undefined &&
                          post.postedBy.profileImg.length > 0
                          ? post.postedBy.profileImg
                          : File1
                      }
                      onClick={() => {
                        if (post.user_type !== "adminuser" && post.postedBy !== undefined &&
                          post.postedBy !== null) history(
                            `/settings/edit`
                          );
                      }}
                    />
                  </div>
                  <div className="newsfeed-post-right">
                    <div className="newsfeed-post-right-header">
                      <div className="newsfeed-post-right-header-name">
                        <span className="user-name-title">
                          {post.postedBy !== undefined &&
                            post.postedBy !== null ? (
                            <>
                              {post.user_type !== "adminuser" ? (
                                <span
                                  className="user-title-click"
                                  onClick={() => {
                                    history(
                                      `/settings/edit`
                                    );
                                  }}
                                >
                                  {post.postedBy.otherdetail[
                                    process.env.REACT_APP_FIRSTNAME_DBID
                                  ] +
                                    " " +
                                    post.postedBy.otherdetail[
                                    process.env.REACT_APP_LASTNAME_DBID
                                    ]}{" "}
                                </span>
                              ) : (
                                post.postedBy.first_name +
                                " " +
                                post.postedBy.last_name
                              )}{" "}
                              {post.postedBy.verified ? (
                                <img className="yesicon" src={Yes} />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {post_scope !== "group" ? (
                            <>
                              <span className="cstm-posted-in">posted in</span>{" "}
                              <span
                                className="user-title-click"
                                onClick={() => {
                                  history(`/group/${post.groupId._id}`);
                                }}
                              >
                                {" "}
                                {post.groupId.groupTitle}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </span>

                        <span className="user-name-date">
                          {new Date(post.createdAt).toLocaleDateString(
                            "en-us",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          ) +
                            " at " +
                            new Date(post.createdAt).toLocaleTimeString(
                              navigator.language,
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                        </span>
                      </div>

                      {userIsMember ? (
                        <div className="newsfeed-post-right-header-icon">
                          <div className="newfeed-dp newfeed-dp-link">
                            <button>
                              <img src={Link} />
                            </button>
                          </div>
                          {post.postStatus !== "Private" ? (
                            <div className="newfeed-dp newfeed-dp-save">
                              <button
                                onClick={() => {
                                  handleSave(post._id);
                                }}
                              >
                                {inprocess_save[post._id] !== undefined &&
                                  inprocess_save[post._id] ? (
                                  <span>
                                    <img
                                      src={loading3}
                                      className="dis-loader3"
                                    />
                                  </span>
                                ) : usersvdpost.includes(post._id) ? (
                                  <img src={svdpostimg} />
                                ) : (
                                  <img src={Save} />
                                )}
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div
                            className={`newfeed-dp cstm-dtl-poll ${post.postedBy !== null &&
                              post.postedBy !== undefined &&
                              USER_ID === post.postedBy._id
                              ? "userpoteclass"
                              : "bijausernoclass"
                              }`}
                          // style={
                          //   post.postedBy !== null &&
                          //   post.postedBy !== undefined &&
                          //   USER_ID === post.postedBy._id
                          //     ? "userpoteclass"
                          //     : "bijausernoclass"
                          // }
                          >
                            <span
                              className="cstm-poll-ad"
                              data-toggle="dropdown"
                            >
                              <img src={Dotted} />
                            </span>
                            <div className="dropdown-menu cstm-edit-delete-poll">
                              <div className="cstm-edit-delete-poll-inner">
                                <ul>
                                  {post.postType === "Poll" &&
                                    post.pollTotalVotes !== undefined &&
                                    post.pollTotalVotes.length > 1 ? (
                                    ""
                                  ) : (
                                    <li className="cstm-edit-dash-btn">
                                      <a
                                        onClick={() => {
                                          seteditId(post._id);
                                          handleSelectEdit(post._id);
                                        }}
                                        data-toggle="modal"
                                        data-target="#editpost"
                                      >
                                        <img src={Edit} />
                                        {post.postType === "Post"
                                          ? "Edit Post"
                                          : "Edit Poll"}
                                      </a>
                                    </li>
                                  )}

                                  <li className="cstm-link-dash-btn">
                                    <button>
                                      <img src={Link} />
                                      <span>Copy Link</span>
                                    </button>
                                  </li>
                                  <li className="cstm-save-dash-btn">
                                    {post.postStatus !== "Private" ? (
                                      <div className="">
                                        <button
                                          onClick={() => {
                                            handleSave(post._id);
                                          }}
                                        >
                                          {inprocess_save[post._id] !==
                                            undefined &&
                                            inprocess_save[post._id] ? (
                                            <span>
                                              <img
                                                src={loading3}
                                                className="dis-loader3"
                                              />
                                            </span>
                                          ) : usersvdpost.includes(post._id) ? (
                                            <img src={svdpostimg} />
                                          ) : (
                                            <img src={Save} />
                                          )}

                                          <span>Save Post</span>
                                        </button>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </li>

                                  <li className="cstm-delete-dash-btn">
                                    <a
                                      onClick={() => {
                                        setdelpostid(post._id);
                                      }}
                                      data-toggle="modal"
                                      data-target="#deletepost"
                                    >
                                      <img src={Delete2} />
                                      {post.postType === "Post"
                                        ? "Delete Post"
                                        : "Delete Poll"}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="newsfeed-post-right-text">
                      <b>
                        {post.tagAFriend.length > 0 ? " with " : ""}
                        {post.tagAFriend.length > 0 ? (
                          post.tagAFriend.map((frnd, index_frnd) => {
                            if (post.tagAFriend.length === 1)
                              return (
                                <span
                                  onClick={() => {
                                    history(`/settings/edit`);
                                  }}
                                >
                                  {frnd.otherdetail[
                                    process.env.REACT_APP_FIRSTNAME_DBID
                                  ] +
                                    " " +
                                    frnd.otherdetail[
                                    process.env.REACT_APP_LASTNAME_DBID
                                    ]}
                                </span>
                              );
                            else if (frnd.length - 1 === index_frnd)
                              return (
                                <span
                                  onClick={() => {
                                    history(`/settings/edit`);
                                  }}
                                >
                                  {" "}
                                  and{" "}
                                  {frnd.otherdetail[
                                    process.env.REACT_APP_FIRSTNAME_DBID
                                  ] +
                                    " " +
                                    frnd.otherdetail[
                                    process.env.REACT_APP_LASTNAME_DBID
                                    ]}
                                </span>
                              );
                            else
                              return (
                                <span
                                  onClick={() => {
                                    history(`/settings/edit`);
                                  }}
                                >
                                  {frnd.otherdetail[
                                    process.env.REACT_APP_FIRSTNAME_DBID
                                  ] +
                                    " " +
                                    frnd.otherdetail[
                                    process.env.REACT_APP_LASTNAME_DBID
                                    ]}{" "}
                                  ,{" "}
                                </span>
                              );
                          })
                        ) : (
                          <></>
                        )}
                      </b>
                      <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="See more"
                        less="See less"
                        className="content-css"
                        anchorclassName="my-anchor-css-class"
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        {post.description}
                      </ShowMoreText>
                      <span>
                        {post.feelingsActivity !== null &&
                          post.feelingsActivity !== undefined &&
                          post.feelingsActivity._id.length > 0 ? (
                          <span>
                            {feelings.filter(
                              (feelings) =>
                                feelings._id === post.feelingsActivity._id
                            )[0] !== undefined
                              ? `
                                 - feeling
                                ${post.feelingsActivity.emoji +
                              " " +
                              post.feelingsActivity.feeling
                              }`
                              : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>

                    {post.topics.length > 0 ? (
                      <div className="newsfeed-post-right-topik">
                        {" "}
                        {post.topics.map((topic) => (
                          <span className="topik-in">{topic.topic}</span>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    <AllPostImgView
                      post={post}
                      postimgvid={postimgvid}
                      setpost_allimg={setpost_allimg}
                      setallimg={setallimg}
                    />
                    <div
                      style={
                        post.pollChoices !== undefined &&
                          post.pollChoices.length > 0
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {post.pollChoices !== undefined &&
                        post.pollChoices.length > 0
                        ? post.pollChoices.map((opt) => {
                          var givevote =
                            post.pollDuration !== "No time limit"
                              ? moment
                                .duration(
                                  moment().diff(
                                    moment(post.createdAt, "YYYY-MM-DD")
                                  )
                                )
                                .asDays() > parseInt(post.pollDuration)
                                ? false
                                : true
                              : true;
                          return (
                            <>
                              <div className="newsfeed-agree-all">
                                <div
                                  className="newsfeed-agree-main"
                                  onClick={() => {
                                    if (givevote && userIsMember)
                                      handleVote(opt._id, post._id);
                                  }}
                                >
                                  <span
                                    className="newsfeed-agree-main-color"
                                    style={{
                                      width: `${post.pollTotalVotes !== undefined &&
                                        post.pollTotalVotes.length > 0
                                        ? Math.round(
                                          (opt.votes.length * 100) /
                                          post.pollTotalVotes.length
                                        )
                                        : 0
                                        }%`,
                                      background: "#d3efe7",
                                    }}
                                  ></span>
                                  <span className="newsfeed-agree-main-title">
                                    {opt.value}
                                  </span>
                                  <span className="newsfeed-agree-main-ps">
                                    {post.pollTotalVotes !== undefined &&
                                      post.pollTotalVotes.length > 0
                                      ? Math.round(
                                        (opt.votes.length * 100) /
                                        post.pollTotalVotes.length
                                      )
                                      : 0}
                                    %
                                  </span>
                                </div>
                                <span className="news-votes-cunt">
                                  {opt.votes.length === 0
                                    ? "0 Vote"
                                    : opt.votes.length === 1
                                      ? "1 Vote"
                                      : `${opt.votes.length} Votes`}{" "}
                                </span>
                              </div>
                            </>
                          );
                        })
                        : ""}
                      <div className="newsfeed-votes-main">
                        <span className="cstm-votes-ct">
                          {post.pollTotalVotes !== undefined
                            ? post.pollTotalVotes.length
                            : 0}{" "}
                          Votes
                        </span>
                        <span className="cstm-poll-will-close">
                          &bull;{" "}
                          {post.pollDuration !== "No time limit" &&
                            Math.ceil(
                              moment
                                .duration(
                                  moment().diff(
                                    moment(post.createdAt, "YYYY-MM-DD")
                                  )
                                )
                                .asDays()
                            ) > parseInt(post.pollDuration)
                            ? "Voting closed!"
                            : post.pollDuration.toLowerCase() ===
                              "no time limit"
                              ? "Not time limit for voting!"
                              : `Poll will close in ${Math.ceil(
                                moment
                                  .duration(
                                    moment().diff(
                                      moment(post.createdAt, "YYYY-MM-DD")
                                    )
                                  )
                                  .asDays()
                              )} days!`}
                        </span>
                      </div>
                    </div>

                    <div className="newsfeed-post-right-cmt">
                      <div className="newsfeed-post-right-cmt-left">
                        <span
                          className="newfeed-img-name"
                          onClick={() => {
                            if (userIsMember) handleLike(post._id);
                          }}
                        >
                          {post.likes.list.includes(USER_ID) ? (
                            <img src={fillheart} />
                          ) : (
                            <img src={Heart} />
                          )}
                          {post.likes.list.includes(USER_ID) ? (
                            post.likes.count === 1 ? (
                              <span className="cmt-ur-cunt">
                                You liked this post
                              </span>
                            ) : (
                              <>
                                <span className="cmt-ur-cunt">
                                  You and {post.likes.count - 1} other{" "}
                                </span>
                                <span className="cmt-other-cunt">
                                  people like this post
                                </span>
                              </>
                            )
                          ) : (
                            <span>{post.likes.count} Likes</span>
                          )}
                        </span>
                      </div>
                      <div className="newsfeed-post-right-cmt-right">
                        <span
                          className="newfeed-img-name"
                          onClick={() => {
                            handleComment(post._id);
                          }}
                        >
                          <img src={Message} />
                          <span>{post.comments.length}</span>
                          <span className="cstm-ppl-comments"> Comments</span>
                        </span>
                        {userIsMember && post.postType === "Post" ? (
                          <span
                            className="newfeed-img-name"
                            onClick={() => {
                              handleSharePost(post._id);
                            }}
                          >
                            <img src={Share} /> {millify(post.share_count)}{" "}
                            Shares
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <PostComments
                      allcomments={allcomments}
                      cntcmt={cntcmt}
                      post={post}
                      deletecmt={deletecmt}
                      userIsMember={userIsMember}
                      likecomment={likecomment}
                      USER_ID={USER_ID}
                      totalcmt={totalcmt}
                      addcomment={addcomment}
                      cmtinprocess={cmtinprocess}
                      replyinprocess={replyinprocess}
                      rereplyinprocess={rereplyinprocess}
                      setrereply={setrereply}
                      rereply={rereply}
                      reply={reply}
                      handlemorecmt={handlemorecmt}
                      setreply={setreply}
                      showcinput={showcinput}
                      setComment={setComment}
                      comment={comment}
                      allusers={allusers}
                      cmtloader={cmtloader}
                      suggestionList={suggestionList}
                    />
                  </div>
                </div>
              );
              //  : ""
            }
          })}
        {pageloader && post_scope !== "member" && allpost.length < totalpost ? (
          <div className="loadind-post">
            <LoaderComponent />
          </div>
        ) : (
          ""
        )}
        {
          allpost.length > 0 && allpost.length === totalpost ? <div className="loadind-post">
            <b>You've seen all posts</b>

          </div> : <></>
        }
        {/* Edit popup */}
        <div
          className="modal fade create-popup-post"
          id="editpost"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="create-post-content-inner-main">
                <div className="create-post-content-inner-sub">
                  <div className="create-ps-title">
                    <div className="create-ps-title-title ccstm-creatr-post-main">
                      Edit Post in Group{" "}
                      {editPost.groupId !== undefined
                        ? editPost.groupId.groupTitle
                        : ""}{" "}
                      Topics
                      <input
                        type="text"
                        list="editposttopics"
                        name="browser"
                        onKeyUp={(e) => {
                          if (e.target.value !== "" || editPost.topics.length > 0)
                            setposterr({ status: false, msg: "" });

                          var seltop = topics.filter((topi) => {
                            if (topi.topic === e.target.value) return topi;
                          });
                          var posttop = editPost.topics.filter((topi) => {
                            if (topi === seltop[0]._id) return topi;
                          });
                          if (seltop.length > 0 && posttop.length === 0) {
                            seteditPost({
                              ...editPost,
                              topics: [...editPost.topics, seltop[0]._id],
                            });
                            e.target.value = "";
                            console.log({
                              ...editPost,
                              topics: [...editPost.topics, seltop[0]._id],
                            });
                          }
                        }}
                      />
                    </div>
                    <datalist id="editposttopics">
                      {topics.map((filterdtopic) => {
                        return <option>{filterdtopic.topic}</option>;
                      })}
                    </datalist>
                    <div className="create-ps-title-close">
                      <button
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setErr("");
                          seteditPost({});
                          setshowpoll(false);
                          setaddImg([]);
                          setaddVid([]);
                          setremoveImg([]);
                          setremoveVid([]);
                          setsavedImg([]);
                          setsavedVid([]);
                        }}
                      >
                        ✕
                      </button>
                    </div>
                  </div>
                  <div className="create-pp-post csm-main-pp-post">
                    <div className="create-pp-post-left">
                    {(localStorage.getItem("userprofile") &&
                      localStorage.getItem("userprofile").length) ? (
                      <img
                        className="cr-pp-img"
                        src={
                        localStorage.getItem("userprofile") !== null &&
                              localStorage.getItem("userprofile") !==
                                undefined &&
                              localStorage.getItem("userprofile").length > 0
                            ? localStorage.getItem("userprofile")
                            : File1
                        }
                      />
                    ) : (
                      <div class="cstm-avat-box">
                        {localStorage.getItem("username")
                          ? localStorage.getItem("username").slice(0,1)
                          : ""}
                      </div>
                    )}
                     {" "}
                      <span className="cr-pp-name">{USER_NAME}</span>
                    </div>
                    <div className="create-pp-post-right">
                      {editId.length > 0 ? (
                        <>
                          <div className="create-pp-post-cont">
                            <div className="create-pp-post-cont-text">
                              {editPost.feelingsActivity !== null &&
                                editPost.feelingsActivity !== undefined &&
                                editPost.feelingsActivity.length > 0 ? (
                                <div className="feelings-emoji">
                                  <div className="feelings-emoji-icon">
                                    {feelings.filter(
                                      (feelings) =>
                                        feelings._id ===
                                        editPost.feelingsActivity
                                    )[0].emoji +
                                      " " +
                                      feelings.filter(
                                        (feelings) =>
                                          feelings._id ===
                                          editPost.feelingsActivity
                                      )[0].feeling}
                                  </div>
                                  <div
                                    className="feelings-emoji-close"
                                    onClick={() => {
                                      seteditPost({
                                        ...editPost,
                                        feelingsActivity: "",
                                      });
                                    }}
                                  >
                                    ×
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {friends.length > 0 ? "with " : ""}
                              {friends.map((frnd, index) => {
                                if (friends.length === 1)
                                  return (
                                    <span> {frnd.name}</span>
                                  )
                                else if (friends.length > 1 && index !== friends.length - 1)
                                  return (
                                    <span> {frnd.name} , </span>
                                  )
                                else return (
                                  <span> and {frnd.name}  </span>
                                )

                              })}
                              <textarea
                                ref={editref}
                                name="description"
                                onChange={handleChange}
                                value={editPost.description}
                                placeholder="What is happening?"
                              ></textarea>
                              <div className="topics-search-label">
                                {editPost.topics !== undefined &&
                                  topics !== undefined &&
                                  editPost.topics.length > 0
                                  ? editPost.topics.map((topic) => {
                                    return (
                                      <span className="topics-search-label-in">
                                        {topics.filter(
                                          (tpc) => tpc._id === topic
                                        )[0] !== undefined
                                          ? topics.filter(
                                            (tpc) => tpc._id === topic
                                          )[0].topic
                                          : ""}
                                        <button
                                          onClick={() => {
                                            seteditPost({
                                              ...editPost,
                                              topics: editPost.topics.filter(
                                                (tpc) => {
                                                  if (tpc !== topic)
                                                    return tpc;
                                                }
                                              ),
                                            });
                                          }}
                                        >
                                          x
                                        </button>
                                      </span>
                                    );
                                  })
                                  : ""}
                              </div>
                              <div
                                style={
                                  showpoll ||
                                    (editPost.pollChoices !== undefined &&
                                      editPost.pollChoices.length > 0)
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <div className="add-poll-main">
                                  <div className="add-poll-title">Add Poll</div>
                                  <div className="add-poll-main-box">
                                    <input
                                      type="text"
                                      name="option"
                                      onChange={(e) => {
                                        setoption(e.target.value);
                                      }}
                                      value={option}
                                    />
                                    <button
                                      className="plus-add-btn"
                                      onClick={(e) => {
                                        seteditPost({
                                          ...editPost,
                                          pollChoices:
                                            option.length > 0
                                              ? [
                                                ...editPost.pollChoices,
                                                option,
                                              ]
                                              : [...editPost.pollChoices],
                                        });
                                        setoption("");
                                      }}
                                    >
                                      <img src={Plusadd} /> Add
                                    </button>
                                  </div>

                                  {editPost.pollChoices !== undefined &&
                                    editPost.pollChoices.length > 0
                                    ? editPost.pollChoices.map((opt) => {
                                      return (
                                        <>
                                          <div className="add-poll-main-box2">
                                            <input
                                              type="text"
                                              value={opt}
                                              disabled
                                            />
                                            <button
                                              className="addpoll-delete"
                                              onClick={() => {
                                                seteditPost({
                                                  ...editPost,
                                                  pollChoices:
                                                    editPost.pollChoices.filter(
                                                      (option) =>
                                                        option !== opt
                                                    ),
                                                });
                                              }}
                                            >
                                              <img src={Delete} />
                                            </button>
                                          </div>
                                        </>
                                      );
                                    })
                                    : ""}
                                </div>
                                <div
                                  style={
                                    editPost.pollChoices !== undefined &&
                                      editPost.pollChoices.length > 0
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                >
                                  <label>Poll Duration</label>
                                  <select
                                    name="pollDuration"
                                    onChange={handleChange}
                                    value={editPost.pollDuration}
                                  >
                                    <option value=""> Select Days </option>
                                    {days.map((d) => {
                                      return (
                                        <option value={d}>
                                          {d}{" "}
                                          {d === 1
                                            ? "Day"
                                            : d !== "No time limit"
                                              ? "Days"
                                              : ""}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <EditPostImgView alleditimgvids={alleditimgvids} />
                          </div>
                          <div className="create-pp-post-btn">
                            <UploadImages
                              addImg={addImg}
                              setaddImg={setaddImg}
                              edit={true}
                              setErr={setErr}
                              showpoll={showpoll}
                              alleditimgvids={alleditimgvids}
                              setalleditimgvids={setalleditimgvids}
                            />
                            <UploadVideos
                              addVid={addVid}
                              setaddVid={setaddVid}
                              edit={true}
                              setErr={setErr}
                              showpoll={showpoll}
                              alleditimgvids={alleditimgvids}
                              setalleditimgvids={setalleditimgvids}
                            />
                            <span className="cp-btn">
                              <button
                                className="cp-btn-feelingn"
                                data-toggle="modal"
                                data-target="#editfeeling"
                              >
                                <img src={Feelings} />
                                Feelings
                              </button>
                            </span>
                            <span className="cp-btn">
                              <button
                                className="cp-btn-topics"
                                data-toggle="modal"
                                data-target="#edittagfriend"
                              >
                                <img src={Tagfrnd} />
                                Tag a Friend
                              </button>
                            </span>
                            <span className="cp-btn cstm-cp-dp">
                              <button className="cp-btn-dotted">
                                {/* <img src={Dotted} /> */}
                                <div
                                  className="crt-post-poll"
                                  onClick={() => {
                                    setshowpoll(true);
                                    setremoveImg(savedImg);
                                    setremoveVid(savedVid);
                                    setsavedImg([]);
                                    setsavedVid([]);
                                    addImg([]);
                                    addVid([]);
                                  }}
                                >
                                  <img src={Barchart} />
                                  poll
                                </div>
                              </button>
                              <div className="cstm-cp">
                                <ul>
                                  <li
                                    onClick={() => {
                                      setshowpoll(true);
                                      setremoveImg(savedImg);
                                      setremoveVid(savedVid);
                                      setsavedImg([]);
                                      setsavedVid([]);
                                      addImg([]);
                                      addVid([]);
                                    }}
                                  >
                                    <img src={Barchart} />
                                    Create a Poll
                                  </li>
                                  {/* <li>
                                <img src={Tagfrnd} />
                                Tag a Friend
                              </li> */}
                                </ul>
                              </div>
                            </span>
                          </div>

                          <div className="create-pp-post-public">
                            {/* <div className="create-pp-post-public-gl"> */}
                            <div className="cstm-error-messages">
                              {posterr.msg.length > 0 && posterr.status && posterr.msg}

                              {/* <select
                            name="postStatus"
                            onChange={handleChange}
                            value={editPost.postStatus}
                            className="cp-public-select"
                          >
                            <option disabled selected value="">
                              Public/Private
                            </option>
                            <option>Public</option>
                            <option>Private</option>
                          </select> */}
                            </div>
                            <div className="create-pp-post-public-btn">
                              <button
                                className="btn"
                                onClick={handlePublish}
                              // data-dismiss="modal"
                              >
                                Publish
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade create-popup-post"
          id="allimgs"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <ImageGalleryPost allimg={allimg} postid={post_allimg} />
        </div>
        <div
          className="modal fade msg-popup create-popup-post edit-img-popup-post"
          id="editallpix"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered edit-img-modal-dialog "
            role="document"
          >
            <div className="modal-content">
              <EditAllPix savedImg={savedImg} savedVid={savedVid} addImg={addImg} addVid={addVid} editremove={editremove} editremovevideo={editremovevideo} setalleditimgvids={setalleditimgvids} alleditimgvids={alleditimgvids} setaddImg={setaddImg} setErr={setErr} showpoll={showpoll} setaddVid={setaddVid} />
            </div>
          </div>
        </div>
        <div
          className="modal fade msg-popup"
          id="deletepost"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
              <div className="msg-icon">
                <img src={bigdelimg} />
              </div>
              <h1>Are you sure want to delete post?</h1>
              <div className="row">
                <div className="col-6">
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={handleDeletePost}
                      data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </span>
                </div>
                <div className="col-6">
                  <span className="msg-btn">
                    <button type="button" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade create-popup-post"
          id="editfeeling"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="create-ps-title">
                <div className="create-ps-title-title">
                  <button className="back-btn-mn" data-dismiss="modal">
                    <img src={Backarrow} />
                  </button>{" "}
                  What are you feeling today?
                </div>
                <div className="create-ps-title-close">
                  <button className="close" data-dismiss="modal">
                    ✕
                  </button>
                </div>
              </div>
              <div className="what-are-you-feeling">
                {feelings.map((feeling) => {
                  return (
                    <span
                      className="wayf-main"
                      onClick={() => {
                        changeFeeling(feeling._id);
                      }}
                    >
                      {feeling.emoji}
                      {feeling.feeling}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade create-popup-post cstm-tagfriend-popup"
          id="edittagfriend"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <TagFriendPopup setfriends={setfriends} friends={friends} />
          </div>
        </div>
      </div>
    </>
  );
};
export default AllPost;
