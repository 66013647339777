const chatconnectingmessage = "";

const ReduChatConnectingMessage = (state = chatconnectingmessage, action) => {
  switch (action.type) {
    case "ASSIGNCONNECTINGMESSAGE":
      return action.payload;
    default:
      return state;
  }
};

export default ReduChatConnectingMessage;
