import { useState } from "react";
import { useLocation } from "react-router-dom";
import AllPost from "../FrontendPost/AllPost";

import MenuFillIcon from "remixicon-react/MenuFillIcon";

const GlobleSearchMiddlebar = ({ setdis_hum }) => {
  const [err, seterr] = useState({
    msg: "",
    status: true,
  });
  const search = useLocation().search;
  const text_search = new URLSearchParams(search).get("text");
  return (
    <>
      <div className="mobile global-result">
        <div className="global-menu-btn">
          <button
            onClick={() => {
              setdis_hum(true);
              document.body.classList.add("global-search-active");
            }}
          >
            <MenuFillIcon />
          </button>
        </div>

        <div className="global-result-inner">
          <span>Search Results By</span>
          <p>{text_search}</p>
        </div>
      </div>
      <AllPost
        posterr={err}
        setposterr={seterr}
        post_scope="search"
        userIsMember={true}
      />
    </>
  );
};
export default GlobleSearchMiddlebar;
