import { useEffect, useState } from "react";
import Search from "../../../Images/search.svg";
import logo from "../../../Images/app_logo.png";
import Backarrow from "../../../Images/back-arrow.svg";
import authService from "../../../Services/auth.service";
import { checktoken_user } from "../../checktoken";

const TagFriendPopup = ({setfriends, friends}) => {
  const [searchfriend, setsearchfriend] = useState("");
  const [selectedfrnd, setselectedfrnd] = useState([]);
  const [friendlist, setfriendlist] = useState([]);
  const [displaylist, setdisplaylist] = useState([])
  useEffect(()=>{
    setselectedfrnd(friends)
  },[friends])
  useEffect(() => {
    authService
      .getfriendlist()
      .then((res) => {
        checktoken_user(res);
        if (res.data.status) {
          setdisplaylist(res.data.data)
          setfriendlist(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const handleSearchUser = (text) => {
    setsearchfriend(text);
    var arr = []
    for(var i = 0 ; i < friendlist.length ; i++)
    {
      console.log(friendlist[i].otherdetail[process.env.REACT_APP_FIRSTNAME_DBID].includes(text) , friendlist[i].otherdetail[process.env.REACT_APP_LASTNAME_DBID].includes(text))
      if(friendlist[i].otherdetail[process.env.REACT_APP_FIRSTNAME_DBID].includes(text) || friendlist[i].otherdetail[process.env.REACT_APP_LASTNAME_DBID].includes(text))
        arr[i] = friendlist[i]
    }
    setdisplaylist(arr)
  }
  return (
    <>
      <div className="modal-content cstm-topic-model-box">
        <div className="create-ps-title">
          <div className="create-ps-title-title">
            <button className="back-btn-mn" data-dismiss="modal">
              <img src={Backarrow} />
            </button>
            Tag Friend
          </div>
          <div className="create-ps-title-close">
            <button className="close" data-dismiss="modal">
              ✕
            </button>
          </div>
        </div>
        <div className="csstm-add-topic-listing tag-csstm-add-topic-listing">
          <div className="topics-search-bar">
            <input
              placeholder="search by user name"
              type="text"
              name="searchtopic"
              onChange={(e) => {
                handleSearchUser(e.target.value);
              }}
              value={searchfriend}
            />
            <img src={Search} />
          </div>

          {selectedfrnd.length > 0 ? (
            <div className="topics-search-label">
              {selectedfrnd.map((frnd) => {
                return (
                  <span className="topics-search-label-in">
                    {frnd.name}
                    <button
                      onClick={() => {
                        setselectedfrnd(
                          selectedfrnd.filter((data) => {
                            if (data.id !== frnd.id) return data;
                          })
                        );
                      }}
                    >
                      X
                    </button>
                  </span>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          <div className="topics-search-ovr tag-search-ovr-main">
            <div className="tag-a-friend">
              <p>Select your friends</p>
            </div>
            <div className="cstm-tag-friend-list">
              {friendlist.length > 0 ? (
                displaylist.map((frnd) => {
                  if (
                    selectedfrnd.filter((data) => {
                      if (data.id === frnd._id) return data;
                    }).length === 0
                  )
                    return (
                      <div className="topics-search-ovr-in">
                        <span
                          className="tso-id"
                          onClick={() => {
                            setselectedfrnd([
                              ...selectedfrnd,
                              {
                                id: frnd._id,
                                name:
                                  frnd.otherdetail[
                                    process.env.REACT_APP_FIRSTNAME_DBID
                                  ] +
                                  " " +
                                  frnd.otherdetail[
                                    process.env.REACT_APP_LASTNAME_DBID
                                  ],
                              },
                            ]);
                          }}
                        >
                          {/* <img src={frnd.profileImg.length > 0 ? process.env.REACT_APP_AWS_IMG_VID_PATH + frnd.profileImg : logo}/> */}
                          {frnd.otherdetail[
                            process.env.REACT_APP_FIRSTNAME_DBID
                          ] +
                            " " +
                            frnd.otherdetail[
                              process.env.REACT_APP_LASTNAME_DBID
                            ]}
                        </span>
                      </div>
                    );
                  else return <></>;
                })
              ) : (
                <>You have no friend list</>
              )}
            </div>
          </div>
          <div className="edit-img-cstm-btn-right">
            <button
              className="btn"
              onClick={() => {
                setfriends(selectedfrnd);
                setselectedfrnd([]);
              }}
              data-dismiss="modal"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default TagFriendPopup;
