import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
//import header file
import authHeader from "../../../Services/auth.header";
import Services from "../../../Services/auth.service";
import { fetchallpost, fetchpostbygroupid } from "../../../Action/index";
//import components
import UploadImages from "./UploadImages";
import UploadVideos from "./UploadVideos";
import TagFriendPopup from "./TagFriendPopup";
//import images
import File1 from "../../../Images/app_logo.png";
import Feelings from "../../../Images/feelings.svg";
import Tagfrnd from "../../../Images/user-add-line.svg";
import Barchart from "../../../Images/bar-chart-fill.svg";
import Backarrow from "../../../Images/back-arrow.svg";
import Plusadd from "../../../Images/plus-add.svg";
import Delete from "../../../Images/delete.svg";
import loader from "../../../Images/loader.gif";
// import tpcloader from "../../../Images/lodemorecmt.gif";
import noMediaFound from "../../../Images/nomediafound.svg";

const AddPost = ({
  posterr,
  setposterr,
  setprogbarVal,
  groupid,
  post_scope,
  groupname,
}) => {
  const descref = useRef();
  const dispatch = useDispatch();
  const Redu_Profile = useSelector((state) => {
    if (state.ReduProfile !== null && state.ReduProfile !== undefined)
      return state.ReduProfile;
    else return {};
  });
  const [Inprocess, setInprocess] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const formData = new FormData();
  const [post, setpost] = useState({
    desc: "",
    imgs: [],
    videos: [],
    likes: 0,
    feelings: "",
    topics: [],
    polloption: [],
    polldur: "",
    pollview: 0,
    status: "",
    tagfrnd: [],
  });
  // const [tpcloder, settpcloader] = useState(false);
  const [showpoll, setshowpoll] = useState(false);
  const [option, setoption] = useState("");
  const [group, setgroup] = useState("");
  const [openmodal, setopenmodal] = useState(false);
  const [alljoinedgroup, setalljoinedgroup] = useState([]);
  const feelings = useSelector((state) => {
    return state.ReduActivityFeeling;
  });
  const topics = useSelector((state) => {
    return state.ReduTopics;
  });
  const alltopics = useSelector((state) => {
    return state.ReduAllTopics;
  });
  const [friends, setfriends] = useState([]);
  const [err, setErr] = useState("");
  const days = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    "No time limit",
  ];
  useEffect(() => {
    Services.accessjoinedgroup()
      .then((res) => {
        if (res.data.status) {
          setalljoinedgroup(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    descref.current.style.height = "10px";
    descref.current.style.height = descref.current.scrollHeight + "px";
  }, [descref.current, post.desc]);
  const remove = (file) => {
    var arr = post.imgs;
    arr.splice(file, 1);
    setpost({
      ...post,
      imgs: arr,
    });
  };
  const removevideo = (file) => {
    console.log(file);
    var arr = post.videos;
    arr.splice(file, 1);
    setpost({
      ...post,
      videos: arr,
    });
  };

  const handleChange = (e) => {
    setErr("");
    setposterr({ status: false, msg: "" });
    setpost({ ...post, [e.target.name]: e.target.value });
  };

  const changeFeeling = (id) => {
    setErr("");
    setpost({ ...post, feelings: id });
  };

  function closeOneModal(addpost) {
    // get modal
    const modal = document.getElementById(addpost);

    // change state like in hidden modal
    modal.classList.remove("show");
    modal.setAttribute("aria-hidden", "true");
    modal.setAttribute("style", "display: none");

    // get modal backdrop
    const modalBackdrops = document.getElementsByClassName("modal-backdrop");

    // remove opened modal backdrop
    document.body.removeChild(modalBackdrops[0]);
  }
  const handlePublish = () => {
    setInprocess(true);
    var empty = true;
    Object.keys(post).map(function (key) {
      if (post[key].length > 0) empty = false;
    });
    if ((!empty && groupid.length > 0) || (!empty && post.groupid.length > 0)) {
      if (post.groupid !== undefined && post.groupid.length > 0)
        formData.append("groupId", post.groupid);
      else if (groupid.length > 0) formData.append("groupId", groupid);
      if (
        post.polloption.length > 0 &&
        (post.desc.length === 0 || post.polldur.length === 0)
      ) {
        setInprocess(false);
        setErr("Poll must have description and poll duration!");
        setposterr({
          status: true,
          msg: "Poll must have description and poll duration!",
        });
        // setTimeout(() => {
        //   setposterr({ status: false, msg: "" });
        // }, 3000);
        return;
      }
      if (post.topics.length > 0) {
        for (var i = 0; i < post.topics.length; i++) {
          formData.append(`topics[]`, post.topics[i]);
        }
      } else {
        setInprocess(false);
        setErr("Select topics!");
        setposterr({
          status: true,
          msg: "Select topics!",
        });
        // setTimeout(() => {
        //   setposterr({ status: false, msg: "" });
        // }, 3000);
        return;
      }
      formData.append("postedBy", localStorage.getItem("userid"));
      if (post.imgs.length > 0) {
        for (var i = 0; i < post.imgs.length; i++) {
          formData.append(`images`, post.imgs[i]);
        }
      } else if (post.polloption.length === 0) {
        formData.append(`images`, []);
      }

      if (post.videos.length > 0) {
        for (var i = 0; i < post.videos.length; i++) {
          formData.append(`videos`, post.videos[i]);
        }
      } else if (post.polloption.length === 0) {
        formData.append(`videos`, []);
      }
      formData.append("description", post.desc);
      if (post.feelings.length > 0)
        formData.append("feelingsActivity", post.feelings);
      if (post.status.length > 0) formData.append("postStatus", post.status);
      if (post.polloption.length > 0) {
        for (var i = 0; i < post.polloption.length; i++) {
          formData.append(`pollChoices[${i}][value]`, post.polloption[i]);
        }
      }
      if (post.polldur.length > 0)
        formData.append("pollDuration", post.polldur);
      post.polloption.length > 0
        ? formData.append("postType", "Poll")
        : formData.append("postType", "Post");
      formData.append("user_type", "user");
      if (friends.length > 0) {
        for (var i = 0; i < friends.length; i++) {
          formData.append(`tagAFriend[${i}]`, friends[i].id);
        }
      }
      if (posterr.status === false) {
        closeOneModal("addpost");
      }
      axios
        .post(API_URL + "post/create", formData, {
          headers: authHeader(),
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setprogbarVal(percentCompleted + "%");
          },
        })
        .then((res) => {
          // closeOneModal('addpost');
          if (res.data.status) {
            console.log(res.data.data);
            if (post_scope === "all") {
              dispatch(fetchallpost(1));
            } else {
              dispatch(fetchpostbygroupid({ groupid: groupid, page: 1 }));
            }
            setposterr({ status: false, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
          } else {
            setposterr({ status: true, msg: res.data.message });
            setTimeout(() => {
              setposterr({ status: false, msg: "" });
            }, 3000);
            setprogbarVal(0);
          }
        })
        .catch((e) => {
          setInprocess(false);
          console.log(e.message);
        });
      setErr("");
      setpost({
        desc: "",
        imgs: [],
        videos: [],
        likes: 0,
        feelings: "",
        topics: [],
        polloption: [],
        polldur: "",
        pollview: 0,
        status: "",
        tagfrnd: [],
      });
      setfriends([]);
      setshowpoll(false);
    } else {
      setInprocess(false);
      setErr("Empty post can't be published!");
      setposterr({ status: true, msg: "Empty post can't be published!" });
      // setTimeout(() => {
      //   setposterr({ status: false, msg: "" });
      // }, 3000);
    }
    setInprocess(false);
  };

  return (
    <>
      <div
        className="modal fade create-popup-post"
        id="addpost"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="create-post-content-inner-main">
              <div className="create-post-content-inner-sub">
                <div className="responsive-cr-heading-sec">
                  <div className="responsive-cr-heading-in">
                    {(localStorage.getItem("userprofile") &&
                      localStorage.getItem("userprofile").length) ||
                    (Redu_Profile &&
                      Redu_Profile.profileImg &&
                      Redu_Profile._id === localStorage.getItem("userid")) ? (
                      <img
                        className="cr-pp-img"
                        src={
                          Redu_Profile &&
                          Redu_Profile.profileImg &&
                          Redu_Profile._id === localStorage.getItem("userid")
                            ? Redu_Profile.profileImg
                            : localStorage.getItem("userprofile") !== null &&
                              localStorage.getItem("userprofile") !==
                                undefined &&
                              localStorage.getItem("userprofile").length > 0
                            ? localStorage.getItem("userprofile")
                            : File1
                        }
                      />
                    ) : (
                      <div class="cstm-avat-box">
                        {localStorage.getItem("username")
                          ? localStorage.getItem("username").slice(0,1)
                          : ""}
                      </div>
                    )}
                    <span className="cr-pp-name">
                      {localStorage.getItem("username")}
                    </span>
                  </div>
                  <div>
                    <button
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setInprocess(false);
                        setErr("");
                        setpost({
                          desc: "",
                          imgs: [],
                          videos: [],
                          likes: 0,
                          feelings: "",
                          topics: [],
                          polloption: [],
                          polldur: "",
                          pollview: 0,
                          status: "",
                          tagfrnd: [],
                        });
                        setfriends([]);
                        setshowpoll(false);
                      }}
                    >
                      ✕
                    </button>
                  </div>
                </div>

                {/* error message show in modal start */}
                {/* {posterr.msg.length > 0 && posterr.status === false ? (
                    <div className="post-msg-all post-sucessfully">
                      <img src={Yesmsg} />
                      {posterr.msg}
                      <button className="post-close-msg">✕</button>
                    </div>
                  ) : (
                    ""
                  )} */}

                {/* {posterr.msg.length > 0 && posterr.status ? (
                  <div className="post-msg-all post-alert">
                    <img src={Alert} />
                    {posterr.msg}
                    <button className="post-close-msg">✕</button>
                  </div>
                ) : (
                  ""
                )} */}
                {/* end here */}
                <div className="create-ps-title res-create-ps-title">
                  <div className="create-ps-title-title ccstm-creatr-post-main">
                    <h4>Create Post in Group</h4>
                    {post_scope !== "group" ? (
                      <select
                        onChange={(e) => {
                          // settpcloader(true)
                          setgroup(e.target.value);
                          setpost({
                            ...post,
                            topics: [],
                            groupid: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select group</option>
                        {alljoinedgroup !== undefined &&
                          alljoinedgroup.map((group) => {
                            return group !== null ? (
                              <option value={group._id}>
                                {group.groupTitle}
                              </option>
                            ) : (
                              ""
                            );
                          })}
                      </select>
                    ) : (
                      groupname
                    )}
                    {(group !== undefined && group.length > 0) ||
                    post_scope === "group" ? (
                      <>
                        {" "}
                        Topics
                        <input
                          type="text"
                          list="addposttopics"
                          name="browser"
                          onKeyUp={(e) => {
                            if (e.target.value !== "") {
                              setposterr({ status: false, msg: "" });
                            }

                            if (post_scope !== "group") {
                              var seltop = alltopics.filter((topi) => {
                                if (topi.topic_name === e.target.value)
                                  return topi;
                              });
                              var posttop = post.topics.filter((topi) => {
                                if (topi === seltop[0].topic_id) return topi;
                              });
                              if (seltop.length > 0 && posttop.length === 0) {
                                setpost({
                                  ...post,
                                  topics: [...post.topics, seltop[0].topic_id],
                                });
                                e.target.value = "";
                              }
                            } else {
                              var seltop = topics.filter((topi) => {
                                if (topi.topic === e.target.value) return topi;
                              });
                              var posttop = post.topics.filter((topi) => {
                                if (topi === seltop[0]._id) return topi;
                              });
                              if (seltop.length > 0 && posttop.length === 0) {
                                setpost({
                                  ...post,
                                  topics: [...post.topics, seltop[0]._id],
                                });
                                e.target.value = "";
                              }
                            }
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <datalist id="addposttopics">
                      {post_scope === undefined || post_scope === "all"
                        ? alltopics
                            .filter((topics) => topics.group_id === group)
                            .map((filterdtopic) => {
                              return <option>{filterdtopic.topic_name}</option>;
                            })
                        : topics.map((filterdtopic) => {
                            return <option>{filterdtopic.topic}</option>;
                          })}
                    </datalist>
                    {/* {tpcloder && <span className="tpcloader">
                      <img src={tpcloader} />
                    </span>} */}
                    {/* {alljoinedgroup.length > 0 ? "":"loading..."} */}
                  </div>
                  <div className="create-ps-title-close">
                    <button
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setInprocess(false);
                        setErr("");
                        setpost({
                          desc: "",
                          imgs: [],
                          videos: [],
                          likes: 0,
                          feelings: "",
                          topics: [],
                          polloption: [],
                          polldur: "",
                          pollview: 0,
                          status: "",
                          tagfrnd: [],
                        });
                        setfriends([]);
                        setshowpoll(false);
                      }}
                    >
                      ✕
                    </button>
                  </div>
                </div>

                <div className="create-pp-post csm-main-pp-post res-csm-main-pp-post">
                  <div className="create-pp-post-left">
                  {(localStorage.getItem("userprofile") &&
                      localStorage.getItem("userprofile").length)||
                    (Redu_Profile &&
                      Redu_Profile.profileImg &&
                      Redu_Profile._id === localStorage.getItem("userid")) ? (
                      <img
                        className="cr-pp-img"
                        src={
                          Redu_Profile &&
                          Redu_Profile.profileImg &&
                          Redu_Profile._id === localStorage.getItem("userid")
                            ? Redu_Profile.profileImg
                            : localStorage.getItem("userprofile") !== null &&
                              localStorage.getItem("userprofile") !==
                                undefined &&
                              localStorage.getItem("userprofile").length > 0
                            ? localStorage.getItem("userprofile")
                            : File1
                        }
                      />
                    ) : (
                      <div class="cstm-avat-box">
                        {localStorage.getItem("username")
                          ? localStorage.getItem("username").slice(0,1)
                          : ""}
                      </div>
                    )}
                    <span className="cr-pp-name">
                      {localStorage.getItem("username")}
                    </span>
                  </div>
                  <div className="create-pp-post-right">
                    <div className="create-pp-post-cont">
                      <div className="create-pp-post-cont-text cstm-create-pp-post-cont-text">
                        {post.feelings.length > 0 ? (
                          <div className="feelings-emoji">
                            <div className="feelings-emoji-icon">
                              {post.feelings.length > 0
                                ? feelings.filter(
                                    (feelings) => feelings._id === post.feelings
                                  )[0].emoji +
                                  " " +
                                  feelings.filter(
                                    (feelings) => feelings._id === post.feelings
                                  )[0].feeling
                                : ""}
                            </div>
                            <div
                              className="feelings-emoji-close"
                              onClick={() => {
                                setpost({ ...post, feelings: "" });
                              }}
                            >
                              ×
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {friends.length > 0 ? "with " : ""}
                        {friends.map((frnd, index) => {
                          if (friends.length === 1)
                            return (
                              <span className="frnd-list-name">
                                {" "}
                                {frnd.name}
                              </span>
                            );
                          else if (
                            friends.length > 1 &&
                            index !== friends.length - 1
                          )
                            return (
                              <span className="frnd-list-name">
                                {" "}
                                {frnd.name} ,{" "}
                              </span>
                            );
                          else
                            return (
                              <span className="frnd-list-name">
                                {" "}
                                and {frnd.name}{" "}
                              </span>
                            );
                        })}
                        <textarea
                          ref={descref}
                          name="desc"
                          onChange={handleChange}
                          value={post.desc}
                          placeholder="What is happening?"
                          row="3"
                        >
                          {post.desc}
                        </textarea>
                        {/* <div className="create-topics-box"> */}

                        {post.topics.length > 0 ? (
                          <div className="topics-search-label">
                            {post.topics.map((topic) => {
                              return (
                                <>
                                  <span className="topics-search-label-in">
                                    {post_scope === "all"
                                      ? alltopics.filter(
                                          (tpc) => tpc.topic_id === topic
                                        )[0].topic_name
                                      : topics.filter(
                                          (tpc) => tpc._id === topic
                                        )[0].topic}
                                    <button
                                      onClick={() => {
                                        setpost({
                                          ...post,
                                          topics: post.topics.filter((topc) => {
                                            if (topc !== topic) return topc;
                                          }),
                                        });
                                      }}
                                    >
                                      ×
                                    </button>
                                  </span>
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}

                        <div
                          style={
                            showpoll
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div className="add-poll-main">
                            <div className="add-poll-title">Add Poll</div>
                            <div className="add-poll-main-box">
                              <input
                                type="text"
                                name="option"
                                onChange={(e) => {
                                  setoption(e.target.value);
                                }}
                                value={option}
                              />
                              <button
                                className="plus-add-btn"
                                onClick={(e) => {
                                  setpost({
                                    ...post,
                                    polloption:
                                      option.length > 0
                                        ? [...post.polloption, option]
                                        : [...post.polloption],
                                  });
                                  setoption("");
                                }}
                              >
                                <img src={Plusadd} /> Add
                              </button>
                            </div>

                            {post.polloption.map((opt) => {
                              return (
                                <>
                                  <div className="add-poll-main-box2">
                                    <input type="text" value={opt} disabled />
                                    <button
                                      className="addpoll-delete"
                                      onClick={() => {
                                        setpost({
                                          ...post,
                                          polloption: post.polloption.filter(
                                            (option) => option !== opt
                                          ),
                                        });
                                      }}
                                    >
                                      <img src={Delete} />
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          <div
                            style={
                              post.polloption.length > 0
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            <label>Poll Duration</label>
                            <select name="polldur" onChange={handleChange}>
                              <option value=""> Select Days </option>
                              {days.map((d) => {
                                return (
                                  <option value={d}>
                                    {d}{" "}
                                    {d === 1
                                      ? "Day"
                                      : d !== "No time limit"
                                      ? "Days"
                                      : ""}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      {[...post.imgs, ...post.videos].length > 0 ? (
                        [...post.imgs, ...post.videos].length === 1 ? (
                          [...post.imgs, ...post.videos][0].type.indexOf(
                            "image"
                          ) !== -1 ? (
                            <div className="create-pp-post-img1">
                              <button
                                className="bx-close"
                                onClick={() => remove(0)}
                              >
                                ✕
                              </button>
                              <img
                                src={URL.createObjectURL(
                                  [...post.imgs, ...post.videos][0]
                                )}
                              />
                            </div>
                          ) : (
                            <div className="create-pp-post-img1">
                              <button
                                className="bx-close"
                                onClick={() => removevideo(0)}
                              >
                                ✕
                              </button>
                              <video
                                src={URL.createObjectURL(
                                  [...post.imgs, ...post.videos][0]
                                )}
                                controls={true}
                              />
                            </div>
                          )
                        ) : [...post.imgs, ...post.videos].length === 2 ? (
                          <div className="create-pp-post-img2">
                            {[...post.imgs, ...post.videos][0].type.indexOf(
                              "image"
                            ) !== -1 ? (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() => remove(0)}
                                >
                                  ✕
                                </button>
                                <img
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                />
                              </div>
                            ) : (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() => removevideo(0)}
                                >
                                  ✕
                                </button>
                                <video
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                  controls={true}
                                />
                              </div>
                            )}
                            {[...post.imgs, ...post.videos][1].type.indexOf(
                              "image"
                            ) !== -1 ? (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() => remove(1)}
                                >
                                  ✕
                                </button>
                                <img
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][1]
                                  )}
                                />
                              </div>
                            ) : (
                              <div className="create-pp-post-img2-in">
                                <button
                                  className="bx-close"
                                  onClick={() =>
                                    removevideo(
                                      [...post.imgs, ...post.videos].length -
                                        post.imgs.length -
                                        1
                                    )
                                  }
                                >
                                  ✕
                                </button>
                                <video
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][1]
                                  )}
                                  controls={true}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="create-pp-post-img3">
                            {[...post.imgs, ...post.videos][0].type.indexOf(
                              "image"
                            ) !== -1 ? (
                              <div className="create-pp-post-img3-left">
                                <button
                                  className="bx-close"
                                  onClick={() => remove(0)}
                                >
                                  ✕
                                </button>
                                <img
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                />
                              </div>
                            ) : (
                              <div className="create-pp-post-img3-left">
                                <button
                                  className="bx-close"
                                  onClick={() => removevideo(0)}
                                >
                                  ✕
                                </button>
                                <video
                                  src={URL.createObjectURL(
                                    [...post.imgs, ...post.videos][0]
                                  )}
                                  controls={true}
                                />
                              </div>
                            )}
                            <div className="create-pp-post-img3-right">
                              {[...post.imgs, ...post.videos][1].type.indexOf(
                                "image"
                              ) !== -1 ? (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() => remove(1)}
                                  >
                                    ✕
                                  </button>
                                  <img
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][1]
                                    )}
                                  />
                                </div>
                              ) : (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() =>
                                      removevideo(
                                        [...post.imgs, ...post.videos].length -
                                          post.imgs.length -
                                          1
                                      )
                                    }
                                  >
                                    ✕
                                  </button>
                                  <video
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][1]
                                    )}
                                    controls={true}
                                  />
                                </div>
                              )}
                              {[...post.imgs, ...post.videos][2].type.indexOf(
                                "image"
                              ) !== -1 ? (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() => remove(2)}
                                  >
                                    ✕
                                  </button>
                                  <img
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][2]
                                    )}
                                  />
                                  {[...post.imgs, ...post.videos].length > 3 ? (
                                    <div
                                      className="create-pp-cunt"
                                      data-toggle="modal"
                                      data-target="#imgs"
                                    >
                                      {[...post.imgs, ...post.videos].length -
                                        3}
                                      +
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div className="create-pp-post-img3-right-in">
                                  <button
                                    className="bx-close"
                                    onClick={() =>
                                      removevideo(
                                        [...post.imgs, ...post.videos].length -
                                          post.imgs.length -
                                          1
                                      )
                                    }
                                  >
                                    ✕
                                  </button>
                                  <video
                                    src={URL.createObjectURL(
                                      [...post.imgs, ...post.videos][2]
                                    )}
                                    controls={true}
                                  />
                                  {[...post.imgs, ...post.videos].length > 3 ? (
                                    <div
                                      className="create-pp-cunt"
                                      data-toggle="modal"
                                      data-target="#imgs"
                                    >
                                      {[...post.imgs, ...post.videos].length -
                                        3}
                                      +
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="create-pp-post-btn">
                        <UploadImages
                          post={post}
                          setpost={setpost}
                          edit={false}
                          setErr={setErr}
                          showpoll={showpoll}
                        />
                        <UploadVideos
                          post={post}
                          setpost={setpost}
                          edit={false}
                          setErr={setErr}
                          showpoll={showpoll}
                        />

                        <span className="cp-btn">
                          <button
                            className="cp-btn-feelingn"
                            data-toggle="modal"
                            data-target="#feeling"
                          >
                            <img src={Feelings} />
                            Feelings
                          </button>
                        </span>
                        {/* <span className="cp-btn">
                          <button
                            className="cp-btn-topics"
                            data-toggle="modal"
                            data-target="#topics"
                          >
                            <img src={Topics} />
                            Topics
                          </button>
                        </span> */}
                        <span className="cp-btn">
                          <button
                            className="cp-btn-topics"
                            data-toggle="modal"
                            data-target="#tagfriend"
                          >
                            <img src={Tagfrnd} />
                            Tag a Friend
                          </button>
                        </span>
                        <span className="cp-btn cstm-cp-dp">
                          <button className="cp-btn-dotted">
                            {/* <img src={Dotted} className="crt-post-dott" /> */}
                            <div
                              className="crt-post-poll"
                              onClick={() => {
                                setshowpoll(true);
                                setpost({
                                  ...post,
                                  imgs: [],
                                  videos: [],
                                });
                              }}
                            >
                              <img src={Barchart} />
                              Poll
                            </div>
                          </button>
                          {/* <div className="cstm-cp res-cstm-cp">
                            <ul>
                              <li
                                onClick={() => {
                                  setshowpoll(true);
                                  setpost({
                                    ...post,
                                    imgs: [],
                                    videos: [],
                                  });
                                }}
                              >
                                <img src={Barchart} />
                                Create a Poll
                              </li>
                              <li>
                                <img src={Tagfrnd} />
                                Tag a Friend
                              </li>
                            </ul>
                          </div> */}
                        </span>
                      </div>

                      <div className="create-pp-post-public">
                        <div className="cstm-error-messages">
                          {posterr !== undefined &&
                            posterr !== null &&
                            posterr.msg &&
                            posterr.msg.length > 0 &&
                            posterr.status}
                          {/* <select
                            name="status"
                            onChange={handleChange}
                            className="cp-public-select"
                          >
                            <option disabled selected value="">
                              Public/Private
                            </option>
                            <option>Public</option>
                            <option>Private</option>
                          </select> */}
                        </div>
                        <div
                          className={
                            Inprocess
                              ? "create-pp-post-public-btn loading-btn"
                              : "create-pp-post-public-btn"
                          }
                        >
                          {Inprocess ? (
                            <>
                              {" "}
                              <span className="loading-btn-in">
                                <img src={loader} />
                              </span>
                              <button
                                className="btn"
                                disabled={true}
                                onClick={handlePublish}
                                // data-dismiss="modal"
                              >
                                Publish
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn"
                              onClick={handlePublish}
                              // data-dismiss="modal"
                            >
                              Publish
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>

      <div
        className="modal fade create-popup-post"
        id="feeling"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="create-ps-title">
              <div className="create-ps-title-title">
                <button className="back-btn-mn" data-dismiss="modal">
                  <img src={Backarrow} />
                </button>
                What are you feeling today?
              </div>
              <div className="create-ps-title-close">
                <button className="close" data-dismiss="modal">
                  ✕
                </button>
              </div>
            </div>

            <div className="what-are-you-feeling">
              {feelings.map((feeling) => {
                return (
                  <span
                    className="wayf-main"
                    onClick={() => {
                      changeFeeling(feeling._id);
                    }}
                    data-dismiss="modal"
                  >
                    {feeling.emoji}
                    {feeling.feeling}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade msg-popup create-popup-post edit-img-popup-post show"
        id="imgs"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered edit-img-modal-dialog "
          role="document"
        >
          <div className=" modal-content">
            <div
              className={`edit-post-content-inner-main ${
                post.imgs.length + post.videos.length === 1
                  ? "editimgcount1"
                  : post.imgs.length + post.videos.length === 2
                  ? "editimgcount2"
                  : post.imgs.length + post.videos.length === 3
                  ? "editimgcount3"
                  : post.imgs.length + post.videos.length === 4
                  ? "editimgcount4"
                  : post.imgs.length + post.videos.length >= 5
                  ? "editimgcount5"
                  : "editimgcount0"
              }`}
            >
              <div className="edit-post-content-inner-sub">
                <div className="edit-img-inner-main">
                  {post.imgs.length === 0 && post.videos.length === 0 ? (
                    <div className="no-img-video">
                      <div>
                        <img src={noMediaFound} alt="noMediaFound" />
                        <p>Please add Image/Video</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {post.imgs.length > 0 &&
                    post.imgs.map((file, i) => (
                      <div className="list-group-item list-group-item-success">
                        <div
                          className="edit-cstm-img-inner-bg"
                          style={{
                            background: `url(${URL.createObjectURL(file)})`,
                          }}
                        ></div>
                        <img
                          src={URL.createObjectURL(file)}
                          // style={{ height: "50px", width: "50px" }}
                        />
                        <span
                          className="um-ulpoad-close"
                          onClick={() => remove(i, "add")}
                        >
                          ×
                        </span>
                      </div>
                    ))}

                  {post.videos.length > 0 &&
                    post.videos.map((file, i) => (
                      <div className="list-group-item list-group-item-success">
                        <div
                          className="edit-cstm-img-inner-bg"
                          style={{
                            background: `url(${URL.createObjectURL(file)})`,
                          }}
                        ></div>
                        <video
                          src={URL.createObjectURL(file)}
                          // style={{ height: "50px", width: "50px" }}
                        />
                        <span
                          className="um-ulpoad-close"
                          onClick={() => removevideo(i, "add")}
                        >
                          ×
                        </span>
                      </div>
                    ))}
                </div>
                <div className="edit-img-cstm-btn">
                  <div className="edit-img-cstm-btn-left">
                    <UploadImages
                      post={post}
                      setpost={setpost}
                      edit={false}
                      setErr={setErr}
                      showpoll={showpoll}
                    />
                    <UploadVideos
                      post={post}
                      setpost={setpost}
                      edit={false}
                      setErr={setErr}
                      showpoll={showpoll}
                    />
                  </div>
                  <div className="edit-img-cstm-btn-right">
                    <button className="btn" data-dismiss="modal">
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade create-popup-post cstm-tagfriend-popup"
        id="tagfriend"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <TagFriendPopup setfriends={setfriends} friends={friends} />
        </div>
      </div>
    </>
  );
};

export default AddPost;
