const chatloader = false;

const ReduChatLoader = (state = chatloader, action) => {
  switch (action.type) {
    case "SETCHATLOADER":
      return true;
    case "REMOVECHATLOADER":
        return false;
    default:
      return state;
  }
};

export default ReduChatLoader;