import { useDropzone } from "react-dropzone";
import { useState, useCallback, useEffect } from "react";
//import image
import Photos from "../../Images/photos.svg";
const UploadImages = ({
  post,
  addImg,
  setpost,
  setaddImg,
  setErr,
  showpoll,
  edit,
}) => {
  const [files, setFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });
  const assignFiles = (acceptedFiles) => {
    if (edit) {
      var urlarr = acceptedFiles.map((file) => {
        return file;
      });
      var arr = [...addImg, ...urlarr];
      setFiles(arr);
      setaddImg(arr);
      setErr("");
    } else {
      var arr = [...post.imgs, ...acceptedFiles];
      setFiles(arr);
      setpost({
        ...post,
        imgs: arr,
      });
      setErr("");
    }
  };

  return (
    <>
      <span className="cp-btn">
        {showpoll ? (
          <button className="cp-btn-photos cp-btn-disable" disabled={showpoll}>
            <img src={Photos} />
            Photos
          </button>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <button className="cp-btn-photos" disabled={showpoll}>
              <img src={Photos} />
              Photos
            </button>
          </div>
        )}
      </span>
    </>
  );
};

export default UploadImages;
