import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchactivityfeelings,
  fetchtopics,
  fetchallpost,
  fetchalltopics,
  setloader,
  removeloader
} from "../../../Action/index.js";
//import api file
import Services from "../../../Services/auth.service";
//import components
import AddPost from "./AddPost";
import AllPost from "./AllPost.js";

const FrontendPost = ({topicSearch}) => {
  const dispatch = useDispatch();
  const [progbarVal, setprogbarVal] = useState("");
  const [posterr, setposterr] = useState({ status: false, msg: "" });
  const [postpage,setpostpage] = useState(1)
  const handleScroll = () => {
    setpostpage(postpage + 1)
  }
  useEffect(() => {
    console.log("calleddd")   
    if(localStorage.getItem("idtoken") === undefined || localStorage.getItem("idtoken") === null || localStorage.getItem("idtoken").length === 0 || localStorage.getItem("userid") === null || localStorage.getItem("userid") === undefined ||
    localStorage.getItem("userid").length === 0 || localStorage.getItem("username") === null || localStorage.getItem("userid") === undefined || localStorage.getItem("username").length === 0 || localStorage.getItem("username") === undefined || 
    localStorage.getItem("role") === null || localStorage.getItem("role") !== 'user' || localStorage.getItem("logintime") === undefined || 
    localStorage.getItem("logintime") === null ){
      window.location.href = "/"
    }  
    setpostpage(1)
    dispatch(fetchactivityfeelings());
    dispatch(fetchalltopics());
    dispatch(fetchallpost(1));
    window.addEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    if(posterr.length > 0)
      dispatch(fetchallpost(postpage));
  }, [posterr]);
  useEffect(()=>{
    dispatch(fetchallpost(postpage));
    dispatch(fetchalltopics())
  },[postpage])
  return (
    <>
     {progbarVal.length > 0 ? (
     <div className="post-uploading-in-progress">
    <div className="pu-progress-title">Post uploading in progress</div>
    <div className="pu-progress-fil">
        <div className="pu-progress-bar" style={{ width: progbarVal}}></div>
    </div>
    </div>  ) : (
        ""
      )}
      <AddPost setposterr={setposterr} posterr={posterr} setprogbarVal={setprogbarVal} post_scope="all" groupid="629d8059546e0333f7809e3e"/>
      <AllPost
        posterr={posterr}
        setposterr={setposterr}
        progbarVal={progbarVal}
        setprogbarVal={setprogbarVal}
        post_scope="all"
        userIsMember={true}
        topicSearch={topicSearch}
      />
    </>
  );
};

export default FrontendPost;
