import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Services from "../../../Services/auth.service.event";
import { getEventParticipantTypesListAction, setloader } from "../../../Action";
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import DeleteModal from "../../AdminCommonComponent/deleteModal";
import ModalPopup from "../../ModalPopup/ModalPopup";
import AlertComponent from "../../../Hooks/AlertComponent";

const columns = [
  { id: "name", label: "Participant Types" },
  { id: "action", label: "Actions" },
];

//create a object of using given key
function createData(
  // drag,
  name,
  isDefault,
  action
) {
  return {
    name,
    isDefault,
    action,
  };
}

const ParticipantType = ({ tabInformation }) => {
  const dispatch = useDispatch();

  // use Selectors
  const eventParticipantTypes = useSelector((state) => {
    return state.EventParticipantReducer.eventParticipantTypesList;
  });

  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  const descriptionRef = useRef(null);
  const editDescriptionRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [rows, setRow] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowalert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowalertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowalertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [allParticipantType, setAllParticipantType] = useState([]);
  const [selectedId, setselectedId] = useState("");
  const [createInprocess, setcreateInprocess] = useState(false);
  const [editInprocess, seteditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [editorStateCreateModal, setEditorStateCreateModal] = useState("");
  const [editorStateEditModal, setEditorStateEditModal] = useState("");
  const [loader, setLoader] = useState(false);
  const [isUrl, setUrl] = useState(false);
  const navigate = useNavigate();
  const [editorStateCreateModalLongDesc, setEditorStateCreateModalLongDesc] =
    useState("");
  const [editorStateEditModalLongDesc, setEditorStateEditModalLongDesc] =
    useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [showData, setshowData] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const emptyObject = { role: "", isDefault: false, event: queryVariable };
  const [addEventParticipantType, setAddEventParticipantType] =
    useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [editEventParticipantType, setEditEventParticipantType] =
    useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  //Get all event participant type api
  async function eventParticipantTypesList(
    searchText,
    col,
    sort,
    isClearSearch
  ) {
    try {
      let query = `?search=&page=${page === 0 ? 1 : page + 1}&limit=${limit}`;

      query +=
        col !== undefined && col !== null && col !== ""
          ? `&sortField=${col === "name" ? "role" : col}`
          : "";
      query +=
        sort !== undefined && sort !== null && sort !== ""
          ? `&sortType=${sort}`
          : "";

      const eventParticipantTypeList =
        await Services.eventParticipantTypesListApi(queryVariable, query);
      if (eventParticipantTypeList?.status == 200) {
        dispatch(
          getEventParticipantTypesListAction(
            eventParticipantTypeList?.data?.data
          )
        );
        let participantTypesData = eventParticipantTypeList?.data?.data.list;
        var table = [];
        var n = 0;
        for (var i = 0; i < participantTypesData.length; i++) {
          table[n] = createData(
            participantTypesData[i].role,
            participantTypesData[i].isDefault,
            participantTypesData[i]._id
          );
          n++;
        }

        setAllParticipantType(table);
        if (isClearSearch) {
          setClearSearch(true);
        }
        if (!isClearSearch && searchedText && searchedText.length > 0) {
          table = table.filter((participantType) => {
            if (
              searchedText.length > 0 &&
              participantType.name
                .toLowerCase()
                .includes(searchedText.toLowerCase())
            )
              return participantType;
          });
        }
        setRow(table);

        setLoader(false);
        setloader(false);
      }
    } catch (error) {
      console.log("error:-----------", error);
    }
  }

  useEffect(() => {
    if (tabInformation === "participantType") {
      setLoader(true);
      eventParticipantTypesList();
      setClearSearch(true);
    }
  }, [tabInformation]);

  // Add participant type Api
  async function addEventParticipant() {
    try {
      const addParticipantType = await Services.addEventParticipantTypesApi(
        addEventParticipantType
      );
      if (addParticipantType?.status == 200 && addParticipantType.data.status) {
        setcreateInprocess(false);
        $("#addParticipantType").modal("hide");
        setClearSearch(true);
        setSearchedText("");
        eventParticipantTypesList(null, null, null, false);
        setShowalert(true);
        setAlertMsg(addParticipantType?.data?.message);
        setcreateInprocess(false);
      } else {
        // showAlertAdd
        // alertMsgAdd
        setShowalertAdd(true);
        setAlertMsgAdd(addParticipantType?.data?.message);
        setAddEventParticipantType(emptyObject);
        setcreateInprocess(false);
        setTimeout(() => {
          setShowalertAdd(false);
        }, 2000);
      }
    } catch (error) {
      console.log("error:-----------", error);
    }
  }

  // edit Member Api
  async function editEventParticipant() {
    if (selectedId !== "") {
      let data = {
        id: selectedId,
        role: editEventParticipantType.role,
        isDefault: editEventParticipantType.isDefault,
        event: editEventParticipantType.event,
      };
      try {
        const editParticipantType = await Services.editEventParticipantTypesApi(
          data
        );
        if (editParticipantType?.status == 200) {
          setcreateInprocess(false);
          setClearSearch(false);
          eventParticipantTypesList(null, null, null, false);
          $("#editParticipantType").modal("hide");
          setShowalert(true);
          setAlertMsg(editParticipantType?.data?.message);
        }
      } catch (error) {
        console.log("error:-----------", error);
      }
    }
  }

  // useEffect(() => {
  //     eventParticipantTypesList()
  // }, [page, limit])

  useEffect(() => {
    setTimeout(() => {
      setShowalert(false);
    }, 2000);
  }, [showAlert]);

  //handle onchange for create package
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    if (e.target.name === "isDefault") {
      setAddEventParticipantType({
        ...addEventParticipantType,
        [e.target.name]: e.target.checked,
      });
    } else {
      setAddEventParticipantType({
        ...addEventParticipantType,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "role") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    }
  };

  //handle on change for edit modal
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    if (e.target.name === "role") {
      setEditEventParticipantType({
        ...editEventParticipantType,
        [e.target.name]: e.target.value,
      });
    } else {
      setEditEventParticipantType({
        ...editEventParticipantType,
        [e.target.name]: e.target.checked,
      });
    }

    if (e.target.name === "role") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    }
  };

  //   Create Data
  const handleCreate = () => {
    var status = true;
    var tempObj = {};
    if (addEventParticipantType.role.trim().length === 0) {
      tempObj = { ...tempObj, role: "Enter type!" };
      status = false;
    }
    if (Object.keys(tempObj).length > 0) {
      const emptyKeys = Object.keys(tempObj).filter(
        (key) => tempObj[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setcreateInprocess(true);
      addEventParticipant();
    } else {
      setAddErr(tempObj);
    }
  };

  //Called when user click on update changes
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};
    if (editEventParticipantType.role.trim().length === 0) {
      temp = { ...temp, role: "Enter type!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.querySelectorAll(
        `[name="${emptyKeys[0]}"]`
      );
      if (inputElement) {
        inputElement[1].focus(); // Focus the input element
      }
    }
    if (status) {
      seteditInprocess(true);
      editEventParticipant();
    } else {
      setEditErr(temp);
    }
  };

  //deletion package api call
  const handleDelete = () => {
    setDeleteModalLoader(true);
    Services.deleteEventParticipantTypesListApi(deleteId)
      .then((res) => {
        setDeleteModalLoader(false);
        if (res.data.status) {
          setClearSearch(true);
          setShowDeleteModal(false);
          setSearchedText("");
          eventParticipantTypesList(null, null, null, true);
        }
        setClearSearch(true);
        setShowalert(true);
        setAlertMsg(res.data.message);
        if (res.data.status) {
          setTimeout(() => {
            setShowalert(false);
            setAlertMsg("");
          }, 5000);
        } else {
          setTimeout(() => {
            setShowalert(false);
            setAlertMsg("");
          }, 9000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setPage(0);

    setRowsPerPage(+event.target.value);
    setLimit(event.target.value);
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setselectedId(value);
    setEditModalLoader(true);
    Services.searchByIdEventParticipantTypesApi(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setEditEventParticipantType({
            role: data.role,
            isDefault: data.isDefault,
            event: data.event,
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //object from array
  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];

    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(arr[i].name, arr[i].isDefault, arr[i].action);
    }
    setPage(0);
    setRow(tbl);
    setLoader(false);
    setloader(false);
    if (updateAllEventArray) setAllParticipantType(tbl);
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#addParticipantType").hasClass("show")) {
        $("#addParticipantType").modal("show");
      }

      if ($("#editParticipantType").hasClass("show")) {
        $("#editParticipantType").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#addParticipantType").hasClass("show")) {
        $("#addParticipantType").modal("hide");
      }

      if ($("#editParticipantType").hasClass("show")) {
        $("#editParticipantType").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //close create package module
  const handleCloseAddModel = (e) => {
    setEditEventParticipantType(emptyObject);
    setEditErr(emptyObject);
    setAddEventParticipantType(emptyObject);
    setAddErr(emptyObject);
    handleClearAllStates();
    setSearchedText("");
  };

  // const sortEventListing = (search, col, direc) => {

  //     let arr = rows.slice();
  //     if (direc.toLowerCase() === "asc") {
  //         arr.sort((a, b) => (a[col] !== undefined ? a[col] : "") < (b[col] !== undefined ? b[col] : "") ? -1 : 1);
  //     } else if (direc.toLowerCase() === "desc") {
  //         arr.sort((a, b) => (a[col] !== undefined ? a[col] : "") > (b[col] !== undefined ? b[col] : "") ? -1 : 1);
  //     }

  //     setRow(arr);
  //     setloader(false)
  //     setLoader(false)
  // }

  return (
    <>
      <SearchAndSorting
        currentArray={allParticipantType}
        createObjectFromArray={createObjectFromArray}
        sortingOn={"name"}
        searchOn={["name"]}
        placeholderText={"Search participant type"}
        clearSearch={clearSearch}
        setClearSearch={setClearSearch}
        searchedText={searchedText}
        setSearchedText={setSearchedText}
      >
        <div className="uic-title-rt-only mt-0 position-relative end-0 ms-auto r-0">
          <div className="uic-fl-btn">
            <button
              className="btn"
              data-toggle="modal"
              data-target="#addParticipantType"
              onClick={(e) => {
                handleCloseAddModel(e);
              }}
            >
              Create Participant Type
            </button>
          </div>
        </div>
      </SearchAndSorting>

      {showAlert && <div className="alert alert-info">{alertMsg}</div>}

      <Paper className="uic-tb-mn mt-lg-4 mt-3">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHeader
              columns={columns}
              createObjectFromArray={createObjectFromArray}
              allEventList={allParticipantType}
              columnsWithoutSoritng={["action"]}
              setLoader={setLoader}
              searchText={searchedText}
              listApi={eventParticipantTypesList}
            />
            <TableBody className="uic-table-td">
              {loader ? (
                <div className="cstm-data-load">
                  <img src={Loader} />{" "}
                </div>
              ) : rows !== undefined && rows.length > 0 ? (
                rows?.map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.action}
                    >
                      <TableCell className="uic-th-td">
                        {row.name !== "others" && !row.isDefault ? (
                          <a
                            data-toggle="modal"
                            class="cstm-view-users-count"
                            data-target="#editParticipantType"
                            onClick={() => {
                              handleEdit(row.action);
                            }}
                          >
                            {row.name}
                          </a>
                        ) : (
                          <>{row.name}</>
                        )}
                      </TableCell>
                      <TableCell className="uic-th-td uic-table-icon w-17">
                        {row.name !== "others" && !row.isDefault && (
                          <>
                            <a
                              data-toggle="modal"
                              data-target="#editParticipantType"
                              onClick={() => {
                                handleEdit(row.action);
                              }}
                            >
                              <img src={editIcon} />
                            </a>

                            <a
                              data-toggle="modal"
                              data-target="#deleteParticipantType"
                              onClick={() => {
                                setDeleteId(row.action);
                                setShowDeleteModal(true);
                                setDeleteName(row.name);
                              }}
                            >
                              <img src={imgDelete} />
                            </a>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <span className="no-data-found">No data found</span>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          component="div"
          count={eventParticipantTypes?.total}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100, 200]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* create package */}
      <div
        className="modal fade edit-user-details-popup"
        // id="Addpackage"
        id="addParticipantType"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Participant Type</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addParticipantType").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Participant Type*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter participant type"
                    name="role"
                    onChange={handleAddChange}
                    value={addEventParticipantType.role}
                  />
                  <span className="error">{addErr.role}</span>
                </div>
              </div>
              {/* <div className="form-group">
                                
                                <div className="form-group-ct">
                                <div className="group-list-create-event">
                                <label>
                                    <input
                                    type="checkbox"
                                    name="isDefault"
                                    onChange={handleAddChange}
                                    checked={addEventParticipantType.isDefault}
                                    />{" "}
                                    Is Default    
                                </label>
                                     </div>   
                                </div>
                            </div> */}
            </div>

            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editParticipantType"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Participant Type</h2>
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editParticipantType").modal("hide");
                      handleCloseAddModel(e);
                    }
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Participant Type*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter participant type"
                      name="role"
                      onChange={handleEditChange}
                      value={editEventParticipantType.role}
                    />
                    <span className="error">{editErr.role}</span>
                  </div>
                </div>
                <div className="form-group">
                  {/* <div className="form-group-ct">
                                <div className="group-list-create-event">
                                <label>
                                    <input
                                    type="checkbox"
                                    name="isDefault"
                                    onChange={handleEditChange}
                                    checked={editEventParticipantType.isDefault}
                                    />{" "}
                                    Is Default    
                                </label>
                                     </div>   
                                </div> */}
                </div>
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                <button
                  type="button"
                  className="btn"
                  onClick={handleEditSubmit}
                >
                  Update Changes
                </button>
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>

      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteModalLoader ? "danger" : "secondary"}
        buttonText1={
          !deleteModalLoader ? (
            "Delete"
          ) : (
            <img
              src={Loader2}
              width={50}
              height={15}
              className="btn-loader-img-cover"
            />
          )
        }
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete participant type"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteName}"? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {/* <DeleteModal message={"Are you sure you want to delete this type?"} handleDelete={handleDelete} modalId={"deleteParticipantType"} /> */}
      <AlertComponent />
    </>
  );
};

export default ParticipantType;
