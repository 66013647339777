import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
// import WaveSurfer from "wavesurfer.js";
// Components
import Avtar from "../../../components/Avtar/Avtar";
import OutgoingMessageTime from "./HelperComponets/OutgoingMessageTime";
import Reaction from "./HelperComponets/Reaction";
import IncomingMessageActions from "./HelperComponets/IncomingMessageActions";
import MessageTail from "./HelperComponets/MessageTail";
import OutgoingMessageActions from "./HelperComponets/OutgoingMessageActions";
import IncomingMessageTime from "./HelperComponets/IncomingMessageTime";
import VoiceMessageControls from "./HelperComponets/VoiceMessageControls";

import DropDownItem from "../../../components/DropDownItem/DropDownItem";
// Style
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatMessagesBubble.css";
import "./Styles/ChatMessagesVoiceMessage.css";

const ChatMessagesVoiceMessage = ({
  menuSelectedDivRef,
  // Main Components Prompts
  type = "Channel/Group",
  isOutgoing = false,
  isHighlighted = false,
  isStacked = false,
  voiceControlType = false,
  voiceWaveFilled = false,
  audioTime = "2:15",
  voiceSrc = "",
  uniqueId = "",
  // Avatar
  AvatarType = "photo",
  Avtarsrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  AvtarLetter = "M",
  AvtarIcon = "",

  // OutgoingMessageTime
  isRead = true,
  hasBackdrop = false,
  isEdited = false,
  time = "09:12 AM",

  handleEmojiClick,
  handleReplyClick,
  handleDotsClick,
  handleReactionClick,
  actionModel,
  setActionModel,
  // reaction
  hasAction = false,
  showAllEmogis,
  toggleShowEmojis,
  initialEmojis,
  reactions,
  setReactions,
  showEmojis,
  showDropdown,
  handleSelectEmoji,
  setShowDropdown,
  setShowEmojis,
  reactionMsg,
  chatId,
  dropDownOptions,
  onSelectOptions,
  chatData,
  hasCounter = true,
  hasReactions = false,
  hasReaction2 = false,
  hasReaction3 = false,
  hasReaction4 = false,
  emoji1 = "👍",
  emoji2 = "😂",
  emoji3 = "👌",
  emoji4 = "🙌",
  counter = 5,
  handleReactionUsersList,
  isInProgress,
}) => {
  const [hoverEffect, setHoverEffect] = useState(hasAction);
  const wavesurferRef = useRef(null);
  const [currentDuration, setCurrentDuration] = useState("0:00");
  const [dropDownClass, setDropDownClass] = useState("bottom-drop-down");
  const dropdownRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [waveSurferInstance, setWaveSurferInstance] = useState(null);

  // Update voice data when page load
  useEffect(() => {
    const voiceInitializationFunction = async () => {
      // Initialize Wavesurfer when the component mounts
      let context, processor;
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (
        voiceSrc &&
        document.getElementById(`wavesurfer-container-${uniqueId}`)
      ) {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
        }
        const { default: WaveSurfer } = await import("wavesurfer.js");
        if (voiceSrc && !wavesurferRef.current) {
          if (isSafari) {
            var AudioContext = window.AudioContext || window.webkitAudioContext;
            context = new AudioContext();
            processor = context.createScriptProcessor(1024, 1, 1);
          }
          wavesurferRef.current = WaveSurfer.create({
            container: `#wavesurfer-container-${uniqueId}`,
            height: 20,
            waveColor: !isOutgoing ? "#CFCFCF" : "#88a9ec",
            progressColor: !isOutgoing ? "#0d99ff" : "white",
            cursorWidth: 0,
            // responsive: true,
            barWidth: 2,
            barHeight: 6,
            barGap: 2,
            backend: "MediaElement",
            audioContext: context || null,
            audioScriptProcessor: processor || null,
          });
          if (voiceSrc) {
            wavesurferRef.current.load(voiceSrc);
          }

          await wavesurferRef.current.on("ready", () => {
            getAudioDuration();
          });

          setWaveSurferInstance(wavesurferRef.current);

          wavesurferRef.current.on("play", () => {
            setIsPlaying(true);
            updateDuration(); // Start updating duration on play
          });

          wavesurferRef.current.on("pause", () => {
            setIsPlaying(false);
            updateDuration(); // Update duration on pause
          });

          wavesurferRef.current.on("finish", () => {
            setIsPlaying(false);
          });
        }
        return () => {
          if (wavesurferRef.current) {
            wavesurferRef.current.destroy();
          }
          if (waveSurferInstance) {
            waveSurferInstance.destroy();
            setWaveSurferInstance(null);
          }
        };
      }
    };
    voiceInitializationFunction();
  }, [voiceSrc]);

  useEffect(() => {
    if (menuSelectedDivRef?.current === null) {
      setHoverEffect(false);
    }
  }, [menuSelectedDivRef?.current]);
  // get audio duration for total
  const getAudioDuration = () => {
    if (wavesurferRef.current) {
      let totalDuration = wavesurferRef.current.getDuration();
      setCurrentDuration(totalDuration);
    }
  };

  // Manage Mouse enter
  const manageMouseEnter = () => {
    setHoverEffect(true);
  };

  // Manage Mouse leave
  const manageMouseLeave = () => {
    if (
      menuSelectedDivRef === null ||
      menuSelectedDivRef.current !== uniqueId
    ) {
      setHoverEffect(false);
    } else if (showEmojis || actionModel) {
      setHoverEffect(true);
    } else {
      setHoverEffect(false);
    }
  };

  // Update Duration when play audio
  const updateDuration = () => {
    const interval = setInterval(() => {
      const currentTime = wavesurferRef.current.getCurrentTime();
      setCurrentDuration(currentTime);
    }, 1000);

    wavesurferRef.current.on("audioprocess", () => {
      const currentTime = wavesurferRef.current.getCurrentTime();
      setCurrentDuration(currentTime);
    });

    wavesurferRef.current.on("pause", () => {
      clearInterval(interval);
    });
  };

  // formate duration for audio
  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    // Use padStart to ensure two-digit format
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    if (formattedMinutes !== "NaN" || formattedSeconds !== "NaN") {
      return `${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `0:00`;
    }
  };

  const playAudio = () => {
    wavesurferRef.current.play();
  };

  const pauseAudio = () => {
    wavesurferRef.current.pause();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // event.preventDefault();
      let emojiDiv = document.getElementById("REACTION_EMOJIS");
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        emojiDiv &&
        !emojiDiv.contains(event.target) &&
        setActionModel
      ) {
        setHoverEffect(false);
        setActionModel(false);
        setShowEmojis(false);
        setShowDropdown(false);
        menuSelectedDivRef = null;
      }
    };
    function handleScroll(event) {
      // setHoverEffect(false);
    }
    const scrollableDiv = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
    scrollableDiv.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOptionSelect = (selectedOption) => {
    if (onSelectOptions) {
      onSelectOptions(selectedOption, chatData); // Pass chat data as an argument
    }
  };
  return (
    <>
      {voiceSrc && (
        <div
          id={uniqueId}
          className="chat-message-bubble-sec"
          onMouseEnter={manageMouseEnter}
          onMouseLeave={manageMouseLeave}
          ref={dropdownRef}
        >
          <div
            className={`chat-message-bubble-inner flex chatmessage-voicemessage-inner ${
              type !== "Personal" ? "chat-channel-group" : ""
            } ${!isOutgoing ? "incoming" : "outgoing"} ${
              isStacked ? "stacked-item" : ""
            }`}
          >
            {/* <div>Outgoing False </div> */}
            {isHighlighted && <div className="highlight"></div>}
            <div
              className={`chat-message-container ${
                hasReactions ? "reaction-on" : ""
              }`}
            >
              <div className="chat-avtar-message flex items-end">
                <div className="chat-avtar flex">
                  {type !== "Personal" && !isOutgoing && (
                    <Avtar
                      type={AvatarType}
                      src={Avtarsrc}
                      letter={AvtarLetter}
                      icon={AvtarIcon}
                      size="40"
                    />
                  )}
                </div>
                <div className="chat-body flex items-end relative">
                  <div className="chat-tail">
                    <MessageTail isOutgoing={isOutgoing} />
                  </div>
                  <div className={`chat-message ${isStacked ? "stacked" : ""}`}>
                    {/* Voice Control sections */}
                    <div className="chatmessage-advanced relative flex items-center">
                      <VoiceMessageControls
                        type={voiceControlType}
                        onPlayClick={playAudio}
                        onPauseClick={pauseAudio}
                        isPlaying={isPlaying}
                      />

                      <div className="chatmessage-wave flex flex-col">
                        {voiceSrc && <audio src={voiceSrc} />}
                        {voiceSrc && (
                          <div id={`wavesurfer-container-${uniqueId}`}> </div>
                        )}
                        <p>{formatDuration(currentDuration)}</p>
                      </div>
                    </div>

                    <div className="chat-message-time">
                      {isOutgoing ? (
                        <OutgoingMessageTime
                          isRead={isRead}
                          hasBackdrop={hasBackdrop}
                          isEdited={isEdited}
                          time={time}
                          isInProgress={isInProgress}
                        />
                      ) : (
                        <IncomingMessageTime
                          hasBackdrop={hasBackdrop}
                          isEdited={isEdited}
                          time={time}
                          isInProgress={isInProgress}
                        />
                      )}
                    </div>
                  </div>
                  <div className="chat-actions">
                    {!isInProgress &&
                    (hasAction || hoverEffect) &&
                    isOutgoing ? (
                      <OutgoingMessageActions
                        handleDotsClick={(e) => {
                          menuSelectedDivRef.current = uniqueId;

                          handleDotsClick(e);
                          let heights = window.innerHeight - e.pageY;
                          if (heights < 500) {
                            setDropDownClass("top-drop-down");
                          }
                        }}
                        handleReplyClick={handleReplyClick}
                        handleEmojiClick={(e) => {
                          handleEmojiClick(chatId, e);
                          menuSelectedDivRef.current = uniqueId;
                        }}
                        chatId={chatId}
                      />
                    ) : !isInProgress && (hasAction || hoverEffect) ? (
                      <IncomingMessageActions
                        handleDotsClick={(e) => {
                          menuSelectedDivRef.current = uniqueId;

                          handleDotsClick(e);
                          let heights = window.innerHeight - e.pageY;
                          if (heights < 500) {
                            setDropDownClass("top-drop-down");
                          }
                        }}
                        handleReplyClick={handleReplyClick}
                        handleEmojiClick={(e) => {
                          handleEmojiClick(chatId, e);
                          menuSelectedDivRef.current = uniqueId;
                        }}
                        chatId={chatId}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {hasReactions && (
                <div className="chat-reaction">
                  <Reaction
                    isOutgoing={isOutgoing}
                    hasCounter={hasCounter}
                    counter={counter}
                    handleReactionClick={handleReactionClick}
                    handleReactionUsersList={handleReactionUsersList}
                    hasReactions={hasReactions}
                    hasReaction2={hasReaction2}
                    hasReaction3={hasReaction3}
                    hasReaction4={hasReaction4}
                    emoji1={emoji1}
                    emoji2={emoji2}
                    emoji3={emoji3}
                    emoji4={emoji4}
                    chatId={chatId}
                  />
                </div>
              )}
              <div className={`chat-action-menu ${dropDownClass}`}>
                {actionModel && (
                  <DropDownItem
                    size="small"
                    options={dropDownOptions}
                    isIcon={true}
                    onSelect={handleOptionSelect}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ChatMessagesVoiceMessage.propTypes = {
  type: PropTypes.oneOf(["Channel/Group", "Personal"]),
  // Main Component prompts
  isOutgoing: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isStacked: PropTypes.bool,
  hasReactions: PropTypes.bool,
  hasAction: PropTypes.bool,
  voiceControlType: PropTypes.bool,
  voiceWaveFilled: PropTypes.bool,
  audioTime: PropTypes.string,
  // Avatar
  AvatarType: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  Avtarsrc: PropTypes.string,
  AvtarLetter: PropTypes.string,
  AvtarIcon: PropTypes.element,

  // OutgoingMessageTime
  isRead: PropTypes.bool,
  hasBackdrop: PropTypes.bool,
  isEdited: PropTypes.bool,
  time: PropTypes.string,

  // Reaction
  hasCounter: PropTypes.bool,
  hasReaction2: PropTypes.bool,
  counter: PropTypes.number,

  // Actions
  handleEmojiClick: PropTypes.func,
  handleReplyClick: PropTypes.func,
  handleDotsClick: PropTypes.func,
  handleReactionClick: PropTypes.func,
  actionModel: PropTypes.any,
  setActionModel: PropTypes.func,
  showAllEmogis: PropTypes.any,
  toggleShowEmojis: PropTypes.func,
  initialEmojis: PropTypes.any,
  reactions: PropTypes.any,
  setReactions: PropTypes.func,
  showEmojis: PropTypes.bool,
  showDropdown: PropTypes.any,
  handleSelectEmoji: PropTypes.func,
  setShowDropdown: PropTypes.func,
  setShowEmojis: PropTypes.func,
  reactionMsg: PropTypes.any,
  chatId: PropTypes.string,
  //DropDown items
  dropDownOptions: PropTypes.array,
  onSelectOptions: PropTypes.func,
  chatData: PropTypes.object,
  handleReactionUsersList: PropTypes.func,
  isInProgress: PropTypes.bool,
};

export default ChatMessagesVoiceMessage;
