import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import millify from "millify";
import { saveAs } from "file-saver";
import ShowMoreText from "react-show-more-text";
import authService from "../../Services/auth.service";
import VideoPlayer from "./VideoPlayerFunction";
import Comments from "./Comments";
import VideoDetailBAck from "../../Images/short_left.png";
import likebtn from "../../Images/heart.svg";
import VideoViewsImage from "../../Images/view-2.png";
import VideoCalendarImage from "../../Images/calendar-1.png";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import DocImg from "../../Images/docImg.png";
import PdfImg from "../../Images/pdfImg.svg";
import Loader from "../../Images/loader.gif";

const VideoDetail = ({ selectedVideoId }) => {
  const history = useNavigate();
  const [videodetail, setvideodetail] = useState({});
  const [videooptions, setvideooptions] = useState({});
  const [deleteid, setdeleteid] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [allcomments, setallcomments] = useState([]);
  const [totalcommts, settotalcommts] = useState(0);
  const [dataLoader, setDataLoader] = useState(false)

  useEffect(() => {
    setDataLoader(true)
    if (selectedVideoId.length > 0) {
      authService
        .getcontentvideobyid(selectedVideoId)
        .then((res) => {
          if (res.data.status) {
            var i = 0;
            var res_arr = [];
            if (
              res.data.data.video_240 !== null &&
              res.data.data.video_240 !== undefined &&
              res.data.data.video_240.length > 0
            ) {
              res_arr[i] = {
                src:
                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.video_240,
                label: "240",
                res: 240,
              };
              i++;
            }
            if (
              res.data.data.video_360 !== null &&
              res.data.data.video_360 !== undefined &&
              res.data.data.video_360.length > 0
            ) {
              res_arr[i] = {
                src:
                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.video_360,
                label: "360",
                res: 360,
              };
              i++;
            }
            if (
              res.data.data.video_480 !== null &&
              res.data.data.video_480 !== undefined &&
              res.data.data.video_480.length > 0
            ) {
              res_arr[i] = {
                src:
                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.video_480,
                label: "480",
                res: 480,
              };
              i++;
            }
            if (
              res.data.data.video !== null &&
              res.data.data.video !== undefined &&
              res.data.data.video.length > 0
            ) {
              res_arr[i] = {
                src:
                  process.env.REACT_APP_AWS_IMG_VID_PATH + res.data.data.video,
                label: "Original",
              };
              i++;
            }
            setvideooptions({
              videoid: selectedVideoId,
              startTime:
                res.data.data.user_video_pause !== null &&
                res.data.data.user_video_pause !== undefined &&
                res.data.data.user_video_pause[
                  localStorage.getItem("adminuserid")
                ] !== undefined &&
                res.data.data.user_video_pause !== null &&
                res.data.data.user_video_pause[
                  localStorage.getItem("adminuserid")
                ] !== null
                  ? res.data.data.user_video_pause[
                      localStorage.getItem("adminuserid")
                    ]
                  : 0,
              playbackRates: [1, 1.25, 1.5, 1.75, 2],
              width: 640,
              height: 360,
              videoUrl:res?.data?.data?.video_url,
              autoplay: false,
              poster:
                res.data.data.thumbnail !== undefined &&
                res.data.data.thumbnail !== null
                  ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                    res.data.data.thumbnail
                  : "",
              controls: true,
              plugins: {
                videoJsResolutionSwitcher: {
                  default: "height",
                  dynamicLabel: true,
                },
              },
              sources: res_arr,
              html5: {
                nativeTextTracks: false,
              },
              subtitle: res.data.data.subtitle_file,
              preload: "auto"
            });
            setvideodetail(res.data.data);
            setDataLoader(false)
          }
        })
        .catch((e) => {
          console.log(e);
        });
      authService
        .getallcomments_videoadmin({ videoid: selectedVideoId, page: 1 })
        .then((res) => {
          if (res.data.status) {
            if (
              res.data.data[0].comment !== null &&
              res.data.data[0].comment !== undefined &&
              res.data.data[0].comment.length > 0
            ) {
              var data = res.data.data[0].comment.map((comm) => {
                var reply = [];
                var rereply = [];
                if (comm.comments.length > 0) {
                  reply = comm.comments.map((reply) => {
                    if (reply.comments.length > 0) {
                      rereply = reply.comments.map((r) => {
                        return {
                          ...r,
                          likes: { count: r.likes.length, list: r.likes },
                        };
                      });
                    }
                    return {
                      ...reply,
                      likes: { count: reply.likes.length, list: reply.likes },
                      comments: rereply,
                    };
                  });
                }
                return {
                  ...comm,
                  likes: { count: comm.likes.length, list: comm.likes },
                  comments: reply,
                };
              });
              setallcomments(data);
              settotalcommts(res.data.data[0].totalComments);
            } else {
              setallcomments([]);
            }
          } else {
            setallcomments([]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedVideoId]);
  const handledelete = () => {
    authService
      .deletecontentvideobyId(deleteid)
      .then((res) => {
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
          if (res.data.status) {
            history(`/contentlibrary/listing/noid/0`);
          }
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  console.log(videodetail);
  const handlelike = () => {
    if (
      videodetail.likes.filter((usr) => {
        if (usr.id === localStorage.getItem("adminuserid")) return usr;
      }).length > 0
    ) {
      var arr = videodetail.likes.filter((lik) => {
        if (lik.id !== localStorage.getItem("adminuserid")) return lik;
      });
      setvideodetail({ ...videodetail, likes: [...arr] });
    } else {
      setvideodetail({
        ...videodetail,
        likes: [
          ...videodetail.likes,
          {
            id: localStorage.getItem("adminuserid"),
            name: localStorage.getItem("username"),
            user: "adminuser",
          },
        ],
      });
    }

    authService.addlikebyadmin(selectedVideoId);
  };
  const handlewatched = () => {
    authService.addviewbyadmin(selectedVideoId, 'web').then((res) => {});
  };
  return (
    <>
    {dataLoader ? <div className="cstm-data-edit-load2"><img src={Loader} /> </div> :
    <div className="users-info-content">
      <div className="users-info-content-main cstm-video-detail-width">
        <div className="uic-title">
          <h3
            onClick={() => {
              history(`/contentlibrary/listing/noid/0`);
            }}
          >
            <img src={VideoDetailBAck} />
            Back
          </h3>
          <div className="uic-fl-link">
            <span>
              <Link
              to={`/contentlibrary/edit/${selectedVideoId}/0`}
                className="edit-link"
              >
                Edit video
              </Link>
              <a
                data-toggle="modal"
                data-target="#DeleteVideoInDtl"
                onClick={() => {
                  setdeleteid(selectedVideoId);
                }}
                className="delete-link"
              >
                Delete video
              </a>
            </span>
          </div>
        </div>
        {showalert && <div className="alert alert-info">{alertmsg}</div>}
        {selectedVideoId.length > 0 &&
        videodetail !== undefined &&
        Object.keys(videodetail).length > 0 &&
        Object.keys(videooptions).length > 0 ? (
          <>
            <div className="videodetail adminVideodetail">
              <VideoPlayer
                videooptions={videooptions}
                setvideooptions={setvideooptions}
              />
            </div>
            <div className="cstm-vd-cnt">
              <div className="cstm-vd-cnt-left">
                <h4>{videodetail.title}</h4>
                {videodetail !== undefined &&
                videodetail.description !== undefined ? (
                  <ShowMoreText
                  lines={3}
                  more="Show more"
                  less="Show less"
                  className="content-css"
                  anchorclassName="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  <div
                    className="cstm-videodes"
                    dangerouslySetInnerHTML={{
                      __html: videodetail.description,
                    }}
                  ></div></ShowMoreText>
                ) : (
                  <></>
                )}
                <div className="cstm-admin-cate-tag">
                  {videodetail.categories !== undefined &&
                  videodetail.categories !== null &&
                  videodetail.categories.length > 0 ? (
                    <span className="cstm-vd-ttl-sb">Categories</span>
                  ) : (
                    <></>
                  )}
                  {videodetail.categories.map((cate) => {
                    return (
                      <a className="musicvideobtn">
                        <button
                          onClick={() => {
                            history(
                              "/contentlibrary/listing/noid/0?category=" +
                                cate._id
                            );
                          }}
                        >
                          {cate?.name}
                        </button>
                      </a>
                    );
                  })}
                  {videodetail.subcategory ? (
                    videodetail.subcategory.map((cate) => {
                      return (
                        <a className="musicvideobtn" style={{ cursor: "none" }}>
                          <button>{cate?.name}</button>
                        </a>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>

                <div className="cstm-admin-grup-tag">
                  {videodetail.group_ids !== undefined &&
                  videodetail.group_ids !== null &&
                  videodetail.group_ids.length > 0 ? (
                    <span className="cstm-vd-ttl-sb">Groups</span>
                  ) : (
                    <></>
                  )}
                  {videodetail.group_ids && videodetail.group_ids.map((grp) => {
                    return (
                      <a className="musicvideobtn">
                        <button>{grp.groupTitle}</button>
                      </a>
                    );
                  })}
                </div>
                <div className="cstm-admin-grup-tag">
                  {videodetail.speaker  &&
                  videodetail.speaker.length > 0 ? (
                    <span className="cstm-vd-ttl-sb">Speakers</span>
                  ) : (
                    <></>
                  )}
                  {videodetail.speaker && videodetail.speaker.map((speaker) => {
                    return (
                      <a className="musicvideobtn">
                        <button>{speaker?.display_name}</button>
                      </a>
                    );
                  })}
                </div>
                <div className="cstm-admin-grup-tag">
                  {videodetail.eventIds  &&
                  videodetail.eventIds.length > 0 ? (
                    <span className="cstm-vd-ttl-sb">Events</span>
                  ) : (
                    <></>
                  )}
                  {videodetail.eventIds && videodetail.eventIds.map((event) => {
                    return (
                      <a className="musicvideobtn">
                        <button>{event.title}</button>
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className="cstm-vd-cnt-right">
                <span className="videodetailicon">
                  <img className="icon-align" src={likebtn} />
                  {videodetail.likes !== undefined &&
                  videodetail.likes.length > 0
                    ? `${millify(videodetail.likes.length)} Likes`
                    : "0 Like"}
                </span>
                <span className="videodetailicon">
                  <img className="icon-align" src={VideoViewsImage} />
                  {videodetail.views !== undefined &&
                  videodetail.views.length > 0
                    ? `${millify(
                        videodetail.starting_view_cnt
                          ? videodetail.views.length +
                              videodetail.starting_view_cnt
                          : videodetail.views.length
                      )} Views`
                    : "0 View"}
                </span>
                <span className="videodetailicon">
                  <img className="icon-align" src={VideoCalendarImage} />
                  {moment(videodetail.createdAt).fromNow()}
                </span>
              </div>
            </div>
            {videodetail.files.length > 0 ||
            videodetail.clif_notes.length > 0 ||
            videodetail.relevant_partners.length > 0 ? (
              <>
                <div className="profile-detilas-all-nav cstm-profile-details-all-nav admin-profile-details-all-nav">
                  <ul className="nav profiledt-menu  cstm-tab-add-video">
                  {videodetail.relevant_partners.length > 0 ? (
                    <li>
                      <a
                        className="active cstm-profile-link"
                        id="Relevant-partner"
                        data-toggle="tab"
                        href="#detailrelevantpartner"
                        role="tab"
                      >
                        Relevant partners
                      </a>
                    </li>) : <></>}
                    {videodetail.clif_notes.length > 0 && videodetail.clif_notes[0].length > 0 ? (
                    <li>
                      <a
                        className="cstm-profile-link"
                        id="Clif-notes"
                        data-toggle="tab"
                        href="#detailclifnotes"
                        role="tab"
                      >
                        Clif notes
                      </a>
                    </li> ) : <></>}
                    {videodetail.files.length > 0 ? (
                    <li>
                      <a
                        className="cstm-profile-link"
                        id="video-files"
                        data-toggle="tab"
                        href="#detailvideofiles"
                        role="tab"
                      >
                        Files
                      </a>
                    </li>) : <></>}
                  </ul>
                </div>
                <div
                  className="tab-content profile-details-tabs"
                  id="myTabContent"
                >
                  <div
                    className={`tab-pane fade ${videodetail.relevant_partners.length > 0 ? "active show" :""}`}
                    id="detailrelevantpartner"
                    role="tabpanel"
                  >
                    <span className="cstm-vd-ttl-sb">Relevant Partners</span>
                    <div className="cstm-vd-box-in">
                      {videodetail.relevant_partners.length > 0 ? (
                        videodetail.relevant_partners.map((partner) => {
                          return (
                            <a href={partner.url} target="_blank">
                              <div className="cstm-vd-box-in-main">
                                {partner.logo ? (
                                  <img
                                    src={
                                      process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      partner.logo
                                    }
                                  />
                                ) : (
                                  <></>
                                )}
                                <span className="cstm-vd-box-in-title">
                                  {partner?.name}
                                </span>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="newsfeed-post">
                          {" "}
                          <span className="emptymsg">No Data Found</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                   className={`tab-pane fade ${videodetail.relevant_partners && videodetail.relevant_partners.length === 0 && videodetail.clif_notes && videodetail.clif_notes.length > 0 && videodetail.clif_notes[0].length > 0 ? "active show" :""}`} 
                    id="detailclifnotes"
                    role="tabpanel"
                    aria-labelledby="Groupposts-tab"
                  >
                    <span className="cstm-vd-ttl-sb">Clif notes</span>
                    <div className="cstm-clif-notes-mn clif-notes-wrapper">
                      {videodetail.clif_notes.length > 0 ? (
                        videodetail.clif_notes.map((note) => {
                          return (
                            <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorclassName="my-anchor-css-class"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: note }}
                            ></div></ShowMoreText>
                          );
                        })
                      ) : (
                        <div className="newsfeed-post">
                          {" "}
                          <span className="emptymsg">No Data Found</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                   className={`tab-pane fade ${videodetail.relevant_partners && videodetail.relevant_partners.length === 0 && videodetail.clif_notes && (videodetail.clif_notes.length === 0 || (videodetail.clif_notes.length > 0 && videodetail.clif_notes[0].length === 0)) && videodetail.files && videodetail.files.length > 0 ? "active show" :""}`} 
                    id="detailvideofiles"
                    role="tabpanel"
                  >
                    <span className="cstm-vd-ttl-sb">Files</span>

                    <div className="cstm-vd-box-in">
                      {videodetail.files.length > 0 ? (
                        videodetail.files.map((fl) => {
                          return (
                            <div className="cstm-vd-box-in-main">
                              <img
                                src={
                                  fl.url.split(".").pop() === "jpg" ||
                                  fl.url.split(".").pop() === "png" ||
                                  fl.url.split(".").pop() === "jpeg"
                                    ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      fl.url
                                    : fl.url.split(".").pop() === "pdf"
                                    ? PdfImg
                                    : DocImg
                                }
                                onClick={() => {
                                  saveAs(
                                    process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      fl.url,
                                    fl.name !== undefined &&
                                      fl.name !== null &&
                                      fl.name.length > 0
                                      ? fl.name
                                      : fl.url.split("-").pop()
                                  );
                                }}
                              />
                              <span className="cstm-vd-box-in-title">
                                {fl?.name}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <div className="newsfeed-post">
                          {" "}
                          <span className="emptymsg">No Data Found</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <Comments
              videoId={selectedVideoId}
              allcomments={allcomments}
              setallcomments={setallcomments}
              totalcommts={totalcommts}
              selectedVideoId={selectedVideoId}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="DeleteVideoInDtl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgbigdelete} />
            </div>
            <h1>Are you sure you want to delete this video?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handledelete}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       }
       </>
  );
};
export default VideoDetail;
