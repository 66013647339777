import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import InputEmoji from "react-input-emoji";
import Services from "../../../Services/auth.service";
import File1 from "../../../Images/image1.png";
import Dotted from "../../../Images/dotted.svg";
import Delete2 from "../../../Images/delete2.svg";
import fillheart from "../../../Images/fillheart.svg";
import Heart from "../../../Images/heart.svg";
import replyimg from "../../../Images/reply-line.svg";
import timeimg from "../../../Images/time-line.svg";
import CommentReply from "./CommentReply";
import CommentReReply from "./CommentReReply";
import cmtprocess from "../../../Images/cmtprocess.gif";
import cmtloading from "../../../Images/lodemorecmt.gif";

const PostComments = ({
  allcomments,
  cntcmt,
  post,
  deletecmt,
  userIsMember,
  likecomment,
  USER_ID,
  totalcmt,
  addcomment,
  setrereply,
  rereply,
  reply,
  handlemorecmt,
  cmtloader,
  setreply,
  showcinput,
  setComment,
  comment,
  cmtinprocess,
  replyinprocess,
  rereplyinprocess,
  called_from,
}) => {
  const inputRef = useRef(null);
  const history = useNavigate();
  const [allusers, setallusers] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);

  useEffect(() => {
    // console.log(cmtinprocess,"cmtprocess in post comment");
    if (called_from === "user") {
      Services.getalluseremailname()
        .then((res) => {
          if (res.data.status) {
            setallusers(res.data.data);
            var arr = [];
            for (var i = 0; i < res.data.data.length; i++) {
              arr[i] =
                res.data.data[i].otherdetail[
                  process.env.REACT_APP_FIRSTNAME_DBID
                ] +
                " " +
                res.data.data[i].otherdetail[
                  process.env.REACT_APP_LASTNAME_DBID
                ];
            }
            setSuggestionList(arr);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      Services.getalluseremailname_admin()
        .then((res) => {
          if (res.data.status) {
            setallusers(res.data.data);
            var arr = [];
            for (var i = 0; i < res.data.data.length; i++) {
              arr[i] =
                res.data.data[i].otherdetail[
                  process.env.REACT_APP_FIRSTNAME_DBID
                ] +
                " " +
                res.data.data[i].otherdetail[
                  process.env.REACT_APP_LASTNAME_DBID
                ];
            }
            setSuggestionList(arr);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);
  function onChange(text) {
    setComment(text);
    const regexp = /@[a-zA-Z0-9]*$/;
    if (regexp.test(text.split(" ").pop())) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }
  function focusInput() {
    inputRef.current.focus();
  }
  return (
    <>
      {showcinput[post._id] !== undefined && showcinput[post._id] ? (
        <div id={post._id}>
          {userIsMember ? (
            <div className="newsfeed-post-right-write">
              <div className="newsfeed-post-right-write-left">
                {(localStorage.getItem("userprofile") &&
                  localStorage.getItem("userprofile").length) ? (
                  <img
                    src={
                      localStorage.getItem("userprofile") !== null &&
                      localStorage.getItem("userprofile") !== undefined &&
                      localStorage.getItem("userprofile").length > 0
                        ? localStorage.getItem("userprofile")
                        : File1
                    }
                  />
                ) : (
                  <div class="cstm-avat-box">
                    {localStorage.getItem("username")
                      ? localStorage.getItem("username").slice(0,1)
                      : ""}
                  </div>
                )}
              </div>
              <div className="newsfeed-post-right-write-right">
                <div className="newsfeed-write-your-comments-tx">
                  <InputEmoji
                    ref={inputRef}
                    value={comment}
                    id={post._id}
                    //   onChange={setComment}
                    onChange={(text) => {
                      onChange(text);
                    }}
                    onEnter={(text) => {
                      if (!cmtinprocess) addcomment(post._id, "");
                    }}
                    placeholder={
                      comment.length === 0 ? "Write your comments..." : ""
                    }
                  />
                  <span
                    className="wyc-send"
                    onClick={() => {
                      if (!cmtinprocess) addcomment(post._id, "");
                    }}
                  >
                    {cmtinprocess ? (
                      <img src={cmtprocess} className="dis-loader-cmt" />
                    ) : (
                      <i className="fa fa-paper-plane"></i>
                    )}
                  </span>
                </div>
                {showSuggestions && (
                  <Suggestions
                    inputValue={comment}
                    suggestionList={suggestionList}
                    applyMention={onChange}
                    focusInput={focusInput}
                    setShowSuggestions={setShowSuggestions}
                    showSuggestions={showSuggestions}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {allcomments !== undefined &&
          allcomments !== null &&
          allcomments.length > 0 ? (
            <>
              <div className="comment-main">
                <div className="comment-main-title">Comments</div>
                {allcomments.map((coment, index) => {
                  cntcmt++;
                  return post.comments.indexOf(coment._id) !== -1 ? (
                    <div className="comment-main-box">
                      <div className="comment-main-box-left">
                        {" "}
                        <img
                          src={
                            coment.userId.profileImg !== undefined &&
                            coment.userId.profileImg.length > 0
                              ? coment.userId.profileImg
                              : File1
                          }
                        />
                      </div>
                      <div className="comment-main-box-right">
                        <div className="comment-main-box-right-box">
                          <div className="comment-main-box-right-box-name">
                            <div className="comment-main-box-right-box-name-name">
                              {coment.user_type !== "adminuser"
                                ? coment.userId.otherdetail[
                                    process.env.REACT_APP_FIRSTNAME_DBID
                                  ] +
                                  " " +
                                  coment.userId.otherdetail[
                                    process.env.REACT_APP_LASTNAME_DBID
                                  ]
                                : coment.userId.first_name +
                                  " " +
                                  coment.userId.last_name}
                            </div>
                            <div className="comment-main-box-right-box-name-dotted">
                              {coment.userId._id === USER_ID ? (
                                <div className="newfeed-dp cstm-dtl-poll">
                                  <span
                                    className="cstm-poll-ad"
                                    data-toggle="dropdown"
                                  >
                                    <img src={Dotted} />
                                  </span>
                                  <div className="dropdown-menu cstm-edit-delete-poll">
                                    <ul>
                                      <li
                                        onClick={() => {
                                          deletecmt(post._id, coment._id);
                                        }}
                                      >
                                        <a>
                                          <img src={Delete2} />
                                          Delete
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="comment-main-box-right-box-cont">
                            {coment.content.split(" ").map((word, cm_index) => {
                              var at_index;
                              var sec_index;
                              coment.content.split(" ").map((w, ind) => {
                                if (
                                  w.slice(0,1) === "@" &&
                                  suggestionList.includes(
                                    w.replace("@", "") +
                                      " " +
                                      coment.content.split(" ")[ind + 1]
                                  )
                                ) {
                                  at_index = ind;
                                  sec_index = ind + 1;
                                }
                              });

                              if (sec_index !== cm_index) {
                                if (
                                  word.slice(0,1) === "@" &&
                                  suggestionList.includes(
                                    word.replace("@", "") +
                                      " " +
                                      coment.content.split(" ")[cm_index + 1]
                                  )
                                ) {
                                  return (
                                    <b
                                      onClick={() => {
                                        var uid = allusers.filter((user) => {
                                          if (
                                            user.otherdetail[
                                              process.env
                                                .REACT_APP_FIRSTNAME_DBID
                                            ] +
                                              " " +
                                              user.otherdetail[
                                                process.env
                                                  .REACT_APP_LASTNAME_DBID
                                              ] ===
                                            word.replace("@", "") +
                                              " " +
                                              coment.content.split(" ")[
                                                cm_index + 1
                                              ]
                                          )
                                            return user;
                                        })[0]._id;
                                        history(`/settings/edit`);
                                      }}
                                    >
                                      {word +
                                        " " +
                                        coment.content.split(" ")[
                                          cm_index + 1
                                        ]}{" "}
                                    </b>
                                  );
                                } else {
                                  return <>{word} </>;
                                }
                              } else {
                                return <></>;
                              }
                            })}
                          </div>
                        </div>
                        <div className="comment-main-box-right-icon">
                          <div className="comment-main-box-right-icon-left">
                            <span
                              className="newfeed-img-name"
                              onClick={() => {
                                likecomment(post._id, coment._id, "cmt");
                              }}
                            >
                              {" "}
                              {coment.likes.list.includes(USER_ID) ? (
                                <img src={fillheart} />
                              ) : (
                                <img src={Heart} />
                              )}
                              {coment.likes.count} Likes
                            </span>
                            <span
                              className="newfeed-img-name"
                              onClick={() => {
                                document.getElementById(
                                  coment._id
                                ).style.display =
                                  document.getElementById(coment._id).style
                                    .display === "block"
                                    ? "none"
                                    : "block";
                              }}
                            >
                              {" "}
                              <img src={replyimg} />
                              {coment.comments.length} Reply
                            </span>
                          </div>
                          <div className="comment-main-box-right-icon-right">
                            <span className="comment-time-st">
                              <img src={timeimg} />{" "}
                              {moment(coment.createdAt).fromNow()}
                            </span>
                          </div>
                        </div>
                        <div className="comment-main-box-right-recmt">
                          <div id={coment._id} style={{ display: "none" }}>
                            {userIsMember ? (
                              <div className="newsfeed-post-right-write2">
                                <div className="newsfeed-post-right-write-left">
                                  {(localStorage.getItem("userprofile") &&
                                    localStorage.getItem("userprofile")
                                      .length) ? (
                                    <img
                                      src={
                                        localStorage.getItem("userprofile") !==
                                          null &&
                                        localStorage.getItem("userprofile") !==
                                          undefined &&
                                        localStorage.getItem("userprofile")
                                          .length > 0
                                          ? localStorage.getItem("userprofile")
                                          : File1
                                      }
                                    />
                                  ) : (
                                    <div class="cstm-avat-box">
                                      {localStorage.getItem("username")
                                        ? localStorage
                                            .getItem("username")
                                            .slice(0,1)
                                        : ""}
                                    </div>
                                  )}
                                </div>
                                <CommentReply
                                  allcomments={allcomments}
                                  coment={coment}
                                  reply={reply}
                                  setreply={setreply}
                                  index={index}
                                  postId={post}
                                  addcomment={addcomment}
                                  cmtinprocess={cmtinprocess}
                                  suggestionList={suggestionList}
                                  replyinprocess={replyinprocess}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            {coment.comments.length > 0
                              ? coment.comments.map((cmt, cmt_index) => {
                                  return (
                                    <>
                                      <div className="comment-main-box-right-recmt-left">
                                        {" "}
                                        <img
                                          src={
                                            cmt.userId.profileImg !==
                                              undefined &&
                                            cmt.userId.profileImg.length > 0
                                              ? cmt.userId.profileImg
                                              : File1
                                          }
                                        />
                                      </div>
                                      <div className="comment-main-box-right-recmt-right">
                                        <div className="comment-main-box-right-box">
                                          <div className="comment-main-box-right-box-name">
                                            <div className="comment-main-box-right-box-name-name">
                                              {cmt.user_type !== "adminuser"
                                                ? cmt.userId.otherdetail[
                                                    process.env
                                                      .REACT_APP_FIRSTNAME_DBID
                                                  ] +
                                                  " " +
                                                  cmt.userId.otherdetail[
                                                    process.env
                                                      .REACT_APP_LASTNAME_DBID
                                                  ]
                                                : cmt.userId.first_name +
                                                  " " +
                                                  cmt.userId.last_name}
                                            </div>
                                            {cmt.userId._id === USER_ID ? (
                                              <div className="comment-main-box-right-box-name-dotted">
                                                <div className="newfeed-dp cstm-dtl-poll">
                                                  <span
                                                    className="cstm-poll-ad"
                                                    data-toggle="dropdown"
                                                  >
                                                    <img src={Dotted} />
                                                  </span>
                                                  <div className="dropdown-menu cstm-edit-delete-poll">
                                                    <ul>
                                                      <li
                                                        onClick={() => {
                                                          deletecmt(
                                                            post._id,
                                                            cmt._id
                                                          );
                                                        }}
                                                      >
                                                        <a>
                                                          <img src={Delete2} />
                                                          Delete
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div className="comment-main-box-right-box-cont">
                                              {cmt.content
                                                .split(" ")
                                                .map((word, reindex) => {
                                                  var at_index;
                                                  var sec_index;
                                                  cmt.content
                                                    .split(" ")
                                                    .map((w, ind) => {
                                                      if (
                                                        w.slice(0,1) === "@" &&
                                                        suggestionList.includes(
                                                          w.replace("@", "") +
                                                            " " +
                                                            cmt.content.split(
                                                              " "
                                                            )[ind + 1]
                                                        )
                                                      ) {
                                                        at_index = ind;
                                                        sec_index = ind + 1;
                                                      }
                                                    });
                                                  console.log(word, "worddd");
                                                  if (sec_index !== reindex) {
                                                    if (
                                                      word.slice(0,1) === "@" &&
                                                      suggestionList.includes(
                                                        word.replace("@", "") +
                                                          " " +
                                                          cmt.content.split(
                                                            " "
                                                          )[reindex + 1]
                                                      )
                                                    ) {
                                                      return (
                                                        <b
                                                          onClick={() => {
                                                            var uid =
                                                              allusers.filter(
                                                                (user) => {
                                                                  if (
                                                                    user
                                                                      .otherdetail[
                                                                      process
                                                                        .env
                                                                        .REACT_APP_FIRSTNAME_DBID
                                                                    ] +
                                                                      " " +
                                                                      user
                                                                        .otherdetail[
                                                                        process
                                                                          .env
                                                                          .REACT_APP_LASTNAME_DBID
                                                                      ] ===
                                                                    word.replace(
                                                                      "@",
                                                                      ""
                                                                    ) +
                                                                      " " +
                                                                      cmt.content.split(
                                                                        " "
                                                                      )[
                                                                        reindex +
                                                                          1
                                                                      ]
                                                                  )
                                                                    return user;
                                                                }
                                                              )[0]._id;
                                                            history(
                                                              `/settings/edit`
                                                            );
                                                          }}
                                                        >
                                                          {word +
                                                            " " +
                                                            cmt.content.split(
                                                              " "
                                                            )[reindex + 1]}{" "}
                                                        </b>
                                                      );
                                                    } else {
                                                      return <>{word} </>;
                                                    }
                                                  } else {
                                                    return <></>;
                                                  }
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="comment-main-box-right-icon">
                                          <div className="comment-main-box-right-icon-left">
                                            <span
                                              className="newfeed-img-name"
                                              onClick={() => {
                                                if (userIsMember)
                                                  likecomment(
                                                    post._id,
                                                    cmt._id,
                                                    "reply"
                                                  );
                                              }}
                                            >
                                              {" "}
                                              {cmt.likes.list.includes(
                                                USER_ID
                                              ) ? (
                                                <img src={fillheart} />
                                              ) : (
                                                <img src={Heart} />
                                              )}
                                              {cmt.likes.count} Likes
                                            </span>
                                            <span
                                              className="newfeed-img-name"
                                              onClick={() => {
                                                document.getElementById(
                                                  cmt._id
                                                ).style.display =
                                                  document.getElementById(
                                                    cmt._id
                                                  ).style.display === "block"
                                                    ? "none"
                                                    : "block";
                                              }}
                                            >
                                              {" "}
                                              <img src={replyimg} />
                                              {cmt.comments.length} Reply
                                            </span>
                                          </div>
                                          <div className="comment-main-box-right-icon-right">
                                            <span className="comment-time-st res-comment-time-st">
                                              <img src={timeimg} />{" "}
                                              <span className="res-comment-time-st-inner">
                                                {moment(
                                                  cmt.createdAt
                                                ).fromNow()}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="comment-main-box-right-recmt-right">
                                        <div
                                          id={cmt._id}
                                          style={{
                                            display: "none",
                                          }}
                                        >
                                          {userIsMember ? (
                                            <div className="newsfeed-post-right-write2">
                                              <div className="newsfeed-post-right-write-left">
                                                <img
                                                  src={
                                                    localStorage.getItem(
                                                      "userprofile"
                                                    ) !== undefined &&
                                                    localStorage.getItem(
                                                      "userprofile"
                                                    ).length > 0
                                                      ? localStorage.getItem(
                                                          "userprofile"
                                                        )
                                                      : File1
                                                  }
                                                />
                                              </div>
                                              <CommentReReply
                                                allcomments={allcomments}
                                                coment={cmt}
                                                reply={rereply}
                                                setreply={setrereply}
                                                index={cmt_index}
                                                postId={post}
                                                addcomment={addcomment}
                                                cmtinprocess={cmtinprocess}
                                                suggestionList={suggestionList}
                                                rereplyinprocess={
                                                  rereplyinprocess
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <></>
                                          )}

                                          {cmt.comments.length > 0
                                            ? cmt.comments.map((rply) => {
                                                return (
                                                  <>
                                                    <div className="comment-main-box-right-recmt-left">
                                                      {" "}
                                                      <img
                                                        src={
                                                          rply.userId
                                                            .profileImg !==
                                                            undefined &&
                                                          rply.userId.profileImg
                                                            .length > 0
                                                            ? rply.userId
                                                                .profileImg
                                                            : File1
                                                        }
                                                      />
                                                    </div>
                                                    <div className="comment-main-box-right-recmt-right">
                                                      <div className="comment-main-box-right-box">
                                                        <div className="comment-main-box-right-box-name">
                                                          <div className="comment-main-box-right-box-name-name">
                                                            {cmt.user_type !==
                                                            "adminuser"
                                                              ? cmt.userId
                                                                  .otherdetail[
                                                                  process.env
                                                                    .REACT_APP_FIRSTNAME_DBID
                                                                ] +
                                                                " " +
                                                                cmt.userId
                                                                  .otherdetail[
                                                                  process.env
                                                                    .REACT_APP_LASTNAME_DBID
                                                                ]
                                                              : cmt.userId
                                                                  .first_name +
                                                                " " +
                                                                cmt.userId
                                                                  .last_name}
                                                          </div>

                                                          {cmt.userId._id ===
                                                          USER_ID ? (
                                                            <div className="comment-main-box-right-box-name-dotted">
                                                              <div className="newfeed-dp cstm-dtl-poll">
                                                                <span
                                                                  className="cstm-poll-ad"
                                                                  data-toggle="dropdown"
                                                                >
                                                                  <img
                                                                    src={Dotted}
                                                                  />
                                                                </span>
                                                                <div className="dropdown-menu cstm-edit-delete-poll">
                                                                  <ul>
                                                                    <li>
                                                                      <a
                                                                        onClick={() => {
                                                                          deletecmt(
                                                                            post._id,
                                                                            rply._id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={
                                                                            Delete2
                                                                          }
                                                                        />
                                                                        Delete
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                        <div className="comment-main-box-right-box-cont">
                                                          {rply.content
                                                            .split(" ")
                                                            .map(
                                                              (
                                                                word,
                                                                repindex
                                                              ) => {
                                                                var at_index;
                                                                var sec_index;
                                                                rply.content
                                                                  .split(" ")
                                                                  .map(
                                                                    (
                                                                      w,
                                                                      ind
                                                                    ) => {
                                                                      if (
                                                                        w.charAt(
                                                                          0
                                                                        ) ===
                                                                          "@" &&
                                                                        suggestionList.includes(
                                                                          w.replace(
                                                                            "@",
                                                                            ""
                                                                          ) +
                                                                            " " +
                                                                            rply.content.split(
                                                                              " "
                                                                            )[
                                                                              ind +
                                                                                1
                                                                            ]
                                                                        )
                                                                      ) {
                                                                        at_index =
                                                                          ind;
                                                                        sec_index =
                                                                          ind +
                                                                          1;
                                                                      }
                                                                    }
                                                                  );

                                                                if (
                                                                  sec_index !==
                                                                  repindex
                                                                ) {
                                                                  console.log(
                                                                    allusers.filter(
                                                                      (
                                                                        user
                                                                      ) => {
                                                                        if (
                                                                          user
                                                                            .otherdetail[
                                                                            process
                                                                              .env
                                                                              .REACT_APP_FIRSTNAME_DBID
                                                                          ] +
                                                                            " " +
                                                                            user
                                                                              .otherdetail[
                                                                              process
                                                                                .env
                                                                                .REACT_APP_LASTNAME_DBID
                                                                            ] ===
                                                                          word.replace(
                                                                            "@",
                                                                            ""
                                                                          ) +
                                                                            " " +
                                                                            rply.content.split(
                                                                              " "
                                                                            )[
                                                                              repindex +
                                                                                1
                                                                            ]
                                                                        )
                                                                          return user;
                                                                      }
                                                                    ),
                                                                    "fdjghgdfjkgkjgfdkjgkfdkg"
                                                                  );
                                                                  if (
                                                                    word.charAt(
                                                                      0
                                                                    ) === "@" &&
                                                                    suggestionList.includes(
                                                                      word.replace(
                                                                        "@",
                                                                        ""
                                                                      ) +
                                                                        " " +
                                                                        rply.content.split(
                                                                          " "
                                                                        )[
                                                                          repindex +
                                                                            1
                                                                        ]
                                                                    )
                                                                  ) {
                                                                    return (
                                                                      <b
                                                                        onClick={() => {
                                                                          var uid =
                                                                            allusers.filter(
                                                                              (
                                                                                user
                                                                              ) => {
                                                                                if (
                                                                                  user
                                                                                    .otherdetail[
                                                                                    process
                                                                                      .env
                                                                                      .REACT_APP_FIRSTNAME_DBID
                                                                                  ] +
                                                                                    " " +
                                                                                    user
                                                                                      .otherdetail[
                                                                                      process
                                                                                        .env
                                                                                        .REACT_APP_LASTNAME_DBID
                                                                                    ] ===
                                                                                  word.replace(
                                                                                    "@",
                                                                                    ""
                                                                                  ) +
                                                                                    " " +
                                                                                    rply.content.split(
                                                                                      " "
                                                                                    )[
                                                                                      repindex +
                                                                                        1
                                                                                    ]
                                                                                )
                                                                                  return user;
                                                                              }
                                                                            )[0]
                                                                              ._id;
                                                                          history(
                                                                            `/settings/edit`
                                                                          );
                                                                        }}
                                                                      >
                                                                        {word +
                                                                          " " +
                                                                          rply.content.split(
                                                                            " "
                                                                          )[
                                                                            repindex +
                                                                              1
                                                                          ]}{" "}
                                                                      </b>
                                                                    );
                                                                  } else {
                                                                    return (
                                                                      <>
                                                                        {word}{" "}
                                                                      </>
                                                                    );
                                                                  }
                                                                } else {
                                                                  return <></>;
                                                                }
                                                              }
                                                            )}
                                                        </div>
                                                      </div>
                                                      <div className="comment-main-box-right-icon">
                                                        <div className="comment-main-box-right-icon-left">
                                                          <span
                                                            className="newfeed-img-name"
                                                            onClick={() => {
                                                              if (userIsMember)
                                                                likecomment(
                                                                  post._id,
                                                                  rply._id,
                                                                  "rereply"
                                                                );
                                                            }}
                                                          >
                                                            {" "}
                                                            {rply.likes.list.includes(
                                                              USER_ID
                                                            ) ? (
                                                              <img
                                                                src={fillheart}
                                                              />
                                                            ) : (
                                                              <img
                                                                src={Heart}
                                                              />
                                                            )}
                                                            {rply.likes.count}{" "}
                                                            Likes
                                                          </span>
                                                        </div>
                                                        <div className="comment-main-box-right-icon-right">
                                                          <span className="comment-time-st">
                                                            <img
                                                              src={timeimg}
                                                            />{" "}
                                                            {moment(
                                                              cmt.createdAt
                                                            ).fromNow()}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              })
                                            : ""}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  );
                })}
                {allcomments.length < totalcmt ? (
                  <div className="view-more-comments-link">
                    {!cmtloader ? (
                      <a
                        onClick={() => {
                          handlemorecmt(post._id);
                        }}
                      >
                        View more comments
                      </a>
                    ) : (
                      <>
                        <div className="loadind-post cmt-loading">
                          <img src={cmtloading} />{" "}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <>
              {" "}
              {post.comments.length !== 0 && (
                <div className="loadind-post cmt-loading">
                  <img src={cmtloading} />{" "}
                </div>
              )}
              {/* <div className="comment-main">
                <div className="emptymsg">No comments</div>
              </div> */}
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default PostComments;

function Suggestions(props) {
  function selectSuggestion(username) {
    const regexp = /@[a-zA-Z0-9]*$/;
    const newValue = props.inputValue.replace(regexp, username + " ");
    props.applyMention(newValue); // THIS MIMICS AN ONCHANGE EVENT
    props.focusInput();
    props.setShowSuggestions(false);
  }

  const suggestionItems = props.suggestionList.map((item) => (
    <div className="item" onClick={() => selectSuggestion("@" + item)}>
      @{item}
    </div>
  ));

  return props.showSuggestions ? (
    <div className="cstm-newsfeed-drop">{suggestionItems}</div>
  ) : (
    <></>
  );
}

function Suggestions_resp(props) {
  function selectSuggestion(username) {
    const regexp = /@[a-zA-Z0-9]*$/;
    props.setvalue({
      ...props.inputValue,
      [props.commentid]: props.inputValue[props.commentid].replace(
        regexp,
        username + " "
      ),
    });
    // props.applyMention(newValue);
    props.focusInput_resp(props.index);
    props.setShowSuggestions_resp({ [props.commentid]: false });
  }

  const suggestionItems = props.suggestionList.map((item) => (
    <div className="item" onClick={() => selectSuggestion("@" + item)}>
      @{item}
    </div>
  ));

  return <div className="cstm-newsfeed-drop">{suggestionItems}</div>;
}

function Suggestions_rereply(props) {
  function selectSuggestion(username) {
    const regexp = /@[a-zA-Z0-9]*$/;
    props.setvalue({
      ...props.inputValue,
      [props.commentid]: props.inputValue[props.commentid].replace(
        regexp,
        username + " "
      ),
    });
    // props.applyMention(newValue);
    props.focusInput_resp(props.index);
    props.setShowSuggestions_rereply({ [props.commentid]: false });
  }

  const suggestionItems = props.suggestionList.map((item) => (
    <div className="item" onClick={() => selectSuggestion("@" + item)}>
      @{item}
    </div>
  ));

  return <div className="cstm-newsfeed-drop">{suggestionItems}</div>;
}
