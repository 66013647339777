import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import ClosePopup from "../../../Images/close-popup.svg";
//Component
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import newsocket from "../../../components/SocketConnect";
import SearchField from "../../../components/InputField/SearchField";
import EmptyState from "../../../components/EmptyState/EmptyState";
import authService from "../../../Services/auth.service";
import Avtar from "../../../components/Avtar/Avtar";
import Typography from "../../../components/Typography/Typography";
import Checkbox from "../../../components/Checkbox/Checkbox";
import ChipMember from "../../../components/Chips/ChipMember";
import AvtarLarge from "../../../components/AvtarLarge/AvtarLarge";
import TextField from "../../../components/InputField/TextField";
import Separators from "../../../components/Separators/Separators";

// Css
import "./ChatStyle/CreateNewGroupModal.css";
import "../../../components/AdminMediaManagement/mediaUpload.css";

export default function CreateNewGroupModal({
  removeAddMemberSelected,
  alreadyExistingMembers = [],
  addMemberSelected,
  isOpenGroupModal,
  isCloseGroupModal,
  openChatListModel,
  setIsOpenGroupModal
}) {
  const history = useNavigate();
  let { userid, type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const myId = localStorage.getItem("userid");
  const userName = localStorage.getItem("username");
  const userid_ref = useRef();
  const allUserList = useSelector((state) => {
    return state.ReduAllUsersList;
  });
  const [searchInput, setSearchInput] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showNextPopup, setNextPopup] = useState(false);
  const [selectedUsersString, setSelectedUsersString] = useState("");
  const [GroupCreationLoader, setGroupCreationLoader] = useState(false);
  const [GroupProfileImg, setGroupProfileImg] = useState();
  const [showErrorState, setErrorState] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [cropedFile, setcropedFile] = useState();
  const [showGroupHistory, setShowGroupHistory] = useState(true);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [fileDimension, setFileDimension] = useState([]);

  //calling when page load cropping area
  // useEffect(() => {
  //   if (fileDimension.length !== 0) {
  //     console.log("File Dimension:", fileDimension); // Verify fileDimension data

  //     // Fetch image blob
  //     fetch(fileDimension[0]?.path)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error("Failed to fetch image");
  //         }
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         const imageUrl = URL.createObjectURL(blob);
  //         console.log("Image URL:", imageUrl); // Verify image URL

  //         const image = new Image();
  //         image.onload = () => {
  //           console.log("Image loaded successfully");
  //           const width = image.naturalWidth;
  //           const height = image.naturalHeight;
  //           const aspectRatio = 16 / 9;
  //           console.log("Width:", width);
  //           console.log("Height:", height);
  //           setFileWidthHeight({ width, height });
  //           setCrop(centerAspectCrop(width, height, aspectRatio));
  //         };
  //         image.onerror = (error) => {
  //           console.error("Error loading image:", error);
  //         };
  //         image.src = imageUrl; // Set src after onload/onerror handlers are attached
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching image:", error.message);
  //       });
  //   }
  // }, [fileDimension]);

  // //set cropping area in center
  // function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  //   return centerCrop(
  //     makeAspectCrop(
  //       {
  //         unit: "%",
  //         width: 50,
  //       },
  //       aspect,
  //       mediaWidth,
  //       mediaHeight
  //     ),
  //     mediaWidth,
  //     mediaHeight
  //   );
  // }

  // get url cropping image

  async function getCroppedBase64(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const cropInPercentage = {
      aspect: crop.aspect,
      unit: "%",
      width: crop.width,
      height: crop.height,
      x: crop.x,
      y: crop.y,
    };

    // Get the actual dimensions of the image
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;

    // Convert percentage values to pixels
    const cropInPixels = {
      aspect: cropInPercentage.aspect,
      unit: "px", // Change to 'px' to use pixel values
      width: (cropInPercentage.width / 100) * imageWidth,
      height: (cropInPercentage.height / 100) * imageHeight,
      x: (cropInPercentage.x / 100) * imageWidth,
      y: (cropInPercentage.y / 100) * imageHeight,
    };

    canvas.width = cropInPixels.width;
    canvas.height = cropInPixels.height;

    ctx.drawImage(
      image,
      cropInPixels.x,
      cropInPixels.y,
      cropInPixels.width,
      cropInPixels.height,
      0,
      0,
      cropInPixels.width,
      cropInPixels.height
    );

    // Convert the canvas content to a base64-encoded string
    const croppedImageUrl = canvas.toDataURL("image/jpeg");
    return croppedImageUrl;
  }

  //handle chaneg in crop
  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  //covert function dataURLtoFile
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], fileName, { type: mime });
  };

  //When Handle Cropping completed
  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCompletedCrop(croppedAreaPixels);
    setCrop(croppedAreaPixels);
  };

  // when crop submit
  const handleCropSubmit = async () => {
    const originalImage = document.querySelector(".crop-media-image");
    originalImage.crossOrigin = "anonymous";

    // Wait for the image to load
    await new Promise((resolve) => {
      originalImage.onload = resolve;
    });

    let croppedImageBlob;
    if (completedCrop) {
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        completedCrop,
        "crop-image" + new Date().getTime() + ".jpg"
      );
    } else {
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        crop,
        "crop-image" + new Date().getTime() + ".jpg"
      );
    }
    // Display the cropped image
    const croppedImageUrl = croppedImageBlob;
    const cropImageData = dataURLtoFile(croppedImageBlob);
    setModalOpen(false);
    setcropedFile(cropImageData);
    setGroupProfileImg(URL.createObjectURL(cropImageData));
    setFile("");
  };

  const onDrop = (acceptedFiles) => {
    setFileDimension(acceptedFiles);
    assignFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });

  const assignFiles = (acceptedFile) => {
    setModalOpen(true);
    setFileName(Math.random().toString() + acceptedFile[0].name);
    setFile(URL.createObjectURL(acceptedFile[0]));
  };

  useEffect(() => {
    // create group receive event
    newsocket.on("create-receive", (res) => {
      if (res.message.status) {
        newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
        const createdByUserId = res.message.data.created_by._id;
        const localStorageUserId = localStorage.getItem("userid");
        if (
          Boolean(createdByUserId === localStorageUserId) &&
          GroupCreationLoader
        ) {
          setIsOpenGroupModal(false)
          history(
            "/chats/" +
            res.message.data._id +
            "/" +
            "userChatGroup" +
            "?user_name=" +
            res.message.data.groupTitle +
            "&" +
            "user_image=" +
            res.message.data.groupImage
          );
          setGroupCreationLoader(false);
          setNextPopup(false)
          setSelectedUsers([]);
          isCloseGroupModal();
          setSearchInput("")
        } else {
          setGroupCreationLoader(false);
          isCloseGroupModal();
        }
      }
    });
    //add member receive event
    newsocket.on("add-member-receive", (res) => {
      if (res.message.status) {
        if (
          Boolean(
            res.message.data.created_by._id === localStorage.getItem("userid")
          )
        ) {
          setGroupCreationLoader(false);
          handleCancelModal();
        } else {
          setGroupCreationLoader(false);
        }
        if (res.message.data._id === userid_ref.current) {
          newsocket.emit("group-member-detail", {
            groupId: userid_ref.current,
          });
        }
      }
    });
  }, [newsocket, GroupCreationLoader]);
  useEffect(() => {
    if (addMemberSelected) {
      setSelectedUsers(alreadyExistingMembers);
    }
  }, [alreadyExistingMembers, addMemberSelected]);
  useEffect(() => {
    userid_ref.current = userid;
  }, [userid]);

  useEffect(() => {
    let namesString = "";

    // Ensure there are selected users
    if (selectedUsers.length > 0) {
      const firstUserName = userName.split(" ")[0];
      const firstUserFirstName = selectedUsers[0]?.fullName.split(" ")[0];
      namesString = `${firstUserName} & ${firstUserFirstName}`;

      // If there are more than three users
      if (selectedUsers.length > 3) {
        const remainingNames = selectedUsers
          .slice(1, 3)
          .map((user) => user.fullName.split(" ")[0])
          .join(", ");
        namesString += `, ${remainingNames}, ${selectedUsers.length - 3} more`;
      } else if (selectedUsers.length > 1) {
        const remainingNames = selectedUsers
          .slice(1)
          .map((user) => user.fullName.split(" ")[0])
          .join(", ");
        namesString += `, ${remainingNames}`;
      }
    }

    setSelectedUsersString(namesString);
  }, [selectedUsers, userName]);

  const handleSearchBar = (e) => {
    const searchTerm = e.target.value;
    setSearchInput(searchTerm);
  };

  const handleClear = () => {
    setSearchInput("");
  };

  const handleBack = () => {
    if (!showNextPopup) {
      openChatListModel();
      setSelectedUsers([]);
      setSearchInput("");
      setGroupProfileImg();
      setcropedFile();
      isCloseGroupModal();
    } else {
      setNextPopup(false);
    }
  };

  const createSocket = (profileImage) => {
    newsocket.emit("create-group", {
      sender: localStorage.getItem("userid"),
      group_image: profileImage,
      group_name: selectedUsersString,
      participents: selectedUsers.map((u) => {
        return u.id;
      }),
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
    });
    setGroupCreationLoader(true);
  };

  const addMemberSocket = () => {
    let addMemberPayload = {
      authUserId: localStorage.getItem("userid"),
      groupId: userid,
      addmember: selectedUsers
        .filter(
          (member) =>
            alreadyExistingMembers.filter(
              (alreadyMember) => alreadyMember.id === member.id
            ).length === 0
        )
        .map((member) => {
          return member.id;
        }),
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
      showHistory: showGroupHistory,
    };
    newsocket.emit("add-member", addMemberPayload);
    newsocket.emit("add-member-activity", addMemberPayload);
    setGroupCreationLoader(true);
  };

  // onChange checkbox
  const onChangeCheckBox = (chat) => {
    const isSelected = selectedUsers.some((user) => user.id === chat.id);

    if (isSelected) {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter((user) => user.id !== chat.id)
      );
    } else {
      if (selectedUsers.length < 49) {
        setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, chat]);
      }
    }
  };

  // onChange show history checkbox
  const onChangeShowHistoryCheckBox = () => {
    setShowGroupHistory(!showGroupHistory);
  };
  const handleNextButtonClick = () => {
    if (!showNextPopup) {
      setNextPopup(true);
    } else if (addMemberSelected) {
      addMemberSocket();
    } else {
      console.log(selectedUsersString, "selectedUsersString");
      if (selectedUsersString.trim() !== "") {
        var formdata = new FormData();
        formdata.append("media", cropedFile);
        if (cropedFile) {
          authService
            .uploadGroupImage(formdata)
            .then((res) => {
              createSocket(res.data.media[0].key);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          createSocket("");
        }
      } else {
        setErrorState("Please enter group name");
      }
    }
  };

  const handleCancelModal = () => {
    removeAddMemberSelected();
    isCloseGroupModal();
    setNextPopup(false);
    setSelectedUsers([]);
    setSearchInput("");
    setGroupProfileImg();
    setcropedFile();
  };

  const onClickMemberCross = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter((user) => user.id !== userId)
    );
  };

  const handleChangeInput = (e) => {
    setSelectedUsersString(e.target.value);
  };

  return (
    <ModalPopup
      handleClick2ndButton={handleNextButtonClick}
      onClose={handleCancelModal}
      isFooter={true}
      isHeader={showNextPopup && addMemberSelected ? false : true}
      modelSize="480"
      isOpen={isOpenGroupModal}
      headingLable={
        !showNextPopup || addMemberSelected ? "Add members" : "New group"
      }
      hasBackIcon={!addMemberSelected}
      hasCross={true}
      countStarts={selectedUsers.length}
      countEnds="49"
      hasCounts={!showNextPopup && !addMemberSelected ? true : false}
      hasContentCenter={true}
      disabled={
        selectedUsers.length === 0 ||
          GroupCreationLoader ||
          (addMemberSelected &&
            selectedUsers.filter(
              (data) =>
                alreadyExistingMembers.filter(
                  (alreadyMember) => alreadyMember.id === data.id
                ).length === 0
            ).length === 0)
          ? true
          : false
      }
      buttonText="Cancel"
      buttonText1={
        !showNextPopup ? "Next" : addMemberSelected ? "Add" : "Create"
      }
      buttonType="twobuttons"
      handleBack={handleBack}
      spinnersType="white"
      hasLoading={GroupCreationLoader}
      className={`create-group-member-model ${showNextPopup && addMemberSelected
        ? "add-member-in-group-confirmation"
        : ""
        }`}
    >
      {!showNextPopup || (addMemberSelected && !showNextPopup) ? (
        <>
          {/* <div>{selectedUsers.length} to 50</div> */}
          <div className="model-search-container flex flex-col">
            <SearchField
              className="text-field-search"
              type="text"
              placeholder="Search for members"
              value={searchInput}
              handleChange={(e) => handleSearchBar(e)}
              handleClear={handleClear}
              isRounded
            />

            {selectedUsers.length !== 0 && (
              <div className="group-selected-members scrollbar flex">
                <div className="group-selected-inner flex">
                  {selectedUsers?.map((data) => {
                    return (
                      <ChipMember
                        hasCrossButton={
                          !addMemberSelected
                            ? true
                            : alreadyExistingMembers.filter(
                              (alreadyMember) =>
                                data && alreadyMember.id === data.id
                            ).length === 0
                        }
                        badgeStatus={data?.onlineStatus ? "online" : "offline"}
                        size="16"
                        src={data?.profileImg}
                        letter={
                          data?.profileImg === "" &&
                          data.fullName.charAt(0).toUpperCase()
                        }
                        type={data?.profileImg === "" ? "letter" : "photo"}
                        hasStatus={true}
                        badgeSize="medium"
                        handleClick={() => onClickMemberCross(data.id)}
                        chipsName={data?.fullName}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <Separators />
          <div className="chat-list-container scrollbar">
            {allUserList &&
              allUserList
                .filter((user) =>
                  user.fullName.toLowerCase().includes(searchInput.toLowerCase())
                )
                .filter((user) => user.id !== myId).length === 0 ? (
              <div className="new-chat-emptystate">
                <EmptyState
                  hasTitle={false}
                  subTitle="No search results"
                  isClear={false}
                />
              </div>
            ) : (
              <div className="chat-list-inner">
                {allUserList &&
                  allUserList
                    .filter((user) =>
                      user.fullName
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    )
                    .filter((user) => user.id !== myId)
                    .map((chat, index) => {
                      return (
                        <div
                          className="flex items-center model-create-member-list"
                          key={index}
                        >
                          <Avtar
                            badgeStatus={
                              chat?.onlineStatus ? "online" : "offline"
                            }
                            size="40"
                            src={chat?.profileImg}
                            letter={
                              chat?.profileImg === "" &&
                              chat.fullName.charAt(0).toUpperCase()
                            }
                            type={chat?.profileImg === "" ? "letter" : "photo"}
                            hasStatus={true}
                            badgeSize="medium"
                          />
                          <div className="model-group-member-name">
                            <Typography variant="h5">
                              {chat.fullName}
                            </Typography>
                          </div>
                          {/* <input
                          type="checkbox"
                          checked={selectedUsers.some(
                            (user) => user.id === chat.id
                          )}
                          id={chat.id}
                          name={chat.fullName}
                          onChange={() => onChangeCheckBox(chat)}
                        /> */}
                          <Checkbox
                            onChange={(e) => {
                              onChangeCheckBox(chat);
                            }}
                            checked={
                              selectedUsers.filter(
                                (user) => chat.id === user.id
                              ).length > 0
                            }
                            id={chat.id}
                            name={chat?.fullName ? chat?.fullName : "Unknown"}
                            disabled={
                              !addMemberSelected
                                ? false
                                : alreadyExistingMembers.filter(
                                  (alreadyMember) =>
                                    alreadyMember.id === chat.id
                                ).length > 0
                            }
                          />
                        </div>
                      );
                    })}
              </div>
            )}
          </div>
        </>
      ) : showNextPopup && addMemberSelected ? (
        <>
          <div className="model-add-conf-body">
            <div className="model-add-conf-container">
              <div className="model-add-conf-content">
                <Typography variant="h3">Add member</Typography>
                <Typography variant="p">
                  Are you sure you want to add{" "}
                  <span>
                    {selectedUsers.filter(
                      (member) =>
                        alreadyExistingMembers.filter(
                          (alreadyMember) => alreadyMember.id === member.id
                        ).length === 0
                    ).length <= 5
                      ? selectedUsers
                        .filter(
                          (member) =>
                            alreadyExistingMembers.filter(
                              (alreadyMember) =>
                                alreadyMember.id === member.id
                            ).length === 0
                        )
                        .map((member) => {
                          return member.fullName;
                        })
                        .join(",")
                      : `${selectedUsers.filter(
                        (member) =>
                          alreadyExistingMembers.filter(
                            (alreadyMember) =>
                              alreadyMember.id === member.id
                          ).length === 0
                      ).length
                      } members`}{" "}
                  </span>
                  to <span> {searchParams.get("user_name")}</span>
                </Typography>
              </div>
              <div className="model-add-conf-checkbox">
                <Checkbox
                  onChange={(e) => {
                    onChangeShowHistoryCheckBox();
                  }}
                  checked={showGroupHistory}
                  hasLabel={true}
                  label="Show group history"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        showNextPopup && (
          <>
            <div className="model-group-icon-container flex flex-col items-center">
              <div className="model-cropping-tool" {...getRootProps()}>
                <input {...getInputProps()} />
                <AvtarLarge
                  type={GroupProfileImg ? "photo" : "empty"}
                  src={GroupProfileImg}
                  hasEdit={GroupProfileImg ? true : false}
                  hasStatus={false}
                />
              </div>

              {/* Cropping */}
              {modalOpen && (
                <div className="desk-globle-model-box globle-model-box checksboxes-visible cstm-rpp-main">
                  <div className="desk-globle-model-box-inner edit-profile-cstm">
                    <div className={`modal-content cropper-content`}>
                      <div className="media-attachment-details-header">
                        <h4 className="madh-h4">Replace profile picture</h4>
                        <button
                          type="button"
                          className="madh-close"
                          onClick={(e) => {
                            setModalOpen(false);
                          }}
                        >
                          <img src={ClosePopup} />
                        </button>
                      </div>
                      <div className="crop-media-body">
                        {file && (
                          <ReactCrop
                            crop={crop}
                            keepSelection={true}
                            aspect={1 / 1}
                            onChange={handleCropChange}
                            onComplete={handleCropComplete}
                          >
                            <img src={file} className="crop-media-image" />
                          </ReactCrop>
                        )}
                      </div>
                      <div className="crop-media-footer justify-content-end">
                        <button
                          className="upload-media-btn"
                          onClick={handleCropSubmit}
                        >
                          Crop & Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <TextField
                className="text-field"
                handleChange={(e) => handleChangeInput(e)}
                isRounded={true}
                value={selectedUsersString}
                readOnly={false}
                hasLabel={false}
                hasRightImg={false}
                placeholder="Enter group name"
              />
            </div>
            <hr className="hr-spacing-8" />
            {showErrorState}
            <div className="chat-list-container scrollbar">
              {selectedUsers?.map((data) => {
                return (
                  <div className="flex items-center model-create-member-list">
                    <Avtar
                      badgeStatus={data?.onlineStatus ? "online" : "offline"}
                      size="40"
                      src={data?.profileImg}
                      letter={
                        data?.profileImg === "" &&
                        data.fullName.charAt(0).toUpperCase()
                      }
                      type={data?.profileImg === "" ? "letter" : "photo"}
                      hasStatus={true}
                      badgeSize="medium"
                      hasEdit={false}
                    />
                    <div className="model-group-member-name">
                      <Typography variant="h5">{data.fullName}</Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )
      )}
    </ModalPopup>
  );
}
