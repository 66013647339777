import UploadImages from "./UploadImages";
import UploadVideos from "./UploadVideos";
import noMediaFound from "../../../Images/nomediafound.svg";
const EditAllPix = ({savedImg,savedVid, addImg, addVid, editremove, editremovevideo, setalleditimgvids, alleditimgvids, setaddImg, setErr ,showpoll,setaddVid }) => {
    return (
       
        <div
        className={`edit-post-content-inner-main ${
          savedImg.length +
            savedVid.length +
            addImg.length +
            addVid.length ===
          1
            ? "editimgcount1"
            : savedImg.length +
                savedVid.length +
                addImg.length +
                addVid.length ===
              2
            ? "editimgcount2"
            : savedImg.length +
                savedVid.length +
                addImg.length +
                addVid.length ===
              3
            ? "editimgcount3"
            : savedImg.length +
                savedVid.length +
                addImg.length +
                addVid.length ===
              4
            ? "editimgcount4"
            : savedImg.length +
                savedVid.length +
                addImg.length +
                addVid.length >=
              5
            ? "editimgcount5"
            : "editimgcount0"
        }`}
      >
        <div className="edit-post-content-inner-sub">
          <div className="edit-img-inner-main">
            {savedImg.length === 0 &&
            savedVid.length === 0 &&
            addImg.length === 0 &&
            addVid.length === 0 ? (
              <div className="no-img-video">
                <div>
                  <img src={noMediaFound} alt="noMediaFound" />
                  <p>Please add Image/Video</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {savedImg !== undefined && savedImg.length > 0
              ? savedImg.map((file, i) => (
                  <div className="list-group-item list-group-item-success">
                    <div
                      className="edit-cstm-img-inner-bg"
                      style={{
                        background: `url(${file})`,
                      }}
                    ></div>
                    <img src={file} />
                    <span
                      className="um-ulpoad-close"
                      onClick={() => {
                        editremove(i, "db");
                        setalleditimgvids({
                          imgs: alleditimgvids.imgs.filter((img) => {
                            if (img !== file) return img;
                          }),
                          vids: alleditimgvids.vids,
                        });
                      }}
                    >
                      ×
                    </span>
                  </div>
                ))
              : ""}
            {savedVid !== undefined && savedVid.length > 0
              ? savedVid.map((file, i) => (
                  <div className="list-group-item list-group-item-success">
                    <div
                      className="edit-cstm-img-inner-bg"
                      style={{
                        background: `url(${file})`,
                      }}
                    ></div>
                    <video src={file} />
                    <span
                      className="um-ulpoad-close"
                      onClick={() => {
                        editremovevideo(i, "db");
                        setalleditimgvids({
                          imgs: alleditimgvids.imgs,
                          vids: alleditimgvids.vids.filter((vid) => {
                            if (vid !== file) return vid;
                          }),
                        });
                      }}
                    >
                      ×
                    </span>
                  </div>
                ))
              : ""}
            {addImg.length > 0 &&
              addImg.map((file, index) => {
                return (
                  <div className="edit-cstm-img-inner-sec">
                    <div
                      className="edit-cstm-img-inner-bg"
                      style={{
                        background: `url(${URL.createObjectURL(file)})`,
                      }}
                    ></div>
                    <span
                      className="um-ulpoad-close"
                      onClick={() => {
                        editremove(index, "newimg");
                        setalleditimgvids({
                          imgs: alleditimgvids.imgs.filter(
                            (img, ind) => {
                              console.log(savedImg.length + index, ind);
                              if (ind !== savedImg.length + index)
                                return img;
                            }
                          ),
                          vids: alleditimgvids.vids,
                        });
                      }}
                    >
                      x
                    </span>
                    <img src={URL.createObjectURL(file)} />
                  </div>
                );
              })}
            {addVid.length > 0 &&
              addVid.map((file, index) => {
                return (
                  <div className="edit-cstm-img-inner-sec">
                    <div
                      className="edit-cstm-img-inner-bg"
                      style={{
                        background: `url(${URL.createObjectURL(file)})`,
                      }}
                    ></div>
                    <span
                      className="um-ulpoad-close"
                      onClick={() => {
                        editremovevideo(index, "newvid");
                        setalleditimgvids({
                          imgs: alleditimgvids.imgs,
                          vids: alleditimgvids.vids.filter(
                            (vid, ind) => {
                              if (ind !== savedVid.length + index)
                                return vid;
                            }
                          ),
                        });
                      }}
                    >
                      x
                    </span>
                    <video
                      src={URL.createObjectURL(file)}
                      controls="true"
                    />
                  </div>
                );
              })}
          </div>
          <div className="edit-img-cstm-btn">
            <div className="edit-img-cstm-btn-left">
              <UploadImages
                addImg={addImg}
                setaddImg={setaddImg}
                edit={true}
                setErr={setErr}
                showpoll={showpoll}
                alleditimgvids={alleditimgvids}
                setalleditimgvids={setalleditimgvids}
              />
              <UploadVideos
                addVid={addVid}
                setaddVid={setaddVid}
                edit={true}
                setErr={setErr}
                showpoll={showpoll}
                alleditimgvids={alleditimgvids}
                setalleditimgvids={setalleditimgvids}
              />
            </div>
            <div className="edit-img-cstm-btn-right">
              <button className="btn" data-dismiss="modal">
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}
export default EditAllPix