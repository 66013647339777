import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as XLSX from "xlsx";
import services from "../../Services/auth.service";
import services1 from "../../Services/auth.service1";
import blocked from "../../Images/BackendImages/blocked.svg";
import imgBigDelete from "../../Images/BackendImages/delete-icon.svg";
import FileSaver from "file-saver";
import { useDropzone } from "react-dropzone";
import loading from "../../Images/loader.gif";
import Loader from "../../Images/loader.gif";
import UserInfoSideBar from "./UserInfoSideBar";
import authService from "../../Services/auth.service";
import ModalPopup from "../../components/ModalPopup/ModalPopup";
import { Tooltip } from "@mui/material";
import { Block, DeleteOutline, Edit } from "@mui/icons-material";
import ActionButtons from "../ActionButtons";
//listing columns array
const columns = [
  { id: "displayName", label: "Name" },
  { id: "firstName", label: "Display Name" },
  { id: "email", label: "Email" },
  { id: "joined", label: "Joined" },
  { id: "migrateUserStatus", label: "Type" },
  { id: "lastSignOn", label: "Last sign in" },
  { id: "action", label: "Actions" },
];

//for creating listing data array
function createData(
  // status,
  img,
  displayName,
  firstName,
  lastName,
  email,
  joined,
  migrateUserStatus,
  lastSignOn,
  action
) {
  return {
    // status,
    img,
    displayName,
    firstName,
    lastName,
    email,
    joined,
    migrateUserStatus,
    lastSignOn,
    action,
  };
}
const UserListing = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [tblAllUser, setTblAllUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedUid, setselectedUid] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [importedFile, setImportedFile] = useState(null);
  const [importLoader, setImportLoader] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const reader = new FileReader();
  const [userEvents, setUserEvents] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation().search;
  const search = new URLSearchParams(location).get("search");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [blockModel, setBlockModel] = useState(false);
  const [actionDropdown, setActionDropdown] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    dispatch({ type: "HISTORYPAGE", payload: "/user/listing" });
    if (
      localStorage.getItem("adminidtoken") === null ||
      localStorage.getItem("adminidtoken").length === 0 ||
      localStorage.getItem("adminuserid") === null ||
      localStorage.getItem("adminuserid").length === 0
    ) {
      history("/adminlogin");
    }

    setLoader(true);
    services1.getallevent_byadmin("").then((res) => {
      if (res.data.status) {
        setUserEvents(res.data.data);
      }
    });
    setSearchText(search !== null ? search : "");
    getAllUserApi(search !== null ? search : searchText);
    getallusersSuggestionListApi();
  }, []);

  async function getAllUserApi(searchText, col, sort) {
    let query = "";
    query += `?search=${searchText}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    try {
      const getAllUserRes = await services.getallusers(query);
      if (getAllUserRes.data.invalidToken) {
        localStorage.clear();
        history("/adminlogin");
      }
      if (getAllUserRes.status) {
        setLoader(false);
        setUsers(getAllUserRes.data);
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  }

  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getAllUser();
      let data = getSuggestionRes.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.filter((item) => arr.push({ name: item.displayName }));
        setSuggestionList(arr);
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    maxSizeBytes: 10485760,
    onDrop,
  });

  const assignFiles = (acceptedFile) => {
    setImportLoader(true);
    setFileName(Math.random().toString() + acceptedFile[0].name);
    setFile(URL.createObjectURL(acceptedFile[0]));
    setImportedFile(acceptedFile[0]);
    if (
      acceptedFile[0] !== undefined &&
      acceptedFile[0] !== null &&
      acceptedFile[0] !== ""
    ) {
      var name = acceptedFile[0].name;
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event

        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data_ = XLSX.utils.sheet_to_json(ws, { header: 1 });
        if (data_.length > 0) {
          var colValues = [];
          var cells = Object.keys(ws);
          colValues = data_[0];
          if (colValues.length !== 5 + userEvents.length) {
            setShowAlert(true);
            setAlertMsg("Invalid Excel file format");
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
            setImportLoader(false);
            return;
          } else {
            if (
              colValues[0] !== "_id" ||
              colValues[1] !== "email" ||
              colValues[2] !== "firstName" ||
              colValues[3] !== "lastName" ||
              colValues[4] !== "migrateUserStatus"
            ) {
              setShowAlert(true);
              setAlertMsg("Invalid Excel file format");
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
              setImportLoader(false);

              return;
            }

            var i = 0;
            colValues.map((col) => {
              if (i > 4) {
                if (userEvents.filter((item) => item.name == col).length <= 0) {
                  setShowAlert(true);
                  setAlertMsg("Invalid Excel file format");
                  setTimeout(() => {
                    setShowAlert(false);
                  }, 2000);
                  setImportLoader(false);
                  return;
                }
              }

              i++;
            });
          }

          const data = XLSX.utils.sheet_to_json(ws);

          var final_list = [];

          if (data !== null && data.length > 0) {
            var i = 0;
            data.map((item) => {
              //if (i !== 0) {
              var obj = {};
              obj._id = item._id;
              obj.email = item.email !== undefined ? item.email : "";
              obj.migrateUserStatus =
                item.migrateUserStatus !== undefined &&
                item.migrateUserStatus === "Migrated User"
                  ? true
                  : false;
              obj.first_name =
                item.firstname !== undefined && item.firstname !== null
                  ? item.firstname
                  : "";
              obj.last_name =
                item.lastname !== undefined && item.lastname !== null
                  ? item.lastname
                  : "";
              var events = {};
              userEvents.length > 0 &&
                userEvents.map((event) => {
                  if (
                    item[event.name] !== undefined &&
                    item[event.name] !== null
                  ) {
                    events[event.name] = item[event.name] === 1 ? true : false;
                  }
                });

              obj.userEvents = events;

              final_list.push(obj);
              //}
              i++;
            });
          }
          const req_data = {
            allUser: final_list,
          };

          services1
            .importuserlist(req_data)
            .then((res) => {
              if (res.data.status) {
                setShowAlert(true);
                setAlertMsg("User data imported!");
                setTimeout(() => {
                  setShowAlert(false);
                }, 2000);
                setImportLoader(false);
              } else {
                setShowAlert(true);
                setAlertMsg("Error in importing!");
                setTimeout(() => {
                  setShowAlert(false);
                }, 2000);
                setImportLoader(false);
              }
            })
            .catch((err) => {
              console.log(err);
              setShowAlert(true);
              setAlertMsg("Error in importing: " + err);
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
              setImportLoader(false);
            });
        } else {
          setShowAlert(true);
          setAlertMsg("No data to import");
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
          setImportLoader(false);
        }
      };
      reader.readAsBinaryString(acceptedFile[0]);
    }
  };

  let history = useNavigate();

  useEffect(() => {
    if (users.length > 0) {
      var newarr = [];
      var arr = [...users];
      for (var i = 0; i < arr.length; i++) {
        // var date = arr[i].joinDate !== undefined ? arr[i].joinDate : "-";
        newarr[i] = createData(
          arr[i].profileImg,
          `${
            arr[i].first_name !== undefined && arr[i].first_name !== null
              ? arr[i].first_name
              : ""
          } ${
            arr[i].last_name !== undefined && arr[i].last_name !== null
              ? arr[i].last_name
              : ""
          }`,
          arr[i].display_name,
          arr[i].last_name,
          arr[i]["Preferred Email"],
          arr[i].joinDate,
          arr[i].migrate_user_status === true
            ? "Migrated User"
            : arr[i].migrate_user_status === false
            ? "Normal User"
            : "-",
          arr[i].last_login,
          arr[i]._id
        );
      }
      setTblAllUser(newarr);
      setRow(newarr);
    } else {
      setRow([]);
    }
  }, [users]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = () => {
    setDeleteModal(false);
    services
      .deactivateuser({ id: selectedUid.action })
      .then((res) => {
        setAlertMsg("Successfully deactivated user!");
        getAllUserApi(searchText);
        services.deleteUserDetailInChatProjectRedis(selectedUid);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlock = () => {
    setBlockModel(false);
    services
      .blockuser({ id: selectedUid.action })
      .then((res) => {
        if (res) {
          getAllUserApi(searchText);
          setShowAlert(true);
          setAlertMsg("Successfully blocked user!");
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (text) => {
    setLoader(true);
    setSearchText(text);
    getAllUserApi(text);
    setSuggestionBox(false);
    history(`/user/listing?search=${text}`);
  };

  const handleSort = (col, direc) => {
    setLoader(true);
    getAllUserApi(searchText, col, direc);
  };

  const handleImport = (e) => {};

  const handleExport = (e) => {
    setExportLoader(true);

    services1
      .exportuserlist()
      .then((res) => {
        if (res.data.status) {
          let final_list2 = [];
          res.data.data.map((item) => {
            var obj = {};
            obj._id = item._id;
            obj.email = item.email !== undefined ? item.email : "";
            obj.firstName = item.first_name;
            obj.lastName = item.last_name;
            obj.migrateUserStatus =
              item.migrateUserStatus !== undefined &&
              item.migrateUserStatus === "Migrate User"
                ? true
                : false;
            item.userEvents !== undefined &&
              item.userEvents !== null &&
              userEvents.map((itm) => {
                obj[itm.name] =
                  item.userEvents[itm.name.trim()] == true ? 1 : 0;
              });

            final_list2.push(obj);
          });

          const workbook_ = XLSX.utils.book_new(); // create new workbook
          const sheet_ = XLSX.utils.json_to_sheet(final_list2); // convert JSON data to sheet

          XLSX.utils.book_append_sheet(workbook_, sheet_, "Sheet2"); // add the sheet to the workbook
          //XLSX.writeFile(workbook, 'data.xlsx');
          const excelBuffer_ = XLSX.write(workbook_, {
            bookType: "xlsx",
            type: "array",
          });
          const dataExcel = new Blob([excelBuffer_], { type: fileType });

          FileSaver.saveAs(dataExcel, "userprofiledata.xlsx");

          setShowAlert(true);
          setAlertMsg("User data exorted!");
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
          setExportLoader(false);
        } else {
          setShowAlert(true);
          setAlertMsg("Error in exporting!");
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
          setExportLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowAlert(true);
        setAlertMsg("Error in exporting : " + err);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        setExportLoader(false);
      });
  };
  function handleSelect(selectedItem, row) {
    selectedItem.handleClick(row);
  }

  const allSelectOption = [
    {
      icon: <Edit />,
      name: "Edit",
      fullName: " ",
      handleClick: (value) => {
        //dispatch({ type: "SETMODALINPROCESS" })
        dispatch({
          type: "SETSTAYONPAGE",
          payload: null,
        });
        // handleUpdate(value);
        dispatch({
          type: "NAVIGATEPAGE",
          payload: `/user/listing?search=${searchText}`,
        });
        history(`/user/edituser?id=${value.action}`);
      },
    },
    {
      icon: <DeleteOutline />,
      name: "Delete",
      fullName: " ",
      handleClick: (value) => {
        setDeleteModal(true);
        setselectedUid(value.action);
        setDeleteName(value.displayName);
      },
    },
    {
      icon: <Block />,
      name: "Block",
      fullName: " ",
      handleClick: (value) => {
        setBlockModel(true);
        setselectedUid(value);
        setDeleteName(value.displayName);
      },
    },
  ];

  return (
    <>
      <div className="users-info">
        <UserInfoSideBar />
        <div className="users-info-content">
          <div className="users-info-content-main">
            {showAlert && <div className="alert alert-info">{alertMsg}</div>}
            <div className="uic-title">
              <h1>Registered users</h1>
            </div>

            <div className="uic-fl-mn">
              <div className="uic-fl-mn-left">
                <div className="uic-fl-search">
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-user-input"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      var currentValue;
                      if (e.target.value) {
                        currentValue = e.target.value;
                      } else {
                        currentValue = e.target.value;
                        setSearchText(currentValue);
                      }
                      setActualsearch(currentValue);
                      if (
                        suggestionList !== undefined &&
                        suggestionList.length > 0
                      ) {
                        setListIndex(-1);
                        setSuggestionBox(true);
                        var searchResult = suggestionList.filter((user) => {
                          return user.name
                            ?.toLowerCase()
                            .includes(e.target.value.trim().toLowerCase());
                        });
                        setSuggestions(searchResult);
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        handleSearch(e.target.value);
                      } else if (e.key === "ArrowDown") {
                        const listLength =
                          suggestions.length > 15 ? 15 : suggestions.length;
                        if (listIndex < listLength - 1) {
                          setListIndex(listIndex + 1);
                        } else if (listIndex === listLength - 1) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        }
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        const listLength =
                          suggestions.length > 15 ? 15 : suggestions.length;
                        if (listIndex === 0) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        } else if (listIndex === -1) {
                          setListIndex(listLength - 1);
                        } else {
                          setListIndex(listIndex - 1);
                        }
                      }
                    }}
                    onBlur={() => setSuggestionBox(false)}
                  />

                  {suggestionBox &&
                    suggestions.length > 0 &&
                    searchText.length > 0 && (
                      <div className="suggestion-box">
                        <ul id="suggestion-list">
                          {suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onMouseDown={() => handleSearch(suggestion.name)}
                              className={
                                index === listIndex ? "selected-active" : ""
                              }
                            >
                              {searchText !== null &&
                              searchText.length > 0 &&
                              suggestion.name
                                .toLowerCase()
                                .startsWith(searchText.toLowerCase()) ? (
                                <>
                                  <b>
                                    {suggestion.name.slice(
                                      0,
                                      searchText.length
                                    )}
                                  </b>
                                  {suggestion.name &&
                                    suggestion.name.slice(
                                      searchText.length,
                                      suggestion.name.length
                                    )}{" "}
                                </>
                              ) : (
                                suggestion.name
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                  <i
                    className="fa fa-search"
                    onClick={() => handleSearch(searchText)}
                  ></i>
                </div>
              </div>
              <div className="uic-fl-mn-right">
                <div className="uic-fl-btn">
                  <button className="btn" onClick={handleExport}>
                    Export
                  </button>
                  {exportLoader ? (
                    <div className="export-loading-image">
                      <span className="cstm-pli-bg-exportfile">
                        <img src={loading} />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="uic-fl-import-btn">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span className="import text">Import</span>
                  </div>
                  {importLoader ? (
                    <div className="import-loading-image">
                      <span className="cstm-pli-bg-importfile">
                        <img src={loading} />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table min-w-auto">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            column.id === "action"
                              ? "uic-th-td w-1"
                              : // : column.id === "status"
                                // ? "uic-th-td w-5"
                                "uic-th-td w-10"
                          }
                        >
                          {column.id !== "action" &&
                          // column.id !== "status" &&
                          column.id !== "lastSignOn" ? (
                            <>
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    handleSort(column.id, "Asc");
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    handleSort(column.id, "Dec");
                                  }}
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {loader ? (
                      <div className="cstm-data-load">
                        <img src={Loader} />{" "}
                      </div>
                    ) : rows.length > 0 ? (
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                              className="uic-th-td w-20"
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return column.id !== "action" &&
                                  // column.id !== "status" &&
                                  column.id !== "migrateUserStatus" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td w-10"
                                  >
                                    {column.id === "displayName" ? (
                                      <Link
                                        to={`/user/edituser?id=${row.action}`}
                                        onClick={() => {
                                          //dispatch({ type: "SETMODALINPROCESS" })
                                          dispatch({
                                            type: "SETSTAYONPAGE",
                                            payload: null,
                                          });
                                          // handleUpdate(value);
                                          dispatch({
                                            type: "NAVIGATEPAGE",
                                            payload: `/user/listing?search=${searchText}`,
                                          });
                                        }}
                                        className="cstm-view-users-count"
                                      >
                                        {row.img !== undefined &&
                                        row.img !== null &&
                                        row.img !== "" ? (
                                          <img
                                            className="cstm-url-link"
                                            src={row.img}
                                            alt="image"
                                          />
                                        ) : (
                                          <div className="cstm-partner-avar">
                                            {row[column.id]
                                              ? row[column.id].charAt(0)
                                              : ""}
                                          </div>
                                        )}
                                        {value}
                                      </Link>
                                    ) : column.id === "lastSignOn" ||
                                      column.id === "joined" ? (
                                      <Tooltip
                                        title={
                                          value !== undefined
                                            ? moment(value).format("hh:mm a")
                                            : "-"
                                        }
                                        arrow
                                      >
                                        {value !== undefined
                                          ? moment(value).format("MMM DD, YYYY")
                                          : "-"}
                                      </Tooltip>
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                ) : column.id !== "status" &&
                                  column.id !== "migrateUserStatus" ? (
                                  <TableCell
                                    key={column.id}
                                  >
                                    <ActionButtons
                                      options={allSelectOption}
                                      menuSelectFun={handleSelect}
                                      row={row}
                                    />
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td w-5"
                                  >
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
                {!loader && rows.length === 0 ? (
                  <div className="no-data-found">
                    <p>No Data Found </p>
                  </div>
                ) : (
                  <></>
                )}
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
      {/* Modal Delete */}

      <ModalPopup
        onClose={() => setDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Delete"
        isOpen={deleteModal}
        btn2variant="danger"
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete user"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this {deleteName}? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup>

      <div
        className="modal fade msg-popup"
        id="Delete"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>

            <div className="msg-icon">
              <img src={imgBigDelete} />
            </div>
            <h1>Are you sure you want to delete this user?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handleDelete}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="uic-fl-btn">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalPopup
        onClose={() => setBlockModel(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Block"
        isOpen={blockModel}
        btn2variant="danger"
        className=""
        handleClick2ndButton={handleBlock}
        headingLable={"Block user"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to block this {deleteName}? This action cannot
            be undone.
          </p>
        </div>
      </ModalPopup>

      {/* Modal Block */}
      <div
        className="modal fade msg-popup"
        id="block"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={blocked} />
            </div>
            <h1>Are you sure you want to block this user?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handleBlock}
                    data-dismiss="modal"
                  >
                    Block
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="uic-fl-btn">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserListing;
