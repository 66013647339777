import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
//import api file
import Services from "../../../Services/auth.service";
import Info from "../../../Images/info.svg";
import Map2 from "../../../Images/map2.png";

const RightSidebar_profile = ({ profiletype, profileDetail , setshoweditdiv}) => {
  let { memberid } = useParams();
  return (
    <>
    {profileDetail._id === memberid ?
      <div className="all-content-main-right-sidebar">
        <div className="accordion" id="accordionExample">
          <div className="sd-detilas">
            <div className="sd-detilas-title">Profile Info {profiletype ? <button className="btn2" onClick={()=>{setshoweditdiv(true)}}>Edit Profile</button> : <></>} </div> 

            <div className="sd-detilas-info">
              {profileDetail !== undefined  &&
              profileDetail.otherdetail !== undefined &&
              profileDetail.otherdetail.length > 0 ? (
                profileDetail.otherdetail.map((detail) => {
                  if (detail.label !== undefined) {
                    if (
                      (profiletype === "myprofile" &&
                        detail._id !==
                          process.env.REACT_APP_PASSWORD_FIELD_DBID &&
                        detail._id !==
                          process.env.REACT_APP_CONPASSWORD_FIELD_DBID) ||
                      profiletype !== "myprofile"
                    ) {
                      if (
                        detail.type === "file" &&
                        detail.value !== undefined &&
                        detail.value !== null &&
                        detail.value.length > 0
                      ) {
                        return (
                          <div className="sd-detilas-info-in">
                            {/* <span className="sd-detilas-info-in-icon"> <img src={Info} /> </span> */}
                            <span className="sd-detilas-info-in-title">
                              {" "}
                              {detail.label}{" "}
                            </span>
                            <span className="sd-detilas-info-in-info">
                              {" "}
                              {detail.value !== undefined &&
                              typeof detail.value !== "object" ? (
                                detail.value
                              ) : detail.value !== undefined &&
                                typeof detail.value === "object" ? (
                                <img
                                  src={
                                    process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    detail.value.toString()
                                  }
                                />
                              ) : (
                                ""
                              )}{" "}
                            </span>
                          </div>
                        );
                      } else {
                        return (
                          <div className="sd-detilas-info-in">
                            {/* <span className="sd-detilas-info-in-icon"> <img src={Info} /> </span> */}
                            <span className="sd-detilas-info-in-title">
                              {" "}
                              {detail.label}{" "}
                            </span>
                            <span className="sd-detilas-info-in-info">
                              {" "}
                              {detail.value !== undefined &&
                              typeof detail.value !== "object"
                                ? detail.value
                                : detail.value !== undefined &&
                                  typeof detail.value === "object"
                                ? detail.value.toString()
                                : ""}{" "}
                            </span>
                          </div>
                        );
                      }
                    } else {
                      return <></>;
                    }
                  } else return <></>;
                })
              ) : (
                <></>
              )}
            
            </div>
          </div>
          {
            !profileDetail.migrate_user_status && <div className="sd-map">
            < iframe src="//maps.google.com/maps?q=23.1104517,72.535058&z=15&output=embed"/>
            <img src={Map2} />
            </div>
          }
          
        </div>
      </div>
      : <></>}
    </>
  );
};

export default RightSidebar_profile;
