import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

const ImageGalleryPost = ({ allimg, postid }) => {
  return (
    <div
      className="cstm-img-peview-model modal-dialog modal-dialog-centered"
      role="document"
    >
      <div className="modal-content">
        <button
          type="button"
          className="cstmcloseBnt"
          data-dismiss="modal"
          aria-label="Close"
        >
          &times;
        </button>
        <AwesomeSlider name={postid}>
          {allimg.map((file, i) => (
            <div className="item cstm-cl-items">
              <div className="list-group-item list-group-item-success">
                {file.indexOf("image") !== -1 ? (
                  <img src={process.env.REACT_APP_AWS_IMG_VID_PATH + file} />
                ) : (
                  <video
                    src={process.env.REACT_APP_AWS_IMG_VID_PATH + file}
                    controls
                  ></video>
                )}
              </div>
            </div>
          ))}
        </AwesomeSlider>
      </div>
    </div>
  );
};
export default ImageGalleryPost;
