import { useNavigate, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";

import Services from "../../../Services/auth.service";
import {
  fetchstarchat,
  fetchchatmessages,
  fetchmyprofiledetail,
  notificationlistAction,
  setModalIsOpen,
} from "../../../Action";
import { checktoken_user } from "../../checktoken";
import GlobleSearch from "./GlobalSearch";
import newsocket from "../../SocketConnect";

//import images
import Logo from "../../../Images/logo_mds_small.svg";
import Image1 from "../../../Images/app_logo.png";
import Searchmain from "../../../Images/search.svg";
import Chat from "../../../Images/chat.svg";
import EditPF from "../../../Images/EditPF.svg";
import MessagePF from "../../../Images/MessagePF.svg";
import LoginPF from "../../../Images/LoginPF.svg";
import AccountSettingsPF from "../../../Images/AccountSettingsPF.svg";
import ViewNav from "../../../Images/ViewNav.svg";
import ReminderIcon from "../../Icons/GeneralIcons/ReminderIcon";
import Notifications from "../../../Pages/Notifications/components/Notification";
import ChannelIcon from "../../Icons/GeneralIcons/ChannelIcon";

import GlobalMenuPopup from "../GlobalMenuPopup";
import NotificationIcon from "../../../components/Icons/GeneralIcons/NotificationIcon";
import NotificationBadge from "../../Badge/NotificationBadge";
import DotsIcon from "../../Icons/GeneralIcons/DotsIcon";
import authNotification from "../../../Services/auth.notification";
import SearchField from "../../InputField/SearchField";
import SuggestionDropDown from "../../DropDownItem/SuggestionDropDown";
import ReloadIcon from "../../Icons/GeneralIcons/ReloadIcon";
import SearchIcon from "../../Icons/GeneralIcons/SearchIcon";

import NavChatIcon from "../../Icons/NavIcons/NavChatIcon";
import ReferralProgram from "../../../Images/ReferralProgram.svg";
import ReferralModal from "../../ReferralModal/ReferralModal";
const Header = ({
  setSelectedGroup,
  setselectedDiv,
  settopicSearch,
  setopenpop,
  setIsOpenRefer,
}) => {
  const { action } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const pathname = useLocation();
  const search_url = useLocation().search;
  const text_search = new URLSearchParams(search_url).get("text");
  const searchedVideo = new URLSearchParams(search_url).get("search");
  const listType = new URLSearchParams(search_url).get("type");
  const categoryId = new URLSearchParams(search_url).get("catid");
  const subcategoryId = new URLSearchParams(search_url).get("subcategoryId");
  const speakerId = new URLSearchParams(search_url).get("speaker");
  const datefilterId = new URLSearchParams(search_url).get("datefilter");
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [showRedFlag, setShowRedFlag] = useState(false);
  const isModalOpen = useSelector((state) => state?.ModalState);
  useEffect(() => {
    notificationList();
  }, []);

  async function notificationList() {
    try {
      const notificationList = await authNotification.getNotificationList(
        page,
        limit
      );
      if (notificationList?.status) {
        dispatch(notificationlistAction(notificationList?.data?.data));
      }
    } catch (error) {
      console.log("error:-----------", error);
    }
  }

  // use Selectors
  const notificationData = useSelector((state) => {
    return state.NotificationReducer.notificationList;
  });

  const Reduchatlist = useSelector((state) => {
    return state.ReduAllChatList;
  });

  const Redu_Profile = useSelector((state) => {
    if (state.ReduMyProfile !== null && state.ReduMyProfile !== undefined)
      return state.ReduMyProfile;
    else return {};
  });

  const [globalmenu, setglobalmenu] = useState(false);
  const [about, setabout] = useState(false);
  const [myprofile, setmyprofile] = useState({});
  const [selectedUser, setSelectedUser] = useState([]);
  const [chatHeadUser, setchatHeadUser] = useState([]);
  const [availableWidth, setavailableWidth] = useState(0);
  const [allgroup, setallgroup] = useState([]);
  const [alltopic, setalltopic] = useState([]);
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState("topics");
  const [text, settext] = useState("");
  const [showlist, setshowlist] = useState(false);
  const [allchatmessages, setallchatmessages] = useState({});
  const [showuserlistchat, setshowuserlistchat] = useState(false);

  const [showNotificationList, setshowNotificationList] = useState(false);
  const [xyz, setxyz] = useState(false);

  // video search
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [allVideoTitleList, setAllVideoTitleList] = useState([]);
  const [finalSuggestionArr, setFinalSuggestionArr] = useState([]);
  const [searchText, setSearchText] = useState(
    searchedVideo ? searchedVideo : ""
  );
  const [listIndex, setListIndex] = useState(-1);
  const [actualSearch, setActualsearch] = useState("");
  const dropdownRef = React.createRef();

  useEffect(() => {
    if (searchedVideo) {
      setSearchText(searchedVideo);
    } else {
      setSearchText("");
    }
  }, [searchedVideo]);

  const handleLogout = () => {
    if (window.location.hostname === "app.milliondollarsellers.com")
      window.Intercom("shutdown");
    newsocket.disconnect();
    Services.setlastlogin();
    localStorage.removeItem("idtoken");
    localStorage.removeItem("userid");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userprofile");
    localStorage.removeItem("useremailid");
    localStorage.removeItem("m_user");
    localStorage.removeItem("app_user");
    history("/");
  };

  const getVideoSuggestionAndHistory = async () => {
    Services.getAllVideoList()
      .then((res) => {
        if (res.data.status) {
          const arr = [];
          const data = res.data.data;
          data.map((item) => {
            arr.push({
              _id: item._id,
              name: item.title,
              description: item.description,
              tagData: item.tagData ? item.tagData : [],
              icon: <SearchIcon />,
            });
          });
          setAllVideoTitleList(arr);
        }
      })
      .catch((e) => {
        return false;
      });
    Services.getSearchHistory()
      .then((res) => {
        if (res.data.status) {
          const arr = [];
          const data = res.data.data;
          data.map((item) => {
            arr.push({
              _id: item._id,
              isHistory: true,
              name: item.name,
              icon: <ReloadIcon />,
            });
          });
          setSearchHistoryList(arr);
          setSearchHistory(arr);
        }
      })
      .catch((e) => {
        return false;
      });
  };
  const chatListData = useSelector((state) => {
    return state.ReduAllChatList;
  });

  const muteNotificationList = useSelector(
    (state) => state?.muteNotificationReducer
  );

  const countChat = chatListData.reduce((sum, item) => {
    if (!muteNotificationList?.notificationBadgeNotDisplay?.includes(item.id)) {
      let count = item.count ?? 0;
      return sum + count;
    }
    return sum;
  }, 0);

  useEffect(() => {
    Services.getDaySinceMDSOnly()
      .then((response) => {
        if (
          response.data.status &&
          response.data.data["# of Days Since MDS Only Census"] !== undefined
        ) {
          localStorage.setItem(
            "count",
            response.data.data["# of Days Since MDS Only Census"]
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getmydetail()
      .then((res) => {
        checktoken_user(res);
        if (res.data.status) setmyprofile(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getallgrouploginuser()
      .then((res) => {
        if (res.data.status) {
          setallgroup(res.data.data);
          const arr = [];
          const t = 0;
          for (const i = 0; i < res.data.data.length; i++) {
            Services.gettopicsbygroupid(res.data.data[i]._id)
              .then((response) => {
                if (response.data.status) {
                  for (const n = 0; n < response.data.data.length; n++) {
                    arr[t] = response.data.data[n];
                    t++;
                  }
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
          setalltopic(arr);
        } else {
          if (res.data.message === "Invalid token!") {
            localStorage.clear();
            history("/");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(fetchstarchat());
    dispatch(fetchmyprofiledetail());

    // video search apis
    getVideoSuggestionAndHistory();
  }, []);

  useEffect(() => {
    setFinalSuggestionArr([...searchHistory, ...suggestionList]);
  }, [searchHistory, suggestionList]);

  useEffect(() => {
    if (
      pathname.pathname.split("/")[1] === "frontcontentlibrary" ||
      (pathname.pathname.split("/")[1] !== "frontcontentlibrary" &&
        pathname.pathname.split("/")[1] !== "dashboard") ||
      pathname.pathname.split("/")[1] === "frontnews"
    )
      document.body.classList.remove("menuview");
  }, [pathname]);
  useEffect(() => {
    if (myprofile.profileImg) {
      localStorage.setItem("userprofile", myprofile.profileImg);
    }
    !localStorage.getItem("username") &&
      localStorage.setItem(
        "username",
        `${myprofile.first_name} ${myprofile.last_name}`
      );
  }, [myprofile]);
  const handleUserSelection = (id, name, type, img, online) => {
    const index = -1;
    for (const i = 0; i < selectedUser.length; i++) {
      if (selectedUser[i].id === id) index = i;
    }
    if (index === -1) {
      if (window.innerWidth - 340 - availableWidth > 340) {
        const index_chat = -1;
        for (const i = 0; i < chatHeadUser.length; i++) {
          if (chatHeadUser[i].id === id) index_chat = i;
        }
        if (index_chat !== -1) removeEclipse(id);
        const arr = selectedUser;
        arr.push({
          id: id,
          name: name,
          type: type,
          image: img,
          useronline: online,
        });
        setSelectedUser(arr);
        setavailableWidth(availableWidth + 340);
        dispatch(fetchchatmessages({ id: id, type: type }));
      } else {
        const removeuser = selectedUser[0];
        const index_chat = -1;
        selectedUser.splice(0, 1);
        for (const i = 0; i < chatHeadUser.length; i++) {
          if (chatHeadUser[i].id === removeuser.id) index_chat = i;
        }
        if (index_chat === -1) {
          const arr = [...chatHeadUser];
          arr.push(removeuser);
          setchatHeadUser(arr);
        }
        const arr = selectedUser;
        arr.push({
          id: id,
          name: name,
          type: type,
          image: img,
          useronline: online,
        });
        setSelectedUser(arr);
      }
    }
  };
  // Called on click on cross icon
  const removechatwindow = (user_id) => {
    const index = -1;
    for (const i = 0; i < selectedUser.length; i++) {
      if (selectedUser[i].id === user_id) {
        index = i;
      }
    }
    selectedUser.splice(index, 1);
    setavailableWidth(availableWidth - 340);
  };
  // Called on click on minus icon
  const addEclipse = (user_id, name, type, image, online) => {
    const index = -1;
    for (const i = 0; i < selectedUser.length; i++) {
      if (selectedUser[i].id === user_id) {
        index = i;
      }
    }
    if (index !== -1) {
      removechatwindow(user_id);
    }
    const index_chat = -1;
    for (const i = 0; i < chatHeadUser.length; i++) {
      if (chatHeadUser[i].id === user_id) index_chat = i;
    }
    if (index_chat === -1) {
      const arr = chatHeadUser;
      arr.push({
        id: user_id,
        name: name,
        type: type,
        image: image,
        useronline: online,
      });
      setchatHeadUser(arr);
      setavailableWidth(availableWidth - 340);
    }
  };
  // Called to remove chat head
  const removeEclipse = (user_id) => {
    const index = -1;
    for (const i = 0; i < chatHeadUser.length; i++) {
      if (chatHeadUser[i].id === user_id) {
        index = i;
      }
    }
    chatHeadUser.splice(index, 1);
  };
  const handlelistClick = (l) => {
    settext(l.name);
    setshowlist(false);
    if (l.type === "group") {
      setSelectedGroup(l.id);
      setselectedDiv("group");
    } else if (l.type === "topic") {
      settopicSearch(l.id);
      setselectedDiv("dashboard");
    }
  };
  const handleMenuViewClick = () => {
    history("/");
  };

  // Show Notification
  const showNotificationHandler = () => {
    setshowNotificationList((prev) => !showNotificationList);
  };

  const checkHasNotifications = () => {
    if (notificationData.notification.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // video search
  const scrollIntoView = (listIndex) => {
    if (dropdownRef.current) {
      dropdownRef.current.children[listIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const getAllHistoryList = () => {
    const newHistoryList = searchHistoryList.map((value) => {
      return { name: value.name, icon: <ReloadIcon />, isHistory: true };
    });
    return newHistoryList;
  };

  const addHistory = (historyText) => {
    const currentHistoryList = getAllHistoryList();
    if (historyText) {
      Services.addSearchHistory({ search: historyText.toLowerCase() })
        .then((res) => {
          if (res.data.status) {
            getVideoSuggestionAndHistory();
          }
        })
        .catch((e) => {
          return false;
        });
    }
  };

  const updateSuggestionAndHistoryList = (searchText) => {
    // Convert searchHistoryList to a Set for faster lookup
    const searchHistorySet = new Set(
      searchHistoryList.map((data) => data.name.toLowerCase())
    );

    // Filter search history items
    let startwithHistory = searchHistoryList.filter((item) =>
      item.name.toLowerCase().startsWith(searchText.toLowerCase())
    );

    // Filter suggestion list with prioritization
    let startWithTitle = [];
    let containsInTitle = [];
    let containsInDescriptionOrTags = [];

    allVideoTitleList.forEach((video) => {
      const lowerCaseTitle = video.name.toLowerCase();
      const lowerCaseDescription = video.description.toLowerCase();
      const lowerCaseTagData = video.tagData
        .map((tag) => tag.name.toLowerCase())
        .join(" ");
      if (!searchHistorySet.has(lowerCaseTitle)) {
        if (lowerCaseTitle.startsWith(searchText.toLowerCase())) {
          startWithTitle.push({
            name: video.name,
            _id: video._id,
            description: video.description,
            icon: video.icon,
            tagData: video.tagData,
          });
        } else if (lowerCaseTitle.includes(searchText.toLowerCase())) {
          containsInTitle.push({
            name: video.name,
            _id: video._id,
            description: video.description,
            icon: video.icon,
            tagData: video.tagData,
          });
        } else if (
          lowerCaseDescription.includes(searchText.toLowerCase()) ||
          lowerCaseTagData.includes(searchText.toLowerCase())
        ) {
          containsInDescriptionOrTags.push({
            name: video.name,
            _id: video._id,
            description: video.description,
            icon: video.icon,
            tagData: video.tagData,
          });
        }
      }
    });

    // Concatenate the prioritized lists
    const finalSuggestionList = [
      ...startWithTitle,
      ...containsInTitle,
      ...containsInDescriptionOrTags,
    ];

    // Update suggestion list
    setSuggestionList(finalSuggestionList);

    // Update search history
    setSearchHistory(startwithHistory);
  };
  const handleSearch = (e, searchText = "") => {
    setListIndex(-1);
    let currentValue;
    if (searchText) {
      currentValue = searchText;
    } else {
      currentValue = e.target.value;
      setSearchText(currentValue);
    }
    setActualsearch(currentValue);
    if (currentValue === "") {
      setSuggestionBox(true);
      setSearchHistory(searchHistoryList);
      setSuggestionList([]);
    } else if (/^\s+/.test(currentValue)) {  // check if currentValue start with space 
      setSuggestionBox(false);
    } else {
      setSuggestionBox(true);
      updateSuggestionAndHistoryList(currentValue);
    }
  };

  const onTitleSelection = (title) => {
    history(`/videos?type=${listType ? listType : "all"}&filter=recent&search=${title}${categoryId ? `&catid=${categoryId}` : ""}${subcategoryId ? `&subcategoryId=${subcategoryId}` : ""}${speakerId ? `&speaker=${speakerId}` : ""}${datefilterId ? `&datefilter=${datefilterId}` : ""}`)
    setListIndex(-1);
    setActualsearch(title);
    setSuggestionBox(false);
    setSearchText(title);
    updateSuggestionAndHistoryList(title);
    addHistory(title);
  };

  const handleKeyPress = (e, title) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      if (e.target.value === "" || e.target.value.match(/^\S/)) {
        getVideoSuggestionAndHistory()
        onTitleSelection(searchText);
      }
    } else if (e.key === "ArrowDown") {
      const listLength = finalSuggestionArr.length;

      if (listIndex < listLength - 1) {
        setListIndex(listIndex + 1);
      } else if (listIndex === listLength - 1) {
        setListIndex(-1);
        setSearchText(actualSearch);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      const listLength = finalSuggestionArr.length;
      if (listIndex === 0) {
        setListIndex(-1);
        setSearchText(actualSearch);
      } else if (listIndex === -1) {
        setListIndex(listLength - 1);
      } else {
        setListIndex(listIndex - 1);
      }
    }
  };

  const handleClearSearch = () => {

    history(`/videos?type=${listType ? listType : "all"}&filter=recent${categoryId ? `&catid=${categoryId}` : ""}${subcategoryId ? `&subcategoryId=${subcategoryId}` : ""}${speakerId ? `&speaker=${speakerId}` : ""}${datefilterId ? `&datefilter=${datefilterId}` : ""}`)
    setSearchText("")
    setSuggestionBox(false)
    setSuggestionList([]);
  };

  async function removeHistoryApi(id) {
    const updatedData = searchHistoryList.filter((row) => row._id !== id);
    const updatedHistoryData = searchHistory.filter((row) => row._id !== id);
    setSearchHistory(updatedHistoryData);
    setSearchHistoryList(updatedData);
    try {
      const removeRes = await Services.removeSearchHistory({ id });
      if (removeRes.data.data.status) {
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      const currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        scrollIntoView(listIndex);
        const text = currentList
          .querySelector(".doc-suggestion-dropdown-list h5")
          .innerText.trim();
        setSearchText(text);
      }
    }
  }, [listIndex]);
  return (
    <>
      <div className={`header-ft cstm-header-bar ${window.location.pathname.includes("videos") ? 'video-header-grid' : ''}`}>
        <div className="header-ft-logo">
          <div
            style={{
              display:
                pathname.pathname.split("/")[1] === "settings" ||
                pathname.pathname.split("/")[1] === "chats" ||
                pathname.pathname.split("/")[1] === "event-details" ||
                pathname.pathname.split("/")[1] === "confirmationPage" ||
                (pathname.pathname.split("/")[1] === "videos" &&
                  pathname.pathname.split("/")[2] !== undefined &&
                  "block !important"),
              marginLeft:
                pathname.pathname.split("/")[1] === "settings" ||
                pathname.pathname.split("/")[1] === "chats" ||
                pathname.pathname.split("/")[1] === "event-details" ||
                pathname.pathname.split("/")[1] === "confirmationPage" ||
                (pathname.pathname.split("/")[1] === "videos" &&
                  pathname.pathname.split("/")[2] !== undefined &&
                  "-10px"),
            }}
            className={`${pathname.pathname.split("/")[1] !== "settings" &&
              pathname.pathname.split("/")[1] !== "chats" &&
              pathname.pathname.split("/")[1] !== "event-details" &&
              pathname.pathname.split("/")[1] !== "confirmationPage" &&
              pathname.pathname.split("/")[1] !== "event-ticketing" &&
              pathname.pathname.split("/")[1] === "videos" &&
              action === undefined
              ? "header-ft-view-nav"
              : ""
              } cstm-menu-mobile`}
            onClick={() => {
              //console.log("fdafdsf")
              // console.log(document.querySelector('.not-found-page'),'dfdsf')
              if (document.querySelector(".not-found-page") !== null) {
                handleMenuViewClick();
              } else {
                if (
                  (pathname.pathname.split("/")[1] === "videos" &&
                    action !== undefined &&
                    action !== "listing" &&
                    action !== "myvideos" &&
                    action !== "history" &&
                    action !== "saved") ||
                  (pathname.pathname.split("/")[1] !== "videos" &&
                    pathname.pathname.split("/")[1] !== "documents" &&
                    pathname.pathname.split("/")[1] !== "partners" &&
                    pathname.pathname.split("/")[1] !== "homepage" &&
                    pathname.pathname.split("/")[1] !== "dashboard" &&
                    pathname.pathname.split("/")[1] !== "frontnews" &&
                    pathname.pathname.split("/")[1] !== "facebook-groups" &&
                    pathname.pathname.split("/")[1] !== "memberchapterpage" &&
                    pathname.pathname.split("/")[1] !== "member-link" &&
                    pathname.pathname.split("/")[1] !==
                    "census-data-google-studio" &&
                    pathname.pathname.split("/")[1] !== "airbnb-directory" &&
                    pathname.pathname.split("/")[1] !== "book-club" &&
                    pathname.pathname.split("/")[1] !== "employee-blocklist" &&
                    pathname.pathname.split("/")[1] !== "employee-hire" &&
                    pathname.pathname.split("/")[1] !== "employee-match" &&
                    pathname.pathname.split("/")[1] !== "freelancers" &&
                    pathname.pathname.split("/")[1] !== "gift-guide" &&
                    pathname.pathname.split("/")[1] !== "team" &&
                    pathname.pathname.split("/")[1] !== "values" &&
                    pathname.pathname.split("/")[1] !== "photos" &&
                    pathname.pathname.split("/")[1] !== "member-score" &&
                    pathname.pathname.split("/")[1] !== "squads")
                )
                  setglobalmenu(!globalmenu);
                if (document.body.classList.contains("menuview")) {
                  document.body.classList.remove("menuview");
                } else if (
                  pathname.pathname.split("/")[1] === "frontcontentlibrary" ||
                  pathname.pathname.split("/")[1] === "homepage" ||
                  pathname.pathname.split("/")[1] === "partners" ||
                  pathname.pathname.split("/")[1] === "dashboard" ||
                  pathname.pathname.split("/")[1] !== "facebook-groups" ||
                  pathname.pathname.split("/")[1] !== "memberchapterpage" ||
                  pathname.pathname.split("/")[1] !== "member-link" ||
                  pathname.pathname.split("/")[1] !==
                  "census-data-google-studio" ||
                  pathname.pathname.split("/")[1] !== "airbnb-directory" ||
                  pathname.pathname.split("/")[1] !== "book-club" ||
                  pathname.pathname.split("/")[1] !== "employee-blocklist" ||
                  pathname.pathname.split("/")[1] !== "employee-hire" ||
                  pathname.pathname.split("/")[1] !== "employee-match" ||
                  pathname.pathname.split("/")[1] !== "freelancers" ||
                  pathname.pathname.split("/")[1] !== "gift-guide" ||
                  pathname.pathname.split("/")[1] !== "team" ||
                  pathname.pathname.split("/")[1] !== "values" ||
                  pathname.pathname.split("/")[1] !== "photos" ||
                  pathname.pathname.split("/")[1] !== "member-score" ||
                  pathname.pathname.split("/")[1] !== "squads" ||
                  pathname.pathname.split("/")[2] !== "detail" ||
                  pathname.pathname.split("/")[1] === "frontnews"
                ) {
                  document.body.classList.add("menuview");
                }
              }
            }}
          >
            <img className="header-ft-vn" src={ViewNav} />
          </div>

          <a
            onClick={() => {
              history("/");
            }}
          >
            <img src={Logo} />
          </a>
        </div>
        {window.location.pathname.includes("videos") &&
          <div className="header-search"  >
            <SearchField
              isToanl={true}
              isRounded
              handleChange={handleSearch}
              value={searchText}
              handleKeyDown={handleKeyPress}
              handleFocus={(e) => {
                getVideoSuggestionAndHistory();
                handleSearch(e, searchText);
                setListIndex(-1);
                setSuggestionBox(true);
              }}
              handleClear={handleClearSearch}
              onBlur={() => setSuggestionBox(false)}
            />
            {suggestionBox && (
              <SuggestionDropDown
                text={searchText}
                ref={dropdownRef}
                options={finalSuggestionArr}
                listIndex={listIndex}
                id="suggestion-list"
                size="large"
                onMouseDown={(data) => {
                  history(`/videos?type=${listType ? listType : "all"}&filter=recent&search=${data.name}${categoryId ? `&catid=${categoryId}` : ""}${subcategoryId ? `&subcategoryId=${subcategoryId}` : ""}${speakerId ? `&speaker=${speakerId}` : ""}${datefilterId ? `&datefilter=${datefilterId}` : ""}`)
                  onTitleSelection(data.name);
                }}
                handleRemove={removeHistoryApi}
              />
            )}
          </div>
        }
        <div className="header-ft-element-sec">
          {/* {process.env.REACT_APP_TESTSITE_HOSTNAME !== "app.milliondollarsellers.com" && */}
          {showNotificationList && (
            <Notifications
              setshowNotificationList={setshowNotificationList}
              showNotificationList={showNotificationList}
              hasNotifications={checkHasNotifications}
            // notificationData={notificationData.notification}
            />
          )}
          {/* } */}
          <Button
            elementIcon={''}
            handleClick={() => dispatch(setModalIsOpen())}
            label="Refer a member"
            size="small"
            variant="ghost"
            hasIconLeft={false}
            hasText={true}
          />
          <div className="header-ft-menu-icon header-chat-main">
            <div className="chat-desk">
              <Button
                size="small"
                variant="ghost"
                hasText={false}
                elementIcon={<NavChatIcon />}
                hasIconLeft={true}
                className="chat-photo-mn"
                handleClick={() => {
                  // history("/chats/me/me");
                  window.open("/chats/me/me", "_blank");
                }}
              />
              {countChat > 0 ? <span className="red-on"></span> : <></>}
            </div>
          </div>

          <div className="header-ft-icon">
            {notificationData?.notification.map(
              (itme) => !itme.isRead && <NotificationBadge />
            )}
            {/* {process.env.REACT_APP_TESTSITE_HOSTNAME !== "app.milliondollarsellers.com" && */}
            <Button
              elementIcon={<NotificationIcon />}
              onContextMenu={() => { }}
              onMouseEnter={() => { }}
              onMouseLeave={() => { }}
              handleClick={showNotificationHandler}
              label="Button"
              isIconFilled
              size="small"
              toolTipId=""
              toolTipText="Notifications"
              variant="ghost"
              hasText={false}
            ></Button>
            {/* } */}
          </div>
          <div
            className="header-ft-menu-user"
            onClick={() => {
              if (
                window.location.hostname !==
                process.env.REACT_APP_TESTSITE_HOSTNAME
              ) {
                if (about) setabout(false);
                else setabout(true);
              }
            }}
          >
            <div className="header-ft-menu-user-main" data-toggle="dropdown">
              <span className="header-ft-photo-user">
                <span
                  class="chat-on-off"
                  style={{
                    background:
                      Redu_Profile !== null && Redu_Profile !== undefined
                        ? "#2CBF88"
                        : "",
                  }}
                ></span>
                {(localStorage.getItem("userprofile") &&
                  localStorage.getItem("userprofile").length) ||
                  (Redu_Profile &&
                    Redu_Profile.profileImg &&
                    Redu_Profile.profileImg.length &&
                    Redu_Profile._id === localStorage.getItem("userid")) ? (
                  <img
                    src={
                      Redu_Profile !== undefined &&
                        Redu_Profile !== null &&
                        Redu_Profile.profileImg !== null &&
                        Redu_Profile.profileImg !== undefined &&
                        Redu_Profile._id === localStorage.getItem("userid")
                        ? Redu_Profile.profileImg
                        : localStorage.getItem("userprofile") &&
                          localStorage.getItem("userprofile").length
                          ? localStorage.getItem("userprofile")
                          : Image1
                    }
                  />
                ) : (
                  <div class="cstm-avat-header-tp">
                    {localStorage.getItem("username")
                      ? localStorage.getItem("username").charAt(0)
                      : ""}
                  </div>
                )}
              </span>
            </div>

            <div
              className="dropdown-menu header-menu-dp"
              onClick={() => {
                if (about) setabout(false);
                else setabout(true);
              }}
            >
              <ul>
                <li>
                  <div className="cstm-profile-info">
                    <div className="cstm-profile-info-user">
                      {" "}
                      {localStorage.getItem("userprofile") &&
                        localStorage.getItem("userprofile").length ? (
                        <img
                          src={
                            localStorage.getItem("userprofile") &&
                              localStorage.getItem("userprofile").length
                              ? localStorage.getItem("userprofile")
                              : Image1
                          }
                        />
                      ) : (
                        <div class="cstm-avat-header-img">
                          {localStorage.getItem("username")
                            ? localStorage.getItem("username").charAt(0)
                            : ""}
                        </div>
                      )}
                    </div>
                    <div className="cstm-profile-info-name">
                      {localStorage.getItem("username")}
                    </div>
                  </div>
                </li>

                <li>
                  <a
                    onClick={() => {
                      history(`/settings/edit`);
                    }}
                  >
                    {/* <i class="fa fa-cog"></i> */}
                    <img src={EditPF} />
                    Settings
                  </a>
                </li>
                <li>
                  <a onClick={() => dispatch(setModalIsOpen())}
                  >
                    {/* <i class="fa fa-cog"></i> */}
                    <img src={ReferralProgram} />
                    Referral program
                  </a>
                </li>

                <li className="cstm-hd-as">
                  <a
                    onClick={() => {
                      history(`/settings/profile`);
                    }}
                  >
                    <img src={AccountSettingsPF} />
                    Account settings
                  </a>
                </li>
                <li>
                  <a
                    href="https://mds-community.canny.io/feature-request"
                    target="_blank"
                  >
                    <ChannelIcon width={20} height={20} iconColor="#201E29" />
                    Request a feature
                  </a>
                </li>
                {/* <li>
                  <a
                    onClick={() => {
                      history(`/settings/contact-us`);
                    }}
                  >
                    <img src={MessagePF} />
                    Contact us
                  </a>
                </li> */}

                <li>
                  <a onClick={handleLogout}>
                    <img src={LoginPF} /> Sign out
                  </a>
                </li>
                <li>
                  <div className="cstm-pt-link">
                    <a href="https://www.milliondollarsellers.com/privacy-policy">
                      Privacy
                    </a>
                    <a href="https://www.milliondollarsellers.com/terms-of-service">
                      Terms
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {xyz ? <GlobleSearch setxyz={setxyz} /> : <></>}
        </div>

        <GlobalMenuPopup
          setglobalmenu={setglobalmenu}
          globalmenu={globalmenu}
        />

        {isModalOpen && <ReferralModal />}
      </div >
    </>
  );
};
export default Header;
