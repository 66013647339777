import { useEffect, useState } from "react";
import Services from "../../../Services/auth.service";
// icon
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
const UserListingPopup = ({ setclose, openingpop }) => {
  const [friendlist, setfriendlist] = useState([]);
  const [joinedgroup, setjoinedgroup] = useState([]);
  const [disfriendlist, setdisfriendlist] = useState([]);
  const [disjoinedgroup, setdisjoinedgroup] = useState([]);
  useEffect(() => {
    Services.getfriendlist()
      .then((res) => {
        if (res.data.status) {
          setfriendlist(res.data.data);
          setdisfriendlist(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.myjoinedgroup()
      .then((res) => {
        if (res.data.status) {
          setjoinedgroup(res.data.data);
          setdisjoinedgroup(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      <div className="globle-model-box for-globle-model-box">
        <div className="an-globle-model-box-inner">
          <div className="an-globle-model-heading">
            <button
              className="an-globle-back"
              onClick={() => {
                setclose({ ...openingpop, chat: false });
              }}
            >
              <ArrowLeftLineIcon size="20px" />
            </button>
            <div className="an-globle-model-heading-sec">
              <h3>User listing</h3>
            </div>
          </div>
          <ul className="chat-list-user-box">
            {disfriendlist !== undefined &&
            disfriendlist !== null &&
            disfriendlist.length > 0
              ? disfriendlist.map((friend) => {
                  return (
                    <li className="chat-list-select">
                      <div className="header-chat-cont-list-photo">
                        <img src={Image1} className="chat-user-phto" />
                        <span
                          className="chat-noti-lt"
                          style={{ background: "#01AE12" }}
                        ></span>
                      </div>
                      <div className="header-chat-cont-list-cont">
                        <a className="chat-sg-ttl-main">
                          {friend.otherdetail[
                            process.env.REACT_APP_FIRSTNAME_DBID
                          ] +
                            " " +
                            friend.otherdetail[
                              process.env.REACT_APP_LASTNAME_DBID
                            ]}
                        </a>
                        {/* <span className="chat-sg-msg">
                        <img src={Yes3} /> Thank you so much time chat
                      </span> */}
                      </div>
                      <div className="header-chat-cont-list-date">
                        {/* <span className="chat-sg-date"> 12 Jul, 2022</span>
                      <span className="chat-sg-count">6</span> */}
                      </div>
                    </li>
                  );
                })
              : ""}
            {disjoinedgroup !== undefined &&
            disjoinedgroup !== null &&
            disjoinedgroup.length > 0
              ? disjoinedgroup.map((group) => {
                  return (
                    <li className="chat-list-select">
                      <div className="header-chat-cont-list-photo">
                        <img src={Image1} className="chat-user-phto" />
                        <span
                          className="chat-noti-lt"
                          style={{ background: "#01AE12" }}
                        ></span>
                      </div>
                      <div className="header-chat-cont-list-cont">
                        <a className="chat-sg-ttl-main">
                          {group !== null ? group.groupTitle : ""}
                        </a>
                        {/* <span className="chat-sg-msg">
                        <img src={Yes3} /> Thank you so much time chat
                      </span> */}
                      </div>
                      <div className="header-chat-cont-list-date">
                        {/* <span className="chat-sg-date"> 12 Jul, 2022</span>
                      <span className="chat-sg-count">6</span> */}
                      </div>
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </>
  );
};
export default UserListingPopup;