import { useEffect, useState, useRef, createRef, useMemo } from "react";

import InputEmoji from "react-input-emoji";
import loading from "../../../Images/cmtprocess.gif";

const CommentReply = ({ allcomments, coment, reply, setreply, index, postId, addcomment, cmtinprocess, suggestionList ,replyinprocess }) => {
    const inputRef = useRef(null);
    const [showSuggestions, setShowSuggestions] = useState(false); 
    const [comment, setComment] = useState("")
    function focusInput() {
        inputRef.current.focus();
    }
    function Suggestions_resp(props) {
        function selectSuggestion(username) {
            const regexp = /@[a-zA-Z0-9]*$/;
            const newValue = props.inputValue.replace(regexp, username + " ");
            setreply({
                ...reply,
                [coment._id]: newValue,
            });
            setComment(newValue)
            props.focusInput();
            props.setShowSuggestions(false);
        }
        const suggestionItems = props.suggestionList.map((item) => (
            <div className="item" onClick={() => selectSuggestion("@" + item)}>
                @{item}
            </div>
        ));
        return <div className="cstm-newsfeed-drop">{suggestionItems}</div>;
    }
    useEffect(() => {
        setreply({ ...reply, [coment._id]: comment })
    }, [comment])
    return (
        <div className="newsfeed-post-right-write-right">
            <div className="newsfeed-write-your-comments-tx" id={coment._id}>
                <InputEmoji
                    ref={inputRef}
                    value={
                        comment
                    }
                    onChange={(text) => {
                        setComment(text)
                        const regexp = /@[a-zA-Z0-9]*$/;
                        if (regexp.test(text.split(" ").pop())) {
                            setShowSuggestions(true);
                        } else {
                            setShowSuggestions(false);
                        }
                    }}
                    onEnter={(text) => {
                        if (!cmtinprocess && (replyinprocess[coment._id] === undefined || !replyinprocess[coment._id])) {

                            addcomment(postId._id, coment._id);
                            setComment("")
                        }
                    }}
                    placeholder={
                        reply[coment._id] === undefined ||
                            (reply[coment._id] !== undefined &&
                                reply[coment._id].length === 0)
                            ? "write your reply here..."
                            : ""
                    }
                />
                <span
                    className="wyc-send"
                    onClick={() => {
                        if (!cmtinprocess && (replyinprocess[coment._id] === undefined || !replyinprocess[coment._id])) {
                            addcomment(postId._id, coment._id);
                            setComment("")
                        }

                    }}
                >
                
                {replyinprocess[coment._id] === true ? <img src={loading} className="dis-loader-cmt" /> : <i className="fa fa-paper-plane"></i>}

                </span>
                {showSuggestions && (
                    <Suggestions_resp
                        commentid={coment._id}
                        inputValue={comment}
                        suggestionList={suggestionList}
                        focusInput={focusInput}
                        setShowSuggestions={setShowSuggestions}
                        showSuggestions={showSuggestions}
                        setvalue={setreply}
                    />
                )}
            </div>

        </div>
    )
}
export default CommentReply;
