import React from "react";
import { Link, useNavigate } from "react-router-dom";

// Import css file
import "../../../Styles/registration.css";

// Import images
import logo from "../../../Images/logo.png";
import applyandpay from "../../../Images/apply-and-pay.png";
import screening from "../../../Images/screening.png";
import interview from "../../../Images/interview.png";
import membersiteaccess from "../../../Images/member-site-access.png";
import maingroupaccess from "../../../Images/main-group-access.png";
import optimistic from "../../../Images/optimistic.png";

const BecomeMember = () => {
  const history = useNavigate()
  return (
    <>
      {/* First step in registration */}
      <div className="um-main cstm-um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn csmt-um-left-in-mn">
                  <div className="um-left-in-content-sec">
                    <div className="um-title2">
                      <h2>
                        Welcome to the
                        <br /> First Step of Your
                        <br /> Application
                      </h2>
                    </div>
                    <div className="um-text um-text-2">
                      <a href="#" className="lets-get">
                        Let’s Get Started
                      </a>
                      <img src={optimistic} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 um-right cstm-um-right-2">
              <div className="um-right-in2">
                <div className="um-title-how">How to become a member</div>
                <p>
                  To begin the application process, please fill out the
                  questions below and submit your payment. Please note that
                  there will be additional steps of this application which will
                  involve more questions about yourself and your business,
                  interviews, and revenue verifications. If you do not meet our
                  minimum requirement of $1,000,000 in trailing revenue on
                  Amazon, your application will be denied.
                </p>
                <p>
                  We take pride in the curation of our community. All of the
                  steps we take are to ensure that your experience in MDS
                  exceeds expectations. If for any reason we do not accept your
                  application, you will receive a full refund of your payment.
                  Also, if for any reason you feel that you are not receiving
                  value from your membership, you will be prorated for your time
                  in the community and receive a refund of the remainder of your
                  payment.
                </p>
                <p>
                  Please note that the application process takes 4-8 weeks of
                  waiting. We thank you for your patience.
                </p>

                <div className="um-member-step">
                  <div className="um-member-step-in">
                    <div className="um-member-step-in-box">
                      <div className="um-member-step-in-box-number">Step 1</div>
                      <div className="um-member-step-in-box-icon">
                        <img src={applyandpay} />
                      </div>
                      <div className="um-member-step-in-box-title">
                        Apply and Pay
                      </div>
                      <div className="um-member-step-in-box-content">
                        Complete basic info and pay membership fee. Fee is fully
                        refundable if you decide to abandon the process or are
                        not approved.
                      </div>
                    </div>
                    <div className="um-member-step-in-box">
                      <div className="um-member-step-in-box-number">Step 2</div>
                      <div className="um-member-step-in-box-icon">
                        <img src={screening} />
                      </div>
                      <div className="um-member-step-in-box-title">
                        Screening
                      </div>
                      <div className="um-member-step-in-box-content">
                        Gain access to internal portal and complete screening
                        application period at the end
                      </div>
                    </div>
                    <div className="um-member-step-in-box">
                      <div className="um-member-step-in-box-number">Step 3</div>
                      <div className="um-member-step-in-box-icon">
                        <img src={interview} />
                      </div>
                      <div className="um-member-step-in-box-title">
                        Interviews
                      </div>
                      <div className="um-member-step-in-box-content">
                        Screening interviews from 2 fellow members.
                      </div>
                    </div>
                    <div className="um-member-step-in-box">
                      <div className="um-member-step-in-box-number">Step 4</div>
                      <div className="um-member-step-in-box-icon">
                        <img src={membersiteaccess} />
                      </div>
                      <div className="um-member-step-in-box-title">
                        Member Site Access
                      </div>
                      <div className="um-member-step-in-box-content">
                        Gain access to membership site, event registrations,
                        chapters, lifestyle groups and begin your annual
                        membership. Time between steps 4{" "}
                        <a className="read-more" href="#">
                          Read More...
                        </a>
                      </div>
                    </div>
                    <div className="um-member-step-in-box">
                      <div className="um-member-step-in-box-number">Step 5</div>
                      <div className="um-member-step-in-box-icon">
                        <img src={maingroupaccess} />
                      </div>
                      <div className="um-member-step-in-box-title">
                        Main Group Access
                      </div>
                      <div className="um-member-step-in-box-content">
                        Gain access to main Facebook group. Prepare your value
                        add. Connect with your community lead.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="um-rgt-btn">
                  <button
                    className="btn2"
                    onClick={() => {
                      history("/firststepregister/2");
                    }}
                  >
                    Start Application
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeMember;
