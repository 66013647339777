import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import moment from "moment";
import ChatDetails from "../Components/ChatDetails";
import authService from "../../../Services/auth.service";
import ChatDetailsNotifications from "../Components/ChatDetailsNotifications";
import { getMuteChatIdListForUser } from "../../../Action";
import ChatDetailsMembers from "../Components/ChatDetailsMembers";
import ModelPopup from "../../../components/ModalPopup/ModalPopup";
import newsocket from "../../../components/SocketConnect";
import ChatDetailsEdit from "../Components/ChatDetailsEdit";
import ChatDetailsMediaMembers from "../Components/ChatDetailsMediaMembers";
import {
  formatMediaPreviewMessages,
  formatMediaPreviewMessagesAllMedia,
} from "../CommonFunctions/AllMedia";
import MediaPreview from "./MediaPreview";
import ClosePopup from "../../../Images/close-popup.svg";

const ChatRightSideBar = ({
  groupMemberList,
  handleAddMemberClick,
  editGroup,
  setEditGroup,
  handleRemoveMember,
}) => {
  let { userid, type } = useParams();
  const dispatch = useDispatch();
  const [sidebarDetail, setSidebarDetail] = useState({});
  const [isOpenSiderBar, setIsOpenSiderBar] = useState(false);
  const [isChatDetailsNotificationOpen, setIsChatDetailsNotificationOpen] =
    useState(false);
  const [isChatDetailsMembersOpen, setIsChatDetailsMembersOpen] =
    useState(false);
  const [isMediaFilesOpen, setIsMediaFilesOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [cropedFile, setCropedFile] = useState();
  const [groupProfile, setGroupProfile] = useState(null);
  const [groupName, setGroupName] = useState(groupMemberList?.groupTitle || "");
  const [errmsg, setErrorMsg] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [newMediaList, setNewMedeaList] = useState([]);
  const [isMediaSelected, setIsMediaSelected] = useState(true);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isLinkSelected, setIsLinkSelected] = useState(false);
  const [showPreview, setPreview] = useState(false);
  const [showFileData, setFileData] = useState([]);
  const [filePreview, setFilePreview] = useState("");
  const [selectedImageVideo, setSelectedImageVideo] = useState("");
  const [previewDisplay, setPreviewDisplay] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [disabledRemoveButton, setDisabledButton] = useState(null);

  //Get Mute ChatList Data
  const muteNotificationList = useSelector(
    (state) => state?.muteNotificationReducer
  );

  const chatAllUsers = useSelector((state) => state.ReduAllUsersList);
  //Get media files and link Data
  const mediaList = useSelector((state) => state?.AllMediaPreview);

  useEffect(() => {
    const data = formatMediaPreviewMessagesAllMedia(mediaList);
    setNewMedeaList(data);
  }, [mediaList]);

  const filesList = useSelector((state) => state?.AllChatFileList);

  const linkList = useSelector((state) => state?.AllUrlList);

  // calling API For Mute ChatList
  const getMuteNotificationList = async () => {
    dispatch(getMuteChatIdListForUser());
  };

  // to Call Function for set Group name and profile
  useEffect(() => {
    setGroupName(groupMemberList?.groupTitle);
    setGroupProfile(groupMemberList?.groupImage);
  }, [groupMemberList]);

  // to set user profile data
  useEffect(() => {
    setIsOpenSiderBar(true);
    getMuteNotificationList();
    if (type === "user") {
      authService.getmemberprofile(userid).then((response) => {
        if (response.data.status) {
          let formatData = {
            name: `${response?.data?.data &&
              (response.data.data?.first_name || response.data.data?.first_name)
              ? `${response.data.data?.first_name} ${response.data.data?.last_name}`
              : ""
              }`,
            profilePic: response?.data?.data?.profileImg.length
              ? response.data.data.profileImg
              : "",
            dateTimeSinceBeenMember: "",
          };

          setSidebarDetail({ ...sidebarDetail, [userid]: formatData });
        }
      });
    }
  }, [userid, type]);

  // Edit group receive event to update data
  useEffect(() => {
    newsocket.on("edit-group-receive", (res) => {
      if (res.message.status) {
        if (
          res.message.data.created_by._id === localStorage.getItem("userid")
        ) {
          newsocket.emit("group-member-detail", {
            groupId: res.message.data._id,
          });
          newsocket.emit("newChatList", {
            id: localStorage.getItem("userid"),
          });
          setIsLoader(false);
          setEditGroup(false);
        }
      } else {
        if (
          res.message.data.created_by._id === localStorage.getItem("userid")
        ) {
          setIsLoader(false);
        }
        newsocket.emit("group-member-detail", {
          groupId: res.message.data._id,
        });
        newsocket.emit("newChatList", {
          id: localStorage.getItem("userid"),
        });
      }
    });
  }, [newsocket]);

  // onClick notification right side
  const onClickNotification = () => {
    setIsOpenSiderBar(false);
    setIsChatDetailsNotificationOpen(true);
  };
  // onClick back button in notification
  const onClickBackNotificationButton = () => {
    setIsOpenSiderBar(true);
    setIsChatDetailsNotificationOpen(false);
  };
  // to Change notification data and update that
  const onChangeNotificationSwitch = () => {
    const payload = {
      chatId: userid,
      unMute: muteNotificationList?.muteNotification?.filter(
        (muteData) => userid === muteData.chatId
      ).length
        ? true
        : false,
      dateTime: "",
      noLimit: muteNotificationList?.muteNotification?.filter(
        (muteData) => userid === muteData.chatId
      ).length
        ? false
        : true,
    };

    // Call the API with the payload
    authService
      .muteChatForAll(payload)
      .then((response) => {
        if (response.data.status) {
          getMuteNotificationList();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // For Change badge switch toggle
  const onChangeBadgesSwitch = () => {
    const payload = {
      chatId: userid,
    };
    authService.badgesForNotification(payload).then((response) => {
      if (response.data.status) {
        getMuteNotificationList();
      }
    });
  };

  //onClick members details right side
  const onClickMemberDetails = () => {
    setIsOpenSiderBar(false);
    setIsChatDetailsMembersOpen(true);
  };
  // onClick member details back button
  const onClickBackMemberDetails = () => {
    setIsOpenSiderBar(true);
    setIsChatDetailsMembersOpen(false);
  };
  // for to search group or channel members in right side
  const handleSearchClick = (e) => {
    setSearchValue(e.target.value);
  };
  // hanbdle search clear
  const handleSearchClear = () => {
    setSearchValue("");
  };

  // for heandle media and files sidebar
  const onClickMediaDetails = () => {
    setIsOpenSiderBar(false);
    setIsMediaFilesOpen(true);
  };

  const onClickBackMediaFiles = () => {
    setIsOpenSiderBar(true);
    setIsMediaFilesOpen(false);
  };

  // for preview
  //onClick preview handle
  const handlePreviewClick = (id, type) => {
    if (type === "file") {
      const data = {
        url: id.key,
        fileName: id.key?.split("?")[0].split("-GRPCHATUNIQUE-").pop(),
      };
      setFileData(data);
    }
    setSelectedImageVideo(id);
    setFilePreview(type);
    setPreview(true);
  };

  // onClick Close media preview popup
  const handleClosePreview = () => {
    setPreview(false);
    setPreviewDisplay(false);
    setSelectedImageVideo("");
  };

  // pass avatar letter in data
  const AvtarLetter =
    type === "user"
      ? sidebarDetail && sidebarDetail[userid]
        ? sidebarDetail[userid]?.name?.charAt(0).toUpperCase()
        : ""
      : type === "userChatGroup"
        ? groupMemberList?.groupTitle?.charAt(0).toUpperCase()
        : type === "chatChannel"
          ? groupMemberList?.channelData?.channelName?.charAt(0).toUpperCase()
          : "";

  //to pass profile data in group and channel
  const profileImage =
    type === "user"
      ? sidebarDetail && sidebarDetail[userid]
        ? sidebarDetail[userid].profilePic
        : ""
      : type === "userChatGroup"
        ? groupMemberList?.groupImage
        : type === "chatChannel"
          ? groupMemberList?.channelData?.channelIcon
          : "";

  // Modify the existing condition for src
  const src =
    profileImage &&
      profileImage.split("?")[0].split("/")[
        profileImage.split("?")[0].split("/").length - 1
      ].length &&
      profileImage.startsWith("https")
      ? profileImage
      : profileImage &&
        profileImage.split("?")[0].split("/")[
          profileImage.split("?")[0].split("/").length - 1
        ].length
        ? process.env.REACT_APP_AWS_IMG_VID_PATH + profileImage
        : "";

  //Will call on dropping or setting file to dropzone
  const assignFiles = (acceptedFile) => {
    setModalOpen(true);
    setFileName(Math.random().toString() + acceptedFile.name);
    setFile(URL.createObjectURL(acceptedFile));
  };

  //When Handle Cropping completed
  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCompletedCrop(croppedAreaPixels);
    setCrop(croppedAreaPixels);
  };

  // when crop submit
  const handleCropSubmit = async () => {
    const originalImage = document.querySelector(".crop-media-image");
    originalImage.crossOrigin = "anonymous";

    // Wait for the image to load
    await new Promise((resolve) => {
      originalImage.onload = resolve;
    });

    let croppedImageBlob;
    if (completedCrop) {
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        completedCrop,
        "crop-image" + new Date().getTime() + ".jpg"
      );
    } else {
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        crop,
        "crop-image" + new Date().getTime() + ".jpg"
      );
    }
    // Display the cropped image
    const croppedImageUrl = croppedImageBlob;
    const cropImageData = dataURLtoFile(croppedImageBlob);
    setCropedFile(cropImageData);
    setModalOpen(false);
    setGroupProfile(URL.createObjectURL(cropImageData));
    setFile("");
  };

  // get url cropping image
  async function getCroppedBase64(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const cropInPercentage = {
      aspect: crop.aspect,
      unit: "%",
      width: crop.width,
      height: crop.height,
      x: crop.x,
      y: crop.y,
    };

    // Get the actual dimensions of the image
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;

    // Convert percentage values to pixels
    const cropInPixels = {
      aspect: cropInPercentage.aspect,
      unit: "px", // Change to 'px' to use pixel values
      width: (cropInPercentage.width / 100) * imageWidth,
      height: (cropInPercentage.height / 100) * imageHeight,
      x: (cropInPercentage.x / 100) * imageWidth,
      y: (cropInPercentage.y / 100) * imageHeight,
    };

    canvas.width = cropInPixels.width;
    canvas.height = cropInPixels.height;

    ctx.drawImage(
      image,
      cropInPixels.x,
      cropInPixels.y,
      cropInPixels.width,
      cropInPixels.height,
      0,
      0,
      cropInPixels.width,
      cropInPixels.height
    );

    // Convert the canvas content to a base64-encoded string
    const croppedImageUrl = canvas.toDataURL("image/jpeg");
    return croppedImageUrl;
  }

  //handle chaneg in crop
  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  //covert function dataURLtoFile
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], fileName, { type: mime });
  };

  //edit socket logic
  const editSocket = (image) => {
    let payload = {
      sender: localStorage.getItem("userid"),
      groupid: userid,
      group_image: image.length > 0 ? image : undefined,
      group_name: groupName,
      participents: [],
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
    };
    newsocket.emit("edit-group", payload);
  };

  // onClick Edit group image and name
  const onClickEditSave = () => {
    if (groupName) {
      setIsLoader(true);
      var formdata = new FormData();
      formdata.append("media", cropedFile);
      if (cropedFile) {
        authService
          .uploadGroupImage(formdata)
          .then((res) => {
            editSocket(res.data.media[0].key);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        editSocket("");
      }
    } else {
      setErrorMsg("Please add group name and image to change");
    }
  };

  //onChange Edit group title
  const handleChangeInput = (e) => {
    setGroupName(e.target.value);
  };

  // to pass this for muteNotification
  const isMuted = muteNotificationList?.muteNotification?.filter(
    (muteData) => userid === muteData.chatId
  ).length
    ? false
    : true;

  // to pass this for Badges
  const isBadges = muteNotificationList?.notificationBadgeNotDisplay?.filter(
    (muteData) => userid === muteData
  ).length
    ? false
    : true;

  // onClick back button in edit group
  const onClickBackButton = () => {
    setIsOpenSiderBar(true);
    setEditGroup(false);
  };

  const handleTabClick = (tabIndex) => {
    if (tabIndex === 0) {
      setIsMediaSelected(true);
      setIsFileSelected(false);
      setIsLinkSelected(false);
    } else if (tabIndex === 1) {
      setIsMediaSelected(false);
      setIsFileSelected(true);
      setIsLinkSelected(false);
    } else if (tabIndex === 2) {
      setIsMediaSelected(false);
      setIsFileSelected(false);
      setIsLinkSelected(true);
    }
  };
  // onClick Remove member
  const onClickRemoveMember = (removeMemberId) => {
    newsocket.emit("remove-member", {
      groupid: userid,
      authUserId: localStorage.getItem("userid"),
      removemember: [removeMemberId],
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
    });
    newsocket.emit("remove-member-activity", {
      groupid: userid,
      authUserId: localStorage.getItem("userid"),
      removemember: [removeMemberId],
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
    });
    handleRemoveMember(removeMemberId);
  };

  let onlineStatus =
    chatAllUsers &&
    chatAllUsers?.filter((user) => user?.id === userid && user?.onlineStatus)
      .length;

  // Determine the number of members based on the type
  const memberCount =
    type === "chatChannel"
      ? groupMemberList?.membersList?.length - 1
      : groupMemberList?.members?.length - 1;

  // Check if there is an admin in the group
  const hasAdmin = groupMemberList?.members?.some(
    (member) => member.user_type === "adminuser"
  );
  let membersText = "";

  // Construct the membersText only if there are members
  if (memberCount > 0) {
    const memberText = memberCount === 1 ? "member" : "members";
    membersText = `${memberCount} ${memberText}`;
  }
  // Append admin text if there is an admin
  if (type !== "chatChannel" && hasAdmin) {
    membersText += ` 1 admin`;
  }

  return (
    <>
      {errmsg}
      {isOpenSiderBar && !editGroup && (
        <ChatDetails
          hasStatus={
            type === "chatChannel" || type === "userChatGroup" ? false : true
          }
          isOnline={onlineStatus ? "online" : "offline"}
          type={
            type === "chatChannel"
              ? "Channel"
              : type === "userChatGroup"
                ? "Group"
                : "Message"
          }
          channelOrGroupNameOrName={
            type === "user"
              ? sidebarDetail && sidebarDetail[userid]
                ? sidebarDetail[userid].name
                : ""
              : type === "userChatGroup"
                ? groupMemberList?.groupTitle
                : type === "chatChannel"
                  ? groupMemberList?.channelData?.channelName
                  : ""
          }
          src={src}
          letter={AvtarLetter}
          isAdmin={
            type === "userChatGroup" &&
              groupMemberList &&
              groupMemberList?.members?.filter(
                (member) =>
                  member.userId === localStorage.getItem("userid") &&
                  member.user_type === "adminuser"
              ).length
              ? true
              : false
          }
          handleButtonClick={handleAddMemberClick}
          onClickNotification={onClickNotification}
          onClickMemberDetails={onClickMemberDetails}
          onClickMediaDetails={onClickMediaDetails}
          membersCount={
            type === "chatChannel"
              ? groupMemberList?.membersList?.length - 1
              : groupMemberList?.members?.length
          }
          membersText={membersText}
          notificationTitle={`${isMuted ? "On" : "Off"}: Push notifications${isBadges || !isMuted ? ", Badges" : ""
            }`}
          mediaText={`${mediaList.length === 0 &&
            filesList.length === 0 &&
            linkList.length === 0
            ? "No media"
            : mediaList.length !== 0
              ? `${mediaList.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.images.length;
              }, 0) +
              mediaList.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.videos.length;
              }, 0)
              } media `
              : ""
            }${mediaList.length !== 0 && filesList.length !== 0 ? ", " : ""}${filesList.length !== 0
              ? `${filesList.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.documents.length;
              }, 0)} ${filesList.length !== 1 ? "files" : "file"} `
              : ""
            }${filesList.length !== 0 && linkList.length !== 0 ? ", " : ""}${linkList.length !== 0
              ? `${linkList.length} ${linkList.length !== 1 ? "links" : "link"}`
              : ""
            }`}
        />
      )}

      {isChatDetailsNotificationOpen && !editGroup && (
        <ChatDetailsNotifications
          onClickBackButton={onClickBackNotificationButton}
          onChangeNotificationSwitch={onChangeNotificationSwitch}
          onChangeBadgesSwitch={onChangeBadgesSwitch}
          isMuted={isMuted}
          isBadges={isBadges}
        />
      )}

      {isChatDetailsMembersOpen && !editGroup && (
        <ChatDetailsMembers
          isAdmin={
            type === "userChatGroup" &&
              groupMemberList &&
              groupMemberList?.members?.filter(
                (member) =>
                  member.userId === localStorage.getItem("userid") &&
                  member.user_type === "adminuser"
              ).length
              ? true
              : false
          }
          groupMemberList={
            type === "chatChannel"
              ? groupMemberList.membersList
              : groupMemberList.members
          }
          onClickBackMemberDetails={onClickBackMemberDetails}
          type={type}
          handleSearchClick={handleSearchClick}
          handleSearchClear={handleSearchClear}
          searchValue={searchValue}
          onClickRemoveMember={onClickRemoveMember}
          chatAllUsers={chatAllUsers}
          disabledRemoveButton={disabledRemoveButton}
          setDisabledButton={setDisabledButton}
        />
      )}
      {isMediaFilesOpen && !editGroup && (
        <ChatDetailsMediaMembers
          onClickBackMediaFiles={onClickBackMediaFiles}
          handleTabClick={handleTabClick}
          isSelected={isMediaSelected}
          isSelected1={isFileSelected}
          isSelected2={isLinkSelected}
          newMediaList={newMediaList}
          linkList={linkList}
          filesList={filesList}
          handlePreviewClick={handlePreviewClick}
        />
      )}

      {/* <ModelPopup
        className="model-image-cropper"
        isFooter={true}
        isHeader={true}
        buttonType="onebutton"
        buttonText1="Crop & Save"
        isOpen={modalOpen}
        modelSize="480"
        onClose={() => setModalOpen(false)}
        handleClick2ndButton={CropDone}
        headingLable="Replace profile picture ttteer"
      >
        <div className="cstm-replace-pp">
          {file && (
            <div
              id="cropImgDis"
              className="cropImgDis"
              style={{ width: `${0}px` }}
            >
              <img id="cropperImg" className="cstmCropperImg" />
            </div>
          )}
        </div>
      </ModelPopup> */}

      {modalOpen && (
        <div className="desk-globle-model-box globle-model-box checksboxes-visible cstm-rpp-main">
          <div className="desk-globle-model-box-inner edit-profile-cstm">
            <div className={`modal-content cropper-content`}>
              <div className="media-attachment-details-header">
                <h4 className="madh-h4">Replace profile picture</h4>
                <button
                  type="button"
                  className="madh-close"
                  onClick={(e) => {
                    setModalOpen(false);
                  }}
                >
                  <img src={ClosePopup} />
                </button>
              </div>
              <div className="crop-media-body">
                {file && (
                  <ReactCrop
                    crop={crop}
                    keepSelection={true}
                    aspect={1 / 1}
                    onChange={handleCropChange}
                    onComplete={handleCropComplete}
                  >
                    <img src={file} className="crop-media-image" />
                  </ReactCrop>
                )}
              </div>
              <div className="crop-media-footer justify-content-end">
                <button className="upload-media-btn" onClick={handleCropSubmit}>
                  Crop & Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {editGroup && (
        <ChatDetailsEdit
          onClickBackButton={onClickBackButton}
          handleImageUpload={assignFiles}
          handleChangeInput={handleChangeInput}
          groupName={groupName}
          isAdmin={false}
          src={groupProfile}
          onClickEditSave={onClickEditSave}
          isLoader={isLoader}
        />
      )}
      <MediaPreview
        mediaPreviewData={newMediaList} //formated data
        showPreview={showPreview} //  open model
        handleClosePreview={handleClosePreview} //close model
        mediaPreviewType={
          filePreview === "media"
            ? "media"
            : filePreview === "file"
              ? "file"
              : ""
        }
        selectedImageVideo={selectedImageVideo} //pass id
        showFileData={showFileData} // url name src object
        previewAttchamentDisplay={false}
      />
    </>
  );
};

export default ChatRightSideBar;
