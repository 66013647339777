const DisplayCustomRegistrationForm = ({
  field,
  handleChangeDBfields,
  otherDetailErr,
  otherUserDetail,
}) => {
  const dbemailId = process.env.REACT_APP_EMAIL_FIELD_DBID;
  const dbpasswordgridId = process.env.REACT_APP_PASSWORD_GRID_DBID;
  const displayOption = (
    fieldvalue,
    options,
    type,
    label,
    id,
    required,
    min,
    max
  ) => {
    // Display input field checkbox, dropdown, radiobutton
    const inputfeild =
      type === "select" ? (
        <select
          onChange={(e) => {
            handleChangeDBfields(e, required, min, max, label);
          }}
          name={id}
          value={fieldvalue}
        >
          <option value="">---Select {label}---</option>
          {options.map((opt) => {
            return <option>{opt}</option>;
          })}
        </select>
      ) : type === "checkbox" ? (
        <div className="um-right-in-box um-comm-prefe">
          <div className="row">
            {options.map((opt, index) => {
              return (
                <>
                  <div className="col-sm-4">
                    <div className="form-check um-cp-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={id}
                          value={opt}
                          onChange={(e) => {
                            handleChangeDBfields(e, required, min, max, label);
                          }}
                          checked={
                            fieldvalue !== undefined && fieldvalue.length > 0 && fieldvalue.indexOf(opt) !== -1 ? true : false
                          }
                        />
                        {opt}
                      </label>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="um-right-in-box um-comm-prefe">
          <div className="row">
            {options.map((opt) => {
              return (
                <>
                  <div className="col-sm-4">
                    <div className="form-check um-cp-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={opt}
                          name={id}
                          onChange={(e) => {
                            handleChangeDBfields(e, required, min, max, label);
                          }}
                          checked={fieldvalue === opt ? true : false}
                        />
                        {opt}
                      </label>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      );
    return <> {inputfeild} </>;
  };
  // Display fields as per grid number

  switch (field.grid) {
    case 1:
      return (
        <div className="form-group">
          <div className="um-right-in-label">
            {field["fields"][0]["label"]}
            {field["fields"][0]["required"].length > 0 ? <span>*</span> : ""}
            {field["fields"][0]["info"].length > 0 ? (
              <span className="um-fm-tl">
                <i
                  className="fa fa-exclamation-circle"
                  title={field["fields"][0]["info"]}
                ></i>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="um-right-in-box">
            {field["fields"][0]["options"].length > 0 ? (
              displayOption(
                otherUserDetail[field["fields"][0]["_id"]],
                field["fields"][0]["options"],
                field["fields"][0]["type"],
                field["fields"][0]["label"],
                field["fields"][0]["_id"],
                field["fields"][0]["required"],
                field["fields"][0]["min"],
                field["fields"][0]["max"]
              )
            ) : field["fields"][0]["type"] === "textarea" ? (
              <textarea
                name={field["fields"][0]["_id"]}
                id={field["fields"][0]["_id"]}
                placeholder={field["fields"][0]["placeholder"]}
                onChange={(e) => {
                  handleChangeDBfields(
                    e,
                    field["fields"][0]["required"],
                    field["fields"][0]["min"],
                    field["fields"][0]["max"],
                    field["fields"][0]["label"]
                  );
                }}
                value={otherUserDetail[field["fields"][0]["_id"]]}
              ></textarea>
            ) : field["fields"][0]["type"] === "file" ? (
              <input
                type={field["fields"][0]["type"]}
                name={field["fields"][0]["_id"]}
                id={field["fields"][0]["_id"]}
                placeholder={field["fields"][0]["placeholder"]}
                accept="image/*"
                onChange={(e) => {
                  handleChangeDBfields(
                    e,
                    field["fields"][0]["required"],
                    field["fields"][0]["min"],
                    field["fields"][0]["max"],
                    field["fields"][0]["label"]
                  );
                }}
              />
            ) : (
              <input
                type={field["fields"][0]["type"]}
                name={field["fields"][0]["_id"]}
                id={field["fields"][0]["_id"]}
                placeholder={field["fields"][0]["placeholder"]}
                // readOnly={true}
                onChange={(e) => {
                  handleChangeDBfields(
                    e,
                    field["fields"][0]["required"],
                    field["fields"][0]["min"],
                    field["fields"][0]["max"],
                    field["fields"][0]["label"]
                  );
                }}
                value={otherUserDetail[field["fields"][0]["_id"]]}
              />
            )}
          </div>
          <span className="error">
            {otherDetailErr[field["fields"][0]["_id"]]}
          </span>
        </div>
      );
    case 2:
      return (
        <div className="row">
           <div className="col-sm-6 form-group">
           <div className="um-right-in-label">
             {field["fields"][0]["label"]}
             {field["fields"][0]["required"].length > 0 ? <span> *</span> : ""}

             {field["fields"][0]["info"].length > 0 ? (
               <span className="um-fm-tl">
                 <i
                   className="fa fa-exclamation-circle"
                   title={field["fields"][0]["info"]}
                 ></i>
               </span>
             ) : (
               ""
             )}
           </div>
           <div className="um-right-in-box">
             {field["fields"][0]["options"].length > 0 ? (
               displayOption(
                 otherUserDetail[field["fields"][0]["_id"]],
                 field["fields"][0]["options"],
                 field["fields"][0]["type"],
                 field["fields"][0]["label"],
                 field["fields"][0]["_id"],
                 field["fields"][0]["required"],
                 field["fields"][0]["min"],
                 field["fields"][0]["max"]
               )
             ) : field["fields"][0]["type"] === "textarea" ? (
               <textarea
                 name={field["fields"][0]["_id"]}
                 id={field["fields"][0]["_id"]}
                 placeholder={field["fields"][0]["placeholder"]}
                 onChange={(e) => {
                   handleChangeDBfields(
                     e,
                     field["fields"][0]["required"],
                     field["fields"][0]["min"],
                     field["fields"][0]["max"],
                     field["fields"][0]["label"]
                   );
                 }}
                 value={otherUserDetail[field["fields"][0]["_id"]]}
               ></textarea>
             ) : field["fields"][0]["type"] === "file" ? (
               <input
                 type={field["fields"][0]["type"]}
                 name={field["fields"][0]["_id"]}
                 id={field["fields"][0]["_id"]}
                 placeholder={field["fields"][0]["placeholder"]}
                 accept="image/*"
                 onChange={(e) => {
                   handleChangeDBfields(
                     e,
                     field["fields"][0]["required"],
                     field["fields"][0]["min"],
                     field["fields"][0]["max"],
                     field["fields"][0]["label"]
                   );
                 }}
               />
             ) : (
               <input
                 type={field["fields"][0]["type"]}
                 name={field["fields"][0]["_id"]}
                 id={field["fields"][0]["_id"]}
                 placeholder={field["fields"][0]["placeholder"]}
                 value={otherUserDetail[field["fields"][0]["_id"]]}
                 onChange={(e) => {
                   handleChangeDBfields(
                     e,
                     field["fields"][0]["required"],
                     field["fields"][0]["min"],
                     field["fields"][0]["max"],
                     field["fields"][0]["label"]
                   );
                 }}
                // readOnly={true}
               />
             )}
           </div>
           <span className="error">
             {otherDetailErr[field["fields"][0]["_id"]]}
           </span>
         </div>
          <div className="col-sm-6 form-group">
            <div className="um-right-in-label">
              {" "}
              {field["fields"][1]["label"]}
              {field["fields"][1]["required"].length > 0 ? <span> *</span> : ""}
              {field["fields"][1]["info"].length > 0 ? (
                <span className="um-fm-tl">
                  <i
                    className="fa fa-exclamation-circle"
                    title={field["fields"][1]["info"]}
                  ></i>
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="um-right-in-box">
              {field["fields"][1]["options"].length > 0 ? (
                displayOption(
                  otherUserDetail[field["fields"][1]["_id"]],
                  field["fields"][1]["options"],
                  field["fields"][1]["type"],
                  field["fields"][1]["label"],
                  field["fields"][1]["_id"],
                  field["fields"][1]["required"],
                  field["fields"][1]["min"],
                  field["fields"][1]["max"]
                )
              ) : field["fields"][1]["type"] === "textarea" ? (
                <textarea
                  name={field["fields"][1]["_id"]}
                  id={field["fields"][1]["_id"]}
                  placeholder={field["fields"][1]["placeholder"]}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][1]["required"],
                      field["fields"][1]["min"],
                      field["fields"][1]["max"],
                      field["fields"][1]["label"]
                    );
                  }}
                  value={otherUserDetail[field["fields"][1]["_id"]]}
                ></textarea>
              ) : field["fields"][1]["type"] === "file" ? (
                <input
                  type={field["fields"][1]["type"]}
                  name={field["fields"][1]["_id"]}
                  id={field["fields"][1]["_id"]}
                  placeholder={field["fields"][1]["placeholder"]}
                  accept="image/*"
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][1]["required"],
                      field["fields"][1]["min"],
                      field["fields"][1]["max"],
                      field["fields"][1]["label"]
                    );
                  }}
                />
              ) : (
                <input
                  type={field["fields"][1]["type"]}
                  name={field["fields"][1]["_id"]}
                  id={field["fields"][1]["_id"]}
                  placeholder={field["fields"][1]["placeholder"]}
                  value={otherUserDetail[field["fields"][1]["_id"]]}
                  // readOnly={true}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][1]["required"],
                      field["fields"][1]["min"],
                      field["fields"][1]["max"],
                      field["fields"][1]["label"]
                    );
                  }}
                />
              )}
            </div>
            <span className="error">
              {otherDetailErr[field["fields"][1]["_id"]]}
            </span>
          </div>
        </div>
      );

    case 3:
      return (
        <div className="row">
           { otherUserDetail.mediasignup.length > 0 && field["fields"][0]["_id"] === dbemailId ? <></> :
          <div className="col-sm-4 form-group">
            <div className="um-right-in-label">
              {field["fields"][0]["label"]}
              {field["fields"][0]["required"].length > 0 ? <span> *</span> : ""}
              {field["fields"][0]["info"].length > 0 ? (
                <span className="um-fm-tl">
                  <i
                    className="fa fa-exclamation-circle"
                    title={field["fields"][0]["info"]}
                  ></i>
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="um-right-in-box">
              {field["fields"][0]["options"].length > 0 ? (
                displayOption(
                  otherUserDetail[field["fields"][0]["_id"]],
                  field["fields"][0]["options"],
                  field["fields"][0]["type"],
                  field["fields"][0]["label"],
                  field["fields"][0]["_id"],
                  field["fields"][0]["required"],
                  field["fields"][0]["min"],
                  field["fields"][0]["max"]
                )
              ) : field["fields"][0]["type"] === "textarea" ? (
                <textarea
                  name={field["fields"][0]["_id"]}
                  id={field["fields"][0]["_id"]}
                  placeholder={field["fields"][0]["placeholder"]}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][0]["required"],
                      field["fields"][0]["min"],
                      field["fields"][0]["max"],
                      field["fields"][0]["label"]
                    );
                  }}
                  value={otherUserDetail[field["fields"][0]["_id"]]}
                ></textarea>
              ) : field["fields"][0]["type"] === "file" ? (
                <input
                  type={field["fields"][0]["type"]}
                  name={field["fields"][0]["_id"]}
                  id={field["fields"][0]["_id"]}
                  placeholder={field["fields"][0]["placeholder"]}
                  accept="image/*"
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][0]["required"],
                      field["fields"][0]["min"],
                      field["fields"][0]["max"],
                      field["fields"][0]["label"]
                    );
                  }}
                />
              ) : (
                <input
                  type={field["fields"][0]["type"]}
                  name={field["fields"][0]["_id"]}
                  id={field["fields"][0]["_id"]}
                  placeholder={field["fields"][0]["placeholder"]}
                  value={otherUserDetail[field["fields"][0]["_id"]]}
                  // readOnly={true}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][0]["required"],
                      field["fields"][0]["min"],
                      field["fields"][0]["max"],
                      field["fields"][0]["label"]
                    );
                  }}
                />
              )}
            </div>
            <span className="error">
              {otherDetailErr[field["fields"][0]["_id"]]}
            </span>
          </div>}
          <div className="col-sm-4 form-group">
            <div className="um-right-in-label">
              {" "}
              {field["fields"][1]["label"]}
              {field["fields"][1]["required"].length > 0 ? <span> *</span> : ""}
              {field["fields"][1]["info"].length > 0 ? (
                <span className="um-fm-tl">
                  <i
                    className="fa fa-exclamation-circle"
                    title={field["fields"][1]["info"]}
                  ></i>
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="um-right-in-box">
              {field["fields"][1]["options"].length > 0 ? (
                displayOption(
                  otherUserDetail[field["fields"][1]["_id"]],
                  field["fields"][1]["options"],
                  field["fields"][1]["type"],
                  field["fields"][1]["label"],
                  field["fields"][1]["_id"],
                  field["fields"][1]["required"],
                  field["fields"][1]["min"],
                  field["fields"][1]["max"]
                )
              ) : field["fields"][1]["type"] === "textarea" ? (
                <textarea
                  name={field["fields"][1]["_id"]}
                  id={field["fields"][1]["_id"]}
                  placeholder={field["fields"][1]["placeholder"]}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][1]["required"],
                      field["fields"][1]["min"],
                      field["fields"][1]["max"],
                      field["fields"][1]["label"]
                    );
                  }}
                  value={otherUserDetail[field["fields"][1]["_id"]]}
                ></textarea>
              ) : field["fields"][1]["type"] === "file" ? (
                <input
                  type={field["fields"][1]["type"]}
                  name={field["fields"][1]["_id"]}
                  id={field["fields"][1]["_id"]}
                  placeholder={field["fields"][1]["placeholder"]}
                  accept="image/*"
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][1]["required"],
                      field["fields"][1]["min"],
                      field["fields"][1]["max"],
                      field["fields"][1]["label"]
                    );
                  }}
                />
              ) : (
                <input
                  type={field["fields"][1]["type"]}
                  name={field["fields"][1]["_id"]}
                  id={field["fields"][1]["_id"]}
                  placeholder={field["fields"][1]["placeholder"]}
                  value={otherUserDetail[field["fields"][1]["_id"]]}
                  // readOnly={
                  //   field["fields"][0]["_id"] === dbemailId ? true : false
                  // }
                  // readOnly={true}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][1]["required"],
                      field["fields"][1]["min"],
                      field["fields"][1]["max"],
                      field["fields"][1]["label"]
                    );
                  }}
                />
              )}
            </div>
            <span className="error">
              {otherDetailErr[field["fields"][1]["_id"]]}
            </span>
          </div>
          <div className="col-sm-4 form-group">
            <div className="um-right-in-label">
              {" "}
              {field["fields"][2]["label"]}
              {field["fields"][2]["required"].length > 0 ? <span> *</span> : ""}
              {field["fields"][2]["info"].length > 0 ? (
                <span className="um-fm-tl">
                  <i
                    className="fa fa-exclamation-circle"
                    title={field["fields"][2]["info"]}
                  ></i>
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="um-right-in-box">
              {field["fields"][2]["options"].length > 0 ? (
                displayOption(
                  otherUserDetail[field["fields"][2]["_id"]],
                  field["fields"][2]["options"],
                  field["fields"][2]["type"],
                  field["fields"][2]["label"],
                  field["fields"][2]["_id"],
                  field["fields"][2]["required"],
                  field["fields"][2]["min"],
                  field["fields"][2]["max"]
                )
              ) : field["fields"][2]["type"] === "textarea" ? (
                <textarea
                  name={field["fields"][2]["_id"]}
                  id={field["fields"][2]["_id"]}
                  placeholder={field["fields"][2]["placeholder"]}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][2]["required"],
                      field["fields"][2]["min"],
                      field["fields"][2]["max"],
                      field["fields"][2]["label"]
                    );
                  }}
                  value={otherUserDetail[field["fields"][2]["_id"]]}
                ></textarea>
              ) : field["fields"][2]["type"] === "file" ? (
                <input
                  type={field["fields"][2]["type"]}
                  name={field["fields"][2]["_id"]}
                  id={field["fields"][2]["_id"]}
                  placeholder={field["fields"][2]["placeholder"]}
                  accept="image/*"
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][2]["required"],
                      field["fields"][2]["min"],
                      field["fields"][2]["max"],
                      field["fields"][2]["label"]
                    );
                  }}
                />
              ) : (
                <input
                  type={field["fields"][2]["type"]}
                  name={field["fields"][2]["_id"]}
                  id={field["fields"][2]["_id"]}
                  placeholder={field["fields"][2]["placeholder"]}
                  value={otherUserDetail[field["fields"][2]["_id"]]}
                  // readOnly={
                  //   field["fields"][0]["_id"] === dbemailId ? true : false
                  // }
                  // readOnly={true}
                  onChange={(e) => {
                    handleChangeDBfields(
                      e,
                      field["fields"][2]["required"],
                      field["fields"][2]["min"],
                      field["fields"][2]["max"],
                      field["fields"][2]["label"]
                    );
                  }}
                />
              )}
            </div>
            <span className="error">
              {otherDetailErr[field["fields"][2]["_id"]]}
            </span>
          </div> 
        </div>
      );
  }
};

export default DisplayCustomRegistrationForm;
