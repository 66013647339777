import React from "react";
import "./Styles/ChatDetails.css";
import PropTypes from "prop-types";
import "../../../Styles/Theme/Theme.css";
import "../../../Styles/frontendGlobal.css";
import ChatDataCell from "../Components/HelperComponets/ChatDataCell";
import AvtarLarge from "../../../components/AvtarLarge/AvtarLarge";
import Typography from "../../../components/Typography/Typography";
import AddUserIcon from "../../../components/Icons/GeneralIcons/AddUserIcon";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/InputField/TextField";

const ChatDetails = ({
  type = "Channel",
  isAdmin = false,
  notificationTitle = "On: Push notifications, Badges",
  notificationText = "Notifications",
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  letter = "W",
  membersText = "22 members, 1 owner, 1 admin",
  membersTitle = "Members",
  mediaTitle = "Media and files",
  mediaText = "24 media, 5 files, 3 links",
  channelOrGroupNameOrName = "Channel Name",
  membersCount = "25",
  dateTime = "Dec 23, 2023",
  handleButtonClick,
  handleClick,
  hasStatus = false,
  isOnline,
  onClickNotification,
  onClickMemberDetails,
  onClickMediaDetails,
}) => {
  return (
    <>
      <div className="chat-details">
        {type === "Channel" && (
          <div className="chat-details-info">
            <div className="chat-details-info-container">
              <div className="chat-details-info-avatar">
                <AvtarLarge
                  size="48"
                  src={src}
                  type={src && src.length ? "photo" : "letter"}
                  letter={letter}
                  hasEdit={false}
                />
              </div>
              <div className="chat-details-info-name-date">
                <Typography variant="h4">{channelOrGroupNameOrName}</Typography>
                {membersCount ? (
                  <span className="chat-details-info-name-date-in">
                    {membersCount} members
                  </span>
                ) : (
                  <div className="cstm-skeleton-box skeleton-box"></div>
                )}
              </div>
            </div>
          </div>
        )}
        {type === "Group" && (
          <div className="chat-details-info">
            <div className="chat-details-info-container">
              <div className="chat-details-info-avatar">
                <AvtarLarge
                  size="48"
                  src={src}
                  type={src && src.length ? "photo" : "letter"}
                  letter={letter}
                  hasEdit={false}
                />
              </div>
              <div className="chat-details-info-name-date">
                <Typography variant="h4">{channelOrGroupNameOrName}</Typography>
                {membersCount ? (
                  <span className="chat-details-info-name-date-in">
                    {membersCount} members
                  </span>
                ) : (
                  <div className="cstm-skeleton-box skeleton-box"></div>
                )}
              </div>
            </div>
            {isAdmin && (
              <div className="chat-details-info-add-member">
                <Button
                  hasIconLeft={true}
                  label="Add Member"
                  size="small"
                  variant="secondary"
                  elementIcon={
                    <AddUserIcon height={24} iconColor="#000" width={24} />
                  }
                  handleClick={handleButtonClick}
                />
              </div>
            )}
          </div>
        )}
        {type === "Message" && (
          <div className="chat-details-info">
            <div className="chat-details-info-container">
              <div className="chat-details-info-avatar">
                <AvtarLarge
                  badgeStatus={isOnline}
                  size="48"
                  src={src}
                  type={src && src.length ? "photo" : "letter"}
                  letter={letter}
                  hasStatus={hasStatus}
                  badgeSize="large"
                  hasEdit={false}
                />
              </div>
              <div className="chat-details-info-name-date">
                <Typography variant="h4">{channelOrGroupNameOrName}</Typography>
                <span className="chat-details-info-name-date-in">
                  Member since: {dateTime}
                </span>
              </div>
            </div>
          </div>
        )}
        <hr />

        <div className="chat-details-container scrollbar">
          {(type === "Channel" || type === "Group") && (
            <>
              <ChatDataCell
                text={notificationTitle}
                title={notificationText}
                handleClick={onClickNotification}
              />
              <ChatDataCell
                text={membersText}
                title={membersTitle}
                handleClick={onClickMemberDetails}
              />
              <ChatDataCell
                text={mediaText}
                title={mediaTitle}
                handleClick={onClickMediaDetails}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

ChatDetails.propTypes = {
  type: PropTypes.oneOf(["Channel", "Group", "Message"]),
  isAdmin: PropTypes.bool,
  src: PropTypes.string,
  notificationTitle: PropTypes.string,
  notificationText: PropTypes.string,
  membersTitle: PropTypes.string,
  membersText: PropTypes.string,
  mediaTitle: PropTypes.string,
  mediaText: PropTypes.string,
  channelOrGroupNameOrName: PropTypes.string,
  membersCount: PropTypes.string,
  dateTime: PropTypes.string,
  hasStatus: PropTypes.bool,
  isOnline: PropTypes.string,
  handleClick: PropTypes.func,
  handleButtonClick: PropTypes.func,
  onClickMemberDetails: PropTypes.func,
  onClickNotification: PropTypes.func,
  onClickEditProfile: PropTypes.func,
  handleImageUpload: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleChangeInput: PropTypes.func,
  forEdit: PropTypes.bool,
  onClickMediaDetails: PropTypes.func,
};

export default ChatDetails;
