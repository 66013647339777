import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Services from "../../../Services/auth.service";
import auth from "../../../Services/auth0services";
import { fetchmemberprofile, fetchmyaccount } from "../../../Action";
//import images
import Dashboardicon from "../../../Images/dashboard-icon.svg";
import Image1 from "../../../Images/app_logo.png";
import Gruopicon from "../../../Images/gruop-icon.svg";
import Logouticon from "../../../Images/logouticon.svg";
import Settings from "../../../Images/settings.svg";
import Loader2 from "../../../Images/loader2.gif";
const LeftSidebar_profile = ({
  setselectedDiv,
  setSelectedGroup,
  profiletype,
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  let { memberid } = useParams();
  const profileDetail = useSelector((state) => {
    return state.ReduProfile;
  });
  const [commonGroup, setcommonGroup] = useState([]);
  const [joinedgroup, setjoinedgroup] = useState([]);
  const [arrow, setarrow] = useState("down");
  const [inprocess_join, setinprocess_join] = useState({});
  const [inprocess_leave, setinprocess_leave] = useState({});
  useEffect(() => {
    console.log(memberid,"memberid")
    if (memberid === localStorage.getItem("userid")) dispatch(fetchmyaccount());
    else dispatch(fetchmemberprofile(memberid));
  }, [memberid]);
  useEffect(() => {
    if (
      profileDetail !== undefined &&
      profileDetail._id !== undefined &&
      (profiletype === undefined || profiletype !== "myprofile")
    ) {
      Services.getjoinedgroupothermember(profileDetail._id)
        .then((res) => {
          if (res.data.status) {
            setjoinedgroup(res.data.data);
          } else {
            setjoinedgroup([]);
          }
        })
        .catch((e) => {
          console.log(e);
          setjoinedgroup([]);
        });
      Services.getcommongroup(profileDetail._id)
        .then((res) => {
          if (res.data.status) {
            setcommonGroup(res.data.data);
          } else {
            setcommonGroup([]);
          }
        })
        .catch((e) => {
          console.log(e);
          setcommonGroup([])
        });
    } else {
      Services.myjoinedgroup()
        .then((res) => {
          if (res.data.status) {
            setjoinedgroup(res.data.data);
          } else {
            setjoinedgroup([]);
          }
        })
        .catch((e) => {
          console.log(e);
          setjoinedgroup([]);
        });
    }
  }, [profileDetail]);
  const joingroup = (gid) => {
    setinprocess_join({ [gid]: true });
    Services.joingroup({ groupId: gid })
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var newgroup = {};
          var n = 0;
          for (var i = 0; i < joinedgroup.length; i++) {
            if (joinedgroup[i]._id !== gid) {
              arr[n] = joinedgroup[i];
              n++;
            } else {
              newgroup = joinedgroup[i];
            }
          }
          setjoinedgroup(arr);
          setcommonGroup([...commonGroup, newgroup]);
        } else {
          setgrouperr({ status: true, msg: res.data.message });
          setTimeout(() => {
            setgrouperr({ status: false, msg: "" });
          }, 3000);
        }
        setinprocess_join({ [gid]: false });
      })
      .catch((e) => {
        setinprocess_join({ [gid]: false });
        console.log(e);
      });
  };
  const leavegroup = (groupid) => {
    setinprocess_leave({ [groupid]: true });
    Services.leavegroup(groupid)
      .then((res) => {
        if (res.data.status) {
          console.log(joinedgroup);
          var arr = [];
          var n = 0;
          for (var i = 0; i < joinedgroup.length; i++) {
            // console.log(joinedgroup[i]._id, groupid);
            if (joinedgroup[i] !== null && joinedgroup[i]._id  !== null && joinedgroup[i]._id !== groupid) {
              arr[n] = joinedgroup[i];
              n++;
            }
          }
          console.log(arr);
          setjoinedgroup(arr);
        }
        setinprocess_leave({ [groupid]: false });
      })
      .catch((e) => {
        setinprocess_leave({ [groupid]: false });
        console.log(e);
      });
  };
  const handlelogout = () => {
    Services.setlastlogin();
    localStorage.setItem("accessToken", "");
    localStorage.setItem("idtoken", "");
    localStorage.setItem("userid", "");
    localStorage.setItem("username", "");
    history("/");
  };
  const ChangePassword = () => {
    auth.changePassword(
      {
        connection: "Username-Password-Authentication",
        email: profileDetail.email,
      },
      function (err, resp) {
        if (err) {
          alert(err.message);
        } else {
          alert(resp);
        }
      }
    );
  };
  return (
    <>
      {profileDetail !== undefined && Object.keys(profileDetail).length > 0 && profileDetail._id === memberid? (
        <div className="all-content-main-left-sidebar all-content-main-left-sidebar-user">
          <div className="accordion" id="accordionExample">
            {/* {
    profiletype !== undefined && profiletype === "myprofile" ? <> */}
            <div className="card">
              <div className="card-header" id="headingOne">
                <button
                  type="button"
                  className="btn-link active2-sd"
                  onClick={() => {
                    history("/dashboard");
                  }}
                >
                  <img src={Dashboardicon} /> Dashboard
                </button>
              </div>
            </div>
            {/* </> : <></>  } */}
          </div>
          {memberid === localStorage.getItem("userid") ? (
            <>
              <div className="user-pt cstm-user-pt">
                <div className="user-pt-main">
                  {" "}
                  <img
                    src={
                      profileDetail.profileImg !== undefined &&
                      profileDetail.profileImg.length > 0
                        ? profileDetail.profileImg
                        : Image1
                    }
                  />{" "}
                  {profileDetail.otherdetail !== undefined
                    ? profileDetail.otherdetail.filter((n) => {
                        if (
                          n._id !== undefined &&
                          n._id === process.env.REACT_APP_FIRSTNAME_DBID
                        )
                          return n;
                      })[0] !== undefined
                      ? profileDetail.otherdetail.filter((n) => {
                          if (
                            n._id !== undefined &&
                            n._id === process.env.REACT_APP_FIRSTNAME_DBID
                          )
                            return n;
                        })[0]["value"]
                      : "" +
                          " " +
                          profileDetail.otherdetail.filter((n) => {
                            if (
                              n._id !== undefined &&
                              n._id === process.env.REACT_APP_LASTNAME_DBID
                            )
                              return n;
                          })[0] !==
                        undefined
                      ? profileDetail.otherdetail.filter((n) => {
                          if (
                            n._id !== undefined &&
                            n._id === process.env.REACT_APP_LASTNAME_DBID
                          )
                            return n;
                        })[0]["value"]
                      : ""
                    : ""}{" "}
                   
                </div>
              </div>
              { window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ? joinedgroup !== undefined && joinedgroup.length > 0 ?
              <div className="card">
                <div className="card-header" id="headingOne">
                  <button
                    type="button"
                    className="btn-link "
                    data-toggle="collapse"
                    data-target="#joinedgrp"
                    onClick={() => {
                      arrow === "up" ? setarrow("down") : setarrow("up");
                    }}
                  >
                    Joined Group
                    {arrow === "down" ? (
                      <i className="fa fa-angle-down"></i>
                    ) : (
                      <i className="fa fa-angle-up"></i>
                    )}
                  </button>
                </div>
                <div
                  id="joinedgrp"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="cstm-starred-group">
                    <ul>
                      {joinedgroup !== undefined && joingroup.length > 0 ? (
                        joinedgroup.map((group) => {
                          if (group !== undefined && group !== null) {
                            return (
                              <>
                                <li>
                                  <div className="cstm-sg-list">
                                    <img
                                      className="sctm-pt-mn"
                                      src={
                                        group.groupImage !== undefined &&
                                        group.groupImage.length > 0
                                          ? process.env
                                              .REACT_APP_AWS_IMG_VID_PATH +
                                            group.groupImage
                                          : Image1
                                      }
                                    />
                                  </div>
                                  <div className="cstm-sg-ttl-name">
                                    <a className="cstm-sg-ttl-main">
                                      {group.groupTitle}
                                    </a>
                                  </div>
                                  <div className="cstm-sg-join">
                                    {inprocess_leave[group._id] !== undefined &&
                                    inprocess_leave[group._id] ? (
                                      <img
                                        src={Loader2}
                                        className="dis-loader3"
                                      />
                                    ) : (
                                      <a
                                        className="leave-group"
                                        onClick={() => {
                                          leavegroup(group._id);
                                        }}
                                      >
                                        Leave
                                      </a>
                                    )}
                                  </div>
                                </li>
                              </>
                            );
                          } else {
                            return <></>;
                          }
                        })
                      ) : (
                        <span className="emptymsg">No Data Found</span>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              : <></> : <></>}
              {/* <div className="cstm-asl-link cstm-asl-link-main">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        ChangePassword();
                      }}
                    >
                     Change Password <img src={Settings} />
                    </a>
                  </li>
                  <li className="sd-logout">
                    <a
                      onClick={() => {
                        handlelogout();
                      }}
                    >
                      Logout <img src={Logouticon} />
                    </a>
                  </li>
                </ul>
              </div> */}
            </>
          ) : (
            <>
              <div className="user-pt">
                <div className="user-pt-main">
                  {" "}
                  <img
                    src={
                      profileDetail.profileImg !== undefined &&
                      profileDetail.profileImg.length > 0
                        ? profileDetail.profileImg
                        : Image1
                    }
                  />
                  {profileDetail.otherdetail !== undefined
                    ? profileDetail.otherdetail.filter((n) => {
                        if (
                          n.id !== undefined &&
                          n.id === process.env.REACT_APP_FIRSTNAME_DBID
                        )
                          return n;
                      })[0]["value"] +
                      " " +
                      profileDetail.otherdetail.filter((n) => {
                        if (
                          n.id !== undefined &&
                          n.id === process.env.REACT_APP_LASTNAME_DBID
                        )
                          return n;
                      })[0]["value"]
                    : ""}
                </div>
              </div>
              {commonGroup !== undefined && commonGroup.length > 0 ? <>
              <div className="common-groups-you">Common Groups You Joined</div>
              <div className="cstm-cgyj-main">
                <ul>
                  {commonGroup !== undefined &&
                    commonGroup.map((group) => {
                      return (
                        <li>
                          <div className="cstm-sg-list">
                            <img
                              className="sctm-pt-mn"
                              src={
                                group.groupImage !== undefined &&
                                group.groupImage.length > 0
                                  ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    group.groupImage
                                  : Image1
                              }
                            />
                          </div>
                          <div className="cstm-sg-ttl-name">
                            <a
                              className="cstm-sg-ttl-main"
                              onClick={() => {
                                history(`/group/${group._id}`);
                              }}
                            >
                              {group.groupTitle}
                            </a>
                            <span className="cstm-sg-sb">
                              {"Member since " +
                                moment(group.updatedAt).format("D,MMMM YYYY")}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
              </> : <></>}
              { joinedgroup !== undefined && joinedgroup.length > 0 ? 
              <div className="cstm-cgyj-jg">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button
                      type="button"
                      className="btn-link"
                      data-toggle="collapse"
                      data-target="#joinedgrp"
                      onClick={() => {
                        arrow === "up" ? setarrow("down") : setarrow("up");
                      }}
                    >
                      <img src={Gruopicon} />
                      Joined Group{" "}
                      {arrow === "down" ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up"></i>
                      )}
                    </button>
                  </div>
                  <div
                    id="joinedgrp"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="cstm-joined-group">
                      <ul>
                        {joinedgroup !== undefined &&
                          joinedgroup.map((group) => {
                            if (group !== undefined) {
                              return (
                                <li>
                                  <div className="cstm-sg-list">
                                    <img
                                      className="sctm-pt-mn"
                                      src={
                                        group.groupImage !== undefined &&
                                        group.groupImage.length > 0
                                          ? process.env
                                              .REACT_APP_AWS_IMG_VID_PATH +
                                            group.groupImage
                                          : Image1
                                      }
                                    />
                                  </div>
                                  <div className="cstm-sg-ttl-name">
                                    <a
                                      className="cstm-sg-ttl-main"
                                      onClick={() => {
                                        history(`/group/${group._id}`);
                                      }}
                                    >
                                      {group.groupTitle}
                                    </a>
                                  </div>
                                  <div className="cstm-sg-join">
                                    {inprocess_join[group._id] !== undefined &&
                                    inprocess_join[group._id] ? (
                                      <span className="loading-btn-in2">
                                        <img src={Loader2} />
                                      </span>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          joingroup(group._id);
                                        }}
                                      >
                                        Join +
                                      </a>
                                    )}
                                  </div>
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              : <></>}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LeftSidebar_profile;

