import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  readMessageCountChange,
  deletemessage,
  editmessage,
  setreadmessage,
  recivemessage,
  setLastMessage,
  addmessagereaction,
  setLastMessageOfChat,
  removereaction,
} from "../../../Action";
import { groupChatData } from "../CommonFunctions/FormatMessages.js";
import authService from "../../../Services/auth.service.js";
import newsocket from "../../../components/SocketConnect.js";
import ChatContainer from "../Components/ChatContainer";
import ChatFloatingButton from "../Components/HelperComponets/ChatFloatingButton.js";
import ModalPopup from "../../../components/ModalPopup/ModalPopup.js";
import Typography from "../../../components/Typography/Typography.js";
import IconSuccesfull from "../../../components/GlobalComponents/Icons/IconEmptyState/IconSuccesfull.js";
import Button from "../../../components/Button/Button.js";
import useReactionHandlers from "../Hooks/useReactionHandlers.js";
import Chips from "../../../components/Chips/Chips.js";
import Avtar from "../../../components/Avtar/Avtar.js";
import ChatMediaProgress from "./ChatMediaProgress.js";
import MediaPreview from "./MediaPreview.js";

const ChatContainerContent = ({
  drag,
  setdrag,
  groupMemberList,
  handleReplyClick,
  seteditMessage,
  setMsg,
  setSelectedMentionName,
  quoteMessageSearchIdRef,
  findQuotedmessage,
  getImageVideoData,
  allChatHeaderDetail,
  attachmentData,
  previewDisplay,
  setPreviewDisplay,
  previewType,
  showSelectedMediaPreviewAttachment,
  setEditMessageState,
}) => {
  const {
    setModelPlace,
    modelPlace,
    initialEmojis,
    reactions,
    setReactions,
    showEmojis,
    setShowEmojis,
    reactionMsg,
    setReactionMsg,
    showDropdown,
    setShowDropdown,
    showAllEmogis,
    msgData,
    setMsgData,
    handleReactionClick,
    toggleShowEmojis,
    handleEmojiClick,
    handleSelectEmoji,
    selectedMessageId,
    deleteReaction,
    hoverEffects,
    setHoverEffects,
  } = useReactionHandlers();

  const { userid, type } = useParams();
  const userid_ref = useRef();
  const type_ref = useRef();
  const location = useLocation();
  const messagesRef = useRef({});
  const chatContainerRef = useRef(null);
  const unReadMessageRef = useRef(null);
  const menuSelectedDivRef = useRef(null);
  let dispatch = useDispatch();
  const [page, setpage] = useState(1);
  const [actionModel, setActionModel] = useState(false);
  const [reactionuserList, setReactionuserList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showReactionList, setShowReactionList] = useState(false);
  const [activeChip, setActiveChip] = useState("All");
  const [showPreview, setPreview] = useState(false);
  const [selectedReactId, setSelectedReactId] = useState();
  const [channelNotFound, setChannelNotFound] = useState(false);
  const [selectedImageVideo, setSelectedImageVideo] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [showFileData, setFileData] = useState("");
  const [isElementInDisplayArea, setIsElementInDisplayArea] = useState(false);
  const [scrolltopdata, setscrolltopdata] = useState("");
  const [noAccess, setNoAccess] = useState(false);

  const chatAllUsers = useSelector((state) => state.ReduAllUsersList);
  const chatConnectingMessage = useSelector((state) => {
    return state.ReduChatConnectingMessage;
  });
  const chatList = useSelector((state) => {
    return state.ReduAllChatList;
  });
  const allMessages = useSelector((state) => {
    return state.ReduChat;
  });

  const unreadMessageIndex = useSelector((state) => {
    return state.ReduUnreadMessageIndex;
  });
  const totalmessages = useSelector((state) => {
    return state.ReduTotalMessages;
  });

  const moreloader = useSelector((state) => {
    return state.ReduChatMoreLoader;
  });

  const progressMessage = useSelector((state) => {
    return state.MediaProgressDisplayMessage;
  });
  const hoverStay = useRef(null);
  const chatListRef = useRef(null);
  useEffect(() => {
    chatListRef.current = chatList;
  }, [chatList]);

  // Scrool top to bottom
  const [showScrollButton, setShowScrollButton] = useState(false);
  const formatedMessages = groupChatData(
    allMessages ? allMessages[userid] : []
  );

  // emit receive event handler
  const receiveEventHandler = useCallback((msg) => {
    let currentlyOnPage =
      window.location.pathname.includes("/chats/") &&
        (((userid_ref.current === msg.message[0].sender.id ||
          userid_ref.current === msg.message[0].recipient.id) &&
          msg.message[0].type !== "userChatGroup" &&
          msg.message[0].type !== "chatChannel") ||
          (userid_ref.current === msg.message[0].recipient.id &&
            (msg.message[0].type === "userChatGroup" ||
              msg.message[0].type === "chatChannel")))
        ? true
        : false;

    dispatch(recivemessage(msg.message, currentlyOnPage));
    if (
      msg.message[0] &&
      (msg.message[0].message_type?.includes("image") ||
        msg.message[0].message_type?.includes("video"))
    ) {
      dispatch({ type: "ADDMEDIA", payload: msg.message[0] });
    }
    if (msg.message[0] && msg.message[0].message_type?.includes("document")) {
      dispatch({ type: "ADDFILEINLIST", payload: msg.message[0] });
    }
    if (msg.message[0] && msg.message[0].message_type?.includes("url")) {
      dispatch({ type: "ADDURLINLIST", payload: msg.message[0] });
    }
    let readMessageSend = false;
    const scrollTop = chatContainerRef
      ? chatContainerRef?.current?.scrollTop
      : 0;
    // readMessageSend = true;
    if (scrollTop >= 0) {
      readMessageSend = true;
    } else {
      readMessageSend = false;
    }
    if (msg && msg.message && !readMessageSend) {
      let allUnreadMessages = msg.message.filter((msgData) => {
        if (
          msgData.group_member &&
          msgData.group_member.findIndex(
            (item) =>
              item.id === localStorage.getItem("userid") &&
              item.readmsg === false
          ) !== -1
        ) {
          return msgData;
        }
      });

      if (allUnreadMessages && allUnreadMessages.length) {
        // Dispatch the last unread message ID only if there are unread messages
        dispatch({
          type: "ASSIGNUNREADMESSAGE",
          payload: allUnreadMessages[allUnreadMessages.length - 1]._id,
        });
      }
    }

    if (
      window.location.pathname.includes("/chats/") &&
      ((userid_ref.current === msg.message[0].sender.id &&
        msg.message[0].type !== "userChatGroup" &&
        msg.message[0].type !== "chatChannel") ||
        (userid_ref.current === msg.message[0].recipient.id &&
          (msg.message[0].type === "userChatGroup" ||
            msg.message[0].type === "chatChannel")))
    ) {
      dispatch(setLastMessage({ message: msg.message[0], currentPage: true }));
    } else {
      dispatch(setLastMessage({ message: msg.message[0], currentPage: false }));
    }

    if (
      chatListRef.current &&
      chatListRef.current.filter((list) => {
        let uId =
          msg.message[0].recipient.id === localStorage.getItem("userid")
            ? msg.message[0].sender.id
            : msg.message[0].recipient.id;
        if (list.id === uId) return list;
      }).length === 0
    ) {
      newsocket.emit("joinRoom", {
        roomId:
          msg.message[0].type !== "chatChannel" &&
            msg.message[0].type !== "userChatGroup"
            ? msg.message[0].recipient.id === localStorage.getItem("userid")
              ? localStorage.getItem("userid") + "-" + msg.message[0].sender.id
              : localStorage.getItem("userid") +
              "-" +
              msg.message[0].recipient.id
            : msg.message[0].recipient.id,
      });
    }
    dispatch({
      type: "REMOVESENDMSGLOADER",
    });
    if (
      window.location.pathname.includes("/chats/") &&
      userid_ref.current === msg.message[0].sender.id &&
      msg.message[0].type !== "userChatGroup" &&
      msg.message[0].type !== "chatChannel"
    ) {
      newsocket.emit("readMessage", {
        read_flag: true,
        recipient: localStorage.getItem("userid"),
        sender: msg.message[0].sender.id,
        type: "user",
      });
      dispatch(
        readMessageCountChange(
          msg.message[0].recipient.id !== localStorage.getItem("userid")
            ? msg.message[0].sender.id
            : msg.message[0].recipient.id
        )
      );
    } else if (
      window.location.pathname.includes("/chats/") &&
      userid_ref.current === msg.message[0].recipient.id &&
      msg.message[0].type.toLowerCase() === "userchatgroup"
    ) {
      newsocket.emit("readMessage", {
        read_flag: true,
        recipient: msg.message[0].recipient.id,
        sender: localStorage.getItem("userid"),
        type: "userChatGroup",
      });
      dispatch(readMessageCountChange(msg.message[0].recipient.id));
    } else if (
      window.location.pathname.includes("/chats/") &&
      userid_ref.current === msg.message[0].recipient.id &&
      msg.message[0].type.toLowerCase() === "chatchannel"
    ) {
      newsocket.emit("readMessage", {
        read_flag: true,
        recipient: msg.message[0].recipient.id,
        sender: localStorage.getItem("userid"),
        type: "chatchannel",
      });
      dispatch(readMessageCountChange(msg.message[0].recipient.id));
    } else if (
      msg.message[0] &&
      msg.message[0].sender &&
      msg.message[0].sender.id === localStorage.getItem("useiId")
    ) {
      dispatch({ type: "RESETUNREADMESSAGEINDEX", payload: "" });
    }
    if (readMessageSend) {
      setTimeout(() => {
        dispatch({ type: "RESETUNREADMESSAGEINDEX", payload: "" });
      }, 3000);
    }
  });

  // all the socket event receiver
  useEffect(() => {
    newsocket.on("new-receive-latest", receiveEventHandler);
    newsocket.on("chat-detail-receive", (detailResponse) => {
      let unReadMessageLast = "";

      if (detailResponse.message.currentPage === 0) {
        if (detailResponse.message.status) {
          for (
            let index = 0;
            index < detailResponse.message.data.Messages.length;
            index++
          ) {
            if (
              detailResponse.message.data.Messages[index] &&
              detailResponse.message.data.Messages[index].group_member
            ) {
              let unreadmsg = detailResponse.message.data.Messages[
                index
              ].group_member.findIndex(
                (item) =>
                  item.id === localStorage.getItem("userid") &&
                  item.readmsg === false
              );
              if (unreadmsg !== -1) {
                unReadMessageLast =
                  detailResponse.message.data.Messages[index]._id;
              }
            }
          }

          dispatch({ type: "ASSIGNUNREADMESSAGE", payload: unReadMessageLast });

          dispatch({
            type: "FETCHALLMESSAGES",
            payload: {
              [detailResponse.message.chatid]:
                detailResponse.message.data.Messages,
            },
          });
          dispatch({
            type: "SETTOTALMESSAGES",
            payload: {
              [detailResponse.message.chatid]:
                detailResponse.message.data.totalMessages,
            },
          });
          if (userid_ref.current !== "me" && type_ref.current !== "me") {
            if (
              type_ref.current.toLowerCase() === "userchatgroup" ||
              type_ref.current.toLowerCase() === "chatchannel"
            ) {
              newsocket.emit("readMessage", {
                read_flag: true,
                recipient: userid_ref.current,
                sender: localStorage.getItem("userid"),
                type: type_ref.current,
              });
            } else {
              newsocket.emit("readMessage", {
                read_flag: true,
                recipient: localStorage.getItem("userid"),
                sender: userid_ref.current,
                type: type_ref.current,
              });
            }
            setShowScrollButton(false);
            setTimeout(() => {
              dispatch(readMessageCountChange(userid_ref.current));
              dispatch({ type: "RESETUNREADMESSAGEINDEX", payload: "" });
            }, 3000);
          }
        } else {
          dispatch({
            type: "FETCHALLMESSAGES",
            payload: { [detailResponse.message.chatid]: [] },
          });
        }
        dispatch({
          type: "REMOVECHATLOADER",
        });
      } else if (detailResponse.message.currentPage > 0) {
        if (detailResponse.message.status) {
          if (messagesRef.current[userid_ref]) {
            for (
              let index = 0;
              index < messagesRef.current[userid_ref].length;
              index++
            ) {
              if (
                messagesRef.current[userid_ref][index] &&
                messagesRef.current[userid_ref][index].group_member
              ) {
                let unreadmsg = messagesRef.current[userid_ref][
                  index
                ].group_member.findIndex(
                  (item) =>
                    item.id === localStorage.getItem("userid") &&
                    item.readmsg === false
                );
                if (unreadmsg !== -1) {
                  unReadMessageLast =
                    messagesRef.current[userid_ref][index]._id;
                }
              }
            }
          }

          dispatch({ type: "ASSIGNUNREADMESSAGE", payload: unReadMessageLast });
          dispatch({
            type: "MDSADDMESSAGE",
            payload: {
              id: detailResponse.message.chatid,
              data: detailResponse.message.data.Messages,
            },
          });
          dispatch({
            type: "SETTOTALMESSAGES",
            payload: {
              [detailResponse.message.chatid]:
                detailResponse.message.data.totalMessages,
            },
          });
        }
        dispatch({
          type: "REMOVECHATMORELOADER",
        });
      }
      if (
        quoteMessageSearchIdRef.current &&
        quoteMessageSearchIdRef.current.length
      ) {
        let containQuoteMessage = false;
        for (
          let index = 0;
          index < messagesRef.current[userid_ref.current].length;
          index++
        ) {
          if (
            messagesRef.current[userid_ref.current][index]._id ===
            quoteMessageSearchIdRef.current
          ) {
            containQuoteMessage = true;
          }
        }
        if (containQuoteMessage) {
          var ele = document.getElementById(quoteMessageSearchIdRef.current);
          if (ele !== null && ele !== undefined) ele.scrollIntoView();

          setTimeout(() => {
            quoteMessageSearchIdRef.current = "";
          }, 2000);
        } else {
          const chat = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
          chat.scrollTop = -chat.scrollHeight;
        }
      }
      dispatch({
        type: "ASSIGNCONNECTINGMESSAGE",
        payload: "",
      });
    });
    newsocket.on("edit-receive", (msg) => {
      newsocket.emit("getLastMessage", {
        roomId:
          msg?.message?.recipient?.id === localStorage.getItem("userid")
            ? msg.message.sender.id
            : msg.message.recipient.id,
        userId: localStorage.getItem("userid"),
      });
      dispatch(editmessage({ id: userid_ref.current, data: msg.message }));
      dispatch({
        type: "REMOVESENDMSGLOADER",
      });
    });
    newsocket.on("getLastMessageReceive", (response) => {
      dispatch(setLastMessageOfChat(response.message));
    });
    const newReadedMsg = (recipientid) => {
      dispatch(setreadmessage(recipientid));
    };
    newsocket.on("new-readed-msg", newReadedMsg);
    // onEvent
    newsocket.on("add-message-reaction-receive", (data) => {
      dispatch(addmessagereaction(data));
    });
    newsocket.on("remove-message-reaction-receive", (data) => {
      dispatch(removereaction(data));
    });
    // create channel receive event action
    newsocket.on("create-channel-receive", (response) => {
      newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
    });
    // edit channel receive event action
    newsocket.on("edit-channel-receive", (response) => {
      newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
      if (response.message._id === userid_ref.current) {
        newsocket.emit("channel-member-detail", {
          channelId: userid_ref.current,
        });
      }
    });
    // add channel member receive event action
    newsocket.on("add-channel-member-receive", (response) => {
      newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
      if (response.message._id === userid_ref.current) {
        newsocket.emit("channel-member-detail", {
          channelId: userid_ref.current,
        });
      }
    });

    // join channel member receive event
    newsocket.on("join-channel-member-receive", (response) => {
      newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
      if (userid_ref.current) {
        newsocket.emit("channel-member-detail", {
          channelId: userid_ref.current,
        });
      }
    });
  }, [newsocket]);

  useEffect(() => {
    // Check if "notAccess" is present in the URL
    const searchParams = new URLSearchParams(location.search);
    console.log('searchParams', searchParams)
    if (searchParams.has("notAccess")) {
      setNoAccess(true); // Set the state to true if "notAccess" is in the URL
    } else {
      setNoAccess(false); // Ensure it's false otherwise
    }
  }, [location.search]);

  //set scroll event 
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setChannelNotFound(queryParams.has('channelnotfound'));
    const handleDivScroll = (element) => {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      if (scrollTop >= 0) {
        setShowScrollButton(false);
      } else {
        setShowScrollButton(true);
      }
    };
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleDivScroll);
    }
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleDivScroll);
      }
    };
  }, []);

  // get chat details
  useEffect(() => {
    userid_ref.current = userid;
    type_ref.current = type;
    if (
      userid &&
      userid !== "me" &&
      (allMessages === undefined ||
        allMessages === null ||
        (allMessages &&
          (allMessages[userid] === null ||
            allMessages[userid] === undefined ||
            allMessages[userid].length === 0)))
    ) {
      dispatch({
        type: "ASSIGNCONNECTINGMESSAGE",
        payload: "Fetching latest messages...",
      });

      newsocket.emit("chat-detail", {
        chatid: userid,
        authUserId: localStorage.getItem("userid"),
        type: type,
        skip: 0,
      });
      dispatch({
        type: "SETCHATLOADER",
      });
      setpage(1);
      const element = document.getElementById(`${unreadMessageIndex}0`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }

      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [userid, type]);

  /// set all the message in container
  useEffect(() => {
    const { scrollTop } = chatContainerRef.current;
    let readMessageSend = true;
    if (scrollTop >= 0) {
      readMessageSend = true;
    } else {
      readMessageSend = false;
    }
    if (messagesRef.current[userid_ref.current] && !readMessageSend) {
      let allUnreadMessages = messagesRef.current[userid_ref.current].filter(
        (msgData) => {
          if (
            msgData.group_member &&
            msgData.group_member.findIndex(
              (item) =>
                item.id === localStorage.getItem("userid") &&
                item.readmsg === false
            ) !== -1
          )
            return msgData;
        }
      );
      if (allUnreadMessages && allUnreadMessages.length === 1) {
        dispatch({
          type: "ASSIGNUNREADMESSAGE",
          payload: allUnreadMessages[allUnreadMessages.length - 1]._id,
        });
      }
    }
    messagesRef.current = allMessages;
  }, [allMessages]);

  // handle scroll bottom event
  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;

    setShowScrollButton(false);
    if (userid !== "me" && type !== "me") {
      // if (userid === localStorage.getItem("userid") && type === "user") {
      // newsocket.emit("readMessage", {
      //   read_flag: true,
      //   recipient: userid,
      //   sender: localStorage.getItem("userid"),
      //   type: type,
      // });
      // }
      setTimeout(() => {
        dispatch({ type: "RESETUNREADMESSAGEINDEX", payload: "" });
      }, 2000);
      dispatch(readMessageCountChange(userid));
    }
  };
  let scrollTimeout;
  let isScrolling = false;

  // handle scroll event
  const handleScroll = (event) => {
    setActionModel(false);
    setShowEmojis(false);
    setShowDropdown(false);
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    if (scrollHeight - scrollTop === clientHeight || scrollTop === 0) {
      setShowScrollButton(false);

      if (userid !== "me" && type !== "me") {
        // newsocket.emit("readMessage", {
        //   read_flag: true,
        //   recipient: userid,
        //   sender: localStorage.getItem("userid"),
        //   type: type,
        // });
        setTimeout(() => {
          dispatch({ type: "RESETUNREADMESSAGEINDEX", payload: "" });
        }, 2000);
        dispatch(readMessageCountChange(userid));
      }
    } else {
      setShowScrollButton(true);
    }
    setscrolltopdata("scrolling");

    isScrolling = true;

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => {
      setscrolltopdata("");
      isScrolling = false;
    }, 4000);
  };

  // add scroll event in conainter
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    chatContainer.addEventListener("scroll", handleScroll);
    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // handle 3 dot click
  const handleDotsClick = (chat) => {
    setActionModel(chat);
  };

  // handle load more message pagination
  const handleLoadMoreMessages = () => {
    if (!moreloader) {
      var totallength = allMessages[userid].length;
      if (totallength < totalmessages[userid]) {
        dispatch({
          type: "ASSIGNCONNECTINGMESSAGE",
          payload: "Updating conversation...",
        });
        dispatch({
          type: "SETCHATMORELOADER",
        });
        newsocket.emit("chat-detail", {
          chatid: userid,
          authUserId: localStorage.getItem("userid"),
          type: type,
          skip: totallength,
        });
        setpage(page + 1);
      }
    }
  };

  let userChatId;
  for (var key in allChatHeaderDetail) {
    if (allChatHeaderDetail.hasOwnProperty(key)) {
      // Access the inner object using the current key
      var innerObject = allChatHeaderDetail[key];
      // Check if the inner object has the _id key
      if (innerObject.hasOwnProperty("_id")) {
        // Access the value associated with the _id key
        userChatId = innerObject._id;
      }
    }
  }

  // dropdown option select handler
  const onSelectOptions = (selectedOption, chatData) => {
    switch (selectedOption.name) {
      case "Copy text":
        handleCopyMessage(chatData);
        break;

      case "Delete message":
        handleDelete(chatData?._id);
        break;

      case "Edit message":
        handleEditMessage(chatData);
        break;

      case "Report":
        handleReportMessage(chatData);
        break;
      default:
        break;
    }
  };

  // handle copy message
  const handleCopyMessage = (chatData) => {
    navigator.clipboard.writeText(chatData.message);
  };

  // handle edit message
  const handleEditMessage = (chatData) => {
    const modifiedId = chatData._id.slice(0, -1);
    let originalMessageFullData = allMessages[userid]
      ? allMessages[userid].filter((msg) => msg._id === modifiedId)[0]
      : "";
    let originalMessage = originalMessageFullData
      ? originalMessageFullData.message
      : "";
    // Filter suggestionList based on taggedId
    const filteredSuggestions = originalMessageFullData.taggedUserId
      ? chatAllUsers.filter((list) => {
        return originalMessageFullData.taggedUserId
          .map((user) => {
            return user._id;
          })
          .includes(list.id);
      })
      : [];
    let updatedTextArray = [],
      updatedText = "";
    if (originalMessage.includes("@") && filteredSuggestions.length > 0) {
      originalMessage.split(" ").map((textItem) => {
        if (textItem.startsWith("@")) {
          let getUser = filteredSuggestions.filter((user) => {
            if (user.id === textItem.replace("@", "")) {
              return user;
            }
          });
          let changeIdwithname = getUser.length
            ? `@${getUser[0].id}`
            : textItem;
          updatedTextArray.push(changeIdwithname);
        } else {
          updatedTextArray.push(textItem);
        }
      });
      updatedText = updatedTextArray.join(" ");
    } else {
      updatedText = originalMessage;
    }
    seteditMessage({
      status: true,
      msg: updatedText,
      id: modifiedId,
      taggedId: originalMessageFullData.taggedUserId,
    });

    setEditMessageState(true);

    setMsg(updatedText);
    setSelectedMentionName(filteredSuggestions);
  };

  // handle delete message
  const handleDeleteMsg = () => {
    let uId = localStorage.getItem("userid");
    const modifiedId = deleteId.slice(0, -1);
    dispatch(deletemessage(modifiedId));
    dispatch({ type: "DELETEMEDIARECORD", payload: modifiedId });
    dispatch({ type: "DELETEURLRECORD", payload: modifiedId });
    dispatch({ type: "DELETEFILEFROMLIST", payload: modifiedId });
    newsocket.emit("deletechat", {
      chatId: userid,
      messageId: modifiedId,
      userId: uId,
      type: type,
    });
    closeDeleteModel();
  };

  // handle delete message modal popup open
  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteModel(true);
  };

  // handle delete message close popup
  const closeDeleteModel = () => {
    setDeleteModel(false);
  };

  // handle report message
  const handleReportMessage = async (chatData) => {
    let uId = localStorage.getItem("userid");
    const modifiedId = chatData._id.slice(0, -1);
    if (chatData) {
      const payload = {
        messageId: modifiedId,
        reportedBy: uId,
        chatId: userid,
        type: type,
      };
      try {
        const response = await authService.addMessageReport(payload);

        if (response.data.status) {
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    }
  };

  // handle modal close
  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Handle reaction user list click
  const handleReactionUsersList = (userList) => {
    setSelectedReactId(userList);
    setReactionuserList(userList?.messageReactions);
    setShowReactionList(true);
    const dataToStore =
      type === "chatChannel"
        ? groupMemberList.membersList
        : groupMemberList.members;
  };

  // close reaction user list
  const closeReactionList = () => {
    setShowReactionList(false);
    setActiveChip("All");
  };

  // Function to handle chip click
  const handleChipClick = (chipId) => {
    setActiveChip(chipId);
  };

  //onClick preview handle
  const handlePreviewClick = (id, index, messageId, type) => {
    if (type === "file") {
      setFileData(id);
      setFilePreview("file");
    }

    const originalData = getImageVideoData.filter((data) => {
      return data._id === messageId;
    });
    if (type === "images" || type === "videos") {
      const mainUrl =
        type === "images"
          ? originalData[0]?.images[index].url
          : originalData[0]?.videos[index].url;
      setFilePreview("media");
      setSelectedImageVideo(mainUrl);
    }
    setPreview(true);
  };

  // onClick Close media preview popup
  const handleClosePreview = () => {
    setPreview(false);
    setPreviewDisplay(false);
  };

  return (
    <>
      <div
        className="relative chat-floating-container"
        onDragEnter={(event) => {
          event.preventDefault();
          if (event.dataTransfer.types[0] === "Files") {
            setdrag(true);
          }
        }}
      >
        <div className="connecting-message-sec">
          {progressMessage.isUploading && <ChatMediaProgress />}
          {chatConnectingMessage.length > 0 && !progressMessage.isUploading ? (
            <div className="connecting-message">{chatConnectingMessage}</div>
          ) : (
            <></>
          )}
        </div>
        <ChatContainer
          menuSelectedDivRef={menuSelectedDivRef}
          hoverStay={hoverStay}
          modelPlace={modelPlace}
          setModelPlace={setModelPlace}
          findQuotedmessage={findQuotedmessage}
          quoteMessageSearchIdRef={quoteMessageSearchIdRef}
          isUnreadMessageInDisplayArea={isElementInDisplayArea}
          handleLoadMoreMessages={handleLoadMoreMessages}
          showScrollButton={showScrollButton}
          unReadMessageRef={unReadMessageRef}
          chatContainerRef={chatContainerRef}
          handleScroll={handleScroll}
          handleEmojiClick={handleEmojiClick}
          msgData={msgData}
          handleReactionClick={handleReactionClick}
          hoverEffects={hoverEffects}
          setHoverEffects={setHoverEffects}
          showAllEmogis={showAllEmogis}
          toggleShowEmojis={toggleShowEmojis}
          initialEmojis={initialEmojis}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          handleSelectEmoji={handleSelectEmoji}
          selectedMessageId={selectedMessageId}
          handleReactionUsersList={handleReactionUsersList}
          reactions={reactions}
          setReactions={setReactions}
          showEmojis={showEmojis}
          setShowEmojis={setShowEmojis}
          reactionMsg={reactionMsg}
          setReactionMsg={setReactionMsg}
          handleDelete={handleDelete}
          handleReplyClick={handleReplyClick}
          unreadMessageIndex={unreadMessageIndex}
          handleDotsClick={handleDotsClick}
          actionModel={actionModel}
          setActionModel={setActionModel}
          scrolltopdata={scrolltopdata}
          chatType={
            type === "chatChannel" || type === "userChatGroup"
              ? "Channel/Group"
              : "Personal"
          }
          type={
            noAccess ? "nofoundaccess" :
              channelNotFound ? "channelnotfound" :
                userid === "me" || type === "me"
                  ? "NotSelected"
                  : allMessages[userid] &&
                    allMessages[userid].length > 0 &&
                    allMessages[userid].filter(
                      (message) => !message.activity_status
                    ).length
                    ? "Slots"
                    : allMessages[userid] &&
                      allMessages[userid].length > 0 &&
                      allMessages[userid].filter((message) => message.activity_status)
                        .length
                      ? "NoMessages"
                      : ""
          }
          chatData={
            userid &&
              type &&
              userid !== "me" &&
              type !== "me" &&
              allMessages[userid] &&
              allMessages[userid].length > 0 &&
              allMessages[userid].filter((message) => !message.activity_status)
                .length === 0
              ? formatedMessages
              : allMessages[userid] && allMessages[userid].length > 0
                ? formatedMessages
                : []
          }
          onSelectOptions={onSelectOptions}
          handlePreviewClick={handlePreviewClick}
        />
        {/* Scrolling Animation */}
        <div
          className={`absolute chat-floating-area ${showScrollButton && type !== "me" && userid !== "me" ? "active" : ""
            }`}
        >
          <ChatFloatingButton
            count={
              allMessages[userid_ref.current]
                ? allMessages[userid_ref.current].reduce(
                  (totalUnread, msgData) => {
                    if (msgData._id === unreadMessageIndex) {
                      // Find the index of unreadMessageIndex
                      const index =
                        allMessages[userid_ref.current].indexOf(msgData);
                      if (index !== -1) {
                        // Add the count of messages before and including the unread message
                        return totalUnread + index + 1;
                      }
                    }
                    return totalUnread;
                  },
                  0
                )
                : 0
            }
            handleClick={scrollToBottom}
          />
        </div>
        <ModalPopup
          handleClick2ndButton={handleClose}
          isFooter={false}
          isHeader={false}
          modelSize="648"
          isOpen={isModalOpen}
        >
          <div className="report-model-body flex flex-col items-center justify-center">
            <IconSuccesfull />
            <div className="report-model-content flex flex-col items-center justify-center">
              <Typography align="center" variant="h3">
                Thank you for your report!
              </Typography>
              <Typography align="center">
                We're here to keep our community safe. If this content goes
                against our <a>Community Guidelines</a>, we'll remove it.
              </Typography>
            </div>
            <Button
              label="Got it, thanks"
              onContextMenu={() => { }}
              onMouseEnter={() => { }}
              onMouseLeave={() => { }}
              size="large"
              variant="secondary"
              handleClick={handleClose}
            />
          </div>
        </ModalPopup>
        <ModalPopup
          isFooter={true}
          isHeader={false}
          modelSize="648"
          isOpen={deleteModel}
          buttonText="Cancel"
          buttonText1="Delete"
          onClose={closeDeleteModel}
          handleClick2ndButton={handleDeleteMsg}
          btn2variant="danger"
        >
          <div className="channel-group-wrapper">
            <Typography variant="h3">Delete Message</Typography>
            <Typography variant="p">
              Are you sure you want to delete this message?
            </Typography>
          </div>
        </ModalPopup>

        <ModalPopup
          isFooter={false}
          isHeader={true}
          headingLable="Reactions"
          modelSize="480"
          isOpen={showReactionList}
          onClose={closeReactionList}
        >
          <div className="reactions-chips">
            <Chips
              key="All"
              className={`medium ${activeChip === "All" ? "active" : ""}`}
              handleClick={() => handleChipClick("All")} // Pass chipId to handleChipClick function
              label="All"
            />
            {/*Filter duplicate emojis and render unique ones  */}
            {selectedReactId && (
              <>
                {allMessages &&
                  allMessages[userid] &&
                  allMessages[userid].filter(
                    (message) => message._id === selectedReactId?._id.slice(0, -1)
                  ).length ? (
                  allMessages[userid]
                    .filter(
                      (message) =>
                        message._id === selectedReactId?._id.slice(0, -1)
                    )[0]
                    ?.messageReactions?.map((msgReact) => {
                      return (
                        <Chips
                          key={`${msgReact.emojiId}`}
                          className={`medium ${activeChip === msgReact.emojiId ? "active" : ""
                            }`}
                          handleClick={() => handleChipClick(msgReact.emojiId)}
                          label={`${msgReact.emojiId} ${msgReact?.userIds ? msgReact.userIds.length : 0
                            }`}
                        />
                      );
                    })
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          <div className="reactions-list scrollbar">
            {selectedReactId &&
              allMessages &&
              allMessages[userid] &&
              allMessages[userid].filter(
                (message) => message._id === selectedReactId?._id.slice(0, -1)
              ).length ? (
              allMessages[userid]
                .filter(
                  (message) => message._id === selectedReactId?._id.slice(0, -1)
                )[0]
                .messageReactions.map((msgReact) => {
                  if (
                    msgReact?.userIds?.length &&
                    (activeChip === "All" || activeChip === msgReact.emojiId)
                  ) {
                    return msgReact.userIds.map((users) => {
                      let userData = [];
                      if (type === "chatChannel") {
                        userData = groupMemberList?.membersList?.filter(
                          (value) => {
                            return value?._id === users;
                          }
                        );
                      } else if (type === "userChatGroup") {
                        userData = groupMemberList?.members?.filter((value) => {
                          return value?.userId === users;
                        });
                      }
                      let profileImgs =
                        type !== "userChatGroup" && type !== "chatChannel"
                          ? users === localStorage.getItem("userid")
                            ? localStorage.getItem("userprofile")
                            : allChatHeaderDetail[users]?.profileImg
                          : type === "userChatGroup"
                            ? userData?.length && userData[0]?.user
                              ? userData[0]?.user?.profileImg
                              : ""
                            : userData?.length
                              ? userData[0]?.profileImg
                              : "";
                      let fullName =
                        type !== "userChatGroup" && type !== "chatChannel"
                          ? users === localStorage.getItem("userid")
                            ? localStorage.getItem("username")
                            : `${allChatHeaderDetail &&
                              allChatHeaderDetail[users] &&
                              (allChatHeaderDetail[users]?.first_name ||
                                allChatHeaderDetail[users]?.last_name)
                              ? `${allChatHeaderDetail[users]?.first_name}
                                    ${allChatHeaderDetail[users]?.last_name}`
                              : ""
                            } 
                              `
                          : type === "userChatGroup"
                            ? userData?.length && userData[0]?.user
                              ? (userData[0]?.user?.first_name ||
                                userData[0]?.user?.last_name) &&
                              `${userData[0]?.user?.first_name}
                                    ${userData[0]?.user?.last_name}`
                              : ""
                            : userData?.length
                              ? (userData[0]?.first_name ||
                                userData[0]?.last_name) &&
                              `${userData[0]?.first_name}
                                    ${userData[0]?.last_name}`
                              : "";
                      return (
                        <div
                          className="reactions-list-box"
                          key={msgReact?.emojiId}
                          onClick={() => {
                            if (users === localStorage.getItem("userid")) {
                              deleteReaction(msgReact, selectedReactId);
                            }
                          }}
                        >
                          <div className="reactions-list-box-avtar">
                            <Avtar
                              size="48"
                              src={profileImgs}
                              letter={fullName}
                              type={profileImgs?.length ? "photo" : "letter"}
                              hasStatus={false}
                            />
                          </div>
                          <div className="reactions-list-box-container">
                            <div className="reactions-list-box-container-title">
                              <Typography variant="h5">{fullName}</Typography>
                            </div>
                            {users === localStorage.getItem("userid") && (
                              <div className="reactions-list-box-container-sub">
                                <Typography variant="default">
                                  Tap to remove
                                </Typography>
                              </div>
                            )}
                          </div>
                          <div className="reactions-list-box-emoji">
                            {msgReact?.emojiId}
                          </div>
                        </div>
                      );
                    });
                  } else {
                    return <></>;
                  }
                })
            ) : (
              <></>
            )}
          </div>
        </ModalPopup>

        <MediaPreview
          mediaPreviewData={attachmentData}
          showPreview={showPreview || previewDisplay}
          handleClosePreview={handleClosePreview}
          mediaPreviewType={
            filePreview === "media" || previewType === "media"
              ? "media"
              : filePreview === "file" || previewType === "file"
                ? "file"
                : ""
          }
          selectedImageVideo={
            !previewDisplay
              ? selectedImageVideo
              : showSelectedMediaPreviewAttachment
          }
          showFileData={showFileData}
          previewAttachmentDisplay={previewDisplay}
        />
      </div>
    </>
  );
};
export default ChatContainerContent;
