import React, { useEffect, useRef, useState } from "react";
import Typography from "../../components/Typography/Typography";
import Chips from "../../components/Chips/Chips";
import DropDown from "../../components/DropDown/DropDown";
import SearchField from "../../components/InputField/SearchField";
import authServiceDocument from "../../Services/auth.service.document";
import DropDownItem from "../../components/DropDownItem/DropDownItem";
import FilterSidebar from "./Components/FilterSidebar";
import DocAltIcon from "../../components/Icons/FileIcons/DocAltIcon";
import ExcelFileIcon from "../../components/Icons/FileIcons/ExcelFileIcon";
import VideoFileIcon from "../../components/Icons/FileIcons/VideoFileIcon";
import PptIcon from "../../components/Icons/FileIcons/PptIcon";
import ImageIcon from "../../components/Icons/FileIcons/ImageIcon";
import PdfIcon from "../../components/Icons/FileIcons/PdfIcon";
import AudioFileIcon from "../../components/Icons/FileIcons/AudioFileIcon";
import { useLocation, useNavigate } from "react-router-dom";
import DocsListView from "./Components/DocsListView";
import SavedDocsListView from "./Components/SavedDocsListView";
import FilterIcon from "../../components/FilterIcon/FilterIcon";
import ToastNotification from "../../components/ToastNotification/ToastNotification";
import {
  cancelDownload,
  handleDownloadDoc,
} from "./commonFunctions/downloadFunction";
import ReloadIcon from "../../components/Icons/GeneralIcons/ReloadIcon";
import SuggestionDropDown from "../../components/DropDownItem/SuggestionDropDown";
import Button from "../../components/Button/Button";
import NotDefinedFileIcon from "../../components/Icons/FileIcons/NotDefinedFileIcon";
import DocIcon from "../../components/Icons/FileIcons/DocIcon";
import DocFileModal from "./Components/DocFileModal";
import "../../Styles/docManagement.css";
import NoDownload from "./Components/NoDownload";
import moment from "moment";
import FilterIconAlt from "../../components/Icons/GeneralIcons/FilterIconAlt";
const DocumentMainPage = () => {
  // date  list array
  const dateList = [
    { id: "", label: "Anytime" },
    { id: "todays", label: "Today" },
    { id: "past7days", label: "Last 7 days" },
    { id: "past30days", label: "Last 30 days" },
    { id: `thisYear`, label: `This year (${moment().format("YYYY")})` },
    {
      id: `lastYear`,
      label: `Last  year (${moment().subtract(1, "year").format("YYYY")})`,
    },
    { id: "custom", label: "Custom date range" },
  ];

  // sort by list array
  const sortByList = [
    { id: "newest", label: "Newest first" },
    { id: "oldest", label: "Oldest first" },
    { id: "filea-z", label: "File name A-Z" },
    { id: "filez-a", label: "File name Z-A" },
    { id: "peoplea-z", label: "People A-Z" },
    { id: "peoplez-a", label: "People Z-A" },
    { id: "commentsCount", label: "Comments count" },
    { id: "lastModified", label: "Date modified" },
  ];

  // file format list array
  const fileFormat = [
    { id: "", label: "Any file format" },
    { id: "documents", label: "Documents", icon: <DocAltIcon /> },
    { id: "tables", label: "Tables", icon: <ExcelFileIcon /> },
    { id: "presentations", label: "Presentations", icon: <PptIcon /> },
    { id: "photos", label: "Photos & images", icon: <ImageIcon /> },
    { id: "pdfs", label: "PDFs", icon: <PdfIcon /> },
    { id: "videos", label: "Videos", icon: <VideoFileIcon /> },
    { id: "audio", label: "Audio", icon: <AudioFileIcon /> },
  ];
  // table columns array
  const columns = [
    { id: "name", label: "Name", width: 54 },
    { id: "people", label: "People", width: 12 },
    { id: "dateUploaded", label: "Uploaded date", width: 14 },
    { id: "category", label: "Category", width: 16 },
    { id: "action", label: "", width: 6 },
  ];

  const navigate = useNavigate();
  const location = useLocation().search;
  const searchFromUrl = new URLSearchParams(location).get("search");
  const sortByFromUrl = new URLSearchParams(location).get("sortby");
  const sortTypeFromUrl = new URLSearchParams(location).get("sorttype");
  const contentTypeFromUrl = new URLSearchParams(location).get("contenttype");
  const categoryFromUrl = new URLSearchParams(location).get("category");
  const filetypeFromUrl = new URLSearchParams(location).get("filetype");
  const uploadedFromUrl = new URLSearchParams(location).get("dateuploaded");
  const uploadedToDateFromUrl = new URLSearchParams(location).get("uploadedTo");
  const uploadedFromDateFromUrl = new URLSearchParams(location).get(
    "uploadedFrom"
  );
  const modifiedToDateFromUrl = new URLSearchParams(location).get("modifiedTo");
  const modifiedFromDateFromUrl = new URLSearchParams(location).get(
    "modifiedFrom"
  );

  const updatedFromUrl = new URLSearchParams(location).get("datemodified");
  const peopleFromUrl = new URLSearchParams(location).get("people");
  const subCategoryFromUrl = new URLSearchParams(location).get("subcategory");
  const typeFromUrl = new URLSearchParams(location).get("type");
  const docFromUrl = new URLSearchParams(location).get("doc");
  const [isOpen, setIsOpen] = useState(false);
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);
  const [isList, setList] = useState(false);
  const [moreLoader, setMoreLoader] = useState(false);
  const [savedLoader, setSavedLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All categories");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isFilter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [savedcurrentPage, setSavedCurrentPage] = useState(1);
  const [totalSavedRecord, setTotalSavedRecord] = useState(0);
  const [savedTotalPage, setSavedTotalPage] = useState(0);
  const [allDocList, setAllDocList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState("documents");
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedSortBy, setSelectedSortBy] = useState({});
  const [selectedContent, setSelectedContent] = useState("");
  const [selectedCreated, setSelectedCreated] = useState({});
  const [selectedUpdated, setSelectedUpdated] = useState({});
  const [userList, setUserList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [resultCount, setResultCount] = useState(0);
  const [contentTypeList, setContentTypeList] = useState([]);
  const [savedList, setSavedList] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState("Select subcategory");
  const [uploadedFrom, setUploadedFrom] = useState(new Date());
  const [uploadedTo, setUploadedTo] = useState(new Date());
  const [modifiedFrom, setModifiedFrom] = useState(new Date());
  const [modifiedTo, setModifiedTo] = useState(new Date());
  const [selectedPeople, setSelectedPeople] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [savedCount, setSavedCount] = useState(0);
  const [suggestionList, setSuggestionList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [fileName, setFileName] = useState("");
  const categoryRef = useRef(null);
  const subCategoryRef = useRef(null);
  const filterRef = useRef(null);
  const dropdownRef = React.createRef();
  const [isModal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortedOrder, setSortedOrder] = useState("desc");
  const [historySuggestion, setHistorySuggestion] = useState([]);
  const [finalArr, setFinalArr] = useState([]);
  const [openRestric, setRestrict] = useState(false);

  // DocsListView > tableHead state for sorting
  const [sortOrder, setSortOrder] = useState("desc");

  // chips Label array
  const chipsLabel = [
    {
      id: "documents",
      label: `Docs ${totalRecords > 0 ? `(${totalRecords})` : ""}`,
      callBackFunction: () => {
        navigate(
          `/documents?type=documents${
            searchFromUrl !== null && searchFromUrl !== ""
              ? `&&search=${searchFromUrl}`
              : ""
          }${
            selectedCategoryId !== "" ? `&&category=${selectedCategoryId}` : ""
          }${sortBy !== "" ? `&&sortby=${sortBy}` : ""}${
            sortType !== "" ? `&&sorttype=${sortType}` : ""
          }${selectedContent !== "" ? `&&contenttype=${selectedContent}` : ""}${
            selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
          }${
            selectedCreated.id !== undefined
              ? `&&dateuploaded=${selectedCreated.id}`
              : ""
          }${
            selectedUpdated.id !== undefined
              ? `&&datemodified=${selectedUpdated.id}`
              : ""
          }${
            selectedPeople._id !== undefined
              ? `&&people=${selectedPeople._id}`
              : ""
          }`
        );
      },
    },
    {
      id: "saved",
      label: `Saved ${savedCount > 0 ? `(${savedCount})` : ""}`,
      callBackFunction: () => {
        navigate(
          `/documents?type=saved${
            searchFromUrl !== null && searchFromUrl !== ""
              ? `&&search=${searchFromUrl}`
              : ""
          }${
            selectedCategoryId !== "" ? `&&category=${selectedCategoryId}` : ""
          }${sortBy !== "" ? `&&sortby=${sortBy}` : ""}${
            sortType !== "" ? `&&sorttype=${sortType}` : ""
          }${selectedContent !== "" ? `&&contenttype=${selectedContent}` : ""}${
            selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
          }${
            selectedCreated.id !== undefined
              ? `&&dateuploaded=${selectedCreated.id}`
              : ""
          }${
            selectedUpdated.id !== undefined
              ? `&&datemodified=${selectedUpdated.id}`
              : ""
          }${
            selectedPeople._id !== undefined
              ? `&&people=${selectedPeople._id}`
              : ""
          }`
        );
      },
    },
  ];

  // category and content type list api function
  async function categoryListApi() {
    try {
      const [categoriesRes, contentTypeRes, userListRes] = await Promise.all([
        authServiceDocument.userCategoryList(),
        authServiceDocument.userContentTypeList(),
        authServiceDocument.userList(),
      ]);
      if (categoriesRes.data.data.status) {
        let arr = [
          {
            _id: "",
            name: "All categories",
          },
        ];
        setCategoryList([...arr, ...categoriesRes.data.data.data]);
      }
      if (contentTypeRes.data.data.status) {
        setContentTypeList(contentTypeRes.data.data.data);
      }
      if (userListRes.data.data.status) {
        setUserList(userListRes.data.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  // get documnet list api
  async function getDocListApi(
    listtype,
    currentPage,
    searchText,
    category,
    sortBy,
    sortType,
    selectedContent,
    datefilteruploaded,
    datefiltermodified,
    selectedFile,
    searchpeople,
    subCategory,
    reqFromDate,
    reqToDate,
    modifiedreqFromDate,
    modifiedreqToDate
  ) {
    let query = "?";
    currentPage !== undefined && currentPage !== null
      ? (query += `page=${currentPage}`)
      : "";
    query += "&limit=10";
    listtype !== undefined && listtype !== null
      ? (query += `&listType=${listtype}`)
      : "";
    searchText !== undefined && searchText !== null && searchText !== ""
      ? (query += `&search=${searchText}`)
      : "";
    category !== undefined &&
    category !== null &&
    category !== "" &&
    category !== "All categories"
      ? (query += `&categories=${category}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy !== ""
      ? (query += `&sortBy=${
          sortBy === "dateUploaded" ? "dateUploaded" : sortBy
        }`)
      : "";
    sortType !== undefined && sortType !== null && sortType !== ""
      ? (query += `&sortType=${sortType}`)
      : "";
    selectedContent !== undefined &&
    selectedContent !== null &&
    selectedContent !== ""
      ? (query += `&contentTypeFilter=${selectedContent}`)
      : "";
    datefilteruploaded !== undefined &&
    datefilteruploaded !== null &&
    datefilteruploaded !== ""
      ? (query += `&datefilteruploaded=${datefilteruploaded}`)
      : "";
    datefiltermodified !== undefined &&
    datefiltermodified !== null &&
    datefiltermodified !== ""
      ? (query += `&datefiltermodified=${datefiltermodified}`)
      : "";
    selectedFile !== undefined && selectedFile !== null && selectedFile !== ""
      ? (query += `&fileFormateFilter=${selectedFile}`)
      : "";
    searchpeople !== undefined && searchpeople !== null && searchpeople !== ""
      ? (query += `&searchpeople=${searchpeople}`)
      : "";
    subCategory !== undefined && subCategory !== null && subCategory !== ""
      ? (query += `&subcategories=${subCategory}`)
      : "";
    datefilteruploaded === "custom" &&
    reqFromDate !== undefined &&
    reqToDate !== undefined
      ? (query += `&reqFromDate=${reqFromDate}&reqToDate=${reqToDate}`)
      : "";
    datefiltermodified === "custom" &&
    modifiedreqFromDate !== undefined &&
    modifiedreqToDate !== undefined
      ? (query += `&modifiedreqFromDate=${modifiedreqFromDate}&modifiedreqToDate=${modifiedreqToDate}`)
      : "";
    try {
      const docListRes = await authServiceDocument.userDocList(query);
      if (docListRes.data.data.status) {
        setSavedCount(docListRes.data.data.data.totalSavedCount);
        if (docListRes.data.data.data.listType === "documents") {
          setLoader(false);
          setMoreLoader(false);
          setTotalRecords(docListRes.data.data.data.totalMessages);
          setTotalPage(docListRes.data.data.data.totalPages);
          setCurrentPage(docListRes.data.data.data.currentPage);
          const sortedData = docListRes.data.data.data.docList;
          // .slice()
          // .sort((a, b) => b.viewsCount - a.viewsCount);
          if (docListRes.data.data.data.currentPage === 1) {
            if (sortBy === "dateUploaded") {
              sortByDate(sortedData, sortType);
            } else {
              setAllDocList(sortedData);
            }
          } else {
            if (sortBy === "dateUploaded") {
              sortByDate([...allDocList, ...sortedData], sortType);
            } else {
              setAllDocList([...allDocList, ...sortedData]);
            }
          }
        } else if (docListRes.data.data.data.listType === "saved") {
          setSavedCurrentPage(docListRes.data.data.data.currentPage);
          setSavedTotalPage(docListRes.data.data.data.totalPages);
          setSavedCount(docListRes.data.data.data.totalMessages);
          setTotalRecords(docListRes.data.data.data.totalDocumentCount);
          setTotalSavedRecord(docListRes.data.data.data.totalMessages);
          setSavedLoader(false);
          setMoreLoader(false);
          const sortedData = docListRes.data.data.data.docList;
          if (docListRes.data.data.data.currentPage === 1) {
            setSavedList(sortedData);
          } else {
            setSavedList([...savedList, ...sortedData]);
          }
        }
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  }

  async function getDocumentById(docFromUrl) {
    try {
      const docById = await authServiceDocument.getDocById(docFromUrl);
      if (
        docById.data.data.data &&
        docById.data.data.data.userDownloadBlockStatus === true
      ) {
        setRestrict(
          docById.data.data.data &&
            docById.data.data.data.userDownloadBlockStatus
        );
        document.body.classList.add("modal-open");
      } else {
        authServiceDocument.updateDocumentViewsCount(docFromUrl);
        document.body.classList.add("modal-open");
        setSelectedRow(docById.data.data.data);
        setModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // suggestionList api
  async function suggestionListApi() {
    let query =
      typeFromUrl !== null
        ? `?listType=${typeFromUrl}`
        : `?listType=${selectedTab}`;
    try {
      const [suggestionRes, historyRes] = await Promise.all([
        authServiceDocument.userSuggestionList(query),
        authServiceDocument.getSearchHistory(query),
      ]);
      if (suggestionRes.data.data.status) {
        let data = suggestionRes.data.data.list;
        var arr = [];
        if (
          data &&
          data.length !== undefined &&
          data.length !== null &&
          data.length > 0
        ) {
          data.map((item) =>
            arr.push({
              fileUrl: item.fileUrl,
              documentType: item.documentType,
              _id: item._id,
              name: item.fileName,
              owner: item.owner === "admin" ? "MDS" : item.owner,
              description: item.shortDescription,
              icon:
                item.documentType !== undefined &&
                item.documentType === "pdfs" ? (
                  <PdfIcon />
                ) : item.documentType === "documents" ? (
                  <DocIcon />
                ) : item.documentType === "tables" ? (
                  <ExcelFileIcon />
                ) : item.documentType === "presentations" ? (
                  <PptIcon />
                ) : item.documentType === "photos" ? (
                  <ImageIcon />
                ) : (
                  <NotDefinedFileIcon />
                ),
              tag: item.hashTagsDetails,
            })
          );
          setSuggestionList(arr);
        }
      }
      if (historyRes.data.data.status) {
        let data = historyRes.data.data.data;
        var arr = [];

        if (
          data &&
          data.length !== undefined &&
          data.length !== null &&
          data.length > 0
        ) {
          // Use a Set to store unique names
          const uniqueNamesSet = new Set();

          data.map((item) => {
            // Check if the name is not in the set
            if (!uniqueNamesSet.has(item.name)) {
              // Add the name to the set and push the item to the array
              uniqueNamesSet.add(item.name);
              arr.push({
                _id: item._id,
                isHistory: true,
                name: item.name,
                icon: <ReloadIcon />,
              });
            }
          });

          // Set the state with the filtered array
          setHistoryList(arr);
          setHistorySuggestion(arr);
        }
      }
    } catch (error) {}
  }
  // remove history
  async function removeHistoryApi(id) {
    const updatedData = historySuggestion.filter((row) => row._id !== id);
    const updatedHistoryData = historyList.filter((row) => row._id !== id);
    setHistoryList(updatedHistoryData);
    setHistorySuggestion(updatedData);
    try {
      const removeRes = await authServiceDocument.deleteSearchHistory(id);
      if (removeRes.data.data.status) {
      }
    } catch (error) {}
  }
  // render when component mount
  useEffect(() => {
    typeFromUrl !== null && setSelectedTab(typeFromUrl.replace(/\/$/, ""));
    categoryListApi();
    suggestionListApi();
    if (docFromUrl !== undefined && docFromUrl !== null && docFromUrl !== "") {
      getDocumentById(docFromUrl);
    }
    searchFromUrl !== null && setSearchText(searchFromUrl);
    categoryFromUrl !== null && setSelectedCategoryId(categoryFromUrl);
    if (typeFromUrl === "documents" || typeFromUrl === null) {
      setLoader(true);
      getDocListApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        currentPage,
        searchFromUrl,
        categoryFromUrl,
        sortByFromUrl !== null ? sortByFromUrl : "",
        sortTypeFromUrl !== null ? sortTypeFromUrl : "desc",
        contentTypeFromUrl,
        uploadedFromUrl,
        updatedFromUrl,
        filetypeFromUrl,
        peopleFromUrl,
        subCategoryFromUrl,
        uploadedFromDateFromUrl,
        uploadedToDateFromUrl,
        modifiedFromDateFromUrl,
        modifiedToDateFromUrl
      );
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchFromUrl,
        categoryFromUrl,
        sortByFromUrl,
        sortTypeFromUrl,
        contentTypeFromUrl,
        uploadedFromUrl,
        updatedFromUrl,
        filetypeFromUrl,
        peopleFromUrl,
        subCategoryFromUrl,
        uploadedFromDateFromUrl,
        uploadedToDateFromUrl,
        modifiedFromDateFromUrl,
        modifiedToDateFromUrl
      );
    } else if (typeFromUrl === "saved" || typeFromUrl !== null) {
      setSavedLoader(true);
      getDocListApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        savedcurrentPage,
        searchFromUrl,
        categoryFromUrl,
        sortByFromUrl !== null ? sortByFromUrl : "viewsCount",
        sortTypeFromUrl !== null ? sortTypeFromUrl : "desc",
        contentTypeFromUrl,
        uploadedFromUrl,
        updatedFromUrl,
        filetypeFromUrl,
        peopleFromUrl,
        subCategoryFromUrl,
        uploadedFromDateFromUrl,
        uploadedToDateFromUrl,
        modifiedFromDateFromUrl,
        modifiedToDateFromUrl
      );
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchFromUrl,
        categoryFromUrl,
        sortByFromUrl,
        sortTypeFromUrl,
        contentTypeFromUrl,
        uploadedFromUrl,
        updatedFromUrl,
        filetypeFromUrl,
        peopleFromUrl,
        subCategoryFromUrl,
        uploadedFromDateFromUrl,
        uploadedToDateFromUrl,
        modifiedFromDateFromUrl,
        modifiedToDateFromUrl
      );
    }
  }, [typeFromUrl]);

  // useffect call when list is update
  useEffect(() => {
    if (categoryList.length > 0) {
      const category = categoryList.filter(
        (cate) => cate._id === categoryFromUrl
      );
      setSelectedCategory(
        category.length > 0 ? category[0].name : "All categories"
      );
      if (
        category.length > 0 &&
        category[0].subcategory !== undefined &&
        category[0].subcategory.length > 0
      ) {
        let arr = [
          {
            _id: "",
            name: "Select subcategory",
          },
        ];
        const subcategory = category[0].subcategory.filter(
          (subCat) => subCat._id === subCategoryFromUrl
        );
        setSelectedSubCategory(
          subcategory.length > 0 ? subcategory[0].name : "Select subcategory"
        );
        setSubCategoryList([...arr, ...category[0].subcategory]);
      }
    }
    if (userList.length > 0) {
      const user = userList.filter((users) => users._id === peopleFromUrl);
      setSelectedPeople(user.length > 0 ? user[0] : "");
    }
    const file = fileFormat.filter((files) => files.id === filetypeFromUrl);
    setSelectedFile(file.length > 0 ? file[0] : {});
    setSelectedContent(contentTypeFromUrl !== null ? contentTypeFromUrl : "");
    filterFromUrl();
  }, [categoryList, userList]);

  // result count api function
  async function resultCountApi(
    listtype,
    searchText,
    category,
    sortBy,
    sortType,
    selectedContent,
    datefilteruploaded,
    datefiltermodified,
    selectedFile,
    searchpeople,
    reqFromDate,
    reqToDate,
    modifiedreqFromDate,
    modifiedreqToDate
  ) {
    let query = "?";
    listtype !== undefined && listtype !== null
      ? (query += `listType=${listtype}`)
      : "";
    searchText !== undefined && searchText !== null && searchText !== ""
      ? (query += `&search=${searchText}`)
      : "";
    category !== undefined &&
    category !== null &&
    category !== "" &&
    category !== "All categories"
      ? (query += `&categories=${category}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy !== ""
      ? (query += `&sortBy=${sortBy}`)
      : "";
    sortType !== undefined && sortType !== null && sortType !== ""
      ? (query += `&sortType=${sortType}`)
      : "";
    selectedContent !== undefined &&
    selectedContent !== null &&
    selectedContent !== ""
      ? (query += `&contentTypeFilter=${selectedContent}`)
      : "";
    datefilteruploaded !== undefined &&
    datefilteruploaded !== null &&
    datefilteruploaded !== ""
      ? (query += `&datefilteruploaded=${datefilteruploaded}`)
      : "";
    datefiltermodified !== undefined &&
    datefiltermodified !== null &&
    datefiltermodified !== ""
      ? (query += `&datefiltermodified=${datefiltermodified}`)
      : "";
    selectedFile !== undefined && selectedFile !== null && selectedFile !== ""
      ? (query += `&fileFormateFilter=${selectedFile}`)
      : "";
    searchpeople !== undefined && searchpeople !== null && searchpeople !== ""
      ? (query += `&searchpeople=${searchpeople}`)
      : "";
    datefilteruploaded === "custom" &&
    reqFromDate !== undefined &&
    reqToDate !== undefined
      ? (query += `&reqFromDate=${reqFromDate}&reqToDate=${reqToDate}`)
      : "";
    datefiltermodified === "custom" &&
    modifiedreqFromDate !== undefined &&
    modifiedreqToDate !== undefined
      ? (query += `&modifiedreqFromDate=${modifiedreqFromDate}&modifiedreqToDate=${modifiedreqToDate}`)
      : "";
    try {
      const countRes = await authServiceDocument.resultCount(query);
      if (countRes.data.data.status) {
        setResultCount(countRes.data.data.data);
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  }

  // get sortbyfilter from url
  function filterFromUrl() {
    if (
      sortByFromUrl !== null &&
      sortByFromUrl === "dateUploaded" &&
      sortTypeFromUrl === "Asc"
    ) {
      setSelectedSortBy({ id: "newest", label: "Newest first" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "dateUploaded" &&
      sortTypeFromUrl === "Desc"
    ) {
      setSelectedSortBy({ id: "oldest", label: "Oldest first" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "fileName" &&
      sortTypeFromUrl === "Dec"
    ) {
      setSelectedSortBy({ id: "filez-a", label: "File name Z-A" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "fileName" &&
      sortTypeFromUrl === "Asc"
    ) {
      setSelectedSortBy({ id: "filea-z", label: "File name A-Z" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "owner" &&
      sortTypeFromUrl === "Asc"
    ) {
      setSelectedSortBy({ id: "peoplea-z", label: "People A-Z" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "owner" &&
      sortTypeFromUrl === "Dec"
    ) {
      setSelectedSortBy({ id: "peoplez-a", label: "People Z-A" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "commentsCount" &&
      sortTypeFromUrl === ""
    ) {
      setSelectedSortBy({ id: "commentsCount", label: "Comments count" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "lastModified" &&
      sortTypeFromUrl === ""
    ) {
      setSelectedSortBy({ id: "lastModified", label: "Date modified" });
    }
  }

  // handle select category
  const handleSelectCategory = (selected) => {
    setSelectedCategory(selected.name);
    setSelectedCategoryId(selected._id);
    if (selected.subcategory !== undefined && selected.subcategory.length > 0) {
      let arr = [
        {
          _id: "",
          name: "Select subcategory",
        },
      ];
      setSubCategoryList([...arr, ...selected.subcategory]);
    } else {
      setSubCategoryList([]);
    }
    selectedTab === "saved" ? setSavedLoader(true) : setLoader(true);
    navigate(
      `/documents?type=${
        typeFromUrl !== null ? typeFromUrl : selectedTab
      }&&search=${searchText}&&category=${
        selected._id
      }&&sortby=${sortBy}&&sorttype=${sortType}&&contenttype=${selectedContent}${
        selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
      }${
        selectedCreated.id !== undefined
          ? `&&dateuploaded=${selectedCreated.id}`
          : ""
      }${
        selectedUpdated.id !== undefined
          ? `&&datemodified=${selectedUpdated.id}`
          : ""
      }`
    );
    getDocListApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      1,
      searchText,
      selected._id,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id
    );
    setIsOpen(false);
    setSelectedSubCategory("Select subcategory");
  };

  // handle select subcategory
  const handleSelectSubCat = (selected) => {
    setSubCategoryOpen(false);
    setSelectedSubCategory(selected.name);
    navigate(
      `/documents?type=${
        typeFromUrl !== null ? typeFromUrl : selectedTab
      }&&search=${searchText}&&category=${selectedCategoryId}&&sortby=${sortBy}&&sorttype=${sortType}&&contenttype=${selectedContent}${
        selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
      }${
        selectedCreated.id !== undefined
          ? `&&dateuploaded=${selectedCreated.id}`
          : ""
      }${
        selectedUpdated.id !== undefined
          ? `&&datemodified=${selectedUpdated.id}`
          : ""
      }`
    );
    getDocListApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      1,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id,
      selected._id
    );
  };

  //when search update the suggestion and history list
  const updateSuggestionAndHistoryList = (searchText) => {
    const lowercasedSearchText = searchText.toLowerCase();
    // Filter users by name
    let updatedUserList = userList.filter((user) =>
      user.name.toLowerCase().includes(lowercasedSearchText)
    );

    const startwithUser = updatedUserList.filter((user) =>
      user.name.toLowerCase().startsWith(lowercasedSearchText)
    );

    const noStartwithUser = updatedUserList.filter(
      (user) => !user.name.toLowerCase().startsWith(lowercasedSearchText)
    );

    // Filter suggestions by name or tags
    let updatedSuggestionList = suggestionList.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().includes(lowercasedSearchText) ||
        (suggestion.tag &&
          suggestion.tag.find((tag) =>
            tag.name.toLowerCase().includes(lowercasedSearchText)
          ))
    );

    const startwithItem = updatedSuggestionList.filter(
      (item) =>
        item.name.toLowerCase().startsWith(lowercasedSearchText) ||
        (item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          ))
    );

    const noStartwithItem = updatedSuggestionList.filter(
      (item) =>
        !item.name.toLowerCase().startsWith(lowercasedSearchText) &&
        !(
          item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          )
        )
    );

    // Filter suggestions by description
    const updatedDescriptionSuggestion = suggestionList.filter((suggestion) =>
      suggestion.description.toLowerCase().includes(lowercasedSearchText)
    );
    const startWithDescription = updatedDescriptionSuggestion.filter((item) =>
      item.description.toLowerCase().startsWith(lowercasedSearchText)
    );
    const noStartWithDescription = updatedDescriptionSuggestion.filter(
      (item) => !item.description.toLowerCase().startsWith(lowercasedSearchText)
    );
    setSearchHistory([
      ...startwithUser,
      ...noStartwithUser,
      ...startwithItem,
      ...noStartwithItem,
      ...startWithDescription,
      ...noStartWithDescription,
    ]);
    // Update history list
    let updatedsearchHistory = historyList.filter(
      (data) =>
        data.name.toLowerCase().includes(lowercasedSearchText) ||
        (data.tag &&
          data.tag.find((tag) =>
            tag.name.toLowerCase().includes(lowercasedSearchText)
          ))
    );

    const startwithHistory = updatedsearchHistory.filter(
      (item) =>
        item.name.toLowerCase().startsWith(lowercasedSearchText) ||
        (item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          ))
    );

    const noStartwithHistory = updatedsearchHistory.filter(
      (item) =>
        !item.name.toLowerCase().startsWith(lowercasedSearchText) &&
        !(
          item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          )
        )
    );

    setHistorySuggestion([...startwithHistory, ...noStartwithHistory]); // historyOptions
  };

  // handle on change searchtext
  // const handleSearchChange = (e) => {
  //   setListIndex(-1);
  //   setSuggestionBox(true);
  //   setSearchText(e.target.value);
  //   setActualsearch(e.target.value);
  //   if (e.target.value) {
  //     updateSuggestionAndHistoryList(e.target.value);
  //   } else {
  //     setSuggestionBox(false);
  //     setSearchHistory(historyList);
  //   }
  // };
  const handleSearchChange = (e) => {
    setListIndex(-1);
    let currentValue = e.target.value;
    setSearchText(currentValue);
    setActualsearch(currentValue);

    if (currentValue === "") {
      setSuggestionBox(true);
      setSearchHistory(historyList);
    } else if (/^\s+/.test(currentValue)) {
      setSuggestionBox(false);
    } else {
      setSuggestionBox(true);
      updateSuggestionAndHistoryList(currentValue);
    }
  };

  // handle more result
  const handleMoreResult = () => {
    if (selectedTab === "documents") {
      setMoreLoader(true);
      getDocListApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        currentPage + 1,
        searchText,
        selectedCategoryId,
        sortBy,
        sortType,
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id,
        null,
        uploadedFrom,
        uploadedTo,
        modifiedFrom,
        modifiedTo
      );
    } else {
      setMoreLoader(true);
      getDocListApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        savedcurrentPage + 1,
        searchText,
        selectedCategoryId,
        sortBy,
        sortType,
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id,
        null,
        uploadedFrom,
        uploadedTo,
        modifiedFrom,
        modifiedTo
      );
      //setSavedCurrentPage(savedcurrentPage + 1)
    }
  };

  // handle search
  const handleSearch = (text) => {
    navigate(
      `/documents?type=${
        typeFromUrl !== null ? typeFromUrl : selectedTab
      }&&search=${text}&&category=${selectedCategoryId}`
    );
    selectedTab === "saved" ? setSavedLoader(true) : setLoader(true);
    getDocListApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      1,
      text,
      selectedCategoryId
    );
    {
      text.length > 0 &&
        authServiceDocument.addSearchHistory({
          search: text,
          type: typeFromUrl !== null ? typeFromUrl : selectedTab,
        });
    }
    setSuggestionBox(false);
    setSelectedUpdated({});
    setSelectedCreated({});
    setSelectedContent("");
    setSelectedPeople({});
    setSelectedFile({});
    setSelectedSortBy({});
  };

  const scrollIntoView = (listIndex) => {
    if (dropdownRef.current) {
      dropdownRef.current.children[listIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  // call when any key event trigger and use for search
  const handleKeyDown = (e, text) => {
    if (e.key === "Enter") {
      if (e.target.value === "" || e.target.value.match(/^\S/)) {
        e.preventDefault();
        suggestionListApi();
        handleSearch(text);
      }
    } else if (e.key === "ArrowDown") {
      const listLength = finalArr.length;
      if (listIndex < listLength - 1) {
        setListIndex(listIndex + 1);
      } else if (listIndex === listLength - 1) {
        setListIndex(-1);
        setSearchText(actualsearch);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      const listLength = finalArr.length;
      if (listIndex === 0) {
        setListIndex(-1);
        setSearchText(actualsearch);
      } else if (listIndex === -1) {
        setListIndex(listLength - 1);
      } else {
        setListIndex(listIndex - 1);
      }
    }
  };
  // handle sortby
  const handleSortBy = (data, id) => {
    setSelectedSortBy(data);
    if (id === "newest") {
      setSortBy("dateUploaded");
      setSortType("Desc");
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchText,
        selectedCategoryId,
        "dateUploaded",
        "Asc",
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id
      );
    } else if (id === "oldest") {
      setSortBy("dateUploaded");
      setSortType("Asc");
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchText,
        selectedCategoryId,
        "dateUploaded",
        "Dec",
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id
      );
    } else if (id === "filea-z") {
      setSortBy("fileName");
      setSortType("Asc");
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchText,
        selectedCategoryId,
        "fileName",
        "Asc",
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id
      );
    } else if (id === "filez-a") {
      setSortBy("fileName");
      setSortType("Dec");
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchText,
        selectedCategoryId,
        "fileName",
        "Dec",
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id
      );
    } else if (id === "peoplea-z") {
      setSortBy("owner");
      setSortType("Asc");
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchText,
        selectedCategoryId,
        "people",
        "Asc",
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id
      );
    } else if (id === "peoplez-a") {
      setSortBy("owner");
      setSortType("Dec");
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchText,
        selectedCategoryId,
        "people",
        "Dec",
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id
      );
    } else {
      setSortBy(id);
      setSortType("");
      resultCountApi(
        typeFromUrl !== null ? typeFromUrl : selectedTab,
        searchText,
        selectedCategoryId,
        id,
        "",
        selectedContent,
        selectedCreated.id,
        selectedUpdated.id,
        selectedFile.id,
        selectedPeople._id
      );
    }
  };

  // handle selecet content
  const handleSelectContent = (name) => {
    setSelectedContent(name);
    resultCountApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      name,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id
    );
  };

  // handle date updated function
  const handleDateUpdated = (data) => {
    setSelectedUpdated(data);
    resultCountApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      data.id,
      selectedFile.id,
      selectedPeople._id
    );
  };

  // handle date created function
  const handleDateCreated = (data) => {
    setSelectedCreated(data);
    resultCountApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      data.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id
    );
  };

  // handle file type
  const handleFiletype = (data) => {
    setSelectedFile(data);
    resultCountApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      data.id,
      selectedPeople._id
    );
  };

  // handle people filter
  const handlePeopleFilter = (data) => {
    setSelectedPeople(data);
    resultCountApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      data._id
    );
  };
  // handle final filter
  const handleFilter = () => {
    selectedTab === "saved" ? setSavedLoader(true) : setLoader(true);
    navigate(
      `/documents?type=${
        typeFromUrl !== null ? typeFromUrl : selectedTab
      }&&search=${searchText}&&category=${selectedCategoryId}&&sortby=${sortBy}&&sorttype=${sortType}&&contenttype=${selectedContent}${
        selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
      }${
        selectedCreated.id !== undefined
          ? `&&dateuploaded=${selectedCreated.id}`
          : ""
      }${
        selectedCreated.id === "custom"
          ? `&&uploadedTo=${uploadedTo}&&uploadedFrom=${uploadedFrom}`
          : ""
      }${
        selectedUpdated.id !== undefined
          ? `&&datemodified=${selectedUpdated.id}`
          : ""
      }${
        selectedUpdated.id === "custom"
          ? `&&modifiedTo=${modifiedTo}&&modifiedFrom=${modifiedFrom}`
          : ""
      }${
        selectedPeople._id !== undefined ? `&&people=${selectedPeople._id}` : ""
      }`
    );
    getDocListApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      1,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id,
      selectedSubCategory !== "Select subcategory" ? selectedSubCategory : "",
      uploadedFrom,
      uploadedTo,
      modifiedFrom,
      modifiedTo
    );
    setFilter(false);
  };

  // handle clear all filters
  const handleClearAllFilter = () => {
    navigate(
      `/documents?type=${typeFromUrl !== null ? typeFromUrl : selectedTab}`
    );
    setSelectedUpdated({});
    setSelectedCreated({});
    setSelectedContent("");
    setSelectedPeople({});
    setSelectedFile({});
    setSelectedSortBy({});
    setSearchText("");
    setSearchHistory([]);
    setSelectedCategory("All categories");
    setSelectedCategoryId("");
    resultCountApi(typeFromUrl !== null ? typeFromUrl : selectedTab);
    getDocListApi(typeFromUrl !== null ? typeFromUrl : selectedTab, 1);
    selectedTab === "saved" ? setSavedLoader(true) : setLoader(true);
    setSubCategoryList([]);
  };

  // handle clear search filter
  const handleClearSearch = () => {
    selectedTab === "saved" ? setSavedLoader(true) : setLoader(true);
    navigate(
      `/documents?search=&&category=${selectedCategoryId}&&sortby=${sortBy}&&sorttype=${sortType}&&contenttype=${selectedContent}${
        Object.keys(selectedFile).length > 0
          ? `&&filetype=${selectedFile.id}`
          : ""
      }&&dateuploaded=${
        selectedCreated.id !== undefined ? selectedCreated.id : ""
      }&&datemodified=${
        selectedUpdated.id !== undefined ? selectedUpdated.id : ""
      }`
    );
    setSearchText("");
    setSearchHistory([]);
    getDocListApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      1,
      "",
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id
    );
  };

  // handle uploaded from change
  const handleUploadedFrom = (date) => {
    setUploadedFrom(date);
  };

  // handle uploaded from change
  const handleUploadedTo = (date) => {
    setUploadedTo(date);
    resultCountApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id,
      uploadedFrom,
      date,
      modifiedFrom,
      modifiedTo
    );
  };

  // handle modified from change
  const handleModifiedFrom = (date) => {
    setModifiedFrom(date);
  };

  // handle modified to change
  const handleModifiedto = (date) => {
    setModifiedTo(date);
    resultCountApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      selectedPeople._id,
      uploadedFrom,
      uploadedTo,
      modifiedFrom,
      date
    );
  };

  const updateAppliedFilterCount = () => {
    // Calculate the count based on your state variables
    const count =
      (Object.keys(selectedSortBy).length > 0 ? 1 : 0) +
      (selectedContent ? 1 : 0) +
      (Object.keys(selectedUpdated).length > 0 ? 1 : 0) +
      (Object.keys(selectedCreated).length > 0 ? 1 : 0) +
      (Object.keys(selectedFile).length > 0 ? 1 : 0) +
      (Object.keys(selectedPeople).length > 0 ? 1 : 0);
    setSelectedFilter(count);
  };

  useEffect(() => {
    updateAppliedFilterCount();
  }, [
    selectedSortBy,
    selectedContent,
    selectedUpdated,
    selectedCreated,
    selectedFile,
    selectedPeople,
  ]);

  // on outside click close category and subcategorymenu
  const closeMenu = (e) => {
    if (
      categoryRef.current &&
      isOpen &&
      !categoryRef.current.contains(e.target)
    ) {
      setIsOpen(false);
      document.removeEventListener("mousedown", closeMenu);
    }
    if (
      subCategoryRef.current &&
      subCategoryOpen &&
      !subCategoryRef.current.contains(e.target)
    ) {
      setSubCategoryOpen(false);
      document.removeEventListener("mousedown", closeMenu);
    }
  };
  document.addEventListener("mousedown", closeMenu);

  // handle download document
  const handleDownload = async (docId, fileUrl, restricted) => {
    if (restricted) {
      const data =
        selectedTab === "documents"
          ? allDocList.filter((doc) => docId === doc._id)
          : savedList.filter((doc) => docId === doc._id);
      setSelectedRow(data[0]);
      setRestrict(true);
    } else {
      //authServiceDocument.updateDocumentDownloadCount(docId);
      handleDownloadDoc(fileUrl, setDownloading, setProgress, fileName, docId);
    }
  };
  //when list index update this useeffect will call
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        scrollIntoView(listIndex);
        var text = currentList
          .querySelector(".doc-suggestion-dropdown-list h5")
          .innerText.trim();
        setSearchText(text);
      }
    }
  }, [listIndex]);

  // sorting by date in asc and desc order
  function sortByDate(array, order) {
    const sortedDates = array.sort((a, b) => {
      const dateA = new Date(a.dateUploaded);
      const dateB = new Date(b.dateUploaded);
      if (order === "Asc") {
        return dateA - dateB;
      } else if (order === "Desc") {
        return dateB - dateA;
      }
    });
    return setAllDocList(sortedDates);
  }

  const handleSortByColumn = (e, col, sort) => {
    if (col === "fileName" && sort === "Asc") {
      setSortBy("fileName");
      setSortType("Asc");
      setSelectedSortBy({ id: "filea-z", label: "File name A-Z" });
    } else if (col === "fileName" && sort === "Desc") {
      setSortBy("fileName");
      setSortType("Desc");
      setSelectedSortBy({ id: "filez-a", label: "File name Z-A" });
    } else if (col === "owner" && sort === "Desc") {
      setSortBy("owner");
      setSortType("Desc");
      setSelectedSortBy({ id: "peoplez-a", label: "People Z-A" });
    } else if (col === "owner" && sort === "Asc") {
      setSortBy("owner");
      setSortType("Asc");
      setSelectedSortBy({ id: "peoplea-z", label: "People A-Z" });
    } else if (col === "dateUploaded" && sort === "Asc") {
      setSortBy("dateUploaded");
      setSortType("Asc");
      // setSelectedSortBy({ id: "peoplea-z", label: "People A-Z" });
    } else if (col === "dateUploaded" && sort === "Desc") {
      setSortBy("dateUploaded");
      setSortType("Desc");
      // setSelectedSortBy({ id: "peoplez-a", label: "People Z-A" });
    } else {
      setSelectedSortBy({});
    }
    setSortedOrder(sort);
    setSortedColumn(col);
    getDocListApi(
      selectedTab,
      1,
      searchFromUrl,
      categoryFromUrl,
      col,
      sort,
      contentTypeFromUrl,
      uploadedFromUrl,
      updatedFromUrl,
      filetypeFromUrl,
      peopleFromUrl,
      subCategoryFromUrl,
      uploadedFromDateFromUrl,
      uploadedToDateFromUrl,
      modifiedFromDateFromUrl,
      modifiedToDateFromUrl
    );
  };
  const handlePreview = (row) => {
    if (!row.fileName) {
      getDocumentById(row._id);
      navigate(
        `/documents?type=${typeFromUrl !== null ? typeFromUrl : selectedTab}${
          searchFromUrl !== null && searchFromUrl !== ""
            ? `&&search=${searchFromUrl}`
            : ""
        }${
          selectedCategoryId !== "" ? `&&category=${selectedCategoryId}` : ""
        }${sortBy !== "" ? `&&sortby=${sortBy}` : ""}${
          sortType !== "" ? `&&sorttype=${sortType}` : ""
        }${selectedContent !== "" ? `&&contenttype=${selectedContent}` : ""}${
          selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
        }${
          selectedCreated.id !== undefined
            ? `&&dateuploaded=${selectedCreated.id}`
            : ""
        }${
          selectedUpdated.id !== undefined
            ? `&&datemodified=${selectedUpdated.id}`
            : ""
        }${
          selectedPeople._id !== undefined
            ? `&&people=${selectedPeople._id}`
            : ""
        }&&doc=${row._id}`
      );
    } else {
      document.body.classList.add("modal-open");
      authServiceDocument.updateDocumentViewsCount(row._id);
      setSelectedRow(row);
      setModal(true);
      navigate(
        `/documents?type=${typeFromUrl !== null ? typeFromUrl : selectedTab}${
          searchFromUrl !== null && searchFromUrl !== ""
            ? `&&search=${searchFromUrl}`
            : ""
        }${
          selectedCategoryId !== "" ? `&&category=${selectedCategoryId}` : ""
        }${sortBy !== "" ? `&&sortby=${sortBy}` : ""}${
          sortType !== "" ? `&&sorttype=${sortType}` : ""
        }${selectedContent !== "" ? `&&contenttype=${selectedContent}` : ""}${
          selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
        }${
          selectedCreated.id !== undefined
            ? `&&dateuploaded=${selectedCreated.id}`
            : ""
        }${
          selectedUpdated.id !== undefined
            ? `&&datemodified=${selectedUpdated.id}`
            : ""
        }${
          selectedPeople._id !== undefined
            ? `&&people=${selectedPeople._id}`
            : ""
        }&&doc=${row._id}`
      );
    }
  };

  const handleClosePreview = () => {
    setModal(false);
    navigate(
      `/documents?type=${typeFromUrl !== null ? typeFromUrl : selectedTab}${
        searchFromUrl !== null ? `&&search=${searchFromUrl}` : ""
      }${categoryFromUrl !== null ? `&&category=${categoryFromUrl}` : ""}${
        sortByFromUrl !== null ? `&&sortby=${sortByFromUrl}` : ""
      }${sortTypeFromUrl !== null ? `&&sorttype=${sortTypeFromUrl}` : ""}${
        contentTypeFromUrl !== null ? `&&contenttype=${contentTypeFromUrl}` : ""
      }${filetypeFromUrl !== null ? `&&filetype=${filetypeFromUrl}` : ""}${
        uploadedFromUrl !== null ? `&&dateuploaded=${uploadedFromUrl}` : ""
      }${
        uploadedFromUrl === "custom"
          ? `&&uploadedTo=${uploadedToDateFromUrl}&&uploadedFrom=${uploadedFromDateFromUrl}`
          : ""
      }${updatedFromUrl !== null ? `&&datemodified=${updatedFromUrl}` : ""}${
        updatedFromUrl === "custom"
          ? `&&modifiedTo=${modifiedToDateFromUrl}&&modifiedFrom=${modifiedFromDateFromUrl}`
          : ""
      }${peopleFromUrl !== null ? `&&people=${peopleFromUrl}` : ""}`
    );
    document.body.classList.remove("modal-open");
  };

  useEffect(() => {
    setFinalArr([...searchHistory, ...historySuggestion]);
  }, [searchHistory, historySuggestion]);

  const handleFilterByOwner = (owner) => {
    selectedTab === "saved" ? setSavedLoader(true) : setLoader(true);
    navigate(
      `/documents?type=${
        typeFromUrl !== null ? typeFromUrl : selectedTab
      }&&search=${searchText}&&category=${selectedCategoryId}&&sortby=${sortBy}&&sorttype=${sortType}&&contenttype=${selectedContent}${
        selectedFile.id !== undefined ? `&&filetype=${selectedFile.id}` : ""
      }${
        selectedCreated.id !== undefined
          ? `&&dateuploaded=${selectedCreated.id}`
          : ""
      }${
        selectedUpdated.id !== undefined
          ? `&&datemodified=${selectedUpdated.id}`
          : ""
      }${`&&people=${owner}`}`
    );
    getDocListApi(
      typeFromUrl !== null ? typeFromUrl : selectedTab,
      1,
      searchText,
      selectedCategoryId,
      sortBy,
      sortType,
      selectedContent,
      selectedCreated.id,
      selectedUpdated.id,
      selectedFile.id,
      owner,
      selectedSubCategory !== "Select subcategory" ? selectedSubCategory : "",
      uploadedFrom,
      uploadedTo,
      modifiedFrom,
      modifiedTo
    );
    setFilter(false);
    if (userList.length > 0) {
      const user = userList.filter((users) => users._id === owner);
      setSelectedPeople(user.length > 0 ? user[0] : "");
    }
  };

  // on esc key press  this event will call
  const closeModalOnEsc = (e) => {
    if (
      e.code === "Escape" &&
      filterRef.current &&
      isFilter &&
      !filterRef.current.contains(e.target)
    ) {
      setFilter(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeModalOnEsc);
    }
    if (e.code === "Escape" && isModal) {
      handleClosePreview();
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeModalOnEsc);
    }
    if (e.code === "Escape" && openRestric) {
      setRestrict(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeModalOnEsc);
    }
  };
  document.addEventListener("keydown", closeModalOnEsc);

  return (
    <>
      <div className="doc-management-main-banner">
        <div className="doc-management-main-banner-detials">
          <h4>Have docs you want to share with the community?</h4>
          <p className="wereon-sub-title">
            We're on a mission to shape the next generation of e-commerce.
          </p>
        </div>
        <div className="doc-management-main-banner-btn">
          <Button
            handleClick={() =>
              (window.location = "mailto:appsupport@milliondollarsellers.com")
            }
            variant="static-white"
            size="large"
            label="Submit your document"
          ></Button>
        </div>
      </div>
      <div className="doc-management-header-main">
        <div className="doc-management-header-main-top">
          <Typography variant="h2">Documents</Typography>
          <div
            className="document-search-suggestion"
            onBlur={() => setSuggestionBox(false)}
          >
            <SearchField
              handleFocus={() => {
                if (searchText.length === 0) {
                  setSearchHistory([]);
                }
                setSuggestionBox(true);
              }}
              value={searchText}
              handleChange={handleSearchChange}
              isRounded
              handleKeyDown={(e) => handleKeyDown(e, e.target.value)}
              handleClear={handleClearSearch}
            />
            {suggestionBox && (
              <SuggestionDropDown
                handlePreview={handlePreview}
                historyOptions={historySuggestion}
                ref={dropdownRef}
                listIndex={listIndex}
                onMouseDown={(text) => {
                  handleSearch(text.name);
                  setSearchText(text.name);
                }}
                text={searchText}
                id="suggestion-list"
                size="large"
                options={finalArr}
                handleRemove={removeHistoryApi}
              />
            )}
          </div>
        </div>

        <div className="doc-management-header-main-bottom">
          <div className="doc-management-header-main-bottom-left">
            <FilterIcon
              handleClick={() => setFilter(true)}
              label={selectedFilter}
              hasText
              filterText="Filters"
              icon={<FilterIconAlt />}
              hasIcon
            />
            <div className="doc-management-chips">
              {chipsLabel.map((name) => (
                <Chips
                  handleClick={name.callBackFunction}
                  isActive={name.id === selectedTab}
                  className="large"
                  label={name.label}
                />
              ))}
            </div>
            <div className="doc-management-dropdown" ref={categoryRef}>
              <DropDown
                label=""
                selected={selectedCategory}
                isOpen={isOpen}
                onOpen={() => setIsOpen(!isOpen)}
              />
              {isOpen && categoryList.length > 0 && (
                <DropDownItem
                  options={categoryList}
                  size="medium"
                  onSelect={handleSelectCategory}
                />
              )}
            </div>
            {subCategoryList.length > 0 && (
              <div className="doc-management-dropdown" ref={subCategoryRef}>
                <DropDown
                  label=""
                  selected={selectedSubCategory}
                  isOpen={subCategoryOpen}
                  onOpen={() => setSubCategoryOpen(!isOpen)}
                />
                {subCategoryOpen && subCategoryList.length > 0 && (
                  <DropDownItem
                    options={subCategoryList}
                    size="medium"
                    onSelect={handleSelectSubCat}
                  />
                )}
              </div>
            )}
            {selectedCategory !== "All categories" ||
            selectedFilter > 0 ||
            searchText ? (
              <Button
                label={selectedFilter > 1 ? "Clear filters" : "Clear filter"}
                variant="static-white"
                handleClick={handleClearAllFilter}
              />
            ) : (
              <></>
            )}
          </div>
          {/* <div className='doc-management-header-main-bottom-right'>
                        <ViewType isActive={isList} handleActive={() => setList(!isList)} />
                    </div> */}
        </div>
      </div>

      {selectedTab === "documents" ? (
        <DocsListView
          handleFilterByOwner={handleFilterByOwner}
          setFileName={setFileName}
          handlePreview={handlePreview}
          setSavedCount={setSavedCount}
          savedCount={savedCount}
          handleSelectCategory={handleSelectCategory}
          searchText={searchText}
          selectedFilter={selectedFilter}
          handleDownloadDoc={handleDownload}
          handleClearAllFilter={handleClearAllFilter}
          totalPage={totalPage}
          columns={columns}
          allDocList={allDocList}
          totalRecords={totalRecords}
          currentPage={currentPage}
          handleMoreResult={handleMoreResult}
          setAllDocList={setAllDocList}
          moreLoader={moreLoader}
          loader={loader}
          handleSortByColumn={handleSortByColumn}
          sortedColumn={sortedColumn}
          setSortedColumn={setSortedColumn}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      ) : selectedTab === "saved" ? (
        <SavedDocsListView
          handleFilterByOwner={handleFilterByOwner}
          setFileName={setFileName}
          handlePreview={handlePreview}
          savedCount={savedCount}
          setSavedCount={setSavedCount}
          handleSelectCategory={handleSelectCategory}
          searchText={searchText}
          selectedFilter={selectedFilter}
          handleDownloadDoc={handleDownload}
          handleClearAllFilter={handleClearAllFilter}
          totalPage={savedTotalPage}
          columns={columns}
          allDocList={savedList}
          setSavedList={setSavedList}
          totalRecords={totalSavedRecord}
          currentPage={savedcurrentPage}
          handleMoreResult={handleMoreResult}
          moreLoader={moreLoader}
          loader={savedLoader}
          handleSortByColumn={handleSortByColumn}
          sortedColumn={sortedColumn}
          setSortedColumn={setSortedColumn}
        />
      ) : (
        <></>
      )}
      <div className={`${isFilter ? "modal-fadein" : "modal-fadeout"}`}>
        <div className="desk-globle-model-box globle-model-box" ref={filterRef}>
          <FilterSidebar
            isFilter={isFilter}
            onCancel={() => setFilter(false)}
            userList={userList}
            contentTypeList={contentTypeList}
            selectedSortBy={selectedSortBy}
            selectedFile={selectedFile}
            selectedContent={selectedContent}
            selectedCreated={selectedCreated}
            selectedUpdated={selectedUpdated}
            handleSortBy={handleSortBy}
            handleSelectContent={handleSelectContent}
            handleFilter={handleFilter}
            handleDateUpdated={handleDateUpdated}
            handleDateCreated={handleDateCreated}
            handleClearAllFilter={handleClearAllFilter}
            handlePeopleFilter={handlePeopleFilter}
            handleFiletype={handleFiletype}
            selectedPeople={selectedPeople}
            resultCount={resultCount}
            sortBy={sortByList}
            dateList={dateList}
            fileFormat={fileFormat}
            uploadedFrom={uploadedFrom}
            uploadedTo={uploadedTo}
            handleUploadedFrom={handleUploadedFrom}
            handleUploadedTo={handleUploadedTo}
            handleModifiedFrom={handleModifiedFrom}
            handleModifiedto={handleModifiedto}
            modifiedFrom={modifiedFrom}
            modifiedTo={modifiedTo}
          />
        </div>
      </div>
      {downloading && (
        <div className="doc-download-progress">
          <ToastNotification
            onCancel={() => cancelDownload(setDownloading, setProgress)}
            isProgress
            progress={progress}
            title={fileName}
            variant="neutral"
            size="medium"
          />
        </div>
      )}
      <DocFileModal
        handleClosePreview={handleClosePreview}
        setSavedCount={setSavedCount}
        savedCount={savedCount}
        isDoc={isModal}
        setDoc={setModal}
        data={selectedRow}
        setSelectedRow={setSelectedRow}
        handleDownload={handleDownload}
        setAllDocList={setAllDocList}
        setRestrict={setRestrict}
      />
      <NoDownload
        isDoc={openRestric}
        data={selectedRow}
        setRestrict={setRestrict}
        setSelectedRow={setSelectedRow}
      />
    </>
  );
};

export default DocumentMainPage;
