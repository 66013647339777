import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchactivityfeelings,
  fetchtopics,
  fetchallpost,
  fetchpostbygroupid_admin
} from "../../Action/index.js";
//import componenets
import AddPost from "./AddPost";
import AllPost from "./AllPost";
//import api file
import Services from "../../Services/auth.service";
//import images
import File1 from "../../Images/image1.png";
import Photos from "../../Images/photos.svg";
import Videos from "../../Images/videos.svg";
import Feelings from "../../Images/feelings.svg";
import Topics from "../../Images/topics.svg";
import Dotted from "../../Images/dotted.svg";
import Tagfrnd from "../../Images/user-add-line.svg";
const GroupPost = ({selectedGid}) => {
  const dispatch = useDispatch();
  const [progbarVal, setprogbarVal] = useState("");
  const [posterr, setposterr] = useState({ status: false, msg: "" });
  useEffect(()=>{ 
    dispatch(fetchactivityfeelings());
    dispatch(fetchtopics());
  },[])
  useEffect(() => {
    dispatch(fetchactivityfeelings());
    dispatch(fetchtopics());
   if(selectedGid.length > 0) dispatch(fetchpostbygroupid_admin({groupid: selectedGid,page:1}))
  }, [selectedGid]);

  const handleLike = (postid) => {
    Services.likepost(postid)
      .then((res) => {
        dispatch(fetchpostbygroupid_admin({groupid:selectedGid,page:1}))
      }) 
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="gi-group-posts-edit">
        <div
          className="what-is-happening-main"
          data-toggle="modal"
          data-target="#adminaddpost"
        >
          <div className="what-post">
            <div className="what-post-left">
              <img src={File1} />
            </div>
            <div className="what-post-right">
              <div className="what-post-right-input cstm-post-input">
                <input type="" placeholder="what is happening?" />
              </div>

              <div className="what-post-btn-all">
                <span className="cp-btn">
                  <button
                    className="cp-btn-photos"
                    data-toggle="modal"
                    data-target="#adminaddpost"
                  >
                    <img src={Photos} />
                    Photos
                  </button>
                </span>

                <span className="cp-btn">
                  <button
                    className="cp-btn-videos"
                    data-toggle="modal"
                    data-target="#adminaddpost"
                  >
                    <img src={Videos} />
                    Videos
                  </button>
                </span>

                <span className="cp-btn">
                  <button
                    className="cp-btn-feelingn"
                    data-toggle="modal"
                    data-target="#adminaddpost"
                  >
                    <img src={Feelings} />
                    Feelings
                  </button>
                </span>

                <span className="cp-btn">
                  <button
                    className="cp-btn-topics"
                    data-toggle="modal"
                    data-target="#adminaddpost"
                  >
                    <img src={Tagfrnd} />
                    {/* <img src={Topics} /> */}
                    Tag a friend
                  </button>
                </span>

                <span className="cp-btn cstm-cp-dp">
                  <button className="cp-btn-dotted">
                    <img src={Dotted} />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {progbarVal.length > 0 ? (
        <div className="progress" id="progressbarmain">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            id="progressbar"
            style={{ width: progbarVal }}
          ></div>
        </div>
      ) : (
        ""
      )}
      <AddPost
        setposterr={setposterr}
        setprogbarVal={setprogbarVal}
        selectedGid={selectedGid}
      />
      <AllPost
        posterr={posterr}
        setposterr={setposterr}
        handleLike={handleLike}
        progbarVal={progbarVal}
        setprogbarVal={setprogbarVal}
        selectedGid={selectedGid}
      />
    </>
  );
};

export default GroupPost;
