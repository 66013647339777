const allUserList = [];

const ReduAllUsersList = (state = allUserList, action) => {
  switch (action.type) {
    case "SETALLUSERLIST":
      return action.payload;
    case "SETONLINE":
        return state.map((user)=>{
            if(user.id === action.payload) return {...user, onlineStatus: true};
            else return user;
        });
    case "SETOFFLINE":
        return state.map((user)=>{
            if(user.id === action.payload) return {...user, onlineStatus: false};
            else return user;
        });
    default:
      return state;
  }
};

export default ReduAllUsersList;