import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "cropperjs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../Images/loader.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import UserInfoSideBar from "./UserInfoSideBar";
import { useCancelledUserSuggestionListsQuery, useGetCancelledUsersQuery } from "../../Services/users";
import loading from "../../Images/loader.gif";
import moment from "moment";

const columns = [
  { id: "displayName", label: "Name" },
  { id: "firstName", label: "First name" },
  { id: "lastName", label: "Last name" },
  { id: "email", label: "Email" },
];

function createData(displayName, firstName,lastName, email, action) {
  return {
    displayName, firstName,lastName, email, action
  };
}
const CancelledUsers = () => {

  const [users, setUsers] = useState([]);
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [sortType, setSortType] = useState("Asc");
  const [sortField, setSortField] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [actualsearch, setActualsearch] = useState("");
  const [finalSearchText, setFinalSearchText] = useState("");
  const [allList, setAllList] = useState([]);
  const [listIndex, setListIndex] = useState(-1);
  const [dataLoaded,setDataLoaded] = useState(null);


  //get imported user list
  const { data: cancelledUsersList, isFetching: dataLoader } =
    useGetCancelledUsersQuery({ page: (page + 1), limit: rowsPerPage, search:finalSearchText ,sortField: sortField, sortType: sortType });

    useEffect(() => {
      if (cancelledUsersList) {
        if (cancelledUsersList?.status && cancelledUsersList?.data) {
          const data = cancelledUsersList.data.userList;
          if (data.length > 0) {
            var newarr = [];
            for (var i = 0; i < data.length; i++) {
              newarr[i] = createData(
                data[i].display_name,
                data[i].first_name,
                data[i].last_name,
                data[i]["Preferred Email"],
                data[i]._id
              );
            }
            setTotalRows(cancelledUsersList.data.totalMessages);
            setRow(newarr);
            setAllList(data);
            setDataLoaded(true); 
          } else {
            setDataLoaded(false);
          }
        }
      }
    }, [cancelledUsersList]);

  //cancel user suggestions list functions
  const { data: resCancelledUserSuggestion, isFetching: newsLoader } =
    useCancelledUserSuggestionListsQuery();

  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    setFinalSearchText(text);
  };

  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="users-info">
        <UserInfoSideBar />
        <div className="users-info-content">
          <div className="users-info-content-main">

            <div className="uic-title">
              <h1>All cancelled users</h1>
            </div>

            <div className="uic-fl-mn">
            <div className="uic-fl-mn-left">
              <div className="uic-fl-search">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-user-input"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    var currentValue;
                    if (e.target.value) {
                      currentValue = e.target.value;
                    } else {
                      currentValue = e.target.value;
                      setSearchText(currentValue);
                    }
                    setActualsearch(currentValue);
                    if (
                      resCancelledUserSuggestion?.data !== undefined &&
                      resCancelledUserSuggestion?.data.length > 0
                    ) {
                      setListIndex(-1);
                      setSuggestionBox(true);
                      var searchResult = resCancelledUserSuggestion?.data?.filter((user) => {
                        return user.display_name?.toLowerCase().includes(e.target.value.trim().toLowerCase());
                      });
                      setSuggestions(searchResult);
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(e.target.value);
                    }
                     else if (e.key === "ArrowDown") {
                      const listLength =
                        suggestions.length > 15 ? 15 : suggestions.length;
                      if (listIndex < listLength - 1) {
                        setListIndex(listIndex + 1);
                      } else if (listIndex === listLength - 1) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      }
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      const listLength =
                        suggestions.length > 15 ? 15 : suggestions.length;
                      if (listIndex === 0) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      } else if (listIndex === -1) {
                        setListIndex(listLength - 1);
                      } else {
                        setListIndex(listIndex - 1);
                      }
                    }
                  }}
                  onBlur={() => setSuggestionBox(false)}
                />
                {suggestionBox &&
                  suggestions.length > 0 &&
                  searchText.length > 0 && (
                    <div className="suggestion-box">
                      <ul id="suggestion-list">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onMouseDown={() => handleSearch(suggestion.name)}
                            className={
                              index === listIndex ? "selected-active" : ""
                            }
                          >
                            {searchText !== null &&
                            searchText.length > 0 &&
                            suggestion.display_name
                              .toLowerCase()
                              .startsWith(searchText.toLowerCase()) ? (
                              <>
                                <b>
                                  {suggestion.display_name.slice(0, searchText.length)}
                                </b>
                                {suggestion.display_name &&
                                  suggestion.display_name.slice(
                                    searchText.length,
                                    suggestion.display_name.length - 1
                                  )}
                              </>
                            ) : (
                              suggestion.display_name
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                <i className="fa fa-search" onClick={()=>handleSearch(searchText)} />
              </div>
            </div>
          </div>
       
            {showAlert && <div className="alert alert-info">{alertMsg}</div>}

            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            column.id === "action"
                              ? "uic-th-td w-8"
                              : "uic-th-td w-10"
                          }
                        >
                          
                          {(column.id === "displayName" || column.id === "firstName" || column.id === "lastName" || column.id === "email")    ? (
                            <>
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    setSortType("Asc")
                                    setSortField(column.id)
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    setSortType("Desc")
                                    setSortField(column.id)
                                  }}
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {dataLoaded === null ? (
                      <div className="cstm-data-load">
                        <img src={Loader} />{" "}
                      </div>
                    ) : dataLoaded ? (
                      rows &&
                      rows.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                            className="uic-th-td w-20"
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-10"
                                >
                                  {value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    ) : (
                      <div className="no-data-found">
                        <p>No Data Found </p>
                      </div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>

      </div>
      <AlertComponent />
    </>
  );
};
export default CancelledUsers;
