import StarYellow from "../../../Images/star-yellow.png";
import Calendar from "../../../Images/calendar.png";
import ListUnordered from "../../../Images/list-unordered.png";
import GrupInviteicon from "../../../Images/grup-invite-icon.svg";
import GrupSharicon from "../../../Images/grup-shar-icon.svg";
const Event = () => {
    return (
      <>
        <div className="pro-events-top">
          <div className="pro-uge-title">Upcoming Group Events</div>
          <div className="pro-uge-view">
            <div className="pro-uge-view-check">
              <input
                type="checkbox"
                id="Favorite"
                name="Favorite"
                value="Bike"
              />
              Favorite Events{" "}
            </div>
            <div className="pro-uge-view-btn">
              <span className="view-by-title">View by :</span>
              <span className="view-by-btn">
                <button className="btn-cal  view-active">
                  <img src={ListUnordered} />
                </button>
                <button className="btn-list">
                  <img src={Calendar} />
                </button>
              </span>
            </div>
          </div>
        </div>

        <div className="upcoming-group-events-box">
          <span className="upcoming-group-star">
            <img src={StarYellow} />
          </span>
          <div className="upcoming-group-events-box-cale">
            <div className="upcoming-group-events-box-wed">Wed</div>
            <div className="cstm-events-list-left-nubmer">15</div>
            <div className="cstm-events-list-left-months">March</div>
          </div>
          <div className="upcoming-group-events-box-cont">
            <div className="upcoming-group-events-box-cont-title">
              Free Food For Needy By Team CssFounder.com
            </div>
            <div className="upcoming-group-events-box-cont-sub">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry.
            </div>
            <div className="upcoming-group-events-box-cont-brn">
              <button className="upcoming-e-join">Join</button>
              <button className="upcoming-e-invite">
                <img src={GrupInviteicon} alt="GrupInviteicon" />
                Invite
              </button>
              <button className="upcoming-e-share">
                <img src={GrupSharicon} alt="GrupSharicon" />
                Share
              </button>
            </div>
          </div>
        </div>

        <div className="upcoming-group-events-box">
          <span className="upcoming-group-star">
            <img src={StarYellow} />
          </span>
          <div className="upcoming-group-events-box-cale">
            <div className="upcoming-group-events-box-wed">Wed</div>
            <div className="cstm-events-list-left-nubmer">15</div>
            <div className="cstm-events-list-left-months">March</div>
          </div>
          <div className="upcoming-group-events-box-cont">
            <div className="upcoming-group-events-box-cont-title">
              Free Food For Needy By Team CssFounder.com
            </div>
            <div className="upcoming-group-events-box-cont-sub">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry.
            </div>
            <div className="upcoming-group-events-box-cont-brn">
              <button className="upcoming-e-join">Join</button>
              <button className="upcoming-e-invite">
                <img src={GrupInviteicon} alt="GrupInviteicon" />
                Invite
              </button>
              <button className="upcoming-e-share">
                <img src={GrupSharicon} alt="GrupSharicon" />
                Share
              </button>
            </div>
          </div>
        </div>

        <div className="upcoming-group-events-box">
          <span className="upcoming-group-star">
            <img src={StarYellow} />
          </span>
          <div className="upcoming-group-events-box-cale">
            <div className="upcoming-group-events-box-wed">Wed</div>
            <div className="cstm-events-list-left-nubmer">15</div>
            <div className="cstm-events-list-left-months">March</div>
          </div>
          <div className="upcoming-group-events-box-cont">
            <div className="upcoming-group-events-box-cont-title">
              Free Food For Needy By Team CssFounder.com
            </div>
            <div className="upcoming-group-events-box-cont-sub">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry.
            </div>
            <div className="upcoming-group-events-box-cont-brn">
              <button className="upcoming-e-join">Join</button>
              <button className="upcoming-e-invite">
                <img src={GrupInviteicon} alt="GrupInviteicon" />
                Invite
              </button>
              <button className="upcoming-e-share">
                <img src={GrupSharicon} alt="GrupSharicon" />
                Share
              </button>
            </div>
          </div>
        </div>

        <div className="upcoming-group-events-box">
          <span className="upcoming-group-star">
            <img src={StarYellow} />
          </span>
          <div className="upcoming-group-events-box-cale">
            <div className="upcoming-group-events-box-wed">Wed</div>
            <div className="cstm-events-list-left-nubmer">15</div>
            <div className="cstm-events-list-left-months">March</div>
          </div>
          <div className="upcoming-group-events-box-cont">
            <div className="upcoming-group-events-box-cont-title">
              Free Food For Needy By Team CssFounder.com
            </div>
            <div className="upcoming-group-events-box-cont-sub">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry.
            </div>
            <div className="upcoming-group-events-box-cont-brn">
              <button className="upcoming-e-join">Join</button>
              <button className="upcoming-e-invite">
                <img src={GrupInviteicon} alt="GrupInviteicon" />
                Invite
              </button>
              <button className="upcoming-e-share">
                <img src={GrupSharicon} alt="GrupSharicon" />
                Share
              </button>
            </div>
          </div>
        </div>

        <div className="upcoming-group-events-box">
          <span className="upcoming-group-star">
            <img src={StarYellow} />
          </span>
          <div className="upcoming-group-events-box-cale">
            <div className="upcoming-group-events-box-wed">Wed</div>
            <div className="cstm-events-list-left-nubmer">15</div>
            <div className="cstm-events-list-left-months">March</div>
          </div>
          <div className="upcoming-group-events-box-cont">
            <div className="upcoming-group-events-box-cont-title">
              Free Food For Needy By Team CssFounder.com
            </div>
            <div className="upcoming-group-events-box-cont-sub">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry.
            </div>
            <div className="upcoming-group-events-box-cont-brn">
              <button className="upcoming-e-join">Join</button>
              <button className="upcoming-e-invite">
                <img src={GrupInviteicon} alt="GrupInviteicon" />
                Invite
              </button>
              <button className="upcoming-e-share">
                <img src={GrupSharicon} alt="GrupSharicon" />
                Share
              </button>
            </div>
          </div>
        </div>
      </>
    );
}
export default Event
