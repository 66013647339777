import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";
import AppleLogin from "react-apple-login";

import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useDispatch } from "react-redux";
import auth0 from "auth0-js";
import { setregidetail } from "../../../Action";
// Import Api integration file
import Services from "../../../Services/auth.service";
import auth from "../../../Services/auth0services";
// Import Images
import logo from "../../../Images/whitelogo.svg";
import arrowleft from "../../../Images/arrow-left.png";
import Loader2 from "../../../Images/loader2.gif";
const PreferredEmail = ({ field }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isProcessing, setProcessingTo] = useState(false);
  const [userDetail, setuserDetail] = useState({
    email: "",
    captcha: "",
    mediasignup: "",
    password: "",
    conpassword: "",
  });
  const [socialEmail, setsocialEmail] = useState("");
  const [socialid, setsocialid] = useState("");
  const [err, setErr] = useState({
    emailErr: "",
    captchaErr: "",
    passwordErr: "",
    conpasswordErr: "",
  });
  const [mainErr, setMainErr] = useState("");
  const recaptchaRef = React.createRef();
  useEffect(() => {
    if (
      location.state !== undefined &&
      location.state !== null &&
      location.state.UserExist
    ) {
      setProcessingTo(false);
      setMainErr("User already exist try to login");
      setTimeout(() => {
        setMainErr("");
        history("/");
      }, 2000);
    }
  }, [location]);

  // called on click on next button
  const handleformStep1 = () => {
    var obj = { ...err };
    if (obj.captchaErr.length === 0 && userDetail.mediasignup.length > 0) {
      verfiyEmail();
    } else if (userDetail.mediasignup.length === 0) {
      obj = { ...obj, mediaErr: "Select social platform!" };
    }
    setErr({ ...obj });
  };

  const handleFBLogin = (media) => {
    setuserDetail({
      ...userDetail,
      password: "",
      conpassword: "",
      mediasignup: media,
    });
    setErr({ ...err, passwordErr: "", conpasswordErr: "" });
  };
  // call api to verify email address
  function verfiyEmail() {
    setProcessingTo(true);
    var checksocialid = socialid;
    if (checksocialid.length > 0) {
      Services.socialuserexist({ facebookLinkedinId: checksocialid })
        .then(function (response) {
          if (!response.data.status) {
            if (userDetail.mediasignup.length > 0) {
              setProcessingTo(false);
              var options = {
                connection: userDetail.mediasignup,
              };
              var auth_soc = new auth0.WebAuth({
                domain: process.env.REACT_APP_AUTH0_DOMAIN,
                clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                responseType: "token id_token",
                redirectUri: process.env.REACT_APP_REDIRECT_URL_SOCIAL_SIGNUP,
                scope: "openid profile email full_access",
              });

              auth_soc.authorize(options, function (err, authResult) {
                if (err) {
                  console.log(err, " error");
                }
                console.log(authResult, "*********");
              });
            } else {
              auth.signup(
                {
                  email: checkEmail,
                  password: userDetail.password,
                  connection: process.env.REACT_APP_AUTH0_DB_NAME,
                },
                function (err, result) {
                  console.log(result, "auth0 signup result");
                  if (err) {
                    setProcessingTo(false);
                    setMainErr("registration failed!");
                  } else {
                    var auth0_data = {
                      email: checkEmail,
                      secondary_email: userDetail.email,
                      provider: userDetail.mediasignup,
                      auth0Id: result.Id,
                      isSocial: true,
                      webOrApp: "web"
                    };
                    Services.saveauth0detail(auth0_data)
                      .then((res) => {
                        if (res.data.status) {
                          dispatch(
                            setregidetail({
                              userid: res.data.data._id,
                              provider: userDetail.mediasignup,
                              register_status: true,
                              personalDetail_status: false,
                              payment_status: false,
                              QA_status: false,
                              emailid: checkEmail,
                              backpage: "preemail",
                            })
                          );
                          setErr({
                            emailErr: "",
                            captchaErr: "",
                            passwordErr: "",
                            conpasswordErr: "",
                          });
                          setProcessingTo(false);
                          history("/firststepregister/3");
                          setMainErr("");
                        } else {
                          setProcessingTo(false);
                          setMainErr(res.data.message);
                        }
                      })
                      .catch((e) => {
                        setProcessingTo(false);
                        console.log(e);
                      });
                  }
                }
              );
            }
          } else {
            setProcessingTo(false);
            setMainErr("User already exist try to login");
            setTimeout(() => {
              setMainErr("");
              history("/");
            }, 2000);
          }
        })
        .catch(function (err) {
          setProcessingTo(false);
          console.log(err);
          setMainErr("Try again something went wrong!");
        });
    }
  }
  const handleSelectFB = (response) => {
    if (response.id !== undefined && response.id.length > 0) {
      // setsocialEmail(response.email);
      setsocialid(response.id);
      setErr({ ...err, mediaErr: "" });
    }
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: "77vqx0j4jty35b",
    redirectUri: process.env.REACT_APP_REDIRECT_URL_SOCIAL_LOGIN,
    scope: "r_emailaddress,r_liteprofile",
    onSuccess: (code) => {
      Services.getlinkedinuser({
        code: code,
        redirect_uri: process.env.REACT_APP_REDIRECT_URL_SOCIAL_LOGIN,
      })
        .then((res) => {
          if (res.data.status) {
            handleFBLogin("linkedin");
            setsocialEmail(res.data.data);
            setErr({ ...err, mediaErr: "" });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <div className="um-main cstm-um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left">
              <div className="um-left-in">
                <div className="um-logo">
                  {" "}
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn csmt-um-left-in-mn">
                  <div className="um-left-in-content-sec">
                    <div className="um-title2">
                      <h2>Registration</h2>
                    </div>
                    <div className="um-text um-text-2">
                      <p>
                        Please provide the best details to personally reach you.
                        This is used for event invites and other important
                        updates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 um-right cstm-um-right-2-1">
              <div className="um-right-in2 cstm-preferred">
                <div className="cstm-sim-title">Connect your Facebook account to your MDS profile.</div>

                <div className="form-group">
                  <span className="error">{mainErr}</span>
                </div>

                <div className="row">
                  <FacebookLogin
                    appId="494638692251434"
                    onSuccess={(res) => {
                      console.log(res);
                    }}
                    onFail={(error) => {
                      console.log(error);
                    }}
                    onProfileSuccess={(response) => {
                      console.log("response from fb", response);
                      handleFBLogin("facebook");
                      handleSelectFB(response);
                    }}
                    render={({ onClick, logout }) => (
                      <div className="">
                        <button
                          className={
                            userDetail.mediasignup === "facebook"
                              ? "btn-facebook social-checked"
                              : "btn-facebook"
                          }
                          onClick={onClick}
                        >
                          <i
                            className="fa fa-facebook-square"
                            aria-hidden="true"
                          ></i>{" "}
                          Connect with Facebook
                        </button>
                      </div>
                    )}
                  />
                  <div className="cstm-apple-btn">
                    <button
                      className="apple-btn"
                      onClick={() => {
                        handleFBLogin("apple");
                        var options = {
                          connection: "apple",
                        };
                        var auth_soc = new auth0.WebAuth({
                          domain: process.env.REACT_APP_AUTH0_DOMAIN,
                          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                          responseType: "token id_token",
                          redirectUri:
                            process.env.REACT_APP_REDIRECT_URL_SOCIAL_SIGNUP,
                          scope: "openid profile email full_access",
                        });

                        auth_soc.authorize(options, function (err, authResult) {
                          if (err) {
                            setMainErr(err);
                            console.log(err, " error");
                          }
                          console.log(authResult, "*********");
                        });
                      }}
                    >
                      <i class="fa fa-apple"></i> Connect with Apple{" "}
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <span className="error">{err.mediaErr}</span>
                </div>
                <div className="um-rgt-btn">
                  <button
                    className="btn3"
                    onClick={() => {
                      if (field !== undefined) history("/firststepregister/2");
                    }}
                  >
                    Cancel
                  </button>
                  {isProcessing ? (
                    <>
                      <div className="um-rgt-btn-loading">
                        <span className="loading-btn-in2">
                          <img src={Loader2} />
                        </span>
                        <button className="btn2" disabled={isProcessing}>
                          Please wait...
                        </button>
                      </div>
                    </>
                  ) : (
                    <button className="btn2" onClick={handleformStep1}>
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferredEmail;
