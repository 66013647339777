const modalinprocess = false;

const ReduModalInProcess = (state = modalinprocess, action) => {
  switch (action.type) {
    case "SETMODALINPROCESS":
      return true;
    case "REMOVEMODALINPROCESS":
        return false;
    default:
      return state;
  }
};

export default ReduModalInProcess;