import { useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import moment from "moment";
import AllPostImgView from "./AllPostImgView.js"
import File1 from "../../../Images/image1.png";
import Yes from "../../../Images/yes.svg";
import Link from "../../../Images/link.svg";
import loading3 from "../../../Images/loading3.gif";
import svdpostimg from "../../../Images/savedpost.svg";
import Save from "../../../Images/save.svg";
import Dotted from "../../../Images/dotted.svg";

const PostMainContent = ({post,userIsMember,handleSave,inprocess_save,usersvdpost,USER_ID , postimgvid, setpost_allimg, setallimg, post_scope, feelings}) => {
    const history = useNavigate()
    return (
        <div className="newsfeed-post cstm-newsfeed-post">
        <div className="newsfeed-post-left">
          <img
            src={
              post.user_type === "user" && post.postedBy &&
              post.postedBy.profileImg !== undefined &&
              post.postedBy.profileImg.length > 0
                ? post.postedBy.profileImg
                : File1
            }
          />
        </div>
        <div className="newsfeed-post-right">
          <div className="newsfeed-post-right-header">
            <div className="newsfeed-post-right-header-name">
              <span className="user-name-title">
                {post.postedBy !== undefined &&
                post.postedBy !== null ? (
                  <>
                    {post.user_type !== "adminuser" ? (
                      <span
                        className="user-title-click"
                        onClick={() => {
                          history(
                            `/settings/edit`
                          );
                        }}
                      >
                        {post.postedBy.otherdetail[
                          process.env.REACT_APP_FIRSTNAME_DBID
                        ] +
                          " " +
                          post.postedBy.otherdetail[
                            process.env.REACT_APP_LASTNAME_DBID
                          ]}{" "}
                      </span>
                    ) : (
                      post.postedBy.first_name +
                      " " +
                      post.postedBy.last_name
                    )}{" "}
                    {post.postedBy.verified ? (
                      <img className="yesicon" src={Yes} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <></>
                )}
                {post_scope !== "group" ? (
                  <>
                    <span className="cstm-posted-in">posted in</span>{" "}
                    <span
                      className="user-title-click"
                      onClick={() => {
                        history(`/group/${post.groupId._id}`);
                      }}
                    >
                      {" "}
                      {post.groupId.groupTitle}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </span>

              <span className="user-name-date">
                {new Date(post.createdAt).toLocaleDateString(
                  "en-us",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }
                ) +
                  " at " +
                  new Date(post.createdAt).toLocaleTimeString(
                    navigator.language,
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
              </span>
            </div>
          </div>

          <div className="newsfeed-post-right-text">
            <b>
              {post.tagAFriend.length > 0 ? " with " : ""}
              {post.tagAFriend.length > 0 ? (
                post.tagAFriend.map((frnd, index_frnd) => {
                  if (post.tagAFriend.length === 1)
                    return (
                      <span
                        onClick={() => {
                          history(`/settings/edit`);
                        }}
                      >
                        {frnd.otherdetail[
                          process.env.REACT_APP_FIRSTNAME_DBID
                        ] +
                          " " +
                          frnd.otherdetail[
                            process.env.REACT_APP_LASTNAME_DBID
                          ]}
                      </span>
                    );
                  else if (frnd.length - 1 === index_frnd)
                    return (
                      <span
                        onClick={() => {
                          history(`/settings/edit`);
                        }}
                      >
                        {" "}
                        and{" "}
                        {frnd.otherdetail[
                          process.env.REACT_APP_FIRSTNAME_DBID
                        ] +
                          " " +
                          frnd.otherdetail[
                            process.env.REACT_APP_LASTNAME_DBID
                          ]}
                      </span>
                    );
                  else
                    return (
                      <span
                        onClick={() => {
                          history(`/settings/edit`);
                        }}
                      >
                        {frnd.otherdetail[
                          process.env.REACT_APP_FIRSTNAME_DBID
                        ] +
                          " " +
                          frnd.otherdetail[
                            process.env.REACT_APP_LASTNAME_DBID
                          ]}{" "}
                        ,{" "}
                      </span>
                    );
                })
              ) : (
                <></>
              )}
            </b>
            <ShowMoreText
              /* Default options */
              lines={3}
              more="See more"
              less="See less"
              className="content-css"
              anchorclassName="my-anchor-css-class"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {post.description}
            </ShowMoreText>
            <span>
              {post.feelingsActivity !== null &&
              post.feelingsActivity !== undefined &&
              post.feelingsActivity._id.length > 0 ? (
                <span>
                  {feelings.filter(
                    (feelings) =>
                      feelings._id === post.feelingsActivity._id
                  )[0] !== undefined
                    ? `
                       - feeling
                      ${
                        post.feelingsActivity.emoji +
                        " " +
                        post.feelingsActivity.feeling
                      }`
                    : ""}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>

          {post.topics.length > 0 ? (
            <div className="newsfeed-post-right-topik">
              {" "}
              {post.topics.map((topic) => (
                <span className="topik-in">{topic.topic}</span>
              ))}
            </div>
          ) : (
            ""
          )}
          <AllPostImgView post={post} postimgvid={postimgvid} setpost_allimg={setpost_allimg} setallimg={setallimg} />
          <div
            style={
              post.pollChoices !== undefined &&
              post.pollChoices.length > 0
                ? { display: "block" }
                : { display: "none" }
            }
          >
            {post.pollChoices !== undefined &&
            post.pollChoices.length > 0
              ? post.pollChoices.map((opt) => {
                //   var givevote =
                //     post.pollDuration !== "No time limit"
                //       ? moment
                //           .duration(
                //             moment().diff(
                //               moment(post.createdAt, "YYYY-MM-DD")
                //             )
                //           )
                //           .asDays() > parseInt(post.pollDuration)
                //         ? false
                //         : true
                //       : true;
                  return (
                    <>
                      <div className="newsfeed-agree-all">
                        <div
                          className="newsfeed-agree-main"
                        //   onClick={() => {
                        //     if (givevote && userIsMember)
                        //       handleVote(opt._id, post._id);
                        //   }}
                        >
                          <span
                            className="newsfeed-agree-main-color"
                            style={{
                              width: `${
                                post.pollTotalVotes !== undefined &&
                                post.pollTotalVotes.length > 0
                                  ? Math.round(
                                      (opt.votes.length * 100) /
                                        post.pollTotalVotes.length
                                    )
                                  : 0
                              }%`,
                              background: "#d3efe7",
                            }}
                          ></span>
                          <span className="newsfeed-agree-main-title">
                            {opt.value}
                          </span>
                          <span className="newsfeed-agree-main-ps">
                            {post.pollTotalVotes !== undefined &&
                            post.pollTotalVotes.length > 0
                              ? Math.round(
                                  (opt.votes.length * 100) /
                                    post.pollTotalVotes.length
                                )
                              : 0}
                            %
                          </span>
                        </div>
                        <span className="news-votes-cunt">
                          {opt.votes.length === 0
                            ? "0 Vote"
                            : opt.votes.length === 1
                            ? "1 Vote"
                            : `${opt.votes.length} Votes`}{" "}
                        </span>
                      </div>
                    </>
                  );
                })
              : ""}
            <div className="newsfeed-votes-main">
              <span className="cstm-votes-ct">
                {post.pollTotalVotes !== undefined
                  ? post.pollTotalVotes.length
                  : 0}{" "}
                Votes
              </span>
              <span className="cstm-poll-will-close">
                &bull;{" "}
                {post.pollDuration !== "No time limit" &&
                Math.ceil(
                  moment
                    .duration(
                      moment().diff(
                        moment(post.createdAt, "YYYY-MM-DD")
                      )
                    )
                    .asDays()
                ) > parseInt(post.pollDuration)
                  ? "Voting closed!"
                  : post.pollDuration.toLowerCase() ===
                    "no time limit"
                  ? "Not time limit for voting!"
                  : `Poll will close in ${Math.ceil(
                      moment
                        .duration(
                          moment().diff(
                            moment(post.createdAt, "YYYY-MM-DD")
                          )
                        )
                        .asDays()
                    )} days!`}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
}
export default PostMainContent