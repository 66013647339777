import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckInModal from "./CheckInModal";
import {
  useGetAllCheckInListQuery,
  useLazyGetCheckInDetailQuery,
  useAddCheckInMutation,
  useSearchEventParticipantTypesQuery,
  useCheckInAttendeeSuggestionListQuery,
  useCheckInActivityListQuery,
  useCheckInTicketListQuery,
  useGetEventDetailQuery,
  useCheckInStatusMutation,
} from "../../../Services/event-services";
import { Box, CircularProgress, Typography } from "@mui/material";
import CheckInCollapesRow from "./CheckInCollapesRow.jsx";
import ActivityCheckInTable from "./ActivityCheckInTable.jsx";

const CheckInList = ({ eventId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkInModal, setCheckInModal] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [finalSearchText, setFinalSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const [checkInLoader, setCheckInLoader] = useState(false);
  const [loaderUserId, setLoaderUserId] = useState("");
  const [loaderRowId, setLoaderRowId] = useState("");
  const [selectedAttendeeType, setSelectedAttendeeType] = useState("");
  const [selectedTicket, setSelectedTicket] = useState("");
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedEventId, setSelectedEventId] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [actualsearch, setActualsearch] = useState("");
  const [listIndex, setListIndex] = useState(-1);
  const [collapesRowState, setCollapesRowState] = useState({});
  const [eventSourceCheckinLoader, setEventSourceCheckinLoader] =
    useState(false);

  const initialRender = useRef(true);
  const initialLoad = useRef(true);
  const navigate = useNavigate();
  const location = useLocation().search;

  const [checkCheckInStatus] = useCheckInStatusMutation();

  const checkInIdFromUrl = new URLSearchParams(location).get("checkin-id");
  const activityIdFromUrl = new URLSearchParams(location).get("activityId");
  const ticketIdFromUrl = new URLSearchParams(location).get("ticketId");
  const statusFromUrl = new URLSearchParams(location).get("status");
  const attendeeTypeFromUrl = new URLSearchParams(location).get("attendeetype");

  useEffect(() => {
    if (initialRender.current && checkInIdFromUrl) {
      handleUserDetail(checkInIdFromUrl);
      setCheckInModal(true);
      document.body.classList.add("modal-open");
    }
    initialRender.current = false;
  }, [checkInIdFromUrl]);

  //checkin hook
  const [addCheckInHook] = useAddCheckInMutation();

  //get event detail
  const { data: eventDetail } = useGetEventDetailQuery({ event_id: eventId });

  //get All checkin
  const {
    checkInList,
    dataLoader,
    refetch: checkInRefetch,
  } = useGetAllCheckInListQuery(
    {
      event_id: eventId,
      params: {
        page: page + 1,
        limit: rowsPerPage,
        search: finalSearchText,
        filterType: selectedStatus,
        role: selectedAttendeeType,
        activityId: selectedActivity,
        ticketId: selectedTicket,
      },
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        // Determine if it's the first load or if filters/search parameters have changed
        const shouldFetch =
          initialLoad.current ||
          finalSearchText !== "" ||
          selectedStatus !== "" ||
          selectedAttendeeType !== "" ||
          selectedActivity !== "" ||
          selectedTicket !== "";

        return {
          checkInList: data,
          dataLoader: shouldFetch && isFetching,
        };
      },
    }
  );

  //Checkin button loader false
  useEffect(() => {
    if (!dataLoader) {
      setCheckInLoader(false);
    }
  }, [dataLoader, checkInList]);

  let eventSource;
  const initializeEventSource = () => {
    if (eventSource) {
      eventSource.close(); // Close existing connection before starting a new one
    }
    const token = localStorage.getItem("adminidtoken");
    if (!token) {
      console.error("Token not available!");
      return;
    }

    eventSource = new EventSource(
      `${process.env.REACT_APP_API_URL}event/check-in-client-connect-checkin?token=${token}`
    );

    eventSource.onmessage = (event) => {
      try {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const checkinId = params.get("checkin-id");
        setEventSourceCheckinLoader(true);
        checkInRefetch();
        if (checkinId) {
          handleUserDetail(checkinId);
        }
      } catch (error) {
        console.error("Failed to parse event data:", error);
      }
    };

    eventSource.onerror = (error) => {
      if (eventSource.readyState === EventSource.CLOSED) {
        setTimeout(initializeEventSource, 2000); // Retry after 2 seconds
      } else {
        // Handle case when connection drops but doesn't reach CLOSED state
        eventSource.close(); // Forcefully close the connection
        setTimeout(initializeEventSource, 2000); // Reconnect after a delay
      }
    };

    return eventSource;
  };

  useEffect(() => {
    if (typeof EventSource !== "undefined") {
      eventSource = initializeEventSource();

      return () => {
        eventSource?.close(); // Ensure cleanup
      };
    } else {
      setEventSourceCheckinLoader(false);
    }
  }, []);

  //Suggestion list hook
  const { data: suggestionList, refetch: suggestionRefetch } =
    useCheckInAttendeeSuggestionListQuery({
      eventId: eventId,
    });

  //Suggestion list hook
  const { data: ActivityList, refetch: activityFilterRefetch } =
    useCheckInActivityListQuery({
      eventId: eventId,
    });

  //Ticket list hook
  const { data: ticketList, refetch: ticketFilterRefetch } =
    useCheckInTicketListQuery({
      eventId: eventId,
    });

  //Get user detail
  const [getCheckInDetail, { isFetching: loader }] =
    useLazyGetCheckInDetailQuery();

  //event participant types hook
  const { data: attendeeTypeFilterList, refetch: attendeeTypeRefetch } =
    useSearchEventParticipantTypesQuery({
      event_id: eventId,
      params: { search: "" },
    });

  //First render
  useEffect(() => {
    attendeeTypeRefetch();
    checkInRefetch();
    activityFilterRefetch();
    suggestionRefetch();
    ticketFilterRefetch();
    if (activityIdFromUrl === eventId) {
      activityIdFromUrl && setSelectedEventId(activityIdFromUrl);
    } else {
      activityIdFromUrl && setSelectedActivity(activityIdFromUrl);
    }
    ticketIdFromUrl && setSelectedTicket(ticketIdFromUrl);
    statusFromUrl && setSelectedStatus(statusFromUrl);
    attendeeTypeFromUrl && setSelectedAttendeeType(attendeeTypeFromUrl);
  }, []);

  const handleUserDetail = async (userId) => {
    const params = {
      eventId: eventId,
      userId: userId,
    };
    try {
      const userDetailRes = await getCheckInDetail({
        params: params,
      });
      setUserDetail(userDetailRes?.data?.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        navigate(`/events/detail?id=${eventId}&tab=checkin`);
        setCheckInModal(false);
        document.body.classList.remove("modal-open");
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //searching
  const handleSearch = (text) => {
    setCheckInLoader(false);
    setPage(0);
    setFinalSearchText(text);
    setSuggestionBox(false);
  };

  // on arrow key up and down when list index change that time this useefect call
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      let currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        let text = currentList.innerText;
        setSearchedText(text);
      }
    }
  }, [listIndex]);

  //Filter status
  const handleFilterStatus = (e) => {
    setCheckInLoader(false);
    setPage(0);
    navigate(
      `/events/detail?id=${eventId}&tab=checkin&activityId=${
        selectedActivity || selectedEventId
      }&ticketId=${selectedTicket}&status=${
        e.target.value
      }&attendeetype=${selectedAttendeeType}`
    );
    setSelectedStatus(e.target.value);
  };


  //Hide modal
  const modalHideShowHandle = () => {
    setCheckInModal((checkInModal) => !checkInModal);
    setUserDetail({});
    navigate(`/events/detail?id=${eventId}&tab=checkin`);
    document.body.classList.remove("modal-open");
  };

  //Checkin button handler
  const handleCheckInButtonClick = async (
    userId,
    status,
    type,
    activityId,
    isPopup
  ) => {
    if (loaderRowId && checkInLoader) return;
    if (loaderUserId && checkInLoader) return;
    setLoaderRowId(activityId);
    setLoaderUserId(userId);
    setCheckInLoader(true);

    let bodyData = {};
    if (type === "event") {
      //Payload for event checkin
      bodyData = {
        checkIn: [
          {
            userId,
            type,
            isCheckIn: status !== "pending" ? false : true,
          },
        ],
      };
    } else if (type === "activity") {
      //Payload for activity checkin
      bodyData = {
        checkIn: [
          {
            userId,
            activityId,
            type,
            isCheckIn: status !== "pending" ? false : true,
          },
        ],
      };
    }
    try {
      const checkInRes = await addCheckInHook({ body: bodyData, id: eventId });
      if (checkInRes.data.status) {
        const bodyDataForCheckIn = {
          checkInStatus: true,
        };
        const checkInStatusResponse = await checkCheckInStatus({
          body: bodyDataForCheckIn,
        });
        setTimeout(() => {
          setCheckInLoader(false);
        }, 2000);
        if (isPopup) {
          handleUserDetail(userId);
        }
      }
    } catch (error) {
      setCheckInLoader(false);
      console.log("Error:", error);
    }
  };

  const handleFilterActivity = (e) => {
    setCheckInLoader(false);
    setPage(0);
    navigate(
      `/events/detail?id=${eventId}&tab=checkin&activityId=${e.target.value}&ticketId=${selectedTicket}&status=${selectedStatus}&attendeetype=${selectedAttendeeType}`
    );
    if (eventId === e.target.value) {
      setSelectedEventId(e.target.value);
      setSelectedActivity("");
    } else {
      setSelectedActivity(e.target.value);
      setSelectedEventId("");
    }
  };

  //Handle ticket filter
  const handleTicketFilter = (e) => {
    setCheckInLoader(false);
    setPage(0);
    navigate(
      `/events/detail?id=${eventId}&tab=checkin&activityId=${
        selectedActivity || selectedEventId
      }&ticketId=${
        e.target.value
      }&status=${selectedStatus}&attendeetype=${selectedAttendeeType}`
    );
    setSelectedTicket(e.target.value);
  };

  //Handle attendee type filter
  const handleAttendeeTypeFilter = (e) => {
    setCheckInLoader(false);
    setPage(0);
    navigate(
      `/events/detail?id=${eventId}&tab=checkin&activityId=${
        selectedActivity || selectedEventId
      }&ticketId=${selectedTicket}&status=${selectedStatus}&attendeetype=${
        e.target.value
      }`
    );
    setSelectedAttendeeType(e.target.value);
  };

  return (
    <>
      <p className="text-end mb-2">
        Checked in {checkInList?.data?.checkInUserCount} of{" "}
        {checkInList?.data?.allUserCount}
      </p>
      <div className="d-flex flex-wrap align-items-center gap-lg-4 gap-2 mb-lg-4 mb-sm-3 mb-2">
        <div className="search-input position-relative mb-0 me-0 flex-grow-1">
          <input
            type="search"
            placeholder="Search by name or email"
            className="w-100"
            value={searchedText}
            onChange={(e) => {
              setSearchedText(e.target.value);
              var currentValue;
              if (e.target.value) {
                currentValue = e.target.value;
              } else {
                currentValue = e.target.value;
                setSearchedText(currentValue);
              }
              setActualsearch(currentValue);
              if (
                suggestionList !== undefined &&
                suggestionList?.data?.length > 0
              ) {
                setListIndex(-1);
                setSuggestionBox(true);
                var searchResult = suggestionList?.data?.filter((user) => {
                  return (
                    user.display_name
                      .toLowerCase()
                      .includes(e.target.value.trim().toLowerCase()) ||
                    user["Preferred Email"]
                      .toLowerCase()
                      .includes(e.target.value.trim().toLowerCase())
                  );
                });
                setSuggestions(searchResult);
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSearch(e.target.value);
              } else if (e.key === "ArrowDown") {
                const listLength =
                  suggestions.length > 15 ? 15 : suggestions.length;
                if (listIndex < listLength - 1) {
                  setListIndex(listIndex + 1);
                } else if (listIndex === listLength - 1) {
                  setListIndex(-1);
                  setSearchedText(actualsearch);
                }
              } else if (e.key === "ArrowUp") {
                e.preventDefault();
                const listLength =
                  suggestions.length > 15 ? 15 : suggestions.length;
                if (listIndex === 0) {
                  setListIndex(-1);
                  setSearchedText(actualsearch);
                } else if (listIndex === -1) {
                  setListIndex(listLength - 1);
                } else {
                  setListIndex(listIndex - 1);
                }
              }
            }}
            onBlur={() => setSuggestionBox(false)}
          />
          {suggestionBox &&
            suggestions.length > 0 &&
            searchedText.length > 0 && (
              <div className="suggestion-box">
                <ul id="suggestion-list">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onMouseDown={() => handleSearch(suggestion.display_name)}
                      className={index === listIndex ? "selected-active" : ""}
                    >
                      {searchedText !== null &&
                      searchedText.length > 0 &&
                      suggestion.display_name
                        .toLowerCase()
                        .startsWith(searchedText.toLowerCase()) ? (
                        <>
                          <b>
                            {suggestion.display_name.slice(
                              0,
                              searchedText.length
                            )}
                          </b>
                          {suggestion.display_name &&
                            suggestion.display_name.slice(
                              searchedText.length,
                              suggestion.display_name.length
                            )}{" "}
                        </>
                      ) : (
                        suggestion.display_name
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          <i
            className="fa fa-search"
            onClick={() => handleSearch(searchedText)}
          ></i>
        </div>

        <div className="uic-fl-btn d-flex align-items-center flex-md-grow-0 flex-grow-1">
          <div className="sort-by-mn-title flex-shrink-0">Filter by : </div>
          <div className="w-100">
            <select
              className="select-min-field"
              value={selectedActivity || selectedEventId || ""}
              onChange={handleFilterActivity}
            >
              <option value="">Check-in desk</option>
              <option value={eventDetail?.data?._id}>
                {eventDetail?.data?.title}
              </option>
              {ActivityList?.data?.map((activity) => (
                <option key={activity._id} value={activity._id}>
                  {activity.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex-md-grow-0 flex-grow-1 w-fit">
          <select
            className="select-min-field"
            value={selectedTicket}
            onChange={handleTicketFilter}
          >
            <option value="">Ticket</option>
            {ticketList?.data?.map((ticket) => (
              <option key={ticket._id} value={ticket._id}>
                {ticket.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex-md-grow-0 flex-grow-1 w-fit">
          <select
            value={selectedStatus}
            onChange={handleFilterStatus}
            className="select-min-field"
          >
            <option value="">Check-in status</option>
            <option value="checkIn">Check In</option>
            <option value="pending">Pending</option>
          </select>
        </div>
        <div className="flex-md-grow-0 flex-grow-1 w-fit">
          <select
            className="select-min-field"
            value={selectedAttendeeType}
            onChange={handleAttendeeTypeFilter}
          >
            <option value="">Attendee type</option>
            {attendeeTypeFilterList?.list
              ?.filter((valid) => valid.role !== "Staff")
              .map((userType) => (
                <option key={userType._id} value={userType._id}>
                  {userType.role}
                </option>
              ))}
          </select>
        </div>
      </div>

      <TableContainer sx={{ mt: 3 }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: "nowrap" }} colSpan={2}>
                Name
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                Ticket
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Attendee type</TableCell>
              <TableCell align="right" sx={{ width: "130px" }}>
                Check-ins
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoader && !checkInLoader && !eventSourceCheckinLoader ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "443px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : checkInList?.data?.list?.length > 0 &&
              selectedActivity === "" &&
              selectedEventId === "" ? (
              checkInList?.data?.list?.map((row, index) => {
                return (
                  <CheckInCollapesRow
                    handleUserDetail={() => {
                      handleUserDetail(row?._id);
                    }}
                    setCheckInModal={setCheckInModal}
                    eventId={eventId}
                    handleCheckInButtonClick={handleCheckInButtonClick}
                    loaderUserId={loaderUserId}
                    loaderRowId={loaderRowId}
                    checkInLoader={checkInLoader}
                    rowData={row}
                    key={index}
                    collapesRowState={collapesRowState}
                    setCollapesRowState={setCollapesRowState}
                  />
                );
              })
            ) : checkInList?.data?.list?.length > 0 &&
              (selectedActivity !== "" || selectedEventId !== "") ? (
              checkInList?.data?.list?.map((row, index) => {
                return (
                  <ActivityCheckInTable
                    handleUserDetail={() => {
                      handleUserDetail(row?._id);
                    }}
                    setCheckInModal={setCheckInModal}
                    eventId={eventId}
                    handleCheckInButtonClick={handleCheckInButtonClick}
                    loaderRowId={loaderRowId}
                    loaderUserId={loaderUserId}
                    checkInLoader={checkInLoader}
                    selectedEventId={selectedEventId}
                    rowData={row}
                    key={index}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography
                    align="center"
                    variant="error-text"
                    component={"p"}
                    sx={{
                      minHeight: "443px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No Data Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 200]}
        component="div"
        count={checkInList?.data?.totalAttendee}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {checkInModal && (
        <CheckInModal
          modalHideShowHandle={modalHideShowHandle}
          checkInModal={checkInModal}
          loaderUserId={loaderUserId}
          loaderRowId={loaderRowId}
          checkInLoader={checkInLoader}
          eventId={eventId}
          userDetail={userDetail}
          handleCheckInButtonClick={handleCheckInButtonClick}
          loader={loader}
          eventSourceCheckinLoader={eventSourceCheckinLoader}
        />
      )}
    </>
  );
};

export default CheckInList;
