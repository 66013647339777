import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Services from "../../../Services/auth.service.event";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import frame from "../../../Images/Frame.svg";
import moment from "moment/moment";
import ProfileSelector from "../../AdminCommonComponent/ProfileSelector";
import AlertComponent from "../../../Hooks/AlertComponent";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import ModalPopup from "../../ModalPopup/ModalPopup";
import copyimg from "../../../Images/file-copy-line 4.svg";
import CloneActivityModal from "./CloneActivityModal";
import { Tooltip } from "@mui/material";
import { useCloneActivityMutation } from "../../../Services/activity";
import undo from "../../../Images/undo.svg";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import UserAccessRule from "./UserAccessRule";
import NonAttendeePopUpModal from "./NonAttendeePopUpModal";

const columns = [
  { id: "name", label: "Name" },
  { id: "location", label: "Location" },
  { id: "date", label: "Date" },
  { id: "startTime", label: "Start time" },
  { id: "endTime", label: "End time" },
  { id: "roles", label: "Access" },
  { id: "status", label: "Status" },
  { id: "action", label: "Actions" },
];

//create a object of using given key
function createData(
  name,
  location,
  date,
  startTime,
  endTime,
  roles,
  status,
  action
) {
  return {
    name,
    location,
    date,
    startTime,
    endTime,
    roles,
    status,
    action,
  };
}

//Event Activities module
const Activities = ({ tabInformation, activityId }) => {
  const descriptionRef = useRef(null);
  const editDescriptionRef = useRef(null);
  const emptyObjectErr = {
    name: "",
    shortDescription: "",
    longDescription: "",
    date: "",
    startTime: "",
    endTime: "",
    access: "",
    location: "",
    sessions: "",
    eventId: "",
    photo: "",
    reservedUrl: "",
    reservedLabelForDetail: "",
    reservedLabelForListing: "",
    notifyChanges: false,
    notificationText: "",
    isEndOnNextDate: "",
    membership: "",
    accessPermission: "",
    status: "",
  };
  const emptyObjectModal = {
    name: "",
    shortDescription: "",
    longDescription: "",
    date: "",
    startTime: "",
    endTime: "",
    member: false,
    partner: false,
    speaker: false,
    guest: false,
    location: "",
    sessions: [],
    eventId: "",
    photo: "",
    reserved: false,
    reservedUrl: "",
    reservedLabelForDetail: "",
    reservedLabelForListing: "",
    access: "",
    notifyChanges: "",
    notificationText: "",
    isEndOnNextDate: false,
    scheduleNotify: false,
    scheduleNotifyTime: 0,
    accessRoles: [],
    membership: "",
    accessPermission: "",
    status: "draft",
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setshowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [addEvent, setAddEvent] = useState(emptyObjectModal);
  const [editEvent, setEditEvent] = useState(emptyObjectModal);
  const [addErr, setAddErr] = useState(emptyObjectErr);
  const [editErr, setEditErr] = useState(emptyObjectErr);
  const [selectedId, setSelectedId] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [deleteName, setDeleteName] = useState("");

  const [editorStateCreateModalLongDesc, setEditorStateCreateModalLongDesc] =
    useState("");
  const [editorStateEditModalLongDesc, setEditorStateEditModalLongDesc] =
    useState("");
  const [locationList, setLocationList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [eventDetail, setEventDetail] = useState({});
  const [showAddLocationFlag, setShowAddLocationFlag] = useState(false);
  const [showEditLocationFlag, setShowEditLocationFlag] = useState(false);
  const [searchDate, setSearchDate] = useState("");
  const [activityImages, setActivityImages] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const [sortingOption, setSortingOption] = useState("");
  const [allAccessRoles, setAllAccessRoles] = useState([]);
  const scheduleNotifyTimeOptionList = [
    { time: "0", label: "At time of Event" },
    { time: "5", label: "Before 5 minutes" },
    { time: "10", label: "Before 10 minutes" },
    { time: "15", label: "Before 15 minutes" },
    { time: "30", label: "Before 30 minutes" },
    { time: "60", label: "Before 1 hour" },
    { time: "120", label: "Before 2 hour" },
  ];
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchUsers, setSearchUsers] = useState("");
  const [allUser, setAllUser] = useState([]);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [cloning, setCloning] = useState(false);
  const [cloneSuccess, setCloneSuccess] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [cloneMsg, setCloneMsg] = useState("");
  const [cloneId, setCloneId] = useState("");
  const [iconCropedFileData, setIconCropedFileData] = useState();
  const [emails, setEmails] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [nonAttendees, setNonAttendees] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState([]);
  const [showNonAttendeeListPopUp, setShowNonAttendeeListPopUp] =
    useState(false);
  const [apiType, setApiType] = useState("");
  const [modalLoader, setModalLoader] = useState(false);
  const [getAllEventAttendees, setGetAllEventAttendees] = useState([]);
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const [isCheckInAllowed, setIsCheckInAllowed] = useState(false);

  //fetch all existing Session list
  useEffect(() => {
    if (tabInformation === "Activity") {
      setLoader(true);
      getAllLocationApi();
      getAllEventActivityApi();
      getEventDetailApi();
      setSearchDate("");
      setSearchText("");
      getAllActivitiesImagesList();

      getAllPlanApi(1, 100, searchUsers);
      if (activityId) {
        handleEdit(activityId);
      }
    }
  }, [tabInformation, activityId]);
  //clone hook
  const [cloneActivityHook] = useCloneActivityMutation();

  ///get event detail
  const getEventDetailApi = () => {
    Services.getEventById(queryVariable)
      .then((response) => {
        if (response.data.status) {
          setEventDetail(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  async function getAllPlanApi(page, rowsPerPage, searchText, col, sort) {
    let query = `?page=${page}&limit=${rowsPerPage}&search=${searchText}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    try {
      const allUserRes = await Services.getAllAttendeesByEventId(
        queryVariable,
        ""
      );

      setGetAllEventAttendees(allUserRes?.data?.data);
      if (allUserRes) {
        let userList = allUserRes.data.data;
        let userListArr = [];
        if (userList !== undefined && userList.length > 0) {
          userList.filter((user) => {
            var i = userListArr.findIndex(
              (x) => x.value.toString() == user._id.toString()
            );
            if (i <= -1) {
              userListArr.push({
                value: user._id,
                label: `${user.display_name}` + " (" + user.email + ")",
              });
            }
            return null;
          });

          setAllUser(userListArr);
        }
      }
    } catch (error) {}
  }

  const getAllActivitiesImagesList = () => {
    Services.getAllActivitiesImages(queryVariable)
      .then((res) => {
        if (res.data.status) {
          setActivityImages(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //get all Activities list
  const getAllEventActivityApi = (
    isClearSearch,
    col,
    sort,
    selectedStatus,
    searchText,
    selectedDate
  ) => {
    let rolesTypesData;
    Services.searchEventParticipantTypesApi(queryVariable, "")
      .then((res) => {
        rolesTypesData = res.data.list;
        setAllAccessRoles(rolesTypesData);
      })
      .catch((e) => {
        console.log(e);
      });
    let query = "";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    query +=
      searchText !== undefined && searchText !== null && searchText !== ""
        ? `&search=${searchText}`
        : "";
    query +=
      selectedDate !== undefined && selectedDate !== null && selectedDate !== ""
        ? `&selectedDataFilter=${moment(selectedDate, "YYYY-MM-DD").format(
            "MM-DD-YYYY"
          )}`
        : "";
    query +=
      selectedStatus !== undefined &&
      selectedStatus !== null &&
      selectedStatus !== ""
        ? `&filter=${selectedStatus}`
        : "";
    Services.getAllActivitysByEventId(queryVariable, query)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          var data = res.data.data;
          var table = [];
          let allRoles = [];
          let totalUsersCount = 0;
          let rolesArray = [];

          for (var i = 0; i < data.length; i++) {
            if (data[i].roles && data[i].roles.length > 0) {
              const roleIds = data[i].roles.map((role) => role.roleId);
              if (
                rolesTypesData?.every((roleId) => roleIds.includes(roleId._id))
              ) {
                allRoles.push("All");
              } else {
                data[i].roles?.map((role) => {
                  allRoles.push(role.roleName);
                });

                if (allRoles.length === 0) {
                  allRoles.push("-");
                }
              }
            } else {
              allRoles.push("-");
            }
            totalUsersCount = data[i].hasOwnProperty("usersCount")
              ? data[i].usersCount
              : 0;
            rolesArray = [...allRoles];
            if (totalUsersCount !== 0) {
              rolesArray.push(
                totalUsersCount === 1
                  ? `+${totalUsersCount} user`
                  : `+${totalUsersCount} users`
              );
            }
            const roles =
              rolesArray && rolesArray.length > 0 ? rolesArray.join(", ") : "";
            table[i] = createData(
              data[i].name,
              data[i].LocationName ? data[i].LocationName : "",
              data[i].date && data[i].date !== ""
                ? moment(data[i].date, "MM-DD-YYYY").format("MMMM DD, YYYY")
                : "",
              data[i].startTime,
              data[i].endTime,
              roles,
              data[i].status,
              data[i]._id
            );
            allRoles = [];
          }
          setAllEvents(table);

          if (isClearSearch) {
            setSearchText("");
            setSortingOption("-1");
          }
          setRow(table);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  //remove image error
  useEffect(() => {
    if (profileImg) {
      setAddErr({ ...addErr, photo: "" });
      setEditErr({ ...editErr, photo: "" });
    }
  }, [profileImg]);

  //get all location list
  const getAllLocationApi = () => {
    Services.getAllEventLocationsByEventId(queryVariable, "")
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          setLocationList(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  //get session list
  const getAllSessionsByIdApi = (date) => {
    Services.getAllSessionsByDate({
      date: moment(date).format("MM-DD-YYYY"),
      eventId: queryVariable,
    })
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          setSessionList(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleFilterStatus = (e) => {
    setSelectedStatus(e.target.value);
    getAllEventActivityApi(
      false,
      "",
      "",
      e.target.value,
      searchText,
      searchDate
    );
  };

  useEffect(() => {
    if (addEvent.date) {
      setAddEvent({ ...addEvent, sessions: [] });
      getAllSessionsByIdApi(addEvent.date);
    }
  }, [addEvent.date]);

  useEffect(() => {
    if (addEvent.isEndOnNextDate) {
      if (addEvent.date)
        setAddEvent({
          ...addEvent,
          endDate: moment(addEvent.date, "YYYY-MM-DD")
            .add(1, "days")
            .format("YYYY-MM-DD"),
        });
      setAddErr({ ...addErr, endTime: "" });
    } else setAddEvent({ ...addEvent, endDate: "" });
  }, [addEvent.isEndOnNextDate]);

  //call when edit event date state change
  useEffect(() => {
    if (editEvent.date) {
      setAddEvent({ ...editEvent, sessions: [] });
      getAllSessionsByIdApi(editEvent.date);
    }
  }, [editEvent.date]);

  //close create session module
  const handleCloseAddModel = (e) => {
    setAddEvent(emptyObjectModal);
    setEditEvent(emptyObjectModal);
    setAddErr(emptyObjectErr);
    setEditErr(emptyObjectErr);
    setProfileImg("");
    descriptionRef?.current.setContent("");
    handleClearAllStates();
    setEmails([]);
    setNonAttendees([]);
    setInvalidEmail([]);
    setAttendees([]);
  };
  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setSelectedId(value);
    setEditModalLoader(true);
    Services.getEventActivityById(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          let dataAccessRoles = [];
          data.accessRoles &&
            data.accessRoles.map((item) => {
              dataAccessRoles.push(item._id.toString());
            });

          let userData =
            data.userId && data.userId.length > 0 ? data.userId : [];

          setEmails(userData?.map((email) => email["Preferred Email"]));
          setAttendees(userData?.map((email) => email["Preferred Email"]));
          setNonAttendees([]);
          setInvalidEmail([]);
          setSelectedUsers(userData?.map((email) => email["_id"]));
          setEditEvent({
            name: data.name,
            shortDescription: data.shortDescription,
            longDescription: data.longDescription,
            date: data.date
              ? moment(data.date, "MM-DD-YYYY").format("YYYY-MM-DD")
              : "",
            startTime: data.startTime
              ? moment(data.startTime, "hh:mm A").format("HH:mm")
              : "",
            endTime: data.endTime
              ? moment(data.endTime, "hh:mm A").format("HH:mm")
              : "",
            member: data.member,
            partner: data.partner,
            speaker: data.speaker,
            guest: data.guest,
            location: data.location?._id ?? "",
            sessions: data.session,
            reserved: data.reserved,
            reservedUrl: data.reserved_URL,
            reservedLabelForListing: data.reservedLabelForListing,
            reservedLabelForDetail: data.reservedLabelForDetail,
            notifyChanges: false,
            notificationText: "",
            isEndOnNextDate: data.isEndOrNextDate,
            endDate: moment(data.endDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
            scheduleNotify: data?.scheduleNotify ?? false,
            scheduleNotifyTime: data?.scheduleNotify
              ? data?.scheduleNotifyTime ?? ""
              : "0",
            accessRoles: dataAccessRoles,
            status: data?.status,
          });
          setProfileImg(data.icon);
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };
  //search using text
  const handleSearch = (text, date) => {
    setSearchText(text);
    getAllEventActivityApi(false, "", "", selectedStatus, text, searchDate);
  };

  //Handle selected date
  const handleSelectedDate = (date) => {
    setSearchDate(date);
    getAllEventActivityApi(false, "", "", selectedStatus, searchText, date);
  };
  //deletion session api call
  const handleDelete = () => {
    Services.deleteEventActivity(deleteId)
      .then((res) => {
        if (res.data.status) {
          getAllEventActivityApi(true, "", "");
          setShowDeleteModal(false);
        }
        setshowAlert(true);
        setAlertMsg(res.data.message);
        setTimeout(() => {
          setshowAlert(false);
          setAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // function for session clone api
  const handleCloneActivity = async () => {
    setCloning(true);
    try {
      const cloneRes = await cloneActivityHook({ body: { id: cloneId } });
      if (cloneRes?.data?.status) {
        getAllEventActivityApi();
        setCloning(false);
        setCloneSuccess(true);
        setCloneMsg(cloneRes.data.message);
        setTimeout(() => {
          setCloneSuccess(false);
          setCloneMsg("");
        }, 3000);
      } else {
        setCloning(false);
        setCloneSuccess(true);
        setCloneMsg(cloneRes.data.message);
        setTimeout(() => {
          setCloneSuccess(false);
          setCloneMsg("");
        }, 3000);
      }
    } catch (error) {
      console.log("Error clone session:", error);
    }
  };

  //sorting function
  const handleSort = (col, direc) => {
    setLoader(true);
    getAllEventActivityApi(false, col, direc, selectedStatus, "", searchDate);
  };

  //handle onchange for create session
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    if (e.target.name === "reserved") {
      if (e.target.checked)
        setAddEvent({ ...addEvent, [e.target.name]: e.target.checked });
      else
        setAddEvent({
          ...addEvent,
          [e.target.name]: e.target.checked,
          reservedUrl: "",
          reservedLabelForDetail: "",
          reservedLabelForListing: "",
        });
    } else if (e.target.name === "date") {
      if (addEvent.isEndOnNextDate) {
        const nextDay = moment(e.target.value, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
        setAddEvent({
          ...addEvent,
          [e.target.name]: e.target.value,
          endDate: nextDay,
        });
      } else setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    }

    if (
      e.target.name !== "reserved" &&
      e.target.name !== "date" &&
      e.target.name !== "accessRoles"
    ) {
      setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    }

    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "description") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "room") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "date") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "startTime") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "endTime") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "shortDescription") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "location") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "status") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "reservedUrl") {
      if (e.target.value.trim().length > 0) {
        let url;
        try {
          url = new URL(e.target.value);
        } catch (error) {
          setAddErr({
            ...addErr,
            [e.target.name]: "Invalid url",
          });
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          setAddErr({ ...addErr, [e.target.name]: "" });
        }
      }
    } else if (
      (e.target.value === "reservedLabelForDetail" ||
        e.target.value === "reservedLabelForListing") &&
      e.target.value.length > 0
    ) {
      setAddErr({ ...addErr, [e.target.name]: "" });
    } else if (e.target.name === "accessRoles" && e.target.checked) {
      setAddEvent({
        ...addEvent,
        [e.target.name]: [
          ...addEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
          e.target.value,
        ],
      });
      setAddErr({ ...addErr, access: "" });
    } else if (e.target.name === "accessRoles" && !e.target.checked) {
      setAddEvent({
        ...addEvent,
        [e.target.name]: [
          ...addEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
        ],
      });
      setAddErr({ ...addErr, access: "" });
    }
  };

  //Create session validation and api call
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //Create session validation and api call
  const checkEditDesciptionValue = (state) => {
    var text = editDescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //validation and api call on create session
  const handleCreate = async () => {
    var status = true;
    var temp = {};
    if (addEvent.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (addEvent.date.trim().length === 0) {
      temp = { ...temp, date: "Please select the date!" };
      status = false;
    }
    if (addEvent.sessions.length === 0) {
      if (addEvent.startTime.length === 0) {
        temp = { ...temp, startTime: "Select start time!" };
        status = false;
      }
      if (addEvent.endTime.length === 0) {
        temp = { ...temp, endTime: "Select end time!" };
        status = false;
      }

      if (
        addEvent.date.length > 0 &&
        addEvent.startTime.length > 0 &&
        addEvent.endTime.length > 0
      ) {
      }
    }

    if (!checkDesciptionValue(editorStateCreateModalLongDesc)) {
      temp = { ...temp, longDescription: "Enter a long description!" };
      status = false;
    }
    if (addEvent.shortDescription.trim().length === 0) {
      temp = { ...temp, shortDescription: "Enter a short description!" };
      status = false;
    }

    if (addEvent.status.trim().length === 0) {
      temp = { ...temp, status: "Select status!" };
      status = false;
    }

    if (addEvent.reserved && addEvent.reservedUrl.length > 0) {
      let url;
      try {
        url = new URL(addEvent.reservedUrl);
      } catch (error) {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }
      if (url && (url.protocol === "http:" || url.protocol === "https:")) {
      } else {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }
    } else if (addEvent.reserved && addEvent.reservedUrl.length === 0) {
      temp = { ...temp, reservedUrl: "Enter url!" };
      status = false;
    }
    if (addEvent.reserved && addEvent.reservedLabelForDetail.length === 0) {
      temp = {
        ...temp,
        reservedLabelForDetail: "Enter button label for detail!",
      };
      status = false;
    }
    if (addEvent.reserved && addEvent.reservedLabelForListing.length === 0) {
      temp = {
        ...temp,
        reservedLabelForListing: "Enter button label for listing!",
      };
      status = false;
    }

    if (!profileImg) {
      temp = { ...temp, photo: "Upload an activity icon!" };
      status = false;
    }
    if (addErr.reservedUrl && addErr.reservedUrl.length > 0) status = false;
    if (addEvent.accessRoles && addEvent.accessRoles.length === 0) {
      temp = { ...temp, access: "Select access!" };
      status = false;
    }

    if (addEvent.status.length > 0 && addEvent.status === "draft") {
      temp = { ...temp, status: "Select status!" };
      status = false;
    }

    setEditorStateCreateModalLongDesc(descriptionRef.current.getContent());

    if (status) {
      if (nonAttendees.length > 0 && !showNonAttendeeListPopUp) {
        setShowNonAttendeeListPopUp(true);
        setApiType("create");
      } else {
        setCreateInprocess(true);
        createApiCall();
      }
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setAddErr(temp);
    }
  };

  // handel save draft event
  const handleSaveDraft = () => {
    if (addEvent.name.trim().length !== 0) {
      setAddEvent({ ...addEvent, status: "draft" });
      if (nonAttendees.length > 0 && !showNonAttendeeListPopUp) {
        setShowNonAttendeeListPopUp(true);
        setApiType("draft");
      } else {
        setSaveInProcess(true);
        createApiCall();
      }
    } else {
      setAddErr({ ...addErr, name: "Enter name!" });
    }
  };

  // handel save draft event
  const handleEditSaveDraft = () => {
    if (editEvent.name.trim().length !== 0) {
      setEditEvent({ ...editEvent, status: "draft" });
      if (nonAttendees.length > 0 && !showNonAttendeeListPopUp) {
        setShowNonAttendeeListPopUp(true);
        setApiType("editDraft");
      } else {
        setSaveInProcess(true);
        editApiCall();
      }
    } else {
      setEditErr({ ...editErr, name: "Enter name!" });
    }
  };

  //Api calll on create session
  const createApiCall = (longDescriptionHtml) => {
    setModalLoader(true);

    setCreateInprocess(true);
    const formdata = new FormData();
    formdata.append("name", addEvent.name);

    if (typeof profileImg === "object")
      formdata.append("event_icon", profileImg);
    else formdata.append("exist_icon", profileImg);
    addEvent.scheduleNotify &&
      formdata.append("scheduleNotify", addEvent.scheduleNotify);
    addEvent.scheduleNotify !== undefined &&
      addEvent.scheduleNotify !== null &&
      addEvent.scheduleNotifyTime &&
      formdata.append(
        "scheduleNotifyTime",
        addEvent.scheduleNotify ? addEvent.scheduleNotifyTime : ""
      );

    addEvent.shortDescription &&
      formdata.append("shortDescription", addEvent.shortDescription);
    descriptionRef.current.getContent() &&
      formdata.append("longDescription", descriptionRef.current.getContent());
    addEvent.date &&
      formdata.append("date", moment(addEvent.date).format("MM-DD-YYYY"));

    if (addEvent.status) formdata.append("status", addEvent.status);

    if (addEvent.sessions.length === 0) {
      addEvent.startTime &&
        formdata.append(
          "startTime",
          moment(addEvent.startTime, "hh:mm A").format("hh:mm A")
        );
      addEvent.endTime &&
        formdata.append(
          "endTime",
          moment(addEvent.endTime, "hh:mm A").format("hh:mm A")
        );
      if (addEvent.isEndOnNextDate) {
        addEvent.isEndOnNextDate && formdata.append("isEndOrNextDate", true);
        addEvent.endDate &&
          formdata.append(
            "endDate",
            moment(addEvent.endDate).format("MM-DD-YYYY")
          );
      }
    } else {
      addEvent.startTime && formdata.append("startTime", "");
      addEvent.endTime && formdata.append("endTime", "");
      addEvent.isEndOnNextDate && formdata.append("isEndOrNextDate", false);
      addEvent.endDate && formdata.append("endDate", "");
    }
    addEvent.location && formdata.append("location", addEvent.location);

    if (addEvent.sessions) {
      for (let key in addEvent.sessions) {
        formdata.append(`session[${key}]`, addEvent.sessions[key]._id);
      }
    }

    formdata.append("event", queryVariable);
    addEvent.reserved && formdata.append("reserved", addEvent.reserved);
    addEvent.reservedUrl &&
      formdata.append("reserved_URL", addEvent.reservedUrl);
    addEvent.reservedLabelForListing &&
      formdata.append(
        "reservedLabelForListing",
        addEvent.reservedLabelForListing
      );
    addEvent.reservedLabelForDetail &&
      formdata.append(
        "reservedLabelForDetail",
        addEvent.reservedLabelForDetail
      );
    addEvent.accessRoles &&
      addEvent.accessRoles.length > 0 &&
      addEvent.accessRoles.map((role, index) => {
        formdata.append(`accessRoles[${index}]`, role);
      });
    if (addEvent.status !== "draft") {
      addEvent.accessRoles.length == 0 && formdata.append(`accessRoles[]`, []);
    }

    selectedUsers.length > 0 &&
      selectedUsers.map((id, index) => {
        formdata.append(`userId[${index}]`, id);
      });
    if (isCheckInAllowed !== undefined) {
      formdata.append("isCheckInAllow", isCheckInAllowed);
    }
    Services.createEventActivity(formdata)
      .then((res) => {
        setCreateInprocess(false);
        setSaveInProcess(false);

        if (res.data.status) {
          setModalLoader(false);
          getAllActivitiesImagesList();
          setShowNonAttendeeListPopUp(false);
          setshowAlert(true);
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 3000);
          $("#AddActivity").modal("hide");
          setEditorStateCreateModalLongDesc("");
          getAllEventActivityApi(false);
          setProfileImg("");
          setAddEvent(emptyObjectModal);
          handleClearAllStates();
        } else {
          setModalLoader(false);
          setShowNonAttendeeListPopUp(false);
          handleClearAllStates();
          setShowAlertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
        }
      })
      .catch((e) => {
        setSaveInProcess(false);
        setCreateInprocess(false);
        console.log(e);
      });
    setAddErr(emptyObjectErr);
  };

  //on change for Edit
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    if (e.target.name === "reserved") {
      if (e.target.checked)
        setEditEvent({ ...editEvent, [e.target.name]: e.target.checked });
      else
        setEditEvent({
          ...editEvent,
          [e.target.name]: e.target.checked,
          reservedUrl: "",
          reservedLabelForDetail: "",
          reservedLabelForListing: "",
        });
    } else if (e.target.name === "date") {
      if (editEvent.isEndOnNextDate) {
        const nextDay = moment(e.target.value, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
        setEditEvent({
          ...editEvent,
          sessions: [],
          date: e.target.value,
          endDate: nextDay,
        });
      } else {
        setEditEvent({
          ...editEvent,
          sessions: [],
          date: e.target.value,
        });
      }
    }

    if (
      e.target.name !== "reserved" &&
      e.target.name !== "date" &&
      e.target.name !== "accessRoles"
    ) {
      setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    }

    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "shortDescription") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "room") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "date") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "startTime") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "endTime") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "location") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "status") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "reservedUrl") {
      if (e.target.value.trim().length > 0) {
        let url;
        try {
          url = new URL(e.target.value);
        } catch (error) {
          setEditErr({
            ...editErr,
            [e.target.name]: "Invalid url",
          });
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          setEditErr({ ...editErr, [e.target.name]: "" });
        }
      }
    } else if (
      (e.target.value === "reservedLabelForDetail" ||
        e.target.value === "reservedLabelForListing") &&
      e.target.value.length > 0
    ) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    } else if (e.target.name === "accessRoles" && e.target.checked) {
      setEditEvent({
        ...editEvent,
        [e.target.name]: [
          ...editEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
          e.target.value,
        ],
      });
      setAddErr({ ...editErr, access: "" });
    } else if (e.target.name === "accessRoles" && !e.target.checked) {
      setEditEvent({
        ...editEvent,
        [e.target.name]: [
          ...editEvent.accessRoles.filter((value) => {
            if (value !== e.target.value) return value;
          }),
        ],
      });
      setAddErr({ ...editErr, access: "" });
    }

    if (e.target.name === "date" && e.target.value.length > 0) {
    }

    if (e.target.name === "startTime" && e.target.value.length > 0) {
    }

    if (
      e.target.name === "startTime" &&
      e.target.value.length > 0 &&
      !editEvent.isEndOnNextDate
    ) {
    }

    if (
      e.target.name === "endTime" &&
      e.target.value.length > 0 &&
      !editEvent.isEndOnNextDate
    ) {
    }
  };

  //Called when user click on update changes
  const handleEditSubmit = async () => {
    var status = true;
    var temp = {};
    if (editEvent.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (!checkEditDesciptionValue(editorStateEditModalLongDesc)) {
      temp = { ...temp, longDescription: "Enter a long description!" };
      status = false;
    }
    if (editEvent.shortDescription.trim().length === 0) {
      temp = { ...temp, shortDescription: "Enter a short description!" };
      status = false;
    }

    if (editEvent.date.length === 0) {
      temp = { ...temp, date: "Select date!" };
      status = false;
    }

    if (editEvent.status.length === 0) {
      temp = { ...temp, date: "Select Status!" };
      status = false;
    }

    if (editEvent.sessions.length === 0) {
      if (editEvent.startTime.length === 0) {
        temp = { ...temp, startTime: "Select start time!" };
        status = false;
      }
      if (editEvent.endTime.length === 0) {
        temp = { ...temp, endTime: "Select end time!" };
        status = false;
      }

      if (
        editEvent.date.length > 0 &&
        editEvent.startTime.length > 0 &&
        editEvent.endTime.length > 0
      ) {
      }
    }
    if (!profileImg) {
      temp = { ...temp, photo: "Upload an activity icon!" };
      status = false;
    }

    if (editEvent.accessRoles && editEvent.accessRoles.length === 0) {
      temp = { ...temp, access: "Select access!" };
      status = false;
    }

    if (editEvent.reserved && editEvent.reservedUrl.length > 0) {
      let url;
      try {
        url = new URL(editEvent.reservedUrl);
      } catch (error) {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }
      if (url && (url.protocol === "http:" || url.protocol === "https:")) {
      } else {
        temp = { ...temp, reservedUrl: "Invalid url!" };
        status = false;
      }
    } else if (editEvent.reserved && editEvent.reservedUrl.length === 0) {
      temp = { ...temp, reservedUrl: "Enter url!" };
      status = false;
    }
    if (editEvent.reserved && editEvent.reservedLabelForDetail.length === 0) {
      temp = {
        ...temp,
        reservedLabelForDetail: "Enter button label for detail!",
      };
      status = false;
    }
    if (editEvent.reserved && editEvent.reservedLabelForListing.length === 0) {
      temp = {
        ...temp,
        reservedLabelForListing: "Enter button label for listing!",
      };
      status = false;
    }
    if (
      editEvent.notifyChanges &&
      editEvent.notificationText.trim().length === 0
    ) {
      temp = { ...temp, notificationText: "Enter notification text!" };
      status = false;
    }

    if (editEvent.status === "draft") {
      temp = { ...temp, status: "Select status!" };
      status = false;
    }

    if (status) {
      if (nonAttendees.length > 0 && !showNonAttendeeListPopUp) {
        setShowNonAttendeeListPopUp(true);
        setApiType("edit");
      } else {
        setEditInprocess(true);
        editApiCall();
      }
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.querySelectorAll(
          `[name="${emptyKeys[0]}"]`
        );
        if (inputElement) {
          inputElement[1] && inputElement[1].focus(); // Focus the input element
        }
      }
      setEditErr(temp);
    }
  };

  //Edit activity api call
  const editApiCall = async (longDescriptionHtml) => {
    console.log("editEvent.scheduleNotify", editEvent.scheduleNotify);
    setModalLoader(true);
    const formdata = new FormData();
    formdata.append("name", editEvent.name);
    editEvent.shortDescription &&
      formdata.append("shortDescription", editEvent.shortDescription);
    editDescriptionRef.current.getContent() &&
      formdata.append(
        "longDescription",
        editDescriptionRef.current.getContent()
      );
    formdata.append("scheduleNotify", editEvent.scheduleNotify);
    editEvent.status && formdata.append("status", editEvent.status);
    editEvent.scheduleNotify !== undefined &&
      editEvent.scheduleNotify !== null &&
      formdata.append(
        "scheduleNotifyTime",
        editEvent.scheduleNotify ? editEvent.scheduleNotifyTime : ""
      );
    editEvent.date &&
      formdata.append("date", moment(editEvent.date).format("MM-DD-YYYY"));
    if (editEvent.sessions.length === 0) {
      editEvent.startTime &&
        formdata.append(
          "startTime",
          moment(editEvent.startTime, "hh:mm A").format("hh:mm A")
        );
      editEvent.endTime &&
        formdata.append(
          "endTime",
          moment(editEvent.endTime, "hh:mm A").format("hh:mm A")
        );
      if (editEvent.isEndOnNextDate) {
        editEvent.isEndOnNextDate && formdata.append("isEndOrNextDate", true);
        editEvent.endDate &&
          formdata.append(
            "endDate",
            moment(editEvent.endDate).format("MM-DD-YYYY")
          );
      } else {
        editEvent.isEndOnNextDate !== undefined &&
          editEvent.isEndOnNextDate !== null &&
          formdata.append("isEndOrNextDate", false);
        formdata.append("endDate", "");
      }
    } else {
      for (let key in editEvent.sessions) {
        formdata.append(`session[${key}]`, editEvent.sessions[key]._id);
      }
      formdata.append("startTime", "");
      formdata.append("endTime", "");
      formdata.append("isEndOrNextDate", false);
      formdata.append("endDate", "");
    }
    editEvent.location && formdata.append("location", editEvent.location);
    if (
      Array.isArray(editEvent.accessRoles) &&
      editEvent.accessRoles.length > 0
    ) {
      const rolesArray = Array.from(editEvent.accessRoles || []);
      let n = 0;
      rolesArray.map((role) => {
        formdata.append(`accessRoles[${n}]`, role);
        n++;
      });
    }

    (editEvent.accessRoles === undefined ||
      editEvent.accessRoles === null ||
      editEvent.accessRoles.length == 0) &&
      formdata.append(`accessRoles[]`, []);

    selectedUsers.length > 0 &&
      selectedUsers.map((id, index) => {
        formdata.append(`userId[${index}]`, id);
      });

    formdata.append("reserved", editEvent.reserved);
    editEvent.reservedUrl &&
      formdata.append("reserved_URL", editEvent.reservedUrl);
    editEvent.reservedLabelForListing &&
      formdata.append(
        "reservedLabelForListing",
        editEvent.reservedLabelForListing
      );
    editEvent.reservedLabelForDetail &&
      formdata.append(
        "reservedLabelForDetail",
        editEvent.reservedLabelForDetail
      );
    editEvent.notifyChanges &&
      formdata.append("notifyChanges", editEvent.notifyChanges);
    editEvent.notificationText &&
      formdata.append("notifyChangeText", editEvent.notificationText);
    if (typeof profileImg === "object")
      formdata.append("event_icon", profileImg);
    else formdata.append("exist_icon", profileImg);

    if (isCheckInAllowed !== undefined) {
      formdata.append("isCheckInAllow", isCheckInAllowed);
    }
    Services.editEventActivity({
      id: selectedId,
      data: formdata,
    })
      .then((res) => {
        if (res.data.status) {
          setModalLoader(false);
          setShowNonAttendeeListPopUp(false);
          setSaveInProcess(false);
          getAllActivitiesImagesList();
          setshowAlert(true);
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 3000);
          $("#editActivity").modal("hide");
          getAllEventActivityApi(
            false,
            "",
            "",
            selectedStatus,
            searchText,
            searchDate
          );
          setEditInprocess(false);
          handleClearAllStates();
        } else {
          setModalLoader(false);
          setShowNonAttendeeListPopUp(false);
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          handleClearAllStates();
        }
        setEditInprocess(false);
      })
      .catch((e) => {
        setSaveInProcess(false);
        console.log(e);
        setEditInprocess(false);
      });
    setEditErr(emptyObjectErr);
  };

  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#AddActivity").hasClass("show")) {
        $("#AddActivity").modal("show");
      }

      if ($("#editActivity").hasClass("show")) {
        $("#editActivity").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#AddActivity").hasClass("show")) {
        $("#AddActivity").modal("hide");
      }

      if ($("#editActivity").hasClass("show")) {
        $("#editActivity").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowNonAttendeeListPopUp(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      {modalOpen && (
        <div className="desk-globle-model-box globle-model-box cstm-rpp-main cstm-create-speaker-cropper">
          <div className="desk-globle-model-box-inner edit-profile-cstm">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div className="cstm-rpp-title">
                  Replace Profile Picture
                  <span
                    className="cstm-rpp-close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    ✖
                  </span>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}

      <div className="uic-fl mt-0 d-flex flex-wrap gap-xl-4 gap-3 align-items-center mb-lg-4 mb-3">
        <div className="search-input position-relative mb-0 me-0 flex-grow-1 flex-shrink-0">
          <input
            type="search"
            placeholder="Search activities"
            className="w-100"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSearch(e.target.value, searchDate);
              }
            }}
          />
          <i
            className="fa fa-search"
            onClick={() => handleSearch(searchText, searchDate)}
          ></i>
        </div>
        <div className="uic-fl-btn d-flex align-items-center flex-xl-grow-0 flex-grow-1">
          <input
            type="date"
            placeholder="Search activities"
            className="w-100"
            value={searchDate}
            onChange={(e) => {
              handleSelectedDate(e.target.value);
            }}
          />
        </div>
        <div className="uic-fl-btn">
          <div className="sort-by-mn-title">Filter by : </div>
          <div className="cstm-select-ap">
            <select value={selectedStatus} onChange={handleFilterStatus}>
              <option value="">Select Status</option>
              <option value="draft">Draft</option>
              <option value="paused">Paused</option>
              <option value="published">Published </option>
            </select>
          </div>
        </div>
        <div className="uic-title-rt-only mt-0 position-relative end-0 ms-auto r-0">
          <button
            className="btn"
            data-toggle="modal"
            data-target="#AddActivity"
            onClick={(e) => {
              handleCloseAddModel(e);
              setSortingOption("-1");
            }}
          >
            Create Activity
          </button>
        </div>
      </div>
      {showAlert && <div className="alert alert-info">{alertMsg}</div>}
      <Paper className="uic-tb-mn">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHead className="uic-table-th">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="uic-th-td w-10"
                  >
                    {"action" !== column.id &&
                    "roles" !== column.id &&
                    "startTime" !== column.id &&
                    "endTime" !== column.id ? (
                      <>
                        <span className="custom-sorting">
                          <i
                            className="fa fa-angle-down"
                            onClick={(e) => {
                              handleSort(column.id, "Dec");
                            }}
                          ></i>
                          <i
                            className="fa fa-angle-up"
                            onClick={(e) => {
                              handleSort(column.id, "Asc");
                            }}
                          ></i>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loader ? (
              <div className="cstm-data-load">
                <img src={Loader} />{" "}
              </div>
            ) : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .length > 0 ? (
              <TableBody className="uic-table-td">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        className="uic-th-td w-20"
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id === "name" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              <a
                                data-toggle="modal"
                                data-target="#editActivity"
                                onClick={() => {
                                  handleEdit(row["action"]);
                                }}
                                className="cstm-view-users-count"
                              >
                                {value}
                              </a>
                            </TableCell>
                          ) : column.id === "location" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value}
                            </TableCell>
                          ) : [
                              "date",
                              "startTime",
                              "endTime",
                              "roles",
                              "status",
                            ].includes(column.id) ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value && value.length > 0 ? value : "-"}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={column.id}
                              className="uic-th-td uic-table-icon w-17"
                            >
                              <Tooltip title="Clone" arrow>
                                <a
                                  data-toggle="modal"
                                  data-target="#cloneActivity"
                                  onClick={() => {
                                    setCloneId(value);
                                  }}
                                >
                                  <img src={copyimg} />
                                </a>
                              </Tooltip>
                              <a
                                data-toggle="modal"
                                data-target="#editActivity"
                                onClick={() => {
                                  setEditEvent(emptyObjectModal);
                                  setEditErr(emptyObjectErr);
                                  handleEdit(value);
                                }}
                              >
                                <img src={editIcon} />
                              </a>
                              {row["name"] !== "others" && (
                                <a
                                  data-toggle="modal"
                                  data-target="#DeleteFromActivity"
                                  onClick={() => {
                                    setDeleteId(value);
                                    setDeleteName(row.name);
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  <img src={imgDelete} />
                                </a>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <span className="no-data-found">No data found</span>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* create modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="AddActivity"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Activity</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#AddActivity").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEvent.name}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Short description*</div>
                <div className="form-group-ct">
                  <textarea
                    placeholder="Enter short description"
                    name="shortDescription"
                    onChange={handleAddChange}
                    value={addEvent.shortDescription}
                    maxLength={80}
                  />
                  <span className="text-limitaion">
                    The maximum character limit is 80.
                  </span>
                  <span className="error">{addErr.shortDescription}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Long description*</div>
                <div className="form-group-ct">
                  <div className="cstm-vd cstm-code-addt">
                    <TinyEditor
                      valueRef={descriptionRef}
                      value={editorStateCreateModalLongDesc}
                      handleEditorChange={(value, editor) => {
                        setAddErr({ ...addErr, longDescription: "" });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{addErr.longDescription}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Date*</div>
                <div className="form-group-ct">
                  <input
                    type="date"
                    placeholder="dd-mm-yyyy"
                    name="date"
                    onChange={handleAddChange}
                    min={moment(eventDetail.startDate, "MM-DD-YYYY").format(
                      "YYYY-MM-DD"
                    )}
                    max={moment(eventDetail.endDate, "MM-DD-YYYY").format(
                      "YYYY-MM-DD"
                    )}
                    value={addEvent.date}
                  />
                  <span className="error">{addErr.date}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Sessions</div>
                <div className="form-group-ct speakers-only">
                  <Multiselect
                    placeholder="Select sessions"
                    displayValue="title"
                    options={sessionList}
                    selectedValues={addEvent.sessions}
                    onSelect={(selectedlist) => {
                      setAddEvent({
                        ...addEvent,
                        sessions: selectedlist,
                        startTime: "",
                        endTime: "",
                        endDate: "",
                        isEndOnNextDate: false,
                      });
                      setAddErr({
                        ...addErr,
                        sessions: "",
                        endTime: "",
                        startTime: "",
                      });
                      if (selectedlist.length > 0)
                        setShowAddLocationFlag(false);
                      else setShowAddLocationFlag(true);
                    }}
                    onRemove={(selectedlist) => {
                      setAddEvent({
                        ...addEvent,
                        sessions: selectedlist,
                        startTime: "",
                        endTime: "",
                        endDate: "",
                        isEndOnNextDate: false,
                      });
                      if (selectedlist.length > 0)
                        setShowAddLocationFlag(false);
                      else setShowAddLocationFlag(true);
                    }}
                  />
                  <span className="error">{addErr.sessions}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Location</div>
                <div className="form-group-ct">
                  <select
                    onChange={handleAddChange}
                    value={addEvent.location}
                    name="location"
                  >
                    <option value="">Select a location</option>
                    {locationList.map((location) => (
                      <option
                        key={location._id}
                        value={location._id}
                        data-address={location.name}
                      >
                        {location.name.slice(0, 70)}
                        {location.name.length > 70 ? "..." : ""}
                      </option>
                    ))}
                  </select>
                  <span className="error">{addErr.location}</span>
                </div>
              </div>
              {addEvent.sessions.length === 0 && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Start time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        placeholder="Select start time"
                        name="startTime"
                        onChange={handleAddChange}
                        value={addEvent.startTime}
                      />
                      <span className="error">{addErr.startTime}</span>
                    </div>
                  </div>
                  <div className="form-group clt-checkbox">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) =>
                          setAddEvent({
                            ...addEvent,
                            isEndOnNextDate: e.target.checked,
                          })
                        }
                        name="isEndOnNextDate"
                        checked={addEvent.isEndOnNextDate}
                      />
                      Activity ends on next day?
                    </label>
                  </div>

                  {addEvent.isEndOnNextDate && (
                    <div className="form-group">
                      <div className="form-group-label">End date</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          placeholder="dd-mm-yyyy"
                          name="endDate"
                          disabled
                          value={addEvent.endDate}
                        />
                        <span className="error">{addErr.endDate}</span>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <div className="form-group-label">End time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        placeholder="Select end time"
                        name="endTime"
                        onChange={handleAddChange}
                        value={addEvent.endTime}
                      />
                      <span className="error">{addErr.endTime}</span>
                    </div>
                  </div>
                </>
              )}
              <div className="cstm-add-video-title-sub">
                Select participants*
              </div>
              <div className="form-group mb-0">
                <div className="form-group-ctst">
                  {allAccessRoles.map((a) => {
                    return (
                      <>
                        <label className="subcat-addvideo">
                          <input
                            type="checkbox"
                            name="accessRoles"
                            value={a._id.toString()}
                            onChange={handleAddChange}
                            checked={addEvent.accessRoles.includes(
                              a._id.toString()
                            )}
                          />
                          {a.role}
                        </label>
                      </>
                    );
                  })}
                  <span className="error acces-error-only">
                    {addErr.access}
                  </span>
                </div>
              </div>

              <div class="cstm-channel-type-title">Select rules</div>

              <span className="error select-atleast-one-rule-from">
                {addErr.membership}
              </span>

              <UserAccessRule
                emails={emails}
                setEmails={setEmails}
                attendees={attendees}
                setAttendees={setAttendees}
                nonAttendees={nonAttendees}
                setNonAttendees={setNonAttendees}
                invalidEmail={invalidEmail}
                setInvalidEmail={setInvalidEmail}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                emailRegex={emailRegex}
                getAllEventAttendees={getAllEventAttendees}
              />
              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Status*
                  <a
                    className="status-undo"
                    onClick={() =>
                      setAddEvent({ ...addEvent, status: "draft" })
                    }
                  >
                    <img src={undo} />
                  </a>
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="paused"
                      name="status"
                      checked={addEvent.status === "paused"}
                      onChange={handleAddChange}
                    />
                    Pause
                  </label>
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="published"
                      name="status"
                      checked={addEvent.status === "published"}
                      onChange={handleAddChange}
                    />
                    Live
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">{addErr.status}</span>
              </div>

              <div className="form-group m-0">
                <div className="form-group-ct">
                  <label className="subcat-addvideo">
                    <input
                      type="checkbox"
                      name="chk-preregistration"
                      id="chk-preregistration"
                      checked={isCheckInAllowed}
                      onChange={(e) => {
                        setIsCheckInAllowed(e.target.checked);
                      }}
                    />
                    Enable Check-in for This Activity
                  </label>
                </div>
              </div>

              <div className="cstm-add-video-title-sub">Reserved</div>
              <div className="form-group">
                <label className="subcat-addvideo">
                  <input
                    type="checkbox"
                    name="reserved"
                    onChange={handleAddChange}
                    checked={addEvent.reserved}
                  />
                  Reserved
                </label>
              </div>
              {addEvent.reserved && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Reserved Url*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter reserved url"
                        name="reservedUrl"
                        onChange={handleAddChange}
                        value={addEvent.reservedUrl}
                      />
                      <span className="error">{addErr.reservedUrl}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Button label for listing*
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter button label for listing"
                        name="reservedLabelForListing"
                        onChange={handleAddChange}
                        value={addEvent.reservedLabelForListing}
                        maxLength={18}
                      />
                      <span className="text-limitaion">
                        The maximum character limit is 18.
                      </span>
                      <span className="error">
                        {addErr.reservedLabelForListing}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Button label for detail*
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter button label for detail"
                        name="reservedLabelForDetail"
                        onChange={handleAddChange}
                        value={addEvent.reservedLabelForDetail}
                      />
                      <span className="error">
                        {addErr.reservedLabelForDetail}
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="cstm-add-video-title-sub">
                Notify reminder to user
              </div>

              <div className="form-group">
                <label className="subcat-addvideo">
                  <input
                    type="checkbox"
                    name="scheduleNotify"
                    onChange={(e) => {
                      setAddEvent({
                        ...addEvent,
                        scheduleNotify: e.target.checked,
                      });
                    }}
                    checked={addEvent.scheduleNotify}
                  />
                  Notify reminder to user
                </label>
              </div>
              {addEvent.scheduleNotify && (
                <div className="form-group">
                  <div className="form-group-label">Choose Time Reminder*</div>
                  <div className="form-group-ct">
                    <select
                      onChange={(e) => {
                        setAddEvent({
                          ...addEvent,
                          scheduleNotifyTime: e.target.value,
                        });
                      }}
                      value={addEvent.scheduleNotifyTime}
                      name="scheduleNotifyTime"
                    >
                      {scheduleNotifyTimeOptionList.map((opt) => (
                        <option key={opt.time} value={opt.time}>
                          {opt.label}
                        </option>
                      ))}
                    </select>
                    <span className="error">{addErr.scheduleNotifyTime}</span>
                  </div>
                </div>
              )}
              <div className="form-group-label">Upload the icon*</div>
              <div className="group-info-user cstm-upload-box">
                <div className="group-info-user-img">
                  <img
                    className={""}
                    alt="Speaker profile"
                    src={
                      profileImg
                        ? typeof profileImg === "object"
                          ? URL.createObjectURL(profileImg)
                          : profileImg
                        : frame
                    }
                  />
                </div>
                <div className="group-info-user-link">
                  <button
                    className="cover-image-upload-ttl"
                    data-toggle="modal"
                    data-target="#profileSelector"
                  >
                    Upload new or select from an existing icon
                  </button>
                </div>
                <span className="error">{addErr.photo}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {addEvent.status !== "draft" && createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
              {addEvent.status === "draft" && (
                <>
                  {saveInProcess ? (
                    <div className="loader-button1 save-draft-loader">
                      <button>In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button className="btn ms-4" onClick={handleSaveDraft}>
                      Save as draft
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editActivity"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Activity</h2>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editActivity").modal("hide");
                      handleCloseAddModel(e);
                    }
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editEvent.name}
                    />
                    <span className="error">{editErr.name}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Short description*</div>
                  <div className="form-group-ct">
                    <textarea
                      placeholder="Enter short description"
                      name="shortDescription"
                      onChange={handleEditChange}
                      value={editEvent.shortDescription}
                      maxLength={80}
                    />
                    <span className="text-limitaion">
                      The maximum character limit is 80.
                    </span>
                    <span className="error">{editErr.shortDescription}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Long description*</div>
                  <div className="form-group-ct">
                    <div className="cstm-vd cstm-code-addt">
                      <TinyEditor
                        valueRef={editDescriptionRef}
                        value={editEvent.longDescription}
                        handleEditorChange={(value, editor) => {
                          setAddErr({ ...editErr, longDescription: "" });
                        }}
                        handleKeyPress={() => {
                          dispatch({ type: "SETMODALINPROCESS" });
                        }}
                      />
                    </div>
                    <span className="error">{editErr.longDescription}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Date*</div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      placeholder="Select date"
                      name="date"
                      onChange={handleEditChange}
                      value={editEvent.date}
                      min={moment(eventDetail.startDate, "MM-DD-YYYY").format(
                        "YYYY-MM-DD"
                      )}
                      max={moment(eventDetail.endDate, "MM-DD-YYYY").format(
                        "YYYY-MM-DD"
                      )}
                    />
                    <span className="error">{editErr.date}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Sessions</div>
                  <div className="form-group-ct speakers-only">
                    <Multiselect
                      placeholder="Select sessions"
                      displayValue="title"
                      options={sessionList}
                      selectedValues={editEvent.sessions}
                      onSelect={(selectedlist) => {
                        setEditErr({ ...editErr, sessions: "" });
                        setEditEvent({
                          ...editEvent,
                          sessions: selectedlist,
                          startTime: "",
                          endTime: "",
                          isEndOnNextDate: false,
                          endDate: "",
                        });
                        if (selectedlist.length > 0)
                          setShowEditLocationFlag(false);
                        else setShowEditLocationFlag(true);
                      }}
                      onRemove={(selectedlist) => {
                        setEditEvent({ ...editEvent, sessions: selectedlist });
                        if (selectedlist.length > 0)
                          setShowEditLocationFlag(false);
                        else setShowEditLocationFlag(true);
                      }}
                    />
                    <span className="error">{editErr.sessions}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Location</div>
                  <div className="form-group-ct">
                    <select
                      onChange={handleEditChange}
                      value={editEvent.location}
                      name="location"
                    >
                      <option value="">Select a location</option>
                      {locationList.map((location) => (
                        <option
                          key={location._id}
                          value={location._id}
                          data-address={location.name}
                        >
                          {location.name.slice(0, 70)}
                          {location.name.length > 70 ? "..." : ""}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {editEvent.sessions.length === 0 && (
                  <>
                    <div className="form-group">
                      <div className="form-group-label">Start time*</div>
                      <div className="form-group-ct">
                        <input
                          type="time"
                          placeholder="Select start time"
                          name="startTime"
                          onChange={handleEditChange}
                          value={editEvent.startTime}
                        />
                        <span className="error">{editErr.startTime}</span>
                      </div>
                    </div>
                    <div className="form-group clt-checkbox">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              if (editEvent.date)
                                setEditEvent({
                                  ...editEvent,
                                  endDate: moment(editEvent.date, "YYYY-MM-DD")
                                    .add(1, "days")
                                    .format("YYYY-MM-DD"),
                                  isEndOnNextDate: e.target.checked,
                                });
                              setEditErr({ ...editErr, endTime: "" });
                            } else
                              setEditEvent({
                                ...editEvent,
                                endDate: "",
                                isEndOnNextDate: e.target.checked,
                              });
                          }}
                          name="isEndOnNextDate"
                          checked={editEvent.isEndOnNextDate}
                        />
                        Activity ends on next day?
                      </label>
                    </div>
                    {editEvent.isEndOnNextDate && (
                      <div className="form-group">
                        <div className="form-group-label">End date</div>
                        <div className="form-group-ct">
                          <input
                            type="date"
                            placeholder="dd-mm-yyyy"
                            name="endDate"
                            disabled
                            value={editEvent.endDate}
                          />
                          <span className="error">{editErr.endDate}</span>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <div className="form-group-label">End time*</div>
                      <div className="form-group-ct">
                        <input
                          type="time"
                          placeholder="Select end time"
                          name="endTime"
                          onChange={handleEditChange}
                          value={editEvent.endTime}
                        />
                        <span className="error">{editErr.endTime}</span>
                      </div>
                    </div>
                  </>
                )}
                <div className="cstm-add-video-title-sub">
                  Select participants*
                </div>
                <div className="col-sm-12 form-group mb-0">
                  <div className="form-group-ctst">
                    {allAccessRoles.map((a) => {
                      return (
                        <>
                          <label className="subcat-addvideo">
                            <input
                              type="checkbox"
                              name="accessRoles"
                              value={a._id.toString()}
                              onChange={handleEditChange}
                              checked={editEvent.accessRoles.includes(
                                a._id.toString()
                              )}
                            />
                            {a.role}
                          </label>
                        </>
                      );
                    })}

                    <span className="error acces-error-only">
                      {editErr.access}
                    </span>
                  </div>
                </div>

                <div class="cstm-channel-type-title">Select rule</div>

                <span className="error select-atleast-one-rule-from">
                  {editErr.membership}
                </span>

                <UserAccessRule
                  emails={emails}
                  setEmails={setEmails}
                  attendees={attendees}
                  setAttendees={setAttendees}
                  nonAttendees={nonAttendees}
                  setNonAttendees={setNonAttendees}
                  invalidEmail={invalidEmail}
                  setInvalidEmail={setInvalidEmail}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  emailRegex={emailRegex}
                  getAllEventAttendees={getAllEventAttendees}
                />
                <div class="form-group">
                  <div class="cstm-channel-type-title partner-icon-rf-only">
                    Activity Status*
                    <a
                      className="status-undo"
                      onClick={() =>
                        setEditEvent({ ...editEvent, status: "draft" })
                      }
                    >
                      <img src={undo} />
                    </a>
                  </div>
                  <div class="cstm-pm-list">
                    <label class="subcat-addvideo">
                      <input
                        type="radio"
                        value="paused"
                        name="status"
                        checked={editEvent.status === "paused" ? true : false}
                        onChange={handleEditChange}
                      />
                      Pause
                    </label>
                    <label class="subcat-addvideo">
                      {" "}
                      <input
                        type="radio"
                        value="published"
                        name="status"
                        checked={
                          editEvent.status === "published" ? true : false
                        }
                        onChange={handleEditChange}
                      />
                      Live
                    </label>
                  </div>
                  <span className="error cstm-pm-list-msg">
                    {editErr.status}
                  </span>
                </div>

                <div className="form-group m-0">
                  <div className="form-group-ct">
                    <label className="subcat-addvideo">
                      <input
                        type="checkbox"
                        name="chk-preregistration"
                        id="chk-preregistration"
                        checked={isCheckInAllowed}
                        onChange={(e) => {
                          setIsCheckInAllowed(e.target.checked);
                        }}
                      />
                      Enable Check-in for This Activity
                    </label>
                  </div>
                </div>

                <div className="cstm-add-video-title-sub">Reserved</div>
                <div className="form-group">
                  <label className="subcat-addvideo">
                    <input
                      type="checkbox"
                      name="reserved"
                      onChange={handleEditChange}
                      checked={editEvent.reserved}
                    />
                    Reserved
                  </label>
                </div>
                {editEvent.reserved && (
                  <>
                    <div className="form-group">
                      <div className="form-group-label">Reserved Url*</div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Enter reserved url"
                          name="reservedUrl"
                          onChange={handleEditChange}
                          value={editEvent.reservedUrl}
                        />
                        <span className="error">{editErr.reservedUrl}</span>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-group-label">
                        Button label for listing*
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Enter button label for listing"
                          name="reservedLabelForListing"
                          onChange={handleEditChange}
                          value={editEvent.reservedLabelForListing}
                          maxLength={18}
                        />
                        <span className="text-limitaion">
                          The maximum character limit is 18.
                        </span>
                        <span className="error">
                          {editErr.reservedLabelForListing}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-group-label">
                        Button label for detail*
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Enter button label for detail"
                          name="reservedLabelForDetail"
                          onChange={handleEditChange}
                          value={editEvent.reservedLabelForDetail}
                        />
                        <span className="error">
                          {editErr.reservedLabelForDetail}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="cstm-add-video-title-sub">
                  Notify reminder to user
                </div>

                <div className="form-group">
                  <label className="subcat-addvideo">
                    <input
                      type="checkbox"
                      name="scheduleNotify"
                      onChange={(e) => {
                        setEditEvent({
                          ...editEvent,
                          scheduleNotify: e.target.checked,
                        });
                      }}
                      checked={editEvent.scheduleNotify}
                    />
                    Notify reminder to user
                  </label>
                </div>
                {editEvent.scheduleNotify && (
                  <div className="form-group">
                    <div className="form-group-label">
                      Choose Time Reminder*
                    </div>
                    <div className="form-group-ct">
                      <select
                        onChange={(e) => {
                          setEditEvent({
                            ...editEvent,
                            scheduleNotifyTime: e.target.value,
                          });
                        }}
                        value={editEvent.scheduleNotifyTime}
                        name="scheduleNotifyTime"
                      >
                        {scheduleNotifyTimeOptionList.map((opt) => (
                          <option key={opt.time} value={opt.time}>
                            {opt.label}
                          </option>
                        ))}
                      </select>
                      <span className="error">
                        {editErr.scheduleNotifyTime}
                      </span>
                    </div>
                  </div>
                )}
                <div className="form-group-label">Upload the icon*</div>
                <div className="group-info-user cstm-upload-box">
                  <div className="group-info-user-img">
                    <img
                      className={""}
                      alt="profile"
                      src={
                        profileImg
                          ? typeof profileImg === "object"
                            ? URL.createObjectURL(profileImg)
                            : profileImg
                          : frame
                      }
                    />
                  </div>
                  <div className="group-info-user-link">
                    <button
                      className="cover-image-upload-ttl"
                      data-toggle="modal"
                      data-target="#profileSelector"
                    >
                      Upload new or select from an existing icon
                    </button>
                  </div>
                  <span className="error">{editErr.photo}</span>
                </div>
                <div className="form-group m-0">
                  <div className="form-group-ct">
                    <label className="subcat-addvideo">
                      <input
                        type="checkbox"
                        name="notifyChanges"
                        value={editEvent.notifyChanges}
                        onChange={(e) => {
                          setEditEvent({
                            ...editEvent,
                            notifyChanges: e.target.checked,
                            notificationText: "",
                          });
                        }}
                        checked={editEvent.notifyChanges}
                        defaultChecked={editEvent.notifyChanges}
                      />
                      Do you want this changes to notify users?
                    </label>
                    <span className="error">{editErr.notifyChanges}</span>
                  </div>
                </div>
                {editEvent.notifyChanges && (
                  <div className="form-group">
                    <div className="form-group-label">Notification text*</div>
                    <div className="form-group-ct">
                      <textarea
                        placeholder="Enter notification text"
                        name="notificationText"
                        onChange={handleEditChange}
                        value={editEvent.notificationText}
                      />
                      <span className="error">{editErr.notificationText}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="edit-user-details-footer cstm-create-button">
                {editEvent.status !== "draft" && editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
                {editEvent.status === "draft" && (
                  <>
                    {saveInProcess ? (
                      <div className="loader-button1 save-draft-loader">
                        <button>In Process...</button>
                        <span className="loader-btn">
                          <img src={Loader2} />
                        </span>
                      </div>
                    ) : (
                      <button
                        className="btn ms-4"
                        onClick={handleEditSaveDraft}
                      >
                        Save as draft
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      <ProfileSelector
        images={activityImages}
        setImages={setActivityImages}
        profileImg={profileImg}
        setProfileImg={setProfileImg}
        setIconCropedFileData={setIconCropedFileData}
      />
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant="danger"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete activity"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteName}"? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {cloning && (
        <div className="cstm-clone-msg-container">
          <h3>Cloning Record...</h3>
          <p>Please wait while the record is being cloned.</p>
        </div>
      )}
      {cloneSuccess && (
        <div className="cstm-success-msg-container">
          <div className="cstm-success-msg-container-title">{cloneMsg}</div>
        </div>
      )}
      {/* clone modal */}
      <CloneActivityModal handleCloneActivity={handleCloneActivity} />

      <NonAttendeePopUpModal
        setShowNonAttendeeListPopUp={setShowNonAttendeeListPopUp}
        showNonAttendeeListPopUp={showNonAttendeeListPopUp}
        apiType={apiType}
        handleCreate={handleCreate}
        handleSaveDraft={handleSaveDraft}
        handleEditSubmit={handleEditSubmit}
        handleEditSaveDraft={handleEditSaveDraft}
        modalLoader={modalLoader}
        nonAttendees={nonAttendees}
        eventDetail={eventDetail}
      />
      {!showNonAttendeeListPopUp && <AlertComponent />}
    </>
  );
};
export default Activities;
