const allFiles = [];

const AllChatFileList = (state = allFiles, action) => {
  switch (action.type) {
    case "FETCHALLFILESLIST":
      return action.payload;
    case "APPENDFILES":
      return [...state, ...action.payload];
    case "ADDFILEINLIST": 
    let data = {
      _id: action.payload._id,
      sender: action.payload.sender.id,
      userTimeStamp: action.payload.userTimeStamp,
     documents: action.payload.documents,
    }
    return [data, ...state];
    case "DELETEFILEFROMLIST":
      return state.filter((data) => data._id !== action.payload);
    default:
      return state;
  }
};

export default AllChatFileList;
