import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchsavedpost,
  fetchlatestfiles,
  fetchsavedvideos,
  fetchstarchat,
} from "../../../Action/index.js";
//import components
import Header from "./Header";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import Middlebar from "./Middlebar.js";
import AllGroupMobile from "./AllGroupMobile";
import AnnoucementPopup from "./AnnoucementPopup";
import EventListPopup from "./EventListPopup";
import FilesPopup from "./FilesPopup";
import ForumListPopup from "./ForumListPopup";
import NotificationListPopup from "./NotificationListPopup";
import SavedPostPopup from "./SavedFilesPopup";
import Footer from "./Footer";
import UserListingPopup from "./UserListingPopup.js";
import playstore from "../../../Images/playstore-logo.svg";
import appstore from "../../../Images/appstore-logo.svg";
import closebtn from "../../../Images/close-btn.svg";
import modellogo from "../../../Images/mds-model-logo.svg";
import modeimg from "../../../Images/model-img.png";
const WelcomeUser = () => {
  const dispatch = useDispatch();
  const migratePopup = useSelector((state) => {
    return state.ReduMigrateUserPopup;
  });
  const [leftsidebar, setleftsidebar] = useState(true);
  const [rightsidebar, setrightsidebar] = useState(true);
  const [SelectedGroup, setSelectedGroup] = useState("");
  const [selectedDiv, setselectedDiv] = useState("dashboard");
  const [topicSearch, settopicSearch] = useState("");
  const [openingpop, setopenpop] = useState({
    annou: false,
    files: false,
    save: false,
    event: false,
    chat: false,
    forum: false,
    notifi: false,
    allgroup: false,
  });
  useEffect(() => {
    if (
      localStorage.getItem("idtoken") === undefined ||
      localStorage.getItem("idtoken") === null ||
      localStorage.getItem("idtoken").length === 0 ||
      localStorage.getItem("userid") === null ||
      localStorage.getItem("userid") === undefined ||
      localStorage.getItem("userid").length === 0 ||
      localStorage.getItem("username") === null ||
      localStorage.getItem("userid") === undefined ||
      localStorage.getItem("username").length === 0 ||
      localStorage.getItem("username") === undefined ||
      localStorage.getItem("role") === null ||
      localStorage.getItem("role") !== "user" ||
      localStorage.getItem("logintime") === undefined ||
      localStorage.getItem("logintime") === null
    ) {
      window.location.href = "/";
    }
    dispatch(fetchsavedpost());
    dispatch(fetchsavedvideos());
    dispatch(fetchlatestfiles());
    setSelectedGroup("");
    setselectedDiv("dashboard");
    settopicSearch("");
    if (window.location.hostname === "app.milliondollarsellers.com") {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "yn8h04m3",
        name: localStorage.getItem("username") ?? "",
        email: localStorage.getItem("useremailid") ?? "", // Email address
        created_at: localStorage.getItem("createdAt") ?? "", // Signup date as a Unix timestamp
      });
    }
  }, []);
  return (
    <>
      <Header
        setSelectedGroup={setSelectedGroup}
        setselectedDiv={setselectedDiv}
        settopicSearch={settopicSearch}
        setopenpop={setopenpop}
      />
      <div
        className={
          leftsidebar && rightsidebar
            ? "all-content-main cstm-all-content-main"
            : leftsidebar === false && rightsidebar
            ? "all-content-main cstm-all-content-main mn-cnt-wd"
            : rightsidebar === false && leftsidebar
            ? "all-content-main cstm-all-content-main mn-cnt3-wd"
            : "all-content-main cstm-all-content-main mn-cnt-both"
        }
      >
        
          <LeftSidebar
            setSelectedGroup={setSelectedGroup}
            setselectedDiv={setselectedDiv}
            selectedDiv={selectedDiv}
            SelectedGroup={SelectedGroup}
            leftsidebar={leftsidebar}
          />
        <div className="all-content-main-center cstm-all-content-center">
          {window.location.hostname !==
          process.env.REACT_APP_TESTSITE_HOSTNAME ? (
            <Middlebar
              SelectedGroup={SelectedGroup}
              selectedDiv={selectedDiv}
              topicSearch={topicSearch}
              setselectedDiv={setselectedDiv}
            />
          ) : (
            <></>
          )}
          {openingpop.annou ? (
            <AnnoucementPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.files ? (
            <FilesPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.save ? (
            <SavedPostPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.event ? (
            <EventListPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.forum ? (
            <ForumListPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.notifi ? (
            <NotificationListPopup
              setclose={setopenpop}
              openingpop={openingpop}
            />
          ) : (
            <></>
          )}
          {openingpop.allgroup ? (
            <AllGroupMobile setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.chat ? (
            <UserListingPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
        </div>

        <div
          className={
            rightsidebar
              ? "all-content-main-right cstm-all-content-main-right"
              : "all-content-main-right  sidebar-hd"
          }
        >
          <RightSidebar selectedDiv={selectedDiv} />
        </div>
      </div>
    </>
  );
};
export default WelcomeUser;
