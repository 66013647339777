import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GroupInfo from "./GroupInfo";
import GroupPost from "./GroupPost";
import MemberList from "./MemberList";
import Setting from "./Setting";
import dummy from "../../Images/dummy.jpg";
import groupmembers from "../../Images/BackendImages/groupmembers.svg"
import grouppost from "../../Images/BackendImages/group-posts.svg"
import arrowleft from "../../Images/arrow-left.png";
import AlertComponent from '../../Hooks/AlertComponent';

const Dashboard = ({
  setcurrentDiv,
  selectedGid,
  grpdetail,
  setgrpdetail,
  infotab,
  setinfotab,
}) => {
  const dispatch = useDispatch()
  const modalinprocess = useSelector((state) => { return state.ReduModalInProcess })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })

  console.log(stayOnPage, modalinprocess, 'dfd')
  //pop up state for do you want to leave....
  useEffect(() => {
    if (stayOnPage !== null && !stayOnPage) {
      setcurrentDiv("listing");
    }
    else if (stayOnPage !== null && stayOnPage) {

    }

  }, [stayOnPage])

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
        <div className="um-back">
              <button type="button" className="back-btn"   
              onClick={() => {
              if (modalinprocess) {
                dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
              } else {
                setcurrentDiv("listing");
              }
            }}
            //  onClick={()=>{setcurrentDiv("listing")}}
             >
                <img src={arrowleft} /> Back
              </button>
            </div>
            <h1>{grpdetail.groupTitle}</h1>
          <div className="group-listing-main">
         
            <ul className="nav group-listing-menu" role="tablist">
              <li>
                <a
                  className={infotab ? "" : "active"}
                  href="#Dashboard"
                  role="tab"
                  data-toggle="tab"
                > 
                  Group dashboard
                </a>
              </li>
              <li>
                <a className="" href="#Posts" role="tab" data-toggle="tab">
                  Group posts
                </a>
              </li>
              <li>
                <a className="" href="#Members" role="tab" data-toggle="tab">
                  Group members
                </a>
              </li>
              <li>
                <a
                  className={infotab ? "active" : ""}
                  href="#Info"
                  role="tab"
                  data-toggle="tab"
                >
                  Group info
                </a>
              </li>
              <li>
                <a className="" href="#Settings" role="tab" data-toggle="tab">
                  Group settings
                </a>
              </li>
            </ul>

            <div className="tab-content group-listing-tabs">
              <div
                role="tabpanel"
                className={infotab ? "tab-pane fade" : "tab-pane in active"}
                id="Dashboard"
              >
                <h5 className="gl-title-sub">Group dashboard</h5>

                <div className="row group-mem-pos">
                  <div className="col-sm-3">
                    <div className="group-mem-pos-in">
                      <div className="group-mem-pos-in-img">
                        <img src={groupmembers} />
                      </div>
                      <div className="group-mem-pos-in-cont">
                        {grpdetail.totalGrpMember}
                      </div>
                      <div className="group-mem-pos-in-title">
                        Group Members
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="group-mem-pos-in">
                      <div className="group-mem-pos-in-img">
                        <img src={grouppost} />
                      </div>
                      <div className="group-mem-pos-in-cont">
                        {grpdetail.totalGrpPosts >= 0 ? grpdetail.totalGrpPosts : 0}
                      </div>
                      <div className="group-mem-pos-in-title">Group Posts</div>
                    </div>
                  </div>
                </div>

                <hr className="gi-line" />

                <h5 className="gl-title-sub">Group Update</h5>

                <div className="group-update-main">
                  <div className="group-update-main-in">
                    <div className="group-update-main-in-img">
                      <img className="group-update-user-photo" src={dummy} />
                      <img className="group-update-user-other" src={dummy} />
                    </div>
                    <div className="group-update-main-in-cont">
                      <span className="group-update-dtl">
                        <b>Ronald Richards</b> posted two photos in Lorem Ipsum
                        is simply dummy t of the printing and tyis simply dummy
                        text of the printing and type setting industry{" "}
                        <b>Amazon.</b>
                      </span>
                      <span className="group-update-time">2h ago</span>
                    </div>
                    <div className="group-update-main-in-new">New</div>
                  </div>

                  <div className="group-update-main-in">
                    <div className="group-update-main-in-img">
                      <img className="group-update-user-photo" src={dummy} />
                      <img
                        className="group-update-user-other"
                        src="images2/comment.svg"
                      />
                    </div>
                    <div className="group-update-main-in-cont">
                      <span className="group-update-dtl">
                        <b>Leslie Alexander</b> posted two photos in Lorem Ipsum
                        is simplis simply dumm nting and tyis simply dummy text
                        of the printing and tyis simply dummy text of the
                        printing and tyis simply dummy text of the printing and
                        tyis simply dummy text of the printing and tyy dummy
                        text of the printing and type setting industry{" "}
                        <b>Amazon.</b>
                      </span>
                      <span className="group-update-time">2h ago</span>
                    </div>
                    <div className="group-update-main-in-new">New</div>
                  </div>

                  <div className="group-update-main-in">
                    <div className="group-update-main-in-img">
                      <img className="group-update-user-photo" src={dummy} />
                      <img className="group-update-user-other" src={dummy} />
                    </div>
                    <div className="group-update-main-in-cont">
                      <span className="group-update-dtl">
                        <b>Ronald Richards</b> posted two photos in Lorem Ipsum
                        is simplyis simply dummy text of the printing and ty
                        dummy text of the printing and type setting industry{" "}
                        <b>Amazon.</b>
                      </span>
                      <span className="group-update-time">2h ago</span>
                    </div>
                  </div>

                  <div className="group-update-main-in">
                    <div className="group-update-main-in-img">
                      <img className="group-update-user-photo" src={dummy} />
                      <img className="group-update-user-other" src={dummy} />
                    </div>
                    <div className="group-update-main-in-cont">
                      <span className="group-update-dtl">
                        <b>Ronald Richards</b> posted two photos in Lorem Ipsum
                        is is simply dummy text of the printing and tyis simply
                        dummy text of the printing and tyis simply dummy text of
                        the printing and tyis simply dummy text of the printing
                        and tysimply dummy text of the printing and type setting
                        industry <b>Amazon.</b>
                      </span>
                      <span className="group-update-time">2h ago</span>
                    </div>
                  </div>

                  <div className="group-update-main-in">
                    <div className="group-update-main-in-img">
                      <img className="group-update-user-photo" src={dummy} />
                      <img className="group-update-user-other" src={dummy} />
                    </div>
                    <div className="group-update-main-in-cont">
                      <span className="group-update-dtl">
                        <b>Ronald Richards</b> posted two photos in Lorem Ipsum
                        is simply dummy text of the printing and type setting
                        industry <b>Amazon.</b>
                      </span>
                      <span className="group-update-time">2h ago</span>
                    </div>
                    <div className="group-update-main-in-new">New</div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane fade admin-group-post-tab" id="Posts">
                <h5 className="gl-title-sub">Group Posts</h5>
                <GroupPost selectedGid={selectedGid}/>
              </div>

              <div role="tabpanel" className="tab-pane fade" id="Members">
                <h5 className="gl-title-sub">Group Members</h5>
                <MemberList
                  setcurrentDiv={setcurrentDiv}
                  selectedGid={selectedGid}
                />
              </div>

              <div
                role="tabpanel"
                className={infotab ? "tab-pane in active" : "tab-pane fade"}
                id="Info"
              >
                <div className="group-info-main-font">
                  <GroupInfo
                    setcurrentDiv={setcurrentDiv}
                    selectedGid={selectedGid}
                    grpdetail={grpdetail}
                    setgrpdetail={setgrpdetail}
                  />
                </div>
              </div>

              <div role="tabpanel" className="tab-pane fade" id="Settings">
                <h5 className="gl-title-sub">Group Settings</h5>
                <Setting
                  setcurrentDiv={setcurrentDiv}
                  selectedGid={selectedGid}
                  grpdetail={grpdetail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertComponent/>
    </>
  );
};
export default Dashboard;
