import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllValidUser } from "../../Action";
import ChatList from "./MainComponent/ChatList";
import ChatMessageComponents from "./MainComponent/ChatMessageComponents";
// CSS
import "./MainComponent/ChatStyle/Chat.css";
const ChatMainPage = () => {
  const dispatch = useDispatch();
  const { userid, type } = useParams();
  const [addMemberSelected, setAddMemberSelected] = useState(false);
  const [alreadyExistingMembers, setAlreadyExistingMembers] = useState([]);
  const [editorClear, setEditorClear] = useState(false);
  const handleAddMemberClick = () => {
    setAddMemberSelected(true);
  };
  const removeAddMemberSelected = () => {
    setAddMemberSelected(false);
  };
  const getAllExistingMembers = (members) => {
    setAlreadyExistingMembers(members);
  };
  useEffect(() => {
    dispatch(getAllValidUser());
  }, [userid, type]);
  return (
    <>
      <div className="chat-main-body flex">
        <div className="chat-sidebar-left">
          <ChatList
            addMemberSelected={addMemberSelected}
            alreadyExistingMembers={alreadyExistingMembers}
            removeAddMemberSelected={removeAddMemberSelected}
            setEditorClear={setEditorClear}
          />
        </div>
        <div className="chat-center-sec flex flex-row">
          <ChatMessageComponents
            handleAddMemberClick={handleAddMemberClick}
            getAllExistingMembers={getAllExistingMembers}
            editorClear={editorClear}
            setEditorClear={setEditorClear}
          />
        </div>
      </div>
    </>
  );
};
export default ChatMainPage;
