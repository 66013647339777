import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Import Api integration file
import Services from "../../../Services/auth.service";
// Import Images
import logo from "../../../Images/logo.png";
import uploadpic from "../../../Images/upload-dp.png";
import folderopen from "../../../Images/folder-open.png";
import Loader2 from "../../../Images/loader2.gif";

const Questions = () => {
  const history = useNavigate();
  const { stepno } = useParams();
  const regidetail = useSelector((state) => {
    return state.ReduRegistrationDetail;
  });
  const [startDate, setStartDate] = useState(moment().toDate());
  const rangeyear = () => {
    var arr = [];
    var n = 0;
    for (var i = 1990; i < new Date().getFullYear() + 20; i++) {
      arr[n] = i;
      n++;
    }
    return arr;
  };
  const years = rangeyear();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [allques, setallques] = useState([]);
  const [quesno, setquesno] = useState(1);
  const [answer, setanswer] = useState({});
  const [err, setErr] = useState("");
  const [files, setFiles] = useState([]);
  const [isProcessing, setProcessingTo] = useState(false);
  // useEffect called on first render
  useEffect(() => {
    if (
      (regidetail === undefined ||
        !regidetail.register_status ||
        !regidetail.payment_status ||
        regidetail.QA_status) &&
      stepno === "6"
    ) {
      window.location.href = "/";
    } else {
      Services.getgivenanswer(regidetail.userid)
        .then((res) => {
          Services.getallquestions()
            .then(function (response) {
              if (response.data.status) {
                var arr = [];
                var n = 0;
                for (var i = 0; i < response.data.data.length; i++) {
                  var is_answered =
                    res.data.data.filter((ans) => {
                      if (ans.question._id === response.data.data[i]._id)
                        return ans;
                    }).length > 0;
                  if (!is_answered) {
                    arr[n] = response.data.data[i];
                    n++;
                  }
                }
                console.log(arr);
                setallques(arr);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [stepno]);
  // called when remove selected files
  const remove = (file) => {
    var arr = files;
    arr.splice(file, 1);
    setFiles(arr);
    setanswer({ data: [...answer.data, ...arr] });
    allques[quesno - 1].required.length > 0 && arr.length < 1
      ? setErr("Select image!")
      : setErr("");
  };
  // called on change input fields
  const handleChange = (e, type, name) => {
    if (type === "date") {
      setStartDate(e);
      setanswer({ data: e });
    } else {
      if (e.target.type === "checkbox") {
        var arr = answer.data === undefined ? [] : answer.data;
        if (e.target.checked) {
          if (arr !== undefined && arr.length > 0 && arr.indexOf(e.target.value) === -1) arr[arr.length] = e.target.value;
          else if( arr === undefined || arr.length === 0) arr[0] = e.target.value
        } else {
          arr.splice(arr.indexOf(e.target.value), 1);
        }
        setanswer({ data: arr });
        setErr(
          allques[quesno - 1].require_field
            ? arr.length > 0
              ? ""
              : "Select answer!"
            : allques[quesno - 1].minlength > 0 &&
              allques[quesno - 1].maxlength > allques[quesno - 1].minlength
            ? arr.length > allques[quesno - 1].minlength &&
              arr.length < allques[quesno - 1].maxlength
              ? ""
              : "Must contain minimum " +
                allques[quesno - 1].minlength +
                "and maximum " +
                allques[quesno - 1].maxlength +
                "!"
            : allques[quesno - 1].minlength > 0
            ? arr.length > allques[quesno - 1].minlength
              ? ""
              : "Must contain minimum " + allques[quesno - 1].minlength + "!"
            : allques[quesno - 1].maxlength > 0
            ? arr.length < allques[quesno - 1].maxlength
              ? ""
              : "Must be less then " + allques[quesno - 1].maxlength + "!"
            : ""
        );
      } else {
        setanswer({ data: e.target.value });
        if (e.target.type === "email") {
          setErr(
            allques[quesno - 1].require_field
              ? e.target.value.length > 0
                ? e.target.value
                    .toLowerCase()
                    .match(
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ) === null
                  ? "Invalid email format!"
                  : ""
                : "Enter email!"
              : ""
          );
        } else {
          setErr(
            allques[quesno - 1].require_field
              ? e.target.value.length > 0
                ? ""
                : "Enter answer!"
              : allques[quesno - 1].minlength > 0 &&
                allques[quesno - 1].maxlength > allques[quesno - 1].minlength
              ? e.target.value.length > allques[quesno - 1].minlength &&
                e.target.value.length < allques[quesno - 1].maxlength
                ? ""
                : "Must contain minimum " +
                  allques[quesno - 1].minlength +
                  "and maximum " +
                  allques[quesno - 1].maxlength +
                  "!"
              : allques[quesno - 1].minlength > 0
              ? e.target.value.length > allques[quesno - 1].minlength
                ? ""
                : "Must contain minimum " + allques[quesno - 1].minlength + "!"
              : allques[quesno - 1].maxlength > 0
              ? e.target.value.length < allques[quesno - 1].maxlength
                ? ""
                : "Must be less then " + allques[quesno - 1].maxlength + "!"
              : ""
          );
        }
      }
    }
  };
  // called on click on submit & next button
  const handleNext = async (id) => {
    var err_var =
      allques[quesno - 1].require_field.length > 0
        ? answer.length > 0
          ? err
          : "Enter answer!"
        : err;
    setErr(err_var);
    if (err.length === 0 && err_var.length === 0) {
      setProcessingTo(true);
      var formdata = new FormData();
      formdata.append("userId", regidetail.userid);
      formdata.append("question", id);
      formdata.append("answer_object[data]", answer.data);
      Services.saveuserQND(formdata)
        .then((res) => {
          setProcessingTo(false);
          console.log( res.data)
          if (res.data.status &&  res.data.data.next_ques) {
            if (quesno < allques.length) {
                setquesno(quesno + 1);
                setanswer({});
                setStartDate(moment().toDate());
              
              } 
          } else {
            history("/firststepregister/7");
          }
        })
        .catch((e) => {
          setProcessingTo(false);
          console.log(e);
        });
    }
  };

  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/*",
    maxSizeBytes: 100000000,
    onDrop,
  });
  const assignFiles = (acceptedFiles) => {
    var arr = [...files, ...acceptedFiles];
    setFiles(arr);
    setanswer({ data: arr });
    allques[quesno - 1].require_field.length > 0 && arr.length > 0
      ? setErr("")
      : allques[quesno - 1].require_field.length > 0
      ? setErr("Select image!")
      : setErr("");
  };
console.log(  answer.data)
  return (
    <>
      <div className="um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left um-questions">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn">
                  <div className="um-title2">Questions For Member</div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 um-right">
              {allques.length > 0 ? (
                <div className="um-right-in2">
                  <div className="um-number">
                    <b>{quesno}</b> / {allques.length}
                  </div>
                  <div className="um-right-in-title">Questions</div>
                  <div className="um-right-in-sub">
                    Please provide an answer to each question so we can get to
                    know you better.
                  </div>
                  <hr className="um-line" />

                  <div className="form-group">
                    <div className="um-right-in-label">
                      <b>
                        {quesno}. {allques[quesno - 1].question}{" "}
                        {allques[quesno - 1].require_field ? "*" : ""}
                      </b>
                    </div>
                    <div className="um-right-in-box ">
                      {allques[quesno - 1].options.length < 1 ? (
                        allques[quesno - 1].field_type === "file" ? (
                          <>
                            <div className="row">
                              <div className="um-drap-main-box">
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <img src={uploadpic} className="full" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="um-upload-msg">
                                {files.length > 0 &&
                                  files.map((file, i) => (
                                    <div className="list-group-item list-group-item-success">
                                      <img src={folderopen} className="full" />
                                      {file.name}
                                      <span
                                        className="um-ulpoad-close"
                                        onClick={() => remove(i)}
                                      >
                                        ×
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="row">
                              <span className="error">{err}</span>
                            </div>
                          </>
                        ) : allques[quesno - 1].field_type === "textarea" ? (
                          <>
                            <textarea
                              name={allques[quesno - 1]._id}
                              onChange={handleChange}
                            >
                              {answer.data}
                            </textarea>
                            <span className="error">{err}</span>
                          </>
                        ) : allques[quesno - 1].field_type === "date" ? (
                          <>
                            <DatePicker
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                  >
                                    {"<"}
                                  </button>
                                  <select
                                    value={new Date().getFullYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={months[new Date().getFullYear(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                  >
                                    {">"}
                                  </button>
                                </div>
                              )}
                              selected={startDate}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  "date",
                                  allques[quesno - 1]._id
                                );
                              }}
                            />
                            <span className="error">{err}</span>
                          </>
                        ) : (
                          <>
                            <input
                              type={allques[quesno - 1].field_type}
                              name={allques[quesno - 1]._id}
                              onChange={handleChange}
                              value={
                                answer.data !== undefined ? answer.data : ""
                              }
                            />
                            <span className="error">{err}</span>
                          </>
                        )
                      ) : allques[quesno - 1].field_type === "checkbox" ? (
                        <>
                          <div className="row">
                            <div className="um-checkbox-main">
                              {allques[quesno - 1].options.map((opt) => {
                                return (
                                  <>
                                    {" "}
                                    <div className="um-checkbox">
                                      <div className="form-check um-cp-check">
                                        <label className="form-check-label">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name={allques[quesno - 1]._id}
                                            onChange={handleChange}
                                            value={opt}
                                            checked={
                                              answer.data !== undefined &&
                                              answer.data.indexOf(opt) !== -1
                                                ? true
                                                : false
                                            }
                                          />
                                          {opt}
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="row">
                            <span className="error">{err}</span>
                          </div>
                        </>
                      ) : (
                        <div className="row">
                          <div className="um-radio-main">
                            {allques[quesno - 1].options.map((opt) => {
                              return (
                                <>
                                  <div className="um-radio">
                                    <div className="form-check um-cp-check">
                                      <label className="form-check-label">
                                        <input
                                          type="radio"
                                          className="form-check-input"
                                          name={allques[quesno - 1]._id}
                                          onChange={handleChange}
                                          value={opt}
                                          checked={
                                            answer.data !== undefined &&
                                            answer.data === opt
                                              ? true
                                              : false
                                          }
                                        />
                                        {opt}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          <span className="error">{err}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="um-rgt-btn">
                    {isProcessing ? (
                      <>
                        {" "}
                        <div className="um-rgt-btn-loading">
                          <span className="loading-btn-in2">
                            <img src={Loader2} />
                          </span>
                          <button
                            type="button"
                            className="btn2"
                            disabled={isProcessing}
                          >
                            Please wait...
                          </button>
                        </div>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn2"
                        disabled={isProcessing}
                        onClick={() => {
                          handleNext(allques[quesno - 1]._id);
                        }}
                      >
                        Save and Next
                      </button>
                    )}
                  </div>

                  {/* <div className="um-rgt-btn">
                    <button
                      type="button"
                      className="btn2"
                      disabled={isProcessing} 
                      onClick={() => {
                        handleNext(allques[quesno - 1]._id);
                      }}
                    >
                     {isProcessing ? "Please wait..." : "Submit and Next"} 
                    </button>
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;
