import { useDropzone } from "react-dropzone";
import { useState } from "react";
//import image
import Videos from "../../../Images/videos.svg";
const UploadVideos = ({
  post,
  addVid,
  setpost,
  setaddVid,
  setErr,
  showpoll,
  edit,
  alleditimgvids,
  setalleditimgvids
}) => {
  const [files, setFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "video/mp4, video/mov, video/gif",
    maxSizeBytes: 10485760,
    onDrop,
  });
  const assignFiles = (acceptedFiles) => {

    if (edit) {
      var arr = acceptedFiles.map((file) => {
        return file;
      });
      var urlarr = acceptedFiles.map((file) => {
        return URL.createObjectURL(file);
      }); 
      console.log(arr);
      var arr_vi = [...addVid, ...arr];
      setFiles(arr_vi);
      setaddVid(arr_vi);
      setErr("");
      setalleditimgvids({imgs: alleditimgvids.imgs , vids:[...alleditimgvids.vids , ...urlarr]})
    } else {
      var arr = [...post.videos, ...acceptedFiles];
      setFiles(arr);
      setpost({
        ...post,
        videos: arr,
      });
      setErr("");
    }
  };

  return (
    <>
      <span className="cp-btn">
        {showpoll ? (
          <button className="cp-btn-videos cp-btn-disable" disabled={showpoll}>
            <img src={Videos} />
            Videos
          </button>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <button className="cp-btn-videos" disabled={showpoll}>
              <img src={Videos} />
              Videos
            </button>
          </div>
        )}
      </span>
    </>
  );
};

export default UploadVideos;
