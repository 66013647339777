import React, { useEffect, useState } from "react";
import EditableTextArea from "../commonComponents/EditableTextArea";
import authService from "../../../Services/auth.service";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import Loader from "../../../Images/loader.gif";

const RestrictedUserAccess = ({
  setSelectedUsers,
  selectedUsers,
  isLoading,
  setIsLoading,
  allParticipantsEmailArr,
  setAllParticipantsEmailArr,
  clickedCheckboxUser,
  setClickedCheckboxUser,
  excelFileEmails,
  setExcelFileEmails,
  isError,
  setIsError,
  nonEmailText,
  setNonEmailText,
  editState,
  setEditState,
  alreadySelectedEventUsersEmail,
  totalUserEventList,
  totalUserList,
  setTotalUserList,
  setTotalUsers,
  totalUsers,
  setRulesDetailErr,
  rulesDetailErr,
  totalUserTagList,
  alreadySelectedTagUsersEmail,
}) => {
  const emptyObjectModal = {
    name: "",
    image: "",
    event: {},
    noEventUsers: "",
    channelType: false,
    accessPermission: "",
    Member: false,
    Guest: false,
    Speaker: false,
    Partner: false,
    Users: false,
  };
  const dispatch = useDispatch();

  const [allUserList, setAllUserList] = useState([]);
  const [nonSelectedUsers, setNonSelectedUsers] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [alreadySelectCount, setAlreadySelectCount] = useState(0);
  const [isLoader, setIsLoader] = useState(true);

  const [searchParticipents, setSearchParticipents] = useState("");
  const [channelDetailErr, setChannelDetailErr] = useState(emptyObjectModal);
  const [responseMessage, setResponseMessage] = useState("");

  const [allRegisterdUserList, setAllRegisterdUserList] = useState([]);

  useEffect(() => {
    getAllUserApi();
    getallusersSuggestionListApi();
    // getAllAttendeeList();
  }, []);

  const getAllUserApi = () => {
    setTotalUserList([]);
    authService
      .getAllAttendeeListForRules("")
      .then((res) => {
        if (res.status == 200) {
          if (res?.data?.length) {
            setIsLoader(false);
            setAllUserList(res.data);
            setAllParticipantsEmailArr(
              res.data.map((user) => user["Preferred Email"])
            );
          }
        }
      })
      .catch((e) => {
        setIsLoader(false);
        console.log(e);
      });
  };

  const getallusersSuggestionListApi = () => {
    try {
      authService.getAllUser().then((res) => {
        if (res.status == 200) {
          if (res?.data?.length) {
            setAllRegisterdUserList(res?.data?.map((user) => user?.email));
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleUserSelect = (user) => {
    dispatch({ type: "SETMODALINPROCESS" });
    // Update selectedUsers based on user selection
    const updatedSelection = selectedUsers.includes(user)
      ? selectedUsers.filter((selectedUser) => selectedUser !== user)
      : [...selectedUsers, user];
    // Update selectedUsers state
    setSelectedUsers(updatedSelection);
    setTotalUserList([
      ...totalUserEventList,
      ...totalUserTagList,
      ...updatedSelection,
    ]);
    setTotalUsers(
      [...totalUserEventList, ...totalUserTagList, ...updatedSelection].length
    );
    // Populate registeredParticipants and Attendees arrays based on selected users
    const registeredParticipants = [];
    const Attendees = [];
    for (let i = 0; i < updatedSelection.length; i++) {
      const selectedUser = updatedSelection[i];
      const email = selectedUser["Preferred Email"];

      if (email) {
        if (allRegisterdUserList?.includes(email)) {
          registeredParticipants.push(selectedUser);
        } else {
          Attendees.push(selectedUser);
        }
      }
    }

    // Set registeredUsers and Attendees state
    setRegisteredUsers(registeredParticipants);
    setAttendees(Attendees);

    // Set clickedCheckboxUser based on whether the user is being selected or deselected
    setClickedCheckboxUser(
      selectedUsers?.includes(user) ? user["Preferred Email"] : null
    );
    setRulesDetailErr({
      ...rulesDetailErr,
      event: "",
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setIsLoading(true);
    if (!file) {
      return;
    }
    // Validate the file type
    const validTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (!validTypes.includes(file.type)) {
      setIsError(true);
      setResponseMessage("Please upload a valid Excel file (.xls or .xlsx).");
      e.target.value = null; // Clear the file input
      setIsLoading(false);
      setTimeout(() => {
        setResponseMessage("");
      }, 3000);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const newArr = [];

      jsonData.forEach((row) => {
        row.forEach((cell) => {
          if (typeof cell === "string") {
            newArr.push(cell);
          }
        });
      });

      setIsError(false);
      setExcelFileEmails(newArr);
      const selectedUserFromExcelFile = allUserList.filter((obj) =>
        newArr.includes(obj["Preferred Email"])
      );
      const removeDuplicateEmails = Object.values(
        [...selectedUserFromExcelFile, ...selectedUsers].reduce((acc, item) => {
          acc[item._id] = item;
          return acc;
        }, {})
      );
      setSelectedUsers(removeDuplicateEmails);
      setResponseMessage("Email list added successfully");
      setIsLoading(false);
      setTimeout(() => {
        setResponseMessage("");
      }, 3000);
    };
    e.target.value = null;
    reader.readAsBinaryString(file);
  };

  const isUserInTotalEventList = (user) => {
    return totalUserEventList.some(
      (eventUser) => eventUser.user_id === user._id
    );
  };

  const isUserInTotalTagList = (user) => {
    return totalUserTagList.some((tagUser) => tagUser._id === user._id);
  };

  return (
    <>
      {responseMessage && (
        <div className={isError ? "alert alert-danger" : "alert alert-info"}>
          {responseMessage}
        </div>
      )}
      {isLoader ? (
        <div className="section-loader-box">
          <img src={Loader} className="loader-img" alt="" />{" "}
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-12 form-group">
            <div className="d-flex  align-items-end justify-content-between">
              <div className="d-flex gap-3">
                <div className="cstm-channel-type-title">
                  Select participants*
                </div>
              </div>
              <div className="ml-auto mr-1">
                <div className="fileUpload">
                  <input
                    type="file"
                    className="upload"
                    onChange={handleFileUpload}
                  />
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <span>Import via excel</span>
                  )}
                </div>
              </div>
            </div>
            {/* Email Text Area */}
            <EditableTextArea
              excelFileEmails={excelFileEmails}
              setExcelFileEmails={setExcelFileEmails}
              selectedUsers={selectedUsers}
              allUserList={allUserList}
              allRegisterdUserList={allRegisterdUserList}
              setSelectedUsers={setSelectedUsers}
              allParticipantsEmailArr={allParticipantsEmailArr}
              clickedCheckboxUser={clickedCheckboxUser}
              setClickedCheckboxUser={setClickedCheckboxUser}
              setNonSelectedUsers={setNonSelectedUsers}
              setNonEmailText={setNonEmailText}
              setRegisteredUsers={setRegisteredUsers}
              registeredUsers={registeredUsers}
              setAttendees={setAttendees}
              attendees={attendees}
              editState={editState}
              setEditState={setEditState}
              alreadySelectedEventUsersEmail={alreadySelectedEventUsersEmail}
              setAlreadySelectCount={setAlreadySelectCount}
              alreadySelectCount={alreadySelectCount}
              totalUserEventList={totalUserEventList}
              setTotalUsers={setTotalUsers}
              totalUsers={totalUsers}
              totalUserList={totalUserList}
              setTotalUserList={setTotalUserList}
              totalUserTagList={totalUserTagList}
              alreadySelectedTagUsersEmail={alreadySelectedTagUsersEmail}
            />
            <div className="d-flex justify-content-between">
              <div className="cstm-channel-type-title">
                Total users:{" "}
                {registeredUsers?.length +
                  attendees?.length +
                  nonSelectedUsers?.length}
              </div>
              <div className="d-flex justify-content-end gap-2">
                <div className="cstm-channel-type-title-alreadyexist mr-1">
                  Already selected users :{" "}
                  {!alreadySelectCount.length ? 0 : alreadySelectCount.length}
                </div>
                <div className="cstm-channel-type-title mr-1">
                  Attendees : {!attendees.length ? 0 : attendees.length}
                </div>
                <div className="cstm-channel-type-title-registeredUser mr-1">
                  Registered participants:{" "}
                  {!registeredUsers.length ? 0 : registeredUsers.length}
                </div>
                <div className="cstm-channel-type-title-non-select mr-1">
                  Non-registered participants:{" "}
                  {!nonSelectedUsers.length ? 0 : nonSelectedUsers.length}
                </div>
                <div className="cstm-channel-type-title-error mr-1">
                  Error: {!nonEmailText.length ? 0 : nonEmailText.length}
                </div>
              </div>
            </div>

            <input
              className="mb-3 col-md-6"
              type="text"
              value={searchParticipents}
              placeholder="Search participants"
              onChange={(e) => {
                setSearchParticipents(e.target.value);
              }}
            />
            <div className="select-participants-box">
              {allUserList
                .filter((participent) => {
                  const fullName = `${participent.first_name} ${participent.last_name}`;
                  const email = participent["Preferred Email"] || "";
                  const searchValue = searchParticipents.toLowerCase();

                  return (
                    fullName.toLowerCase().includes(searchValue) ||
                    email.toLowerCase().includes(searchValue)
                  );
                })
                .map((user) => {
                  let disable = true;
                  let isChecked =
                    isUserInTotalEventList(user) || isUserInTotalTagList(user);
                  if (!isChecked) {
                    isChecked = selectedUsers
                      .map((ele) => ele._id)
                      .includes(user._id);
                    disable = false;
                  }
                  return (
                    <label
                      className="select-participants-box-in"
                      key={user._id}
                      style={{
                        backgroundColor:
                          selectedUsers.includes(user) || disable
                            ? "#EBE8FA"
                            : "",
                      }}
                    >
                      <div className="select-participants-box-in-name">
                        <p className="select-pt-title">
                          {`${user.first_name} ${user.last_name}`}
                        </p>
                        <p className="select-pt-email">
                          {user["Preferred Email"]
                            ? user["Preferred Email"]
                            : "-"}
                        </p>
                      </div>
                      <div className="select-participants-box-in-checkbox">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => {
                            handleUserSelect(user);
                          }}
                          disabled={disable}
                        />
                      </div>
                    </label>
                  );
                })}
            </div>
            <span className="error">{channelDetailErr.noEventUsers}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default RestrictedUserAccess;
