const EditPostImgView = ({alleditimgvids}) => {
    return (
        [...alleditimgvids.imgs, ...alleditimgvids.vids]
            .length > 0 ? (
            [...alleditimgvids.imgs, ...alleditimgvids.vids]
              .length === 1 ? (
              alleditimgvids.imgs.indexOf(
                [
                  ...alleditimgvids.imgs,
                  ...alleditimgvids.vids,
                ][0]
              ) !== -1 ? (
                <div className="create-pp-post-img1 edit-pp-post">
                  <div className="edit-pp-post-btn">
                    <button
                      data-toggle="modal"
                      data-target="#editallpix"
                    >
                      Edit Image/Video
                    </button>
                  </div>
                  <img
                    src={
                      [
                        ...alleditimgvids.imgs,
                        ...alleditimgvids.vids,
                      ][0]
                    }
                  />
                </div>
              ) : (
                <div className="create-pp-post-img1 edit-pp-post">
                  <div className="edit-pp-post-btn">
                    <button
                      data-toggle="modal"
                      data-target="#editallpix"
                    >
                      Edit Image/Video
                    </button>
                  </div>
                  <video
                    src={
                      [
                        ...alleditimgvids.imgs,
                        ...alleditimgvids.vids,
                      ][0]
                    }
                    controls={true}
                  />
                </div>
              )
            ) : [
                ...alleditimgvids.imgs,
                ...alleditimgvids.vids,
              ].length === 2 ? (
              <div className="create-pp-post-img2 edit-pp-post">
                {alleditimgvids.imgs.indexOf(
                  [
                    ...alleditimgvids.imgs,
                    ...alleditimgvids.vids,
                  ][0]
                ) !== -1 ? (
                  <>
                    <div className="edit-pp-post-btn">
                      <button
                        data-toggle="modal"
                        data-target="#editallpix"
                      >
                        Edit Image/Video
                      </button>
                    </div>
                    <div className="create-pp-post-img2-in">
                      <img
                        src={
                          [
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ][0]
                        }
                      />
                    </div>
                  </>
                ) : (
                  <div className="create-pp-post-img2-in">
                    <video
                      src={
                        [
                          ...alleditimgvids.imgs,
                          ...alleditimgvids.vids,
                        ][0]
                      }
                      controls={true}
                    />
                  </div>
                )}
                {alleditimgvids.imgs.indexOf(
                  [
                    ...alleditimgvids.imgs,
                    ...alleditimgvids.vids,
                  ][1]
                ) !== -1 ? (
                  <div className="create-pp-post-img2-in">
                    <img
                      src={
                        [
                          ...alleditimgvids.imgs,
                          ...alleditimgvids.vids,
                        ][1]
                      }
                    />
                  </div>
                ) : (
                  <div className="create-pp-post-img2-in">
                    <video
                      src={
                        [
                          ...alleditimgvids.imgs,
                          ...alleditimgvids.vids,
                        ][1]
                      }
                      controls={true}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="create-pp-post-img3 edit-pp-post">
                {alleditimgvids.imgs.indexOf(
                  [
                    ...alleditimgvids.imgs,
                    ...alleditimgvids.vids,
                  ][0]
                ) !== -1 ? (
                  <>
                    <div className="edit-pp-post-btn">
                      <button
                        data-toggle="modal"
                        data-target="#editallpix"
                      >
                        Edit Image/Video
                      </button>
                    </div>
                    <div className="create-pp-post-img3-left">
                      <img
                        src={
                          [
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ][0]
                        }
                      />
                    </div>
                  </>
                ) : (
                  <div className="create-pp-post-img3-left">
                    <video
                      src={
                        [
                          ...alleditimgvids.imgs,
                          ...alleditimgvids.vids,
                        ][0]
                      }
                      controls={true}
                    />
                  </div>
                )}
                <div className="create-pp-post-img3-right">
                  {alleditimgvids.imgs.indexOf(
                    [
                      ...alleditimgvids.imgs,
                      ...alleditimgvids.vids,
                    ][1]
                  ) !== -1 ? (
                    <div className="create-pp-post-img3-right-in">
                      <img
                        src={
                          [
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ][1]
                        }
                      />
                    </div>
                  ) : (
                    <div className="create-pp-post-img3-right-in">
                      <video
                        src={
                          [
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ][1]
                        }
                        controls={true}
                      />
                    </div>
                  )}
                  {alleditimgvids.imgs.indexOf(
                    [
                      ...alleditimgvids.imgs,
                      ...alleditimgvids.vids,
                    ][2]
                  ) !== -1 ? (
                    <div className="create-pp-post-img3-right-in">
                      <img
                        src={
                          [
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ][2]
                        }
                      />
                      {[
                        ...alleditimgvids.imgs,
                        ...alleditimgvids.vids,
                      ].length > 3 ? (
                        <div className="create-pp-cunt">
                          {[
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ].length - 3}
                          +
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="create-pp-post-img3-right-in">
                      <video
                        src={
                          [
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ][2]
                        }
                        controls={true}
                      />
                      {[
                        ...alleditimgvids.imgs,
                        ...alleditimgvids.vids,
                      ].length > 3 ? (
                        <div className="create-pp-cunt">
                          {[
                            ...alleditimgvids.imgs,
                            ...alleditimgvids.vids,
                          ].length - 3}
                          +
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            )
          ) : (
            <></>
          )
    )
} 
export default EditPostImgView;