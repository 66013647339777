
const initialState = {
  muteChatIdList: [],
};

const muteNotificationReducer = (state = {} , action) => {
 switch (action.type) {
    case 'UPDATEMUTESTATE':
      return action.payload
    default:
      return state;
  }
};

export default muteNotificationReducer;