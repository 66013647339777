import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import * as pdfjs from "pdfjs-dist";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const PdfPreview = ({
  url,
  setNoPreview,
  setLoader,
  numPages,
  setNumPages,
  setPageNumber,
}) => {
  const containerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
    setPageNumber(1);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);

  const handleOnLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoader(false);
    setNoPreview(false);

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }
  };

  const handleOnLoadError = () => {
    setLoader(false);
    setNoPreview(true);
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const pageHeight = containerRef.current.clientHeight;
      const scrollTop = containerRef.current.scrollTop;
      const newPage = Math.ceil(scrollTop / pageHeight) + 1;

      if (newPage !== currentPage) {
        setCurrentPage(newPage);
        setPageNumber(newPage);
      }
    }
  };

  return (
    <div className="cstm-doc-pdf" ref={containerRef}>
      <Document
        file={url}
        onLoadError={handleOnLoadError}
        onLoadSuccess={handleOnLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={true}
            renderAnnotationLayer={true}
            className="pdf-page"
          />
        ))}
      </Document>
    </div>
  );
};

export default PdfPreview;
