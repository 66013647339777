import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from "./auth.admin.header";
const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = "http://localhost:3002/api/";

const FIREBASE_API_URL = process.env.REACT_APP_FIREBASE_URL;
const CHAT_API_URL = process.env.REACT_APP_CHAT_BASE_API_URL;
const userLogin = (userLogin) => {
  return axios.post(API_URL + "user/login", userLogin);
};

const adminLoginAuth = (data) => {
  return axios.post(API_URL + "admin_user/login", data);
};
const adminLogin = (data) => {
  return axios.post(API_URL + "user/adminLogin", data);
};
const sociallogin = (emailid) => {
  return axios.post(API_URL + "user/sociallogin", { email: emailid });
};
const getallplanatregis = () => {
  return axios.get(API_URL + "membership/allplan");
};
const userRegistration = (userRegister) => {
  return axios.post(API_URL + "user/register", userRegister);
};
const uploadfiles = (data) => {
  return axios.post(API_URL + "user/save/questionsMedia/S3", data);
};
const removefiles = (data) => {
  return axios.post(API_URL + "user/delete/questionsMedia/S3", data);
};
const paymentapi = (data) => {
  return axios.post(API_URL + "handlePayment", data);
};
const forgotpassword = (email) => {
  return axios.post(API_URL + "user/forgot-pwd", email);
};

const checkToken = (data) => {
  return axios.post(API_URL + "user/checkToken", data);
};

const resetpassword = (data) => {
  return axios.post(API_URL + "user/reset-pwd", data);
};

const updateprofile = (data) => {
  return axios.post(API_URL + "user/updateprofile", data);
};
const updateprofilebyadmin = (data) => {
  return axios.put(API_URL + "user/edit/byadmin", data, {
    headers: authAdminHeader(),
  });
};

const updateUserDataInRadis = (data) => {
  return axios.post(CHAT_API_URL + `update-user-profile/${data}`);
};

const updateprofileimagebyadmin = (data) => {
  return axios.put(API_URL + "user/editimage/byadmin", data, {
    headers: authAdminHeader(),
  });
};
const updateAttendeeProfileByAdmin = (data) => {
  return axios.patch(API_URL + "user/edit/attendeeProfileByAdmin", data, {
    headers: authAdminHeader(),
  });
};
const deactivateuser = (data) => {
  return axios.post(API_URL + "user/deactivateuser", data);
};
const getuserbyid = (userid) => {
  return axios.get(API_URL + "user/profile/" + userid, {
    headers: authAdminHeader(),
  });
};
const getAttendeeProfile = (id) => {
  return axios.get(API_URL + "user/attendeeProfile/" + id, {
    headers: authAdminHeader(),
  });
};
const blockuser = (data) => {
  return axios.post(API_URL + "user/blockuser", data, {
    headers: authAdminHeader(),
  });
};

const unblockuser = (data) => {
  return axios.post(API_URL + "user/unblockuser", data, {
    headers: authAdminHeader(),
  });
};

const deleteuser = (data) => {
  return axios.post(API_URL + "user/deleteuser", data, {
    headers: authAdminHeader(),
  });
};

const getblockeduser = (query) => {
  return axios.get(API_URL + "user/getblockeduser?" + query, {
    headers: authAdminHeader(),
  });
};
const getAllAttendeeList = (query) => {
  return axios.get(API_URL + "user/getAllAttendeeList" + query, {
    headers: authAdminHeader(),
  });
};

const getAllAttendeeListForRules = (query) => {
  return axios.get(API_URL + "user/getAllAttendeeListForRules" + query, {
    headers: authAdminHeader(),
  });
};

const getAllChannelUserBasedOnFilter = (data) => {
  return axios.post(API_URL + "create/channel/userList", data, {
    headers: authAdminHeader(),
  });
};

const getAllChannelUserBasedOnTag = (data) => {
  return axios.post(API_URL + "get/channel/tags/userList", data, {
    headers: authAdminHeader(),
  });
};
const getAllUsersMembersAndAttendees = () => {
  return axios.get(API_URL + "user/getAllUsersMembersAndAttendees", {
    headers: authAdminHeader(),
  });
};
const userExist = (data) => {
  return axios.post(API_URL + "user/userExist", data);
};

const socialuserexist = (data) => {
  return axios.post(API_URL + "user/SocialuserExist", data);
};

const saveuserpersonaldetail = (data) => {
  return axios.put(API_URL + "user/register/personal-details", data);
};

const saveuseronstep2 = (data) => {
  return axios.put(API_URL + "user/plan_payment", data);
};
const saveauth0detail = (data) => {
  return axios.post(API_URL + "user/register-oauth", data);
};
const saveuserQND = (data) => {
  return axios.put(API_URL + "user/QnA", data);
};
const getgivenanswer = (userid) => {
  return axios.get(API_URL + "user/QnA/list/" + userid);
};
const getallusers = (query) => {
  return axios.get(API_URL + "user/getallusers" + query, {
    headers: authAdminHeader(),
  });
};
const getUserByPlan = (query) => {
  return axios.get(API_URL + "getUsers/list/" + query, {
    headers: authAdminHeader(),
  });
};
const getallusersLimitedFields = () => {
  return axios.get(API_URL + "user/getallusersLimitedFields", {
    headers: authAdminHeader(),
  });
};
const getAllUsers = () => {
  return axios.get(API_URL + "getallusersforchat");
};

const addnewmessage = (messagedata) => {
  return axios.post(API_URL + "addnewmessage", messagedata);
};

const getallMessages = (me) => {
  return axios.get(API_URL + "getMessages/" + me);
};

const getimage = (filepath) => {
  return axios.get(API_URL + "uploadedfiles/" + filepath);
};

const addfieldsregistrationform = (data) => {
  return axios.post(API_URL + "addfieldsregistrationform", data);
};

const retrivefieldsregistrationform = () => {
  return axios.get(API_URL + "retrivefieldsregistrationform", {
    headers: authAdminHeader(),
  });
};

const deletefieldregistrationform = (data) => {
  return axios.post(API_URL + "deletefieldregistrationform", data);
};

const updatefieldregistrationform = (data) => {
  return axios.post(API_URL + "updatefieldregistrationform", data);
};
const addquestion = (data) => {
  return axios.post(API_URL + "question/create", data);
};

const retrivequestion = () => {
  return axios.get(API_URL + "retrivequestion");
};
const getallquestions = () => {
  return axios.get(API_URL + "questions", { headers: authAdminHeader() });
};
const getAllUser = () => {
  return axios.get(API_URL + "user/getallusersSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAllAttendeeSuggestionList = () => {
  return axios.get(API_URL + "user/getAllAttendeeSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAllPartnerSuggestionList = () => {
  return axios.get(API_URL + "getAllPartnerSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getBlockedUserSuggestionList = () => {
  return axios.get(API_URL + "user/getBlockedUserSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAllEventSuggestionList = () => {
  return axios.get(API_URL + "event/getAllEventSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAllEventTypeSuggestionList = () => {
  return axios.get(API_URL + "event/getAllEventTypeSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getChannelSuggestionList = () => {
  return axios.get(API_URL + "getChannelSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAllBannerSuggestionList = () => {
  return axios.get(API_URL + "news/getAllBannerSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAllPostSuggestionList = () => {
  return axios.get(API_URL + "news/getAllPostSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getNewsSuggestionList = () => {
  return axios.get(API_URL + "news/getNewsSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getContentNewsSuggestionList = () => {
  return axios.get(API_URL + "contentNews/getContentNewsSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getPartnerSuggestionList = () => {
  return axios.get(API_URL + "partner/getPartnerSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getFeaturedPartnersList = () => {
  return axios.get(
    API_URL +
    "partner/getFeaturedOrFreshdealPartnersList?type=featuredPartner&sortField=companyName",
    { headers: authAdminHeader() }
  );
};
const getFreshdealPartnersList = () => {
  return axios.get(
    API_URL +
    "partner/getFeaturedOrFreshdealPartnersSuggestionList?type=freshdeal",
    { headers: authAdminHeader() }
  );
};
const getAllPartnerReasonSuggestionList = () => {
  return axios.get(API_URL + "partner/getAllPartnerReasonSuggestionList", {
    headers: authAdminHeader(),
  });
};

const getAllPartnerBadgeSuggestionList = () => {
  return axios.get(API_URL + "partner/Badge/getAllPartnerBadgeSuggestionList", {
    headers: authAdminHeader(),
  });
};
const partnerCategoriesSuggestionList = () => {
  return axios.get(API_URL + "partner/categoriesSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAllMembershipPlanSuggestionList = () => {
  return axios.get(API_URL + "membership/getAllMembershipPlanSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getAcceptedCollaboratorsSuggestionList = () => {
  return axios.get(API_URL + "getAcceptedCollaboratorsSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getPendingCollaboratorsSuggestionList = () => {
  return axios.get(API_URL + "getPendingCollaboratorsSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getRevokedCollaboratorsSuggestionList = () => {
  return axios.get(API_URL + "getRevokedCollaboratorsSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getPartnerReviewSuggestionList = () => {
  return axios.get(API_URL + "partnerReview/getPartnerReviewSuggestionList", {
    headers: authAdminHeader(),
  });
};

const getContentVideoSuggestionList = () => {
  return axios.get(API_URL + "content-archive/getContentVideoSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getDeletedContentVideoSuggestionList = () => {
  return axios.get(
    API_URL + "content-archive/getDeletedContentVideoSuggestionList",
    { headers: authAdminHeader() }
  );
};
const eventSuggestionList = () => {
  return axios.get(API_URL + "eventSuggestionList", {
    headers: authAdminHeader(),
  });
};

const getCategoriesSuggestionList = () => {
  return axios.get(API_URL + "AS/getCategoriesSuggestionList", {
    headers: authAdminHeader(),
  });
};
const tagSuggestionList = () => {
  return axios.get(API_URL + "tagSuggestionList", {
    headers: authAdminHeader(),
  });
};
const getPartnerPostByPartnerIdSuggestionList = () => {
  return axios.get(
    API_URL +
    "partner/post/getPartnerPostByPartnerIdSuggestionList/6537bd6639a12b3e28aa222d",
    { headers: authAdminHeader() }
  );
};
const getPartnerReviewByIdSuggestionList = () => {
  return axios.get(
    API_URL + "getPartnerReviewByIdSuggestionList/651e9f16c814e10ceb9fed60",
    { headers: authAdminHeader() }
  );
};
const getPartnerhelpfulLinksByPartnerIdSuggestionList = () => {
  return axios.get(
    API_URL +
    "partner/helpfullinks/getPartnerhelpfulLinksByPartnerIdSuggestionList/65239c9a3b70954ef6475ea8",
    { headers: authAdminHeader() }
  );
};
const getVideoListByPartnerSuggestionList = () => {
  return axios.get(
    API_URL + "getVideoListByPartnerSuggestionList/65239c9a3b70954ef6475ea8",
    { headers: authAdminHeader() }
  );
};

const deletequestion = (data) => {
  return axios.put(API_URL + "question/delete/" + data);
};

const updatequestion = (data) => {
  return axios.put(API_URL + "question/edit/" + data.id, data.data);
};
const getquestionbyid = (id) => {
  return axios.get(API_URL + "question/" + id);
};
const addpost = (data) => {
  return axios.post(API_URL + "post/create", data, {
    headers: authHeader(),
  });
};
const addpost_admin = (data) => {
  return axios.post(API_URL + "AS/post/create", data, {
    headers: authHeader(),
  });
};
const getallpost = (page) => {
  return axios.get(API_URL + "post/view/all?page=" + page + "&limit=20", {
    headers: authHeader(),
  });
};
const getsinglepost = (postid) => {
  return axios.get(API_URL + "post/" + postid, { headers: authHeader() });
};
const getsinglepost_admin = (postid) => {
  return axios.get(API_URL + "AS/post/" + postid, {
    headers: authAdminHeader(),
  });
};
const editpost = (data) => {
  return axios.put(API_URL + "post/edit/" + data.editid, data.data, {
    headers: authHeader(),
  });
};
const editpost_admin = (data) => {
  return axios.put(API_URL + "AS/post/edit/" + data.editid, data.data, {
    headers: authHeader(),
  });
};
const deletepost = (data) => {
  return axios.put(
    API_URL + "post/delete",
    { ...data },
    {
      headers: authHeader(),
    }
  );
};
const deletepost_admin = (data) => {
  return axios.put(
    API_URL + "AS/post/delete",
    { ...data },
    {
      headers: authAdminHeader(),
    }
  );
};
const likepost = (postId) => {
  return axios.put(
    API_URL + "post/like/" + postId,
    {},
    { headers: authAdminHeader() }
  );
};
const likepost_admin = (postId) => {
  return axios.put(
    API_URL + "AS/post/like/" + postId,
    {},
    { headers: authAdminHeader() }
  );
};
const addcomment = (data) => {
  return axios.post(
    API_URL + "post/" + data.postId + "/comment",
    { ...data.data },
    {
      headers: authHeader(),
    }
  );
};
const addcomment_admin = (data) => {
  return axios.post(
    API_URL + "AS/post/" + data.postId + "/comment",
    { ...data.data },
    {
      headers: authAdminHeader(),
    }
  );
};
const getalltopics = () => {
  return axios.get(API_URL + "topic/all", {
    headers: authAdminHeader(),
  });
};

const getallfeelings = () => {
  return axios.get(API_URL + "post/feelingsactivity");
};

const getallcomments = (data) => {
  return axios.get(
    API_URL +
    "post/" +
    data.postid +
    "/comments/replies/all?page=" +
    data.page +
    "&limit=10",
    {
      headers: authHeader(),
    }
  );
};
const getallcomments_admin = (data) => {
  return axios.get(
    API_URL +
    "AS/post/" +
    data.postid +
    "/comments/replies/all?page=" +
    data.page +
    "&limit=10",
    {
      headers: authAdminHeader(),
    }
  );
};
const likecomment = (data) => {
  return axios.put(
    API_URL + "post/" + data.postId + "/comments/" + data.commId + "/like",
    { userId: localStorage.getItem("userid") },
    { headers: authHeader() }
  );
};
const likecomment_admin = (data) => {
  return axios.put(
    API_URL + "AS/post/" + data.postId + "/comments/" + data.commId + "/like",
    { userId: localStorage.getItem("adminuserid") },
    { headers: authAdminHeader() }
  );
};

const createreply = (data) => {
  return axios.post(
    API_URL + "post/" + data.postId + "/comments/" + data.commId + "/replies",
    { content: data.content, user_type: data.user_type },
    { headers: authHeader() }
  );
};
const createreply_admin = (data) => {
  return axios.post(
    API_URL +
    "AS/post/" +
    data.postId +
    "/comments/" +
    data.commId +
    "/replies",
    { content: data.content, user_type: data.user_type },
    { headers: authAdminHeader() }
  );
};
const deletecomment = (data) => {
  return axios.delete(
    API_URL + "post/" + data.postId + "/comments/" + data.commId + "/delete",
    { headers: authHeader() }
  );
};
const deletecomment_admin = (data) => {
  return axios.delete(
    API_URL + "AS/post/" + data.postId + "/comments/" + data.commId + "/delete",
    { headers: authAdminHeader() }
  );
};
const addvote = (data) => {
  return axios.put(
    API_URL + "post/" + data.postid + "/pollvote",
    { pollChoicesID: data.pollChoicesID },
    { headers: authHeader() }
  );
};
const addvote_admin = (data) => {
  return axios.put(
    API_URL + "AS/post/" + data.postid + "/pollvote",
    { pollChoicesID: data.pollChoicesID },
    { headers: authAdminHeader() }
  );
};
const savepost = (postid) => {
  return axios.put(
    API_URL + "post/saved/" + postid,
    {},
    { headers: authAdminHeader() }
  );
};
const getsavedpost = () => {
  return axios.get(API_URL + "post/saved/all", { headers: authHeader() });
};
const creategroup = (data) => {
  return axios.post(API_URL + "group/create", data, {
    headers: authAdminHeader(),
  });
};
const getallgroup = (data) => {
  return axios.get(API_URL + "groups/all", { headers: authAdminHeader() });
};
const getgroupbyid = (groupid) => {
  return axios.get(API_URL + "group/" + groupid, { headers: authHeader() });
};
const getadmingroupbyid = (groupid) => {
  return axios.get(API_URL + "group_byadmin/" + groupid, {
    headers: authAdminHeader(),
  });
};
const updategroup = (data) => {
  return axios.put(API_URL + "group/" + data.groupid + "/update", data.data, {
    headers: authHeader(),
  });
};
const updategroupimg = (data) => {
  return axios.put(
    API_URL + "group/" + data.groupid + "/update/profileImg",
    data.data,
    { headers: authHeader() }
  );
};
const deletegroup = (groupid) => {
  return axios.delete(API_URL + "group/delete/" + groupid, {
    headers: authAdminHeader(),
  });
};
const groupsetting = (data) => {
  return axios.put(API_URL + "group/" + data.groupid + "/settings", data.data, {
    headers: authHeader(),
  });
};

const createtopic = (data) => {
  return axios.post(API_URL + "topic/create", data);
};
const deletetopic = (topicid) => {
  return axios.delete(API_URL + "topic/delete/" + topicid);
};
const gettopicbyid = (topicid) => {
  return axios.get(API_URL + "topic/" + topicid);
};
const edittopic = (data) => {
  return axios.put(API_URL + "topic/edit/" + data.topicid, data.data);
};
const groupmembers = (groupid) => {
  return axios.get(API_URL + "group/getmembers/" + groupid, {
    headers: authHeader(),
  });
};
const groupmembers_admin = (groupid) => {
  return axios.get(API_URL + "AS/group/getmembers/" + groupid, {
    headers: authAdminHeader(),
  });
};
const gettopicsbygroupid = (groupid) => {
  return axios.get(API_URL + "topic/bygroup/" + groupid, {
    headers: authHeader(),
  });
};
const gettopicsbygroupid_byadmin = (groupid) => {
  return axios.get(API_URL + "topic/bygroup_byadmin/" + groupid, {
    headers: authAdminHeader(),
  });
};
const savetoken = (data) => {
  return axios.put(API_URL + "user/save/token", data, {
    headers: authHeader(),
  });
};
const getpostbygroupid = (data) => {
  return axios.get(
    API_URL +
    "AS/group/" +
    data.groupid +
    "/allPost?page=" +
    data.page +
    "&limit=20",
    { headers: authAdminHeader() }
  );
};
const getpostbygroupid_user = (data) => {
  return axios.get(
    API_URL +
    "group/" +
    data.groupid +
    "/allPost?page=" +
    data.page +
    "&limit=20",
    { headers: authHeader() }
  );
};
const getpostbygroupidtopicid_user = (data) => {
  return axios.get(
    API_URL +
    "group/" +
    data.groupid +
    "/" +
    data.topicid +
    "/allPost?page=" +
    data.page +
    "&limit=20",
    { headers: authHeader() }
  );
};
const hidefromfeedpost = (data) => {
  return axios.put(
    API_URL + "post/hideFromFeed/" + data.postid + "/group/" + data.groupid,
    {},
    { headers: authAdminHeader() }
  );
};
const clonegroup = (data) => {
  return axios.post(API_URL + "group/clone", data, {
    headers: authAdminHeader(),
  });
};
const deletemember = (data) => {
  return axios.put(
    API_URL + "group/" + data.groupId + "/memberDelete/" + data.memberId,
    {},
    { headers: authHeader() }
  );
};
const getannouncementbygroupid = (data) => {
  return axios.get(
    API_URL +
    "group/" +
    data.groupid +
    "/annoucements?page=" +
    data.page +
    "&limit=5",
    { headers: authHeader() }
  );
};
const makeannoucement = (data) => {
  console.log(data.postid, data.groupid);
  return axios.put(
    API_URL + "post/makeannoucement/" + data.postid + "/group/" + data.groupid,
    {},
    { headers: authAdminHeader() }
  );
};
const gethiddenpost = (data) => {
  return axios.get(
    API_URL +
    "group/" +
    data.groupid +
    "/hidePosts?page=" +
    data.page +
    "&limit=5",
    { headers: authHeader() }
  );
};
const getAllPlan = (query) => {
  return axios.get(API_URL + "membership/plan" + query, {
    headers: authAdminHeader(),
  });
};
const getPlanService = () => {
  return axios.get(API_URL + "membership/list", { headers: authAdminHeader() });
};
const deleteplan = (planId) => {
  return axios.put(
    API_URL + "membership/plan/delete/" + planId,
    {},
    { headers: authAdminHeader() }
  );
};
const getlinkedinuser = (data) => {
  return axios.post(API_URL + "user/getlinkedinuserdetails", data);
};
const getresource = () => {
  return axios.get(API_URL + "resources/all");
};
const createmembershipplan = (data) => {
  return axios.post(API_URL + "membership/plan/create", data, {
    headers: authAdminHeader(),
  });
};
const getplanbyid = (id) => {
  return axios.get(API_URL + "membership/plan/" + id, {
    headers: authAdminHeader(),
  });
};
const editplan = (data) => {
  return axios.put(API_URL + "membership/plan/update/" + data.id, data.data, {
    headers: authAdminHeader(),
  });
};
const getstarredgroup = () => {
  return axios.get(API_URL + "group/starred/list", { headers: authHeader() });
};
const getstarredtopic = () => {
  return axios.get(API_URL + "topic/starred/list", { headers: authHeader() });
};
const getallgrouploginuser = () => {
  return axios.get(API_URL + "user/group/all", { headers: authHeader() });
};
const addstargroup = (data) => {
  return axios.post(API_URL + "group/starred", data, { headers: authHeader() });
};
const addstartopic = (data) => {
  return axios.post(API_URL + "topic/starred", data, { headers: authHeader() });
};
const removestargroup = (groupid) => {
  return axios.delete(API_URL + "group/remove/starred/" + groupid, {
    headers: authHeader(),
  });
};
const removestartopic = (topicid) => {
  return axios.delete(API_URL + "topic/remove/starred/" + topicid, {
    headers: authHeader(),
  });
};
const joingroup = (data) => {
  return axios.post(API_URL + "group/join", data, { headers: authHeader() });
};
const leavegroup = (groupid) => {
  return axios.put(
    API_URL + "group/" + groupid + "/leave",
    {},
    { headers: authHeader() }
  );
};
const getallannoucements = (page) => {
  return axios.get(API_URL + "post/annoucements/forUser", {
    headers: authHeader(),
  });
};
const getmemberprofile = (member_uid) => {
  return axios.get(API_URL + "user/get/member/" + member_uid, {
    headers: authHeader(),
  });
};
const getcommongroup = (member_uid) => {
  return axios.get(API_URL + "user/get/commonGroup/oFmember/" + member_uid, {
    headers: authHeader(),
  });
};
const getjoinedgroupothermember = (member_uid) => {
  return axios.get(API_URL + "user/get/member/joingroups/" + member_uid, {
    headers: authHeader(),
  });
};
const getmydetail = () => {
  return axios.get(API_URL + "user/get", { headers: authHeader() });
};
const othermemberfollowers = (member_uid) => {
  return axios.get(API_URL + "member/followers/" + member_uid, {
    headers: authHeader(),
  });
};
const othermemberfollowing = (member_uid) => {
  return axios.get(API_URL + "member/following/" + member_uid, {
    headers: authHeader(),
  });
};
const getuserfollower = () => {
  return axios.get(API_URL + "user/followers", { headers: authHeader() });
};
const getuserfollowing = () => {
  return axios.get(API_URL + "user/following", { headers: authHeader() });
};
const acceptfollowreq = (memberid) => {
  return axios.put(
    API_URL + "accept_follow_request/" + memberid,
    {},
    { headers: authHeader() }
  );
};
const sendfollowrequest = (member_uid) => {
  return axios.post(
    API_URL + "send/follow_request/" + member_uid,
    {},
    { headers: authHeader() }
  );
};
const sendunfollowrequest = (member_uid) => {
  return axios.put(
    API_URL + "unfollow_user/" + member_uid,
    {},
    { headers: authHeader() }
  );
};
const cancelfollowrequest = (member_uid) => {
  return axios.delete(API_URL + "undo/follow/request/" + member_uid, {
    headers: authHeader(),
  });
};
const memberposts = (member_uid) => {
  return axios.get(API_URL + "user/get/member/posts/" + member_uid, {
    headers: authHeader(),
  });
};
const groupmedia = (gid) => {
  return axios.get(API_URL + "group/" + gid + "/allPost/media", {
    headers: authHeader(),
  });
};
const membermedia = (mid) => {
  return axios.get(API_URL + "user/media/" + mid, { headers: authHeader() });
};
const myjoinedgroup = () => {
  return axios.get(API_URL + "user/join/group", { headers: authHeader() });
};
const accessjoinedgroup = () => {
  return axios.get(API_URL + "user/join/access/group", {
    headers: authHeader(),
  });
};
const myallpost = () => {
  return axios.get(API_URL + "post/all/byuser", { headers: authHeader() });
};
const getrequestslist = () => {
  return axios.get(API_URL + "user/request/list", { headers: authHeader() });
};
const rejectrequest = (member_id) => {
  return axios.delete(API_URL + "cancel/followRequest/" + member_id, {
    headers: authHeader(),
  });
};
const editprofile = (data) => {
  return axios.put(API_URL + "user/edit", data, { headers: authHeader() });
};
const getsentrequestlist = () => {
  return axios.get(API_URL + "sendbyuser/request/list", {
    headers: authHeader(),
  });
};
const getalltopicsforpost = () => {
  return axios.get(API_URL + "topic/groupby/list", { headers: authHeader() });
};
const groupfileupload = (data) => {
  return axios.post(
    API_URL + "group/" + data.groupid + "/uploadFile",
    data.data,
    { headers: authHeader() }
  );
};
const getuploadedfiles = (groupid) => {
  return axios.get(API_URL + "group/" + groupid + "/files", {
    headers: authHeader(),
  });
};
const deleteuploadfiles = (data) => {
  return axios.delete(
    API_URL + "group/" + data.group_id + "/remove/file/" + data.file_id,
    { headers: authHeader() }
  );
};
const setlastlogin = () => {
  return axios.put(
    API_URL + "user/manage/lastlogin",
    {},
    { headers: authHeader() }
  );
};
const activeInactiveUser = (data) => {
  return axios.put(API_URL + "user/active", data, {
    headers: authAdminHeader(),
  });
};
const getfriendlist = () => {
  return axios.get(API_URL + "user/friendlist", { headers: authHeader() });
};
const getlatestfiles = () => {
  return axios.get(API_URL + "groups/latest/files", { headers: authHeader() });
};
const getbillinginfo = () => {
  return axios.get(API_URL + "user/purchased_plan", { headers: authHeader() });
};
const savereorderindb = (data) => {
  return axios.put(API_URL + "question/reorder", data);
};
const getalladmin = () => {
  return axios.get(API_URL + "admin_users", { headers: authAdminHeader() });
};
const getallresource = () => {
  return axios.get(API_URL + "resources", { headers: authAdminHeader() });
};
const getallroles = () => {
  return axios.get(API_URL + "roles", { headers: authAdminHeader() });
};
const createadmin = (data) => {
  return axios.post(API_URL + "admin_user/create", data, {
    headers: authAdminHeader(),
  });
};
const getadminbyid = (id) => {
  return axios.get(API_URL + "admin_user/" + id, { headers: authHeader() });
};
const editadmindetail = (data) => {
  return axios.patch(API_URL + "admin_user/" + data.id + "/edit", data.data, {
    headers: authHeader(),
  });
};
const deleteadmin = (id) => {
  return axios.patch(
    API_URL + "admin_user/" + id + "/delete",
    {},
    {
      headers: authAdminHeader(),
    }
  );
};
const getallcontentcategories = () => {
  return axios.get(API_URL + "categories", { headers: authHeader() });
};
const getallcontentcategory_byadmin = (query) => {
  return axios.get(API_URL + "AS/categories" + query, {
    headers: authAdminHeader(),
  });
};
const createcontentcategory = (data) => {
  return axios.post(API_URL + "category/create", data, {
    headers: authAdminHeader(),
  });
};

const checkAlredayExistSubCategory = (data) => {
  return axios.post(API_URL + "category/checkAlredayExistSubCategory", data, {
    headers: authAdminHeader(),
  });
};

const editSubCategory = (id, data) => {
  return axios.post(API_URL + "category/subCategory/edit/" + id, data, {
    headers: authAdminHeader(),
  });
};

const deleteCategory = (id) => {
  return axios.delete(API_URL + "delete/categories/" + id, {
    headers: authAdminHeader(),
  });
};
const editCategory = (id, data) => {
  return axios.put(API_URL + "category/edit/" + id, data, {
    headers: authAdminHeader(),
  });
};
const getcategorybyid = (id) => {
  return axios.get(API_URL + "category/" + id);
};
const createvideo = (data) => {
  return axios.post(API_URL + "video/create", data, {
    headers: authAdminHeader(),
  });
};
const editvideo = (data) => {
  return axios.put(API_URL + "video/edit", data, {
    headers: authAdminHeader(),
  });
};
const getallcontentvideos = (page, tag) => {
  return axios.get(
    API_URL + "content-archive/videos?page=" + page + "&limit=20&tag=" + tag,
    { headers: authAdminHeader() }
  );
};
const getallcontentvideosuser = (page) => {
  return axios.get(
    API_URL + "content-archive/user/videos?page=" + page + "&limit=20",
    { headers: authHeader() }
  );
};
const getcontentvideobyid = (id) => {
  return axios.get(API_URL + "content-archive/video/" + id, {
    headers: authAdminHeader(),
  });
};
const getcontentvideobyidForUser = (id) => {
  return axios.get(API_URL + "content-archive/video/foruser/" + id, {
    headers: authHeader(),
  });
};
const videodetailLog = (data) => {
  return axios.post(API_URL + "video/create-video-error-log", data, {
    headers: authHeader(),
  });
}
const deletecontentvideobyId = (id) => {
  return axios.patch(
    API_URL + "video/delete/" + id,
    {},
    { headers: authAdminHeader() }
  );
};
const getalldeletedvideos = () => {
  return axios.get(API_URL + "content-archive/deletedvideos", {
    headers: authAdminHeader(),
  });
};
const restorevideobyid = (id) => {
  return axios.patch(
    API_URL + "video/restore/" + id,
    {},
    { headers: authAdminHeader() }
  );
};
const permenantdeletevideobyid = (id) => {
  return axios.delete(API_URL + "permenant-delete/content-arcive/" + id, {
    headers: authAdminHeader(),
  });
};
const addpausevideobyadmin = (data) => {
  return axios.patch(API_URL + "add-pause-video/" + data.id, data.data, {
    headers: authAdminHeader(),
  });
};
const addpausevideobyuser = (data) => {
  return axios.patch(API_URL + "add-pause-video/user/" + data.id, data.data, {
    headers: authHeader(),
  });
};
const addviewbyadmin = (id, platform) => {
  return axios.patch(
    API_URL + "AS/add-view/" + id + "?platform=" + platform,
    {},
    { headers: authAdminHeader() }
  );
};
const addviewbyuser = (id, platform) => {
  return axios.patch(
    API_URL + "add-view/" + id + "?platform=" + platform,
    {},
    { headers: authHeader() }
  );
};
const addlikebyadmin = (id) => {
  return axios.patch(
    API_URL + "AS/add-like/" + id,
    {},
    { headers: authAdminHeader() }
  );
};
const addlikebyuser = (id, data) => {
  return axios.patch(API_URL + "add-like/" + id, data, {
    headers: authHeader(),
  });
};
const addcomment_video = (data) => {
  return axios.post(
    API_URL + "video/" + data.videoId + "/comment",
    { ...data.data },
    {
      headers: authHeader(),
    }
  );
};
const addcomment_videoadmin = (data) => {
  return axios.post(
    API_URL + "AS/video/" + data.videoId + "/comment",
    { ...data.data },
    {
      headers: authAdminHeader(),
    }
  );
};
const getallcomments_video = (data) => {
  return axios.get(
    API_URL +
    "video/" +
    data.videoid +
    "/comments/replies/all?page=" +
    data.page +
    `&limit=${data.limit !== undefined && data.limit !== null && data.limit > 10
      ? data.limit
      : 10
    }`,
    {
      headers: authHeader(),
    }
  );
};
const getallcomments_videoadmin = (data) => {
  return axios.get(
    API_URL +
    "AS/video/" +
    data.videoid +
    "/comments/replies/all?page=" +
    data.page +
    `&limit=${data.limit !== undefined && data.limit !== null && data.limit > 10
      ? data.limit
      : 10
    }`,
    {
      headers: authAdminHeader(),
    }
  );
};
const likecomment_video = (data) => {
  return axios.put(
    API_URL + "video/" + data.videoId + "/comments/" + data.commId + "/like",
    { userId: localStorage.getItem("userid") },
    { headers: authHeader() }
  );
};
const likecomment_adminvideo = (data) => {
  return axios.put(
    API_URL + "AS/video/" + data.videoId + "/comments/" + data.commId + "/like",
    { userId: localStorage.getItem("adminuserid") },
    { headers: authAdminHeader() }
  );
};
const createreply_video = (data) => {
  return axios.post(
    API_URL + "video/" + data.videoId + "/comments/" + data.commId + "/replies",
    {
      content: data.content,
      user_type: data.user_type,
      createdAt: data.createdAt,
    },
    { headers: authHeader() }
  );
};
const createreply_adminvideo = (data) => {
  return axios.post(
    API_URL +
    "AS/video/" +
    data.videoId +
    "/comments/" +
    data.commId +
    "/replies",
    {
      content: data.content,
      user_type: data.user_type,
      createdAt: data.createdAt,
    },
    { headers: authAdminHeader() }
  );
};
const deletecomment_video = (data) => {
  return axios.delete(
    API_URL + "video/" + data.videoId + "/comments/" + data.commId + "/delete",
    { headers: authHeader() }
  );
};
const deletecomment_adminvideo = (data) => {
  return axios.delete(
    API_URL +
    "AS/video/" +
    data.videoId +
    "/comments/" +
    data.commId +
    "/delete",
    { headers: authAdminHeader() }
  );
};
const getalluseremailname = () => {
  return axios.get(API_URL + "user/getalluseremailname", {
    headers: authHeader(),
  });
};
const getalluseremailname_admin = () => {
  return axios.get(API_URL + "user/AS/getalluseremailname", {
    headers: authAdminHeader(),
  });
};
const getrecentlyaddedvideos = () => {
  return axios.get(API_URL + "getrecentlyadded", { headers: authHeader() });
};
const getrelatedvideos = (data) => {
  return axios.get(API_URL + "getrelatedvideos/" + data, {
    headers: authHeader(),
  });
  return axios.get(
    API_URL +
    "getrelatedvideos/" +
    data.id +
    "?page=" +
    data.page +
    "&limit=10",
    {
      headers: authHeader(),
    }
  );
};

const getRelatedVideosEvent = (query, data) => {
  return axios.post(API_URL + "/get-related-videos-event" + query, data, {
    headers: authHeader(),
  });
};
const editComment = (query, data) => {
  return axios.patch(API_URL + `/video/${query}/editComment`, data, {
    headers: authHeader(),
  });
};

const replyDeleteCommenmt = (mainId, replyId) => {
  return axios.delete(API_URL + `post/${mainId}/comments/${replyId}/delete`, {
    headers: authHeader(),
  });
};
const getnewestcommentedvideos = () => {
  return axios.get(API_URL + "getnewestcommentsvideos", {
    headers: authHeader(),
  });
};
const getmostpopularvideos = () => {
  return axios.get(API_URL + "getpopularvideos", { headers: authHeader() });
};
const getvideobycategories = (data) => {
  // console.log(data)
  return axios.get(
    API_URL +
    "getvideobycategories/" +
    data.id +
    "?page=" +
    data.page +
    "&limit=10",
    { headers: authHeader() }
  );
};
const getvideobycategory_byadmin = (data) => {
  return axios.get(
    API_URL +
    "AS/getvideobycategories/" +
    data.id +
    "?page=" +
    data.page +
    "&limit=10",
    { headers: authAdminHeader() }
  );
};
const getaddvideostatus = () => {
  return axios.get(API_URL + "getvideouploadstatus", { headers: authHeader() });
};
const sharepost = (data) => {
  return axios.get(API_URL + "sharepost/" + data, { headers: authHeader() });
};
const cancelPlan = () => {
  return axios.put(
    API_URL + "cancel/subscription",
    {},
    { headers: authHeader() }
  );
};
const updatePlan = (data) => {
  return axios.put(API_URL + "plan/upgrade", data, { headers: authHeader() });
};
const ChatUploadFiles = (data) => {
  return axios.post(CHAT_API_URL + "chat/upload/files", data, {
    headers: authHeader(),
  });
};
const chatdetail = (data) => {
  return axios.get(
    API_URL +
    "chat/getchatdetailweb/" +
    data.userid +
    "/" +
    data.type +
    "?page=" +
    data.page +
    "&limit=" +
    data.limit,
    { headers: authHeader() }
  );
};
const deletemessage = (id) => {
  return axios.delete(CHAT_API_URL + "chat/deletemessage/" + id, {
    headers: authHeader(),
  });
};
const getchatgroupmember = (id) => {
  return axios.get(CHAT_API_URL + "chat/getallmemeber/" + id, {
    headers: authHeader(),
  });
};
const starchat = (id) => {
  return axios.post(
    CHAT_API_URL + "chat/star/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};

const addMessageReport = (data) => {
  return axios.post(CHAT_API_URL + "chat/addmessagereport", data, {
    headers: authHeader(),
  });
};

const joinInviteLinkChannel = (data) => {
  return axios.post(CHAT_API_URL + "Join-Channel", data, {
    headers: authHeader(),
  });
}

const getmediafileslinks = (id) => {
  return axios.get(CHAT_API_URL + "chat/media/files/links/" + id, {
    headers: authHeader(),
  });
};
const updatemigrateduserdetail = (data) => {
  return axios.post(API_URL + "user/update/migrateduser", data);
};
const deletemigrateduser = (email) => {
  return axios.patch(API_URL + "user/delete/migrateduser/" + email);
};
const getauthtoken = () => {
  return axios.get(API_URL + "user/getauthtoken");
};
const deleteauthuser = (userid) => {
  return axios.delete(API_URL + "user/deleteauthuser/" + userid);
};
const savevideo = (videoid) => {
  return axios.put(
    API_URL + "video/saved/" + videoid,
    {},
    {
      headers: authHeader(),
    }
  );
};
const getsavedvideos = () => {
  return axios.get(API_URL + "video/saved/all", {
    headers: authHeader(),
  });
};
const getallaccessibletopics = () => {
  return axios.get(API_URL + "GS/topics", {
    headers: authHeader(),
  });
};
const getfriendlistglobalsearch = () => {
  return axios.get(API_URL + "GS/user-friends", { headers: authHeader() });
};
const globalsearch = (params) => {
  if (params.text && params.topic && params.user)
    return axios.get(
      API_URL +
      "GS/global-search?s=" +
      params.text +
      "&topic=" +
      params.topic +
      "&user=" +
      params.user,
      {
        headers: authHeader(),
      }
    );
  else if (params.text && params.topic)
    return axios.get(
      API_URL + "GS/global-search?s=" + params.text + "&topic=" + params.topic,
      {
        headers: authHeader(),
      }
    );
  else if (params.user && params.text)
    return axios.get(
      API_URL + "GS/global-search?s=" + params.text + "&user=" + params.user,
      {
        headers: authHeader(),
      }
    );
  else
    return axios.get(API_URL + "GS/global-search?s=" + params.text, {
      headers: authHeader(),
    });
};
const contentarchivefilter = (data) => {
  console.log(data, "data");
  return axios.get(
    API_URL +
    "getVideoByCategoriesFilter?categorie_id=" +
    data.cat_id +
    "&subcategorie_id=" +
    data.subcategorie_id +
    "&filter=" +
    data.filter +
    "&page=" +
    data.page +
    "&limit=20",
    {
      headers: authHeader(),
    }
  );
};
const getauthuser = (data) => {
  return axios.get(API_URL + "user/getuserfromauth/" + data);
};
const getAuthUserDetail = (data) => {
  return axios.get(API_URL + "user/getuserInfofromauth/" + data);
};
const isAuthUserExists = (data) => {
  return axios.post(API_URL + "user/isAuthUserExists", data);
};
const updateMigrationCaseStatus = (data) => {
  return axios.post(API_URL + "updateMigrationCaseStatus", data);
};
const verifyOTP = (data) => {
  return axios.post(API_URL + "user/verify", data);
};
const resendOTP = (data) => {
  return axios.post(API_URL + "user/resend", data);
};
const blockChat = (data) => {
  return axios.post(
    CHAT_API_URL + "chat/block/" + data,
    {},
    {
      headers: authHeader(),
    }
  );
};
const appletokenconvertdata = (data) => {
  return axios.post(API_URL + "user/appletokenconvertdata", data);
};
const getalluser_forfrontend = () => {
  return axios.get(API_URL + "user/getAllValidUser", { headers: authHeader() });
};
const getstarchatusers = () => {
  return axios.get(CHAT_API_URL + "chat/getstarchat", {
    headers: authHeader(),
  });
};

const getMuteChatIdListForUser = () => {
  return axios.get(CHAT_API_URL + "getMuteChatIdListForUser ", {
    headers: authHeader(),
  });
};

const muteChatForAll = (data) => {
  return axios.post(CHAT_API_URL + "muteUnmuteChatWithTimeLimit", data, {
    headers: authHeader(),
  });
};

const badgesForNotification = (data) => {
  return axios.post(CHAT_API_URL + "changeBadgeArray", data, {
    headers: authHeader(),
  });
};

const clearchat = (data) => {
  return axios.post(CHAT_API_URL + "chat/clearChat", data, {
    headers: authHeader(),
  });
};

const addcontactuser = (data) => {
  return axios.post(API_URL + "user/addContactUser", data, {
    headers: authHeader(),
  });
};

const getcontactuserslist = () => {
  return axios.get(API_URL + "user/getContactUserList", {
    headers: authHeader(),
  });
};

const senddeactivaterequest = () => {
  return axios.get(API_URL + "user/sendDeactivateRequest", {
    headers: authHeader(),
  });
};

const searchmessages = (data) => {
  return axios.post(CHAT_API_URL + "chat/searchChat", data, {
    headers: authHeader(),
  });
};
const getallspeaker_byadmin = () => {
  return axios.get(API_URL + "speakerList", { headers: authHeader() });
};
const getspeakerbyid = (id) => {
  return axios.get(API_URL + "speakerById/" + id, { headers: authHeader() });
};
const deleteSpeaker = (id) => {
  return axios.post(
    API_URL + "speaker/delete/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};
const createspeaker = (data) => {
  return axios.post(API_URL + "createSpeaker", data, {
    headers: authHeader(),
  });
};
const createSpeakerInUserChanges = (data) => {
  return axios.post(API_URL + "createSpeakerInUser", data, {
    headers: authAdminHeader(),
  });
};
const editSpeaker = (data) => {
  return axios.put(API_URL + "speaker/edit/" + data.id, data.data, {
    headers: authHeader(),
  });
};
const getalltags_byadmin = (query) => {
  return axios.get(API_URL + "tagList?" + query, {
    headers: authAdminHeader(),
  });
};
const gettagbyid = (id) => {
  return axios.get(API_URL + "tagById/" + id, { headers: authAdminHeader() });
};
const deleteTag = (id) => {
  return axios.post(
    API_URL + "tag/delete/" + id,
    {},
    {
      headers: authAdminHeader(),
    }
  );
};
const createtag = (data) => {
  return axios.post(API_URL + "createTag", data, {
    headers: authAdminHeader(),
  });
};
const editTag = (data) => {
  return axios.put(API_URL + "tag/edit/" + data.id, data.data, {
    headers: authAdminHeader(),
  });
};
const getAllVideoList = () => {
  return axios.get(API_URL + "allVideo", {
    headers: authHeader(),
  });
};
const addSearchHistory = (data) => {
  return axios.post(API_URL + "addSearch", data, {
    headers: authHeader(),
  });
};
const removeSearchHistory = (data) => {
  return axios.post(
    API_URL + "removeSearch/" + data.id,
    {},
    {
      headers: authHeader(),
    }
  );
};

const getSearchHistory = (data) => {
  return axios.get(API_URL + "topSearch", {
    headers: authHeader(),
  });
};
const getVideoBySearchFilterAndCategory = (data) => {
  return axios.get(
    API_URL +
    "getVideoByCategoriesFilter?categorie_id=" +
    data.cat_id +
    "&filter=" +
    data.filter +
    "&search=" +
    data.search +
    "&subcategorie_id=" +
    data.subcategorie_id +
    "&page=" +
    data.page +
    "&limit=20",
    {
      headers: authHeader(),
    }
  );
};
const getUrlMetadata = (data) => {
  return axios.get(API_URL + "getMetaData?urlData=" + data);
};
const createGroupChat = (data) => {
  return axios.post(CHAT_API_URL + "chat/creategroup", data, {
    headers: authHeader(),
  });
};

const getGroupAndMemberDetails = (data) => {
  return axios.get(CHAT_API_URL + "chat/group/getgroupmember/" + data, {
    headers: authHeader(),
  });
};

const updateGroupInfo = (data) => {
  return axios.put(CHAT_API_URL + "chat/editgroup/" + data.id, data.data, {
    headers: authHeader(),
  });
};

const addMemberGroupChat = (data) => {
  return axios.put(
    CHAT_API_URL + "chat/group/addmember/" + data.id,
    data.data,
    {
      headers: authHeader(),
    }
  );
};
const removeMemberGroupChat = (data) => {
  return axios.put(
    CHAT_API_URL + "chat/group/removemember/" + data.id,
    data.data,
    {
      headers: authHeader(),
    }
  );
};

const deleteGroupChat = (id) => {
  return axios.post(
    CHAT_API_URL + "chat/deletegroup/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};
const leaveGroupChat = (id) => {
  return axios.put(
    CHAT_API_URL + "chat/leaveGroup/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};
const uploadGroupImage = (data) => {
  return axios.post(CHAT_API_URL + "chat/group/upload/files", data, {
    headers: authHeader(),
  });
};

const getChatGroupById = (id) => {
  return axios.get(CHAT_API_URL + "chat/group/getgroupbyid/" + id, {
    headers: authHeader(),
  });
};

const getChatUserById = (id) => {
  return axios.get(CHAT_API_URL + "chat/getallmemeber/" + id, {
    headers: authHeader(),
  });
};
const getMessageDetail = (id) => {
  return axios.get(CHAT_API_URL + "chat/getmessagedetail/" + id, {
    headers: authHeader(),
  });
};

const getDataSyncUser = () => {
  return axios.get(API_URL + "user/getdatasyncuser");
};
const updateDataSyncUser = (data) => {
  return axios.post(API_URL + "user/updatedatasyncuser", data);
};
const updateRegistrationDetailOnDashboard = () => {
  return axios.post(
    API_URL + "/updateRegistrationDetailOnDashboard",
    {},
    { headers: authHeader() }
  );
};

const getAllValidUserAndAttendees = () => {
  return axios.get(API_URL + "user/getAllValidUserAndAttendees", {
    headers: authHeader(),
  });
};
const getAllValidUserAPI = () => {
  return axios.get(CHAT_API_URL + "/getAllValidUser", {
    headers: authHeader(),
  });
};
const deleteAttendeefromAllAttendeeList = (id) => {
  return axios.patch(
    API_URL + "delete/attendee/fromAllAttendeeList/" + id,
    {},
    { headers: authAdminHeader() }
  );
};

//partner list for main page
const getFeaturedPartnerList = () => {
  return axios.get(
    API_URL +
    "/user/partner/featuredOrFreshDealPartnersListForUser?type=featured",
    { headers: authHeader() }
  );
};

const getFreshDealPartnerList = () => {
  return axios.get(
    API_URL +
    "/user/partner/featuredOrFreshDealPartnersListForUser?type=freshdeal",
    { headers: authHeader() }
  );
};
const getSpeakerList = () => {
  return axios.get(API_URL + "user/getAllSpeakerList", {
    headers: authAdminHeader(),
  });
};
const getPartnerDetail = (id) => {
  return axios.get(API_URL + "getPartnerDetails/" + id, {
    headers: authHeader(),
  });
};
const getPartnerDetailReview = (id) => {
  return axios.get(API_URL + "getPartnerReviewDetails/" + id, {
    headers: authHeader(),
  });
};
const addPartnerReview = (data) => {
  return axios.post(API_URL + "createPartnerReview", data, {
    headers: authHeader(),
  });
};
const getOfferDetailsCount = (id) => {
  return axios.get(API_URL + "getClaimOfferDetails/" + id, {
    headers: authHeader(),
  });
};
const updateUserViewCountOfPartner = (id) => {
  return axios.get(API_URL + "updateUserViewCountOfPartner/" + id, {
    headers: authHeader(),
  });
};
const updateUserClaimCountOfPartner = (id) => {
  return axios.get(API_URL + "updateUserClaimCountOfPartner/" + id, {
    headers: authHeader(),
  });
};
const getPartnerDetailPosts = (id) => {
  return axios.get(API_URL + "getPartnerDetailPosts/" + id, {
    headers: authHeader(),
  });
};
const getPartnerDetailVideos = (id) => {
  return axios.get(API_URL + "getPartnerDetailVideos/" + id, {
    headers: authHeader(),
  });
};
const getPartnersInOtherCategories = (id) => {
  return axios.get(API_URL + "getPartnersInOtherCategories/" + id, {
    headers: authHeader(),
  });
};
const getPartnerBadges = () => {
  return axios.get(API_URL + "partner/Badge/partnerBadgelist", {
    headers: authHeader(),
  });
};
const getPartnerSearchHistory = (type) => {
  return axios.get(API_URL + "topPartnerSearch" + type, {
    headers: authHeader(),
  });
};

const removetPartnerSearchHistory = (id) => {
  return axios.post(
    API_URL + "removePartnerSearch/" + id,
    {},
    { headers: authHeader() }
  );
};
const addPartnerSearchHistory = (name) => {
  return axios.post(API_URL + "addPartnerSearch", name, {
    headers: authHeader(),
  });
};
const allPartnerList = (data) => {
  return axios.get(API_URL + "allPartnerList" + data, {
    headers: authHeader(),
  });
};
const commonMediaUpload = (media) => {
  return axios.post(API_URL + "common/images", media, {
    headers: authAdminHeader(),
  });
};
const getCommonImages = () => {
  return axios.get(API_URL + "commonImages", { headers: authAdminHeader() });
};

//Getting Sharing short url
const getShortShareLink = (key, requestBody) => {
  return axios.post(FIREBASE_API_URL + key, requestBody);
};

const allEventList = () => {
  return axios.get(API_URL + "event/getPastEventNameList", {
    headers: authAdminHeader(),
  });
};

const partnerBannerDetail = () => {
  return axios.get(API_URL + "getPartnerBanner", { headers: authHeader() });
};
const getAllPlanResource = () => {
  return axios.get(API_URL + "getAllAccessResource", {
    headers: authAdminHeader(),
  });
};

const getDaySinceMDSOnly = () => {
  return axios.get(API_URL + "getDaysSinceMDSOnlyCensus", {
    headers: authHeader(),
  });
};

// Getting not access screen
const notAccessScreen = (data) => {
  return axios.post(API_URL + "video/checkVideoAccess", data, {
    headers: authHeader(),
  });
};

// Getting not access screen
const notChatAccessScreen = (data) => {
  return axios.post(API_URL + "chat/checkChatAccess", data, {
    headers: authHeader(),
  });
};
const selectPartnerList = (search) => {
  return axios.get(API_URL + "partner/selectPartnerlist?search=" + search, {
    headers: authHeader(),
  });
};
const reviewCountByUser = () => {
  return axios.get(API_URL + "partner/reviewCountByUserId", {
    headers: authHeader(),
  });
};
const updateUserDetailInChatProjectRedis = (data) => {
  return axios.post(CHAT_API_URL + "updateUserDetailInRedis/" + data, {});
};
const deleteUserDetailInChatProjectRedis = (data) => {
  return axios.post(CHAT_API_URL + "deleteUserDetailInRedis/" + data, {});
};
const getMediaDetails = (queryParams) => {
  return axios.get(API_URL + `media/getAllMedia?${queryParams}`, {
    headers: authAdminHeader(),
  });
};
const mediaUploadFrontend = (media, mediaTypeData) => {
  return axios.post(API_URL + `media/upload?type=${mediaTypeData}`, media, {
    headers: authAdminHeader(),
  });
};

const getCategoryMediaDetails = () => {
  return axios.get(API_URL + "getAllCategory", { headers: authAdminHeader() });
};

const getMediaProgress = (ids) => {
  return axios.post(API_URL + "getMediaProgress", ids, {
    headers: authAdminHeader(),
  });
};

const deleteMedia = (mediaId) => {
  return axios.post(API_URL + "deleteMedia", mediaId, {
    headers: authAdminHeader(),
  });
};

const getAllUsersForMap = () => {
  return axios.get(API_URL + "user/getAllUsersForMap", {
    headers: authHeader(),
  });
};
const getUserTagList = () => {
  return axios.get(API_URL + "tagListUser", { headers: authHeader() });
};

const editMediaDetails = (data) => {
  return axios.post(API_URL + "edit/mediaDetail", data, {
    headers: authAdminHeader(),
  });
};

const syncUserDataWithDocumet = () => {
  return axios.post(
    API_URL + "user/syncUserDataWithDocumet",
    {},
    { headers: authAdminHeader() }
  );
};

const getVideoListingByAdmin = (query) => {
  return axios.get(API_URL + "content-archive/videos" + query, {
    headers: authAdminHeader(),
  });
};

export default {
  editSubCategory,
  checkAlredayExistSubCategory,
  syncUserDataWithDocumet,
  addMessageReport,
  editMediaDetails,
  deleteMedia,
  getMediaProgress,
  getCategoryMediaDetails,
  mediaUploadFrontend,
  getMediaDetails,
  updateUserDetailInChatProjectRedis,
  deleteUserDetailInChatProjectRedis,
  selectPartnerList,
  reviewCountByUser,
  notChatAccessScreen,
  notAccessScreen,
  allEventList,
  getAllPlanResource,
  getShortShareLink,
  commonMediaUpload,
  getCommonImages,
  getFeaturedPartnerList,
  getPartnerSearchHistory,
  removetPartnerSearchHistory,
  addPartnerSearchHistory,
  allPartnerList,
  getPartnerBadges,
  getPartnersInOtherCategories,
  updateUserViewCountOfPartner,
  getPartnerDetailVideos,
  getPartnerDetailPosts,
  updateUserClaimCountOfPartner,
  getFreshDealPartnerList,
  getAllValidUserAndAttendees,
  userLogin,
  adminLogin,
  sociallogin,
  getallplanatregis,
  uploadfiles,
  removefiles,
  paymentapi,
  userRegistration,
  forgotpassword,
  checkToken,
  resetpassword,
  updateprofile,
  deactivateuser,
  blockuser,
  unblockuser,
  deleteuser,
  getblockeduser,
  getAllAttendeeList,
  getAllAttendeeListForRules,
  getAllChannelUserBasedOnFilter,
  getAllChannelUserBasedOnTag,
  userExist,
  socialuserexist,
  getallusers,
  getUserByPlan,
  getallusersLimitedFields,
  getAllUsers,
  addnewmessage,
  getallMessages,
  getimage,
  addfieldsregistrationform,
  retrivefieldsregistrationform,
  deletefieldregistrationform,
  updatefieldregistrationform,
  addquestion,
  retrivequestion,
  deletequestion,
  updatequestion,
  addpost,
  addpost_admin,
  getallpost,
  getsinglepost,
  getsinglepost_admin,
  editpost,
  editpost_admin,
  deletepost,
  deletepost_admin,
  likepost,
  likepost_admin,
  addcomment,
  addcomment_admin,
  likecomment,
  likecomment_admin,
  createreply,
  createreply_admin,
  deletecomment,
  deletecomment_admin,
  getallcomments,
  getalltopics,
  getallfeelings,
  addvote,
  addvote_admin,
  savepost,
  getsavedpost,
  creategroup,
  getallgroup,
  getgroupbyid,
  updategroup,
  updategroupimg,
  deletegroup,
  groupsetting,
  createtopic,
  deletetopic,
  gettopicbyid,
  edittopic,
  groupmembers,
  groupmembers_admin,
  gettopicsbygroupid,
  savetoken,
  getpostbygroupid,
  hidefromfeedpost,
  clonegroup,
  deletemember,
  getannouncementbygroupid,
  makeannoucement,
  gethiddenpost,
  getAllPlan,
  getPlanService,
  deleteplan,
  getlinkedinuser,
  getresource,
  createmembershipplan,
  getplanbyid,
  editplan,
  getstarredgroup,
  getstarredtopic,
  getallgrouploginuser,
  addstargroup,
  addstartopic,
  removestargroup,
  removestartopic,
  joingroup,
  leavegroup,
  getallannoucements,
  getmemberprofile,
  getcommongroup,
  getjoinedgroupothermember,
  getmydetail,
  othermemberfollowers,
  othermemberfollowing,
  sendfollowrequest,
  sendunfollowrequest,
  cancelfollowrequest,
  memberposts,
  membermedia,
  groupmedia,
  myjoinedgroup,
  myallpost,
  getrequestslist,
  rejectrequest,
  editprofile,
  updateAttendeeProfileByAdmin,
  getsentrequestlist,
  getalltopicsforpost,
  groupfileupload,
  getuploadedfiles,
  deleteuploadfiles,
  setlastlogin,
  activeInactiveUser,
  getfriendlist,
  getuserfollowing,
  getuserfollower,
  acceptfollowreq,
  getlatestfiles,
  getbillinginfo,
  saveuserpersonaldetail,
  saveuseronstep2,
  saveauth0detail,
  saveuserQND,
  getgivenanswer,
  getallquestions,
  getuserbyid,
  getAttendeeProfile,
  updateprofilebyadmin,
  updateUserDataInRadis,
  getquestionbyid,
  savereorderindb,
  getalladmin,
  getallresource,
  getallroles,
  createadmin,
  getadminbyid,
  editadmindetail,
  deleteadmin,
  getallcontentcategories,
  getallcontentcategory_byadmin,
  createcontentcategory,
  deleteCategory,
  editCategory,
  getcategorybyid,
  createvideo,
  getallcontentvideos,
  getcontentvideobyid,
  getcontentvideobyidForUser,
  videodetailLog,
  getallcontentvideosuser,
  editvideo,
  getadmingroupbyid,
  gettopicsbygroupid_byadmin,
  deletecontentvideobyId,
  getalldeletedvideos,
  restorevideobyid,
  permenantdeletevideobyid,
  addpausevideobyadmin,
  getpostbygroupid_user,
  getpostbygroupidtopicid_user,
  getallcomments_admin,
  addpausevideobyuser,
  addviewbyuser,
  addviewbyadmin,
  addlikebyadmin,
  addlikebyuser,
  addcomment_video,
  addcomment_videoadmin,
  deletecomment_video,
  deletecomment_adminvideo,
  createreply_adminvideo,
  createreply_video,
  likecomment_adminvideo,
  likecomment_video,
  getallcomments_videoadmin,
  getallcomments_video,
  getalluseremailname,
  getalluseremailname_admin,
  getrecentlyaddedvideos,
  getrelatedvideos,
  getnewestcommentedvideos,
  getmostpopularvideos,
  getvideobycategories,
  getaddvideostatus,
  getvideobycategory_byadmin,
  sharepost,
  cancelPlan,
  updatePlan,
  accessjoinedgroup,
  ChatUploadFiles,
  chatdetail,
  deletemessage,
  getchatgroupmember,
  starchat,
  getmediafileslinks,
  updatemigrateduserdetail,
  deletemigrateduser,
  getauthtoken,
  deleteauthuser,
  savevideo,
  getsavedvideos,
  getallaccessibletopics,
  getfriendlistglobalsearch,
  globalsearch,
  contentarchivefilter,
  getauthuser,
  getAuthUserDetail,
  isAuthUserExists,
  updateMigrationCaseStatus,
  verifyOTP,
  resendOTP,
  blockChat,
  appletokenconvertdata,
  getalluser_forfrontend,
  getstarchatusers,
  getMuteChatIdListForUser,
  muteChatForAll,
  badgesForNotification,
  clearchat,
  addcontactuser,
  senddeactivaterequest,
  getcontactuserslist,
  searchmessages,
  getspeakerbyid,
  deleteSpeaker,
  createspeaker,
  editSpeaker,
  getalltags_byadmin,
  gettagbyid,
  deleteTag,
  createtag,
  editTag,
  getallspeaker_byadmin,
  getVideoBySearchFilterAndCategory,
  addSearchHistory,
  removeSearchHistory,
  getSearchHistory,
  getAllVideoList,
  getUrlMetadata,
  updateprofileimagebyadmin,
  createGroupChat,
  getGroupAndMemberDetails,
  updateGroupInfo,
  addMemberGroupChat,
  removeMemberGroupChat,
  deleteGroupChat,
  leaveGroupChat,
  uploadGroupImage,
  getChatGroupById,
  getChatUserById,
  getMessageDetail,
  getDataSyncUser,
  updateDataSyncUser,
  updateRegistrationDetailOnDashboard,
  getAllUsersMembersAndAttendees,
  deleteAttendeefromAllAttendeeList,
  createSpeakerInUserChanges,
  getSpeakerList,
  getPartnerDetail,
  getPartnerDetailReview,
  getOfferDetailsCount,
  addPartnerReview,
  partnerBannerDetail,
  getDaySinceMDSOnly,
  getAllUser,
  getAllAttendeeSuggestionList,
  getAllPartnerSuggestionList,
  getBlockedUserSuggestionList,
  getAllEventSuggestionList,
  getAllEventTypeSuggestionList,
  getChannelSuggestionList,
  getAllBannerSuggestionList,
  getAllPostSuggestionList,
  getNewsSuggestionList,
  getContentNewsSuggestionList,
  getPartnerSuggestionList,
  getFeaturedPartnersList,
  getFreshdealPartnersList,
  getAllPartnerBadgeSuggestionList,
  partnerCategoriesSuggestionList,
  getAllMembershipPlanSuggestionList,
  getAcceptedCollaboratorsSuggestionList,
  getPendingCollaboratorsSuggestionList,
  getRevokedCollaboratorsSuggestionList,
  getContentVideoSuggestionList,
  getDeletedContentVideoSuggestionList,
  eventSuggestionList,
  getCategoriesSuggestionList,
  tagSuggestionList,
  getPartnerPostByPartnerIdSuggestionList,
  getPartnerReviewByIdSuggestionList,
  getPartnerhelpfulLinksByPartnerIdSuggestionList,
  getVideoListByPartnerSuggestionList,
  getAllPartnerReasonSuggestionList,
  getPartnerReviewSuggestionList,
  getAllUsersForMap,
  getUserTagList,
  getAllValidUserAPI,
  getRelatedVideosEvent,
  editComment,
  replyDeleteCommenmt,
  getVideoListingByAdmin,
  joinInviteLinkChannel
};
