import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
//import components
import Header from "../Dashboard/Header";
import Footer from "../Dashboard/Footer";
import GlobleSearchLeftsidebar from "./GlobleSearchLeftsidebar";
import GlobleSearchMiddlebar from "./GlobleSearchMiddlebar";
import AnnoucementPopup from "../Dashboard/AnnoucementPopup";
import FilesPopup from "../Dashboard/FilesPopup";
import SavedPostPopup from "../Dashboard/SavedFilesPopup";
import EventListPopup from "../Dashboard/EventListPopup";
import ForumListPopup from "../Dashboard/ForumListPopup";
import NotificationListPopup from "../Dashboard/NotificationListPopup";
import AllGroupMobile from "../Dashboard/AllGroupMobile";
import { fetchsearchpost } from "../../../Action";

const GlobleSearchPage = () => {
    const dispatch = useDispatch()
    const search = useLocation().search;
    const topic_search = new URLSearchParams(search).get("topic_search");
    const text_search = new URLSearchParams(search).get("text");
    const user_search = new URLSearchParams(search).get("user");
    const [SelectedGroup, setSelectedGroup] = useState("");
    const [selectedDiv, setselectedDiv] = useState("dashboard");
    const [topicSearch, settopicSearch] = useState("");
    const [openingpop, setopenpop] = useState({
        annou: false,
        files: false,
        save: false,
        event: false,
        forum: false,
        notifi: false,
        allgroup: false,
    });
    const [dis_hum, setdis_hum] = useState(false)
  useEffect(() => {
    if (
      localStorage.getItem("idtoken") === null ||
      localStorage.getItem("idtoken").length === 0 ||
      localStorage.getItem("userid") === null ||
      localStorage.getItem("userid").length === 0 ||
      localStorage.getItem("username") === null ||
      localStorage.getItem("username").length === 0 ||
      localStorage.getItem("role") === null ||
      localStorage.getItem("role") !== "user" ||
      localStorage.getItem("logintime") === undefined ||
      localStorage.getItem("logintime") === null
    ) {
      window.location.href = "/";
    }
  }, []);
  useEffect(()=>{
    dispatch(fetchsearchpost({text: text_search, topic: topic_search, user: user_search}))
  },[topic_search,text_search,user_search])
  return (
    <>
      <Header
        setSelectedGroup={setSelectedGroup}
        setselectedDiv={setselectedDiv}
        settopicSearch={settopicSearch}
      />
      <div className="all-content-main cstm-all-content-main">
        <div
          className={`all-content-main-left cstm-all-content-main-left glob-hidden ${
            dis_hum ? "active" : ""
          }`}
        >
          <GlobleSearchLeftsidebar setdis_hum={setdis_hum} />
        </div>
        <div className="all-content-main-center cstm-all-content-center">
          <GlobleSearchMiddlebar setdis_hum={setdis_hum} />
          {/* <GloblePopup trigger={openingpop}> */}
          {openingpop.annou ? (
            <AnnoucementPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {/* </GloblePopup> */}
          {openingpop.files ? (
            <FilesPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.save ? (
            <SavedPostPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.event ? (
            <EventListPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.forum ? (
            <ForumListPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.notifi ? (
            <NotificationListPopup
              setclose={setopenpop}
              openingpop={openingpop}
            />
          ) : (
            <></>
          )}
          {openingpop.allgroup ? (
            <AllGroupMobile setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* <Footer openingpop={openingpop} setopenpop={setopenpop} /> */}
    </>
  );
};
export default GlobleSearchPage;
