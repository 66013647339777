import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";
import millify from "millify";
import Masonry from "react-responsive-masonry";
import moment from "moment";
import Services from "../../../Services/auth.service";
import {
  fetchpostbygroupid,
  fetchactivityfeelings,
  fetchgrouptopics,
  fetchpostbygroupidtopicid,
  fetchlatestfiles,
} from "../../../Action/index.js";
import Members from "./Members";
import Event from "./Event";
import About from "./About";
import AllPost from "../FrontendPost/AllPost";
import Header from "../Dashboard/Header";
import LeftSidebar from "../Dashboard/LeftSidebar";
import RightSidebar from "../Dashboard/RightSidebar";
import AllGroupMobile from "../Dashboard/AllGroupMobile";
import AnnoucementPopup from "../Dashboard/AnnoucementPopup";
import EventListPopup from "../Dashboard/EventListPopup";
import FilesPopup from "../Dashboard/FilesPopup";
import ForumListPopup from "../Dashboard/ForumListPopup";
import NotificationListPopup from "../Dashboard/NotificationListPopup";
import SavedPostPopup from "../Dashboard/SavedFilesPopup";
import Footer from "../Dashboard/Footer";
import AddPost from "../FrontendPost/AddPost";
import LoaderComponent from "../LoaderComponent";
import CreatePostPopupClick from "../CreatePostPopupClick";
//images
import Dummy from "../../../Images/dummyGrp.png";
import Group_img from "../../../Images/group.svg";
import Global from "../../../Images/global.svg";
import Dottedy from "../../../Images/dottedy.svg";
import plus_img from "../../../Images/plus.svg";
import link_img from "../../../Images/link.svg";
import Uploadwhite from "../../../Images/upload-white.svg";
import Uploadblack from "../../../Images/upload-black.svg";
import Backarrow from "../../../Images/back-arrow.svg";
import noMediaFound from "../../../Images/nomediafound.svg";
import openfolder from "../../../Images/folder-open.png";

const Group = ({ SelectedGroup, setselectedDiv }) => {
  const search = useLocation().search;
  const topic_def = new URLSearchParams(search).get("topic");
  const USERID = localStorage.getItem("userid");
  const dispatch = useDispatch();
  const [grploader, setgrploader] = useState(false);
  const [leftsidebar, setleftsidebar] = useState(true);
  const [rightsidebar, setrightsidebar] = useState(true);
  const [topicSearch, settopicSearch] = useState("");
  const { groupid } = useParams();
  const [groupDetail, setgroupDetail] = useState({});
  const [groupmembers, setgroupmembers] = useState([]);
  const [friendlist, setfriendlist] = useState([]);
  const [progbarVal, setprogbarVal] = useState("");
  const [page, setpage] = useState(1);
  const [userIsMember, setuserIsMember] = useState(false);
  const [allimages, setimages] = useState([]);
  const [disimage, setdisimage] = useState([]);
  const [groupfiles, setgroupfiles] = useState([]);
  const [grouperr, setgrouperr] = useState({
    msg: "",
    status: true,
  });
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [filtermedia, setfiltermedia] = useState("all");
  const [openingpop, setopenpop] = useState({
    annou: false,
    files: false,
    save: false,
    event: false,
    forum: false,
    notifi: false,
    allgroup: false,
  });
  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept:
      "application/vnd.ms-excel, application/pdf, application/msword, text/csv , application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    maxSizeBytes: 10485760,
    onDrop,
  });
  const assignFiles = (acceptedFiles) => {
    var arr = [...acceptedFiles];
    if (arr.length > 0) {
      var formdata = new FormData();
      for (var i = 0; i < arr.length; i++) {
        formdata.append("file", arr[i]);
      }
      Services.groupfileupload({
        groupid:
          SelectedGroup !== undefined && SelectedGroup.length > 0
            ? SelectedGroup
            : groupid,
        data: formdata,
      })
        .then((res) => {
          if (res.data.status) {
            dispatch(fetchlatestfiles());
            setgroupfiles([...groupfiles, ...res.data.data]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    setgrploader(true);
    dispatch(fetchactivityfeelings());
    dispatch(
      fetchgrouptopics(
        SelectedGroup !== undefined && SelectedGroup.length > 0
          ? SelectedGroup
          : groupid
      )
    );
    dispatch(
      fetchpostbygroupid({
        groupid:
          SelectedGroup !== undefined && SelectedGroup.length > 0
            ? SelectedGroup
            : groupid,
        page: page,
      })
    );
    Services.getfriendlist()
      .then((res) => {
        if (res.data.status) {
          setfriendlist(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getuploadedfiles(
      SelectedGroup !== undefined && SelectedGroup.length > 0
        ? SelectedGroup
        : groupid
    )
      .then((res) => {
        if (res.data.status) {
          setgroupfiles(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.groupmedia(
      SelectedGroup !== undefined && SelectedGroup.length > 0
        ? SelectedGroup
        : groupid
    )
      .then((res) => {
        if (res.data.status) {
          setimages(res.data.data);
          setdisimage(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getgroupbyid(
      SelectedGroup !== undefined && SelectedGroup.length > 0
        ? SelectedGroup
        : groupid
    )
      .then((res) => {
        if (res.data.status) {
          setgroupDetail(res.data.data);
        }
        setgrploader(false);
      })
      .catch((err) => {
        setgrploader(false);
        console.log(err);
      });
    Services.groupmembers(
      SelectedGroup !== undefined && SelectedGroup.length > 0
        ? SelectedGroup
        : groupid
    )
      .then((res) => {
        if (res.data.status) {
          setgroupmembers(res.data.data);
          var val =
            res.data.data.filter((mem) => {
              if (mem.userId !== null && mem.userId._id === USERID) return mem;
            }).length > 0
              ? true
              : false;
          setuserIsMember(val);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [SelectedGroup, groupid]);
  useEffect(() => {
    if (topic_def !== null && topic_def !== undefined && topic_def.length > 0) {
      console.log(topic_def, "topic_def");
      dispatch(
        fetchpostbygroupidtopicid({
          groupid:
            SelectedGroup !== undefined && SelectedGroup.length > 0
              ? SelectedGroup
              : groupid,
          topicid: topic_def,
          page: 1,
        })
      );
      setpage(1);
    }
  }, [topic_def]);
  useEffect(() => {
    Services.groupmedia(
      SelectedGroup !== undefined && SelectedGroup.length > 0
        ? SelectedGroup
        : groupid
    )
      .then((res) => {
        if (res.data.status) {
          setimages(res.data.data);
          setdisimage(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [grouperr]);
  useEffect(() => {
    var arr = allimages.filter((media, index) => {
      if (
        media.type === "image" &&
        (filtermedia === "images" || filtermedia === "all")
      ) {
        return media;
      } else if (
        media.type === "video" &&
        (filtermedia === "videos" || filtermedia === "all")
      ) {
        return media;
      }
    });
    setdisimage(arr);
  }, [filtermedia]);
  const joinGroup = () => {
    Services.joingroup({
      groupId:
        SelectedGroup !== undefined && SelectedGroup.length > 0
          ? SelectedGroup
          : groupid,
    })
      .then((res) => {
        if (res.data.status) {
          setgroupmembers([...groupmembers, res.data.data]);
          setuserIsMember(true);
        } else {
          setgrouperr({ status: true, msg: res.data.message });
          setTimeout(() => {
            setgrouperr({ status: false, msg: "" });
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const leaveGroup = () => {
    Services.leavegroup(
      SelectedGroup !== undefined && SelectedGroup.length > 0
        ? SelectedGroup
        : groupid
    ).then((res) => {
      if (res.data.status) {
        var arr = [];
        var n = 0;
        for (var i = 0; i < groupmembers.length; i++) {
          if (
            groupmembers[i].userId !== null &&
            groupmembers[i].userId._id !== localStorage.getItem("userid")
          ) {
            arr[n] = groupmembers[i];
            n++;
          }
        }
        setgroupmembers(arr);
        setuserIsMember(false);
      } else {
        setgrouperr({ status: true, msg: res.data.message });
        setTimeout(() => {
          setgrouperr({ status: false, msg: "" });
        }, 3000);
      }
    });
  };
  return (
    <>
      <Header />
      <div
        className={
          leftsidebar && rightsidebar
            ? "all-content-main cstm-all-content-main grup-cstm-all-content-main"
            : leftsidebar === false && rightsidebar
              ? "all-content-main mn-cnt-wd"
              : rightsidebar === false && leftsidebar
                ? "all-content-main mn-cnt3-wd"
                : "all-content-main mn-cnt-both"
        }
      >
        
          <LeftSidebar
            selectedDiv="group"
            SelectedGroup={
              SelectedGroup !== undefined && SelectedGroup.length > 0
                ? SelectedGroup
                : groupid
            }
            leftsidebar={leftsidebar}
          />
        <div className="all-content-main-center cstm-all-content-main-center grup-cstm-all-content">
          {groupDetail.accessible_group ? <></> : <div className="loadind-post"> Upgrade your plan to access this group! </div>}
          {grploader ? (
            <div className="loadind-post">
              <LoaderComponent />
            </div>
          ) :
            <>
              <div className="profile-bg-img ">
                <img
                  className="profile-bg-img-mn"
                  src={
                    groupDetail.groupCoverImage !== null &&
                      groupDetail.groupCoverImage !== undefined &&
                      groupDetail.groupCoverImage.length > 0
                      ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                      groupDetail.groupCoverImage
                      : Dummy
                  }
                />
              </div>

              <div className="profile-detilas-all">
                <div className="profile-detilas-all-username">
                  <div className="profile-detilas-all-username-left">
                    <div className="profile-detilas-user-title">
                      {groupDetail.groupTitle}{" "}
                    </div>
                    <div className="profile-detilas-user-icon">
                      <span className="profile-detilas-user-icon-list">
                        <img src={Global} />
                        {groupDetail.groupType} group
                      </span>
                      <span className="profile-detilas-user-icon-list">
                        <img src={Group_img} />
                        {groupmembers.length !== undefined
                          ? millify(groupmembers.length)
                          : "0 "}{" "}
                        {groupmembers.length > 1 ? "members" : "member"}
                      </span>
                    </div>
                  </div>
                  <div className="profile-detilas-all-username-right">
                    <div className="profile-detilas-cmn pd-joind">
                      {groupmembers.length > 0 ? (
                        groupmembers.filter((member) => {
                          if (
                            member.userId !== null &&
                            member.userId._id !== undefined &&
                            member.userId._id === localStorage.getItem("userid")
                          )
                            return member;
                        }).length > 0 ? (
                          <>
                            <span
                              className="pd-joind-btn-mn"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {" "}
                              <button className="pd-joind-btn">
                                Joined <i className="fa fa-angle-down"></i>
                              </button>{" "}
                            </span>
                            <div
                              className="dropdown-menu  pd-joind-in"
                              x-placement="bottom-start"
                            >
                              <ul>
                                <li>
                                  <a
                                    onClick={() => {
                                      leaveGroup();
                                    }}
                                  >
                                    Leave group
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <button
                            className="pd-joind-btn"
                            onClick={() => {
                              joinGroup();
                            }}
                          >
                            {" "}
                            Join now
                          </button>
                        )
                      ) : (
                        <button
                          className="pd-joind-btn"
                          onClick={() => {
                            joinGroup();
                          }}
                        >
                          {" "}
                          Join now
                        </button>
                      )}{" "}
                    </div>

                    <div className="profile-detilas-cmn pd-invite">
                      <span
                        className="pd-invite-btn-mn"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {" "}
                        <button className="pd-invite-btn">
                          Invite <i className="fa  fa-plus"></i>
                        </button>
                      </span>
                      <div
                        className="dropdown-menu  pd-invite-in"
                        x-placement="bottom-start"
                      >
                        <ul>
                          <li>
                            <span className="pd-invite-in-title">
                              <img src={plus_img} />
                              <a
                                href=""
                                data-toggle="modal"
                                data-target="#friendlist"
                              >
                                Invite
                              </a>
                            </span>
                            <span className="pd-invite-in-sub">
                              Invite people you are connected to on MDS
                            </span>
                          </li>
                          <li>
                            <span className="pd-invite-in-title">
                              <img src={link_img} />
                              <a href="#">Invite with link</a>
                            </span>
                            <span className="pd-invite-in-sub">
                              Copy link and invite
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="profile-detilas-cmn pd-dottod">
                      <span
                        className="pd-dottod-btn-mn"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {" "}
                        <button className="pd-dottod-btn">
                          <img src={Dottedy} />
                        </button>
                      </span>

                      <div
                        className="dropdown-menu  pd-dottod-in"
                        x-placement="bottom-start"
                      >
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-info-circle"></i> Report group
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-detilas-all-nav">
                  <ul className="nav profiledt-menu">
                    <li>
                      <a
                        className="active"
                        id="Groupposts-tab"
                        data-toggle="tab"
                        href="#Group"
                        role="tab"
                        aria-controls="Group"
                        aria-selected="true"
                      >
                        Group posts
                      </a>
                    </li>
                    <li>
                      <a
                        className=""
                        id="members-tab"
                        data-toggle="tab"
                        href="#members"
                        role="tab"
                        aria-controls="members"
                        aria-selected="false"
                      >
                        Members
                      </a>
                    </li>
                    <li>
                      <a
                        className=""
                        id="events-tab"
                        data-toggle="tab"
                        href="#events"
                        role="tab"
                        aria-controls="events"
                        aria-selected="false"
                      >
                        Events
                      </a>
                    </li>
                    <li>
                      <a
                        className=""
                        id="about-tab"
                        data-toggle="tab"
                        href="#about"
                        role="tab"
                        aria-controls="about"
                        aria-selected="false"
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className=""
                        id="media-tab"
                        data-toggle="tab"
                        href="#media"
                        role="tab"
                        aria-controls="media"
                        aria-selected="false"
                      >
                        Media
                      </a>
                    </li>
                    {userIsMember ? (
                      <li>
                        <a
                          className=""
                          id="files-tab"
                          data-toggle="tab"
                          href="#files_grp"
                          role="tab"
                          aria-controls="files"
                          aria-selected="false"
                        >
                          Files
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
              <div
                className="tab-content profile-details-tabs"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active group-post-tab-inner"
                  id="Group"
                  role="tabpanel"
                  aria-labelledby="Groupposts-tab"
                >
                  {userIsMember && groupDetail.accessible_group ? (
                    <CreatePostPopupClick />
                  ) : (
                    <></>
                  )}

                  {progbarVal.length > 0 ? (
                    <div className="post-uploading-in-progress">
                      <div className="pu-progress-title">
                        Post uploading in progress
                      </div>
                      <div className="pu-progress-fil">
                        <div
                          className="pu-progress-bar"
                          style={{ width: progbarVal }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <AddPost
                    setposterr={setgrouperr}
                    setprogbarVal={setprogbarVal}
                    groupid={
                      SelectedGroup !== undefined && SelectedGroup.length > 0
                        ? SelectedGroup
                        : groupid
                    }
                    post_scope="group"
                    groupname={groupDetail.groupTitle}
                  />
                  <AllPost
                    posterr={grouperr}
                    setposterr={setgrouperr}
                    progbarVal={progbarVal}
                    setprogbarVal={setprogbarVal}
                    post_scope="group"
                    userIsMember={userIsMember}
                    SelectedGroup={
                      SelectedGroup !== undefined && SelectedGroup.length > 0
                        ? SelectedGroup
                        : groupid
                    }
                  />
                </div>

                <div
                  className="tab-pane fade member-post-tab-inner"
                  id="members"
                  role="tabpanel"
                  aria-labelledby="members-tab"
                >
                  <Members
                    groupmembers={groupmembers}
                    setselectedDiv={setselectedDiv}
                    userIsMember={userIsMember}
                  />
                </div>

                <div
                  className="tab-pane fade events-post-tab-inner"
                  id="events"
                  role="tabpanel"
                  aria-labelledby="events-tab"
                >
                  <Event />
                </div>
                <div
                  className="tab-pane fade about-post-tab-inner"
                  id="about"
                  role="tabpanel"
                  aria-labelledby="about-tab"
                >
                  <About
                    groupmembers={groupmembers}
                    groupDetail={groupDetail}
                  />
                </div>
                <div
                  className="tab-pane fade media-post-tab-inner"
                  id="media"
                  role="tabpanel"
                  aria-labelledby="media-tab"
                >
                  {allimages.length > 0 ? (
                    <div className="cstm-media-all-main">
                      <div
                        className="modal fade create-popup-post"
                        id="groupmediaslider"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            {allimages !== undefined &&
                              allimages[selectedSlide] !== undefined &&
                              allimages[selectedSlide].type !== undefined ? (
                              allimages[selectedSlide].type === "image" ? (
                                <img
                                  src={
                                    process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    allimages[selectedSlide].image
                                  }
                                />
                              ) : (
                                <video
                                  src={
                                    process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    allimages[selectedSlide].video
                                  }
                                  controls={true}
                                />
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="group-media-title">Group Media</span>
                      <div className="group-media-main">
                        <ul>
                          <li>
                            <button
                              className={
                                filtermedia === "all" ? "acvite-gm" : ""
                              }
                              onClick={() => {
                                setfiltermedia("all");
                              }}
                            >
                              ALL
                            </button>
                          </li>
                          <li>
                            <button
                              className={
                                filtermedia === "images" ? "acvite-gm" : ""
                              }
                              onClick={() => {
                                setfiltermedia("images");
                              }}
                            >
                              Images
                            </button>
                          </li>
                          <li>
                            <button
                              className={
                                filtermedia === "videos" ? "acvite-gm" : ""
                              }
                              onClick={() => {
                                setfiltermedia("videos");
                              }}
                            >
                              Videos
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="group-media-slider">
                        <Masonry columnsCount={3} gutter="16px">
                          {disimage.map((media, index) => {
                            if (
                              media.type === "image" &&
                              (filtermedia === "images" ||
                                filtermedia === "all")
                            ) {
                              return (
                                <img
                                  src={
                                    process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    media.image
                                  }
                                  onClick={() => {
                                    setSelectedSlide(index);
                                  }}
                                  data-toggle="modal"
                                  data-target="#groupmediaslider"
                                />
                              );
                            } else if (
                              media.type === "video" &&
                              (filtermedia === "videos" ||
                                filtermedia === "all")
                            ) {
                              return (
                                <video
                                  src={
                                    process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    media.video
                                  }
                                  controls={true}
                                  onClick={() => {
                                    setSelectedSlide(index);
                                  }}
                                  data-toggle="modal"
                                  data-target="#groupmediaslider"
                                />
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </Masonry>
                      </div>
                    </div>
                  ) : (
                    <div className="cstm-media-all-main cstm-news-no-post">
                      <img src={noMediaFound} alt="noMediaFound" />
                      <span className="emptymsg">No media file exist</span>
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane fade files-post-tab-inner"
                  id="files_grp"
                  role="tabpanel"
                  aria-labelledby="files-tab"
                >
                  <div className="pro-about">
                    <div className="pro-group-members">
                      <div className="pro-group-members-title">Group Files</div>
                      <div className="pro-upload-filas-btn">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <button className="btn-uf">
                            <img src={Uploadwhite} /> Upload Files
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="pro-group-members-list-main">
                      {groupfiles.map((file) => {
                        return (
                          <div className="pro-group-members-list">
                            <div className="pro-group-members-list-left">
                              <div className="pro-group-members-list-left-img">
                                <img src={openfolder} />
                              </div>
                              <div className="pro-group-members-list-left-title">
                                <a>
                                  {file.file.split("-").splice(1).join("-")}
                                </a>
                              </div>
                              <div className="pro-group-members-list-left-file">
                                File
                              </div>
                            </div>
                            <div className="pro-group-members-list-right">
                              <div className="pro-group-members-list-right-date">
                                {moment(file.updatedAt).format("DD MMM, YYYY")}
                              </div>
                              <div
                                className="pro-group-members-list-right-upload"
                                onClick={() => {
                                  saveAs(
                                    process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    file.file,
                                    file.file.split("-").splice(1).join("-")
                                  );
                                }}
                              >
                                <img src={Uploadblack} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>}
          {openingpop.annou ? (
            <AnnoucementPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.files ? (
            <FilesPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.save ? (
            <SavedPostPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.event ? (
            <EventListPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.forum ? (
            <ForumListPopup setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
          {openingpop.notifi ? (
            <NotificationListPopup
              setclose={setopenpop}
              openingpop={openingpop}
            />
          ) : (
            <></>
          )}
          {openingpop.allgroup ? (
            <AllGroupMobile setclose={setopenpop} openingpop={openingpop} />
          ) : (
            <></>
          )}
        </div>

        <div
          className={
            rightsidebar
              ? "all-content-main-right cstm-all-content-main-right"
              : "all-content-main-right  sidebar-hd"
          }
        >
          <RightSidebar selectedDiv="group" />
        </div>
      </div>
      {/* <Footer openingpop={openingpop} setopenpop={setopenpop} /> */}
      <div
        className="modal fade create-popup-post"
        id="friendlist"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="create-ps-title">
              <div className="create-ps-title-title">
                <button className="back-btn-mn" data-dismiss="modal">
                  <img src={Backarrow} />
                </button>
                Friend list
              </div>
              <div className="create-ps-title-close">
                <button className="close" data-dismiss="modal">
                  ✕
                </button>
              </div>
            </div>
            <div className="topics-search-ovr">
              <div className="topics-search-ovr-in">
                <span className="tso-id"> Friend name </span>
              </div>
            </div>

            <div className="topics-search-ovr">
              <div className="topics-search-ovr-in">
                <span className="tso-id"> Friend name </span>
              </div>
            </div>

            <div className="topics-search-ovr">
              <div className="topics-search-ovr-in">
                <span className="tso-id"> Friend name </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Group;



